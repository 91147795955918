<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox ">
					<div class="textImage2">
						<div>
							<h2>凝思虚拟化管理平台概述</h2>
							<p>凝思虚拟化管理平台是以凝思安全操作系统为基础，通过虚拟化技术将一台计算机虚拟为多台逻辑物理机。每个逻辑计算机可运行不同的操作系统及应用程序，可以<span>在相互独立的空间内运行而互不影响</span>。使用软件的方法重新定义划分 IT 资源，可以实现 IT 资源的<span>动态分配、灵活调度、跨域共享</span>，提高 IT 资源利用率，还可以通过瘦客户端或者其他与网络相连的设备来访问整个虚拟机客户桌面。</p>
							<p>从底层操作系统到虚拟化软件再到云终端客户机，实现了虚拟化技术的全国产化，而凝思虚拟化管理平台与凝思安全操作系统相结合，<span>提供了更为稳定、可靠的运行环境</span>。（图示1）</p>
							<div v-show="bodyWidthMut > 750">
								<h2>凝思虚拟化管理引擎</h2>
								<p>运行凝思虚拟化管理  Web 的服务器，用来管理主机、网络、存储、虚拟机、模板、用户。</p>
								<h2>凝思虚拟化节点1-n</h2>
								<p>提供计算、内存、网络能力的主机。</p>
								<h2>存储1-n</h2>
								<p>支持不同类型的存储，如：fc 存储，nfs 存储，本地存储等。</p>
							</div>
						</div>
						<div>
							<img src="./images/虚拟化管理平台-01.png"/>
							<h6>图示1：架构</h6>
							<div v-show="bodyWidthMut <= 750">
								<h2>凝思虚拟化管理引擎</h2>
								<p>运行凝思虚拟化管理 Web 的服务器，用来管理主机、网络、存储、虚拟机、模板、用户。</p>
								<h2>凝思虚拟化节点1-n</h2>
								<p>提供计算、内存、网络能力的主机。</p>
								<h2>存储1-n</h2>
								<p>支持不同类型的存储，如：fc存储，nfs存储，本地存储等。</p>
							</div>
						</div>
					</div>
				</div>
				<div class="textBox textBoxBorder">
					<h2>凝思虚拟化管理平台优势</h2>
					<div class="textDetails figureweight">
						<p class="figure figure1">整合数据中心IT基础设施资源，提升资源的利用率。</p>
						<p class="figure figure2">建立安全的、可审核的数据中心环境，提供多种手段登录到虚拟化管理平台，支持人脸认证、指纹识别、用户名密码，安全可追溯。</p>
						<p class="figure figure3">对业务部门的需求可集中做出快速响应，提供 GPU 加速功能，实际性能与图形化工作站性能相仿。</p>
						<p class="figure figure4">缩短业务计划内、计划外停顿时间，保证业务连续性。</p>
						<p class="figure figure5">网络隔离的安全性，将虚拟机的远程图像流量与业务数据流量进行分离，保障网间业务数据安全交互。</p>
						<p class="figure figure6">虚拟工作站终端回显，凝思安全操作系统中可通过云桌面提供终端回显功能回溯整个操作过程，可查询问题所在。</p>
					</div>
				</div>
				<div class="textBox textBoxBackground">
					<h2>凝思虚拟化管理平台特点</h2>
					<div class="featureBox">
						<div class="featureText5">
							<div>
								<div class="text">
									<h4>高兼容性</h4>
									<p>相比其他虚拟化平台，凝思虚拟化管理平台结合凝思安全操作系统，能够为用户提供更为优秀的性能和体验，且广泛支持市场各类架构，如X86_64、ARM64等，支持各大品牌服务器，如华为、浪潮、Dell等。</p>
								</div>
								<div class="text">
									<h4>易管理</h4>
									<p>凝思虚拟化基于 Web 页面的可视化资源管控平台，方便管理员进行资源分配与管理，可通过网络访问控制机制对虚拟工作站进行网络控制，可通过虚拟设备访问管控机制对存储功能进行管控，以避免用户的不当操作，为企业提供安全保障。</p>
								</div>
								<div class="text">
									<h4>高安全</h4>
									<p>凝思虚拟化管理平台基于凝思安全操作系统，宿主机系统、瘦终端系统、虚拟化平台各组件均自主可控，提供后续的安全升级与补丁更新，保障了客户业务的安全、稳定。</p>
								</div>
							</div>
							<div>
								<div class="text">
									<h4>高可用</h4>
									<p>通过操作系统层面的系统数据和用户数据隔离实现了虚拟机级的高可用技术。用户误操作导致系统损坏引发虚拟机无法启动时，仅需重新克隆并更换系统介质，即可实现整个虚拟机的修复，保障了用户数据不丢失。</p>
								</div>
								<div class="text">
									<h4>易扩展</h4>
									<p>通过资源池化技术打破单机资源隔离限制，通过迅速、动态的对计算资源、存储资源进行扩容/分配，帮助客户适应新的业务需求，提高应用部署的灵活性。</p>
								</div>
							</div>
						</div>
					</div>
					<!--<FeatureBox :feature="feature"></FeatureBox>-->
					<h2>应用场景</h2>
					<div class="imgsDisplay">
						<div>
							<img src="./images/虚拟化管理平台-02.png"/>
							<p>数据中心</p>
						</div>
						<div>
							<img src="./images/虚拟化管理平台-03.png"/>
							<p>生产系统</p>
						</div>
						<div>
							<img src="./images/虚拟化管理平台-04.png"/>
							<p>服务大厅</p>
						</div>
						<div>
							<img src="./images/虚拟化管理平台-05.png"/>
							<p>集中办公 </p>
						</div>
					</div>
				</div>
				<div class="textBox textBigTop">
					<h2>凝思虚拟化管理平台产品规格</h2>
					<p class="tintcolor"><b>虚拟机规格</b>提供了参考规格，不同的业务需求，所需资源不尽相同，可根据实际需求进行扩展。</p>
					<p class="tintcolor"><b>节点服务器规格</b>以下是节点服务器单机规格，同时也可根据业务需求，进行扩展或者集群。凝思同时提供了定制化的解决方案。</p>
					<div class="tableBox">
						<div>
							<p><b>CPU：</b>20核，单核频率2.20GHz，物理CPU的核的个数决定了一个虚拟机最大的可运行VCPU个数</p>
							<p><b>内存256G：</b>节点服务器内存的大小决定了同时可运行的虚拟机个数。如运行一个具有4G内存的Windows系统，则理论上可跑（256-4）/4=63个，留下4G给主机系统使用，但实际节点的物理CPU是虚拟机的限制</p>
						</div>
						<div>
							<p><b>本地存储：</b>1.8T</p>
							<p><b>网络带宽：</b>千兆网卡 X4</p>
							<p><b>FC存储阵列：</b>可按需要扩展</p>
						</div>
					</div>
					<p class="tintcolor"><b>瘦终端规格</b>对于瘦终端规格，凝思也提供高配、中配、低配三种解决方案供用户选择，客户可根据需求、资源、成本等进行综合考量。</p>
					<div class="tablebutton">
						<div @click="tableShow = 1" :class="{inbutton:tableShow == 1}">高端配置</div>
						<div @click="tableShow = 2" :class="{inbutton:tableShow == 2}">中端配置</div>
						<div @click="tableShow = 3" :class="{inbutton:tableShow == 3}">低端配置</div>
					</div>
					<table>
						<tr>
							<th>基本参数(单位)</th>
							<transition name="fade">
								<th v-show="tableShow == 1 || tablecue == 1">高端配置</th>
							</transition>
							<transition name="fade">
								<th v-show="tableShow == 2 || tablecue == 1">中端配置</th>
							</transition>
							<transition name="fade">
								<th v-show="tableShow == 3 || tablecue == 1">低端配置</th>
							</transition>
						</tr>
						<tr v-for="(cor,indexcor) in table" :key="indexcor + '-1'">
							<td>{{cor[0]}}</td>
							<transition name="fade">
								<td v-show="tableShow == 1 || tablecue == 1" v-html="cor[1]"></td>
							</transition>
							<transition name="fade">
								<td v-show="tableShow == 2 || tablecue == 1" v-html="cor[2]"></td>
							</transition>
							<transition name="fade">
								<td v-show="tableShow == 3 || tablecue == 1" v-html="cor[3]"></td>
							</transition>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'VirtualizationManagementPlatform',
	data() {
		return {
			tableShow: 1,
			tablecue: 0,
			bodyWidthMut: document.body.clientWidth,
			feature:[
        		{
					id:'feature24',
        			title:'高兼容性',
        			text:[
        				'相比其他虚拟化平台，凝思虚拟化管理平台结合凝思安全操作系统，能够为用户提供更为优秀的性能和体验，且广泛支持市场各类架构，如X86_64、ARM64等，支持各大品牌服务器，如华为、浪潮、Dell等。',
        			],
        		},
        		{
					id:'feature25',
        			title:'易管理',
        			text:[
        				'凝思虚拟化基于Web页面的可视化资源管控平台，方便管理员进行资源分配与管理，可通过网络访问控制机制对虚拟工作站进行网络控制，可通过虚拟设备访问管控机制对存储功能进行管控，以避免用户的不当操作，为企业提供安全保障。',
        			],
        		},
        		{
					id:'feature26',
        			title:'高安全',
        			text:[
        				'凝思虚拟化管理平台基于凝思安全操作系统，宿主机系统、瘦终端系统、虚拟化平台各组件均自主可控，提供后续的安全升级与补丁更新，保障了客户业务的安全、稳定。',
        			],
        		},
        		{
					id:'feature27',
        			title:'高可用',
        			text:[
        				'通过操作系统层面的系统数据和用户数据隔离实现了虚拟机级的高可用技术。用户误操作导致系统损坏引发虚拟机无法启动时，仅需重新克隆并更换系统介质，即可实现整个虚拟机的修复，保障了用户数据不丢失。',
        			],
        		},
        		{
					id:'feature28',
        			title:'易扩展',
        			text:[
        				'通过资源池化技术打破单机资源隔离限制，通过迅速、动态的对计算资源、存储资源进行扩容/分配，帮助客户适应新的业务需求，提高应用部署的灵活性。',
        			],
        		},
    		],
    		table:[
    			[
    				'CPU(GHZ)',
    				'&gt;=2.6&times;(4 or 8核)',
    				'&gt;=2.0(4核)',
    				'&gt;=1.8(4核)',
				],
    			[
    				'内存(GB)',
    				'&gt;=8',
    				'&gt;=4',
    				'&gt;=4',
				],
    			[
    				'存储(GB)',
    				'&gt;=16',
    				'&gt;=8',
    				'&gt;=8',
				],
    			[
    				'USB(个)',
    				'&gt;=4',
    				'&gt;=4',
    				'&gt;=4',
				],
    			[
    				'声音接口(个)',
    				'&gt;=1个3.5mm耳麦一体接口',
    				'&gt;=1个3.5mm耳麦一体接口',
    				'&gt;=1个3.5mm耳麦一体接口',
				],
    			[
    				'U显卡(个)',
    				'1个(支持4K)',
    				'1个',
    				'1个',
				],
    			[
    				'网卡(M)',
    				'&gt;=1个网口<br>(10/100/1000)',
    				'&gt;=1个网口<br>(10/100/1000)',
    				'&gt;=1个网口<br>(10/100/1000)',
				],
    			[
    				'显示接口(个)',
    				'2 HDMI接口',
    				'1 HDMI接口',
    				'1 HDMI接口',
				],
    			[
    				'盒子尺寸(mm)',
    				'200&times;200&times;50',
    				'200&times;200&times;50',
    				'200&times;200&times;50',
				],
    			[
    				'最高分辨率',
    				'3840&times;2160',
    				'1920&times;1080',
    				'1920&times;1080',
				],
			]
    	}
	},
	mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
    watch: {
	    bodyWidthMut:{
		    immediate:true,
		    handler(val){
				if(val > 750){
					this.tablecue = 1;
				} else {
					this.tablecue = 0;
				}
			}
		},
	},
}
</script>
<style scoped>

.fade-leave-active,.fade-enter-active{
    transition: opacity 0.6s
}
.fade-enter-to,.fade-leave{
    opacity: 1
}
.fade-leave-to,.fade-enter{
    opacity: 0
}

</style>
