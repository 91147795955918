<template>
	<div class="font" id="fontNoBorder">
		<div class="column columnImgFont">
			<figure><img src="../images/发电集团.jpg"/></figure>
			<div class="cornerBorder">
				<h1>发电集团</h1>
				<p>随着国网对信息化安全重视的程度越来越高，对国内各发电集团下属电厂的安全升级工作发出指导意见，明确要求使用<span>符合等级保护第四级</span>的安全操作系统。自2018年开始逐步推行，凝思凭借此前在变电站项目中的经验，迅速支撑起电厂业务的应用，逐步在全国范围内的电厂开始部署。中国五大发电集团华能、大唐、华电、国电、国电投，四小发电集团华润、国华、国投、中广核下属的电厂均有部署，累计部署量<span>超数千套。</span></p>
			</div>
		</div>
		<div class="column columngray rhombusp">
			<div>
				<h1>典型案例</h1>
				<p>华润风电安阳永和电厂</p>
				<p>围场张家湾永日30兆瓦光伏扶贫电站（光伏）</p>
				<p>浙江安吉草荡光伏电站项目（光伏）</p>
				<p>乌东德水电，四川省会东县和云南省禄劝县交界处金沙江河道、水电站坝顶高程988米，最大坝高270米，总库容74.08亿立方米。电站安装12台单机容量85万千瓦的水轮发电机组，装机总容量1020万千瓦，年发电量389.1亿千瓦时，已全部部署凝思安全操作系统、指纹认证软件</p>
			</div>
			<div>
				<h1 class="novisical">典型案例</h1>
				<p>四川雅安电力水电站集控中心</p>
				<p>全国范围内国电投集控中心</p>
				<p>白鹤滩水电，四川省宁南县和云南省巧家县境内，是金沙江下游干流河段梯级开发的第二个梯级电站，具有以发电为主，兼有防洪、拦沙、改善下游航运条件和发展库区通航等综合效益。 水库正常蓄水位825米，相应库容206亿立方米，地下厂房装有16台机组，初拟装机容量1600万千瓦，多年平均发电量602.4亿千瓦时，已全部部署凝思安全操作系统</p>
			</div>
		</div>
		<div class="column noBorder">
			<div>
				<h1>业务应用</h1>
				<p>综合自动化管理后台（包括监控主机、操作员及工程师工作站、数据服务器、综合服务器、PMU、网分后台、远动机、故障录波等），功率预测、调峰辅助、AVC、AGC等</p>
			</div>
			<div>
				<h1>部署地区</h1>
				<p>西北、山西、江西、四川、河北、江苏、浙江、上海、北京、湖南等范围内的水电厂、火电厂、风电厂及光伏电厂</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'PowerGenerationGroup',
	}
</script>
<style scoped>

</style>
