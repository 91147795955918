<template>
	<div>
		<TopOfProducts :smallTitle="natittle2" :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainNoBottom">
			<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
			<SecureServer v-show="natittle3 == '凝思安全服务器平台'"></SecureServer>
		</div>
	</div>
</template>

<script>
import SecureServer from './SecureServer.vue';
export default {
	name:'SecureServerPlatform',
	components:{
		SecureServer
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '凝思安全服务器平台',
			natittle3: this.$route.query.producttitle,
			brief:'',
			brief1:[
				'服务器平台作为对外提供服务的门户，其自身的安全举足轻重。如果服务器平台遭受攻击，导致数据被篡改、敏感信息被窃取和非法利用，或者成为攻击系统内部网络的跳板，将严重影响到国家的信息安全。凝思软件基于凝思安全操作系统研发的多种安全服务器平台，提高了服务器系统自身的安全性和健壮性。',
				'凝思安全服务器平台是一款优秀的基础系统支撑平台，可以作为数据库服务、网页服务、邮件服务、文件共享服务、域名解析服务和CA服务等业务系统的基础支撑平台。',
			],
		}
	},
    watch:{
        $route:{
            immediate:true,
            handler(){
                this.natittle3 =  this.$route.query.producttitle;
                /*if(this.$route.query.producttitle == '安全服务器平台'){
                    this.brief = this.brief1;
                }*/
            }
        }
    }
}
</script>
<style scoped>

</style>
