<template>
<div class="imgCode" @click="refreshCode">
    <canvas id="s-canvas" :width="contentWidth" :height="contentHeight"></canvas>
    <p>点击图片刷新</p>
</div>
</template>
<script>
export default {
    name: 'SIdentify',
    props: {
        identifyCode: {type: String,default: '123456'}, // 生成的随机字符传
        fontSizeMin: {type: Number,default: 18},
        fontSizeMax: {type: Number,default: 32},
        backgroundColorMin: {type: Number,default: 235},
        backgroundColorMax: {type: Number,default: 255},
        colorMin: {type: Number,default: 0},
        colorMax: {type: Number,default: 180},
        lineColorMin: {type: Number,default: 80},
        lineColorMax: {type: Number,default: 230},
        dotColorMin: {type: Number,default: 160},
        dotColorMax: {type: Number,default: 255},
        contentWidth: {type: Number,default: 80},
        contentHeight: {type: Number,default: 38}
    },
    methods: {
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        randomColor(min, max) {
            let r = this.randomNum(min, max)
            let g = this.randomNum(min, max)
            let b = this.randomNum(min, max)
            return 'rgb(' + r + ',' + g + ',' + b + ')'
        },
        drawPic() {
            let canvas = document.getElementById('s-canvas')
            let ctx = canvas.getContext('2d')
            ctx.textBaseline = 'bottom'
            ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax)
            ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
            for (let i = 0; i < this.identifyCode.length; i++) {
                this.drawText(ctx, this.identifyCode[i], i)
            }
            this.drawLine(ctx)
            this.drawDot(ctx)
        },
        drawText(ctx, txt, i) {
            ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax)
            ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei'
            let x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
            let y = this.randomNum(this.fontSizeMax, this.contentHeight - 5)
            var deg = this.randomNum(-30, 30)
            ctx.translate(x, y)
            ctx.rotate(deg * Math.PI / 180)
            ctx.fillText(txt, 0, 0)
            ctx.rotate(-deg * Math.PI / 180)
            ctx.translate(-x, -y)
        },
        drawLine(ctx) {
            for (let i = 0; i < 6; i++) {
                ctx.strokeStyle = this.randomColor(this.lineColorMin, this.lineColorMax)
                ctx.beginPath()
                ctx.moveTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
                ctx.lineTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
                ctx.stroke()
            }
        },
        drawDot(ctx) {
            for (let i = 0; i < 60; i++) {
                ctx.fillStyle = this.randomColor(this.dotColorMin, this.dotColorMax)
                ctx.beginPath()
                ctx.arc(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight), 1, 0, 2 * Math.PI)
                ctx.fill()
            }
        },
        refreshCode(){
            this.$emit("refreshCode");
        }
    },
    mounted() {
        this.drawPic()
    },
    watch: {
        identifyCode: {
            deep: true,
            handler() {
                this.drawPic()
            }
        }
    }
}
</script>
<style scoped>
.imgCode p {
    font-size: 10px;
    font-weight: 300;
    line-height: 18px;
    color: #c2c2c2;
}
.imgCode #s-canvas {
    border: 1px solid #ebebeb;
    border-radius: 2px;
}
</style>