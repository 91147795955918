<!-- 首页-生态合作 -->
<template>
    <div class="box" id="ecologica">
        <div class="title-font1" id="title-font1-box5">生态合作
            <div></div>
        </div>
        <div id="grid">
            <router-link
                v-for="(ecologica,index) in ecologicalList"
                :key="index"
                class="card"
                :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}"
                data-wow-duration="1.2s" :data-wow-delay="index*0.3 + 's'" data-wow-offset="150"  data-wow-iteration="1"
                :to="ecologica.link"
            >
                <div>
                	<img :src="ecologica.imgUrl" :alt="ecologica.imgAlt">
                </div>
                <h1>{{ecologica.tittle}}</h1>
                <p v-html="ecologica.text"></p>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EcologicalCooperation2',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
            ecologicalList:[
                {
                    imgUrl:require('./images/生态合作-合作价值.png'),
                    imgAlt:'生态合作-合作价值',
                    tittle:'合作价值',
                    text:'凝思软件将积极开展与上下游厂商全方位的生态合作。',
                    link:'/EcologicalCooperation/CooperativeValue'
                },
                {
                    imgUrl:require('./images/生态合作-生态实验室.png'),
                    imgAlt:'生态合作-生态实验室',
                    tittle:'生态实验室',
                    text:'凝思软件自建生态实验室，旨在为客户提供完整的国产化<br>解决方案。',
                    link:'/EcologicalCooperation/EcologicalLaboratory'
                },
                {
                    imgUrl:require('./images/生态合作-兼容列表.png'),
                    imgAlt:'生态合作-兼容列表',
                    tittle:'兼容列表',
                    text:'凝思软件长期致力于与众多合作伙伴合力打造更可靠的国产软硬件环境。',
                    link:'/EcologicalCooperation/CompatibleList'
                },
            ]
        }
    },
    mounted() {
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
	}
}
</script>

<style scoped>
/*pc*/
#ecologica{
	background: url(./images/生态合作下点阵-pc.png) no-repeat right 130px;
}
#grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(1170px / 3));
    align-items: center;
    justify-content: space-around;
    width: 1170px;
    margin: 0 auto;
}
#grid .card{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	position: relative;
    margin: 0 auto;
    background-color:#fff;
    width: 360px;
    height:100%;
    box-shadow: 0px 0px 15px 0px rgba(4,4,5,0.08);
    z-index: 1;
    cursor: pointer;
}
#grid .card div{
    width: 100%;
    height: 252px;
    position: relative;
    overflow:hidden;
}
#grid .card div img{
	position: absolute;
    left: 50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    height: 252px;
    transition:all 0.3s ease-in-out;
}
#grid .card:last-child div img{
    left: calc(50% + 8px);
}
#grid .card:hover div img{
    height: 272px;
}
#grid .card div:after{
	content:" ";
	display:block;
	position: absolute;
    width:100%;
    height:252px;
    top:0;
    background-color:#040405;
    opacity:0;
    transition:all 0.3s ease-in-out;
}
#grid .card:hover div:after{
    opacity:0.2;
}
#grid .card h1:before{
	content:" ";
	display:block;
	position: absolute;
    width:2px;
    height:52px;
    top:-26px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #dd1d29;
}
#grid .card h1{
	position: relative;
	padding-top:39px;
    width:100%;
    color: #262b33;
    font-size: 22px;
    text-align:center;
    letter-spacing:0px;
    font-weight: 500;
    line-height: 64px;
}
#grid .card p{
	width:208px;
    margin:0 auto 55px;
    color: #565d69;
    font-size: 16px;
    text-align:center;
    letter-spacing:0px;
    font-weight: 300;
    line-height: 28px;
}

/*pad横屏*/
@media only screen and (max-width:1170px){
    #ecologica{
		background: url(./images/生态合作下点阵-pad.png) no-repeat right 80px;
	}
    #grid {
        grid-template-columns: repeat(auto-fill, 311px);
        width: 933px;
    }
    #grid .card{
		width: 283px;
	}
	#grid .card div{
		height: 198px;
	}
	#grid .card div img{
		height: 198px;
	}
	#grid .card:last-child div img{
		left: calc(50% + 6px);
	}
	#grid .card:hover div img{
		height: 218px;
	}
	#grid .card h1{
		padding-top:38px;
		font-size: 22px;
		line-height: 62px;
	}
	#grid .card p{
		width:192px;
		margin:0 auto 54px;
		font-size: 16px;
		line-height: 24px;
	}
	#grid .card p >>> br{
		display:none;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
    #grid {
        grid-template-columns: repeat(auto-fill, 374px);
        grid-gap: 32px 0px;
        width: 748px;
    }
    #grid .card{
		width: 346px;
	}
	#grid .card:last-child{
		grid-column-start: 1;
		grid-column-end: 3;
	}
	#grid .card div{
		height: 242px;
	}
	#grid .card div img{
		height: 242px;
	}
	#grid .card:last-child div img{
		left: calc(50% + 8px);
	}
	#grid .card:hover div img{
		height: 262px;
	}
	#grid .card h1{
		padding-top:38px;
		font-size: 22px;
		line-height: 62px;
	}
	#grid .card p{
		width:208px;
		margin:0 auto 54px;
		font-size: 16px;
		line-height: 24px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
    #ecologica{
		background: url(./images/生态合作下点阵-手机端.png) no-repeat calc(100vw - 146px - 6px) 112px;
		background-size:146px auto;
		background-color:#f7f7f7;
	}
   #grid {
        grid-template-columns: 317px;
        grid-gap: 29px 0px;
        width: 317px;
    }
    #grid .card{
		width: 317px;
	}
	#grid .card:last-child{
		grid-column-start: 1;
		grid-column-end: 2;
	}
	#grid .card div{
		height: 211.5px;
	}
	#grid .card div img{
		height: 211.5px;
	}
	#grid .card:last-child div img{
		left: calc(50%);
	}
	#grid .card:hover div img{
		height: 231.5px;
	}
	#grid .card h1{
		padding-top:41px;
		font-size: 18px;
		line-height: 56px;
	}
	#grid .card p{
		width:196px;
		margin:0 auto 57px;
		font-size: 14px;
		line-height: 20px;
	}
}
/*手机端1080
@media only screen and (min-height:1300px){
    #ecologica{
		background: url(./images/生态合作下点阵-手机端1080.png) no-repeat right 170px;
		background-color:#f7f7f7;
	}
    #grid {
        grid-template-columns: repeat(auto-fill, 992px);
        width: 992px;
    }
    #grid .card{
        width: 910px;
        height: 525px;
        margin-bottom: 72px;
        border:1px solid #ebebeb;
        box-shadow: 0px 0px 0px 0px rgba(5,5,5,0.07);
    }
    #grid .card img{
        top:74px;
        height: 94px;
    }
    #grid .card h1{
        top:243px;
        font-size: 52px;
        line-height: 52px;
    }
    #grid .card p{
        top:340px;
        width:680px;
        font-size: 40px;
        font-weight: 300;
        line-height: 58px;
    }
    #grid .card a,#grid .card a:hover{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border:1px solid rgba(5,5,5,0);
        transition: box-shadow 0.3s;
        border-radius: 0px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
    }
}*/
</style>
