<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>    
		<div class="font" v-show="title4 == 0">
			<h1>
				<img :src="icon" alt="item.tittle">
				<span>{{title4List[title4].tittle}}</span>
			</h1>
			<p>北京凝思软件股份有限公司拥有一支专业的技术支持和服务团队，可以为客户提供技术咨询、工程实施、技术支持、专业培训、运维保障等多项服务，并可根据客户的要求提供操作系统开发版和运行版定制。</p>
			<p>在产品质保期内和维保合同期内，凝思软件根据合同规定向客户提供运维基本服务。</p>
			<p>除合同规定的基本服务之外，凝思软件还可提供其它的服务，以满足客户在特定情况下提出的一些特殊的服务需求。</p>
        </div>
		<div class="font" v-show="title4 == 1">
			<h1>
				<img :src="icon" alt="item.tittle">
				<span>{{title4List[title4].tittle}}</span>
			</h1>
			<div class="serviceDiv">
				<h2>电话及邮件支持服务</h2>
				<p>凝思软件设有客户服务热线，提供全天候无间断的产品技术咨询、故障申报、培训需求受理、以及服务政策咨询等服务内容。客服对所有来电进行实时响应，技术工程师对故障问题实时受理，并对服务进行全程记录和跟踪并存档。</p>
				<h4>电话及电子邮件服务的提供时间为:365天，7*24小时。</h4>
			</div>
			<div class="serviceDiv">
				<h2>远程服务</h2>
				<p>凝思软件提供远程网络联机调试服务，技术人员在用户和相关厂商的配合下，远程登录到用户系统上查看并诊断分析现场状况，指导用户排除故障。</p>
				<p>远程网络联机调试服务的提供时间为工作时间，周一到周五，每日9:00-17:30。</p>
				<h4>客户服务邮件 : support@linx-info.com</h4>
			</div>
			<div class="serviceDiv">
				<h2>现场技术服务</h2>
				<p>现场技术服务</p>
				<h4>电话服务热线:010-82624179 (9:00-17:30) 400-690-8128 (0:00-24:00)</h4>
			</div>
			<div class="serviceDiv">
				<h2>软件升级</h2>
				<p>在服务有效期内，凝思软件通过网络下载、光盘介质寄送的方式向客户提供凝思安全操作系统的各个升级版本，及针对缺陷的补丁软件、特定软件包的升级版本，以及该配套的文档资料。获得此类升级软件后，客户将享有与原有软件相同的许可权利，但不得用于商业目的的传播。</p>
				<p>具有特殊功能并单独销售的软件不在提供范围以内。使用License控制与销售的软件产品，只提供软件补丁，不提供新的License或新版本的软件本身。</p>
			</div>
			<div class="serviceDiv">
				<h2>版本使用</h2>
				<p>作为操作系统使用客户，除了可获得软件补丁、升级及相关配套文档资料外，凝思软件技术专家团队还将根据客户的设备运行情况、技术特性、软件新增功能来建议客户是否升级。</p>
				<p>在有软件补丁或新版本软件发布时，客户无需担心没有得到及时升级。客户也可提出其想法，并可通过客户服务经理联系凝思软件专家团队进行讨论。</p>
			</div>
			<div class="serviceDiv">
				<h2>编制故障处理手册</h2>
				<p>凝思软件建立有电子化故障和缺陷管理系统，该系统用于故障的受理、解决、跟踪、检测和记录，每个故障在处理完成后都可汇总到常见问题库中，凝思将根据具体情况会同用户，编制运维手册。手册将涵盖用户单位目前的常用软件和业务，将常见故障的解决和防范进行汇编，提高运维人员的故障处理速度和能力。</p>
			</div>
			<div class="serviceDiv">
				<h2>应急备用方案</h2>
				<p>针对项目特点，凝思软件在用户系统硬件提供单位和应用软件提供单位的配置支持下，可提供以下应急备用方案:</p>
				<p>在用户系统系统配置、软件版本以及相关配置定型后，凝思软件可针对用户系统对操作系统的安装、配置进行定制化，有效实现操作系统安装、配置的自动化。在用户系统硬件提供单位和应用软件提供单位的配合支持下，可快速、准确地对用户系统中的故障节点进行重新安装、配置，用户在报修24小时之内恢复用户关键业务系统正常运行。</p>
			</div>
			<div class="serviceDiv">
				<h2>新业务技术支持</h2>
				<p>用户单位在运行维护和业务开展中遇到的各种问题，都可向凝思软件进行技术咨询。凝思软件将根据多年专业的工程技术经验为用户提供解答。在客户方需要进行软硬件升级时，凝思软件技术专家团队还将根据客户的软硬件设备运行情况、硬件技术特性、软件新增功能的具体情况提供给解决方案。客户可根据解决方案内容要求凝思软件提供所需的软件更新、软件补丁、升级软件等基本服务，或相应的服务及相关配套文档资料。</p>
			</div>
			<div class="serviceDiv">
				<h2>技术咨询和支持</h2>
				<p>凝思软件为客户提供凝思安全操作系统环境下的开发相关技术咨询，咨询方式为电话和邮件支持。</p>
			</div>
			<div class="serviceDiv">
				<h2>重大故障会诊</h2>
				<p>对于设备故障，无论硬件平台或者中间件、安全操作系统 、上层应用发生问题，凝思软件都将提供会诊服务支持，对于重大故障将组成临时的技术小组进行会诊，以恢复业务为第一原则。故障解决后，凝思将会同友商详细分析故障原因并回顾处理过程，形成故障分析报告，递交用户单位。</p>
			</div>
		</div>
		<div class="font" v-show="title4 == 2">
			<h1>
				<img :src="icon" alt="item.tittle">
				<span>{{title4List[title4].tittle}}</span>
			</h1>
			<div class="serviceDiv">
				<h2>系统支持非通用硬件应用场景</h2>
				<p>当客户需要在非通用硬件上部署系统和应用，且该非通用硬件从未与凝思安全操作系统做过适配和兼容性认证，凝思软件可以为支持此类应用场景提供系统研发级的服务。</p>
			</div>
			<div class="serviceDiv">
				<h2>系统在非通用硬件上部署的工程实施</h2>
				<p>当客户需要在非通用硬件上部署系统和应用时，凝思软件可为支持此类应用场景提供工程实施服务。</p>
			</div>
			<div class="serviceDiv">
				<h2>实施在线扩容</h2>
				<p>在某些特定的条件下为客户实施在线扩容。</p>
			</div>
			<div class="serviceDiv">
				<h2>系统搬迁割接</h2>
				<p>应客户要求提供系统搬迁后的割接服务。</p>
			</div>
        </div>
		<div class="font" v-show="title4 == 3">
			<h1>
				<img :src="icon" alt="item.tittle">
				<span>{{title4List[title4].tittle}}</span>
			</h1>
			<div class="tittle3">
				<a @click="ptitle=0" class="celltittle3" :class="{intittle3:ptitle==0}">服务申请流程结构图</a>
				<a @click="ptitle=1" class="celltittle3" :class="{intittle3:ptitle==1}">服务申请流程图说明</a>
				<a @click="ptitle=2" class="celltittle3" :class="{intittle3:ptitle==2}">技术支持工作流程图</a>
				<a @click="ptitle=3" class="celltittle3" :class="{intittle3:ptitle==3}">技术支持流程图说明</a>
			</div>
			<div v-show="ptitle==0">
				<img src="../images/服务流程.png" alt="服务流程" class="imgBlock">
			</div>
			<div v-show="ptitle==1">
				<h2>基本结构概况</h2>
				<p>客户应通过发电子邮件，传真或电话提交客户服务申请（客服申请）的方式向客户服务热线反映所有的问题。客户服务热线立即将客服申请发给技术支持部门。技术支持部门的一线支持负责为所反映的问题提供解决方案，或将问题上报给二线支持。解决问题后，技术支持部门将把答复发给客户服务热线，客户服务热线再将最终答复发给客户。</p>
				<h2>客户服务热线</h2>
				<p>客户服务热线是客户向凝思软件反映所有问题的接口，同时也负责监督所有客服申请的处理进程。</p>
				<p>当客户向客服热线发出客服请求时，客服热线会核对发件人，用户代码，优先级别，与客户签订的服务合同，以及该服务申请的描述。 如果有任何信息不详，客服热线会向客户要求更多信息。 在得到所有信息后，客服热线将该客服申请输入客户服务申请数据库，并将该申请的状态设置为＂新的申请＂，然后客服热线将参考客户为该客服申请分配的优先级别，与技术支持部门共同确认该申请的优先级别，并将最终确认发给客户。 然后，客服热线将该客服申请发给技术支持部门进行评估解决。</p>
				<p>当问题解决后，技术支持部门将把经客服申请答复小组审核认可的答复发给客服热线，客服热线将把最终答复发给客户，并将该客服申请的状态改为“解决”。在客户接受了最终答复，并发出确认，客服热线将把该客服申请的状态改为＂结束＂。</p>
			</div>
			<div v-show="ptitle==2">
				<img src="../images/技术支持流程.png" alt="技术支持流程" class="imgBlock">
			</div>
			<div v-show="ptitle==3">
				<h2>基本结构概况</h2>
				<p>技术支持工作流程过程分解为5个过程元素，分别是:<span>任务指派、故障确认、处置预案、故障排查与解决和结项与归档。</span></p>
				<p><span>任务指派</span>:技术支持经理接到客服申请后根据客服申请内容指定技术支持工程师。</p>
				<p><span>故障确认</span>:技术支持工程师向用户确认故障现象并详细记录下列内容:硬件平台序列号、硬件平台配置、操作系统版本号、用户 的操作过程、故障现象。</p>
				<p><span>处置预案</span>:技术支持工程师编写故障分析与处置预案、项目经理确认故障分析与处置预案。</p>
				<h2>故障排查与解决</h2>
				<p>技术支持工程师通过电话支持或现场解决的方式解决故障，并详细记录故障原因和解决方法;</p>
				<p>用户代表确认故障处置结果；</p>
				<p>项目经理确认故障处置结果;</p>
				<p>技术支持工程师填写纸质版《客户服务处理报告》。</p>
				<h2>结项与归档</h2>
				<p>技术支持部经理审查《客户服务处理报告》。</p>
				<p>部门接口人员将纸质版《客户服务处理报告》提交商务部签收并备案。</p>
				<p>归档人员对故障相关资料进行归档。</p>
			</div>
        </div>
	</div>
</template>

<script>
export default {
	name:'CustomDevelopment',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '服务流程',
			natittle3: '服务流程概况',
			title4: 0,
			icon: require('../images/icon-服务流程.png'),
			title4List: [
				{
					tittle:'服务流程概况',
				},
				{
					tittle:'基本服务'
				},
				{
					tittle:'服务'
				},
				{
					tittle:'服务说明'
				}
			],
			ptitle:0,
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
p span{
	color: #0072ce;
}
.serviceDiv{
	margin-top: 48px;
}
.serviceDiv:nth-child(2){
	margin-top: 24px;
}
.serviceDiv h2, .serviceDiv p{
	margin-top: 0;
	text-indent: 0em;
}
.serviceDiv h4{
	display: block;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 34px;
	color: #0072ce;
	text-indent: 0em;
	position: relative;
	margin: 0 32px;
}
.serviceDiv h2:before{
	display: inline-block;
    margin-right: 12px;
    width: 18px;
    height: 18px;
    background: url(../images/向右标记.png) no-repeat;
    background-size:18px;
    position: relative;
    top: 3px;
    content: '';
}
.tittle3{
	position: relative;
	width:calc(100% - 64px);
	padding: 2px 32px 0;
	background-color: #f5f5f5;
	white-space:nowrap;
	overflow-x: auto;
	overflow-y:visible;
	z-index:1;
}
.tittle3:before{
	position: absolute;
	content:'';
	display:block;
	width:100%;
	min-width:784px;
	height:1px;
	background-color:#e0e0e0;
	border-top:1px solid #fff;
	left:0;
	top:0px;
	z-index:1;
}
.tittle3 a{
	position: relative;
	display:inline-block;
	width:144px;
	padding:22px 0;
	margin-right:44px;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 20px;
	color: #262b33;
	cursor:pointer;
	overflow-y:visible;
	z-index:10;
}
.tittle3 a:hover:before,.tittle3 .intittle3:before{
	position: absolute;
	content:'';
	display:block;
	width:44px;
	height:2px;
	background-color:#dd1d29;
	left:0;
	top:-1px;
	z-index:999;
}
.intittle3{
	font-weight: 500!important;
}
.imgBlock{
	display: block;
	margin:44px auto;
	max-width:calc(100% - 64px);
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	.tittle3{
		margin-top:-12px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.tittle3{
		width: 100%;
		white-space:nowrap;
		padding: 0;
		padding-top: 3px;
		background-color: #f5f5f5;
		border-top: none;
	}
	.tittle3:before{
		width:100%;
		min-width:560px;
		height:1px;
		border-top:1px solid #fff;
		left:0;
		top:0;
		z-index:1;
	}
	.tittle3 a{
		margin: 0;
		padding: 11px 15px;
		font-size: 12px;
		width: 110px;
	}
	.tittle3 a:hover:before,.tittle3 .intittle3:before{
		position: absolute;
		content:'';
		display:block;
		width:22px;
		height:1.5px;
		background-color:#dd1d29;
		left:15px;
		top:-2px;
	}
	.imgBlock{
		width: calc(100% - 30px);
	}
	.serviceDiv h4{
		font-size: 14px;
		line-height: 23px;
		margin: 0 16px;
	}
}
</style>
