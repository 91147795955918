<template>
    <div class="featureBox">
        <div class="featureImage2">
            <div
                v-for="(feature11,index11) in feature"
                :key="index11 + '-1'" 
                @click="figureShow = index11"
                class="outOffeature"
                :class="{boxshadow:figureShow == index11}"
            ><p
                class="feature"
                :id="feature11.id"
                :class="{infeature:figureShow == index11}"
            >{{feature11.title}}</p></div>
        </div>
        <div class="featureImage featureImages6">
            <p
                v-for="(feature21,index21) in feature.slice(0,6)"
                :key="index21 + '-2'" 
                class="feature"
                :id="feature21.id"
                @click="figureShow = index21"
                :class="{infeature:figureShow == index21}"
            >{{feature21.title}}</p>
        </div>
        <div class="featureText">
            <div
                class="text"
                v-for="(feature3,index3) in feature"
                :key="index3 + '-4'"
                v-show="figureShow == index3"
            >
                <h4>{{feature3.title}}</h4>
                <p v-for="(p,indext) in feature3.text" :key="indext" v-html="p"></p>
            </div>
        </div>
        <div class="featureImage featureImages6">
            <p
                v-for="(feature22,index22) in feature.slice(6,12)"
                :key="index22 + '-3'" 
                class="feature"
                :id="feature22.id"
                @click="figureShow = (index22 + 6)"
                :class="{infeature:figureShow == (index22 + 6)}"
            >{{feature22.title}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeatureBox6',
    data() {
        return {
            feature:this.$attrs.feature,
            figureShow:0,
        }
    },
}
</script>

<style scoped>
</style>