/* News仓库 */
const state = {
	honorList: [
		{
			// 公司资质,
			text: [
		    		{
					imgUrl: require('../../images/honor/高新技术企业证书.png'),
					imgAlt:'高新技术企业证书',
					class:'icontent-mid'
		    		},
		    		{
					imgUrl: require('../../images/honor/软件企业证书（北京软协）.png'),
					imgAlt:'凝思软件-软件企业证书（北京软协）',
					class:'icontent-mid'
		    		},
		    		{
					imgUrl: require('../../images/honor/软件企业证书（中国软协）.png'),
					imgAlt:'凝思软件-软件企业证书（中国软协）',
					class:'icontent-mid'
		    		},
		    		{
					imgUrl: require('../../images/honor/凝思软件IS9001.png'),
					imgAlt:'凝思软件ISO9001证书',
					class:'icontent-lar'
		    		},
		    		{
					imgUrl: require('../../images/honor/CMMI5.jpg'),
					imgAlt:'凝思软件-CMMI五级证书',
					class:'icontent-lar'
		    		},
		    		{
					imgUrl: require('../../images/honor/北京凝思软件股份有限公司IT(1)-1.png'),
					imgAlt:'凝思软件ISO27001证书',
					class:'icontent-lar'
		    		},
		    		{
					imgUrl: require('../../images/honor/北京凝思软件股份有限公司IT(1)-3.png'),
					imgAlt:'凝思软件ISO20000证书',
					class:'icontent-lar'
		    		},
		    		{
					imgUrl: require('../../images/honor/知识产权管理体系认证证书-中文.png'),
					imgAlt:'知识产权管理体系认证证书',
					class:'icontent-lar'
		    		},
		    		{
					imgUrl: require('../../images/honor/凝思软件-中关村高新技术企业.jpg'),
					imgAlt:'凝思软件-中关村高新技术企业',
					class:'icontent-mid'
		    		},
			]
		},
		{
			// 产品资质,
			text:[
		    	{
					imgUrl: require('../../images/honor/计算机信息系统安全专用产品销售许可证-凝思安全操作系统V6.0-安全操作系统四级.jpg'),
					imgAlt:'计算机信息系统安全专用产品销售许可证-凝思安全操作系统V6.0-安全操作系统四级',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/3C认证（中）2017.png'),
					imgAlt:'3C认证',
					class:'icontent-lar'
		    	},
		    	{	
					imgUrl: require('../../images/honor/产品认证证书.jpg'),
					imgAlt:'产品认证证书',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/计算机信息系统安全专用产品销售许可证-凝思安全云桌面系统V1.0-桌面云系统（行标-基本级）.jpg'),
					imgAlt:'计算机信息系统安全专用产品销售许可证-凝思安全云桌面系统V1.0-桌面云系统（行标-基本级）',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/信息技术产品安全测试证书-凝思安全云桌面系统V1.0.jpg'),
					imgAlt:'信息技术产品安全测试证书-凝思安全云桌面系统V1.0',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/信息技术产品安全测试证书-凝思安全操作系统V6.0.jpg'),
					imgAlt:'信息技术产品安全测试证书-凝思安全操作系统V6.0',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/凝思安全操作系统V6.0等保四级测试报告（公计检202101391）.jpg'),
					imgAlt:'凝思安全操作系统V6.0等保四级测试报告（公计检202101391）',
					class:'icontent-lar'
		    	},
				{
					imgUrl: require('../../images/honor/服务器密码机产品资质证书.jpg'),
					imgAlt:'服务器密码机产品资质证书',
					class:'icontent-lar'
		    	},
		    	{
					imgUrl: require('../../images/honor/凝思安全操作系统V6.0.90（北京软协）.png'),
					imgAlt:'软件产品证书-凝思安全操作系统V6.0.90（北京软协）',
					class:'icontent-mid'
		    	},
		    	{
					imgUrl: require('../../images/honor/凝思安全操作系统V6.0.90（中国软协）.png'),
					imgAlt:'软件产品证书-凝思安全操作系统V6.0.90（中国软协）',
					class:'icontent-mid'
		    	},
			]
		},
		// {
		// 	// 专利证书,
		// 	text: [
		//     	{
		// 			imgUrl: require('../../images/honor/发明专利证书-系统层DNS防护方法.png'),
		// 			imgAlt:'发明专利证书-系统层DNS防护方法',
		// 			class:'icontent-lar'
		//     	},
		//     	{
		// 			imgUrl: require('../../images/honor/专利-中文地址解析.png'),
		// 			imgAlt:'专利-中文地址解析',
		// 			class:'icontent-lar'
		//     	},
		//     	{
		// 			imgUrl: require('../../images/honor/专利证书-基于紧耦合规则的可信验证方法、装置、存储介质和电子设备.png'),
		// 			imgAlt:'专利证书-基于紧耦合规则的可信验证方法、装置、存储介质和电子设备',
		// 			class:'icontent-lar'
		//     	},
		//     	{
		// 			imgUrl: require('../../images/honor/基于标记的进程强制行为控制方法和系统.png'),
		// 			imgAlt:'基于标记的进程强制行为控制方法和系统',
		// 			class:'icontent-lar'
		//     	},
		// 	]
		// },
		{
			// 公司荣誉,
			text: [
		    	{
					imgUrl: require('../../images/honor/国家级专精特新”小巨人“企业铜牌.jpg'),
					imgAlt:'第四批国家级专精特新<br>“小巨人”企业',
					class:'icontent-mid'
		    	},
		    	{
					imgUrl: require('../../images/honor/河南抗洪捐款证书.png'),
					imgAlt:'2021年河南省抗洪救灾捐赠证书',
					class:'icontent-mid'
		    	},
		    	{
					imgUrl: require('../../images/honor/北京市”小巨人“.png'),
					imgAlt:'2021年度北京市专精特新“小巨人”企业',
					class:'icontent-mid'
		    	},
		    	{
					imgUrl: '',
					imgAlt:'工信部2021年<br>物联网示范项目',
					class:'icontent-mid'
		    	},
			]
		}
	]
};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
