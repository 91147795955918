<!-- 关于凝思 -->
<template>
    <div>
		<TopOfPage :tittle="tittle" :path="path"></TopOfPage>
		<router-view :tittle="tittle"></router-view> 
    </div>
</template>

<script>
export default {
    name: 'AboutLinx',
	data() {
		return {
			tittle: "关于凝思",
			path: "/AboutLinx"
		}
	},

}
</script>

<style scoped>
</style>
