import { render, staticRenderFns } from "./Offices.vue?vue&type=template&id=06ced1eb&scoped=true"
import script from "./Offices.vue?vue&type=script&lang=js"
export * from "./Offices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ced1eb",
  null
  
)

export default component.exports