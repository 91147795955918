<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="textBox">
				<p>示意图如下所示:</p>
				<img v-show="bodyWidthMut > 750" src="./images/分布式.png"/>
				<img v-show="bodyWidthMut <= 750" src="./images/分布式2.png"/>
			</div>
			<div class="textBox textBoxBackground">
				<h2>系统特性</h2>
				<!--<FeatureBox6 :feature="feature"></FeatureBox6>-->
				<div class="featureBox">
					<div class="featureText5">
						<div>
							<div class="text">
								<h4>高可用性</h4>
								<p>文件系统的所有节点均存在一个或多个备份节点，无单点故障问题。根据配置，同一文件数据可以在凝思分布式文件系统中多副本冗余存储，多个副本分布于不同的数据服务器中，多台数据服务器均可提供文件访问服务。如果其中一台或多台数据服务器出现故障，凝思分布式文件系统会在其它可用的数据服务器中创建该文件的新副本，使系统中文件的副本数与配置文件中设定的副本数保持一致。</p>
							</div>
							<div class="text">
								<h4>动态可扩展</h4>
								<p>可以根据需要，动态调整数据服务器的数目。无需停止或重启系统服务进程，实现对存储容量的在线动态扩容。</p>
							</div>
							<div class="text">
								<h4>易用</h4>
								<p>元数据管理服务器和数据服务器的配置使用简单易用。在相关的配置文件中，所有的配置选项以显式的方式给出默认配置，并提供相关配置选项的简要注释说明。用户可通过Web图形管理界面，对系统状态进行直观查看和实时准确的监控。</p>
							</div>
						</div>
						<div>
							<div class="text">
								<h4>通用性</h4>
								<p>文件系统所保存的文件具有POSIX规定的访问权限和访问时间等文件属性。文件管理采用目录树类型的层级结构，结构清晰，便于管理。系统支持块设备文件、字符设备文件、管道和套接字等特殊文件，支持符号链接和硬链接。</p>
							</div>
							<div class="text">
								<h4>高效</h4>
								<p>所有文件的管理信息均缓存至管理服务器的内存中，当客户端访问文件时，管理服务器可以快速定位文件的信息。相对于传统的NFS共享文件服务，凝思分布式文件系统有着更高的稳定性和效率。</p>
							</div>
							<div class="text">
								<h4>高安全</h4>
								<p>利用完善的访问控制机制有效管理资源。删除文件所使用的回收站、文件快照功能，最大程度地保证存储数据的完整性和可恢复性。底层平台基于凝思安全操作系统，通过强制能力控制机制对应用系统实施最小授权；强制访问控制机制保护文件系统数据的安全性；强制行为控制机制防止系统遭受代码注入攻击。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'Distributed',
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			feature:[
        		{
					id:'feature18',
        			title:'高可用性',
        			text:[
        				'文件系统的所有节点均存在一个或多个备份节点，无单点故障问题。根据配置，同一文件数据可以在凝思分布式文件系统中多副本冗余存储，多个副本分布于不同的数据服务器中，多台数据服务器均可提供文件访问服务。如果其中一台或多台数据服务器出现故障，凝思分布式文件系统会在其它可用的数据服务器中创建该文件的新副本，使系统中文件的副本数与配置文件中设定的副本数保持一致。',
        			],
        		},
        		{
					id:'feature19',
        			title:'动态可扩展',
        			text:[
        				'可以根据需要，动态调整数据服务器的数目。无需停止或重启系统服务进程，实现对存储容量的在线动态扩容。',
        			],
        		},
        		{
					id:'feature20',
        			title:'易用',
        			text:[
        				'元数据管理服务器和数据服务器的配置使用简单易用。在相关的配置文件中，所有的配置选项以显式的方式给出默认配置，并提供相关配置选项的简要注释说明。用户可通过Web图形管理界面，对系统状态进行直观查看和实时准确的监控。',
        			],
        		},
        		{
					id:'feature21',
        			title:'通用性',
        			text:[
        				'文件系统所保存的文件具有POSIX规定的访问权限和访问时间等文件属性。文件管理采用目录树类型的层级结构，结构清晰，便于管理。系统支持块设备文件、字符设备文件、管道和套接字等特殊文件，支持符号链接和硬链接。',
        			],
        		},
        		{
					id:'feature22',
        			title:'高效',
        			text:[
        				'所有文件的管理信息均缓存至管理服务器的内存中，当客户端访问文件时，管理服务器可以快速定位文件的信息。相对于传统的NFS共享文件服务，凝思分布式文件系统有着更高的稳定性和效率。',
        			],
        		},
        		{
					id:'feature23',
        			title:'高安全',
        			text:[
        				'利用完善的访问控制机制有效管理资源。删除文件所使用的回收站、文件快照功能，最大程度地保证存储数据的完整性和可恢复性。底层平台基于凝思安全操作系统，通过强制能力控制机制对应用系统实施最小授权；强制访问控制机制保护文件系统数据的安全性；强制行为控制机制防止系统遭受代码注入攻击。',
        			],
        		},
    		],
    	}
	},
	mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
}
</script>
<style scoped>

</style>
