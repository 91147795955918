<template>
	<transition name="fade">
		<div class="img-view" @click="bigImg">
			<div class="img-layer"></div>
			<div class="modal">
				<img :src="imgSrc">
				<p>{{imgAlt}}</p>
			</div>
		</div>
	</transition>
</template>
<script>
	export default {
		name:'BigImg',
		props: ['imgSrc','imgAlt'],
		methods:{
			bigImg(){
				this.$emit('clickit')
			}
		}
	}
</script>
<style scoped>
/*bigimg*/
.img-view{
	position: relative;
	width: 100%;
	height: 100%;
}
/*遮罩*/
.img-view .img-layer{
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	background: rgb(255,255,255,0.95);
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.img-view .img-layer:after{
	position:fixed;
	top: 2vw;
	right:2vw;
	width: 44px;
	height: 44px;
	content: '';
	background: url(../images/关闭.png) no-repeat center center;
	background-size:15px;
	cursor:pointer;
}
.img-view .modal{
	position: fixed;
	max-width: 800px;
	top: 50%;
	left:50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	margin: auto;
	z-index:100000;
}
.img-view .modal img{
	display: block;
	margin: 0 auto;
	max-width: calc(100% - 34px);
	max-height: 80vh;
	padding:16px;
	background-color: #f7f7f7;
	border: 1px solid #e0e0e0;
}
.img-view .modal p{
	max-width: 60%;
	margin: 7px auto 0;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #262b33;
	text-indent: 0em;
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.img-view .modal{
		max-width: 90vw;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.img-view .img-layer:after{
		background-size:15px;
	}
	.img-view .modal{
		max-width: 90vw;
	}
	.img-view .modal img{
	max-width: 80vw;
	max-height: 80vh;
	}
	.img-view .modal p{
		max-width: 80%;
		margin: 8px auto 0;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #262b33;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.img-view .img-layer:after{
		width: 104px;
		height: 104px;
		background-size:39px;
	}
	.img-view .modal{
		max-width: 90vw;
	}
	.img-view .modal img{
	max-width: 80vw;
	max-height: 80vh;
	}
	.img-view .modal p{
		max-width: 80%;
		margin: 15px auto 0;
		text-align: center;
		font-size: 40px;
		font-weight: 400;
		line-height: 60px;
		color: #262b33;
	}
} */
</style>
