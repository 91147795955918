<!-- 产品中心页面顶部 -->
<template>
    <div :id="this.$attrs.natittle3 == '产品中心' ? 'topPage1' : 'topPage2'">
    	<template v-if="this.$attrs.natittle3 == '产品中心'">
		    <div id="title1">{{this.$attrs.natittle3}}</div>
		    <div id="under-title1"><div></div></div>
		    <p>与世界技术发展同步</p>
		    <p>打造安全自主可控的基础软件平台<span class="twinkle"></span></p>
        </template>
        <template v-if="this.$attrs.natittle3 != '产品中心'">
		    <h1>{{this.$attrs.smallTitle}}</h1>
		    <h2>{{this.$attrs.natittle3}}</h2>
		    <p v-for ="(briefp,index) in this.$attrs.brief" :key="index" v-html="briefp" :class="{nowrap1:nowrap}"></p>
		</template>
    </div>
</template>

<script>
export default {
    name: 'TopOfProducts',
    data() {
        return {
            brief:this.$attrs.brief,
            nowrap: false,
        }
    },
    methods:{
        setnowrap(){
        	if(this.$attrs.natittle3 == '凝思分布式文件系统'){
        		this.nowrap = true;
        	}
        }
    },
    mounted() {
		this.briefLength = this.brief.length;
	}
}
</script>

<style scoped>
#topPage1{
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 124px;
    z-index: 1;
    padding-bottom: 125px;
	background: url("./images/产品中心背景图.png") no-repeat center center;
}
#topPage1 #title1{
    width: 1170px;
    color: #fff;
    font-size: 40px;
    letter-spacing:2px;
    margin: 0 auto;
    font-weight: 500;
}
#topPage1 #under-title1{
    height: 69px;
    width: 1170px;
    margin: 0 auto;
    padding-top: 26px;
}
#topPage1 #under-title1 div{
    width: 40px;
    height: 4px;
    background-color: #cbcbcb;
}
#topPage1 p{
    width: 1170px;
    color: #fff;
    font-size: 24px;
    line-height:38px;
    letter-spacing:1px;
    margin: 0 auto;
    font-weight: 500;
}
#topPage1 p span{
	display:inline-block;
    width: 15px;
    height: 2px;
    margin-left:5px;
    margin-bottom: -2px;
    background-color: #fff;
    transition:opacity 0.05s;
}
.twinkle{
	animation-name: flash; 
  	animation-duration: 2s; 
	animation-timing-function:ease;
	  animation-iteration-count :infinite;
}

#topPage2{
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 146px;
    z-index: 1;
    padding-bottom: 30px;
}
#topPage2 h1{
    width: 1170px;
    color:#dd1d29;
    font-size: 18px;
    line-height:18px;
    letter-spacing:0px;
    margin: 0 auto;
    font-weight: 400;
}
#topPage2 h2{
    width: 1170px;
    color:#141414;
    font-size: 36px;
    line-height:96px;
    letter-spacing:1px;
    margin: 0 auto 2px;
    font-weight: 500;
}
#topPage2 p{
    width: 648px;
    color:#141414;
    font-size: 18px;
    line-height:30px;
    letter-spacing:0px;
    margin-left: calc(50% - 585px);
    font-weight: 400;
}
#topPage2 p >>> span{
    color:#0072ce;
}
#topPage2 p >>> h{
	white-space:nowrap;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#topPage1{
		padding-top: 160px;
		padding-bottom: 132px;
		background: url("./images/产品中心背景图.png") no-repeat -94px -299px
	}
	#topPage1 #title1{
		width: calc(100% - 120px);
		font-size: 36px;
		letter-spacing:2px;
		margin: 0 auto;
		font-weight: 500;
	}
	#topPage1 #under-title1{
		height: 53px;
		width: calc(100% - 60px);
		margin: 0 auto;
		padding-top: 26px;
		padding-left:60px;
	}
	#topPage1 #under-title1 div{
		height: 3px;
	}
	#topPage1 p{
		width: calc(100% - 120px);
		margin: 0 60px;
	}

	#topPage2{
		padding-top: 128px;
		padding-bottom: 34px;
	}
	#topPage2 h1{
		width: calc(100% - 120px);
		margin: 0 60px;
	}
	#topPage2 h2{
		width: calc(100% - 120px);
		line-height:84px;
		margin: 0 60px 2px;
	}
	#topPage2 p{
		width: 512px;
		font-size: 16px;
		line-height:28px;
		margin-left: 60px;
	}
	#topPage2 .nowrap1{
		white-space: nowrap;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#topPage1{
		width: 100%;
		padding-top: 221px;
		padding-bottom: 165px;
		background: url("./images/产品中心背景图.png") no-repeat -214px -196px;
	}
	#topPage1 #title1{
		width: calc(100% - 120px);
		padding:0;
	}
	#topPage1 #under-title1{
		height: 67px;
		width: calc(100% - 60px);
	}

	#topPage2 p{
		width: 402px;
	}
	#topPage2 .nowrap1:last-child{
		white-space: normal;
		width: 416px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){	
	#topPage1{
		width: 100%;
		padding-top: 297px;
		padding-bottom: 82.5px;
		background: url("./images/产品中心背景图.png") no-repeat -47px 0px;
	}
	#topPage1 #title1{
		width: calc(100% - 30px);
		font-size: 26px;
	}
	#topPage1 #under-title1{
		height: 25.5px;
		width: calc(100% - 30px);
		margin: 0 auto;
		padding-top: 19px;
		padding-left: 0;
	}
	#topPage1 #under-title1 div{
		width: 28px;
		height: 2px;
	}
	#topPage1 p{
		width: calc(100% - 30px);
		font-size: 18px;
		line-height:25px;
		margin: 0 auto;
	}
	#topPage1 p span{
		width: 11px;
		height: 1.5px;
		margin-left:1px;
	}

	#topPage2{
		width: 100%;
		padding-top: 92px;
		padding-bottom:  20.5px;
	}
	#topPage2 h1{
		width: calc(100% - 30px);
		margin: 0 auto;
		font-size: 14px;
		line-height:14px;
	}
	#topPage2 h2{
		width: calc(100% - 30px);
		font-size: 26px;
		line-height:48px;
		margin: 8.5px auto 0;
	}
	#topPage2 p{
		width: calc(100% - 30px);
		font-size: 14px;
		line-height:21px;
		margin-left: 15px;
	}
	#topPage2 .nowrap1 {
		white-space: normal;
		width: calc(100% - 30px);
	}
	#topPage2 .nowrap1 br{
		display:none;
	}
	#topPage2 .nowrap1:last-child {
		white-space: normal;
		width: calc(100% - 30px);
	}
	#topPage2 p >>> h{
		white-space:normal;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#topPage1{
		width: 100%;
		padding-top: 855px;
		padding-bottom: 242px;
		background: url("./images/产品中心背景图.png") no-repeat -135px 0px;
		background-size: 2762px 1552px;
	}
	#topPage1 #title1{
		width: calc(100% - 88px);
		font-size: 74px;
	}
	#topPage1 #under-title1{
		height: 82px;
		width: calc(100% - 88px);
		margin: 0 auto;
		padding-top: 56px;
		padding-left: 0;
	}
	#topPage1 #under-title1 div{
		width: 80px;
		height: 6px;
	}
	#topPage1 p{
		width: calc(100% - 88px);
		font-size: 52px;
		line-height:72px;
		margin: 0 auto;
	}
	#topPage1 p span{
		width: 32px;
		height: 4px;
		margin-left:2px;
	}

	#topPage2{
		width: 100%;
		padding-top: 264px;
		padding-bottom:  60px;
		background: url("./images/二级页顶背景.png") no-repeat center 184px;
		background-size:100%;
	}
	#topPage2 h1{
		width: calc(100% - 88px);
		margin: 0 auto;
		font-size: 40px;
		line-height:40px;
	}
	#topPage2 h2{
		width: calc(100% - 88px);
		font-size: 74px;
		line-height:140px;
		margin: 27px auto 0;
	}
	#topPage2 p{
		width: calc(100% - 88px);
		font-size: 40px;
		line-height:62px;
		margin-left: 44px;
	}
#topPage2 .nowrap1 {
	white-space: normal;
	width: calc(100% - 88px);
}
#topPage2 .nowrap1 br{
	display:none;
}
#topPage2 .nowrap1:last-child {
	white-space: normal;
	width: calc(100% - 88px);
}
} */
</style>
