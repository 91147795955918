<template>
	<div id="naIn">
		<span class="naIn1">{{natittle3}}</span>
		<span class="naIn2" v-show="natittle2">{{natittle2}}</span>
		<span class="naIn2" v-show="natittle1">{{natittle1}}</span>
		<span class="naIn2">首页</span>
		<span class="naIn1">当前位置：</span>
	</div>  
</template>

<script>
export default {
    name: 'NaIn',
	data() {
		return {
			natittle1: this.$attrs.natittle1,
			natittle2: this.$attrs.natittle2,
			natittle3: this.$attrs.natittle3
		}
	},
	watch:{
		$attrs:{
			immediate:true,
            handler(){
				this.natittle2 = this.$attrs.natittle2;
				this.natittle3 = this.$attrs.natittle3;
				this.natittle3 = this.natittle3.replace('<br>','')
			}
		}
	}
}
</script>

<style>
.fontmainMargin #naIn{
	margin: 0px auto 24px;
}
/*pc*/
#naIn{
	width: 100%;
	height: 21px;
	margin: 0px auto 12px;
	left: 0px;
}
#naIn .naIn2{
	float: right;
	color: #666;
	font-size: 14px;
	font-weight: 300;
}
#naIn .naIn2::after{
	display: inline-block;
	content: '>';
	margin: 0 5px;
}
#naIn .naIn2:hover{
	color: #3d3d3d;
}
#naIn .naIn1{
	float: right;
	color: #3d3d3d;
	font-size: 14px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#naIn{
		width: 100%;
		margin: 0px auto 20px;
	}
	.fontmainMargin #naIn{
		margin: 0px auto 27px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#naIn{
		display:none;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#naIn{
		display:none;
	}
} */
</style>
