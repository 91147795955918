<template>
    <div class="buboxfooter">
    	<!-- <img src="./images/logo.png" alt="LOGO"> -->
        <div>
            <p>©{{time}} 北京凝思软件股份有限公司</p>
            <p> 
                <router-link to="/PrivacyPolicy">隐私政策</router-link>
                <span>|</span>
                <router-link to="/LegalNotices">法律声明</router-link>
                <span>|</span>
                <a target="_parent" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021006165号-1</a>
                <span>|</span>
                <a target="_parent" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024724">京公网安备11010802024724号</a>
                <span>|</span>
                <router-link to="/Home">www.linx-info.com</router-link>
            </p>
        </div>   
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'foot2',
    data() {
		return {
			time: "",
		};
	},
	mounted() {
      this.nowtime()
    },
    methods:{
    	nowtime() {
			let nowDate = new Date();
			let date = {
				// 获取当前年份
				year: nowDate.getFullYear(),
			};
			//拼接
			this.time = date.year;
		},
    },
}
</script>

<style scoped>
/*pad横屏*/
@media only screen and (max-width:1170px){

    .buboxfooter{
        width: 904px;
        padding: 0 calc(50% - 452px);
        height: 92px;
        margin: 0 auto;
        position: relative;
        background-color: #14161c;
        display:grid;
        grid-template-columns: 138px 766px;
    }
    .buboxfooter > img{
    	display:block;
        position:relative;
        width: 108px;
        height:auto;
        left:0;
        top:50%;
        transform: translateY(-50%); 
    }
    .buboxfooter > div{
        width: 766px;
        padding:10px 0;
        margin: 0;
        position: relative;
    }
    .buboxfooter div p{
        color: #a2a2a3;
        line-height: 36px;
        font-size: 16px;
    }
    .buboxfooter div p span{
        font-size: 16px;
        color: #a2a2a3;
    }
    .buboxfooter div p span{
        padding:0 5px;
    }
    .buboxfooter div p span:nth-child(1){
        padding:0 0;
        display:block;
    }
    .buboxfooter div p a{
        font-size: 16px;
        color: #a2a2a3;
    }
    .buboxfooter div p a:hover{
        color: #a2a2a3;
    }
}
</style>
