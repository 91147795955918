<template>
	<div class="font" id="fontNoBorder">
		<div class="column columnImgFont">
			<figure><img src="../images/变电站.jpg"/></figure>
			<div class="cornerBorder">
				<h1>变电站</h1>
				<p>目前国网全部存量变电站多达四万余座，变电站中综合自动化管理后台（简称：综自后台）是变电站站控层最重要的管控系统，经历数代的变迁，逐步向<span>自动化、智能化、智慧化</span>过渡发展。2017年国网在技术规范中明确要求在综自后台主机上使用国产安全操作系统。作为电力行业国产安全操作系统领军企业，针对综自后台的特殊需求，凝思与集成厂商在技术上深入合作和研发。从2018年初开始逐步推行，凝思安全操作系统在全国范围内的变电站大量应用，截至目前<span>已部署近万套。</span></p>
				<p>特别是国网于2018年新建的第一批8个储能电站项目中，在站控层监控主机的部分均采用了<span>凝思安全操作系统产品</span>，凝思为大型城市电网的“充电宝”项目助力。</p>
			</div>
		</div>
		<div class="column columngray rhombusp">
			<div>
				<h1>典型案例</h1>
				<p><b>站控层全国产化示范站（110kV）：</b>江苏盐城陈老变电站、江苏泰州娄庄变电站 </p>
				<p><b>特高压变电站（500kV）：</b>山西临汾变电站、四川成都尖山变电站、湖南云田变电站、湖南罗城变电站、湖北武汉柏泉变电站、陕西宝鸡换流站、湖南长沙鼎功变电站、天津静海变电站、湖北鄂州变电站、山西忻州五寨变电站、湖南衡阳东变电站、山东油城变电站、昆嵛变电站</p>
			</div>
			<div>
				<h1 class="novisical">典型案例</h1>
				<p><b>储能站：</b>江苏镇江扬中长旺8MW/16MWh储能站、湖南长沙储能站、江苏苏州乐余20.16MW/35.2MWh储能电站、江苏镇江东部电网16MW丹阳及大港储能站</p>
				<p><b>南网：</b>广东江门、韶关、清远、汕头等地共计60余座变电站监控系统国产化示范站的部署；广东惠州龙门、云南昆明昆北、广西柳州柳北三个大型重点换流站的监控系统的部署</p>
			</div>
		</div>
		<div class="NoColumn rhombusp NoColumn2">
			<h2>业务应用</h2>
			<p><b>综合自动化管理后台：</b>包括监控主机、操作员及工程师工作站、数据服务器、综合服务器、PMU、网分后台、远动机、故障录波等</p>
			<p><b>一键顺控系统：</b>包括顺控主机、智能防误主机等</p>
		</div>
	</div>
</template>

<script>
	export default {
		name:'Transformersubstation',
	}
</script>
<style scoped>

</style>
