<!-- 服务与支持 -->
<template>
    <div>
		<TopOfPage :tittle="tittle" :path="path"></TopOfPage>
		<router-view :tittle="tittle"></router-view> 
    </div>
</template>

<script>
export default {
    name: 'ServiceAndSupport',
    data() {
		return {
			tittle: "服务与支持",
			path: "/ServiceAndSupport"
		}
	},
}
</script>

<style>
#downfont{
  min-height: 0;
}
#downfont > h1{
  margin: -1px -1px 0px -1px;
  padding: 0 32px;
  border: #e0e0e0 1px solid;
}
#downfont > h1 .titleOfh1{
  font-size: 20px;
  line-height: 72px;
  letter-spacing: 0px;
  font-weight: 500; 
}
#downfont > h1 .tips{
  float: right;
  color: #565d69;
  font-size: 14px;
  line-height: 72px;
  letter-spacing: 0px;
  font-weight: 500; 
}
/*表头*/
#downfont h2{
  margin: 0;
    height: 56px;
    padding-left: 32px;
    line-height: 56px;
    font-size: 16px;
    font-weight: 500; 
    color: #dd1d29;
    position: relative;
}
#downfont h2 .name{
    position: absolute;
    width: 356px;
}
#downfont h2 .md5{
    position: absolute;
    width: 269px;
    left: 458px;
}
#downfont h2 .time{
    position: absolute;
    width: 71px;
    right: 32px;
}
/*表格*/
#downfont p:nth-child(n){
  margin: 0;
  padding: 0 32px;
  height: 75px;
  font-weight: 400; 
  color: #262b33;
  background-color: #f5f5f5;
  position: relative;
  text-indent: 0em;
}
#downfont p:nth-child(2n){
    background-color: #fff;
}
#downfont p .Pname{
    width: 356px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #262b33;
    font-size: 16px;
    line-height: 20px;
    word-wrap:break-word ; 
}
#downfont p .Pname:hover{
    color: #dd1d29;
}
#downfont p .Pmd5{
  color: #262b33;
    width: 269px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 458px;
    font-size: 14px;
    word-wrap:break-word ; 
}
#downfont p .Ptime{
  color: #262b33;
    position: absolute;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);
    right: 32px;
    word-wrap:break-word ; 
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
/*服务与支持*/
  /*表头*/
  #downfont h2{
    display:none;
  }
/*表格*/
  #downfont p:nth-child(n){
    padding: 18px 32px;
    height: auto;
    position: relative;
  }
  #downfont p .Pname{
    position: relative;
    display:block;
    width: 544px;
    top: 0;
    transform: translateY(0);
    color: #262b33;
    font-size: 16px;
    line-height: 28px;
    word-wrap:break-word ; 
    white-space:nowrap;text-overflow:ellipsis;overflow:hidden; 
  }
  #downfont p .Pmd5{
    position: relative;
    display:block;
    width: 544px;
    top: 0;
    transform: translateY(0);
    left: 0;
    color: #262b33;
    font-size: 16px;
    line-height: 28px;
    word-wrap:break-word ; 
    white-space:nowrap;text-overflow:ellipsis;overflow:hidden; 
  }
  #downfont p .Pmd5:before{
    content:"MD5：";
  }
  #downfont p .Ptime{
    position: absolute;
    top: 0;
    color: #262b33;
    font-size: 16px;
    line-height: 64px;
    transform: translateY(0);
    right: 32px;
    word-wrap:break-word ; 
  }
}
/*手机端*/
@media only screen and (max-width:750px){
      /*服务与支持*/
  #downfont h1 .titleOfh1{
    font-size: 15px;
    line-height: 0;
    letter-spacing: 0px;
    font-weight: 500; 
    color:#262b33;
  }
  #downfont h1 .tips{
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    float: none;
  }
  /*表格*/
  #downfont > h1{
    line-height: 30px;
    padding: 20px 16px 14px;
    margin: 0;
    border: #e0e0e0 0px solid;
  }
  #downfont p:nth-child(n){
      background-color: #fff;
      margin: 0px 15px 15px;
      padding: 0px 0px 15px;
      height: auto;
      position: relative;
      border-bottom: #e0e0e0 0.5px solid;
  }
  #downfont p:last-child{
      margin: 0px 15px 0px;
  }
  #downfont p .Pname{
      position: relative;
      display:block;
      width: 100%;
      top: 0;
      transform: translateY(0);
      color: #262b33;
      font-size: 14px;
      line-height: 17px;
      padding:3.5px 0;
      white-space:normal;
      text-overflow:none;
  }
  #downfont p .Pmd5,#downfont p .Ptime{
      position: relative;
      display:block;
      width: 100%;
      top: 0;
      transform: translateY(0);
      left: 0;
      color: #262b33;
      font-size: 14px;
      line-height: 24px;
      white-space:normal;
      text-overflow:none;
  }
  #downfont p .Pmd5:before{
      content:"MD5：";
  }
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
      服务与支持
  #downfont h1{
    padding: 15px 44px 0;
    line-height: 152px;
    margin: 0;
    border: #e0e0e0 0px solid;
  }
  #downfont h1 .titleOfh1{
    font-size: 42px;
    line-height: 152px;
    letter-spacing: 0px;
    font-weight: 500; 
    color:#262b33;
  }
  #downfont h1 .tips{
    font-size: 36px;
    line-height: 152px;
  }
  表格
  #downfont p:nth-child(n){
      background-color: #fff;
      margin: 0px 44px 42px;
      padding: 0px 0px 42px;
      height: auto;
      position: relative;
      border-bottom: #e0e0e0 2px solid;
  }
  #downfont p:last-child{
      margin: 0px 44px 0px;
  }
  #downfont p .Pname{
      position: relative;
      display:block;
      width: 100%;
      top: 0;
      transform: translateY(0);
      color: #262b33;
      font-size: 40px;
      line-height: 50px;
      padding:9px 0;
      white-space:normal;
      text-overflow:none;
  }
  #downfont p .Pmd5,#downfont p .Ptime{
      position: relative;
      display:block;
      width: 100%;
      top: 0;
      transform: translateY(0);
      left: 0;
      color: #262b33;
      font-size: 40px;
      line-height: 68px;
      white-space:normal;
      text-overflow:none;
  }
  #downfont p .Pmd5:before{
      content:"MD5：";
  }
} */
</style>
