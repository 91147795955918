<!-- 顶部导航栏 -->
<template>
    <div
        :class="[navInHeader == '/Home' && navShow == 0 ? 'box1_2' : 'box1_1',{box2:navHide}]"
    >
        <div id="navigation">
            <router-link to="/Home"><img id="logotop" src="./images/logo.png" alt="LOGO"></router-link>
            <div id="lattice">
                <ul class="menu">
                    <li
                    	class="list"
                    	v-for="(item,index) in navList"
                    	:key="index"
                    	@mouseenter="enterHoverInNav(index)"
                        @mouseleave="leaveHoverInNav(index)"
                    >
                    	<!-- :class="navInHeader1 == index ? navInHeader == '/Home' &&navShow == 0 ? 'level2_0':'level1_0' : navInHeader == '/Home' &&navShow == 0 ? 'level2_1':'level1_1'"  -->
                        <router-link
                            :class="navInHeader == '/Home' &&navShow == 0 ? 'level2_1':'level1_1'" 
                            :to="item.path"
                        >{{item.name}}</router-link>
                        <transition name="fade">
		                    <ul
		                        v-if="index > 1"
		                        v-show="navInHeaderIndex==index || hoverInNav == index"
		                    >
		                        <li v-for ="(nav,index2) in item.subItems" :key="index2">
		                            <router-link class="level_2" :to="nav.link">{{nav.text}}</router-link>
		                        </li>
		                    </ul>
		                 </transition>
		                 <transition name="fade">
		                    <div
		                        v-if="index == 1"
		                        v-show="navInHeaderIndex==index || hoverInNav == index"
                            	class="proNav"
                            	:class="navProindex==-1 ? 'proNav1' : 'proNav2'"
		                    >
		                        <div>
		                        	<p
		                        		@mouseenter="navProindex = 0"
		                        		:class="{inpro:navProindex == 0}"
		                        	>操作系统</p>
		                        	<p
		                        		@mouseenter="navProindex = 1"
		                        		:class="{inpro:navProindex == 1}"
		                        	>系统功能增强</p>
		                        	<p
		                        		@mouseenter="navProindex = 2"
		                        		:class="{inpro:navProindex == 2}"
		                        	>平台软件</p>
		                        	<p
		                        		@mouseenter="navProindex = 3"
		                        		:class="{inpro:navProindex == 3}"
		                        	>定制开发</p>
		                        	<router-link
		                        		@mouseenter.native="navProindex = -1"
		                        		class="cssp"
		                        		:to="{path:'/Products/SecureServerPlatform',query:{'producttitle':'凝思安全服务器平台'}}"
		                        	>凝思安全服务器平台</router-link>
		                        </div>
		                        <div v-show="navProindex == 0">
		                        	<h1 v-show="0">服务器版</h1>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':0}}">凝思安全操作系统V6.0.42</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':1}}">凝思安全操作系统V6.0.60</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':2}}">凝思安全操作系统V6.0.80</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':5}}">凝思安全操作系统龙蜥版V6.0.98</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':6}}">凝思安全操作系统欧拉版V6.0.99</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':3}}">凝思安全操作系统V6.0.100</router-link>
		                        	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':4}}">凝思工业互联网安全操作系统</router-link>
		                        </div>
		                        <div v-show="navProindex == 1">
		                        	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思多路径软件'}}">凝思多路径软件</router-link>
		                        	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思双机热备软件'}}">凝思双机热备软件</router-link>
		                        	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思资源控制软件'}}">凝思资源控制软件</router-link>
		                        	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思触摸屏软件'}}">凝思触摸屏软件</router-link>
		                        </div>
		                        <div v-show="navProindex == 2">
		                        	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思云桌面系统'}}">凝思云桌面系统</router-link>
		                        	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思云计算平台软件'}}">凝思云计算平台软件</router-link>
		                        	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思大数据平台软件'}}">凝思大数据平台软件</router-link>
		                        	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思分布式文件系统'}}">凝思分布式文件系统</router-link>
		                        	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思虚拟化管理平台'}}">凝思虚拟化管理平台</router-link>
		                        </div>
		                        <div v-show="navProindex == 3">
		                        	<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'平台产品定制开发'}}">平台产品定制开发</router-link>
		                        	<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'跨平台移植开发'}}">跨平台移植开发</router-link>
		                        	<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'系统功能软件开发'}}">系统功能软件开发</router-link>
		                        </div>
		                    </div>
		                 </transition>
		                 <transition name="fade">
		                    <ul
		                        v-if="index == 0"
		                        v-show="navInHeader == '/Home' && hoverInNav == index"
		                        id="navInHome"
		                    >
		                        <li><a class="level_2" @click="goAnchor('#products')">凝思产品</a></li>
		                        <li><a class="level_2" @click="goAnchor('#certify')">解决方案</a></li>
		                        <li><a class="level_2" @click="goAnchor('#ecologica')">生态合作</a></li>
		                        <li><a class="level_2" @click="goAnchor('#partners')">生态伙伴</a></li>
		                        <li><a class="level_2" @click="goAnchor('#support')">服务与支持</a></li>
		                        <li><a class="level_2" @click="goAnchor('#news')">公司要闻</a></li>
		                    </ul>
		            	</transition>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
    name: 'header1',
    data(){
        return{
        	bodyWidthMut: document.body.clientWidth,
            navInHeader:this.$route.path,
            navShow:0,
            navHide:0,
            navInHeader1:0,
            navInHeaderIndex:-1,
            hoverInNav:-1,
            judgeTimer: null,
            navProindex:0,
            navHideTime: 0,
        }
    },
    computed:{
        ...mapState({
            navList: (state)=>state.Navigation.navList,
        })
    },
    methods:{
    	play(index){
    		this.hoverInNav = index;
    		clearTimeout(this.judgeTimer);
    	},
    	enterHoverInNav(index){
    		if(this.navHideTime == 0){
    			this.judgeTimer = setTimeout(() => {
					this.play(index);
				},0)
    		}else {
    			this.hoverInNav = index;
    			this.navHideTime = 0;
    		}
    		this.navHideTime = index;
    	},
    	leaveHoverInNav(index){
    		this.navProindex = 0;
    		this.hoverInNav = -1;
    		this.navHideTime = 0;
    		clearTimeout(this.judgeTimer);
    	},

        goAnchor(id){
            this.navInHeaderIndex = -1;
            document.querySelector(id).scrollIntoView({
                behavior:"smooth",
                block:"start",
            });
        },
        toPath(path) {
            this.$router.push({path: path})
        },
    },
    mounted() {
    	window.addEventListener('scroll',()=>{this.navShow=document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset}, true);
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
		//点击其他位置，div关闭
        document.addEventListener('click',(e) => {
            const uc = document.getElementById('lattice');
            if(uc && !uc.contains(e.target)){
                this.navInHeaderIndex = -1;
                this.navProindex = 0;
            }
        })
    },
    watch: {
    	$route:{
            immediate:true,
            handler(val,oldVal){
                this.navInHeaderIndex = -1;
                this.navInHeader = this.$route.path;
                if(this.navInHeader == '/Home'){
                    this.navInHeader1 = 0;
                } else if(this.navInHeader.indexOf('/Products') != -1){
                    this.navInHeader1 = 1;
                } else if(this.navInHeader.indexOf('/Solutions') != -1){
                    this.navInHeader1 = 2;
                } else if(this.navInHeader.indexOf('/EcologicalCooperation') != -1){
                    this.navInHeader1 = 3;
                } else if(this.navInHeader.indexOf('/ServiceAndSupport') != -1){
                    this.navInHeader1 = 4;
                } else if(this.navInHeader.indexOf('/AboutLinx') != -1){
                    this.navInHeader1 = 5;
                } else{
                    this.navInHeader1 = -1;
                }
                
            },
            deep: true
        },
	    bodyWidthMut:{
		    immediate:true,
		    handler(){
		    	this.navInHeaderIndex = -1;
			}
		},
	},
}
</script>
<style scoped>
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.fade-enter-to,
.fade-leave {
	opacity: 1;
}

.fade-enter-active{
	transition: all 0s 0s linear;
}
.fade-leave-active {
	transition: all 0s 0s linear;
}	
/*pc*/
/* 导航栏样式 */
.box1_1{
    width: 100%;
    height: 79px;
    background-color: rgba(255,255,255,1);
    position: fixed;         
    text-align: center; 
    z-index: 9999;
    box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
    transition: background-color .3s;
    top:0;
    transition: top .3s;
    border-bottom:1px solid #e0e0e0;
}
/*首页导航栏-透明*/
.box1_2{
    width: 100%;
    height: 79px;
    background-color: rgba(255,255,255,0);
    position: fixed;         
    text-align: center; 
    z-index: 9999;
    transition: background-color .3s;
}
.box2{
    top:-80px;
    transition: top .3s;
}
/*内容区域*/
#navigation{
    position: relative;
    top: 0px;
    width: calc(100% - 73px - 73px);
    height: 79px;
    margin: 0 auto;
}
a{
    text-decoration: none;
    color:#fff;
}
#logotop{
    position: absolute;
    left:0px;
    top:50%;
    transform: translateY(-50%);
    width: auto;
    height: 56px;
}
#lattice{
    float: right;
}
li{
    float: left;
    display: block;
    width: 126px;
    line-height: 79px;
    text-align: center;
    margin-right: 0;
     cursor:pointer;
}
/*二级导航块*/
.level1_0{
    display: block;
    background: rgb(221,29,41);
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    width: 126px;
    line-height: 79px;
    text-shadow:rgba(05, 05, 05, 0) 0px 1px 1px ;
    transition: color .3s;
    white-space:nowrap;
}
.level1_1{
    display: block;
    background: rgba(0,0,0,0);
    font-size: 16px;
    font-weight: 500;
    color: #262b33;
    width: 126px;
    line-height: 79px;
    transition: color .3s;
    text-shadow:rgba(05,05, 05, 0) 0px 1px 1px ;
    white-space:nowrap;
}
.list:hover .level1_1{
    color: #fff;
    background: rgb(221,29,41);
}
.level2_0{
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255,255,255,0.6);
    width: 126px;
    line-height: 79px;
    text-shadow:rgba(05,05, 05, 0.25) 0px 1px 1px ;
    transition: color .3s;
}
.level2_1{
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 126px;
    line-height: 79px;
    transition: color .3s;
    text-shadow:rgba(05,05, 05, 0.25) 0px 1px 1px ;
}
.list:hover .level2_1{
    color: rgba(255,255,255,0.6);
}


li ul{
    position: absolute;
    display: block;
    top: 79px;
    width: 204px;
    border-top: 24px #fff solid;
    border-bottom: 24px #fff solid;
    background: #fff;
    box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
}
li:last-child ul{
    right:0;
}
.level_2{
    display: block;
    text-align:left;
    text-indent:40px;
    letter-spacing: 1px;
    font-size: 14px;
    color: #262b33;
    width: 204px;
    line-height: 38px;
    font-weight: 500;
    transition: color .3s;
	margin: 4px 0;
}
.level_2:hover{
    color:  #dd1d29;
    transition: color .3s;
}

.proNav{
	position: relative;
	display:block;
	padding:24px 0;
	background-color:#fff;
	box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
	cursor:default;
}
.proNav1{
	width:242px;
}
.proNav2{
	width: 634px;
}
.proNav div:first-child{
	position: relative;
	width:242px;
    height: calc(230px + 46px + 46px);
	background-color:#fff;
	left: 0;
}
.proNav2 div:first-child{
	border-right:1px #e0e0e0 solid;
}
.proNav div:first-child p,.proNav div:first-child .cssp{
	position: relative;
	display:block;
	font-size: 14px;
    color: #262b33;
    width: 242px;
    line-height: 46px;
    font-weight: 500;
    text-align:left;
    text-indent:40px;
    cursor:pointer;
}
.proNav div:first-child p:hover,
.proNav div:first-child .inpro,
.proNav div:first-child .cssp:hover,
.proNav div:first-child .csst:hover{
	color:#dd1d29;
}
.proNav div:first-child p:after{
	display:block;
	position: absolute;
	content:'';
	width:46px;
	height:46px;
	background: url(./images/箭头-菜单-灰色-pc.png) no-repeat center;
	top:0;
	right:10px;
}
.proNav div:first-child p:hover:after,
.proNav div:first-child .inpro:after{
	background: url(./images/箭头-菜单-红色-pc.png) no-repeat center;
}
.proNav div:first-child .csst{
	display:block;
	font-size: 14px;
    color: #262b33;
    width: 242px;
    line-height: 46px;
    font-weight: 600;
    text-align:left;
    text-indent:40px;
}
.proNav div:not(:first-child){
	position: absolute;
	padding:24px 0;
	width:391px;
	height:calc(230px + 46px + 46px);
	background-color:#fff;
	background: url(./images/下拉菜单点阵-pc.png) no-repeat bottom right;
	top:0;
	left:243px;
}
.proNav div:not(:first-child) h1{
	display:block;
	font-size: 18px;
    color: #262b33;
    width: 391px;
    line-height: 46px;
    font-weight: 400;
    text-align:left;
    text-indent:40px;
}
.proNav div:not(:first-child) a{
	display:block;
	font-size: 14px;
    color: #262b33;
    width: 391px;
    line-height: 46px;
    font-weight: 500;
    text-align:left;
    text-indent:40px;
}
.proNav div:not(:first-child) a:hover{
    color: #dd1d29;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    /* 导航栏样式 */
    .box1_1, .box1_2{
        height: 80px;
    }
    .box2{
        top:-100px;
        transition: top .3s;
    }
    #navigation{
	    width: 100%;
	    height: 80px;
    }
    #logotop{
        margin-left: 30px;
    }
    #lattice{
        margin-right:30px;
    }

    li{
        width: 118px;
        line-height: 80px;
    }

    /*二级导航块*/  
    .level1_0,.level1_1, .level2_0, .level2_1{
        width: 118px;
        line-height: 80px;
    }

    li ul{
        position: absolute;
        display: block;
        top: 80px;
        width: 168px;
        border-top: 15px #fff solid;
        border-bottom: 15px #fff solid;
        background: #fff;
        box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
    }
    li ul li{
        width: 168px;
        line-height: 44px;
    }
    .level_2{
        text-indent:27px;
        width: 168px;
        line-height: 44px;
	margin: 0;
    }
    

	.proNav1{
		width:211px;
	}
	.proNav2{
		width:542px;
	}
	.proNav div:first-child{
		width:211px;
	}
	.proNav div:first-child p,
	.proNav div:first-child .cssp,
	.proNav div:first-child .csst{
		width: 211px;
		text-indent:28px;
	}
	.proNav div:first-child p:after{
		right:8px;
	}
	.proNav div:not(:first-child){
		width:330px;
		left:212px;
	}
	.proNav div:not(:first-child) h1,
	.proNav div:not(:first-child) a{
		width: 330px;
	}
}
</style>
