<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<Military v-show="natittle3 == '军队'"></Military>
	</div>
</template>

<script>
import Military from './Military.vue';

export default {
	name:'ArmyDetails',
	components:{
		Military
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	mounted(){
        if(this.natittle3 == '军队'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '军队'
        }
    },
}
</script>
<style>
#fontNoBorder > #tittlrImgArmy1{
	background:url('../images/标题背景-军队.jpg') no-repeat center -166px;
	background-size: 1172px 886px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#fontNoBorder > #tittlrImgArmy1{
		background:url('../images/标题背景-军队.jpg') no-repeat center -96px;
		background-size:100% auto;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > #tittlrImgArmy1{
		background:url('../images/标题背景-军队.jpg') no-repeat center -80px;
		background-size:100% auto;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder > #tittlrImgArmy1{
		background:url('../images/标题背景-军队.jpg')-127px -53.5px;
		background-size: auto 429px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder > #tittlrImgArmy1{
		background:url('../images/标题背景-军队.jpg') no-repeat 0px -25px;
		background-size: auto 778px;
	}
} */
</style>
