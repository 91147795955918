<template>
	<div>
		<TopOfProducts :smallTitle="natittle2" :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainNoBottom">
			<!--<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->-->
			<CloudDesktopSystem v-show="natittle3 == '凝思云桌面系统'"></CloudDesktopSystem>
			<CloudComputing v-show="natittle3 == '凝思云计算平台软件'"></CloudComputing>
			<BigData v-show="natittle3 == '凝思大数据平台软件'"></BigData>
			<Distributed v-show="natittle3 == '凝思分布式文件系统'"></Distributed>
			<VirtualizationManagementPlatform v-show="natittle3 == '凝思虚拟化管理平台'"></VirtualizationManagementPlatform>
		</div>
	</div>
</template>

<script>
import CloudDesktopSystem from './CloudDesktopSystem.vue';
import CloudComputing from './CloudComputing.vue';
import BigData from './BigData.vue';
import Distributed from './Distributed.vue';
import VirtualizationManagementPlatform from './VirtualizationManagementPlatform.vue';
export default {
	name:'PlatformSoftware',
	components:{
		CloudDesktopSystem,
		CloudComputing,
		BigData,
		Distributed,
		VirtualizationManagementPlatform,
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '平台软件',
			natittle3: this.$route.query.producttitle,
			brief:'',
			brief1:[
				'凝于思，敏于行。',
				'基于凝思虚拟桌面技术，为您带来更为便捷安全的体验。',
			],
			brief2:[
				'凝思软件基于对操作系统的深入理解，利用业界先进的云计算技术和工程应用经验，结合凝思安全机制，为用户提供全方位云计算系统解决方案，适用于构建从中小企业到大型行业的私有云和公有云平台。一方面可大幅降低企业IT成本，提高资源利用率；另一方面有力增强企业自身信息安全和防护能力。',
			],
			brief3:[
				'凝思大数据分析系统平台是一套支持PB级大数据分析的系统平台方案。采用业界先进的技术，基于分布式并行技术、分布式文件系统、高速IO技术构建大数据平台的基础设施，采用内存数据库、分布式数据库、分布式分析查询引擎作为中间层，应用层提供批处理、交互式查询、机器学习、数据挖掘、流处理等功能，同时提供第三方应用的接口支持。特别适用于情报分析领域和商业智能分析应用领域。',
			],
			brief4:[
				'凝思分布式文件系统支持大容量存储设备，在实现安全冗余存储的同时，<br>支持存储空间的动态扩展。凝思安全分布式文件系统如下所示，由四部分组成:',
				'<span>管理服务器</span>，管理整个文件群集的元数据日志；',
				'<span>数据服务器</span>，存储数据文件，并与其它数据服务器做数据同步；',
				'<span>备份服务器</span>，保存元数据的更新日志，并定时更新元数据，管理服务器故障时元数据备份服务器提升为管理服务器；',
				'<span>客户端与管理服务器通信更新元数据，或与数据服务器通信交换数据文件。</span>',
			],
			brief5:[
				'凝于思，敏于行',
				'一机多用，多系统、多空间从此触手可及。',
			],
		}
	},
    watch:{
        $route:{
            immediate:true,
            handler(){
                this.natittle3 = this.$route.query.producttitle;
                if(this.$route.query.producttitle == '凝思云桌面系统'){
                    this.brief = this.brief1;
                } else if(this.$route.query.producttitle == '凝思云计算平台软件'){
                    this.brief = this.brief2;
                } else if(this.$route.query.producttitle == '凝思大数据平台软件'){
                    this.brief = this.brief3;
                } else if(this.$route.query.producttitle == '凝思分布式文件系统'){
                    this.brief = this.brief4;
                } else if(this.$route.query.producttitle == '凝思虚拟化管理平台'){
                    this.brief = this.brief5;
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
