<template>
    <div class="fontmain">
        <div class="font" id="fontTable" style="width:100%;margin-left:0;">
            <h1>
                <img :src="icon"/>
				<span>{{natittle3}}</span>
                <input type="text" class="search-val" placeholder="请输入公告/漏洞编号" v-model="searchValue">
				<a @click="searchCVE()"></a>
            </h1>
            <div class="tableBox">
                <el-table
                    class="table"
                    :data="tableData"
                    height="670"
                >
                    <el-table-column
                        label="公告编号"
                        width="160"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <!-- <a
                                @click="getInfo(scope.row)"
                                target="_blank"
                                class="buttonText"
                            >{{ scope.row.linxNumber }}</a> -->
                            <router-link class="buttonText" :to="{path:'/ServiceAndSupport/NoticeDetails',query:{id:scope.row.linxNumber}}">{{ scope.row.linxNumber }}</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="漏洞编号"
                        width="160"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <div v-html="scope.row.cveNumber.split(';').join('<br/>')"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="安全级别"
                        width="90"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-tag
                                size="mini"
                                :type="security_level[scope.row.bugLevel-1].listClass"
                            >{{ security_level[scope.row.bugLevel-1].dictLabel }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="受影响产品"
                        width="200"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <div v-html="scope.row.affectedProducts.split(';').join('<br/>')"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="affectedCompoment" label="受影响组件" width="auto" min-width="120" align="center"></el-table-column>
                    <el-table-column label="发布时间" width="160" align="center">
                        <template slot-scope="scope">
                        {{ time(scope.row.noticePublished) }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        :current-page="pageNum"
                        :page-size="pageSize"
                        :total="total"
                        layout="total,prev,pager,next,jumper"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { watch } from 'vue'

export default {
	name:'SecurityNotice',
	data() {
		return {
            natittle1: this.$attrs.tittle,
			natittle2: '安全公告',
			natittle3: '安全公告',
            icon: require('../images/icon-安全公告.png'),
            activeName: 'first',
            itemList:'',
            annocementList: [],
            tableData: [],
            security_level: [
                {
                    "createBy": "admin",
                    "createTime": "2023-06-21 14:46:13",
                    "updateBy": null,
                    "updateTime": null,
                    "remark": null,
                    "dictCode": 55,
                    "dictSort": 1,
                    "dictLabel": "严重",
                    "dictValue": "1",
                    "dictType": "security_level",
                    "cssClass": null,
                    "listClass": "danger",
                    "isDefault": "N",
                    "status": "0",
                    "default": false
                },
                {
                    "createBy": "admin",
                    "createTime": "2023-06-21 14:46:37",
                    "updateBy": null,
                    "updateTime": null,
                    "remark": null,
                    "dictCode": 56,
                    "dictSort": 2,
                    "dictLabel": "高危",
                    "dictValue": "2",
                    "dictType": "security_level",
                    "cssClass": null,
                    "listClass": "danger",
                    "isDefault": "N",
                    "status": "0",
                    "default": false
                },
                {
                    "createBy": "admin",
                    "createTime": "2023-06-21 14:46:55",
                    "updateBy": null,
                    "updateTime": null,
                    "remark": null,
                    "dictCode": 57,
                    "dictSort": 3,
                    "dictLabel": "中危",
                    "dictValue": "3",
                    "dictType": "security_level",
                    "cssClass": null,
                    "listClass": "warning",
                    "isDefault": "N",
                    "status": "0",
                    "default": false
                },
                {
                    "createBy": "admin",
                    "createTime": "2023-06-21 14:47:05",
                    "updateBy": null,
                    "updateTime": null,
                    "remark": null,
                    "dictCode": 58,
                    "dictSort": 4,
                    "dictLabel": "低危",
                    "dictValue": "4",
                    "dictType": "security_level",
                    "cssClass": null,
                    "listClass": "success",
                    "isDefault": "N",
                    "status": "0",
                    "default": false
                },
                {
                    "createBy": "admin",
                    "createTime": "2023-09-20 10:47:07",
                    "updateBy": null,
                    "updateTime": null,
                    "remark": null,
                    "dictCode": 72,
                    "dictSort": 5,
                    "dictLabel": "无",
                    "dictValue": "5",
                    "dictType": "security_level",
                    "cssClass": null,
                    "listClass": "default",
                    "isDefault": "N",
                    "status": "0",
                    "default": false
                }
            ],
            total: 1,
            pageNum: 1,
            pageSize: 100,
            searchValue: '',
            dialogVisible: false,
            infoData: [],
            archData: [],
            x86Data: [],
            o80Data: [],
            h1Data: [],
            c80Data: [],
            o100Data: [],
            h2Data: [],
            c100Data: [],
            baseUrl_x86: "http://mirrors.linx-info.com/mirrors-update/mirrors-6.0.99/20.03-sp3/x86_64/Packages/",
            baseUrl_arch: "http://mirrors.linx-info.com/mirrors-update/mirrors-6.0.99/20.03-sp3/aarch64/Packages/"
		}
	},
    created() {
        this.getSercurityLevel(),
        this.getList()
    },
    methods:{
        getInfo(row){
            this.dialogVisible = true
            this.infoData = row
            this.archData = []
            this.x86Data = []
            this.v80Data = []
            this.v100Data = []
            row.packageInfo.forEach(item => {
                if (item.packageType === 'aarch64') {
                    this.archData.push(item)
                }
                if (item.packageType === 'x86_64') {
                    this.x86Data.push(item)
                }
                if (item.packageType === 'V6.0.80系统') {
                    this.v80Data.push(item)
                }
                if (item.packageType === 'V6.0.100系统') {
                    this.v100Data.push(item)
                }
            });
        },
        handleClose() {
            this.dialogVisible = false
            this.infoData = []
            this.archData = []
            this.x86Data = []
            this.activeName = 'first'
        },

        async getList() {
            let i = 0
            var nowTotal = 0
            let fileName = ''
            while(nowTotal < this.total) {
                i++
                fileName = '/db/notice/home_cve'+i+'.json'
                await axios.get(fileName).then((res) => {
                    this.annocementList = [...this.annocementList, ...res.data.rows];
                    this.total = res.data.total;
                })
                nowTotal = i*100
            }
            this.tableData=this.annocementList.filter(x => x.cveNumber.indexOf(this.searchValue) != -1 || x.linxNumber.indexOf(this.searchValue) != -1).slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
        },
        getSercurityLevel() {
            axios.get('/db/security_level.json').then((res) => {
                this.security_level = res.data.data
            })
        },
        time(val) {
            return moment(val).format('YYYY-MM-DD')
        },

        handleSizeChange (val){
            this.pageSize = val
            this.tableData=this.annocementList.filter(x => x.cveNumber.indexOf(this.searchValue) != -1 || x.linxNumber.indexOf(this.searchValue) != -1).slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
        },
        handleCurrentChange (val){
            this.pageNum = val
            this.tableData=this.annocementList.filter(x => x.cveNumber.indexOf(this.searchValue) != -1 || x.linxNumber.indexOf(this.searchValue) != -1).slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
        },
        searchCVE(){
            this.tableData=this.annocementList.filter(x => x.cveNumber.indexOf(this.searchValue) != -1 || x.linxNumber.indexOf(this.searchValue) != -1).slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
            this.total=this.annocementList.filter(x => x.cveNumber.indexOf(this.searchValue) != -1 || x.linxNumber.indexOf(this.searchValue) != -1).length
        }
    },
    watch: {
        searchValue: {
            handler(val) {
                if (val === ''){
                    this.tableData=this.annocementList.slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
                    this.total=this.annocementList.length
                }
            }
        }
    }
}
</script>
<style scoped>
/* 搜索框 */
.font h1 input{
    position: absolute;
	top: 17px;
	right: 70px;
    width: 164px;
    height: 32px;
    border: 1px #e8e9eb solid;
    padding-left: 12px;
    outline:none;
    font-size: 14px;
    color: #262b33;
}
.font h1 input::-webkit-input-placeholder {
    color: #bbb;
    font-size: 14px;
}
.font h1 a{
    position: absolute;
	top: 17px;
	right: 32px;
    background:url(../images/search-line.png) no-repeat;
    background-color: #f7f7f7;
    background-position: center center;
    border: none;
    color: white;
    width: 38px;
    height: 32px;
    border-right: 1px #ebebeb solid;
    border-top: 1px #ebebeb solid;
    border-bottom: 1px #ebebeb solid;
    outline:none;
}
.font h1 a:hover{
    background: url(../images/search-line移入.png) no-repeat;
    background-color: #e8e9eb;
    background-position: center center;
}
#fontTable > h1{
    line-height:72px;
    border-bottom:1px solid #e0e0e0;
}
#fontTable .tableBox {
    overflow: auto;
}
#fontTable .buttonText {
    display: block;
    color: #00afff;
    text-decoration:underline;
    cursor: pointer;
}
#fontTable .pagination {
    position: absolute;
    right: 0;
    bottom: -36px;
}


/*手机端*/
@media only screen and (max-width:750px){
	#fontTable {
        margin-top: 36px;
    }
	/*搜索框*/
	.font h1 input{
		position: absolute;
		top:-33px;
		left: 15px;
		width: calc(100% - 46px - 52.5px);
		height: 35px;
		border: 1px #ebebeb solid;
		padding-left: 15px;
		outline:none;
		font-size: 13px;
		background-color:#fff;
	}
	.font h1 input::-webkit-input-placeholder {
		font-size: 9px;
	}
	.font h1 a,.font h1 a:hover{
		position: absolute;
		top:-33px;
		transform: translateY(0);
		right: 15px;
		background:url(../images/search-line-白.png) no-repeat;
		background-size:14px;
		background-color: #dd1d29;
		background-position: center center;
		border: none;
		color: white;
		width: 52px;
		height: 35px;
		border-right: 1px #dd1d29 solid;
		border-top: 1px #dd1d29 solid;
		border-bottom: 1px #dd1d29 solid;
		outline:none;
	}
    #fontTable > h1 span{
        margin-left: 0;
    }
}
</style>
