<template>
    <div class="font" id="fontTable">
        <h1>
            <span class="titleOfh1">CVE</span>
        </h1>
        <div class="tableBox">
            <el-table
                class="table"
                :data="resultList"
                height="580"
            >
                <el-table-column
                    label="CVE编号"
                    width="150"
                    align="center"
                >
                    <template slot-scope="scope">
                        <a
                            :href="scope.row.cveLink"
                            target="_blank"
                            class="buttonText"
                        >{{ scope.row.cveNumber}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="bugDesc" label="漏洞描述" width="auto" />
                <el-table-column label="发布时间" width="120" align="center">
                    <template slot-scope="scope">
                    {{ time(scope.row.cvePublished) }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    :current-page="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    layout="total,prev,pager,next,jumper"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
	name:'Cve',
	data() {
		return {
            itemList:'',
            resultList: [],
            total: 0,
            pageNum: 1,
            pageSize: 100
		}
	},
    created() {
        this.getList()
    },
    methods:{
        getList() {
            let fileName = '/db/cve/cve_number'+this.pageNum+'.json'
             axios.get(fileName).then((res) => {
                this.resultList = res.data.rows;
                this.total = res.data.total;
            })
        },
        time(val) {
            return moment(val).format('YYYY-MM-DD')
        },

        handleSizeChange (val){
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange (val){
            this.pageNum = val
            this.getList()
        }
    }
}
</script>
<style scoped>
#fontTable > h1{
    line-height:72px;
    border-bottom:1px solid #e0e0e0;
}
#fontTable .tableBox {
    overflow: auto;
}
#fontTable .tableBox .buttonText {
    color: #00afff;
    text-decoration:underline;
    cursor: pointer;
}
#fontTable .pagination {
    position: absolute;
    right: 0;
    bottom: -36px;
}

#fontTable .dialog >>> .el-dialog__body  {
    padding: 0 32px;
}
#fontTable .dialog .section_article {
    width: 100%;
}
#fontTable .dialog .container-announcementdetail {
    background-color: #F5F5F5;
    height: auto;
}
#fontTable .dialog .section_subnav {
    font-size: 20px;
    margin-bottom: 30px;
    margin-left: 10%;
}
#fontTable .dialog .subnav_title {
    margin-top: 30px;
}
#fontTable .dialog .header_title {
    margin-top: 50px;
    text-align: left;
    margin-bottom: 30px;
    text-align: center;
}
#fontTable .dialog .header_title .title_info span{
    color: #262b33;
}
#fontTable .dialog .title_content {
    font-size: 30px;
    text-align: center;
}
#fontTable .dialog .info_date {
    text-align: center;
}
#fontTable .dialog .article_body {
    text-align: left;
}
#fontTable .dialog .article_body p{
    margin: 0;
    text-indent: 0;
}
#fontTable .dialog .share_copyright {
    font-size: 13px;
    text-align: right;
    margin-bottom: 20px;
}
#fontTable .dialog .m3 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    text-indent: 0;
}
#fontTable .dialog .m3::before {
    display: none;
}
/*手机端*/
@media only screen and (max-width:750px){
    #fontTable > h1 span{
        margin-left: 0;
    }
}
</style>
