<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div
			class="font"
			id="fontNoMax"
			v-for="(jobs,indexjobs) in JobsList"
			:key="indexjobs + '-1'"
			v-show="title4 == indexjobs && !detailShow"
		>
			<h1 class="jobs">
				<img :src="icon"/>
				<span class="titleOfh1">{{natittle3}}</span>
		        <span class="tips">共 <b>{{JobsList[title4].text.length}}</b> 个在招职位</span>
			</h1>
			<a
				class="ajob"
				v-for="(detail,indexdetail) in jobs.text"
				:key="indexdetail + '-2'"
				@click="detailindex=indexdetail,detailShow=true,toTop()"
			>
				<h1>{{detail.tittle}}</h1>
				<p>{{detail.condition}}</p>
				<p>{{detail.city}}</p>
				<!-- <p class="time">{{detail.time}}</p> -->
			</a>
		</div>
		<div class="font" v-show="detailShow" id="fontNoMax">
			<h1 class="jobs">
				<a class="titleOfh2" @click="detailShow=false">返回职位列表</a>
			</h1>
			<div class="jobde1">
				<h1>{{JobsList[title4].text[detailindex].tittle}}</h1>
				<p>{{JobsList[title4].text[detailindex].condition}}</p>
				<p>{{JobsList[title4].text[detailindex].city}}</p>
				<!-- <p class="time">{{JobsList[title4].text[detailindex].time}}</p> -->
				<a href="mailto:hrzhaopin@linx-info.com">投递简历</a>
			</div>
			<div class="jobde2" v-show="JobsList[title4].text[detailindex].content.responsibility">
				<h1>岗位职责：</h1>
				<p
					v-for="(responsibility,indexresponsibility) in JobsList[title4].text[detailindex].content.responsibility"
					:key="indexresponsibility + '-3'"
				>{{responsibility}}</p>
			</div>
			<div class="jobde2" v-show="JobsList[title4].text[detailindex].content.request">
				<h1>任职要求：</h1>
				<p
					v-for="(request,indexrequest) in JobsList[title4].text[detailindex].content.request"
					:key="indexrequest + '-4'"
				>{{request}}</p>
			</div>
			<div class="jobde2" v-show="JobsList[title4].text[detailindex].content.advantage">
				<h1>加分项：</h1>
				<p
					v-for="(advantage,indexadvantage) in JobsList[title4].text[detailindex].content.advantage"
					:key="indexadvantage + '-5'"
				>{{advantage}}</p>
			</div>
			<a href="mailto:hrzhaopin@linx-info.com" id="hrmail">投递简历<img src="../images/箭头-白色-向右.png"></a>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';

export default {
	name:'Enrollful',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '招贤纳士',
			natittle3: '',
			title4: 0,
			icon: require('../images/招贤icon.png'),
			title4List: [
				{
					tittle:'社会招聘',
				},
				{
					tittle:'校园招聘',
				}
			],
			detailShow: false,
			detailindex: 0,
		}
	},
	computed:{
		...mapState({
			JobsList:(state)=>state.Jobs.JobsList,
		})
	},
	methods: {
		toTop() {
			document.documentElement.scrollTop = 225;
		}
	},
	watch:{
        title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
				this.detailShow = false;
				this.detailindex = 0;
            },
            deep: true,
        }
    }
}
</script>
<style scoped>
.font .jobs{
    margin: -1px -1px 0px -1px;
    border: #e0e0e0 1px solid;
}
.font .jobs .tips{
    float: right;
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500; 
}
.font .jobs .tips b{
    color: #b11721;
}
.font .ajob{
    display: block;
    height: 124px;
    position: relative;
    margin: -1px -1px -1px -1px;
    border: #e0e0e0 1px solid;
    cursor:pointer;
}
.font .ajob:hover{
    background-color: #f7f7f7;
}
.font .ajob h1{
    padding-left: 32px;
    padding-top: 16px;
    color: #262b33;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500; 
    line-height: 32px;
}
.font .ajob p{
    margin:0;
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400; 
    line-height: 30px;
}
.font .ajob .time{
    position: absolute;
    right: 32px;
    top: 16px;
    padding-left: 24px;
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400; 
    line-height: 30px;
}
.font .ajob .time:before{
    display: inline-block;
    content: "发布时间：";
}
/*  详情页面　*/
.font .jobs{
    padding-bottom:0;
}
.font .jobs a{
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 400; 
    color: #262b33;
    cursor:pointer;
}
.font .jobs a:hover{
    color: #dd1d29;
}
.font .jobs a:before{
    display: inline-block;
    height: 16px;
    width: 15px;
    margin-right: 8px;
    background: url(../images/返回.png) no-repeat;
    background-size:15px 16px;
    content: "";
}
.font .jobs a:hover:before{
    background: url(../images/返回-选中.png) no-repeat;
}

.font .jobde1{
    display: block;
    margin: 20px 32px 0;
    height: 124px;
    position: relative;
    background-color: #f7f7f7;
}
.font .jobde1 h1{
    padding-left: 24px;
    padding-top: 2px;
    color: #262b33;
    font-size: 20px;
    letter-spacing: 0px;
    font-weight: 500; 
    line-height: 56px;
}
.font .jobde1 p{
    margin:0;
    padding-left: 24px;
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400; 
    line-height: 26px;
    text-indent:0em;
}
.font .jobde1 .time{
    position: absolute;
    right: 24px;
    top: 0px;
    padding-left: 32px;
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400; 
    line-height: 60px;
}
.font .jobde1 .time:before{
    display: inline-block;
    content: "发布时间：";
}
.font .jobde1 a{
    position: absolute;
    right: 24px;
    bottom: 20px;
    height: 36px;
    width: 98px;
    background-color: #dd1d29;
    color:#fff;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500; 
    line-height: 36px;
    text-align: center;
    border: #dd1d29 1px solid;
}
.font #hrmail{
    display:none;
}

.font .jobde2{
    margin: 14px 32px 18px;
    /* background-color: #f7f7f7; */
}
.font .jobde2:nth-last-child(1){
    padding-bottom: 6px;
}
.font .jobde2 h1{
    color: #565d69;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500; 
    line-height: 28px;
}
.font .jobde2 p{
    margin:0;
    color: #262b33;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400; 
    line-height: 28px;
    text-indent:0em;
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
/*  详情页面　*/
	.font .jobde1{
		margin: 0;
	}
	.font .jobde1 h1,.font .jobde1 p{
		padding-left: 32px;
	}
	.font .jobde1 .time{
		right: 32px;
	}
	.font .jobde1 a{
		right: 32px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.font .jobs{
	    margin: 0;
	    border: #e0e0e0 0px solid;
	}
	.font .jobs .tips{
	    font-size: 13px;
	    font-weight: 400; 
	}
	.font .ajob{
	    height: auto;
	    padding-bottom:17.5px;
	    margin: 0 15px 20.5px;
	    border: #e0e0e0 0px solid;
	    border-bottom: #e0e0e0 1px solid;
	}
	.font .ajob:last-child{
	    border-bottom: #e0e0e0 0px solid;
	    margin-bottom:0px;
	}
	.font .ajob:hover{
	    background-color: #fff;
	}
	.font .ajob h1{
	    padding-left: 0px;
	    padding-top: 0px;
	    padding-bottom: 3px;
	    color: #262b33;
	    font-size: 14px;
	    letter-spacing: 0px;
	    font-weight: 500; 
	    line-height: 21px;
	}
	.font .ajob p{
	    padding-left: 0px;
	    font-size: 13px; 
	    line-height: 26px;
	    text-indent:0em;
	}
	.font .ajob .time{
	    right: 0px;
	    top: 4px;
	    padding-left: 0px;
	    font-size: 13px;
	    letter-spacing: 0px;
	    font-weight: 400; 
	    line-height: 13px;
	}
	.font .ajob .time:before{
	    display: none;
	}
	/*  详情页面　*/
	.font .jobs a{
	    font-size: 16px;
	}
	.font .jobs a:before{
	    height: 15px;
	    width: 14.5px;
	    margin-right: 8px;
	    background: url(../images/返回.png) no-repeat;
	    background-size:15px 14.5px;
	}
	.font .jobs a:hover:before{
	    background: url(../images/返回-选中.png) no-repeat;
	    background-size:14.5px 15px;
	}

	.font .jobde1{
	    margin: 0 15px;
	    padding-bottom: 17.5px;
	    height: auto;
	    background-color: #fff;
	    border-top: #e0e0e0 1px solid;
	    border-bottom: #e0e0e0 1px solid;
	}
	.font .jobde1 h1{
	    padding-left: 0px;
	    padding-top: 10.5px;
	    color: #262b33;
	    font-size: 21px;
	    line-height: 48px;
	}
	.font .jobde1 p, .font .jobde1 .time{
	    position: relative;
	    left: 0px;
	    top: 0px;
	    padding-left: 0px;
	    color: #565d69;
	    font-size: 14px;
	    line-height: 27px;
	}
	.font .jobde1 .time:before{
	    display: none;
	}
	.font .jobde1 a{
	    display:none;
	}
	.font .jobde2{
	    margin: 20px 15px 24px;
	}
	.font .jobde2:nth-last-child(2){
	    margin: 20px 15px 32px;
	    padding-bottom: 20px;
	    border-bottom: #e0e0e0 1px solid;
	}
	.font .jobde2 h1, .font .jobde2 p{
	    font-size: 14px;
	    line-height: 22px;
	    text-indent:0em;
	}
	.font #hrmail{
	    display:block;
	    position: relative;
	    left: 50%;
	    bottom: 0;
	    transform: translateX(-50%);
	    height: 44px;
	    width: 207px;
	    background-color: #dd1d29;
	    color:#fff;
	    font-size: 15px;
	    letter-spacing: 1px;
	    font-weight: 500; 
	    line-height: 44px;
	    text-align: center;
	}
	.font #hrmail img{
	    position: relative;
	    top: 0;
	    left: 0;
	    display: inline-block;
	    padding-left: 10px;
	    width: 8px;
	    height: 13px;
	    transition: left .3s;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	招贤纳士 
	.font .jobs{
	    margin: 0;
	    border: #e0e0e0 0px solid;
	}
	.font .jobs .tips,.font .jobs .tips b{
	    font-size: 36px;
	    font-weight: 400; 
	}
	.font .ajob{
	    height: auto;
	    padding-bottom:50px;
	    margin: 0 44px 50px;
	    border: #e0e0e0 0px solid;
	    border-bottom: #e0e0e0 2px solid;
	}
	.font .ajob:last-child{
	    border-bottom: #e0e0e0 0px solid;
	    margin-bottom:0px;
	}
	.font .ajob:hover{
	    background-color: #fff;
	}
	.font .ajob h1{
	    padding-left: 0px;
	    padding-top: 0px;
	    padding-bottom: 0px;
	    color: #262b33;
	    font-size: 40px;
	    letter-spacing: 0px;
	    font-weight: 500; 
	    line-height: 80px;
	}
	.font .ajob p{
	    padding-left: 0px;
	    font-size: 38px; 
	    line-height: 78px;
	    text-indent:0em;
	}
	.font .ajob .time{
	    right: 0px;
	    top: 0px;
	    padding-left: 0px;
	    font-size: 38px;
	    letter-spacing: 0px;
	    font-weight: 400; 
	    line-height: 80px;
	}
	.font .ajob .time:before{
	    display: none;
	}
	 招贤纳士详情页面　
	.font .jobs a{
	    font-size: 40px;
	}
	.font .jobs a:before{
	    height: 39px;
	    width: 37px;
	    margin-right: 20px;
	    background: url(../images/返回.png) no-repeat;
	    background-size:39px 37px;
	}
	.font .jobs a:hover:before{
	    background: url(../images/返回-选中.png) no-repeat;
	    background-size:39px 37px;
	}

	.font .jobde1{
	    margin: 0 40px 57px;
	    padding-bottom: 54px;
	    height: auto;
	    background-color: #fff;
	    border-top: #e0e0e0 2px solid;
	    border-bottom: #e0e0e0 2px solid;
	}
	.font .jobde1 h1{
	    padding-left: 0px;
	    padding-top: 30px;
	    color: #262b33;
	    font-size: 52px;
	    line-height: 132px;
	}
	.font .jobde1 p, .font .jobde1 .time{
	    position: relative;
	    left: 0px;
	    top: 0px;
	    padding-left: 0px;
	    color: #333;
	    font-size: 40px;
	    line-height: 72px;
	}
	.font .jobde1 .time:before{
	    display: none;
	}
	.font .jobde1 a{
	    display:none;
	}
	.font .jobde2{
	    margin: 44px 40px;
	}
	.font .jobde2:nth-last-child(2){
	    margin: 44px 40px 104px;
	    padding-bottom: 57px;
	    border-bottom: #e0e0e0 2px solid;
	}
	.font .jobde2 h1, .font .jobde2 p{
	    font-size: 40px;
	    line-height: 66px;
	    text-indent:0em;
	}
	.font #hrmail{
	    display:block;
	    position: relative;
	    left: 50%;
	    bottom: 0;
	    transform: translateX(-50%);
	    height: 142px;
	    width: 668px;
	    background-color: #dd1d29;
	    color:#fff;
	    font-size: 42px;
	    letter-spacing: 1px;
	    font-weight: 500; 
	    line-height: 142px;
	    text-align: center;
	}
	.font #hrmail img{
	    position: relative;
	    top: 0;
	    left: 0;
	    display: inline-block;
	    padding-left: 26px;
	    width: 16px;
	    height: 26px;
	    transition: left .3s;
	}
} */
</style>
