<template>
    <div class="buboxfooter">
        <div>
            <!-- <img src="./images/logo.png" alt="LOGO"> -->
            <p>
                <span>©{{time}} 北京凝思软件股份有限公司</span>
                <router-link to="/PrivacyPolicy">隐私政策</router-link>
                <span>|</span>
                <router-link to="/LegalNotices">法律声明</router-link>
                <span>|</span>
                <a target="_parent" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021006165号-1</a>
                <span>|</span>
                <a target="_parent" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024724">京公网安备11010802024724号</a>
                <span>|</span>
                <router-link to="/Home">www.linx-info.com</router-link>
            </p>
        </div>   
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'foot1',
    data() {
		return {
			time: "",
		};
	},
	mounted() {
    	this.nowtime()
    },
    methods:{
    	nowtime() {
			let nowDate = new Date();
			let date = {
				// 获取当前年份
				year: nowDate.getFullYear(),
			};
			//拼接
			this.time = date.year;
		},
    },
}
</script>

<style scoped>
.buboxfooter{
    width: 100%;
    height: 50px;
    background-color: #14161c;
}
.buboxfooter div{
    width: 1170px;
    margin: 0 auto;
    position: relative;
}
.buboxfooter div img{
    display:inline-block;
    height: 40px;
    position: absolute;
    left:0;
    top:5px;
}
.buboxfooter div p{
    position: absolute;
    left: 0;
    top:50%;
    color: #a0a3a8;
    line-height: 50px;
    font-size: 14px;
}
.buboxfooter div p span{
    font-size: 14px;
    color: #a0a3a8;
}
.buboxfooter div p a{
    font-size: 14px;
    color: #a0a3a8;
    transition: color .3s;
}
.buboxfooter div p a:hover{
    color: #fff;
    transition: color .3s;
}
.buboxfooter div p span:nth-child(1):after{
    content:"1111111";
    font-size: 14px;
    color: rgba(0,0,0,0);
}
.buboxfooter div p span:nth-child(1),.buboxfooter div p span:nth-child(3),.buboxfooter div p span:nth-child(5),.buboxfooter div p span:nth-child(7),.buboxfooter div p span:nth-child(9){
    font-size: 14px;
    margin:0 4px;
}
</style>
