<!-- 产品中心 -->
<template>
    <router-view :tittle="tittle"></router-view> 
</template>

<script>
export default {
    name: 'Products',
    data() {
		return {
			tittle: "产品中心",
		}
	},
}
</script>

<style>
/*详情页*/
#boxTittle3{
	position: relative;
	width:100%;
	padding:0 calc(50vw - 585px);
	left: calc(585px - 50vw);
	background-color:#f5f5f5;
	border-top:0px solid #fff;
}
#boxTittle3 #tittle3{
	position: relative;
	width:100%;
}
#boxTittle3 #tittle3:before{
	position: absolute;
	content:'';
	display:block;
	width:100%;
	left: 0;
	height:1px;
	background-color:#c2c2c2;
	top:0px;
}
#boxTittle3 #tittle3 a{
	position: relative;
	cursor: pointer;
	display:inline-block;
	width:128px;
	padding:22px 0;
	margin-right:44px;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 20px;
	color: #262b33;
}
#boxTittle3 #tittle3 a:hover:before,#boxTittle3 #tittle3 .intittle3:before{
	position: absolute;
	content:'';
	display:block;
	width:44px;
	height:3px;
	background-color:#dd1d29;
	left:0;
	top:-1px;
}
.intittle3{
	font-weight: 600!important;
}

#fontPdetail{
	background-color:rgba(0,0,0,0);
	width: 1170px;
	border: none;
	margin: 0;
}
#fontPdetail .textBox{
	padding:48px 0 34px;
}
#fontPdetail .textBoxBackground{
	position: relative;
	width:100%;
	padding:48px calc(50vw - 585px) 64px;
	left: calc(585px - 50vw);
	background:url('./images/固定背景.png') fixed center top;
	background-size:100vw auto;
	border-top: 1px solid #e0e0e0;
}
#fontPdetail .caseDisplayBox{
	padding: 16px 0 34px;
}
#fontPdetail .textBoxBorder{
	border-top: 1px solid #c2c2c2;
}
#fontPdetail .textBigTop{
	padding-top:112px;
}
#fontPdetail .textBox:last-child{
	padding-bottom:98px;
	/* margin-bottom:-130px; */
}
#fontPdetail .textBoxBackground:last-child{
	padding-bottom:128px;
}
#fontPdetail .textBox h2{
	margin: 0;
	color:#262b33;
	font-size: 24px;
	line-height: 56px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0em;
}
#fontPdetail .textBox .featureBox+h2{
	margin:80px 0 16px;
}
#fontPdetail .textBox .featureBox+.marginshort{
	margin:48px 0 0;
}
#fontPdetail .textBigTop h2{
	margin-bottom:8px;
}
#fontPdetail .textBox p{
	position: relative;
	margin: 0;
	color:#262b33;
	font-size: 18px;
	line-height: 30px;
	padding:10px 0;
	letter-spacing: 0px;
	font-weight: 400;
	text-indent: 0em;
}
#fontPdetail .textBox .tintcolor{
	color:#262b33;
}
#fontPdetail .textBox p b{
	display:block;
	color:#262b33;
	font-weight: 500;
	margin-bottom: 2px;
}
#fontPdetail .textBox p span{
	color:#0072ce;
}
.overstriking{
	font-weight: 500;
}
#fontPdetail .textBox > img{
	display:block;
	width:100%;
	margin: 8px 0 94px;
}
#fontPdetail .textBox > figure{
	display:block;
	width:100%;
	margin: 8px 0 94px;
}
#fontPdetail .textBox figure img{
	max-width:100%;
}
#fontPdetail .textBox figure p{
	text-align:center;
	color:#585f6b;
	font-size: 14px;
	line-height: 38px;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0em;
	padding:0;
	margin:0;
}
#fontPdetail .textBox .grayBlock{
	margin-top:0;
	/*background-color:#f7f7f7;
	border:1px solid #e0e0e0;*/
	padding:0;
}
#fontPdetail .textBox .grayBlock h4{
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0em;
	padding-top:40px;
}
#fontPdetail .textBox .grayBlock p{
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0em;
	padding:0;
}

#fontPdetail .textBox .textDetails{
	margin: 32px 0 0;
}
#fontPdetail .textBox .textDetails p{
	margin-bottom: 16px;
}

#fontPdetail .textBox .textDetails div{
	vertical-align:top;
	display:inline-block;
	width:514px;
}
#fontPdetail .textBox .textDetails div:last-child{
	margin-left:calc(100% - 514px - 514px - 4px);
}
#fontPdetail .textBox .textDetails .figure{
	padding-left: 10px;
	color:#262b33;
	line-height: 28px;
	font-weight:300;
}
#fontPdetail .textBox .figureweight .figure{
	font-weight:400;
}
#fontPdetail .textBox .textDetails figure{
	display:block;
	margin-top:-16px;
}
#fontPdetail .textBox .textDetails figure img{
	margin:0 auto;
	display:block;
}
#fontPdetail .textBox .textDetails p:before{
	position: absolute;
	content:'';
	display:block;
	width:34px;
	height:19px;
	background-size:auto 19px!important;
	left:0;
	top: 6px;
	z-index:-1;
}

#fontPdetail .textBox .featureBox{
	padding-top:0px;
}
/*#fontPdetail .textBox .featureBox .featureImage2{
	display: none;
}
#fontPdetail .textBox .featureBox .featureImage{
	display: grid;
	width:100%;
	grid-template-columns: repeat(5,149px);
	justify-content:space-around;
}
#fontPdetail .textBox .featureBox .featureImages6{
	grid-template-columns: repeat(6,149px);
}
#fontPdetail .textBox .featureBox .featureImage .feature{
	text-align:center;
	padding:calc(48px + 16px) 0 0;
	width:100%;
	margin:0 auto 32px;
	color:#292929;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0px;
	font-weight: 400;
	text-indent: 0em;
}
#fontPdetail .textBox .featureBox .featureImage:last-child .feature{
	margin:32px auto 0;
}
#fontPdetail .textBox .featureBox .featureImage .feature:before{
	position: absolute;
	content:'';
	display:block;
	width:100%;
	height:48px;
	left:0;
	top: 0;
	opacity:0;
}
#fontPdetail .textBox .featureBox .featureImage .feature:hover,
#fontPdetail .textBox .featureBox .featureImage .infeature{
	background:rgba(0,0,0,0)!important;
}
#fontPdetail .textBox .featureBox .featureImage .feature:hover:before,
#fontPdetail .textBox .featureBox .featureImage .infeature:before{
	opacity:1;
}
.infeature{
	color:#dd1d29!important;
	font-weight: 500!important;
}
#fontPdetail .textBox .featureBox .featureImage .infeature:after{
	position: absolute;
	content:'';
	background-color:#dd1d29;
	display:block;
	width:1px;
	height:22px;
	left: 50%;
	transform: translateX(-50%);
	bottom: -32px;
}
#fontPdetail .textBox .featureBox .featureImage:last-child .infeature:after{
	top: -32px;
}
#fontPdetail .textBox .featureBox .featureText{
	padding:30px 56px 34px;
	background-color:#fff;
	border:1px solid #e0e0e0;
}
#fontPdetail .textBox .featureBox .featureText h4{
	margin: 0;
	color:#141414;
	font-size: 16px;
	line-height: 36px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0em;
}
#fontPdetail .textBox .featureBox .featureText p{
	margin: 0;
	color:#333;
	font-size: 16px;
	line-height: 28px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0em;
}*/
#fontPdetail .textBox .featureBox .featureText1 .text{
	padding:32px 40px 36px;
	margin-top:28px;
	background-color:rgba(255,255,255,0.8);
	border:1px solid rgba(224,224,224,0.8);
}
#fontPdetail .textBox .featureBox .featureText1 .text:hover{
	background-color:rgba(255,255,255,1);
	border:1px solid rgba(224,224,224,1);
	box-shadow:0px 2px 10px 0px rgba(26,28,31,0.08);
}
#fontPdetail .textBox .featureBox .featureText1 .text:first-child{
	margin-top:8px;
}
#fontPdetail .textBox .featureBox .featureText1 .text h4{
	position: relative;
	margin: 0;
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 600;
	text-indent: 32px;
}
#fontPdetail .textBox .featureBox .featureText1 .text h4:before{
	position: absolute;
	content:'';
	display:inline-block;
	width:32px;
	height:34px;
	background:url('./images/箭头-黄.png') no-repeat center center;
	background-size:11px 16px;
	left:0;
}
#fontPdetail .textBox .featureBox .featureText1 .text p{
	margin: 0;
	color:#262b33;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 32px;
}
#fontPdetail .textBox .featureBox .featureText1 .text .notextIndent{
	text-indent: 0;
}
#fontPdetail .textBox .featureBox .featureText1 .text > div{
	position: relative;
	display:grid;
	grid-template-columns: 448px 448px;
	justify-content:space-around;
	margin-top:20px;
}
#fontPdetail .textBox .featureBox .featureText1 .text > div:before{
	position: absolute;
	content:'';
	display:block;
	width:1px;
	height:100%;
	background-color:rgba(224,224,224,0.8);
	left:50%;
}
#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n+1){
	position: relative;
	left:-16px;
}
#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n){
	position: relative;
	left:16px;
}
#fontPdetail .textBox .featureBox .featureText1 .text div div p{
	margin: 0;
	color:#565d68;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
	margin-top:20px;
}
#fontPdetail .textBox .featureBox .featureText1 .text div div p:first-child{
	margin-top: 0;
}
#fontPdetail .textBox .featureBox .featureText1 .text div div p span{
	display:block;
	color:#262b33;
	font-size: 16px;
	line-height: 28px;
	font-weight: 400;
}
#fontPdetail .textBox .featureBox .featureText2{
	position: relative;
	padding:4px 0 36px;
	background-color:rgba(255,255,255,1);
	border:1px solid rgba(224,224,224,1);
	display:grid;
	grid-template-columns: 496px 496px;
	justify-content:space-around;
	margin-top:8px;
}
#fontPdetail .textBox .featureBox .featureText2 > div:nth-child(2n+1){
	position: relative;
	left:-4px;
}
#fontPdetail .textBox .featureBox .featureText2 > div:nth-child(2n){
	position: relative;
	left:4px;
}
#fontPdetail .textBox .featureBox .featureText2 h4{
	position: relative;
	margin: 28px 0 0;
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0;
}
#fontPdetail .textBox .featureBox .featureText2 p{
	margin: 0;
	color:#565d68;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
	padding-left:3em;
}
#fontPdetail .textBox .featureBox .featureText2 p:before{
	display:block;
	position: absolute;
	left:0;
}
#fontPdetail .textBox .featureBox .featureText2 p:nth-child(2n):before{
	content:'特性:';
}
#fontPdetail .textBox .featureBox .featureText2 p:nth-child(2n+1):before{
	content:'优点:';
}
#fontPdetail .textBox .featureBox .featureText3{
	position: relative;
	padding:4px 0 36px;
	display:grid;
	grid-template-columns: 504px 504px;
	justify-content:space-between;
	margin-top:8px;
}
#fontPdetail .textBox .featureBox .featureText3 h4{
	position: relative;
	margin: 28px 0 0;
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0;
}
#fontPdetail .textBox .featureBox .featureText3 div div:first-child h4{
	margin: 0;
}
#fontPdetail .textBox .featureBox .featureText3 p{
	margin: 0;
	color:#262b33;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
	padding-left:0;
}
#fontPdetail .textBox .featureBox .featureText4{
	position: relative;
	display:grid;
	grid-template-columns: 565px 565px;
	justify-content:space-between;
	margin-top:16px;
}
#fontPdetail .textBox .featureBox .featureText4 .text{
	padding:24px 38px 36px;
	background-color:rgba(255,255,255,0.8);
	border:1px solid rgba(224,224,224,0.8);
}
#fontPdetail .textBox .featureBox .featureText4 .text:hover{
	background-color:rgba(255,255,255,1);
	border:1px solid rgba(224,224,224,1);
	box-shadow:0px 2px 10px 0px rgba(26,28,31,0.08);
}
#fontPdetail .textBox .featureBox .featureText4 .text h4{
	position: relative;
	margin: 0;
	color:#262b33;
	font-size: 18px;
	line-height: 50px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0;
}
#fontPdetail .textBox .featureBox .featureText4 .text p{
	margin: 0;
	color:#262b33;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
	padding-left:0;
}
#fontPdetail .textBox .featureBox .featureText5{
	position: relative;
	padding:4px 0 36px;
	background-color:rgba(255,255,255,1);
	border:1px solid rgba(224,224,224,1);
	display:grid;
	grid-template-columns: 496px 496px;
	justify-content:space-around;
	margin-top:8px;
}
#fontPdetail .textBox .featureBox .featureText5 > div:nth-child(2n+1){
	position: relative;
	left:-4px;
}
#fontPdetail .textBox .featureBox .featureText5 > div:nth-child(2n){
	position: relative;
	left:4px;
}
#fontPdetail .textBox .featureBox .featureText5 h4{
	position: relative;
	margin: 28px 0 0;
	color:#262b33;
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0;
}
#fontPdetail .textBox .featureBox .featureText5 p{
	margin: 0;
	color:#565d68;
	font-size: 16px;
	line-height: 24px;
	padding:0;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
	padding-left:0;
}


#fontPdetail .textBox .textDisplay{
	display:grid;
	grid-template-columns: 370px 370px 370px;
	grid-gap: 30px 30px;
	grid-auto-flow:row dense;
	margin-top:12px;
	margin-bottom:94px;
}
#fontPdetail .textBox:last-child .textDisplay{
	margin-bottom:30px;
}
#fontPdetail .textBox .textDisplay div{
	padding-bottom:31px;
	min-height:191px;
	background-color:#fff;
	border:1px solid #e0e0e0;
}
#fontPdetail .textBox .textDisplay div:hover{
	box-shadow:0px 4px 16px 0px rgba(15,17,20,0.08);
}
#fontPdetail .textBox .textDisplay div h4{
	position: relative;
	color:#262b33;
	font-size: 18px;
	line-height: 56px;
	padding-top:17px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 40px;
	background:url('./images/grid背景.png') no-repeat;
}
#fontPdetail .textBox .textDisplay div:hover h4{
	background:url('./images/grid背景-移入.png') no-repeat;
}
#fontPdetail .textBox .textDisplay div:hover h4:before{
	position: absolute;
	content:'';
	display:block;
	width:3px;
	height:14px;
	left:25px;
	top: 38px;
	background-color:#dd1d29;
	border-top-right-radius:2px;
	border-bottom-right-radius:2px;
}
#fontPdetail .textBox .textDisplay div h5{
	margin:0 40px 4px;
	padding:0;
	color:#262b33;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0px;
	font-weight: 500;
	text-indent: 0;
}
#fontPdetail .textBox .textDisplay div p+h5{
	margin:16px 40px 4px;
}
#fontPdetail .textBox .textDisplay div p{
	margin:0 40px;
	padding:0;
	color:#565d69;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0px;
	font-weight: 300;
	text-indent: 0;
}
#fontPdetail .textBox .textDisplay .longGrid{
	grid-column-start: 1;
	grid-column-end: 4;
}
#fontPdetail .textBox .textDisplay .longGrid h4{
	background:url('./images/grid长背景.png') no-repeat;
}
#fontPdetail .textBox .textDisplay .longGrid:hover h4{
	background:url('./images/grid长背景-移入.png') no-repeat;
}

#fontPdetail .textBox .textImage{
	display:grid;
	grid-template-columns: 637px 432px;
	justify-content:space-between;
	margin:16px 0 30px;
}
#fontPdetail .textBox .textImage div h2{
	padding-top:6px;
}
#fontPdetail .textBox .textImage img{
	width:432px;
	margin-bottom: 0px;
}

#fontPdetail .textBox .textImage2{
	display:grid;
	grid-template-columns: 506px 504px;
	justify-content:space-between;
	margin:16px 0 30px;
}
#fontPdetail .textBox .textImage2 div h2{
	padding:0 0 20px;
	margin: 0;
	font-size: 24px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 24px;
	color: #262b33;
	text-indent: 0em;
}
#fontPdetail .textBox .textImage2 div p{
	padding: 6px 0;
	line-height:28px;
}
#fontPdetail .textBox .textImage2 div div{
	padding-top: 38px;
}
#fontPdetail .textBox .textImage2 div div h2{
	padding:53px 0 10px;
	font-size: 22px;
	line-height: 22px;
}
#fontPdetail .textBox .textImage2 div div p{
	color: #262b33;
	line-height: 24px;
	padding:0;
}
#fontPdetail .textBox .textImage2 img{
	width: 504px;
	margin-bottom: 0px;
}
#fontPdetail .textBox .textImage2 div h6{
	margin-top: 12px;
	text-align:center;
	font-size: 14px;
	letter-spacing: 0px;
	font-weight: 300;
	line-height: 14px;
	color: #585f6b;
}

#fontPdetail .textBox .imgsDisplay{
	display: grid;
	width:100%;
	grid-gap: 0 27px;
	grid-template-columns: repeat(auto-fill,272px);
	margin-bottom:32px;
}
#fontPdetail .textBox .imgsDisplay div p{
	margin:0;
	padding:0;
	width: 272px;
	line-height: 204px;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	background-color:rgba(20,22,28,0.6);
	color:#fff;
	text-align:center;
}
#fontPdetail .textBox .imgsDisplay div img{
	position: absolute;
	width: 272px;
	height: 204px;
}
#fontPdetail .textBox .imgsDisplay2{
	display: grid;
	width:100%;
	grid-gap: 0 27px;
	grid-template-columns: repeat(auto-fill,272px);
	margin-top:16px;
}
#fontPdetail .textBox .imgsDisplay2 div p{
	margin:0;
	padding:0;
	width: 272px;
	line-height: 158px;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	background-color:rgba(20,22,28,0.6);
	color:#fff;
	text-align:center;
}
#fontPdetail .textBox .imgsDisplay2 div img{
	position: absolute;
	width: 272px;
	height: 158px;
}

#fontPdetail .textBox .tableBox{
	display: grid;
	grid-gap: 0;
	grid-template-columns: repeat(auto-fill,50%);
	border:1px solid #e0e0e0;
	margin:8px 0 48px;
}
#fontPdetail .textBox .tableBox div{
	padding:20px 84px 20px 28px;
}
#fontPdetail .textBox .tableBox div:first-child{
	background-color:#f5f5f5;
}
#fontPdetail .textBox .tableBox div p+p{
	margin-top:4px;
}
#fontPdetail .textBox .tableBox div p{
	color:#262b33;
	font-weight: 300;
}
#fontPdetail .textBox .tableBox div p b{
	display:inline;
	color:#262b33;
	font-weight: 500;
}
#fontPdetail .textBox .tablebutton{
	display:none;
}
#fontPdetail .textBox table{
	border:1px solid #e0e0e0;
	border-collapse: collapse;
	width: 100%;
	margin:8px 0 30px;
}
#fontPdetail .textBox table tr:nth-child(2n+1){
	background-color:#f5f5f5;
}
#fontPdetail .textBox table tr th{
	border:1px solid #e0e0e0;
	text-align:left;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 74px;
	color:#262b33;
	text-indent: 28px;
	background-color:#ebebeb;
}
#fontPdetail .textBox table tr td{
	border-left:1px solid #e0e0e0;
	text-align:left;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 66px;
	color:#262b33;
	text-indent: 28px;
}
#fontPdetail .textBox table tr td br{
	display:none;
}
#fontPdetail .tableinBox{
	border:none;
	padding:4px 0 0;
	margin:0;
}
#fontPdetail .tableinBox p{
	margin:0;
	font-weight:500;
}
#fontPdetail .tableinBox table{
	border:none;
	border-collapse: separate;
	border-spacing:0 20px;
	width: 100%;
	margin:-14px 0 -20px;
}
#fontPdetail .tableinBox table tr:nth-child(2n+1){
	background-color:rgba(0,0,0,0);
}
#fontPdetail .tableinBox table tr th{
	position: relative;
	top:20px;
	border:none;
	width:33.33%;
	border-top:1px solid #c2c2c2;
	border-bottom:1px solid #c2c2c2;
	text-align:left;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 64px;
	color:#262b33;
	text-indent: 50px;
	background-color:#f5f5f5;
}
#fontPdetail .tableinBox table tr th:first-child{
	border-left:1px solid #c2c2c2;
}
#fontPdetail .tableinBox table tr th:last-child{
	border-right:1px solid #c2c2c2;
}
#fontPdetail .tableinBox table tr td{
	border-left:none;
	text-align:left;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 28px;
	height: 116px;
	color:#fff;
	text-indent: 0;
	padding:0 50px;
}
#fontPdetail .tableinBox table tr td:nth-child(1){
	background-color:#00a4bf;
	border-left:1px solid #00a4bf;
}
#fontPdetail .tableinBox table tr td:nth-child(2){
	background-color:#1f609e;
}
#fontPdetail .tableinBox table tr td:nth-child(3){
	background-color:#96999c;
	border-right:1px solid #96999c;
}

/*文字、icon背景图*/
#fontPdetail .textBox .textDetails .figure1:before{
	background:url('./images/1.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure2:before{
	background:url('./images/2.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure3:before{
	background:url('./images/3.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure4:before{
	background:url('./images/4.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure5:before{
	background:url('./images/5.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure6:before{
	background:url('./images/6.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure7:before{
	background:url('./images/7.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure8:before{
	background:url('./images/8.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure9:before{
	background:url('./images/9.png') no-repeat left;
}
#fontPdetail .textBox .textDetails .figure10:before{
	background:url('./images/10.png') no-repeat left;
	left: -12px;
}
#fontPdetail .textBox .textDetails .figure11:before{
	background:url('./images/11.png') no-repeat left;
	left: -12px;
}
#fontPdetail .textBox .textDetails .figure12:before{
	background:url('./images/12.png') no-repeat left;
	left: -12px;
}
#fontPdetail .textBox .textDetails .figure13:before{
	background:url('./images/13.png') no-repeat left;
	left: -12px;
}
#fontPdetail .textBox .textDetails .figure14:before{
	background:url('./images/14.png') no-repeat left;
	left: -12px;
}
#fontPdetail .textBox .textDetails .figure15:before{
	background:url('./images/15.png') no-repeat left;
	left: -12px;
}
/*#fontPdetail .textBox .featureBox > div #feature1{
	background:url('./images/高安全-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature1:before{
	background:url('./images/高安全-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature2{
	background:url('./images/高可用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature2:before{
	background:url('./images/高可用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature3{
	background:url('./images/高兼容-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature3:before{
	background:url('./images/高兼容-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature4{
	background:url('./images/高效-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature4:before{
	background:url('./images/高效-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature5{
	background:url('./images/易维护-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature5:before{
	background:url('./images/易维护-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature6{
	background:url('./images/身份鉴别-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature6:before{
	background:url('./images/身份鉴别-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature7{
	background:url('./images/强制行为控制MBC-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature7:before{
	background:url('./images/强制行为控制MBC-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature8{
	background:url('./images/强制能力控制MCC-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature8:before{
	background:url('./images/强制能力控制MCC-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature9{
	background:url('./images/访问控制列表ACL-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature9:before{
	background:url('./images/访问控制列表ACL-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature10{
	background:url('./images/强制访问控制MAC-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature10:before{
	background:url('./images/强制访问控制MAC-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature11{
	background:url('./images/安全审计-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature11:before{
	background:url('./images/安全审计-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature12{
	background:url('./images/四权分立-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature12:before{
	background:url('./images/四权分立-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature13{
	background:url('./images/无root运行环境-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature13:before{
	background:url('./images/无root运行环境-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature14{
	background:url('./images/内核级的防火墙-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature14:before{
	background:url('./images/内核级的防火墙-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature15{
	background:url('./images/冗余容错功能-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature15:before{
	background:url('./images/冗余容错功能-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature16{
	background:url('./images/高可用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature16:before{
	background:url('./images/高可用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature17{
	background:url('./images/软件固化-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature17:before{
	background:url('./images/软件固化-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature18{
	background:url('./images/高可用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature18:before{
	background:url('./images/高可用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature19{
	background:url('./images/动态可扩展-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature19:before{
	background:url('./images/动态可扩展-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature20{
	background:url('./images/易用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature20:before{
	background:url('./images/易用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature21{
	background:url('./images/通用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature21:before{
	background:url('./images/通用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature22{
	background:url('./images/高效-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature22:before{
	background:url('./images/高效-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature23{
	background:url('./images/高安全-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature23:before{
	background:url('./images/高安全-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature24{
	background:url('./images/高兼容-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature24:before{
	background:url('./images/高兼容-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature25{
	background:url('./images/易管理-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature25:before{
	background:url('./images/易管理-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature26{
	background:url('./images/高安全-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature26:before{
	background:url('./images/高安全-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature27{
	background:url('./images/高可用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature27:before{
	background:url('./images/高可用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature28{
	background:url('./images/动态可扩展-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature28:before{
	background:url('./images/动态可扩展-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature29{
	background:url('./images/通用-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature29:before{
	background:url('./images/通用-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature30{
	background:url('./images/易管理-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature30:before{
	background:url('./images/易管理-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature31{
	background:url('./images/易维护-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature31:before{
	background:url('./images/易维护-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature32{
	background:url('./images/强制运行控制机制-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature32:before{
	background:url('./images/强制运行控制机制-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature33{
	background:url('./images/强制能力控制MCC-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature33:before{
	background:url('./images/强制能力控制MCC-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature34{
	background:url('./images/访问控制列表ACL-静默.png') no-repeat center top;
	width: 162px;
}
#fontPdetail .textBox .featureBox > div #feature34:before{
	background:url('./images/访问控制列表ACL-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature35{
	background:url('./images/四权分立-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature35:before{
	background:url('./images/四权分立-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature36{
	background:url('./images/提供安全增强服务-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature36:before{
	background:url('./images/提供安全增强服务-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature37{
	background:url('./images/冗余容错功能-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature37:before{
	background:url('./images/冗余容错功能-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature38{
	background:url('./images/热备与互备-静默.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div #feature38:before{
	background:url('./images/热备与互备-选中.png') no-repeat center top;
}
#fontPdetail .textBox .featureBox > div .feature{
	background-size:auto 48px!important;
}
#fontPdetail .textBox .featureBox > div .feature:before{
	background-size:auto 48px!important;
}*/
/*pad横屏*/
@media only screen and (max-width:1170px){
	/*详情页*/
	#boxTittle3{
		padding:0;
		width: 100vw;
		left: calc(452px - 50vw);
		border-top:1px solid #c2c2c2;
		overflow-x: auto;
	}
	#boxTittle3 #tittle3 {
		width: 1204px;
		white-space: nowrap;
		padding:0 0 0 48px;
	}
	#boxTittle3 #tittle3:before{
		display:none;
	}
	#fontPdetail{
		width: 100%;
	}
	#fontPdetail .textBox{
		padding:48px 0 50px;
	}
	#fontPdetail .textBoxBackground{
		padding:48px calc(50vw - 452px) 64px;
		left: calc(452px - 50vw);
		background:url('./images/固定背景2.png') repeat center top;
	}

	#fontPdetail .textBigTop{
		padding-top:64px;
	}
	#fontPdetail .textBox:last-child{
		padding-bottom:88px;
	}
	#fontPdetail .textBoxBackground:last-child{
		padding-bottom:118px;
	}
	#fontPdetail .textBox .featureBox+h2{
		margin:40px 0 16px;
	}
	#fontPdetail .textBox p{
		font-size: 16px;
		line-height: 28px;
	}
	#fontPdetail .textBox > img{
		margin: 8px 0 62px;
	}
	
	#fontPdetail .textBox .textDetails{
		margin: 16px 0 0;
	}
	#fontPdetail .textBox .textDetails div{
		width:392px;
	}
	#fontPdetail .textBox .textDetails > p:last-child{
		margin-bottom:28px;
	}
	#fontPdetail .textBox .textDetails div:last-child{
		margin-left:calc(100% - 392px - 392px - 4px);
	}
	#fontPdetail .textBox .textDetails .figure{
		line-height:24px;
		padding: 12px 0;
		padding-left: 10px;
	}

	/*#fontPdetail .textBox .featureBox .featureImage{
		grid-template-columns: repeat(5,70px);
	}
	#fontPdetail .textBox .featureBox .featureImages6{
		grid-template-columns: repeat(6,70px);
	}
	#fontPdetail .textBox .featureBox .featureText{
		padding:26px 36px 30px;
	}
	#fontPdetail .textBox .featureBox .featureImage .feature {
		padding: calc(48px + 14px) 0 0;
		margin: 0 auto 30px;
		font-size: 16px;
		line-height: 20px;
	}
	#fontPdetail .textBox .featureBox .featureImage #feature19 {
		white-space:nowrap;
	}
	#fontPdetail .textBox .featureBox > div #feature34{
		width: 90px;
	}*/
	#fontPdetail .textBox .featureBox .featureText1 .text > div{
		grid-template-columns: 322px 322px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n+1){
		left:-14px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n){
		left:14px;
	}
	#fontPdetail .textBox .featureBox .featureText2{
		grid-template-columns: 370px 370px;
	}
	#fontPdetail .textBox .featureBox .featureText3{
		grid-template-columns: 378px 378px;
	}
	#fontPdetail .textBox .featureBox .featureText4{
		grid-template-columns: 439px 439px;
	}
	#fontPdetail .textBox .featureBox .featureText5{
		grid-template-columns: 370px 370px;
	}
	
	#fontPdetail .textBox .textDisplay{
		grid-template-columns: 370px 370px;
		grid-gap: 40px 40px;
		margin-bottom:46px;
		justify-content:center;
	}
	#fontPdetail .textBox .textDisplay div{
		padding-bottom:28px;
	}
	#fontPdetail .textBox .textDisplay div h4{
		padding-top:16px;
		line-height: 50px;
	}
	#fontPdetail .textBox .textDisplay div:hover h4:before{
		top: 34px;
	}
	#fontPdetail .textBox .textDisplay div h5{
		font-size: 16px;
		line-height: 24px;
	}
	#fontPdetail .textBox .textDisplay div p{
		font-size: 16px;
		line-height: 24px;
	}
	#fontPdetail .textBox .textDisplay .longGrid{
		grid-column-start: 1;
		grid-column-end: 3;
	}

	#fontPdetail .textBox .textImage{
		grid-template-columns: 385px 432px;
		margin:16px 0 -18px;
	}
	#fontPdetail .textBox .textImage div h2{
		padding-top:0px;
		margin-top: -16px;
		margin-bottom: -6px;
		white-space:nowrap;
	}

	#fontPdetail .textBox .textImage2{
		grid-template-columns: 386px 452px;
	}
	#fontPdetail .textBox .textImage2 div h2{
		padding:0 0 10px;
		font-size: 20px;
		line-height: 20px;
	}
	#fontPdetail .textBox .textImage2 div p{
		padding: 8px 0;
		line-height: 26px;
	}
	#fontPdetail .textBox .textImage2 div div{
		padding-top: 21px;
	}
	#fontPdetail .textBox .textImage2 div div h2{
		padding:46px 0 10px;
		font-size: 20px;
		line-height: 20px;
	}
	#fontPdetail .textBox .textImage2 div div p{
		line-height: 20px;
	}
	#fontPdetail .textBox .textImage2 img{
		width: 452px;
		margin-bottom: 0px;
	}

	#fontPdetail .textBox .imgsDisplay,#fontPdetail .textBox .imgsDisplay2{
		grid-gap: 0 28px;
		grid-template-columns: repeat(auto-fill,205px);
	}
	#fontPdetail .textBox .imgsDisplay div p{
		width: 205px;
		line-height: 153px;
		font-size: 18px;
	}
	#fontPdetail .textBox .imgsDisplay div img{
		width: 205px;
		height: 153px;
	}
	#fontPdetail .textBox .imgsDisplay2 div p{
		width: 205px;
		line-height: 120px;
		font-size: 18px;
	}
	#fontPdetail .textBox .imgsDisplay2 div img{
		width: 205px;
		height: 120px;
	}

	#fontPdetail .textBox .tableBox div{
		padding:18px 30px;
	}
	#fontPdetail .textBox .tableBox div p{
		padding:8px 0;
	}
	#fontPdetail .textBox .tableBox div p+p{
		margin-top:0px;
	}
	#fontPdetail .textBox table tr th, #fontPdetail .textBox table tr td{
		font-size: 16px;
		line-height: 62px;
	}
	#fontPdetail .textBox table tr th:first-child,#fontPdetail .textBox table tr td:first-child{
		width:215px
	}
	#fontPdetail .tableinBox table tr th{
		font-size: 16px;
		line-height: 62px;
		text-indent: 40px;
	}
	#fontPdetail .tableinBox table tr td{
		font-size: 16px;
		line-height: 26px;
		height: 116px;
		padding:0 40px;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	/*详情页*/
	#boxTittle3 {
		left: calc(360px - 50vw);
	}
	#boxTittle3 #tittle3{
		background-color:#f5f5f5;
		padding:0 32px;
	}
	#boxTittle3 #tittle3 a{
		margin-left:22px;
		margin-right:22px;
	}
	
	#fontPdetail .textBoxBackground{
		padding:48px calc(50vw - 360px) 64px;
		left: calc(360px - 50vw);
		background:url('./images/固定背景2.png') fixed center top;
	}

	#fontPdetail .textBox .textDetails div{
		width:100%;
	}
	#fontPdetail .textBox .textDetails div:last-child{
		margin-left:0;
	}

	#fontPdetail .textBox .textDisplay{
		grid-template-columns: 335px 335px;

	}
	#fontPdetail .textBox .textDisplay .longGrid h4{
		background: url('./images/grid长背景.png') no-repeat right;
	}
	
	#fontPdetail .textBox .featureBox .featureText1 .text > div{
		grid-template-columns: 256px 256px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n+1){
		left:-14px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n){
		left:14px;
	}
	#fontPdetail .textBox .featureBox .featureText2{
		grid-template-columns: 304px 304px;
	}
	#fontPdetail .textBox .featureBox .featureText3{
		grid-template-columns: 312px 312px;
	}
	#fontPdetail .textBox .featureBox .featureText4{
		grid-template-columns: 347px 347px;
	}
	#fontPdetail .textBox .featureBox .featureText5{
		grid-template-columns: 304px 304px;
	}

	#fontPdetail .textBox .textImage{
		grid-template-columns: 260px 432px;
	}
	#fontPdetail .textBox .textImage img{
		padding-top:48px;
	}

	#fontPdetail .textBox .textImage2{
		grid-template-columns: 256px 398px;
	}
	#fontPdetail .textBox .textImage2 > div > p:nth-child(2){
		width:720px;
	}
	#fontPdetail .textBox .textImage2 div div{
		padding-top: 0;
	}
	#fontPdetail .textBox .textImage2 div div h2{
		padding:106px 0 10px;
	}
	#fontPdetail .textBox .textImage2 div div h2:first-child{
		padding:34px 0 10px;
	}
	#fontPdetail .textBox .textImage2 img{
		margin-top:172px;
		width: 398px;
	}
	
	#fontPdetail .textBox .imgsDisplay, #fontPdetail .textBox .imgsDisplay2{
		grid-gap: 0 25px;
		grid-template-columns: repeat(auto-fill,161px);
	}
	#fontPdetail .textBox .imgsDisplay div p{
		width: 161px;
		line-height: 123px;
	}
	#fontPdetail .textBox .imgsDisplay div img{
		width: 161px;
		height: 123px;
	}
	#fontPdetail .textBox .imgsDisplay2 div p{
		width: 161px;
		line-height: 95px;
	}
	#fontPdetail .textBox .imgsDisplay2 div img{
		width: 161px;
		height: 95px;
	}
	
	#fontPdetail .textBox table tr th, #fontPdetail .textBox table tr td{
		line-height: 20px;
		height: 62px;
		text-indent: 0px;
		padding:0 28px;
	}
	#fontPdetail .textBox table tr td br{
		display:block; 
	}
	#fontPdetail .textBox table tr th:first-child,#fontPdetail .textBox table tr td:first-child{
		width:calc(160px - 28px - 28px)
	}
	#fontPdetail .tableinBox table tr th{
		font-size: 16px;
		line-height: 62px;
		text-indent: 40px;
		padding:0;
	}
	#fontPdetail .tableinBox table tr td{
		font-size: 16px;
		line-height: 26px;
		height: 148px;
		padding:0 40px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	/*详情页*/
	#boxTittle3{
		left: 0;
	}
	#boxTittle3 #tittle3{
		background-color:#f5f5f5;
		width: 846px;
	}
	#boxTittle3 #tittle3 a{
		width:85px;
		padding: 13.5px 0;
		margin-left:15px;
		margin-right:13px;
		font-size: 14px;
		line-height: 17px;
		white-space:normal;
	}
	#boxTittle3 #tittle3 a:nth-child(4),
	#boxTittle3 #tittle3 a:nth-child(5) {
		width: 105px;
	}
	#boxTittle3 #tittle3 a:last-child{
		width:98px;
		margin-right:15px;
	}
	#boxTittle3 #tittle3 a:hover:before,#boxTittle3 #tittle3 .intittle3:before{
		width:30px;
		height:1.5px;
		top:-1px;
		z-index: 999;
	}
	#boxTittle3 #tittle3 a br{
		display:none;
	}
	#fontPdetail{
		width: 100%;
	}
	#fontPdetail .textBox{
		padding:40px 0 49px;
	}
	#fontPdetail .textBoxBackground{
		padding:30px 0 64px;
		left: 0;
		/* background:url('./images/固定背景3.png') fixed center top; */
		background:url('./images/固定背景3.png') repeat center top;
		border-top: 0.5px solid #e0e0e0;
	}
#fontPdetail .caseDisplayBox{
	padding: 8px 0 17px;
}
	#fontPdetail .textBoxBorder{
		border-top: 0.5px solid #c2c2c2;
		padding-top:64px;
	}
	#fontPdetail .textBigTop{
		padding-top:64px;
	}
	#fontPdetail .textBox:last-child{
		padding-bottom:49px;
		margin-bottom:0px;
	}
	#fontPdetail .textBoxBackground:last-child{
		padding-bottom:64px;
	}
	#fontPdetail .textBox h2{
		margin: -4px 15px 16px;
		font-size: 15px;
		line-height: 23px;
		text-indent: 0;
		font-weight:600;
	}
	#fontPdetail .textBox .featureBox+h2{
		margin:36px 15px 16px;
	}
	#fontPdetail .textBox .featureBox + .marginshort {
		margin: 24px 15px 0;

	}
	#fontPdetail .textBigTop h2{
		margin-bottom: 6px;
	}
	#fontPdetail .textBox p{
		margin: 0 15px;
		font-size: 14px;
		line-height: 22px;
		padding:6px 0;
	}
	#fontPdetail .textBox p b{
		margin: 0 0 2px;
	}
	#fontPdetail .textBox > img{
		display:block;
		width:calc(100% - 30px);
		margin: 10px auto 15px;
	}
	#fontPdetail .textBox > figure{
		display:block;
		width:calc(100% - 30px);
		margin: 10px auto 15px;
	}
	#fontPdetail .textBox figure img{
		max-width:100%;
	}
	#fontPdetail .textBox figure p{
		font-size: 14px;
		line-height: 34px;
	}
	#fontPdetail .textBox .grayBlock{
		margin: 0 15px;
		background-color:#fff;
		border:none;
		padding:0;
	}
	#fontPdetail .textBox .grayBlock h4{
		font-size: 14px;
		line-height: 24px;
		padding-top:0px;
	}
	#fontPdetail .textBox .grayBlock p+h4{
		padding-top:20px;
	}
	#fontPdetail .textBox .grayBlock p{
		font-size: 14px;
		line-height: 24px;
		padding:0;
		margin:0;
	}

	#fontPdetail .textBox .textDetails{
		margin: 10px 0 0;
	}
	#fontPdetail .textBox .textDetails p{
		margin-bottom: 7px;
	}
	#fontPdetail .textBox .textDetails > p:last-child{
		margin-bottom:7px;
	}
	#fontPdetail .textBox .textDetails div{
		width:100%;
	}
	#fontPdetail .textBox .textDetails div:last-child{
		margin-left:0;
		margin-bottom: 0;
	}
	#fontPdetail .textBox .textDetails .figure{
		line-height: 18px;
		padding-left: 7px;
		font-weight: 400;
	}
#fontPdetail .textBox .textDetails figure{
	display:block;
	margin-top:-16px;
}
#fontPdetail .textBox .textDetails figure img{
	margin:0 auto;
	display:block;
}

	#fontPdetail .textBox .textDetails p:before{
		width:26px;
		height:14.5px;
		background-size:auto 14.5px!important;
		left:0;
		top: 1px;
	}

	#fontPdetail .textBox .featureBox .featureText1 .text{
		padding:11.5px 30px 21.5px;
		margin:16px 15px 0;
		background-color:rgba(255,255,255,0.8);
		border:1px solid rgba(224,224,224,0.8);
	}
	#fontPdetail .textBox .featureBox .featureText1 .text:hover{
		background-color:rgba(255,255,255,1);
		border:1px solid rgba(224,224,224,1);
		box-shadow:0px 2px 10px 0px rgba(26,28,31,0.08);
	}
	#fontPdetail .textBox .featureBox .featureText1 .text:first-child{
		margin-top:0px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text h4{
		font-size: 14px;
		line-height: 37px;
		text-indent: 28px;
		font-weight:500;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text h4:before{
		width:28px;
		height:37px;
		background-size:auto 13px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text p{
		font-size: 14px;
		line-height: 21px;
		text-indent: 2em;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text .notextIndent{
		text-indent: 2em;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div{
		grid-template-columns: 100%;
		justify-content:center;
		margin-top:0;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div:before{
		display:none;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n+1),
	#fontPdetail .textBox .featureBox .featureText1 .text > div div:nth-child(2n){
		left:0;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text div div p{
		font-size: 14px;
		line-height: 21px;
		margin-top:11px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text div div p:first-child{
		margin-top: 11px;
	}
	#fontPdetail .textBox .featureBox .featureText1 .text div div p span{
		font-size: 14px;
		line-height: 29px;
		font-weight: 400;
	}
	#fontPdetail .textBox .featureBox .featureText2{
		padding:6.5px 0 21.5px;
		background-color:rgba(255,255,255,1);
		border:1px solid rgba(224,224,224,1);
		display:grid;
		grid-template-columns: calc(100% - 60px);
		justify-content:space-around;
		margin:0 15px;
	}
	#fontPdetail .textBox .featureBox .featureText2 > div:nth-child(2n+1),
	#fontPdetail .textBox .featureBox .featureText2 > div:nth-child(2n){
		left:0;
	}
	#fontPdetail .textBox .featureBox .featureText2 h4{
		margin: 11px 0 0;
		font-size: 14px;
		line-height: 37px;
	}
	#fontPdetail .textBox .featureBox .featureText2 p{
		font-size: 14px;
		line-height: 21px;
	}
	#fontPdetail .textBox .featureBox .featureText3{
		position: relative;
		padding:0;
		display:grid;
		grid-template-columns: 100%;
		justify-content:space-between;
		margin-top:0px;
		margin-bottom:-32px;
	}
	#fontPdetail .textBox .featureBox .featureText3 h4{
		margin: 11px 15px 0;
		font-size: 14px;
		line-height: 37px;
		text-indent: 0;
	}
	#fontPdetail .textBox .featureBox .featureText3 div div:first-child h4{
		margin: -1.5px 15px 0;
	}
	#fontPdetail .textBox .featureBox .featureText3 p{
		margin: 0 15px;
		font-size: 14px;
		line-height: 21px
	}
	#fontPdetail .textBox .featureBox .featureText4{
		grid-template-columns: 100%;
		margin:0 15px;
	}
	#fontPdetail .textBox .featureBox .featureText4 .text{
		padding:17.5px 30px 21.5px;
		background-color:rgba(255,255,255,1);
		border:1px solid rgba(224,224,224,1);
		margin-bottom:16px;
	}
	#fontPdetail .textBox .featureBox .featureText4 .text:hover{
		box-shadow:0px 2px 10px 0px rgba(26,28,31,0.08);
	}
	#fontPdetail .textBox .featureBox .featureText4 .text h4{
		font-size: 14px;
		line-height: 37px;
	}
	#fontPdetail .textBox .featureBox .featureText4 .text p{
		font-size: 14px;
		line-height: 21px
	}
	#fontPdetail .textBox .featureBox .featureText5{
		padding:6.5px 0 21.5px;
		background-color:rgba(255,255,255,1);
		border:1px solid rgba(224,224,224,1);
		display:grid;
		grid-template-columns: calc(100% - 60px);
		justify-content:space-around;
		margin:0 15px;
	}
	#fontPdetail .textBox .featureBox .featureText5 > div:nth-child(2n+1),
	#fontPdetail .textBox .featureBox .featureText5 > div:nth-child(2n){
		left:0;
	}
	#fontPdetail .textBox .featureBox .featureText5 h4{
		margin: 11px 0 0;
		font-size: 14px;
		line-height: 37px;
	}
	#fontPdetail .textBox .featureBox .featureText5 p{
		font-size: 14px;
		line-height: 21px;
	}

	#fontPdetail .textBox .textDisplay{
		display:grid;
		grid-template-columns: calc(100% - 30px);
		grid-gap: 30px;
		grid-auto-flow:row dense;
		margin-top:12px;
		margin-bottom:15px;
	}
	#fontPdetail .textBox:last-child .textDisplay{
		margin-bottom:15px;
	}
	#fontPdetail .textBox .textDisplay div{
		padding-bottom:24px;
		min-height:95.5px;
		border:0.5px solid #e0e0e0;
		background:url('./images/grid背景750.png') no-repeat top right;
		background-size:750px auto;
	}
	#fontPdetail .textBox .textDisplay div:hover{
		background:url('./images/grid背景750-移入.png') no-repeat top right;
		background-size:750px auto;
	}
	#fontPdetail .textBox .textDisplay div h4{
		font-size: 15px;
		line-height: 39px;
		padding-top: 16px;
		letter-spacing: 0px;
		text-indent: 28px;
		background:none;
	}
	#fontPdetail .textBox .textDisplay div:hover h4{
		background:none;
	}
	#fontPdetail .textBox .textDisplay div:hover h4:before{
		position: absolute;
		content:'';
		display:block;
		width:2.5px;
		height:12px;
		left:18px;
		top: 29px;
		background-color:#dd1d29;
		border-top-right-radius:2px;
		border-bottom-right-radius:2px;
	}
	#fontPdetail .textBox .textDisplay div h5{
		margin:0 28px 4px;
		font-size: 14px;
		line-height: 22px;
	}
	#fontPdetail .textBox .textDisplay div p+h5{
		margin:16px 28px 4px;
	}
	#fontPdetail .textBox .textDisplay div p{
		margin:0 25px;
		font-size: 14px;
		line-height: 22px;
	}
	#fontPdetail .textBox .textDisplay .longGrid{
		grid-column-start: 1;
		grid-column-end: 2;
	}
	#fontPdetail .textBox .textDisplay .longGrid h4{
		background:none;
	}
	#fontPdetail .textBox .textDisplay .longGrid:hover h4{
		background:none;
	}

	#fontPdetail .textBox .textImage{
		display:grid;
		grid-template-columns: 100%;
		justify-content:space-between;
		margin:0 0 -49px;
	}
	#fontPdetail .textBox .textImage div h2{
		padding-top:0;
		margin: 0 15px 20px;
		white-space:normal;
	}
	#fontPdetail .textBox .textImage img{
		width:calc(100% - 30px);
		margin: 0 15px;
		padding-top: 10px;
	}

	#fontPdetail .textBox .textImage2{
		display:grid;
		grid-template-columns: 100%;
		justify-content:space-between;
		margin:0;
		position: relative;
	}
	#fontPdetail .textBox .textImage2 div h2{
		margin: -4px 15px 10px;
		font-size: 15px;
		line-height: 23px;
		padding:0;
	}
	#fontPdetail .textBox .textImage2 div p{
		padding: 7px 0;
	}
	#fontPdetail .textBox .textImage2 div div{
		padding-top: 16px;
		position: relative;
	}
	#fontPdetail .textBox .textImage2 div div h2, 
	#fontPdetail .textBox .textImage2 div div h2:first-child{
		padding:0;
		margin:16px 15px -1px;
		font-weight:500;
		font-size:14px;
		line-height:32px;
	}
	#fontPdetail .textBox .textImage2 div div p{
		line-height:18px;
	}
	#fontPdetail .textBox .textImage2 > div > p:nth-child(2) {
		width: calc(100% - 30px);
	}
	#fontPdetail .textBox .textImage2 img{
		display:block;
		width: 252px;
		margin: 10px auto 0;
	}
	#fontPdetail .textBox .textImage2 div h6{
		margin-top: 15px;
		font-size: 13px;
		line-height: 13px;
	}

	#fontPdetail .textBox .imgsDisplay,
	#fontPdetail .textBox .imgsDisplay2{
		display: grid;
		grid-gap: 15px;
		width: 100%;
		grid-template-columns: 148px 133px 133px 148px;
		margin-bottom:0;
		overflow: auto;
	}
	#fontPdetail .textBox .imgsDisplay div,
	#fontPdetail .textBox .imgsDisplay2 div{
		position: relative;
	}
	#fontPdetail .textBox .imgsDisplay div:first-child,
	#fontPdetail .textBox .imgsDisplay2 div:first-child{
		left: 15px;
	}
	#fontPdetail .textBox .imgsDisplay div p{
		width: 133px;
		line-height: 94px;
		font-size: 14px;
	}
	#fontPdetail .textBox .imgsDisplay div img{
		width: 133px;
		height: 94px;
		top:0;
		left:0;
	}
	#fontPdetail .textBox .imgsDisplay2 div p{
		width: 133px;
		line-height: 78px;
		font-size: 14px;
	}
	#fontPdetail .textBox .imgsDisplay2 div img{
		width: 133px;
		height: 78px;
		top:0;
		left:0;
	}

	#fontPdetail .textBox .tableBox{
		display: grid;
		grid-gap: 0;
		grid-template-columns: 100%;
		border:0.5px solid #e0e0e0;
		margin:10px 15px 20px;
	}
	#fontPdetail .textBox .tableBox div{
		padding:0 5px 14px;
	}
	#fontPdetail .textBox .tableBox div:first-child{
		padding:14px 10px 0;
	}
	#fontPdetail .textBox .tableBox div{
		background-color:#f5f5f5;
	}
	#fontPdetail .textBox .tableBox div p {
		padding: 6px 0;
	}
	#fontPdetail .textBox .tableBox div p+p{
		margin-top:0;
	}
	
	#fontPdetail .textBox .tablebutton{
		display: grid;
		width: 324px;
		height:50px;
		background:url('./images/选项卡背景.png') no-repeat;
		background-size:324px 50px;
		grid-template-columns: 108px 108px 108px;
		margin:30px auto 30px;
	}
	#fontPdetail .textBox .tablebutton div{
		display:inline-block;
		width: 107.5px;
		line-height:50px;
		text-align:center;
		font-size: 14px;
		letter-spacing: 0px;
		font-weight: 400;
		color: #141414;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(1){
		color: #fff;
		background:url('./images/选中左侧.png') no-repeat;
		background-size:107.5px 50px;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(2){
		color: #fff;
		background:url('./images/选中中间.png') no-repeat;
		background-size:107.5px 50px;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(3){
		color: #fff;
		background:url('./images/选中右侧.png') no-repeat;
		background-size:107.5px 50px;
	}
	#fontPdetail .textBox table{
		border:none;
		border-collapse: collapse;
		width: calc(100% - 30px);
		margin:0 auto 15px;
		box-shadow:0px 1px 8px 0px rgba(5,5,5,0.08);
	}
	#fontPdetail .textBox table tr{
		position: relative;
	}
	#fontPdetail .textBox table tr th{
		border:none;
		text-align:center;
		font-size: 14px;
		line-height: 80px;
		height: 80px;
		color: #141414;
		text-indent: 0;
		padding:0;
		z-index:99;
	}
	#fontPdetail .textBox table tr td{
		border-left:none;
		text-align:center;
		font-size: 14px;
		line-height: 40px;
		height: 40px;
		color: #525252;
		text-indent: 0;
		padding:0;
		background-color:#f5f5f5;
		z-index:9;
	}
	#fontPdetail .textBox table tr th:nth-child(1),
	#fontPdetail .textBox table tr td:nth-child(1){
		background-color:#e6555f;
		width:136px;
		color: #fff;
	}
	#fontPdetail .textBox table tr th:nth-child(3),
	#fontPdetail .textBox table tr td:nth-child(3),
	#fontPdetail .textBox table tr th:nth-child(4),
	#fontPdetail .textBox table tr td:nth-child(4){
		position: absolute;
		width: calc(100% - 136px);
		right: 0;
	}
	#fontPdetail .textBox table tr .tryth{
		background-color:#f5f5f5;
		width:calc(100% - 136px);
		position: absolute;
		right:0;
		opacity:0;
		transition:color 0.3s;
	}
	#fontPdetail .textBox table tr .trytd{
		background-color:#f5f5f5;
		width:calc(100% - 136px);
		position: absolute;
		right:0;
		opacity:0;
		transition:color 0.3s;
	}
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(1),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(1){
		background-color:#e0343e;
	}
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(2),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(2),
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(3),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(3),
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(4),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(4){
		background-color:#fff;
	}
	#fontPdetail .textBox table tr td br{
		display:none;
	}
#fontPdetail .textBox .featureBox > div .feature{
	background-size: 24px!important;
}
#fontPdetail .textBox .featureBox > div .feature:before{
	background-size: 24px!important;
}
#fontPdetail .textBox .featureBox .featureImage2 div:first-child:after{
	background:url('./images/01.png') no-repeat left;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(2):after{
	background:url('./images/02.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(3):after{
	background:url('./images/03.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(4):after{
	background:url('./images/04.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(5):after{
	background:url('./images/05.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(6):after{
	background:url('./images/06.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(7):after{
	background:url('./images/07.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(8):after{
	background:url('./images/08.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(9):after{
	background:url('./images/09.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(10):after{
	background:url('./images/010.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(11):after{
	background:url('./images/011.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(12):after{
	background:url('./images/012.png') no-repeat center;
}
}
/*手机端1080
@media only screen and (min-height:1300px){

	详情页
	#boxTittle3{
		width:100%;
		padding:0;
		left: 0;
		background-color:#fff;
		border-top:none;
		padding-top:4px;
		overflow-x:scroll;
	}
	#boxTittle3::-webkit-scrollbar{
		display:none;
	}
	#boxTittle3 #tittle3{
		width:1352px;
		white-space:nowrap;
		background-color:#f5f5f5;
	}
	#boxTittle3 #tittle3:before{
		display:block;
		height:2px;
		top:-2px;
	}
	#boxTittle3 #tittle3 a{
		width:240px;
		padding: 34px 0;
		margin-left:44px;
		margin-right:40px;
		font-size: 40px;
		line-height: 50px;
		white-space:normal;
	}
	#boxTittle3 #tittle3 a:last-child{
		width:280px;
		margin-right:44px;
	}
	#boxTittle3 #tittle3 a:hover:before,
	#boxTittle3 #tittle3 .intittle3:before{
		width:92px;
		height:6px;
		top:-4px;
	}
	#boxTittle3 #tittle3 a br{
		display:none;
	}
	#fontPdetail{
		width: 100%;
	}
	#fontPdetail .textBox{
		padding:115px 0 130px;
	}
	#fontPdetail .textBoxBackground{
		padding:86px 0 186px;
		left: 0;
		background:url('./images/固定背景4.png') repeat center top;
		border-top: 2px solid #e0e0e0;
	}
#fontPdetail .caseDisplayBox{
padding: 16px 0 34px;
}
	#fontPdetail .textBoxBorder{
		border-top: 2px solid #c2c2c2;
		padding-top:186px;
	}
	#fontPdetail .textBigTop{
		padding-top:186px;
	}
	#fontPdetail .textBox:last-child{
		padding-bottom:130px;
		margin-bottom:-207px;
	}
	#fontPdetail .textBoxBackground:last-child{
		padding-bottom:186px;
		margin-bottom:-207px;
	}
	#fontPdetail .textBox h2{
		margin: -11px 44px 49px;
		color:#141414;
		font-size: 44px;
		line-height: 66px;
		text-indent: 0;
	}
	#fontPdetail .textBox .featureBox+h2{
		margin:115px 44px 60px;
	}
	#fontPdetail .textBigTop h2{
		margin-bottom: 48px;
	}
	#fontPdetail .textBox p{
		margin: 0 44px;
		font-size: 40px;
		line-height: 62px;
		padding: 15px 0;
	}
	#fontPdetail .textBox p b{
		margin: 0 0 6px;
	}
	#fontPdetail .textBox > img{
		display:block;
		width:907px;
		margin: 32px auto 55px;
	}
	#fontPdetail .textBox .grayBlock{
		margin: 0 44px;
		background-color:#fff;
		border:none;
		padding:0;
	}
	#fontPdetail .textBox .grayBlock h4{
		font-size: 40px;
		line-height: 68px;

	}
	#fontPdetail .textBox .grayBlock p+h4{
		padding-top:70px;
	}
	#fontPdetail .textBox .grayBlock p{
		font-size: 40px;
		line-height: 68px;
		padding:0;
		margin:0;
	}

	#fontPdetail .textBox .textDetails{
		margin: 34px 0 0;
	}
	#fontPdetail .textBox .textDetails p{
		margin-bottom: 30px;
	}
	#fontPdetail .textBox .textDetails > p:last-child{
		margin-bottom: 30px;
	}
	#fontPdetail .textBox .textDetails div{
		width:100%;
	}
	#fontPdetail .textBox .textDetails div:last-child{
		margin-left:0;
		margin-bottom: 0;
	}
	#fontPdetail .textBox .textDetails .figure{
		line-height: 62px;
		padding: 15px 0;
		padding-left: 18px;
	}

	#fontPdetail .textBox .textDetails p:before{
		width:76px;
		height:42px;
		background-size:auto 42px!important;
		left:0;
		top: 4px;
	}

	#fontPdetail .textBox .featureBox{
		padding-top: 0;
	}
	#fontPdetail .textBox .featureBox .featureImage{
		display: none;
	}
	#fontPdetail .textBox .featureBox .featureImage2{
		display: block;
		width:100%;
		overflow-x:scroll;
		white-space:nowrap;
	}
	#fontPdetail .textBox .featureBox .featureImage2::-webkit-scrollbar{
		display:none;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div{
		position: relative;
		display: inline-block;
		text-align:center;
		width:383px;
		height:271px;
		margin:0 22px 44px;
		background-color:rgba(255,255,255,0.7);
		border:2px solid rgba(224,224,224,0.7);
		overflow:hidden;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div:after{
		position: absolute;
		content:'';
		display:block;
		content:'';
		width: 266px;
		height: 194px;
		background-size:auto 194px!important;
		right:-20px;
		bottom:-24px;
		opacity:0.4;
	}
	.boxshadow,#fontPdetail .textBox .featureBox .featureImage2 div:hover{
		box-shadow:0px 4px 16px 0px rgba(5,5,5,0.08);
	}
	#fontPdetail .textBox .featureBox .featureImage2 div:first-child{
		margin-left:44px;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div:last-child{
		margin-right:44px;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div .feature{
		display: inline-block;
		text-align:center;
		margin: 0;
		padding: calc(64px + 70px + 34px) 0 64px;
		width:383px;
		color:#292929;
		font-size: 40px;
		line-height: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		text-indent: 0em;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div .feature:before{
		position: absolute;
		content:'';
		display:block;
		width:100%;
		height:calc(64px + 70px);
		left:0;
		top: 0;
		opacity:0;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div .feature:hover,
	#fontPdetail .textBox .featureBox .featureImage2 div .infeature{
		background:rgba(0,0,0,0)!important;
	}
	#fontPdetail .textBox .featureBox .featureImage2 div .feature:hover:before,
	#fontPdetail .textBox .featureBox .featureImage2 div .infeature:before{
		opacity:1;
	}

	#fontPdetail .textBox .featureBox .featureText{
		margin:0 44px;
		padding:41px 86px 61px;
		background-color: rgba(255,255,255,0.7);
		border:2px solid rgba(224,224,224,0.7);
		box-shadow:0px 4px 16px 0px rgba(5,5,5,0.08);
	}
	#fontPdetail .textBox .featureBox .featureText h4{
		font-size: 40px;
		line-height: 102px;
	}
	#fontPdetail .textBox .featureBox .featureText p{
		font-size: 40px;
		line-height: 62px;
	}

	#fontPdetail .textBox .textDisplay{
		display:grid;
		grid-template-columns: calc(100% - 88px);
		grid-gap: 85px;
		grid-auto-flow:row dense;
		margin-top:34px;
		margin-bottom:55px;
	}
	#fontPdetail .textBox:last-child .textDisplay{
		margin-bottom:55px;
	}
	#fontPdetail .textBox .textDisplay div{
		padding-bottom: 60px;
		border:2px solid #e0e0e0;
	}
	#fontPdetail .textBox .textDisplay div h4{
		margin-bottom: 50px;
		font-size: 44px;
		line-height: 142px;
		padding-top: 0px;
		letter-spacing: 0px;
		text-indent: 86px;
		background:url('./images/grid背景1080.png') no-repeat;
		border-bottom:2px solid #e0e0e0;
	}
	#fontPdetail .textBox .textDisplay div:hover h4{
		background:url('./images/grid背景1080-移入.png') no-repeat;
	}
	#fontPdetail .textBox .textDisplay div h5{
		margin:0 86px 10px;
		font-size: 40px;
		line-height: 60px;
	}
	#fontPdetail .textBox .textDisplay div p+h5{
		margin:40px 86px 10px;
	}
	#fontPdetail .textBox .textDisplay div p{
		margin:0 86px;
		font-size: 40px;
		line-height: 60px;
	}
	#fontPdetail .textBox .textDisplay .longGrid{
		grid-column-start: 1;
		grid-column-end: 2;
	}
	#fontPdetail .textBox .textDisplay .longGrid h4{
		background:url('./images/grid背景1080.png') no-repeat;
	}
	#fontPdetail .textBox .textDisplay .longGrid:hover h4{
		background:url('./images/grid背景1080-移入.png') no-repeat;
	}

	#fontPdetail .textBox .textImage{
		display:grid;
		grid-template-columns: 100%;
		justify-content:space-between;
		margin:0 0 -130px;
	}
	#fontPdetail .textBox .textImage div h2{
		padding-top:0;
		margin: 0 44px 60px;
	}
	#fontPdetail .textBox .textImage img{
		display:block;
		width:908px;
		margin: 0 auto;
		padding-top: 32px;
	}

	#fontPdetail .textBox .textImage2{
		display:grid;
		grid-template-columns: 100%;
		justify-content:space-between;
		margin:0;
	}
	#fontPdetail .textBox .textImage2 div h2{
		width:100%;
		margin: -11px 44px 40px;
		font-size: 44px;
		line-height: 66px;
		padding:0;
	}
	#fontPdetail .textBox .textImage2 div p{
		padding: 19px 0;
	}
	#fontPdetail .textBox .textImage2 div div{
		padding: 60px 0 26px;
		position: relative;
		top:1542px;
	}
	#fontPdetail .textBox .textImage2 div div h2, 
	#fontPdetail .textBox .textImage2 div div h2:first-child{
		padding:0;
		margin:44px 44px -11px;
		font-weight:500;
	}
	#fontPdetail .textBox .textImage2 > div > p:nth-child(2) {
		width: calc(100% - 88px);
	}
	#fontPdetail .textBox .textImage2 img{
		display:block;
		width: 926px;
		margin: 28px auto 0;
		position: relative;
		top:-807px;
	}
	#fontPdetail .textBox .textImage2 div h6{
		margin-top: 40px;
		font-size: 38px;
		line-height: 38px;
		position: relative;
		top:-807px;
	}

	#fontPdetail .textBox .imgsDisplay{
		display: grid;
		grid-gap: 44px;
		width: 100%;
		grid-template-columns: 427px 383px 383px 427px;
		margin-bottom:0;
		overflow: scroll;
	}
	#fontPdetail .textBox .imgsDisplay div{
		position: relative;
	}
	#fontPdetail .textBox .imgsDisplay div:first-child{
		left: 44px;
	}
	#fontPdetail .textBox .imgsDisplay div p{
		width: 383px;
		line-height: 271px;
		font-size: 44px;
	}
	#fontPdetail .textBox .imgsDisplay div img{
		width: 383px;
		height: 271px;
		top:0;
		left:0;
	}

	#fontPdetail .textBox .tableBox{
		display: grid;
		grid-gap: 0;
		grid-template-columns: 100%;
		border:2px solid #e0e0e0;
		margin:34px 44px 89px;
	}
	#fontPdetail .textBox .tableBox div{
		padding:0 16px 40px;
	}
	#fontPdetail .textBox .tableBox div:first-child{
		padding:40px 16px 0;
	}
	#fontPdetail .textBox .tableBox div{
		background-color:#f5f5f5;
	}
	#fontPdetail .textBox .tableBox div p {
		padding: 19px 0;
	}
	#fontPdetail .textBox .tableBox div p+p{
		margin-top:0;
	}

	#fontPdetail .textBox .tablebutton{
		display: grid;
		width: 934px;
		height:145px;
		background:url('./images/选项卡背景2.png') no-repeat;
		background-size:934px 145px;
		grid-template-columns: 311px 311px 312px;
		margin:89px auto 85px;
	}
	#fontPdetail .textBox .tablebutton div{
		display:inline-block;
		width: 312px;
		line-height:145px;
		text-align:center;
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		color: #141414;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(1){
		color: #fff;
		background:url('./images/选中左侧2.png') no-repeat;
		background-size:311px 145px;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(2){
		color: #fff;
		background:url('./images/选中中间2.png') no-repeat;
		background-size:311px 145px;
	}
	#fontPdetail .textBox .tablebutton .inbutton:nth-child(3){
		color: #fff;
		background:url('./images/选中右侧2.png') no-repeat;
		background-size:312px 145px;
	}
	#fontPdetail .textBox table{
		border:none;
		border-collapse: collapse;
		width: calc(100% - 88px);
		margin:0 auto 55px;
		box-shadow:0px 4px 16px 0px rgba(5,5,5,0.08);
	}
	#fontPdetail .textBox table tr{
		position: relative;
	}
	#fontPdetail .textBox table tr th{
		border:none;
		text-align:center;
		font-size: 40px;
		line-height: 232px;
		height: 232px;
		color: rgba(0,0,0,0);
		text-indent: 0;
		padding:0;
	}
	#fontPdetail .textBox table tr td{
		border-left:none;
		text-align:center;
		font-size: 40px;
		line-height: 115px;
		height: 115px;
		color: rgba(0,0,0,0);
		text-indent: 0;
		padding:0;
	}
	#fontPdetail .textBox table tr th:nth-child(1),
	#fontPdetail .textBox table tr td:nth-child(1){
		background-color:#e6555f;
		width:392px;
		color: #fff;
	}
	#fontPdetail .textBox table tr .tryth{
		background-color:#f5f5f5;
		width:calc(100% - 392px);
		position: absolute;
		right:0;
		opacity:0;
		transition:color 0.3s;
	}
	#fontPdetail .textBox table tr .trytd{
		background-color:#f5f5f5;
		width:calc(100% - 392px);
		position: absolute;
		right:0;
		opacity:0;
		transition:color 0.3s;
	}
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(1),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(1){
		background-color:#e0343e;
	}
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(2),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(2),
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(3),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(3),
	#fontPdetail .textBox table tr:nth-child(2n+1) th:nth-child(4),
	#fontPdetail .textBox table tr:nth-child(2n+1) td:nth-child(4){
		background-color:#fff;
	}
	.see1{
		color:#141414!important;
		opacity:1!important;
	}
	.see{
		color:#525252!important;
		opacity:1!important;
	}
	#fontPdetail .textBox table tr td br{
		display:none;
	}
#fontPdetail .textBox .featureBox > div #feature1{
	background:url('./images/高安全-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature1:before{
	background:url('./images/高安全-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature2{
	background:url('./images/高可用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature2:before{
	background:url('./images/高可用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature3{
	background:url('./images/高兼容-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature3:before{
	background:url('./images/高兼容-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature4{
	background:url('./images/高效-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature4:before{
	background:url('./images/高效-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature5{
	background:url('./images/易维护-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature5:before{
	background:url('./images/易维护-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature6{
	background:url('./images/身份鉴别-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature6:before{
	background:url('./images/身份鉴别-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature7{
	background:url('./images/强制行为控制MBC-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature7:before{
	background:url('./images/强制行为控制MBC-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature8{
	background:url('./images/强制能力控制MCC-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature8:before{
	background:url('./images/强制能力控制MCC-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature9{
	background:url('./images/访问控制列表ACL-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature9:before{
	background:url('./images/访问控制列表ACL-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature10{
	background:url('./images/强制访问控制MAC-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature10:before{
	background:url('./images/强制访问控制MAC-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature11{
	background:url('./images/安全审计-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature11:before{
	background:url('./images/安全审计-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature12{
	background:url('./images/四权分立-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature12:before{
	background:url('./images/四权分立-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature13{
	background:url('./images/无root运行环境-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature13:before{
	background:url('./images/无root运行环境-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature14{
	background:url('./images/内核级的防火墙-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature14:before{
	background:url('./images/内核级的防火墙-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature15{
	background:url('./images/冗余容错功能-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature15:before{
	background:url('./images/冗余容错功能-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature16{
	background:url('./images/高可用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature16:before{
	background:url('./images/高可用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature17{
	background:url('./images/软件固化-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature17:before{
	background:url('./images/软件固化-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature18{
	background:url('./images/高可用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature18:before{
	background:url('./images/高可用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature19{
	background:url('./images/动态可扩展-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature19:before{
	background:url('./images/动态可扩展-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature20{
	background:url('./images/易用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature20:before{
	background:url('./images/易用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature21{
	background:url('./images/通用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature21:before{
	background:url('./images/通用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature22{
	background:url('./images/高效-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature22:before{
	background:url('./images/高效-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature23{
	background:url('./images/高安全-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature23:before{
	background:url('./images/高安全-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature24{
	background:url('./images/高兼容-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature24:before{
	background:url('./images/高兼容-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature25{
	background:url('./images/易管理-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature25:before{
	background:url('./images/易管理-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature26{
	background:url('./images/高安全-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature26:before{
	background:url('./images/高安全-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature27{
	background:url('./images/高可用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature27:before{
	background:url('./images/高可用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature28{
	background:url('./images/动态可扩展-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature28:before{
	background:url('./images/动态可扩展-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature29{
	background:url('./images/通用-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature29:before{
	background:url('./images/通用-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature30{
	background:url('./images/易管理-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature30:before{
	background:url('./images/易管理-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature31{
	background:url('./images/易维护-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature31:before{
	background:url('./images/易维护-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature32{
	background:url('./images/强制运行控制机制-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature32:before{
	background:url('./images/强制运行控制机制-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature33{
	background:url('./images/强制能力控制MCC-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature33:before{
	background:url('./images/强制能力控制MCC-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature34{
	background:url('./images/访问控制列表ACL-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature34:before{
	background:url('./images/访问控制列表ACL-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature35{
	background:url('./images/四权分立-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature35:before{
	background:url('./images/四权分立-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature36{
	background:url('./images/提供安全增强服务-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature36:before{
	background:url('./images/提供安全增强服务-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature37{
	background:url('./images/冗余容错功能-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature37:before{
	background:url('./images/冗余容错功能-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature38{
	background:url('./images/热备与互备-静默.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div #feature38:before{
	background:url('./images/热备与互备-选中.png') no-repeat center 64px;
}
#fontPdetail .textBox .featureBox > div .feature{
	background-size:auto 70px!important;
}
#fontPdetail .textBox .featureBox > div .feature:before{
	background-size:auto 70px!important;
}
#fontPdetail .textBox .featureBox .featureImage2 div:first-child:after{
	background:url('./images/01.png') no-repeat left;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(2):after{
	background:url('./images/02.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(3):after{
	background:url('./images/03.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(4):after{
	background:url('./images/04.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(5):after{
	background:url('./images/05.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(6):after{
	background:url('./images/06.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(7):after{
	background:url('./images/07.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(8):after{
	background:url('./images/08.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(9):after{
	background:url('./images/09.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(10):after{
	background:url('./images/010.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(11):after{
	background:url('./images/011.png') no-repeat center;
}
#fontPdetail .textBox .featureBox .featureImage2 div:nth-child(12):after{
	background:url('./images/012.png') no-repeat center;
}
}*/
</style>
