<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg" id="tittlrImgResearchInstitutes1">
			<b>科研院所</b>
		</h1>
		<div class="column">
			<div>
				<h2>北京某研究所</h2>
				<p>随着互联网的高速发展，北京信息技术研究所针对自身的工作生产和内外部交流，提出了组建邮件系统的需求。针对这个需求，凝思软件立足与自身的凝思安装操作系统和凝思邮件系统，为北京信息技术研究所制作了一款安全可靠、稳定高效、灵活易用、具有高性价比的邮件系统。</p>
			</div>
			<div>
				<h2>江苏某研究所</h2>
				<p>江苏省信息技术应用研究所为安全部某省安全厅开发了电子政务系统，实现公文报告、请示、批复、公告、通知和通报等电子公文相关功能，该系统运行于涉密网中，安全保密要求高，故在各个服务器节点均使用运行凝思安全操作系统的安全服务器作为底层平台，DNS、网站、CA、邮件和数据库等通用网络服务使用凝思软件的安全增强版本。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'ScientificResearch',
	}
</script>
<style scoped>

</style>
