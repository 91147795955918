<!-- 底部导航栏 -->
<template>
    <!-- 网页的底部 -->
    <div class="boxfooter">
        <div class="topboxfooter">
            <div class="left">
            	<div
                    class="fontOfbottom"
                    v-for="(item,index) in navList"
                    :key="index+'-999'"
                    v-show="index == 1"
                >
                    <span
                        class="keys"
                        v-if="index > 0"
                    >{{item.name}}</span>
                    <div>
                        <a
                            class="linkOfpage"
                            v-for = "(nav,index2) in item.subItems"
                            :key= "index2"
                            @click="toguid(nav.link,nav.goid)"
                        >{{nav.text}}</a>
                    </div>
                </div>
                <div
                    class="fontOfbottom"
                    v-for="(item,index) in navList"
                    :key="index"
                    v-show="index > 1"
                >
                    <span
                        class="keys"
                    >{{item.name}}</span>
                    <div>
                        <router-link
                            class="linkOfpage"
                            v-for = "(nav,index2) in item.subItems"
                            :key= "index2"
                            :to= "nav.link"
                        >{{nav.text}}</router-link>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="zhaunxian">
                    <h1>全国400专线</h1>
                    <p><span>全国运维客服电话：</span>400-086-5855</p>
                    <p><span>全国销售咨询专线：</span>400-690-8128 转 1</p>
                    <p><span>全国兼容适配专线：</span>400-690-8128 转 2</p>
                    <p><span>人工咨询：</span>400-690-8128 转 0</p>
                </div>
                <div class="add">
                    <h1>办公地址</h1>
                    <p><span>北京总部：</span>北京市海淀区知春路63号卫星大厦602室</p>
                    <p><span>四川子公司：</span>成都市锦江区三色路238号新华之星B座26层2603号</p>
                    <p><span>四川分公司：</span>成都市高新区天府大道中段530号东方希望天祥广场B座36层3606号</p>
                    <p><span>江苏分公司：</span>南京市雨花台区丁墙路9号瀚瑞中心1栋2503、2504室</p>
                </div>
                <div class="gongzonghao">
                    <img src="./images/二维码.png" alt="二维码">
                    <p class="p1">扫描并关注</p>
                    <br>
                    <p class="p2">凝思软件微信公众号</p>
                </div>
            </div>
        </div>
        <foot1 v-show="bodyWidthMut > 1170"></foot1>
        <foot2 v-show="bodyWidthMut <= 1170 && bodyWidthMut > 1024"></foot2>
        <foot3 v-show="bodyWidthMut <= 1024 && bodyWidthMut > 750"></foot3>
        <foot4 v-show="bodyWidthMut <= 750"></foot4>
        <a href="#" id="returnfooter"><img src="./images/返回顶部-移动端.png" alt="返回顶部"></a>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import foot1 from './foot1.vue';
import foot2 from './foot2.vue';
import foot3 from './foot3.vue';
import foot4 from './foot4.vue';

export default {
    name: 'Footer',
    data(){
        return{
        	bodyWidthMut: document.body.clientWidth,
        }
    },
    computed:{
	    ...mapState({
            navList:(state)=>state.Navigation.navList,
        })
    },
    components:{
		foot1,
		foot2,
		foot3,
		foot4
    },
    mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
    methods:{
    	toguid(path, id){
    		var path = path;
    		var Id = id;
    		var go = '#' + Id;
    		localStorage.setItem('toId',Id);
    		if(this.$route.path.indexOf('/Products/ProductsCatalog') != -1){
    			document.querySelector(go).scrollIntoView();
		        console.log(1);
            } else {
            	this.$router.push(path);
            }
    	}
    },
}
</script>

<style scoped>
/*pc*/
.boxfooter a{
    text-decoration: none;
}
.boxfooter{
    position:relative;
    width: 100%;
    background-color: #21232b;
    color: #fff;
    border-top: 8px #dd1d29 solid;
}
.topboxfooter{
    position:relative;
    width: 1170px;
    margin: 0px auto;
    padding: 20px 0;
}
.topboxfooter .left{
    width:622px;
    margin-top: 28px;
    display: inline-block;
}
.topboxfooter .left .fontOfbottom .keys{
    vertical-align: top;
    display: inline-block;
    width: 120px;
    line-height:56px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0px;
}
.topboxfooter .left .fontOfbottom div{
    display: inline-block;
    width: 502px;
    padding:11px 0;
}
.topboxfooter .left .fontOfbottom div .linkOfpage{
    display: inline-block;
    line-height:14px;
    margin:10px 0;
    font-size: 14px;
    color: #ccced2;
    font-weight: 500;
    letter-spacing: 0px;
    transition: color .3s;
    padding-right: 8px;
    border-right: 1px solid #a2a2a3;
    margin-right: 8px;
    cursor:pointer;
}
.topboxfooter .left .fontOfbottom div .linkOfpage:last-child{
    padding-right: 0px;
    border-right: 0px solid #a2a2a3;
    margin-right: 0px;
}
.topboxfooter .left .fontOfbottom div .linkOfpage:hover{
    color: #fff;
    transition: color .3s;
}

.topboxfooter .right{
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 460px;
    padding-left: 88px;
}
.zhaunxian,.add {
    margin: 20px 0;
}
.zhaunxian h1,.add h1{
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 45px;
}
.zhaunxian p,.add p{
    font-size: 14px;
    color: #ccced2;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 27px;
}
.zhaunxian p span{
    display:inline-block;
    width:126px;
    height:27px;
    line-height: 27px;
    text-align: justify;
	text-justify: distribute-all-lines;/*ie6-8*/
	text-align-last: justify;
}
.add p span{
    display:inline-block;
    width:84px;
    height:25px;
    line-height: 25px;
    text-align: justify;
	text-justify: distribute-all-lines;/*ie6-8*/
	text-align-last: justify;
}
.gongzonghao{
    position: absolute;
    width: 135px;
    height: 220px;
    top: 30px;
    right: 0px;
}
.gongzonghao img{
    width: 135px;
    height: 135px;
}
.gongzonghao p{
    margin: 10px 0 0 0;
    font-size: 12px;
    color: #ccced2;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 12px;
    text-align: center;
}


#returnfooter{
    display: none;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    .topboxfooter{
        width: 100%;
        margin: 0px auto;
        padding: 0px;
    }
    .topboxfooter .left{
        position: relative;
        display: block;
        width:904px;
        margin: 0;
        padding:48px calc(50% - 452px) 20px;
        border-bottom: 1px rgba(235,235,235,0.25) solid;
    }
    .topboxfooter .left .fontOfbottom{
        margin: 0;
        padding: 16px 0;
    }
    .topboxfooter .left .fontOfbottom .keys{
        vertical-align: top;
        display: inline-block;
        width: 120px;
        line-height:50px;
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0px;
    }
    .topboxfooter .left .fontOfbottom div{
        display: inline-block;
        width: 784px;
    }
    .topboxfooter .left .fontOfbottom div .linkOfpage{
        display: inline-block;
        line-height:18px;
        margin:16px 0;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        letter-spacing: 0px;
        transition: color .3s;
        padding-right: 22px;
        border-right: 1px solid #a2a2a3;
        margin-right: 22px;
    }

    .topboxfooter .right{
        position: relative;
        width:904px;
        margin: 0;
        padding:33px calc(50% - 452px) 47px;
    }
    .topboxfooter .right .zhaunxian{
        display: inline-block;
        margin: 0;
        position: relative;
        width:275px;
        left:445px;
        top:0px;
        vertical-align:top;
    }
    .topboxfooter .right .add {
        display: inline-block;
        margin: 0;
        position: relative;
        width:410px;
        left:-275px;
        top:0px;
    }
    .topboxfooter .right .zhaunxian h1,.topboxfooter .right .add h1{
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 56px;
    }
    .topboxfooter .right .zhaunxian p,.topboxfooter .right .add p{
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
    }
    .topboxfooter .right .zhaunxian p{
        white-space:nowrap;
    }
    .zhaunxian p span{
		width:126px;
		height:24px;
		line-height: 24px;
	}
	.add p span{
		width:84px;
		height:24px;
		line-height: 24px;
	}
    .topboxfooter .right .gongzonghao{
        display: block;
        position: absolute;
        margin: 0;
        top: 52px;
        right: calc(50% - 452px);
    }
    .topboxfooter .right .gongzonghao p{
        display:none;
    }

    #returnfooter{
        display:block;
        position:relative;
        width:100%;
        height:88px;
        border-top:1px solid rgba(235,235,235,0.25);
        background-color: #14161c;
    }
    #returnfooter img{
        position: absolute;
        left:50%;
        top:50%;
        height:33px;
        transform: translateX(-50%) translateY(-50%); 
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.boxfooter a{
		text-decoration: none;
		color:#fff;
		font-size: 22px;
		font-weight: normal;
	}
	.boxfooter{
		position:relative;
		width: 100%;
	}
	.topboxfooter{
		position:relative;
		width: 720px;
		padding:0 calc(50vw - 360px);
		margin: 0 auto;
		background: url(./images/凝思线框.png) no-repeat top -23px right;
	}
	.topboxfooter .left{
		display:none;
	}
	.topboxfooter .right{
		position: relative;
		width:100%;
		margin: 0px auto;
		padding:0;
	}
	.topboxfooter .right .zhaunxian{
		padding:190px 24px 0;
		margin: 0 0;
		position: relative;
		width:calc(100% - 48px);
		left:0;
		top:0;
		text-align: left;
		padding-bottom:34px;
		border-bottom:1px solid rgba(235,235,235,0.25);
	}
	.topboxfooter .right .add {
		margin: 0 auto;
		position: relative;
		width:calc(100% - 48px);
		left:0;
		top:0;
		text-align: left;
		padding:24px 24px 49px;
	}
	.topboxfooter .right .zhaunxian h1,.topboxfooter .right .add h1{
		margin: 0 auto;
		font-size: 24px;
		color: #fff;
		font-weight: 500;
		letter-spacing: 0px;
		line-height: 74px;
	}
	.topboxfooter .right .zhaunxian p,.topboxfooter .right .add p{
		margin: 0 auto;
		width:100%;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 30px;
	}
	.zhaunxian p span{
		width:152px;
		height:30px;
		line-height: 30px;
	}
	.add p span{
		width:104px;
		height:30px;
		line-height: 30px;
	}
	.topboxfooter .right .gongzonghao{
		position: absolute;
		width: 135px;
		height: 135px;
		top: 30px;
		left: 24px;
	}
	.topboxfooter .right .gongzonghao p{
		display:none;
	}

	#returnfooter{
		display:block;
		position:relative;
		width:100%;
		height:88px;
		border-top:1px solid rgba(235,235,235,0.25);
		background-color: #14161c;
	}
	#returnfooter img{
		position: absolute;
		left:50%;
		top:50%;
		height:33px;
		transform: translateX(-50%) translateY(-50%); 
	}
}
/*手机端*/
@media only screen and (max-width:750px){
.boxfooter{
    min-width: 360px;
}
.boxfooter a{
    text-decoration: none;
    color:#fff;
    font-size: 11px;
    font-weight: normal;
}
	.topboxfooter{
		width: calc(100vw - 50px);
		padding:0 25px;
		background: url(./images/凝思线框.png) no-repeat top -11.5px right;
		background-size: 134px 140.5px;
	}
.topboxfooter .right{
    position: relative;
    width:100%;
    margin: 0px auto;
    padding:0;
    padding-bottom:0px;
    top:0;
}
.topboxfooter .right .zhaunxian{
    padding:175px 0 34px;
    margin: 0 auto;
    position: relative;
    display: block;
    width:100%;
    left:0;
    top:0;
    text-align: left;
    font-size: 13px;
    border-bottom:0.5px solid rgba(235,235,235,0.25);
}
.topboxfooter .right .add {
    margin: 0 auto;
    position: relative;
    width:100%;
    left:0;
    top:0;
    text-align: left;
    padding:12px 0 24.5px;
}
.topboxfooter .right .zhaunxian h1,.topboxfooter .right .add h1{
    margin: 0 auto;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 34px;
}
.topboxfooter .right .zhaunxian p,.topboxfooter .right .add p{
    width:100%;
    font-size: 12px;
    line-height: 16px;
}
	.zhaunxian p span{
		width:108px;
		height:30px;
		line-height: 30px;
	}
	.add p span{
		width:72px;
		height:30px;
		line-height: 30px;
		display:block;
	}
.topboxfooter .right .gongzonghao{
    position: absolute;
    width: 135px;
    height: 135px;
    top: 28px;
    left: 0;
}
.topboxfooter .right .gongzonghao p{
    display:none;
}


#returnfooter{
    display:block;
    position:relative;
    width:100%;
    height:44px;
    border-top:0.5px solid rgba(235,235,235,0.25);
    background-color: #14161c;
}
#returnfooter img{
    position: absolute;
    left:50%;
    top:50%;
    height:16.5px;
    transform: translateX(-50%) translateY(-50%); 
}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
.boxfooter a{
    text-decoration: none;
    color:#fff;
    font-size: 22px;
    font-weight: normal;
}
.boxfooter{
    min-width: 674px;
}
.topboxfooter .left{
    display:none;
}
.topboxfooter .right{
    position: relative;
    width:100%;
    margin: 0px auto;
    padding:0;
    padding-bottom:0px;
    top:0;
}
.topboxfooter .right .zhaunxian{
    padding-top:347px;
    margin: 0 auto;
    position: relative;
    display: block;
    width:992px;
    left:0;
    top:0;
    text-align: center;
    font-size: 38px;
    padding-bottom:48px;
    border-bottom:2px solid rgba(235,235,235,0.25);
}
.topboxfooter .right .add {
    margin: 0 auto;
    position: relative;
    display: block;
    width:992px;
    left:0;
    top:0;
    text-align: center;
    padding-top:33px;
    padding-bottom:70px;
}
.topboxfooter .right .zhaunxian h1,.topboxfooter .right .add h1{
    margin: 0 auto;
    font-size: 38px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 88px;
}
.topboxfooter .right .zhaunxian h1:before{
    position: relative;
    display: inline-block;
    width: 37px;
    height: 37px;
    content: ' ';
    background: url(./images/footer-phone.png) no-repeat center center;
    background-size:auto 37px;
    top:4px;
    padding-right:10px;
}
.topboxfooter .right .add h1:before{
    position: relative;
    display: inline-block;
    width: 37px;
    height: 37px;
    content: ' ';
    background: url(./images/footer-map.png) no-repeat center center;
    background-size:auto 37px;
    top:4px;
    padding-right:10px;
}
.topboxfooter .right .zhaunxian p,.topboxfooter .right .add p{
    width:992px;
    margin:0 auto;
    font-size: 34px;
    line-height: 54px;
}
.topboxfooter .right .gongzonghao{
    position: absolute;
    width: 194px;
    height: 194px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
}
.topboxfooter .right .gongzonghao img{
    width: 194px;
    height: 194px;
    background-size:194px;
}
.topboxfooter .right .gongzonghao p{
    display:none;
}


.buboxfooter{
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    background-color: #14161c;
}
.buboxfooter div{
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding:0 0 31px;
    position: relative;
}
.buboxfooter div img{
    display:block;
    position:relative;
    width: auto;
    height:92px;
    left:50%;
    top:0;
    transform: translateX(-50%); 
    padding:46px 0 31px;
}
.buboxfooter div p{
    position: relative;
    left: 0px;
    top:0px;
    color: #a2a2a3;
    line-height: 32px;
    font-size: 62px;
    text-align: center;
}
.buboxfooter div p span{
    position: relative;
    font-size: 32px;
    line-height: 62px;
    color: #a2a2a3;
}
.buboxfooter div p span{
    font-size: 32px;
    padding:0 10px 0 0;
}
.buboxfooter div p span:nth-child(1){
    font-size: 32px;
    padding:0 0;
    display:inline-block;
}
.buboxfooter div p span:nth-child(3),.buboxfooter div p span:nth-child(5),.buboxfooter div p span:nth-child(7),.buboxfooter div p span:nth-child(9){
    font-size: 38px;
}
.buboxfooter div p span:nth-child(5):after{
    content: ' ';
    width: 100%;
    height:0px;
    padding:0 0;
    display:block;
}
.buboxfooter div p span:nth-child(9){
    display:none;
}
.buboxfooter div p a:nth-child(10){
    display:block;
    top:-62px;
}
.buboxfooter div p a{
    position: relative;
    font-size: 32px;
    line-height: 62px;
    color: #a2a2a3;
}
.buboxfooter div p a:hover{
    color: #a2a2a3;
}

.buboxfooter div p span:nth-child(1),.buboxfooter div p a:nth-child(2),.buboxfooter div p span:nth-child(3),.buboxfooter div p a:nth-child(4),.buboxfooter div p span:nth-child(5){
    top:124px;
}
.buboxfooter div p span:nth-child(1){
    left:calc(50vw - 515px + 320px + 6px);
}
.buboxfooter div p a:nth-child(2),.buboxfooter div p span:nth-child(3),.buboxfooter div p a:nth-child(4),.buboxfooter div p span:nth-child(5){
    left:calc(515px - 50vw - 576px);
}
.buboxfooter div p a:nth-child(6),.buboxfooter div p span:nth-child(7),.buboxfooter div p a:nth-child(8){
    top:-62px;
}


#returnfooter{
    display:block;
    position:relative;
    width:100%;
    height:142px;
    border-top:2px solid rgba(235,235,235,0.25);
    background-color: #14161c;
}
#returnfooter img{
    position: absolute;
    left:50%;
    top:50%;
    height:62px;
    transform: translateX(-50%) translateY(-50%); 
}
} */
</style>
