<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
		    <h2 class="contacth2">北京凝思软件股份有限公司</h2>
		    <div class="contactdiv">
		        <div class="contactd">
	                <div class="cellincontactd">
	                    <p class="cell1">全国<span>运维</span>客服专线</p>
	                    <p class="cell2">400-086-5855</p>
	                </div>
	                <div class="cellincontactd">
	                    <p class="cell1">全国<span>销售</span>咨询专线</p>
	                    <p class="cell2">400-690-8128 <b style="font-size:16px;font-weight: 500;">转</b> 1</p>
	                </div>
	                <div class="cellincontactd">
	                    <p class="cell1">全国<span>兼容适配</span>专线</p>
	                    <p class="cell2">400-690-8128 <b style="font-size:16px;font-weight: 500;">转</b> 2</p>
	                </div>
					<div class="cellincontactd">
	                    <p class="cell1">人工咨询</p>
	                    <p class="cell2">400-690-8128 <b style="font-size:16px;font-weight: 500;">转</b> 0</p>
	                </div>
		        </div>
		        <div class="contactd">
	                <div class="cellincontactd">
	                    <p class="cell1">技术工程中心邮箱</p>
	                    <p class="cell2">support@linx-info.com</p>
	                </div>
	                <div class="cellincontactd">
	                    <p class="cell1">人力资源部邮箱</p>
	                    <p class="cell2">hrzhaopin@linx-info.com</p>
	                </div>
	                <div class="cellincontactd">
	                    <p class="cell1">全国生态服务邮箱</p>
	                    <p class="cell2">marketing@linx-info.com</p>
	                </div>
					<div class="cellincontactd">
	                    <p class="cell1">公司网址</p>
	                    <p class="cell2">http://www.linx-info.com/</p>
	                </div>
		        </div>
		    </div>
		    <div class="contactmap">
		        <div class="cellMap">
		            <div class="fontMap">
		                <div class="infontMap">
		                    <h3>北京总部地址：</h3>
		                    <p>北京市海淀区知春路63号卫星大厦602室</p>
		                    <p>传真：010-82624792</p>
		                </div>
		            </div>
		            <div class="map">
		                    <div width="387px" height="218px">
								<img src="../images/北京.png"/>
							</div>
		            </div>
		        </div>
		        <div class="cellMap">
		            <div class="fontMap">
		                <div class="infontMap">
		                    <h3>四川子公司地址：</h3>
		                    <p>成都市锦江区三色路238号新华之星B座26层2603号</p>
		                    <p>传真：028-63172328</p>
		                </div>
		            </div>
		            <div class="map">
		                    <div width="387px" height="218px">
								<img src="../images/成都2.png"/>
							</div>
		            </div>
		        </div>
				<div class="cellMap">
		            <div class="fontMap">
		                <div class="infontMap">
		                    <h3>四川分公司地址：</h3>
		                    <p>成都市高新区天府大道中段530号东方希望天祥广场B座36层3606号</p>
		                </div>
		            </div>
		            <div class="map">
		                    <div width="387px" height="218px">
								<img src="../images/成都.png"/>
							</div>
		            </div>
		        </div>
		        <div class="cellMap">
		            <div class="fontMap">
		                <div class="infontMap">
		                    <h3>江苏分公司地址：</h3>
		                    <p>南京市雨花台区丁墙路9号瀚瑞中心1栋2503室</p>
		                </div>
		            </div>
		            <div class="map">
		                    <div width="387px" height="218px">
								<img src="../images/南京.png"/>
							</div>
		            </div>
		        </div>
		    </div>
		</div>
	</div>
</template>

<script>
export default {
	name:'Contact',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '',
			title4: 0,
			icon: require('../images/联系icon.png'),
			title4List: [
				{
					tittle:'联系我们',
				}
			],
		}
	},
	watch:{
        title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
            },
            deep: true,
        }
    }
}
</script>
<style scoped>
.font .contacth2{
    text-align: center;
    line-height: 32px;
    margin-bottom: 26px;
    color: #b11721;
    font-size: 20px;
    font-weight: 500;
    letter-spacing:1px;
}
.font .contactdiv{
    width: 100%;
    margin: 32px auto 0;
    padding-bottom:48px;
    display: grid;
    grid-template-columns: repeat(auto-fill,50%);
    background: url(../images/凝思线框.png) no-repeat right bottom -52px;
}
.font .contactdiv .contactd{
    position: relative;
    padding-left:114px;
    padding-top:45px;
    padding-bottom:21px;
}
.font .contactdiv .contactd .cellincontactd{
    margin-bottom: 24px;
}
.font .contactdiv .contactd .cellincontactd .cell1{
	margin: 0;
    line-height: 24px;
    color: #585f6b;
    font-size: 16px;
    font-weight: 400;
    letter-spacing:0px;
	text-indent: 0;
}
.font .contactdiv .contactd .cellincontactd .cell1 span{
	color:#0072ce;
}
.font .contactdiv .contactd .cellincontactd .cell2{
	margin: 0;
    line-height: 26px;
    color: #262b33;
    font-size: 18px;
    font-weight: 500;
    letter-spacing:0px;
	text-indent: 0;
}

.font .contactdiv:before{
	content:'';
	position: absolute;
    display: block;
    width: 1px;
	height: 362px;
	left:50%;
	background-image: linear-gradient(0deg, 
		#fafafa 0%, 
		#e0e0e0 50%, 
		#fafafa 100%), 
	linear-gradient(
		#e0e0e0, 
		#e0e0e0);
	background-blend-mode: normal, normal;
}


.font .contactmap{
    padding: 28px 0;
    background-color: #f7f7f7;
}
.font .contactmap .cellMap{
    height: 242px;
    padding-left: 32px;
}
.font .contactmap .cellMap .fontMap{
    display: inline-block;
    position: relative;
    width: 386px;
    height: 242px;
    border-bottom: 1px solid #e0e0e0;
    margin-right: 64px;
}
.font .contactmap .cellMap:last-child .fontMap{
    border-bottom: 0px solid #e0e0e0;
}
.font .contactmap .cellMap .fontMap .infontMap{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.font .contactmap .cellMap .fontMap .infontMap h3{
	margin: 0;
    line-height: 34px;
    color: #262b33;
    font-size: 20px;
    font-weight: 500;
    letter-spacing:0px;
	text-indent: 0;
}
.font .contactmap .cellMap .fontMap .infontMap h3::before{
	display: none;
}
.font .contactmap .cellMap .fontMap .infontMap p{
	margin: 0;
    line-height: 32px;
    color: #262b33;
    font-size: 18px;
    font-weight: 400;
    letter-spacing:0px;
	text-indent: 0;
}
.font .contactmap .cellMap .map{
    display: inline-block;
    position: relative;
    bottom: 12px;
    width: 385px;
    height: 216px;
}
/* .font .contactmap .cellMap .map:before{
    content:" ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../images/loading.png) no-repeat center center;
    animation:loading .7s linear infinite ;
}
@keyframes loading{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
} */
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.font .contactdiv .contactd{
		padding-left:64.5px;
	}


	.font .contactmap{
	    padding: 38px 0 0;
	    background-color: #f7f7f7;
	}

	.font .contactmap .cellMap .fontMap{
	    display: inline-block;
	    position: relative;
	    width: 236px;
	    height: 218px;
	    border-bottom: 1px solid #e0e0e0;
	    margin-right: 32px;
	}
	.font .contactmap .cellMap:last-child .fontMap{
	    border-bottom: 0px solid #e0e0e0;
	}
	.font .contactmap .cellMap .fontMap .infontMap{
	    position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	}
	.font .contactmap .cellMap .fontMap .infontMap h3{
	    line-height: 34px;
	    color: #262b33;
	    font-size: 20px;
	    font-weight: 500;
	    letter-spacing:0px;
	}
	.font .contactmap .cellMap .fontMap .infontMap p{
	    line-height: 32px;
	    color: #262b33;
	    font-size: 18px;
	    font-weight: 400;
	    letter-spacing:0px;
	}
	.font .contactmap .cellMap .map{
	    display: inline-block;
	    position: relative;
	    bottom: 12px;
	    width: 385px;
	    height: 216px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.font .contacth2{
	    line-height: 38px;
	    margin-bottom: 4px;
	    font-size: 18px;
	    text-align:left;
	}
	.font .contactdiv{
		width: calc(100% - 30px);
		margin: 0 auto;
		padding-bottom:0;
		display: grid;
		grid-template-columns: 100%;
		background: none;
	}
	.font .contactdiv .contactd{
		position: relative;
		padding-left:0;
		padding-top:25px;
		padding-bottom:7px;
		border-bottom:1px solid #e5e5e5;
	}
	.font .contactdiv .contactd .cellincontactd{
		margin-bottom: 18px;
	}
	.font .contactdiv .contactd .cellincontactd .cell1{
		line-height: 18px;
		font-size: 14px;
	}
	.font .contactdiv .contactd .cellincontactd .cell2{
		line-height: 19px;
		font-size: 15px;
	}

	.font .contactdiv:before{
		display: none;
	}


	.font .contactmap{
	    position: relative;
	    width:calc(100% - 30px);
	    padding: 0;
	    background-color: #fff;
	    margin:24.5px auto 0px;
	    margin-bottom:-27px;
	}
	.font .contactmap .cellMap{
	    height: auto;
	    padding-left: 0px;
	    margin-bottom: 24.5px;
	}
	.font .contactmap .cellMap .fontMap{
	    display: block;
	    position: relative;
	    width: 100%;
	    height: auto;
	    border-bottom: 0px solid #e0e0e0;
	    margin-right: 0px;
	}
	.font .contactmap .cellMap .fontMap .infontMap{
	    position: relative;
	    top: 0;
	    transform: translateY(0);
	}
	.font .contactmap .cellMap .fontMap .infontMap h3{
	    line-height: 20px;
	    font-size: 15px;
	}
	.font .contactmap .cellMap .fontMap .infontMap p{
	    margin:0;
	    text-indent:0em;
	    line-height: 19px;
	    font-size: 14px;
	}
	.font .contactmap .cellMap .map{
	    display: none;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.font .contacth2{
	    line-height: 204px;
	    margin-bottom: 76px;
	    font-size: 52px;
	}
	.font .contactdiv{
	    width: 100%;
	    height: 562px;
	    margin-bottom: 92px;
	}
	.font .contactdiv .contactd{
	    position: relative;
	    display: inline-block;
	    width: 50%;
	    height: 562px;
	}
	.font .contactdiv .contactd .incontactd{
	    position: absolute;
	    margin: 0px auto 0px;
	    left: 44px;
	    transform: translateX(0);
	}
	.font .contactdiv .contactd #incon1,.font .contactdiv .contactd #incon2{
	    width: calc(100% - 88px);
	}
	.font .contactdiv .contactd .incontactd .cellincontactd{
	    margin-bottom: 46px;
	}
	.font .contactdiv .contactd .incontactd .cellincontactd:last-child{
	    margin-bottom: 0px;
	}
	.font .contactdiv .contactd .incontactd .cellincontactd .cell1{
	    margin:0;
	    text-indent:0em;
	    line-height: 52px;
	    color: #666;
	    font-size: 38px;
	}
	.font .contactdiv .contactd .incontactd .cellincontactd .cell2{
	    margin:0;
	    text-indent:0em;
	    line-height: 54px;
	    color: #262b33;
	    font-size: 40px;
	    font-weight: 500;
	    letter-spacing:0px;
	}

	.font .contactdiv .contactd2{
	    width: 2px;
	    height: 562px;
	}

	.font .contactmap{
	    position: relative;
	    width:calc(100% - 88px);
	    padding: 0;
	    background-color: #fff;
	    margin: 130px auto 0px;
	}
	.font .contactmap .cellMap{
	    height: auto;
	    padding-left: 0px;
	    margin-bottom: 60px;
	}
	.font .contactmap .cellMap .fontMap{
	    display: block;
	    position: relative;
	    width: 100%;
	    height: auto;
	    border-bottom: 0px solid #e0e0e0;
	    margin-right: 0px;
	}
	.font .contactmap .cellMap .fontMap .infontMap{
	    position: relative;
	    top: 0;
	    transform: translateY(0);
	}
	.font .contactmap .cellMap .fontMap .infontMap h3{
	    line-height: 60px;
	    font-size: 40px;
	}
	.font .contactmap .cellMap .fontMap .infontMap p{
	    margin:0;
	    text-indent:0em;
	    line-height: 58px;
	    font-size: 38px;
	}
	.font .contactmap .cellMap .map{
	    display: none;
	}
} */
</style>
