<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font" v-show="title4 == 0">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
			<div class="Introduction">
				<img src="../images/gong2.png"/>
				<div>
					<h2>宫敏</h2>
					<p>公司董事长宫敏先生1980年毕业于北京大学无线电电子学系无线电物理专业，1999年获得芬兰赫尔辛基理工大学技术科学博士学位<span>（Doctor of Science in Technology, Helsinki University of Technology）</span>。大学毕业后曾在多家机构、公司担任重要职位。</p>
				</div>
			</div>
			<p>2000年，宫敏承担了国家科技部“863”信息安全项目（课题名称为“服务器安全增强”，课题编号为863-301-6-6B），推出了自主版权安全服务器系统；随后又主持研发了并推出了双机隔离系统、 安全文件网关、安全操作系统卡等系列安全产品。</p>
			<p>2001年，宫敏参与了中华人民共和国国家军用标准《军用安全操作系统安全评估等级要求》和《军用安全操作系统用户安全接口使用要求》（GJB4936-20030134、GJB4937-20030134）的起草、审订工作。为军用安全操作系统标准专家组成员之一。</p>
			<p>2004年10月，参与国家电力调度中心“863”项目“国家电网调度中心安全防护体系研究及示范”，项目编号为2002AA145040。为电力行业定制了电力安全文件网关产品，现已部署于国家电网公司调度系统。</p>
			<p>2007年，承担科技部863项目“开源软件IP资源库关键技术及系统”；2008年承担国家测评中心项目“ICT产品信息安全隐患分析方法”课题研究。</p>
			<p>宫敏博士现为中国电子学会军用安全操作系统联合专家组成员、中国OSS联盟专家委员会委员、中国软件行业协会共创软件分会副理事长、工业控制系统产业联盟理事会副理事长、科技部专家库专家。2006年，宫敏博士作为代码与资料审核组组长，参与了国防科大863重大专项“服务器操作系统内核”项目的验收工作。宫敏还先后获得过中国十大功勋程序员、中国科学院技术进步一等奖、中关村优秀创业留学人员奖、中国开源杰出贡献奖、北京市第三届留学人员创业奖等奖项。</p>
			<div class="paper">
				<p class="inpaper">宫敏的学术论文，发表时间、发表刊物名称、代表论文如下：</p>
				<p>
				  <span>1.《Introduction to Multiprocessor Architecture for Image Processing》</span>
				  <span>1990年发表在芬兰The photogrammetric Journal of Finland, Vol. 12, No. 1, 1990刊物上；</span>
				</p>
				<p>
					<span>2.《An Attempt to Solve Memory Access Conflict--Multifunctional Distributed Shared Memory》</span>
					<span>1990年发表在芬兰The photogrammetric Journal of Finland, Vol. 12, No. 1, 1990刊物上；</span>
				</p>
				<p>
					<span>3.《Multiprocessor System for Photogrammetry and Remote Sensing》</span>
					<span>1992年发表在芬兰Surveying Science in Finland, Vol. 10, No.1, 1992刊物上；</span>
				</p>
				<p>
					<span>4.《Effect of head positioning in panoramic radiography on vertical measurements:an in bitro study》</span>
					<span>1996年发表在英国dentomaxillofac Radiol,vol.25.No.2.61-66,1996刊物上。</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Founder",
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '',
			title4: 0,
			icon: require("../images/创始人icon.png"),
			title4List: [
				{
					tittle: "创始人简介",
				},
			],
		};
	},
	watch:{
        title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
            },
            deep: true,
        }
    }
};
</script>
<style scoped>
.font .Introduction {
  margin: 0px 32px 31px;
  position: relative;
}
.font .Introduction img {
  display: inline-block;
  height: 183px;
}
.font .Introduction div {
  display: inline-block;
  width: 675px;
  height: 183px;
  margin-left: 16px;
  background-color: #f7f7f7;
  position: absolute;
}
.font .Introduction div h2 {
  color: #262b33;
  margin: 15px 24px 0px;
}
.font .Introduction div p {
  color: #262b33;
  margin: 5px 24px 0px;
  letter-spacing: 0px;
}
.font .Introduction div p span {
  color: #262b33;
  letter-spacing: 1px;
}
.font .paper {
  margin: 24px 32px 32px;
  background-color: #f7f7f7;
  padding: 26px 0;
}
.font .paper p {
  margin: 12px 36px 0px;
  text-indent: 0em;
  color: #262b33;
}
.font .paper .inpaper {
  margin: 0px 36px 0px;
}
.font .paper p span {
  display: block;
  font-weight: 300;
  color: #262b33;
}
/*pad竖屏*/
@media only screen and (max-width: 1024px) {
  /* 创始人简介 */
  .font .Introduction {
    margin: 0px 32px 39px;
  }
  .font .Introduction div {
    display: inline-block;
    width: 470px;
    height: 183px;
    margin-left: 40px;
    background-color: #fff;
    position: absolute;
  }
  .font .Introduction div h2 {
    margin: 0;
    font-weight: 500;
    color: #262b33;
  }
  .font .Introduction div p {
    margin: 5px 0 0px;
    line-height: 28px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #262b33;
  }
  .font .Introduction div p span {
    color: #262b33;
    letter-spacing: 1px;
  }

  .font .paper {
    margin: 23px 32px 32px;
    padding: 26px 0;
  }
  .font .paper p {
    margin: 12px 36px 0px;
    text-indent: 0em;
    line-height: 28px;
  }
  .font .paper .inpaper {
    margin: 0px 36px 0px;
  }
}
/*手机端*/
@media only screen and (max-width:750px){
  /* 创始人简介 */
  .font .Introduction {
    margin: 0px 15px 15px;
    width: calc(100% - 30px);
    position: relative;
  }
  .font .Introduction img {
    display: inline-block;
    height: 183px;
    margin: 0 auto;
    position: absolute;
    z-index: 99;
  }
  .font .Introduction div {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
    background-color: #fff;
    position: relative;
  }
  .font .Introduction div h2 {
    margin: 0;
    padding-top: 183px;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    float: left;
    width: 157px;
    /* height: 200px; */
  }
  .font .Introduction div p {
    display: block;
    margin: 0px 0 0px;
    line-height: 23px;
    letter-spacing: 0px;
  }
  .font .Introduction div p span{
    letter-spacing: 0px;
  }
  .font .paper {
    margin: 27px 0 0px;
    padding: 15px 0 40px;
    margin-bottom: -90px;
  }
  .font .paper p {
    display: block;
    margin: 15px 15px;
    text-indent: 0em;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #262b33;
  }
  .font .paper .inpaper {
    margin: 15px 15px;
  }
  .font .paper p span {
    font-weight: 300;
    color: #262b33;
  }
}
/*手机端1080*/
/* @media only screen and (min-height: 1300px) {
  创始人简介
  .font .Introduction {
    margin: 15px 44px 31px;
    width: calc(100% - 88px);
  }
  .font .Introduction img {
    display: block;
    height: 264px;
    margin: 0 auto;
  }
  .font .Introduction div {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
    background-color: #fff;
    position: relative;
  }
  .font .Introduction div h2 {
    margin: 0px auto;
    text-align: center;
    font-size: 40px;
    line-height: 104px;
    font-weight: 500;
  }
  .font .Introduction div p {
    margin: 11px 0 0px;
    letter-spacing: 0px;
  }
  .font .paper {
    margin: 79px 0 0px;
    padding: 79px 0 13px;
  }
  .font .paper p {
    margin: 60px 44px;
    text-indent: 0em;
    font-size: 38px;
    line-height: 48px;
    font-weight: 400;
    color: #262b33;
  }
  .font .paper .inpaper {
    margin: 0px 44px;
  }
  .font .paper p span {
    font-weight: 300;
    color: #262b33;
  }
} */
</style>
