<!-- 顶部导航栏 -->
<template>
	<div>
    	<header1 v-show="bodyWidthMut > 1170"></header1>
    	<header2 v-show="bodyWidthMut <= 1170"></header2>
    </div>
</template>

<script>
import header1 from './header1.vue';
import header2 from './header2.vue';

export default {
    name: 'Header',
    data(){
        return{
        	bodyWidthMut: document.body.clientWidth,
        }
    },
    components:{
		header1,
		header2,
    },
    mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
}
</script>
<style scoped>

</style>
