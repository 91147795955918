<!-- 首页顶部轮播图 -->
<template>
<!-- 顶部轮播图　-->       
	<div id="banner">
		<banner1 v-show="bodyWidthMut > 1170"></banner1>
		<banner2 v-show="bodyWidthMut > 1024 && bodyWidthMut <= 1170"></banner2>
		<banner3 v-show="bodyWidthMut > 750 && bodyWidthMut <= 1024"></banner3>
		<banner4 v-show="bodyWidthMut <= 750"></banner4>
	</div>
</template>

<script>
import Swiper from 'swiper';
import banner1 from './banner1.vue';
import banner2 from './banner2.vue';
import banner3 from './banner3.vue';
import banner4 from './banner4.vue';

export default {
    name: 'Banner',
    data() {
		return {
			bodyWidthMut: document.body.clientWidth,
		}
	},
	components:{
		banner1,
		banner2,
		banner3,
		banner4,
    },
	mounted(){
        window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
}
</script>

<style>
/*pc*/
html {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}
body {
	margin: 0;
	padding: 0;
}
#banner {
	width: 100%;
	/*max-height:70vh;*/
}
#banner .swiper-container {
	width:100%;
	/*max-height:70vh;*/
	margin: 0 auto;
}
#banner .swiper-container .swiper-wrapper .swiper-slide {
	width:100%;
	position: relative;
	
}
#banner .swiper-container .swiper-wrapper .swiper-slide .img-fix {
	width:100%;
	margin-top:0;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .img-fix .img-inner img{
	width: 100%;
	display:block;
	position: relative;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .img-fix .img-inner a{
	width: 100%;
	height:100%;
	display:block;
	position: absolute;
	/*background:rgba(255,0,0,0.5);*/
	top:0;
	left:0;
}
#banner .swiper-container .swiper-button-next,#banner .swiper-container .swiper-button-prev {
	position: absolute;
	background: none;
	top: 50%;
	display: block;
	height: 60px;
	width: 60px;
	color: rgba(0, 0, 0, 0);
}
#banner .swiper-container .swiper-button-next{
    background:url("./images/向右移入.png");
	opacity: 0;
	transition: opacity .3s;
    right: 72px;
}
#banner .swiper-container .swiper-button-prev{
    background:url("./images/向左移入.png");
	opacity: 0;
	transition: opacity .3s;
    left: 72px;
}
#banner .swiper-container .swiper-button-next:hover, #banner .swiper-container .swiper-button-prev:hover{
	opacity: 1;
}

#banner .swiper-container #under-swiper-button-next{
	position: absolute;
	background: url("./images/向右静默状态.png");
	top: 50%;
	display: block;
	height: 60px;
	width: 60px;
	right: 72px;
	opacity: 1;
	border-radius:50%;
	box-shadow: 0px 2px 8px rgba(4,5,5,0.04);
}
#banner .swiper-container #under-swiper-button-prev{
	position: absolute;
	background: url("./images/向左静默状态.png");
	top: 50%;
	display: block;
	height: 60px;
	width: 60px;
	left: 72px;
	opacity: 1;
	border-radius:50%;
	box-shadow: 0px 2px 8px rgba(4,5,5,0.08);
}

#banner .swiper-container .bottom-nav {
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 40px;
	height: auto;
	z-index: 10;
}
#banner .swiper-container .bottom-nav .swiper-pagination {
	position: relative;
	display: inline-block;
	height: 12px;
	width: auto;
	vertical-align: middle;
}
#banner .swiper-container .bottom-nav .swiper-pagination-bullet {
	position: relative;
	display: inline-block;
	left:0px;
	width: 12px;
	height: 12px;
	box-sizing: border-box;
	border: 2px solid #fff;
	margin: 0 7px;
	vertical-align: middle;
	opacity: 0.3;
	background-color: rgba(0,0,0,0);
}
#banner .swiper-container .bottom-nav .swiper-pagination-bullet-active {
	background: #fff;
	opacity: 1;
}	

/*pad横屏*/
@media only screen and (max-width:1170px){
	#banner .swiper-container .swiper-button-next,#banner .swiper-container .swiper-button-prev{
		display: none;
		z-index:-1;
	}
	#banner .swiper-container .bottom-nav .swiper-pagination-bullet {
	    width: 10px;
	    height: 10px;
	    border: 2px solid #fff;
	    margin: 0 6px;
	}
}

/*pad竖屏*/
@media only screen and (max-width:1024px){
	#banner .swiper-container .swiper-button-next,#banner .swiper-container .swiper-button-prev{
		display: none;
		z-index:-1;
	}
	#banner .swiper-container .bottom-nav .swiper-pagination-bullet {
	    width: 10px;
	    height: 10px;
	    border: 2px solid #fff;
	    margin: 0 6px;
	}
}

/*手机端*/
@media only screen and (max-width:750px){
	html {
		margin: 0;
		padding: 0;
		overflow-x: auto;
	}
	#banner .swiper-container .swiper-button-next,#banner .swiper-container .swiper-button-prev{
		display: none;
		z-index:-1;
	}
	#banner .swiper-container .bottom-nav .swiper-pagination-bullet {
	    width: 6px;
	    height: 6px;
	    border: 1px solid #fff;
	    margin: 0 4px;
	}
}

/*手机端1080
@media only screen and (min-height:1300px){
	#banner .swiper-container .swiper-button-next,#banner .swiper-container .swiper-button-prev{
		display: none;
		z-index:-1;
	}
	#banner .swiper-container .bottom-nav .swiper-pagination-bullet {
	    width: 18px;
	    height: 18px;
	    border: 3px solid #fff;
	    margin: 0 11px;
	}
}*/
</style>
