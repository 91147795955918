<template>
    <div class="page-slidecode">
        <div class="border">
            <h1>安全验证<span @click="turnoff()"></span></h1>
            <slide-verify
                v-if="bodyWidthMut > 1170"
                ref="slideblock"
                :l="42"
                :r="10"
                :w="320"
                :h="168"
                :imgs="bgimgs"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                slider-text="向右滑动~"
            ></slide-verify> 
            <div class="numberValidate" v-if="bodyWidthMut <= 1170">
                <div>
                    <el-input v-model="imgCodeValue"></el-input>
                    <imgCode :identifyCode="identifyCode" @refreshCode="refreshCode"></imgCode>
                </div>
                <el-button :type="CodeButType" plain @click="validate()">{{CodeButText}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import imgCode from "@/components/imgCode"
export default {
    name: 'Verification',
    components: {
		imgCode
	},
    computed:{
		...mapState({
			nowUrl:(state)=>state.Service.nowUrl,
		})
	},
    data() {
        return {
            bodyWidthMut: document.body.clientWidth,

            identifyCode: "",
            imgCodeValue: "",
            codeList: ['0','1','2','3','4','5','6','7','8','9','q','w','e','r','t','y','u','o','p','a','s','d','f','g','h','j','k','z','x','c','v','b','n','m'], 
            CodeButType: 'primary',
            CodeButText: '  确定',

            verShow:false,
            bgimgs:[
                // require('./images/端午1@2x.png'),
                // require('./images/端午2@2x.png'),
                require('./images/风景1@2x.png'),
                require('./images/风景2@2x.png'),
                // require('./images/父亲节1@2x.png'),
                // require('./images/父亲节2@2x.png'),
                require('./images/凝思1@2x.png'),
                require('./images/凝思2@2x.png'),
                require('./images/凝思3@2x.png'),
                require('./images/兔年1@2x.png'),
                require('./images/兔年2@2x.png'),
            ]
        }
    },
    mounted() {
        window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
        this.refreshCode()
    },
    methods: {
	    refreshCode() {
	      this.identifyCode = "";
	      this.imgCodeValue = "";
	      this.makeCode(this.codeList, 6);
	    },
	    makeCode(data, len) {
	      for (let i = 0; i < len; i++) {
	        this.identifyCode = this.identifyCode + data[Math.round(Math.random()*(data.length - 1))]
	      }
	    },
        validate() {
            if(this.imgCodeValue === this.identifyCode) {
                this.CodeButType = 'success'
                this.CodeButText = "成功"
                this.GoLink()
            } else {
                this.CodeButType = 'danger'
                this.CodeButText = "失败"
            }
            window.setTimeout(() => {
                this.CodeButType = 'primary'
                this.CodeButText = "确定"
            }, 1500)
            this.refreshCode()
        },
        onSuccess() {
            this.GoLink();
            this.$refs.slideblock.reset();
        },
        GoLink() {
            const nowurl = this.nowUrl 
            // const url = (Math.round(Math.random()) ? 'https://www.linx-info.com' : 'http://www.linx-sc.com:13335') + nowurl
            const url = 'https://www.linx-info.com' + nowurl
            // console.log('登录成功~', url)
            window.open(url, '_blank');
            this.$store.commit('getNowUrl', '')
            this.$store.commit('getVerShow', false)
            this.$store.commit('getNowUrls', nowurl)
            window.setTimeout(() => {
				this.$store.commit('removeNowUrl', nowurl)
			}, 10000)
        },
        onFail() {
            // this.$refs.slideblock.reset();
            // console.log('登录失败！')
        },
        onRefresh() {
            // console.log('重新生成验证码')
        },

        turnoff() {
            this.$store.commit('getNowUrl', '')
			this.$store.commit('getVerShow', false)
        }
    }
}
</script>

<style>
.page-slidecode{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(20, 22, 28, 0.4);
}
.page-slidecode .border{
    margin: calc(50vh - 150px) auto;
    width: 368px;
    /* height: 180px; */
    padding: 0 0 26px;
    background: #fff;
    text-align: center;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 3px 16px 0px rgba(0,0,0,00.016);
}
.page-slidecode .border h1 {
    border-bottom: 1px solid #e5e5e5;
    width: 368px;
    height: 48px;
    line-height: 48px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
}
.page-slidecode .border h1 span {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 4px;
    right: 4px;
    background: url("./images/验证弹窗关闭-默认@2x.png") center no-repeat;
    background-size: 14px 14px;
}
.page-slidecode .border h1 span:hover {
    background: url("./images/验证弹窗关闭-移入@2x.png") center no-repeat;
    background-size: 14px 14px;
    background-color: rgba(221,29,41,0.75);
}
.page-slidecode .border .slide-verify{
    margin: 0 auto;
}

.numberValidate {
    margin: 32px 32px 0;
}
.numberValidate > div {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: auto 88px;
    justify-content:space-between;
}
.numberValidate .el-button {
    width: 100%;
    margin-top: 12px;
}
</style>
