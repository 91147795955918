<template>
    <div class="featureBox">
        <div class="featureImage2">
            <div
                v-for="(feature11,index11) in feature"
                :key="index11 + '-1'" 
                @click="figureShow = index11"
                class="outOffeature"
                :class="{boxshadow:figureShow == index11}"
            >
                <p
                    class="feature"
                    :id="feature11.id"
                    :class="{infeature:figureShow == index11}"
                >{{feature11.title}}</p>
            </div>
        </div>
        <div class="featureImage">
            <p
                v-for="(feature12,index12) in feature.slice(0,5)"
                :key="index12 + '-2'" 
                class="feature"
                :id="feature12.id"
                @click="figureShow = index12"
                :class="{infeature:figureShow == index12}"
            >{{feature12.title}}</p>
        </div>
        <div class="featureText">
            <div
                class="text"
                v-for="(feature2,index2) in feature"
                :key="index2 + '-3'"
                v-show="figureShow == index2"
            >
                <h4>{{feature2.title}}</h4>
                <p v-for="(p,indext) in feature2.text" :key="indext" v-html="p"></p>
            </div>
        </div>
        <div class="featureImage">
            <p
                v-for="(feature13,index13) in feature.slice(5,10)"
                :key="index13 + '-2'" 
                class="feature"
                :id="feature13.id"
                @click="figureShow = (index13 + 5)"
                :class="{infeature:figureShow == (index13 + 5)}"
            >{{feature13.title}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeatureBox',
    data() {
        return {
            feature:this.$attrs.feature,
            figureShow:0,
        }
    },
}
</script>

<style scoped>
</style>