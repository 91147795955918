<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<p>凝思软件为满足中国电网、党政部门和军队特定业务应用需要，开发了众多系统功能软件，如：异构系统文件共享、虚拟专用网络、统一标记集中管理、多屏显示、零拷贝系统、安全容器系统、海量连接系统、指纹仪认证平台软件、内网主机安全监管软件等。凝思软件完成的软硬一体、系统内核和应用级开发达三十余项，充分满足了行业用户的高难度功能特性需要。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'SystemFunctionSoftwareDevelopment',
}
</script>
<style scoped>

</style>
