<template>
<div id="titleinbox">
	<div id="titleinpage" @click="title4Hide = !title4Hide" v-show="title4Length>1">
		{{title4List[title4].tittle}}
		<div id="rinpage" :class="title4Hide ? 'rinpage2' : 'rinpage1'"></div>
	</div>
	<div id="title4" :class="title4Hide ? '' : 'title4Hide'">
      <a
        v-for="(item,index) in title4List"
        :key="index + '-1'"
        @click="changeTitle4(index);titleinpagetext=title4List[title4].tittle"
        :class="{title5:(title4 == index || title4Length==1), title5_1:item.tittle.includes('（服务器和工作站版）') }"
        :id="(title4 == index) && item.tittle.includes('（服务器和工作站版）') ? 'title5_2' : ''"
        v-html="item.tittle"
        :data-content="item.tittle"
      ></a>
	</div> 
</div>
</template>
<script>
export default {
    name: 'SideNavigation',
    props: ['title4'],
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			title4List: this.$attrs.title4List,
			title4Length: 1,
			title4Hide :false,
      		titleinpagetext:'请选择',
		}
	},
    mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
      this.title4Length = this.title4List.length;
    },
	methods:{
		changeTitle4(index) {
			this.$emit('update:title4', index);
			this.title4Hide = !this.title4Hide;
		},
	},
	watch: {
		bodyWidthMut:{
			immediate:true,
			handler(val){
				this.title4Hide = false;
				if(val > 1170){
					this.title4Hide = false;
				}
			}
		}
	},
}
</script>
<style scoped>
/*pc*/
#titleinbox{
  display: inline-block;
  vertical-align: top;
  white-space:normal;
}
#titleinpage{
    display:none;
}
#title4{
  position: relative;
  width: 248px;
  border: #e0e0e0 1px solid;
}
#title4 a{
  position: relative;
  display: block;
  width: calc(100% - 32px);
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing:0px;
  /* text-indent: 32px; */
  padding: 24px 0;
  padding-left: 32px;
  color: #262b33;
  z-index: 1;
  cursor:pointer;
}

#title4 .title5:after,#title4 a:hover:after{
  content:attr(data-content);
  display: inline-block;
  position:absolute;
  vertical-align: middle;
  width: calc(100% - 32px);
  padding: 24px 0;
  padding-left: 32px;
  height: 24px;
  line-height: 24px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  letter-spacing:0px;
  font-weight: 500;
  color: #dd1d29;
  background-color: #f7f7f7;
  top:-1px;
  left: 0;
  z-index: 9;
}
#title4 .title5::before,#title4 a:hover:before{
  display: block;
  position:absolute;
  content: '';
  height: 74px;
  width: 2px;
  background-color: #dd1d29;
  z-index: 10;
  top:-1px;
  left: -1px;
}

#title4 .title5_1{
  position: relative;
  display: block;
  width: calc(100% - 32px);
  height: 44px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing:0px;
  /* text-indent: 32px; */
  padding: 24px 0;
  padding-left: 32px;
  color: #262b33;
  z-index: 1;
}
#title4 #title5_2:after,#title4 .title5_1:hover:after{
  content:attr(data-content);
  display: inline-block;
  position:absolute;
  vertical-align: middle;
  width: calc(100% - 32px);
  padding: 24px 0;
  padding-left: 32px;
  height: 44px;
  line-height: 24px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  letter-spacing:0px;
  font-weight: 500;
  color: #dd1d29;
  background-color: #f7f7f7;
  top:-1px;
  left: 0;
  z-index: 9;
}
#title4 #title5_2:before,#title4 .title5_1:hover:before{
  display: block;
  position:absolute;
  content: '';
  height: 94px;
  width: 2px;
  background-color: #dd1d29;
  z-index: 10;
  top:-1px;
  left: -1px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	  /*三级导航栏*/
  #titleinpage{
    display:block;
    position: relative;
    width:872px;
    height:47px;
    padding-left:30px;
    line-height:49px;
    font-size:18px;
    font-weight: 500;
    color:#565d69;
    background-color:#fff;
    border:1px solid #e0e0e0;
    margin:0 auto 40px;
    z-index:8;
  }
  #titleinpage #rinpage{
      display:block;
      position: absolute;
      width:59px;
      height:47px;
      right:0;
      top:0;
      background:url(./images/箭头-蓝灰.png) no-repeat center center;
      transition:transform 0.15s;
  }
  .rinpage1{transform:rotate(0deg);}
  .rinpage2{transform:rotate(90deg);}

  #title4{
    position: absolute;
    width:902px;
    padding:22px 0;
    top:48px;
    border: #e0e0e0 1px solid;
    box-shadow: 0px 3px 12px rgba(5,5,5,0.1);
    margin: 0;
    background-color:#fff;
    z-index:9999;
  }
  .title4Hide{display: none!important;}
#title4 .title5:after,#title4 a:hover:after,
#title4 .title5::before,#title4 a:hover:before,
#title4 #title5_2:after,#title4 .title5_1:hover:after,
#title4 #title5_2:before,#title4 .title5_1:hover:before{
  display: none;
}
#title4 a{
  font-size: 18px;
}
#title4 .title5{
    font-weight: 500;
    color: #292929;
  }
  #title4 .title5_1{
    line-height: 24px;
    height: 24px;
  }

}
/*pad竖屏*/
@media only screen and (max-width:1024px){
  /*三级导航栏*/
  #titleinpage{
    width:703px;
    padding-left:15px;
  }
  #title4{
    width:718px;
  }
}
/*手机端*/
@media only screen and (max-width:750px){
  #titleinpage{
    width:calc(100vw - 15px - 1px - 30px - 35px);
    height:35px;
    line-height:35px;
    font-size:14px;
    margin: 0 auto;
    margin-left: 15px;
    padding-right:35px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
  }
  #titleinpage #rinpage{
    width:34.5px;
    height:35px;
    background-size: 5px 8px;
  }
  #title4{
    width:calc(100% - 30px - 1px);
    left:15px;
    padding:13px 0;
    top:35.5px;
    white-space:nowrap;
  }
  #title4 .title5:after,#title4 a:hover:after,
#title4 .title5::before,#title4 a:hover:before,
#title4 #title5_2:after,#title4 .title5_1:hover:after,
#title4 #title5_2:before,#title4 .title5_1:hover:before{
  display: none;
}
#title4 a,#title4 .title5_1{
  height:24px;
    line-height:24px;
    font-size: 14px;
    padding: 12px 0;
    padding-left: 16px;
    0width: calc(100% - 16px);
}
#title4 .title5,#title4 .title5:hover{
    font-weight: 500;
    color: #292929;
    height:24px;
    line-height:24px;
    font-size: 14px;
  }
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
  #titleinpage{
    width:calc(100% - 88px - 2px - 40px);
    padding-left:40px;
    height:100px;
    line-height:100px;
    font-size:40px;
    margin: 0 auto;
  }
  #titleinpage #rinpage{
    width:89px;
    height:100px;
    background-size:13px;
  }

  #title4{
    width:calc(100% - 88px - 2px);
    left:44px;
    padding:32px 0;
    top:101px;
  }
  .title5,.title5:hover,#title5_1,#title5_n,#title5_2,#title5_3{
    height:104px;
    line-height:104px;
    font-size: 40px;
  }
  .title6, .title6:hover,#title6_1,#title6_n,#title6_2,#title6_3{
    display:block;
    position: relative;
    width: calc(100% - 40px);
    padding:0;
    padding-left:40px;
  }
  .title6 span{
      width: calc(100%);
      height:104px;
      line-height:104px;
      font-size: 40px;
  }
  #title6_1 span,#title6_n span,#title6_2 span,#title6_3 span{
      color: rgba(20,20,20,0.8);
  }
  三级导航栏展开
  .title6 .rinti1,.title6 .rinti2{
    width: 104px;
    height: 104px;
    background-size: 10px 16px;
  }
  .title6 .fillti{
    width:calc(100% - 88px - 2px);
    padding:32px 0;
    padding-left: 88px;
    top:101px;
  }
  .title6 .fillti a{
    width: 800px;
    line-height:104px;
    font-size: 40px;
  }
} */
</style>
