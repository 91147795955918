<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思触摸屏软件功能特点：</h2>
					<div class="textDetails">
						<div>
							<p class="figure figure1"><b>支持在物理触摸屏幕的点击功能</b>通过单击触摸屏，实现和鼠标单击一样的效果和功能。</p>
							<p class="figure figure2"><b>在一台机器上同时支持多个物理的触摸屏</b>触摸屏软件支持多个屏幕，每个屏幕可对应一个SCREEN，在任意的屏幕上点击，即可操作该屏幕上对应的内容。支持的屏幕数量没有限制。单个触摸屏故障不影响其余触摸屏的使用。</p>
							<p class="figure figure3"><b>在每个物理屏幕上支持两点触摸</b>触摸屏软件不但支持单点触摸，同时支持两点触摸，比如图片放大应用，可以两个点同时触摸，进行图片的放大和缩小。</p>
							<p class="figure figure4"><b>支持鼠标右键功能</b>选中操作对象后，点击该对象一段时间后保持不动，即等同于进行了鼠标右键点击。</p>
							<p class="figure figure5"><b>支持拖动功能</b>触摸屏软件支持拖动功能，可以将应用窗口在屏幕上任意拖动，和鼠标拖动一样的效果。</p>
						</div>
						<div>
							<p class="figure figure6"><b>支持屏幕的自由切换功能</b>在多个物理触摸屏幕时，可任意点击触摸屏，当前鼠标将会自动跟随，当前焦点自动切换到所点击的屏幕和窗口。</p>
							<p class="figure figure7"><b>支持USB接口的触摸屏</b>支持USB接口方式的触摸屏，支持热插拔。当拔掉再接上后，自动恢复并支持触摸功能。</p>
							<p class="figure figure8"><b>可配置性</b>在多个物理触摸屏间，提供可配置能力，配置触摸屏和显示屏幕之间的对应关系。</p>
							<p class="figure figure9"><b>支持多种型号的触摸屏设备</b>支持型号包括EIZO T2351W，EIZO T2381W，Philips 2315t。</p>
							<p class="figure figure10"><b>支持多种操作系统</b>支持Linux 2.6.32内核的多种操作系统。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'TouchScreen',
}
</script>
<style scoped>

</style>
