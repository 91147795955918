/*路由配置文件*/
import Vue from 'vue';
import VueRouter from 'vue-router';

/*使用插件*/
Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

/*引入组件*/
//首页
import Home from '../pages/Home'
//产品中心
import Products from '../pages/Products'
import ProductsCatalog from '../pages/Products/ProductsCatalog'
import OperatingSystem from '../pages/Products/OperatingSystem'
import SystemEnhancements from '../pages/Products/SystemEnhancements'
import PlatformSoftware from '../pages/Products/PlatformSoftware'
import SecureServerPlatform from '../pages/Products/SecureServerPlatform'
//import SecurityProducts from '../pages/Products/SecurityProducts'
import CustomDevelopment from '../pages/Products/CustomDevelopment'
//解决方案
import Solutions from '../pages/Solutions'
import Government from '../pages/Solutions/Government'
import PoverIndustry from '../pages/Solutions/PoverIndustry'
import Rail from '../pages/Solutions/Rail'
import Army from '../pages/Solutions/Army'
import ResearchInstitutes from '../pages/Solutions/ResearchInstitutes'
import Operator from '../pages/Solutions/Operator'
import Petrochemicals from '../pages/Solutions/Petrochemicals'
import WaterConservancy from '../pages/Solutions/WaterConservancy'
import Solution from '../pages/Solutions/Solution'
import GovernmentDetails from '../pages/Solutions/GovernmentDetails'
import PoverIndustryDetails from '../pages/Solutions/PoverIndustryDetails'
import RailDetails from '../pages/Solutions/RailDetails'
import ArmyDetails from '../pages/Solutions/ArmyDetails'
import ResearchInstitutesDetails from '../pages/Solutions/ResearchInstitutesDetails'
import OperatorDetails from '../pages/Solutions/OperatorDetails'
import PetrochemicalsDetails from '../pages/Solutions/PetrochemicalsDetails'
import WaterConservancyDetails from '../pages/Solutions/WaterConservancyDetails'
import SolutionDetails from '../pages/Solutions/SolutionDetails'
//生态合作
import EcologicalCooperation from '../pages/EcologicalCooperation'
import CooperativeValue from '../pages/EcologicalCooperation/CooperativeValue'
import EcologicalPartner from '../pages/EcologicalCooperation/EcologicalPartner'
import EcologicalLaboratory from '../pages/EcologicalCooperation/EcologicalLaboratory'
import CompatibleList from '../pages/EcologicalCooperation/CompatibleList'
//服务与支持
import ServiceAndSupport from '../pages/ServiceAndSupport'
import SoftwareDownload from '../pages/ServiceAndSupport/SoftwareDownload'
import DocumentDownload from '../pages/ServiceAndSupport/DocumentDownload'
import ServicePrecess from '../pages/ServiceAndSupport/ServicePrecess'
import CommonQuestions from '../pages/ServiceAndSupport/CommonQuestions'
import SecurityNotice from '../pages/ServiceAndSupport/SecurityNotice'
import NoticeDetails from '../pages/ServiceAndSupport/NoticeDetails'
//关于凝思
import AboutLinx from '../pages/AboutLinx'
import Company from '../pages/AboutLinx/Company'
import Founder from '../pages/AboutLinx/Founder'
import DevelopmentPath from '../pages/AboutLinx/DevelopmentPath'
import QualificationHonor from '../pages/AboutLinx/QualificationHonor'
import News from '../pages/AboutLinx/News'
import SearchNews from '../pages/AboutLinx/SearchNews'
import NewsDetails from '../pages/AboutLinx/NewsDetails'
// import Partner from '../pages/AboutLinx/Partner'
// import InvestorInformation from '../pages/AboutLinx/InvestorInformation'
import Enrollful from '../pages/AboutLinx/Enrollful'
import Contact from '../pages/AboutLinx/Contact'
//隐私政策
import PrivacyPolicy from '../pages/PrivacyPolicy'
//法律声明
import LegalNotices from '../pages/LegalNotices'
//404
import NotFound from '../pages/NotFound'

/*配置路由*/
const router =  new VueRouter({
	scrollBehavior(to,from,saveTop){
		window.scrollTo(0,0);
	},
	mode: 'hash',
    routes:[
	{
	    path:'/Home',
	    component:Home,
	    meta:{title:'先进的国产安全操作系统厂商'}
	},
	{
	    path:'/Products',
	    component:Products,
	    meta:{title:'凝思产品'},
	    redirect:"/NotFound",
		children:[
			{
				name:'ProductsCatalog',
				path:'ProductsCatalog',
				component:ProductsCatalog,
				meta:{title:'凝思产品'}
			},
			{
				name:'OperatingSystem',
				path:'OperatingSystem',
				component:OperatingSystem,
				meta:{title:'操作系统'}
			},
			{
				name:'SystemEnhancements',
				path:'SystemEnhancements',
				component:SystemEnhancements,
				meta:{title:'系统功能增强软件'}
			},
			{
				name:'PlatformSoftware',
				path:'PlatformSoftware',
				component:PlatformSoftware,
				meta:{title:'平台软件'}
			},
			{
				name:'SecureServerPlatform',
				path:'SecureServerPlatform',
				component:SecureServerPlatform,
				meta:{title:'安全服务器平台'}
			},
			/*
			{
				name:'SecurityProducts',
				path:'SecurityProducts',
				component:SecurityProducts,
				meta:{title:'安全产品'}
			},
			*/
			{
				name:'CustomDevelopment',
				path:'CustomDevelopment',
				component:CustomDevelopment,
				meta:{title:'定制开发'}
			}
		]
	},
	{
	    path:'/Solutions',
	    component:Solutions,
	    meta:{title:'解决方案'},
	    redirect:"/NotFound",
		children:[
			{
				name:'Government',
				path:'Government',
				component:Government,
				meta:{title:'政府机关'}
			},
			{
				name:'PoverIndustry',
				path:'PoverIndustry',
				component:PoverIndustry,
				meta:{title:'电力行业'}
			},
			{
				name:'Rail',
				path:'Rail',
				component:Rail,
				meta:{title:'轨道交通'}
			},
			{
				name:'Army',
				path:'Army',
				component:Army,
				meta:{title:'军队'}
			},
			{
				name:'ResearchInstitutes',
				path:'ResearchInstitutes',
				component:ResearchInstitutes,
				meta:{title:'科研院所'}
			},
			/*
			{
				name:'Operator',
				path:'Operator',
				component:Operator,
				meta:{title:'运营商'}
			},
			{
				name:'Petrochemicals',
				path:'Petrochemicals',
				component:Petrochemicals,
				meta:{title:'石油化工'}
			},
			{
				name:'WaterConservancy',
				path:'WaterConservancy',
				component:WaterConservancy,
				meta:{title:'水利'}
			},
			{
				name:'Solution',
				path:'Solution',
				component:Solution,
				meta:{title:'解决方案'}
			},
			*/
			{
				name:'GovernmentDetails',
				path:'GovernmentDetails',
				component:GovernmentDetails,
				meta:{title:'政府机关详情'}
			},
			{
				name:'PoverIndustryDetails',
				path:'PoverIndustryDetails',
				component:PoverIndustryDetails,
				meta:{title:'电力行业详情'}
			},
			{
				name:'RailDetails',
				path:'RailDetails',
				component:RailDetails,
				meta:{title:'轨道交通详情'}
			},
			{
				name:'ArmyDetails',
				path:'ArmyDetails',
				component:ArmyDetails,
				meta:{title:'军队详情'}
			},
			{
				name:'ResearchInstitutesDetails',
				path:'ResearchInstitutesDetails',
				component:ResearchInstitutesDetails,
				meta:{title:'科研院所详情'}
			},
			/*
			{
				name:'OperatorDetails',
				path:'OperatorDetails',
				component:OperatorDetails,
				meta:{title:'运营商详情'}
			},
			{
				name:'PetrochemicalsDetails',
				path:'PetrochemicalsDetails',
				component:PetrochemicalsDetails,
				meta:{title:'石油化工详情'}
			},
			{
				name:'WaterConservancyDetails',
				path:'WaterConservancyDetails',
				component:WaterConservancyDetails,
				meta:{title:'水利详情'}
			},
			{
				name:'SolutionDetails',
				path:'SolutionDetails',
				component:SolutionDetails,
				meta:{title:'解决方案详情'}
			}
			*/
		]
	},
	{
	    path:'/EcologicalCooperation',
	    component:EcologicalCooperation,
	    meta:{title:'生态合作'},
	    redirect:"/NotFound",
		children:[
			{
				name:'CooperativeValue',
				path:'CooperativeValue',
		 		component:CooperativeValue,
		 		meta:{title:'合作价值'}
			},
		 	{
		 		name:'EcologicalPartner',
		 		path:'EcologicalPartner',
		 		component:EcologicalPartner,
		 		meta:{title:'生态伙伴'}
		 	},
		 	{
		 		name:'EcologicalLaboratory',
		 		path:'EcologicalLaboratory',
		 		component:EcologicalLaboratory,
		 		meta:{title:'生态实验室'}
		 	},
		 	{
		 		name:'CompatibleList',
		 		path:'CompatibleList',
		 		component:CompatibleList,
		 		meta:{title:'兼容列表'}
		 	}
		]
	},
	{
	    path:'/ServiceAndSupport',
	    component:ServiceAndSupport,
	    meta:{title:'服务与支持'},
	    redirect:"/NotFound",
		children:[
			{
				name:'SecurityNotice',
				path:'SecurityNotice',
				component:SecurityNotice,
				meta:{title:'安全公告'}
			},
			{
				name:'NoticeDetails',
				path:'NoticeDetails',
				component:NoticeDetails,
				meta:{title:'公告详情'}
			},
			{
				name:'SoftwareDownload',
				path:'SoftwareDownload',
				component:SoftwareDownload,
				meta:{title:'软件下载'}
			},
			{
				name:'DocumentDownload',
				path:'DocumentDownload',
				component:DocumentDownload,
				meta:{title:'文档下载'}
			},
			{
				name:'ServicePrecess',
				path:'ServicePrecess',
				component:ServicePrecess,
				meta:{title:'服务流程'}
			},
			{
				name:'CommonQuestions',
				path:'CommonQuestions',
				component:CommonQuestions,
				meta:{title:'常见问题'}
			}
		]
	},
	{
	    path:'/AboutLinx',
	    component:AboutLinx,
	    meta:{title:'关于凝思'},
	    redirect:"/NotFound",
		children:[
			{
				name:'Company',
				path:'Company',
				component:Company,
				meta:{title:'公司简介'}
			},
			{
				name:'Founder',
				path:'Founder',
				component:Founder,
				meta:{title:'创始人简介'}
			},
			{
				name:'DevelopmentPath',
				path:'DevelopmentPath',
				component:DevelopmentPath,
				meta:{title:'发展历程'}
			},
			{
				name:'QualificationHonor',
				path:'QualificationHonor',
				component:QualificationHonor,
				meta:{title:'资质荣誉'}
			},
			{
				name:'News',
				path:'News',
				component:News,
				meta:{title:'新闻中心'}
			},
			{
				name:'SearchNews',
				path:'SearchNews',
				component:SearchNews,
				meta:{title:'新闻搜索'}
			},
			{
				name:'NewsDetails',
				path:'NewsDetails',
				component:NewsDetails,
				meta:{title:'新闻详情'}
			},
			// {
			// 	name:'Partner',
			// 	path:'Partner',
			// 	component:Partner,
			// 	meta:{title:'合作伙伴'}
			// },
			// {
			// 	name:'InvestorInformation',
			// 	path:'InvestorInformation',
			// 	component:InvestorInformation,
			// 	meta:{title:'投资者信息'}
			// },
			{
				name:'Enrollful',
				path:'Enrollful',
				component:Enrollful,
				meta:{title:'招贤纳士'}
			},
			{
				name:'Contact',
				path:'Contact',
				component:Contact,
				meta:{title:'联系我们'}
			}
		]
	},
	{
	    path:'/PrivacyPolicy',
	    component:PrivacyPolicy,
	    meta:{title:'隐私政策'}
	},
	{
	    path:'/LegalNotices',
	    component:LegalNotices,
	    meta:{title:'法律声明'}
	},
	{
	    path:'/NotFound',
	    component:NotFound,
	    meta:{title:'页面错误'}
	},
	//重定向
	{
	    path:'',
	    redirect:"/Home"
	},
	{
	    path:'*',
	    redirect:"/NotFound"
	}
	
    ]
})

//全局后置路由守卫——title显示
router.afterEach((to,from)=>{
	document.title = '凝思软件-' + to.meta.title
})

export default router
