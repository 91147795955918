<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
            <div class="Linx">
                <div>
                    <h2>打造自主可控的中国操作系统新生态</h2>
                </div>
            </div>
            <div class="grayBlock">
           		<h2>各位合作伙伴：</h2>
           		<h3>大家好！</h3>
                <p>动荡的全球局势，让中国科技自主创新发展的需求愈发迫切。为摆脱核心技术处处受掣肘的现状，2006年国务院颁布《国家中长期科学和技术发展规划纲要（2006-2020年）》，其中“核高基”被列为16个重大科技专项之一，标志着中国信创产业正式起步。后续国家接连发布多项政策，不断强调加快关键硬件和软件的发展，为各个行业国产化替代提速。而操作系统作为协同软硬件的中间层，有着重要的战略地位。</p>
                <p>我国操作系统经过多年发展，正在从“可用”走向“好用”。但是，国产操作系统软件和厂商与国际成熟软件和巨头之间仍然存在相当大的差距，我们必须更加清醒地认识到国产基础软件的“重要性、艰巨性和长期性”。</p>
                <p>对于国产操作系统来说，技术和生态互为推动，缺一不可。从应用角度看，软件生态系统建设更为重要。未来，操作系统的竞争是生态的竞争，生态是否完善，能否不断满足用户需求，是国产操作系统可持续发展的关键。</p>
                <p>凝思软件坚持开放合作打造软硬件生态认证体系，自建生态实验室，已适配国内外主流的软硬件平台，并在线运行多年。凝思软件愿意携手合作伙伴快速融入产业生态，为客户提供完整的国产化解决方案。</p>
                <p>凝思软件真诚的希望您与我们合作，共同打造具有影响力的中国操作系统新生态，共创中国软件辉煌的未来！</p>
                <p>北京凝思软件股份有限公司</p>
            </div>
            <div class="spirt">
            	<h2>凝思软件合作伙伴权益</h2>
           		<div>
           			<h2>打造联合方案</h2>
           			<p>凝思软件提供高安全、定制化、兼容性高的产品并与合作伙伴一起打造联合解决方案</p>
           		</div>
           		<div>
           			<h2>宣传互动</h2>
           			<p>通过凝思软件的自媒体渠道获得免费的企业或产品宣传</p>
           		</div>
           		<div>
           			<h2>业务共赢</h2>
           			<p>通过凝思软件的市场渠道，共同开拓发掘新的客户市场</p>
           		</div>
           		<h2>凝思软件合作伙伴权益</h2>
           		<div>
           			<div>
           				<p>全国兼容适配热线：</p>
           				<p>400-690-8128 <b style="font-size:15px;font-weight: 500;">转</b> 2</p>
           			</div>
           			<div>
           				<p>全国兼容适配邮箱：</p>
           				<p>marketing@linx-info.com</p>
           			</div>
           		</div>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	name:'CooperativeValue',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '合作价值',
			title4: 0,
			icon: require('../images/合作价值.png'),
			title4List: [
				{
					tittle:'合作价值',
				}
			],
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
.font .Linx{
    width: 839px;
    height: 256px;
    margin:0 auto 56px;
    background: url(../images/合作价值背景图.png) no-repeat;
    background-size:auto 256px;
    position: relative;
}
.font .Linx div{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 48px;
}
.font .Linx div h2{
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
    font-size: 28px;
    font-weight: 400;
    letter-spacing:1px;
	margin: 0;
	text-indent: 0em;
}
.font .Linx:before{
	position: absolute;
	display:block;
	content:'';
	width:24px;
	height:2px;
	top:44px;
	left:48px;
	background-color:#fff;
}
.font .grayBlock{
	padding:34px 32px;
}
.font .grayBlock h2, .font .grayBlock h3, .font .grayBlock p{
	margin:0;
	color: #262b33;
	font-weight: 400;
	margin-top:28px;
	line-height:28px;
}
.font .grayBlock h3{
	text-indent: 1em;
}
.font .grayBlock h3:before{
	display:none;
}
.font .grayBlock p:last-child{
	text-align:right;
}

.font .spirt{
    padding: 32px 32px 12px;
    background: url(../images/长背景.png) no-repeat;
}
.font .spirt h2{
    font-size: 18px;
    font-weight: 500;
    line-height:66px;
	margin: 0;
	text-indent: 0em;
}
.font .spirt div{
    margin-bottom: 24px;
    background-color: #fff;
    padding: 22px 32px;
    box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
}
.font .spirt div h2{
	position: relative;
    line-height: 28px;
    margin: 0;
    color: #dd1d27;
    font-size: 16px;
    font-weight: 500;
    letter-spacing:1px;
    text-indent:24px;
}
.font .spirt div h2:before{
	position: absolute;
	display:block;
	content:'';
	width:16px;
	height:21px;
	top:2px;
	background: url(../images/权益.png) no-repeat center;
	background-size:16px 21px;
}
.font .spirt div p{
    line-height: 28px;
    margin: 0;
    color: #262b33;
    font-weight: 500;
    text-indent:0px;
    margin-left:24px;
}
.font .spirt > div:last-child{
	display:grid;
	grid-template-columns: 50% 50%;
}
.font .spirt div div{
    box-shadow: none;
    padding:0;
    margin:0;
}
.font .spirt div div p{
    display:block;
    color: #262b33;
    text-indent:21px;
}
.font .spirt div div:nth-child(2n) p{
    text-indent:53px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.font .Linx{
		width: 656px;
		background: url(../images/合作价值背景图.png);
	}
	.font .spirt div div p{
		display:block;
		color: #262b33;
		text-indent:0px;
		margin-left:0;
	}
	.font .spirt div div:nth-child(2n) p{
		text-indent:53px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.font .Linx div h3{
		font-size: 18px;
		line-height:28px;
		letter-spacing:0px;
		margin-bottom: 0;
	}
	.font .Linx div h2{
		font-size: 22px;
		line-height:32px;
		letter-spacing:0px;
	}
	.font .Linx{
		width: 100%;
		height: 150.5px;
		background-size:auto 150.5px;
	}
	.font .Linx div{
		padding-left: 15px;
	}
	.font .Linx div h2{
		font-size: 20px;
	}
	.font .Linx:before{
		width:18px;
		height:1.5px;
		top:22px;
		left:15px;
	}
	.font .grayBlock{
		padding:34px 15px;
	}
	.font .grayBlock h2, .font .grayBlock h3, .font .grayBlock p{
		margin-top:14px;
		line-height:23px;
	}

	.font .spirt{
		padding: 32px 15px 47px;
		margin-bottom:-59px;
		background: url(../images/长背景.png);
		background-size:auto 100%;
	}
	.font .spirt h2{
		font-size: 14px;
		line-height:43px;
	}
	.font .spirt div{
		margin-bottom: 18px;
		padding: 16.5px 15px;
		box-shadow: 0px 0.5px 2px 0px rgba(5,5,5,0.12);
	}
	.font .spirt div h2{
		line-height: 24px;
		font-size: 13px;
		letter-spacing:0.5px;
		text-indent:15px;
	}
	.font .spirt div h2:before{
		width:10.5px;
		height:13.5px;
		top:5px;
		background-size:10.5px 13.5px;
	}
	.font .spirt div p{
		line-height: 19px;
		margin-left:15px;
	}
	.font .spirt > div:last-child{
		grid-template-columns: 100%;
	}

	.font .spirt div div p,.font .spirt div div:nth-child(2n) p{
		text-indent:14px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.font .Linx div h3{
		font-size: 36px;
		line-height:56px;
		letter-spacing:0px;
		margin-bottom: 0;
	}
	.font .Linx div h2{
		font-size: 44px;
		line-height:64px;
		letter-spacing:0px;
	}
	.font .Linx{
		width: 100%;
		height: 301px;
		background-size:auto 301px;
	}
	.font .Linx div{
		padding-left: 30px;
	}
	.font .Linx div h2{
		font-size: 40px;
	}
	.font .Linx:before{
		width:36px;
		height:3px;
		top:44px;
		left:30px;
	}
	.font .grayBlock{
		padding:68px 30px;
	}
	.font .grayBlock p+p{
		margin-top:28px;
	}

	.font .spirt{
		padding: 64px 30px 94px;
		margin-bottom:-118px;
		background-size:auto 100%;
	}
	.font .spirt h2{
		font-size: 28px;
		line-height:86px;
	}
	.font .spirt div{
		margin-bottom: 36px;
		padding: 33px 30px;
		box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	}
	.font .spirt div h2{
		line-height: 48px;
		font-size: 26px;
		letter-spacing:1px;
		text-indent:30px;
	}
	.font .spirt div h2:before{
		width:21px;
		height:27px;
		top:10px;
		background-size:21px 27px;
	}
	.font .spirt div p{
		line-height: 38px;
		margin-left:30px;
	}
	.font .spirt > div:last-child{
		grid-template-columns: 100%;
	}

	.font .spirt div div p,.font .spirt div div:nth-child(2n) p{
		text-indent:28px;
	}
} */
</style>
