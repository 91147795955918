<template>
	<div id="boxTittleCatalog" :class="vAlign ? 'verticalAlignTop' : 'verticalAlignBottom'">
		<div id="tittleCatalog" :class="{tittleCatalogFixed:tCatalogChange}">
			<a
				@click="goAnchor('#ProductDescription')"
				:class="{inCatalog:cueColor == 0}"
			>产品概述</a>
			<a
				@click="goAnchor('#OperatingSystem')"
				:class="{inCatalog:cueColor == 1}"
			>操作系统</a>
			<a
				@click="goAnchor('#SystemEnhancements')"
				:class="{inCatalog:cueColor == 2}"
			>系统功能增强软件</a>
			<a
				@click="goAnchor('#PlatformSoftware')"
				:class="{inCatalog:cueColor == 3}"
			>平台软件</a>
			<a
				@click="goAnchor('#CustomDevelopment')"
				:class="{inCatalog:cueColor == 4}"
			>定制开发</a>
			<a
				@click="goAnchor('#SecureServerPlatform')"
				:class="{inCatalog:cueColor == 5}"
			>凝思安全服务器平台</a>
		</div> 
	</div>
</template>

<script>
export default {
    name: 'ScrollCatalog',
    data(){
        return{
            cueColor: 0,
			vAlign: 1,
			tCatalogChange: 0,
			hv: this.$attrs.hv,
        }
    },
	mounted(){
    	window.addEventListener("scroll",this.handleScroll,true);
		window.addEventListener("scroll",this.handleScroll2,true);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll,true);
    },
    methods:{
        goAnchor(id){
            document.querySelector(id).scrollIntoView({
                behavior:"smooth",
                block:"start",
            });
        },
        handleScroll(){
			// 目录浮动规则
			// var move=document.getElementById("tittleCatalog");
			var cueH = this.$parent.$refs.fontCatalog.offsetTop + 192 - 56;//目录距离页面顶端距离
			console.log(cueH)
			this.vAlign = 1;
			var pageY = document.body.scrollHeight;//页面高度
			var butH = 448 + 130 + 30;//页面底部组件高度
			var cueh = 274;//目录组件高度 var butH = 434+128;
        	var scroH = document.documentElement.scrollTop || document.body.scrollTopo || window.pageY0ffset;
			if((scroH + 120) >= cueH){
				this.tCatalogChange = 1;
				if((scroH + cueh + 120) >= (pageY - butH)){
					this.tCatalogChange = 0;
					this.vAlign = 0;
				}
			} else {
				this.tCatalogChange = 0;
				this.vAlign = 1;
			}
        },
		handleScroll2(){
			var scroH = document.documentElement.scrollTop || document.body.scrollTopo || window.pageY0ffset;
			var hProductDescription = this.hv[0];
			var hOperatingSystem = this.hv[1];
			var hSystemEnhancements = this.hv[2];
			var hPlatformSoftware = this.hv[3];
			var hSecureServerPlatform = this.hv[4];
			var hCustomDevelopment = this.hv[5];
			// 目录变色索引规则0
			// ProductDescription
			if(hOperatingSystem >(scroH + 100)){
				this.cueColor = 0;
			}
			// OperatingSystem
			else if(hSystemEnhancements >(scroH + 100) && hOperatingSystem <=(scroH + 100)){
				this.cueColor = 1;
			} 
			// SystemEnhancements
			else if(hPlatformSoftware >(scroH + 100) && hSystemEnhancements <=(scroH + 100)){
				this.cueColor = 2;
			}
			// PlatformSoftware
			else if(hCustomDevelopment >(scroH + 100) && hPlatformSoftware <=(scroH + 100)){
				this.cueColor = 3;
			}
			// CustomDevelopment
			else if(hSecureServerPlatform >(scroH + 100) && hCustomDevelopment <=(scroH + 100)){
				this.cueColor = 4;
			}
			// SecureServerPlatform
			else if(hSecureServerPlatform <=(scroH + 100)){
				this.cueColor = 5;
			}
        },
    },
    
}
</script>

<style scoped>
.verticalAlignTop{
	vertical-align: top;
}
.verticalAlignBottom{
	vertical-align: bottom;
}
#boxTittleCatalog{
	display:inline-block;
	width:240px;
	min-height:100px;
	margin-right:35px;
}
#tittleCatalog{
	display:block;
	margin-left:1px;
	border-left:1px solid #c2c2c2;
}
.tittleCatalogFixed{
	position:fixed;
	top:120px;
	left:calc(50vw - 585px -1px);
}
#tittleCatalog a{
	position: relative;
	display:block;
	line-height:44px;
	width:225px;
	padding-left:14px;
	color:#262b33;
	font-size:16px;
	font-weight:400;
	cursor:pointer;
}
#tittleCatalog a:hover{
	color:#dd1d29;
}
#tittleCatalog .inCatalog:before{
	position: absolute;
	display:block;
	content:'';
	height:44px;
	width:3px;
	background-color:#dd1d29;
	left:-2px;
}
#tittleCatalog .inCatalog{
	color:#262b33!important;
	font-weight:600;
}
#tittleCatalog a+a{
	margin-top:2px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#boxTittleCatalog{
		display:none;
	}
}
/*手机端1080
@media only screen and (min-height:1300px){
	#boxTittleCatalog{
		display:none;
	}
}*/
</style>
