/* News仓库 */
const state = {
	CompatibleList: [
		/*模板
		{
			title:'大数据',
			text:[
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'DM V7.6',
					AuthenticationSystem:'V6.0',
					time:'2018/4/1',
				},
			]
		},
		*/
		//软件兼容列表
		{
			title:'基础软件',
			text:[
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'达梦数据库管理系统 DM V7',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'达梦数据库管理系统 DM V8',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'达梦数据库管理系统V8',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'达梦数据库管理系统V8',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京人大金仓信息技术股份有限公司',
					CertifiedProduct:'KingbaseES V7',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京人大金仓信息技术股份有限公司',
					CertifiedProduct:'KingbaseES V8',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京人大金仓信息技术股份有限公司',
					CertifiedProduct:'金仓数据库管理系统 KingbaseES V7、V8',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京人大金仓信息技术股份有限公司',
					CertifiedProduct:'金仓数据库管理系统 KingbaseES V7、V8',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津神舟通用数据技术有限公司',
					CertifiedProduct:'神通数据库管理系统V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津神舟通用数据技术有限公司',
					CertifiedProduct:'神通数据库管理系统V7.0',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津神舟通用数据技术有限公司',
					CertifiedProduct:'神通数据库管理系统V7.0',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'瀚高基础软件股份有限公司',
					CertifiedProduct:' 瀚高安全版数据库系统V4.5',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'瀚高基础软件股份有限公司',
					CertifiedProduct:'瀚高企业版数据库系统V6.0',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京优炫软件股份有限公司',
					CertifiedProduct:'优炫数据库管理系统V2.1',
					AuthenticationSystem:'V6.0',
					time:'2021年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'西安热工研究院有限公司',
					CertifiedProduct:'睿腾（Rython）实时数据库V5.0',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司 ',
					CertifiedProduct:'中创应用服务器软件[简称：InforSuite AS]V9.1',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司 ',
					CertifiedProduct:'中创应用服务器软件[简称：InforSuite AS]V10',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司 ',
					CertifiedProduct:'中创应用服务器软件[简称：InforSuite AS]V9.1、V10',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司 ',
					CertifiedProduct:'中创应用服务器软件[简称：InforSuite AS]V9.1、V10',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京宝兰德软件股份有限公司',
					CertifiedProduct:'应用服务器软件BES Application Server V9.5',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京宝兰德软件股份有限公司',
					CertifiedProduct:'宝兰德应用服务器软件【简称：BES Application Server】V9.5',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京宝兰德软件股份有限公司',
					CertifiedProduct:'宝兰德应用服务器软件【简称：BES Application Server】V9.5',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京东方通科技股份有限公司',
					CertifiedProduct:'TongWebV7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方通科技股份有限公司',
					CertifiedProduct:'应用服务器软件TongWeb V7.0',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方通科技股份有限公司',
					CertifiedProduct:'应用服务器软件TongWeb V7.0',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'深圳市金蝶天燕云计算股份有限公司',
					CertifiedProduct:'金蝶Apusic应用服务器软件V9.0',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'深圳市金蝶天燕云计算股份有限公司',
					CertifiedProduct:'金蝶Apusic应用服务器软件V10',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'深圳市金蝶天燕云计算股份有限公司',
					CertifiedProduct:'金蝶Apusic应用服务器软件V9.0<br>金蝶Apusic应用服务器软件V10',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年10月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'深圳市金蝶天燕云计算股份有限公司',
					CertifiedProduct:'金蝶Apusic应用服务器软件V9.0<br>金蝶Apusic应用服务器软件V10',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京科蓝软件系统股份有限公司',
					CertifiedProduct:'Goldilocks分布式内存数据库系统V3.1',
					AuthenticationSystem:'V6.0',
					time:'2020年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'中兴GoldenDB分布式数据库',
					AuthenticationSystem:'V6.0',
					time:'2019年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京许继电气有限公司',
					CertifiedProduct:'许电华源数据库管理软件V3.0',
					AuthenticationSystem:'V6.0',
					time:'2020年02月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京奥星贝斯科技有限公司',
					CertifiedProduct:'OceanBase原生分布式数据库软件',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天云融创数据科技（北京）有限公司',
					CertifiedProduct:'天云融创Hubble数据库',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津南大通用数据技术有限公司',
					CertifiedProduct:'分布式交易型数据库管理系统GBase 8c',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津南大通用数据技术有限公司',
					CertifiedProduct:'南大通用安全数据库管理系统 [简称：GBase 8s]',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'四川蜀天梦图数据科技有限公司 ',
					CertifiedProduct:' GDM V2.0',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C SeaSQL EDW ',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'成都虚谷伟业科技有限公司 ',
					CertifiedProduct:'虚谷数据库V11.0 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元应用服务器软件[简称：PAS]V6.5 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元消息中间件软件[简称：Primeton MQ]V7 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件[简称：Primeton ESB]V6 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元大文件传输平台软件[简称：Primeton BFT] V7',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元BPS软件[简称：Primeton BPS] V7 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元应用开发平台软件[简称：EOS Platform] V8 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元应用开发平台软件[简称：EOS Platform] V7.6 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C SeaSQL DWS云数据库系统软件 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天谋科技（北京）有限公司',
					CertifiedProduct:' 物联网原生时序数据库管理系统 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'贵州易鲸捷信息技术有限公司',
					CertifiedProduct:'易鲸捷数据库管理软件(简称：QianBase xTP) V9.0.0 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'贵州易鲸捷信息技术有限公司',
					CertifiedProduct:'易鲸捷单机数据库管理软件(简称：QianBase TP) V12.10.0 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'贵州易鲸捷信息技术有限公司',
					CertifiedProduct:'易鲸捷QianBase大规模分布式并行数据库软件(简称QianBase MPP) V6.0 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京海量数据技术股份有限公司',
					CertifiedProduct:'海量数据库管理系统 VastbaseG100V2.2 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京万里开源软件有限公司',
					CertifiedProduct:'万里安全数据库软件V1.0',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京万里开源软件有限公司',
					CertifiedProduct:'万里安全数据库软件V5.1',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京万里开源软件有限公司',
					CertifiedProduct:'GreatSQL开源数据库',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方金信科技股份有限公司',
					CertifiedProduct:'海盒MPP数据库系统SeaboxMPP V21',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方金信科技股份有限公司',
					CertifiedProduct:'海盒通用数据库管理系统(SeaboxSQL)V11',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'金篆信科有限责任公司',
					CertifiedProduct:'GoldenDB V6',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'金篆信科有限责任公司',
					CertifiedProduct:'GoldenDB V6',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2022年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'金篆信科有限责任公司',
					CertifiedProduct:'GoldenDB V6',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2022年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京庚顿数据科技有限公司',
					CertifiedProduct:'庚顿实时数据库管理系统（Linux版）V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年11月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'湖南亚信安慧科技有限公司',
					CertifiedProduct:'AntDB数据库V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创防篡改软件V9.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创分布式缓存中间件软件[简称：InforSuite RDS]V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创负载均衡软件[简称：InforSuite LB]V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创统一监管平台软件[简称：InforGuard UMP]V2.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创消息中间件软件[简称：InforSuite MQ]V9.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创业务流程平台软件[简称：InforSuite Flow] V9.2、V10.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创业务信息和电子文件交换系统软件[简称：中创交换系统]V2.2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创应用服务器软件[简称：InforSuite AS]V10',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创PaaS平台软件V2.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},

				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元大文件传输平台软件[简称：Primeton BFT]V7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件[简称：Primeton ESB]V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件[简称：Primeton ESB]V8',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元消息中间件软件[简称：Primeton MQ]V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元应用服务器软件[简称：PAS] V6.5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元负载均衡软件[简称：Primeton LB] V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元数据缓存软件[简称：Primeton MemDB] V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元消息中间件软件[简称：Primeton MQ]V7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'云和恩墨（北京）信息技术有限公司',
					CertifiedProduct:'MogDB企业版数据库管理系统[简称：MogDB]V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据技术有限公司',
					CertifiedProduct:'达梦启云数据库云服务系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'杭州易景数通科技有限公司',
					CertifiedProduct:'羲和（Halo）通用数据库系统软件',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'贵州易鲸捷信息技术有限公司',
					CertifiedProduct:'易鲸捷数据库QianBase V7、QianBase V9、QianBase V15',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京涛思数据科技有限公司',
					CertifiedProduct:'TDengine数据库',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京快立方科技有限公司',
					CertifiedProduct:'快立方数据库管理系统V5（简称：Qcubic）',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京科蓝软件系统股份有限公司',
					CertifiedProduct:'内存数据库管理系统 [简称：SUNDB]V5.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京科蓝软件系统股份有限公司',
					CertifiedProduct:'分布式数据库管理系统 [简称：SUNDB]V5.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京科蓝软件系统股份有限公司',
					CertifiedProduct:'数据库管理系统 [简称：SUNDB]V5.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京奥星贝斯科技有限公司',
					CertifiedProduct:'OceanBase数据库软件V3',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'上海沄熹科技有限公司',
					CertifiedProduct:'开务数据库系统[简称：KaiwuDB]V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年11月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方金信科技股份有限公司',
					CertifiedProduct:'海盒通用数据库管理系统(SeaboxSQL)V21',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年11月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京东方金信科技股份有限公司',
					CertifiedProduct:'海盒MPP数据库系统（SeaboxMPP）V21',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年11月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'中软国际科技服务有限公司',
					CertifiedProduct:'中软国际磐石数据库（CSIDB V1.0.0）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京海量数据技术股份有限公司',
					CertifiedProduct:'海量数据库管理系统G100[简称：Vastbase G100]V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京海量数据技术股份有限公司',
					CertifiedProduct:'海量数据库管理系统Vastbase M100 V5.1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'格睿时代（杭州）信息科技有限公司',
					CertifiedProduct:'GreptimeDB',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'阿里云计算有限公司',
					CertifiedProduct:'开源PolarDB分布式版V2.2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'成都虚谷伟业科技有限公司',
					CertifiedProduct:'虚谷数据库V11.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'成都虚谷伟业科技有限公司',
					CertifiedProduct:'虚谷数据库V12.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'天津南大通用数据技术股份有限公司',
					CertifiedProduct:'南大通用大规模分布式并行数据库集群系统[简称：GBase 8a MPP Cluster]',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年04月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京优炫软件股份有限公司',
					CertifiedProduct:'优炫数据库管理系统V2.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'北京奥星贝斯科技有限公司',
					CertifiedProduct:'OceanBase数据库软件V4',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'瀚高基础软件股份有限公司',
					CertifiedProduct:'瀚高安全版数据库系统 V4.5',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'瀚高基础软件股份有限公司',
					CertifiedProduct:'瀚高企业版数据库系统（简称“HGDB-EE”） V6.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'瀚高基础软件股份有限公司',
					CertifiedProduct:'瀚高数据库管理系统 V9.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'星环信息科技（上海）股份有限公司',
					CertifiedProduct:'星环分布式分析型数据库 ArgoDB',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'星环信息科技（上海）股份有限公司',
					CertifiedProduct:'分布式在线交易数据库[简称：Transwarp KunDB]',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},

				{
					category:'数据库',
					CertifiedManufacturer:'武汉达梦数据库股份有限公司',
					CertifiedProduct:'达梦数据库管理系统V8（简称DM8）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'浪潮云信息技术股份公司',
					CertifiedProduct:'浪潮云岳数据库管理系统 InDB V3.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京东方通科技股份有限公司',
					CertifiedProduct:'东方通应用服务器软件TongWeb V7.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京东方通科技股份有限公司',
					CertifiedProduct:'东方通应用服务器软件TongWeb V8.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'北京华宇信息技术有限公司',
					CertifiedProduct:'TAS应用中间件软件V2.8',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'湖南亚信安慧科技有限公司',
					CertifiedProduct:'AntDB数据库',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'亚信科技（中国）有限公司',
					CertifiedProduct:'AntDB数据库',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'高斯开源社区',
					CertifiedProduct:'opengauss',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创高吞吐消息中间件软件[简称：InforSuite HTMQ]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创高性能消息队列软件[简称：InforSuite HCMQ]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创云原生消息中间件软件[简称：InforSuite Cloud MQ]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创天枢数据治理平台软件[简称：DubheDGP]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创元穹大数据智能分析平台软件[简称：DataDome]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创元穹数据集成平台软件[简称：InforSuite DI]V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创业务信息和电子文件交换系统V3',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东众量信息科技有限公司',
					CertifiedProduct:'众量应用服务器软件V3.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创企业服务总线软件V9.1',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'山东中创软件商用中间件股份有限公司',
					CertifiedProduct:'中创应用集成服务平台软件软件V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元流程集成平台 V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件V6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件V8',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元ESB软件V9',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元大文件传输平台软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元负载均衡软件V6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元开发运维一体化平台软件V6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元数据缓存软件V6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元数据开发平台软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元数据质量管理平台软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元数据资产管理平台软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元消息中间件软件V6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元消息中间件软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元应用服务器软件V6.5',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元低代码开发平台V7.6',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元低代码开发平台V8',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元元数据管理软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'普元信息技术股份有限公司',
					CertifiedProduct:'普元主数据管理平台软件V7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元EOS低代码平台软件V8.2.3',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元ESB软件V9.2',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元开发运维一体化平台软件V6.3',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元数据开发平台软件V7.2',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元数据质量管理平台软件V7.1',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元数据资产管理平台软件 V7.1',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元信创应用服务器软件V6.5',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元元数据管理软件V7.5',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'中间件',
					CertifiedManufacturer:'上海普元信创软件技术有限公司',
					CertifiedProduct:'普元主数据管理平台软件V7.1',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'数据库',
					CertifiedManufacturer:'上海爱可生信息技术股份有限公司',
					CertifiedProduct:'爱可生云树数据库软件V4',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				}
			]
		},
		{
			title:'安全产品',
			text:[
				{
					category:'主机安全',
					CertifiedManufacturer:'北京江民新科技术有限公司',
					CertifiedProduct:'江民赤豹近卫终端安全防御系统V19',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京江民新科技术有限公司',
					CertifiedProduct:'江民赤豹终端安全与响应系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京江民新科技术有限公司',
					CertifiedProduct:'江民网络版杀毒软件V19',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京江民新科技术有限公司',
					CertifiedProduct:'江民终端安全管理系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京江民新科技术有限公司',
					CertifiedProduct:'江民信创版杀毒软件V2.0',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星恶意代码管理软件V2.0',
					AuthenticationSystem:'V6.0',
					time:'2021年02月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'虚拟化恶意代码防护系统2.0',
					AuthenticationSystem:'V6.0',
					time:'2021年02月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'虚拟化系统安全软件V2.0',
					AuthenticationSystem:'V6.0',
					time:'2021年02月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'华雁智能科技（集团）股份有限公司',
					CertifiedProduct:'华雁NVS-8800智能巡检系统V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'华雁智能科技（集团）股份有限公司',
					CertifiedProduct:'华雁IVIS-10000远程智能巡视集中监控系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京安天网络安全技术有限公司',
					CertifiedProduct:'安天智甲终端防御系统V3.0',
					AuthenticationSystem:'V6.0',
					time:'2019年06月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京威努特技术有限公司',
					CertifiedProduct:'威努特工控主机卫士V3.0',
					AuthenticationSystem:'V6.0',
					time:'2019年11月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'广州海颐信息安全技术有限公司',
					CertifiedProduct:'海颐特权账号安全管理系统V9.7',
					AuthenticationSystem:'V6.0',
					time:'2020年06月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京珞安科技有限责任公司',
					CertifiedProduct:'珞安卫士V2.0',
					AuthenticationSystem:'V6.0',
					time:'2020年05月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京珞安科技有限责任公司',
					CertifiedProduct:'主机安全加固系统V2.0',
					AuthenticationSystem:'V6.0',
					time:'2020年05月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京珞安科技有限责任公司',
					CertifiedProduct:'工业主机安全卫士',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京可信华泰信息技术有限公司',
					CertifiedProduct:'白细胞操作系统免疫平台V3.0',
					AuthenticationSystem:'V6.0',
					time:'2020年07月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京泰腾恩科技有限公司',
					CertifiedProduct:'安克诺斯数据保护软件12.5',
					AuthenticationSystem:'V6.0',
					time:'2021年01月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'四川精容数安科技有限公司',
					CertifiedProduct:'精容数安企业级备份软件V1.5',
					AuthenticationSystem:'V6.0',
					time:'2020年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'主机安全防护系统',
					AuthenticationSystem:'V6.0',
					time:'2021年07月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'工控防火墙软件',
					AuthenticationSystem:'V6.0',
					time:'2021年07月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'工控安全审计平台',
					AuthenticationSystem:'V6.0',
					time:'2021年07月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'主机安全卫士HX-HPS V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'主机安全检测系统HX-HPS V3.0 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京天地和兴科技有限公司',
					CertifiedProduct:'主机安全加固系统HX-HPS V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'浪潮（北京）电子信息产业有限公司',
					CertifiedProduct:'浪潮主机安全增强系统SSR/V5.0',
					AuthenticationSystem:'V6.0',
					time:'2019年09月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'长扬科技(北京)有限公司',
					CertifiedProduct:'长扬工控主机卫士V3',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'天融信科技集团',
					CertifiedProduct:'工控主机卫士系统',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京腾凌科技有限公司',
					CertifiedProduct:'滕凌沃德备份一体机ODB2000',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京亚控科技发展有限公司',
					CertifiedProduct:'KingsuperSCADA工业自动化监控组态软件',
					AuthenticationSystem:'V6.0',
					time:'2020年05月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:' 深圳市蓝凌软件股份有限公司',
					CertifiedProduct:'蓝凌企业知识化平台软件V15.0',
					AuthenticationSystem:'V6.0',
					time:'2020年06月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京安帝科技有限公司',
					CertifiedProduct:'工业统一行为管理平台AD-UBM',
					AuthenticationSystem:'V6.0',
					time:'2020年01月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京安帝科技有限公司',
					CertifiedProduct:'工业安全流量日志分析系统AD-UBA',
					AuthenticationSystem:'V6.0',
					time:'2020年01月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'深圳市软派智能技术有限公司',
					CertifiedProduct:'软派数据中心动力环境监控系统-软云 V5.6',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'深圳市吉斯凯达智慧科技有限公司',
					CertifiedProduct:'GS-X5 通用自动化组态平台 V1.0',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'杭州慧瑞信息技术有限公司',
					CertifiedProduct:'慧瑞内控一体化平台',
					AuthenticationSystem:'V6.0',
					time:'2020年12月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'上海网强软件有限公司',
					CertifiedProduct:'上海网强IT综合管理软件',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'德派软件(北京)有限公司',
					CertifiedProduct:'德派访问专家V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'杭州立思辰安科科技有限公司',
					CertifiedProduct:'立思辰终端防护系统xGuard',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'OceanStor UltraPath多路径软件',
					AuthenticationSystem:'V6.0',
					time:'2021年05月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工业安全隔离交换系统（SZHA-FERRY/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工控运维审计系统（SZHA-YWSJ/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工控数据库审计系统（SZHA-IDBA/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工控入侵检测系统（SZHA-IIDS/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工控漏洞扫描系统（SZHA-IDBA/V1.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工业防火墙（SZHA-ICFW/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工业网络检测审计（SZHA-INMA/V1.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工控主机加固软件（SZHA-BMD/V3.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工业信息安全日志平台（SZHA-JGPT/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京神州慧安科技有限公司',
					CertifiedProduct:'工业安全监测分析管理平台（SZHA-JFYPT/V2.0）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京猎鹰安全科技有限公司（金山安全）',
					CertifiedProduct:' 猎鹰安全恶意代码监控分析系统 ',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京猎鹰安全科技有限公司（金山安全）',
					CertifiedProduct:'金山终端安全系统V9.0<br>毒霸防病毒系统（专用平台网络版）V9.0<br>毒霸防病毒系统V9.0<br>毒霸终端安全管理系统V10<br>猎鹰终端安全管理系统V9.0',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京优炫软件股份有限公司',
					CertifiedProduct:'优炫操作系统安全增强系统（RS-CDPS）',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京正剑有限公司',
					CertifiedProduct:'访问专家V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'广州合明软件科技有限公司',
					CertifiedProduct:'合明监控分析中心软件HMCS V10.0',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'广州合明软件科技有限公司',
					CertifiedProduct:'合明硬件运维管理信创版软件HMAS V1.0',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京山石网科信息技术有限公司',
					CertifiedProduct:'山石网科运维安全网关',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'南京壹进制信息科技有限公司',
					CertifiedProduct:'黑方容灾备份与恢复系统软件 V6.0',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:' 工控主机安全卫士V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'可信模块',
					CertifiedManufacturer:'大唐高鸿信安(浙江)信息科技有限公司',
					CertifiedProduct:'可信支撑模块',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'可信模块',
					CertifiedManufacturer:'大唐高鸿信安(浙江)信息科技有限公司',
					CertifiedProduct:'操作系统可信增强',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'可信模块',
					CertifiedManufacturer:'大唐高鸿信安(浙江)信息科技有限公司',
					CertifiedProduct:'可信代理系统',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'可信模块',
					CertifiedManufacturer:'大唐高鸿信安(浙江)信息科技有限公司',
					CertifiedProduct:'可信管理平台',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京辰光融信技术有限公司',
					CertifiedProduct:'终端防护系统xGuard',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京未来智安科技有限公司 ',
					CertifiedProduct:'未来智安XDR扩展威胁检测响应系统 ',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京盈泽世纪科技发展有限公司 ',
					CertifiedProduct:'CSS3000DCIM设备设施管理软件V1.0',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
				},
				{
					category:'数据安全',
					CertifiedManufacturer:'北京盈泽世纪科技发展有限公司 ',
					CertifiedProduct:'CSS5000数据中心设施管理平台V1.0',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
				},
				{
					category:'安全硬件',
					CertifiedManufacturer:'珠海市鸿瑞信息技术股份有限公司',
					CertifiedProduct:'HR-CRPM -3000型网络安全装置',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'安全硬件',
					CertifiedManufacturer:'北京华电众信技术股份有限公司',
					CertifiedProduct:'LEC-6180',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'安全硬件',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'T8510',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'安全硬件',
					CertifiedManufacturer:'沈阳东软系统集成工程有限公司',
					CertifiedProduct:'东软 NetEye 防火墙 FW5200',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京安帝科技有限公司',
					CertifiedProduct:'工业主机安全卫士V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'深圳融安网络科技有限公司',
					CertifiedProduct:'工业主机安全加固系统产品',
					AuthenticationSystem:'V6.0',
					time:'2023年01月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'江苏云涌电子科技股份有限公司',
					CertifiedProduct:'云涌主机加固软件V3.0',
					AuthenticationSystem:'V6.0',
					time:'2023年01月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'绿盟科技集团股份有限公司',
					CertifiedProduct:'绿盟终端安全管理系统（ESS）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星ESM防病毒终端安全防护系统',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星ESM防病毒系统',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星虚拟化恶意代码防护系统',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},

				{
					category:'主机安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'主机安全防疫卫士V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'主机安全加固系统V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},

				{
					category:'主机安全',
					CertifiedManufacturer:'奇安信网神信息技术(北京)股份有限公司',
					CertifiedProduct:'奇安信网神工业主机安全防护系统V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
				},
				{
					category:'主机安全',
					CertifiedManufacturer:'北京圣博润高新技术股份有限公司',
					CertifiedProduct:'圣博润工控主机安全卫士V3.0',
					AuthenticationSystem:'V6.0',
					time:'2023年08月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒漏洞扫描系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒日志审计分析系统V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒身份认证系统软件V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒数据库安全审计系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'特权账号管理系统V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒下一代防火墙系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒应用访问管理系统JH-UIM V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒运维审计与管理系统JH-OAM V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京建恒信安科技有限公司',
					CertifiedProduct:'建恒准入控制系统JH-SAC V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京惠而特科技有限公司',
					CertifiedProduct:'工控主机卫士系统 V3.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'深圳融安网络科技有限公司',
					CertifiedProduct:'工业终端安全卫士产品',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'杭州瑞晟博科技有限公司',
					CertifiedProduct:'瑞晟博网络端口安全防护管控系统V4.2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星ESM防病毒系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京瑞星网安技术股份有限公司',
					CertifiedProduct:'瑞星终端威胁检测与响应系统（EDR）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京六方云信息技术有限公司',
					CertifiedProduct:'六方云工业主机卫士软件V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'山东云果信息技术有限公司',
					CertifiedProduct:'网络全链路分析系统（NLA）V1.7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'北京北信源软件股份有限公司',
					CertifiedProduct:'北信源内网安全管理系统V8.1',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'网络安全',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'云峦操作系统安全防御软件V2（简称KSecure）',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京升鑫网络科技有限公司',
					CertifiedProduct:'青藤主机自适应安全平台V3.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'深圳市易聆科信息技术股份有限公司',
					CertifiedProduct:'啄木鸟工控漏洞模糊测试系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京火绒网络科技有限公司',
					CertifiedProduct:'火绒终端安全管理系统v2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'杭州中电安科现代科技有限公司',
					CertifiedProduct:'终端安全管理系统xGuard',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'USB安全隔离装置NV-USB/V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'工控安全审计系统 NV-A/V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'安全专用设备',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'工业防火墙 NV-FW/V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'日志审计分析系统 NV-LAS/V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'安全专用设备',
					CertifiedManufacturer:'北京网藤科技有限公司',
					CertifiedProduct:'入侵检测系统 NV-IDS/V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'可信模块',
					CertifiedManufacturer:'大唐高鸿信安（浙江）信息科技有限公司',
					CertifiedProduct:'可信支撑模块软件V2.0、操作系统可信增强系统V2.0、可信管理平台V2.0、可信代理软件V2.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'终端安全',
					CertifiedManufacturer:'宁波和利时信息安全研究院有限公司',
					CertifiedProduct:'HOLLiSec-AGS 终端安全卫士V2',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				}
			]
		},
		{
			title:'云计算',
			text:[
				{
					category:'云平台',
					CertifiedManufacturer:'北京优炫软件股份有限公司',
					CertifiedProduct:'UXCloud 云平台V2.0',
					AuthenticationSystem:'V6.0',
					time:'2018年12月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'希云科技(北京)有限公司',
					CertifiedProduct:'希云 XVP 虚拟化平台 2019.12',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'湖南麒麟信安科技有限公司',
					CertifiedProduct:'麒麟云桌面系统v7、V7.1',
					AuthenticationSystem:'V6.0',
					time:'2019年11月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'深信服科技股份有限公司',
					CertifiedProduct:'深信服信云sCloud',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'CAS5.0',
					AuthenticationSystem:'V6.0',
					time:'2019年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C CloudOS云操作系统软件V5.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C CloudOS云操作系统软件V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UIS超融合管理软件[简称：H3C UIS]V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C Workspace云桌面管理软件V5.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C云计算管理软件[简称：H3C CAS软件]V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'济南浪潮数据技术有限公示',
					CertifiedProduct:'浪潮云海服务器虚拟化系统InCloud SphereV5',
					AuthenticationSystem:'V6.0',
					time:'2018年09月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'中铁信息工程集团有限公司',
					CertifiedProduct:'中铁信弘盛超虚拟化平台V1.0',
					AuthenticationSystem:'V6.0',
					time:'2019年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'安超云软件有限公司',
					CertifiedProduct:'安超OS(通用型云操作系统)',
					AuthenticationSystem:'V6.0',
					time:'2021年08月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'云宏信息科技股份有限公司',
					CertifiedProduct:'CNware WinSphere服务器虚拟化软件',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'云宏信息科技股份有限公司',
					CertifiedProduct:'CNware WinStack虚拟化云平台软件',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'云宏信息科技股份有限公司',
					CertifiedProduct:'WinHCI超融合软件',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'云宏信息科技股份有限公司',
					CertifiedProduct:'WinCloud云管理平台软件',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'云宏信息科技股份有限公司',
					CertifiedProduct:'WinGarden容器云平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'航天云宏技术贵州有限公司',
					CertifiedProduct:'航天云宏服务器虚拟化资源管理高安全版软件V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'航天云宏技术贵州有限公司',
					CertifiedProduct:'航天云宏云服务中心管理高安全软件V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
				},
				{
					category:'超融合一体机',
					CertifiedManufacturer:'北京志凌海纳科技有限公司',
					CertifiedProduct:'SMTX OS 4.0',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'上海酷栈科技有限公司',
					CertifiedProduct:'桌面云平台软件xView',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				
				{
					category:'云平台',
					CertifiedManufacturer:'阿里云计算有限公司',
					CertifiedProduct:'阿里云专有云企业版V3（飞腾架构）',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'阿里云计算有限公司',
					CertifiedProduct:'阿里云专有云企业版V3（集成认证测试）',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'阿里云计算有限公司',
					CertifiedProduct:'阿里云专有云企业版V3（海光架构）',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'阿里云计算有限公司',
					CertifiedProduct:'阿里云专有云企业版V3（鲲鹏架构）',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'紫光云技术有限公司',
					CertifiedProduct:'UniCloud云操作系统[简称：UniCloudOS]V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'紫光云技术有限公司',
					CertifiedProduct:'UniCloud安全桌面云管理软件V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'紫光云技术有限公司',
					CertifiedProduct:'UniCloud超融合安全云操作系统[简称：UniCloudHCI]V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'紫光云技术有限公司',
					CertifiedProduct:'UniCloud虚拟化安全云操作系统[简称：UniCloudUsphere]V7.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中电云数智科技有限公司',
					CertifiedProduct:'中国电子云专属云平台 CECSTACK V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中电云数智科技有限公司',
					CertifiedProduct:'中国电子云云操作系统 CECloud V5.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中电云数智科技有限公司',
					CertifiedProduct:'中国电子云超融合系统(简称：CeaCube) V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云专属云平台 CECSTACK V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云云操作系统 CECloud V5.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云超融合系统(简称：CeaCube) V3.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'优刻得科技股份有限公司公司',
					CertifiedProduct:'UCloudStack私有云平台',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'曙光信息产业（北京）有限公司',
					CertifiedProduct:'Cloudview云计算操作系统V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'宁畅信息产业（北京）有限公司',
					CertifiedProduct:'NCube超融合一体机管理系统V2.1',
					AuthenticationSystem:'V6.0',
					time:'2023年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'曙光信息产业（北京）有限公司',
					CertifiedProduct:'StackCube-K超融合系统V4',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'SumaCube超融合一体机管理系统V4.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中科腾龙信息技术有限公司',
					CertifiedProduct:'中科腾龙TLSCube-A超融合系统V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'深圳爱捷云科技有限公司',
					CertifiedProduct:'爱捷云（EdgeRay）云平台V5.0、V6.0',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京易思捷信息技术有限公司',
					CertifiedProduct:'易思捷云操作系统[简称：Esage Cloud OS]V6.0',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'浙江远算科技有限公司',
					CertifiedProduct:'格物云CAE',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},

				{
					category:'云平台',
					CertifiedManufacturer:'中移（苏州）软件技术有限公司',
					CertifiedProduct:'移动信创云虚拟化云平台（基于鲲鹏）',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中移（苏州）软件技术有限公司',
					CertifiedProduct:'移动信创云虚拟化云平台（基于飞腾）',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中移（苏州）软件技术有限公司',
					CertifiedProduct:'移动信创云虚拟化云平台（基于海光）',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'远光软件股份有限公司',
					CertifiedProduct:'YG-RPA云平台V6.0.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'联想（北京）有限公司',
					CertifiedProduct:'联想私有云系统 V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'广州常青云科技有限公司',
					CertifiedProduct:'常青云产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'广州常青云科技有限公司',
					CertifiedProduct:'常青桌面云产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（鲲鹏）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（海光）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（飞腾）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（鲲鹏）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（海光）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（飞腾）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京青云科技股份有限公司',
					CertifiedProduct:'青云云平台（可信版） [简称： QingCloud Platform] V5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'虚拟化',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C CloudOS3.0 云操作系统',
					AuthenticationSystem:'V6.0',
					time:'2019年12月',
				},

				{
					category:'云平台',
					CertifiedManufacturer:'中电云计算技术有限公司',
					CertifiedProduct:'中电云专属云平台（简称：CECSTACK）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中电云计算技术有限公司',
					CertifiedProduct:'中国电子云虚拟化软件（简称：CeaSphere）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中电云计算技术有限公司',
					CertifiedProduct:'中国电子云超融合系统（简称：CeaCube）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中电云专属云平台（简称：CECSTACK）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云虚拟化软件（简称：CeaSphere）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云超融合系统（简称：CeaCube）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'上海云轴信息科技有限公司',
					CertifiedProduct:'ZStack Cloud云平台V4.8',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京联想超融合科技有限公司',
					CertifiedProduct:'AIO D1000桌面云管理系统 V5.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京联想超融合科技有限公司',
					CertifiedProduct:'联想超融合H1000软件V5.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京联想超融合科技有限公司',
					CertifiedProduct:'凌云D9000桌面云管理系统V5.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'北京联想超融合科技有限公司',
					CertifiedProduct:'凌云H9000基础云管理系统V5.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},

				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云虚拟化云平台（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云服务器虚拟化系统（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云云管理平台软件（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云云管理平台软件（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云云管理平台软件（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云电脑（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云电脑（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云电脑（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云TeleDB数据库软件（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云TeleDB数据库软件（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云TeleDB数据库软件（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云容器云平台（鲲鹏）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云容器云平台（海光）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'天翼云容器云平台（飞腾）',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'济南浪潮数据技术有限公司',
					CertifiedProduct:'浪潮云海云操作系统（InCloud OS）V6/V8',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'济南浪潮数据技术有限公司',
					CertifiedProduct:'浪潮云海服务器虚拟化系统（InCloud Sphere）V6/V8',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'云平台',
					CertifiedManufacturer:'上海云轴信息科技有限公司',
					CertifiedProduct:'ZStack Cloud 云平台V4.8',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				}
			]
		},
		{
			title:'应用软件',
			text:[
				{
					category:'输入法',
					CertifiedManufacturer:'北京搜狗科技发展有限公司',
					CertifiedProduct:'搜狗输入法 Linux 版本 V2.0',
					AuthenticationSystem:'V6.0',
					time:'2020年12月',
				},
				{
					category:'多媒体软件',
					CertifiedManufacturer:'苏州思必驰信息科技有限公司',
					CertifiedProduct:'思必驰智能语音平台 V1.0 ',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'广州中望龙腾软件股份有限公司',
					CertifiedProduct:'中望CAD平台软件（For Linux）V2022',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'广州中望龙腾软件股份有限公司',
					CertifiedProduct:'中望建筑CAD设计软件（For Linux）V2022',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'广州中望龙腾软件股份有限公司',
					CertifiedProduct:'中望机械CAD设计软件（For Linux）V2022',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'文字处理软件',
					CertifiedManufacturer:'珠海金山办公软件有限公司',
					CertifiedProduct:'WPS Office 2019 for  Linux专业版办公软件V1.1',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州杭途科技有限公司',
					CertifiedProduct:'iHT-AUX-PE-PED-ZK动环监控终端',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州杭途科技有限公司',
					CertifiedProduct:'iHT-AUX-SD-SD-ZK安防监控终端',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京直真科技股份有限公司',
					CertifiedProduct:'直真数据采集和共享平台',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京直真科技股份有限公司',
					CertifiedProduct:'直真政企运维工作台',
					AuthenticationSystem:'V6.0',
					time:'2022年06月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'北京数安行科技有限公司',
					CertifiedProduct:'数安行零信任数据运营安全平台V5.0',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'上海远景科创智能科技有限公司',
					CertifiedProduct:'保信主站监控系统、故障录波主站系统',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'业务系统',
					CertifiedManufacturer:'上海泛微网络科技股份有限公司',
					CertifiedProduct:'泛微协同商务软件[简称：e-cology]V9.0',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'业务系统',
					CertifiedManufacturer:'上海泛微软件有限公司',
					CertifiedProduct:'泛微协同商务软件[简称：e-cology]V9.1',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'深圳市前海伟创科技有限公司',
					CertifiedProduct:'汇流箱测控单元软件',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'深圳市前海伟创科技有限公司',
					CertifiedProduct:'光伏电站运营管理系统',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'深圳市连用科技有限公司',
					CertifiedProduct:'连用文件云系统V5',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'运维软件',
					CertifiedManufacturer:'北京基调网络股份有限公司',
					CertifiedProduct:'基调听云企业版平台V3.6',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'上海天玑科技股份有限公司',
					CertifiedProduct:'PhegData-X分布式存储',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'上海天玑数据技术有限公司',
					CertifiedProduct:'PhegData-X分布式存储',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'中讯邮电咨询设计院',
					CertifiedProduct:'中讯5G小基站管理系统',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'成都云祺科技有限公司',
					CertifiedProduct:'云祺容灾备份系统【简称：Vinchin DR】V6.0',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'上海远景科创智能科技有限公司',
					CertifiedProduct:'保信主站监控系统、故障录波主站系统',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京仁和昌盛技术开发有限公司',
					CertifiedProduct:'早发达语音识别引擎系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中国船舶重工集团海装风电股份有限公司',
					CertifiedProduct:'中国海装风电场中央监控系统（简称HZ-SCADA）V5.0',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中国船舶重工集团海装风电股份有限公司',
					CertifiedProduct:'中国海装风电场能量管理系统（简称DC-EMS）V1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'弘正储能（南京）数字技术有限公司',
					CertifiedProduct:'智慧能碳管控平台',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'弘正储能（南京）数字技术有限公司',
					CertifiedProduct:'iEMS-EDGE-EMESS',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京天华星航科技有限公司',
					CertifiedProduct:'THE VRTS备份软件V5.0',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京华夏威科软件技术有限公司',
					CertifiedProduct:'SinoUEBA用户实体行为分析平台V6.0',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'同方知网数字出版技术股份有限公司',
					CertifiedProduct:'KBase全文搜索引擎V12.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'四川启睿克科技有限公司',
					CertifiedProduct:'仓储管理系统（WMS）V1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'四川启睿克科技有限公司',
					CertifiedProduct:'制造执行系统（iMES）V2.0',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'四川启睿克科技有限公司',
					CertifiedProduct:'质量管理系统（QMS）V1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'成都中科合迅科技有限公司',
					CertifiedProduct:'合迅智灵一体化智能开发平台V4.0',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'深圳市云屋科技有限公司',
					CertifiedProduct:'视频会议SDK',
					AuthenticationSystem:'V6.0',
					time:'2023年05月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'北京京天威科技发展有限公司',
					CertifiedProduct:'Miracle软件开发平台V6',
					AuthenticationSystem:'V6.0',
					time:'2023年05月',
				},

				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科智能监管数字平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科衍生品定价分析平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科套期保值业务决策管理平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'北京百度网讯科技有限公司',
					CertifiedProduct:'百度智能云曦灵-智能数字人平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'合肥思创医疗信息技术有限公司',
					CertifiedProduct:'智慧卫监综合监管平台1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'合肥思创医疗信息技术有限公司',
					CertifiedProduct:'云巡诊车系统3.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'合肥思创医疗信息技术有限公司',
					CertifiedProduct:'中小学生智能物联体检数据平台1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'安徽壹医云技术有限公司',
					CertifiedProduct:'智慧卫监综合监管平台1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'安徽壹医云技术有限公司',
					CertifiedProduct:'云巡诊车系统3.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'安徽壹医云技术有限公司',
					CertifiedProduct:'中小学生智能物联体检数据平台1.0',
					AuthenticationSystem:'V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'信创桥（成都）科技有限公司',
					CertifiedProduct:'软通桥信创应用兼容适配系统V4',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'天津凯发电气股份有限公司',
					CertifiedProduct:'KF6510智能通信网关机软件',
					AuthenticationSystem:'V6.0',
					time:'2023年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'天津凯发电气股份有限公司',
					CertifiedProduct:'KF6510智能通信网关机系列产品',
					AuthenticationSystem:'V6.0',
					time:'2023年08月',
				},
				{
					category:'其他',
					CertifiedManufacturer:'上海涛略信息技术有限公司',
					CertifiedProduct:'搜狗输入法4.2.1.270',
					AuthenticationSystem:'V6.0',
					time:'2023年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科热备（北京）云计算技术有限公司',
					CertifiedProduct:'亢龙数据备份与恢复系统V8.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'山东源鲁信息科技有限公司',
					CertifiedProduct:'内外网文件交换系统V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'积成电子股份有限公司',
					CertifiedProduct:'新一代变电站集中监控系统（iES-MAX）',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'浙江齐安信息科技有限公司',
					CertifiedProduct:'检修作业安全运维系统',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'浙江齐安信息科技有限公司',
					CertifiedProduct:'便携式运维网关',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'苏州浩辰软件股份有限公司',
					CertifiedProduct:'浩辰CAD平台软件（Linux版）V2023',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'东方旭能（山东）科技发展有限公司',
					CertifiedProduct:'东方旭能储能智慧能量管理系统',
					AuthenticationSystem:'V6.0',
					time:'2023年11月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'天津市普迅电力信息技术有限公司',
					CertifiedProduct:'分布式新能源协同调控管理平台',
					AuthenticationSystem:'V6.0',
					time:'2023年11月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'成都思瑞奇信息产业有限公司',
					CertifiedProduct:'统一查控管理平台（信创）',
					AuthenticationSystem:'V6.0',
					time:'2023年11月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'陕西云长信息科技有限公司',
					CertifiedProduct:'云长精益运维管理系统V5.0',
					AuthenticationSystem:'V6.0',
					time:'2023年11月',
				},

				{
					category:'行业应用',
					CertifiedManufacturer:'北京科东电力控制系统有限责任公司',
					CertifiedProduct:'电力专用横向安全隔离装置',
					AuthenticationSystem:'V6.0',
					time:'2023年12月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京科东电力控制系统有限责任公司',
					CertifiedProduct:'电力专用纵向加密认证装置',
					AuthenticationSystem:'V6.0',
					time:'2023年12月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'紫金诚征信有限公司',
					CertifiedProduct:'猎鹰智能决策引擎系统',
					AuthenticationSystem:'V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'航天壹进制（江苏）信息科技有限公司',
					CertifiedProduct:'黑方容灾备份与恢复系统V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'南京云信达科技有限公司',
					CertifiedProduct:'云信达eCloud Data Master 云数据管理系统软件V4',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'南京云信达科技有限公司',
					CertifiedProduct:'云信达eCloud Data Backup企业数据备份与恢复系统软件V4',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'苍穹数码技术股份有限公司',
					CertifiedProduct:'苍穹地理信息平台(KQGIS) V8.5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'安腾普（北京）科技有限公司',
					CertifiedProduct:'安腾普ADA数据归档系统软件V4.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'安腾普（北京）科技有限公司',
					CertifiedProduct:'安腾普Tina备份系统软件V4.6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'广州鼎甲计算机科技有限公司',
					CertifiedProduct:'鼎甲数据备份与恢复系统V8.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'上海英方软件股份有限公司',
					CertifiedProduct:'英方备份管理软件[简称：i2Backup]V7.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'上海英方软件股份有限公司',
					CertifiedProduct:'英方不间断数据保护与恢复软件[简称：i2CDP]V7.1',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'中电云计算技术有限公司',
					CertifiedProduct:'中电云灾备一体机CeaBackup',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中电云灾备一体机CeaBackup',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州实在智能科技有限公司',
					CertifiedProduct:'实在RPA设计器软件V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州实在智能科技有限公司',
					CertifiedProduct:'实在RPA-机器人软件V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州实在智能科技有限公司',
					CertifiedProduct:'实在RPA-控制台系统V3.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州实在智能科技有限公司',
					CertifiedProduct:'实在智能RPA软件V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'杭州实在智能科技有限公司',
					CertifiedProduct:'实在智能软件系统V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'烟台东方威智电子科技有限公司',
					CertifiedProduct:'供水调度监控平台',
					AuthenticationSystem:'V6.0',
					time:'2024年02月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'南京奥拓电子科技有限公司',
					CertifiedProduct:'智能播控系统V4.0产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
				},

				{
					category:'备份软件',
					CertifiedManufacturer:'安腾普（北京）科技有限公司',
					CertifiedProduct:'安腾普Tina数据备份系统软件V4.7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'备份软件',
					CertifiedManufacturer:'安腾普（北京）科技有限公司',
					CertifiedProduct:'安腾普Miria海量数据归档系统软件V4.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科弘云科技（北京）有限公司',
					CertifiedProduct:'中科弘云深度计算服务平台V5.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年04月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'神州灵云（北京）科技有限公司',
					CertifiedProduct:'神州灵云网络流量分析审计系统',
					AuthenticationSystem:'V6.0',
					time:'2024年04月',
				},

				{
					category:'行业应用',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'中兴通讯ZXSEM智简运维工具',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科智能监管数字平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年04月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科套期保值业务决策管理平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'盛宝金融科技有限公司',
					CertifiedProduct:'盛宝金科衍生品定价分析平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'威步信息系统（上海）有限公司',
					CertifiedProduct:'CodeMeter系列产品',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},

				{
					category:'行业应用',
					CertifiedManufacturer:'四川英得赛克科技有限公司',
					CertifiedProduct:'电力监控系统网络感知分析装置软件[简称：网络感知分析装置软件]V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'四川英得赛克科技有限公司',
					CertifiedProduct:'电力监控系统网络感知分析平台软件【简称：网络感知分析平台】V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'国电联合动力有限公司北京技术开发分公司新能源事业部',
					CertifiedProduct:'EMS（电力系统的能量管理软件）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'九科信息技术（深圳）有限公司',
					CertifiedProduct:'bit-Worker RPA智能自动化平台V7.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京中泰华电科技有限公司',
					CertifiedProduct:'NseaScada一体化实时监控平台软件 V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京中泰华电科技有限公司',
					CertifiedProduct:'NseaScada一体化实时监控平台软件 V2.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京中泰华电科技有限公司',
					CertifiedProduct:'NseaDB羲和工业实时数据库 V1.0',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年05月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'自然语言处理组件V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'视频处理组件V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'多源大数据分析管理平台V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'互联网内容智能识别模型V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'网信研判指挥系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'网信指令传输系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'网信属地治理系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'网信网评协同系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'中科软科技股份有限公司',
					CertifiedProduct:'网信机器网评系统V1.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'大数据',
					CertifiedManufacturer:'星环信息科技（上海）股份有限公司',
					CertifiedProduct:'企业级大数据平台Transwarp Data Hub（简称：TDH)',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},

				{
					category:'备份软件',
					CertifiedManufacturer:'成都云祺科技有限公司',
					CertifiedProduct:'云祺容灾备份系统V6.0',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京慧点科技有限公司',
					CertifiedProduct:'WE.Office协同办公管理平台V8.0软件',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京慧点科技有限公司',
					CertifiedProduct:'数字监督平台软件',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京慧点科技有限公司',
					CertifiedProduct:'WEP数字化应用云平台V8.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京慧点科技有限公司',
					CertifiedProduct:'WE.Office 协同办公管理平台 v9.0',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'浏览器',
					CertifiedManufacturer:'天津赢达信科技有限公司',
					CertifiedProduct:'赢达信安全浏览器V4.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'天翼云科技有限公司',
					CertifiedProduct:'区域算力互联网平台（息壤）-基于海光',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'天地（常州）自动化股份有限公司',
					CertifiedProduct:'KJ36A型煤矿电力监控系统',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'苏州美名软件有限公司',
					CertifiedProduct:'WAGO SCADA',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'珠海优特电力科技股份有限公司',
					CertifiedProduct:'UT-F600D发电企业现场作业安全管控系统V3.0',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'行业应用',
					CertifiedManufacturer:'北京菲力克技术有限公司',
					CertifiedProduct:'智能巡检平台软件V3.22，主运输系统智能化、辅助运输系统智能化、猴车智能化、井筒智能监控系统、皮带巡检机器人、变电所巡检机器人、猴车巡检机器人、固定式巡检机器人。',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				}
			]
		},
		//硬件兼容列表
		{
			title:'整机',
			text:[
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'8100 V5',
					AuthenticationSystem:'V6.0',
					time:'2018年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH5288 V5',
					AuthenticationSystem:'V6.0',
					time:'2018年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH1288H V5',
					AuthenticationSystem:'V6.0',
					time:'2018年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2488 V5',
					AuthenticationSystem:'V6.0',
					time:'2019年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2288',
					AuthenticationSystem:'V6.0',
					time:'2017年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2488 V2',
					AuthenticationSystem:'V6.0',
					time:'2019年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH5885 V3',
					AuthenticationSystem:'V6.0',
					time:'2016年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2285',
					AuthenticationSystem:'V6.0',
					time:'2017年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'2488H V5',
					AuthenticationSystem:'V6.0',
					time:'2018年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH8100 V3',
					AuthenticationSystem:'V6.0',
					time:'2019年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'H58M-03',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH5885',
					AuthenticationSystem:'V6.0',
					time:'2016年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'9008 V5',
					AuthenticationSystem:'V6.0',
					time:'2020年01月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'CH121 V3',
					AuthenticationSystem:'V6.0',
					time:'2019年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'2488 V5',
					AuthenticationSystem:'V6.0',
					time:'2018年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'2288X V5',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'2288 V5',
					AuthenticationSystem:'V6.0',
					time:'2021年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2288H V3',
					AuthenticationSystem:'V6.0',
					time:'2018年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2288 V3',
					AuthenticationSystem:'V6.0',
					time:'2017年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'BC62MBHA0',
					AuthenticationSystem:'V6.0',
					time:'2019年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'BC61BLCA',
					AuthenticationSystem:'V6.0',
					time:'2019年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'212B',
					AuthenticationSystem:'V6.0',
					time:'2019年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'XH321 V5',
					AuthenticationSystem:'V6.0',
					time:'2021年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'CH121 V5',
					AuthenticationSystem:'V6.0',
					time:'2020年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'Tecal RH2485 V2',
					AuthenticationSystem:'V6.0',
					time:'2017年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2485 V2',
					AuthenticationSystem:'V6.0',
					time:'2017年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'Tecal RH2285',
					AuthenticationSystem:'V6.0',
					time:'2017年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2285H V2-12T',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'RH2285H V2-8S',
					AuthenticationSystem:'V6.0',
					time:'2020年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'Atlas 500 Pro 智能边缘服务器 （型号：3000）',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CZ300Z',
					AuthenticationSystem:'V6.0',
					time:'2020年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CS5260F',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CS5260H',
					AuthenticationSystem:'V6.0',
					time:'2020年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CS5280H',
					AuthenticationSystem:'V6.0',
					time:'2020年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NE3120M5',
					AuthenticationSystem:'V6.0',
					time:'2020年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF8480M6',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF8260M6',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF5270M6',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF5280M6',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NP3020-M7-A0-R0-00',
					AuthenticationSystem:'V6.0',
					time:'2022年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'浪潮英信服务器NE5260H6',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF5280M6,NF5270M6,NF8260M6和NF8480M6服务器产品',
					AuthenticationSystem:'V6.0',
					time:'2023年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CS5260L2/CS5466L2',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'CS5280Z2/CS5466Z2',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF5270-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF5280-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF8260-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'浪潮计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS5280K2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS8468K2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年04月',
					remark:'',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF5270-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF5280-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'元脑服务器NF8260-M7-A0-R0-00',
					AuthenticationSystem:'V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF5280M4',
					AuthenticationSystem:'V6.0',
					time:'2018年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'NF5280M5',
					AuthenticationSystem:'V6.0',
					time:'2019年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H620-G30',
					AuthenticationSystem:'V6.0',
					time:'2019年02月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'I420-G30',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'A420-G30',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'A620-G30',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H610',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H540',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H520',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H320',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H210',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X740',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X7340',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X745',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X7440',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X785',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'X7840',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'R6240',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'R6140',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'R3240',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'R5240',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'R2140',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W330',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W550',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W3330',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W3335',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W3340',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W3345',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'工作站W5540',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'A620r-G',
					AuthenticationSystem:'V6.0',
					time:'2017年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'I620-G20',
					AuthenticationSystem:'V6.0',
					time:'2018年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'W560-G20',
					AuthenticationSystem:'V6.0',
					time:'2019年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'H8QG7-LN4F',
					AuthenticationSystem:'V6.0',
					time:'2019年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业股份有限公司 ',
					CertifiedProduct:'I840-G30',
					AuthenticationSystem:'V6.0',
					time:'2018年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业（北京）有限公司',
					CertifiedProduct:'Sugon A840-G10',
					AuthenticationSystem:'V6.0',
					time:'2018年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业（北京）有限公司',
					CertifiedProduct:'A840-G10',
					AuthenticationSystem:'V6.0',
					time:'2016年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光信息产业（北京）有限公司',
					CertifiedProduct:'曙光H620,H520,H420系列服务器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'R6320HA',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'R6327HA',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'X73系列、X74系列、X78系列异构加速服务器',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'H320-G30',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W330-H35',
					AuthenticationSystem:'V6.0',
					time:'2019年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'H540-G30',
					AuthenticationSystem:'V6.0',
					time:'2020年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W3330H0',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'H210-G30',
					AuthenticationSystem:'V6.0',
					time:'2019年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W330-C35',
					AuthenticationSystem:'V6.0',
					time:'2021年01月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'H620-G30',
					AuthenticationSystem:'V6.0',
					time:'2020年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'H520-G30',
					AuthenticationSystem:'V6.0',
					time:'2021年01月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W330-C30',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W550-H30',
					AuthenticationSystem:'V6.0',
					time:'2019年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'A620-G30',
					AuthenticationSystem:'V6.0',
					time:'2018年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W330-H30',
					AuthenticationSystem:'V6.0',
					time:'2019年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'W3335H0',
					AuthenticationSystem:'V6.0',
					time:'2021年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'中科可控云终端 M3230H0',
					AuthenticationSystem:'V6.0',
					time:'2022年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'服务器及工作站产品 R6240H0、R5240H0、R3240H0、<br>W550-H30、W3335HA1 、W3335H0、W3330H0、<br>X7340H0、 X7840H0 、H540-G30、H210-G30、<br>R2140H0、R6440H0、R6240A0、X7340A0、TC4600',
					AuthenticationSystem:'V6.0',
					time:'2022年11月',
					remark:'',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'天阔W40/天阔W40P',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem SR658H',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem SR558H',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem SR658Z',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem SR358F V2',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P318',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P320',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P328',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P330',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P340',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P350',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P520c',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P520',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P720',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站ThinkStation P920',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'工作站P620',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
					remark:'',
				},
				
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'M70f G1s',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'M70z G1s',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'M90h G1t',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'M90h G1s',
					AuthenticationSystem:'V6.0',
					time:'2022年09月',
					remark:'',
				},
				
				
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'SR850',
					AuthenticationSystem:'V6.0',
					time:'2018年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'RD640',
					AuthenticationSystem:'V6.0',
					time:'2019年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'P300',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'P500',
					AuthenticationSystem:'V6.0',
					time:'2019年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'p510',
					AuthenticationSystem:'V6.0',
					time:'2016年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'D20',
					AuthenticationSystem:'V6.0',
					time:'2017年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'P700',
					AuthenticationSystem:'V6.0',
					time:'2016年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'天逸510pro-181cb',
					AuthenticationSystem:'V6.0',
					time:'2019年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'C30',
					AuthenticationSystem:'V6.0',
					time:'2017年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'C20',
					AuthenticationSystem:'V6.0',
					time:'2018年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'RQ940',
					AuthenticationSystem:'V6.0',
					time:'2017年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'P710',
					AuthenticationSystem:'V6.0',
					time:'2017年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'P410',
					AuthenticationSystem:'V6.0',
					time:'2017年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem ST250 ',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'RD450X',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'B8DT6',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'Lenovo ThinkServer RD630',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'Lenovo WQ R510 G7',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem ST258 ',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkSystem SR850 ',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkServer RQ940',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkServer RS260',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkServer RD450',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkServer RD650',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkServer TS250',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'D30',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkStation P350',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkStation C20X',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'ThinkStation P310',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'QiTianM420-D536',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'QiTianM420-D355',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'联想集团有限公司',
					CertifiedProduct:'QiTianM420-D586',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'R2700 G3',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'R4700 G3',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'R390X G2',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'B5700G3',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'B5800G3',
					AuthenticationSystem:'V6.0',
					time:'2019年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'B7800G3',
					AuthenticationSystem:'V6.0',
					time:'2019年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4930 G3',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4930 G5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C R6900 G5服务器',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4330 G5',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4960 G5',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'R4960 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4300 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4700 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R6900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4360 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R2960 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4960 G5',
					AuthenticationSystem:'V6.0',
					time:'2023年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'R4960 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4300 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4700 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R6900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4360 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R2960 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'R6800 G2',
					AuthenticationSystem:'V6.0',
					time:'2018年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'FlexServer B390',
					AuthenticationSystem:'V6.0',
					time:'2016年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'RS63BP4U',
					AuthenticationSystem:'V6.0',
					time:'2017年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'UniServer R4950 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'UniServer R4900 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UIS-Cell 6000 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UIS 5000 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'UniServer R2900 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R6700 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R6900 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'UIS R390X G2',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'UIS-Cell 3010 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'B7800 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'NaviData 5200 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'NaviData 5900 G3',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'ZXCLOUD R5300',
					AuthenticationSystem:'V6.0',
					time:'2019年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'ZXCLOUD R5300',
					AuthenticationSystem:'V6.0',
					time:'2019年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5530 G2',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5930 G2',
					AuthenticationSystem:'V6.0',
					time:'2022年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5300 G4',
					AuthenticationSystem:'V6.0',
					time:'2019年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5930 G2',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R8500 G4',
					AuthenticationSystem:'V6.0',
					time:'2020年07月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'烽火通信科技股份有限公司',
					CertifiedProduct:'R2400',
					AuthenticationSystem:'V6.0',
					time:'2021年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'烽火通信科技股份有限公司',
					CertifiedProduct:'R2200',
					AuthenticationSystem:'V6.0',
					time:'2021年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'烽火通信科技股份有限公司',
					CertifiedProduct:'烽火通信系列服务器（鲲鹏920）',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中国长城科技集团股份有限公司',
					CertifiedProduct:'长城擎天DF720',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中国长城科技集团股份有限公司',
					CertifiedProduct:'长城擎天EF860',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中国长城科技集团股份有限公司',
					CertifiedProduct:'长城擎天DF723产品',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中国长城科技集团股份有限公司',
					CertifiedProduct:'长城世恒TD120A2产品',
					AuthenticationSystem:'V6.0',
					time:'2023年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'FP5290G2',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'FP5180G2',
					AuthenticationSystem:'V6.0',
					time:'2019年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'FP5466G2',
					AuthenticationSystem:'V6.0',
					time:'2020年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'FP5468G2',
					AuthenticationSystem:'V6.0',
					time:'2020年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'FP5280G2',
					AuthenticationSystem:'V6.0',
					time:'2020年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'CP5280G2',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'CP5466G2',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮商用机器有限公司',
					CertifiedProduct:'CP8480G2',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'PR4840R',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'PR4510G',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'工作站PT620K',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'武汉长江计算科技有限公司',
					CertifiedProduct:'长江计算系列服务器（鲲鹏 920）',
					AuthenticationSystem:'V6.0',
					time:'2020年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'同方股份有限公司',
					CertifiedProduct:'超强 K620',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'同方股份有限公司',
					CertifiedProduct:'超强 K628',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'同方股份有限公司',
					CertifiedProduct:'超强 K640',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'同方股份有限公司',
					CertifiedProduct:'超强 K820',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'深圳市黄河数字技术有限公司',
					CertifiedProduct:'huanghe 2280 V2',
					AuthenticationSystem:'V6.0',
					time:'2020年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'深圳市黄河数字技术有限公司',
					CertifiedProduct:'huanghe 2480 V2',
					AuthenticationSystem:'V6.0',
					time:'2020年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'广州五舟科技股份有限公司',
					CertifiedProduct:'S527F4飞腾服务器',
					AuthenticationSystem:'V6.0',
					time:'2021年09月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中电超云（南京）科技有限公司',
					CertifiedProduct:'R5210 G11',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中电超云（南京）科技有限公司',
					CertifiedProduct:'R7210 G11',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'FusionServer机架服务器',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'1288H V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'1288H V6',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'2288H V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'2288H V6',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'2488  V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'2488H V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'2488H V6',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'5288 V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'5288 V6',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'5885H V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'9008 V5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'超聚变数字技术有限公司',
					CertifiedProduct:'G5500',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'合芯科技有限公司',
					CertifiedProduct:'HP-C1000',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'R3830 G3',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'UNIS Server R3830 G5',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'UNIS Server R3630 G5',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
					remark:'',
				},
				{
					category:'笔记本',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'微型计算机UNIS D3830 G2',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
					remark:'',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'UNIS D3830 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'笔记本',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'UNIS L3813 G2笔记本',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4930 G5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4330 G5',
					AuthenticationSystem:'V6.0',
					time:'2022年05月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4960 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年03月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4300 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4700 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R6900 G5',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4360 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R2960 G3',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'北京计算机技术及应用研究所',
					CertifiedProduct:'SR119220',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'北京计算机技术及应用研究所',
					CertifiedProduct:'SR125220',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},

				{
					category:'桌面终端',
					CertifiedManufacturer:'浪潮集团有限公司',
					CertifiedProduct:'便携式计算机CP300Z',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
					remark:'',
				},
				{
					category:'桌面终端',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'自强台式机',
					AuthenticationSystem:'V6.0',
					time:'2020年07月',
					remark:'',
				},
				{
					category:'桌面终端',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'PR205KI系列服务器',
					AuthenticationSystem:'V6.0',
					time:'2022年11月',
					remark:'',
				},
				{
					category:'桌面终端',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强PR21072H',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'海光7200系列处理器',
				},
				{
					category:'桌面终端',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强PT620Z1',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'兆芯开先KX-6000系列',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强PR210K',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
					remark:'基于鲲鹏920处理器，均衡性，2U2路机架服务器',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强PR210K',
					AuthenticationSystem:'龙蜥版V6.0',
					time:'2021年12月',
					remark:'基于鲲鹏920处理器，均衡性，2U2路机架服务器',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强系列服务器PR410K',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年01月',
					remark:'基于国产鲲鹏920处理器的4U双路机架服务器系列',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'PT620Q',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'桌面终端',
					CertifiedManufacturer:'北京计算机技术及应用研究所',
					CertifiedProduct:'TR11A2',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
					remark:'',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'上海瑞强信息科技有限公司',
					CertifiedProduct:'工控机 NPC-SMU',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'上海瑞强信息科技有限公司',
					CertifiedProduct:'工控机 NPC-800',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'北京中科腾越科技发展有限公司',
					CertifiedProduct:'中科腾越记录仪及录波器TR(E/A/D)',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'北京中科腾越科技发展有限公司',
					CertifiedProduct:'中科腾越工控机TPE系列',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'北京中科腾越科技发展有限公司',
					CertifiedProduct:'中科腾越工控机TWN系列',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'工控类',
					CertifiedManufacturer:'北京中科腾越科技发展有限公司',
					CertifiedProduct:'中科腾越工控机T5DB系列',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'摩莎科技（上海）有限公司',
					CertifiedProduct:'嵌入式计算机 DA-682B-TCC Series',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'摩莎科技（上海）有限公司',
					CertifiedProduct:'嵌入式计算机 DA-682B-PMU Series ',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'深圳市中网信安技术有限公司',
					CertifiedProduct:'数据通信网关机 EDG-FT322',
					AuthenticationSystem:'V6.0',
					time:'2021年12月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'南京卓威研信息技术有限公司',
					CertifiedProduct:'嵌入式网关机 PEM-7000',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'江苏云涌电子科技股份有限公司',
					CertifiedProduct:'移动堡垒机YY0013',
					AuthenticationSystem:'V6.0、V1.0',
					time:'2021年07月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'同泰怡信息技术(福建)有限公司',
					CertifiedProduct:'飞腾S5000C系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年05月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中诚华隆计算机技术有限公司',
					CertifiedProduct:'昆吾服务器（KW2021000-SW3231）',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'工控机',
					CertifiedManufacturer:'福升威尔（河北）智能控制技术有限公司',
					CertifiedProduct:'WIPC-7461系列产品',
					AuthenticationSystem:'V6.0',
					time:'2024年05月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'R52系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'R62系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中科可控信息产业有限公司',
					CertifiedProduct:'R64系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'工控机',
					CertifiedManufacturer:'北京智达数通科技有限公司',
					CertifiedProduct:'BOS8000无风扇工业计算机/通讯管理机/数据网关机',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4970 G7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三信息技术有限公司',
					CertifiedProduct:'H3C UniServer R4970 G7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光华山科技有限公司',
					CertifiedProduct:'H3C UniServer R4970 G7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'UNIS Server R4970 G7',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'河南昆仑技术有限公司',
					CertifiedProduct:'KunLun 2280\\2280-VF\\2480\\5280\\5280-VF\\E2180\\G2280\\G5500\\G5680 V2',
					AuthenticationSystem:'V6.0',
					time:'2024年06月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS5466K2',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS8260K2',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'工控机',
					CertifiedManufacturer:'北京研华兴业电子科技有限公司',
					CertifiedProduct:'ECU-461',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'工控机',
					CertifiedManufacturer:'南方电网数字平台科技（广东）有限公司',
					CertifiedProduct:'青舟近边缘一体机/G100',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'工控机',
					CertifiedManufacturer:'北京研华兴业电子科技有限公司 ',
					CertifiedProduct:'ECU-469',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'广州广电五舟科技股份有限公司',
					CertifiedProduct:'广电五舟S627K2服务器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年07月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'广州广电五舟科技股份有限公司',
					CertifiedProduct:'广电五舟S500K2服务器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年07月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强鲲鹏服务器PR220K',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'宝德计算机系统股份有限公司',
					CertifiedProduct:'宝德自强鲲鹏服务器PR410K',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'江苏云涌电子科技股份有限公司',
					CertifiedProduct:'YY0013、YY0095、YY0106、YY0110、YY0114',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R6930 G7服务器',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4330 G7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R4930 G7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniServer R5330 G7',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想（北京）有限公司',
					CertifiedProduct:'P系列工作站 （P360/P520c/P520/P720/P920/P2/P3/P5/P7/P8/PX）',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'浙江齐安信息科技有限公司',
					CertifiedProduct:'移动堡垒机GMOM-3000',
					AuthenticationSystem:'V1.0',
					time:'2022年03月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'北京北变智达科技有限公司',
					CertifiedProduct:'PSX-681G',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'天固信息安全系统(深圳)有限公司',
					CertifiedProduct:'D211F',
					AuthenticationSystem:'V6.0',
					time:'2021年03月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'杭州杭途科技有限公司',
					CertifiedProduct:'HT818',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'山东超越信息科技有限公司',
					CertifiedProduct:'移动堡垒机TB3200',
					AuthenticationSystem:'V6.0、V1.0',
					time:'2021年12月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'杭州乾坤科技有限公司',
					CertifiedProduct:'QKP-X-04FS',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'北京科东电力控制系统有限责任公司',
					CertifiedProduct:'充电控制计费单元STCU-3000',
					AuthenticationSystem:'V6.0',
					time:'',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'国网思极网安科技（北京）有限公司',
					CertifiedProduct:'移动堡垒机SGIT-MAG',
					AuthenticationSystem:'V6.0',
					time:'2022年03月',
				},
				{
					category:'嵌入式设备',
					CertifiedManufacturer:'北京珞安科技有限责任公司',
					CertifiedProduct:'移动堡垒机LOSA-100-MH',
					AuthenticationSystem:'V6.0、V1.0',
					time:'2022年01月',
				},
				{
					category:'笔记本',
					CertifiedManufacturer:'上海联和东海信息技术有限公司',
					CertifiedProduct:'东海XMD30/XTD30系列微型计算机处理器',
					AuthenticationSystem:'欧拉版V6.0<br>龙蜥版V6.0',
					time:'2022年12月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'四川华鲲振宇智能科技有限责任公司',
					CertifiedProduct:'天宫AT800（Model 3000）、天宫AT800（Model 9000）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2023年03月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想开天科技有限公司',
					CertifiedProduct:'ThinkServer SR658H V2,<br>SR658H V2,联想开天KR722h G2,<br>KaiTianKR722h G2,KR722h G2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想开天科技有限公司',
					CertifiedProduct:'联想开天KR722h G2（即联想ThinkServer SR658H V2）',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想开天科技有限公司',
					CertifiedProduct:'联想开天KR722z G2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'联想（北京）信息技术有限公司',
					CertifiedProduct:'ThinkServer SR658H V2,<br>SR658H V2,联想开天KR722h G2,<br>KaiTianKR722h G2,KR722h G2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年04月',
				},
				{
					category:'工作站',
					CertifiedManufacturer:'联想（北京）有限公司',
					CertifiedProduct:'工作站P360',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年09月',
				},
				{
					category:'笔记本',
					CertifiedManufacturer:'上海联和东海信息技术有限公司',
					CertifiedProduct:'东海XMD30/XTD30/XNC30系列微型计算机',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮（山东）计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS5260H2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
					remark: '海光3号5000系列2U'
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮（山东）计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS5280H2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
					remark: '海光3号7000系列2U'
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮（山东）计算机科技有限公司',
					CertifiedProduct:'浪潮英政服务器CS5420H2',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
					remark: '海光3号7000系列4U'
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮（山东）计算机科技有限公司',
					CertifiedProduct:'CS5260L2/CS5466L2',
					AuthenticationSystem:'V6.0',
					time:'2023年09月'
				},
				{
					category:'服务器',
					CertifiedManufacturer:'浪潮（山东）计算机科技有限公司',
					CertifiedProduct:'CS5280Z2/CS5466Z2',
					AuthenticationSystem:'V6.0',
					time:'2023年09月'
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2416',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2111',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2211',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2411',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2215',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2415',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R2216',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3116',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3216',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3416',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'HD210',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'HS310',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3210',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3211',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'R3215',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'合肥航天联志科技有限公司',
					CertifiedProduct:'24040R-HB/46040R-HB/26081R-HB/26082R-HB/26121R-HB服务器产品',
					AuthenticationSystem:'V6.0',
					time:'2023年10月',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'北京华电众信技术股份有限公司',
					CertifiedProduct:'HEC-3212',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'曙光网络科技有限公司',
					CertifiedProduct:'GWR、GWB系列工业计算平台',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'长城超云（北京）科技有限公司',
					CertifiedProduct:'超云X86服务器R5210、R5215 R7210、R7410、R8424、R8428系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'紫光恒越技术有限公司',
					CertifiedProduct:'台式机UNIS D3890 G2产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'笔记本',
					CertifiedManufacturer:'南瑞集团有限公司',
					CertifiedProduct:'瑞腾（RETURN）微型计算机：nDesktop HG201、nDesktop ZX601、nDesktop FT301、nDesktop PG101、nDesktop LX501<br>瑞腾（RETURN）笔记本电脑：nBook FT301、nBook FT303、nBook ZX601、nBook ZX701、nBook LX501',
					AuthenticationSystem:'V6.0',
					time:'2023年11月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'上海华诚金锐信息技术有限公司',
					CertifiedProduct:'申威3231、1261、831服务器系列产品',
					AuthenticationSystem:'V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威421台式机',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威3231双路服务器',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威1621服务器',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威831台式机电脑',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2024年01月',
				},

				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5930 G2',
					AuthenticationSystem:'6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'中兴通讯股份有限公司',
					CertifiedProduct:'R5530 G2',
					AuthenticationSystem:'6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'服务器',
					CertifiedManufacturer:'湖南湘江鲲鹏信息科技有限责任公司',
					CertifiedProduct:'兆瀚RHXXX系列服务器、兆瀚RAXXX系列服务器',
					AuthenticationSystem:'6.0<br>欧拉版V6.0',
					time:'2024年01月',
				},
				{
					category:'台式机',
					CertifiedManufacturer:'江苏云涌电子科技股份有限公司',
					CertifiedProduct:'YY0121',
					AuthenticationSystem:'6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年02月',
					remark: '龙芯3A6000'
				},
			]
		},
		{
			title:'存储',
			text:[
				{
					category:'存储设备',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'华为OceanStor Dorado V6',
					AuthenticationSystem:'V6.0<br>多路径',
					time:'2019年02月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'OceanStor Hybrid Flash Storage',
					AuthenticationSystem:'V6.0',
					time:'2022年02月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'OceanStor Pacific',
					AuthenticationSystem:'V6.0',
					time:'2022年04月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C 3PAR存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C CF8K&9K&20K存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C CF22000 G2存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},

				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C Nimble存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C UniStor CF5000存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'新华三技术有限公司',
					CertifiedProduct:'H3C Primera存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'北京辰光融信技术有限公司',
					CertifiedProduct:'自主可控光纤通道存储设备L5000',
					AuthenticationSystem:'V6.0',
					time:'2020年09月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'航天七〇六所',
					CertifiedProduct:'天玥1234AS10A/1234AS10B',
					AuthenticationSystem:'V6.0',
					time:'2020年09月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'北京同有飞骥科技股份有限公司',
					CertifiedProduct:'ACS 5000系列存储',
					AuthenticationSystem:'V6.0',
					time:'2020年09月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'北京同有飞骥科技股份有限公司',
					CertifiedProduct:'NetStor ISUM A系列存储',
					AuthenticationSystem:'V6.0',
					time:'2020年09月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'北京腾凌科技有限公司',
					CertifiedProduct:'存储阵列T2000系列产品',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'中电云数智科技有限公司',
					CertifiedProduct:'中国电子云仓海系列存储<br>CeaStor 6XXX / 8XXX / 16XXX / 18XXX',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'中国电子系统技术有限公司',
					CertifiedProduct:'中国电子云仓海系列存储<br>CeaStor 6XXX / 8XXX / 16XXX / 18XXX',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'AS系列存储、HF系列存储',
					AuthenticationSystem:'V6.0',
					time:'2020年11月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'中国电子科技集团公司第五十二研究所',
					CertifiedProduct:'HikSpace-F7010G2、HikSpace-F7020、<br>HikSpace-F8000、HikSpace-F8110<br>自主统一存储设备',
					AuthenticationSystem:'V6.0',
					time:'2023年09月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'集中式存储AS/HF系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'存储设备',
					CertifiedManufacturer:'浪潮电子信息产业股份有限公司',
					CertifiedProduct:'分布式存储AS13000系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'存储',
					CertifiedManufacturer:'北京星辰天合科技股份有限公司',
					CertifiedProduct:'星辰天合企业级分布式统一数据平台 V6、星辰天合分布式块存储引擎软件 V6、<br>星辰天合分布式文件存储系统 V6 、星辰天合分布式对象存储系统V6 、<br>星辰天合分布式云后端存储系统V6 、星辰天合企业级统一存储系统 V6、<br>星辰天合非结构化数据存储系统V6',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年08月',
				}
			]
		},
		{
			title:'整机配件',
			text:[
				{
					category:'网卡',
					CertifiedManufacturer:'深圳市联瑞电子有限公司',
					CertifiedProduct:'SF400HT网卡、RP2000P2SFP-SW网卡',
					AuthenticationSystem:'V6.0',
					time:'2020年12月',
					remark:'网讯',
				},
				{
					category:'显卡',
					CertifiedManufacturer:'长沙景美集成电路设计有限公司',
					CertifiedProduct:'JM72',
					AuthenticationSystem:'V6.0',
					time:'2021年11月',
					remark:'景嘉微',
				},
				{
					category:'显卡',
					CertifiedManufacturer:'长沙景美集成电路设计有限公司',
					CertifiedProduct:'JM9230GPU芯片',
					AuthenticationSystem:'V6.0',
					time:'2022年12月',
					remark:'景嘉微',
				},
				{
					category:'显卡',
					CertifiedManufacturer:'西安芯瞳半导体技术有限公司',
					CertifiedProduct:'GenBu01系列GPU',
					AuthenticationSystem:'V6.0',
					time:'2023年01月',
					remark:'',
				},
				{
					category:'加密卡',
					CertifiedManufacturer:'北京科东电力控制系统有限责任公司',
					CertifiedProduct:'PT2000E-G',
					AuthenticationSystem:'V6.0',
				},
				{
					category:'固态硬盘',
					CertifiedManufacturer:'深圳大普微电子科技有限公司',
					CertifiedProduct:'大普微蛟容系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年05月',
				},
				{
					category:'主板',
					CertifiedManufacturer:'北京华电众信技术股份有限公司',
					CertifiedProduct:'LB-3212A产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年10月',
				},
				{
					category:'固态硬盘',
					CertifiedManufacturer:'深圳市硅格半导体有限公司',
					CertifiedProduct:'NVME SSD SGM9702C产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
				},
				{
					category:'固态硬盘',
					CertifiedManufacturer:'深圳市江波龙电子股份有限公司',
					CertifiedProduct:'ORCA 4836系列企业级NVMe SSD',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'固态硬盘',
					CertifiedManufacturer:'深圳市江波龙电子股份有限公司',
					CertifiedProduct:'UNCIA 3836系列企业级SATA SSD',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'网卡',
					CertifiedManufacturer:'无锡沐创集成电路设计有限公司',
					CertifiedProduct:'N10G-X2-DC网卡',
					AuthenticationSystem:'V6.0',
					time:'2024年02月',
				},
				{
					category:'显卡',
					CertifiedManufacturer:'芯动微电子科技（武汉）有限公司',
					CertifiedProduct:'“风华1号”GPU',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},
				{
					category:'显卡',
					CertifiedManufacturer:'芯动微电子科技（武汉）有限公司',
					CertifiedProduct:'“风华2号”GPU',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年06月',
				},

				{
					category:'RAID卡',
					CertifiedManufacturer:'灵达科技（天津）有限公司',
					CertifiedProduct:'RAID卡3260系列、HBA卡2230系列',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				},
				{
					category:'网卡',
					CertifiedManufacturer:'灵达科技（天津）有限公司',
					CertifiedProduct:'NIC 1160-2X',
					AuthenticationSystem:'V6.0',
					time:'2024年07月',
				}
			]
		},
		{
			title:'CPU',
			text:[
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光7165',
					AuthenticationSystem:'V6.0',
					remark:'曙光H620-G30',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光7185',
					AuthenticationSystem:'V6.0',
					remark:'曙光H620-G30',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光人工智能加速卡DCU',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光三号3000、5000、7000系列CPU',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年08月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'人工智能加速卡DCU系列',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光7000',
					AuthenticationSystem:'V6.0',
					remark:'H610',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光5000',
					AuthenticationSystem:'V6.0',
					remark:'H540',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'海光信息技术股份有限公司',
					CertifiedProduct:'海光3000',
					AuthenticationSystem:'V6.0',
					remark:'H320',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯2K1000',
					AuthenticationSystem:'装置专用版',
					remark:'云涌、众达',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯3B5000',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯3A5000',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯3C5000L',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯3A5000/3B5000/3C5000/3C5000L',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'',
					time:'2023年08月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯先开ZX-C/C+系列',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯KX-6000系列/KH-30000系列处理器',
					AuthenticationSystem:'V6.0',
					time:'2021年04月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯KH-40000系列、KH-30000系列、<br>KH-20000、ZX-C+系列处理器',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯KH-60000系列、KH-50000系列、<br>ZX-C+系列、ZX-C系列处理器',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯开胜® KH-40000系列服务器处理器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年03月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海兆芯集成电路有限公司',
					CertifiedProduct:'兆芯开先® KX-6000G系列处理器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年04月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'飞腾2000/4',
					AuthenticationSystem:'装置专用版',
					remark:'云涌',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'飞腾1500/4',
					AuthenticationSystem:'装置专用版',
					remark:'合为/利华（装置）',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'飞腾D2000',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'FT-1500A/4、FT-2000/4',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'FT-2000+/64、飞腾腾云S2500、飞腾腾珑E2000',
					AuthenticationSystem:'V6.0',
					time:'2023年08月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'飞腾腾云S5000C处理器',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					time:'2024年08月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'飞腾信息技术有限公司',
					CertifiedProduct:'FT-2000+/64、飞腾腾云S2500',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年08月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'Kunpeng 920',
					AuthenticationSystem:'V6.0',
					time:'',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'华为技术有限公司',
					CertifiedProduct:'Kunpeng 920',
					AuthenticationSystem:'欧拉版V6.0',
					time:'2023年09月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'合芯科技有限公司',
					CertifiedProduct:'HP-C1000',
					AuthenticationSystem:'V6.0',
					remark:'HX-S1221',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海熠知电子科技有限公司',
					CertifiedProduct:'TF7000系列新型处理器',
					AuthenticationSystem:'V6.0',
					remark:'异构处理器芯片',
					time:'2023年02月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'上海华诚金锐信息技术有限公司',
					CertifiedProduct:'申威3231、1261、831服务器系列产品',
					AuthenticationSystem:'V6.0',
					remark:'申威3231、1261、831',
					time:'2024年01月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威421台式机',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'申威421',
					time:'2024年01月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威3231双路服务器',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'申威3231',
					time:'2024年01月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威1621服务器',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'申威1621',
					time:'2024年01月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'成都申威科技有限责任公司',
					CertifiedProduct:'神威831台式机电脑',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'申威831',
					time:'2024年01月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'中电科申泰信息科技有限公司',
					CertifiedProduct:'申威3231处理器',
					AuthenticationSystem:'欧拉版V6.0',
					remark:'申威3231',
					time:'2024年02月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'龙芯中科技术股份有限公司',
					CertifiedProduct:'龙芯3A6000',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					remark:'龙芯3A6000',
					time:'2024年02月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'同泰怡信息技术(福建)有限公司',
					CertifiedProduct:'飞腾S5000C系列产品',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0',
					remark:'飞腾S5000C',
					time:'2024年05月',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'澜起科技股份有限公司',
					CertifiedProduct:'第二代津逮CPU',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'澜起科技股份有限公司',
					CertifiedProduct:'第三代津逮CPU',
					AuthenticationSystem:'V6.0',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'澜起科技股份有限公司',
					CertifiedProduct:'第四代津逮®CPU',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年08月',
					remark:'',
				},
				{
					category:'CPU',
					CertifiedManufacturer:'澜起科技股份有限公司',
					CertifiedProduct:'第五代津逮® CPU',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2023年12月',
					remark:'',
				},
			]
		},
		{
			title:'外设',
			text:[
				{
					category:'打印机',
					CertifiedManufacturer:'新会江裕信息产业有限公司',
					CertifiedProduct:'LQ-350K打印机',
					AuthenticationSystem:'V6.0',
					time:'2020年08月',
					remark:'映美',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'新会江裕信息产业有限公司',
					CertifiedProduct:'LQ-350K+打印机',
					AuthenticationSystem:'V6.0',
					time:'2020年08月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'新会江裕信息产业有限公司',
					CertifiedProduct:'FP-8600K打印机',
					AuthenticationSystem:'V6.0',
					time:'2020年08月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'富士胶片（中国）投资有限公司',
					CertifiedProduct:'富士施乐C328',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'富士胶片（中国）投资有限公司',
					CertifiedProduct:'富士施乐C3555',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'富士胶片（中国）投资有限公司',
					CertifiedProduct:'富士施乐C288',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'富士胶片（中国）投资有限公司',
					CertifiedProduct:'富士施乐DC3208',
					AuthenticationSystem:'V6.0',
					time:'2021年06月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'利盟信息技术（中国）有限公司',
					CertifiedProduct:'Lexmark MS黑白激光单功能系列',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'利盟信息技术（中国）有限公司',
					CertifiedProduct:'Lexmark MX黑白激光多功能系列',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'利盟信息技术（中国）有限公司',
					CertifiedProduct:'Lexmark CS彩色激光单功能系列',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'利盟信息技术（中国）有限公司',
					CertifiedProduct:'Lexmark CX彩色激光多功能系列',
					AuthenticationSystem:'V6.0',
					time:'2022年01月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'佳能（中国）有限公司',
					CertifiedProduct:'佳能公司LBP系列激光打印机',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'佳能（中国）有限公司',
					CertifiedProduct:'佳能公司MF系列激光一体机',
					AuthenticationSystem:'V6.0',
					time:'2022年08月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'大连中盈科技股份有限公司',
					CertifiedProduct:'中盈Laser 1020 plus',
					AuthenticationSystem:'V6.0',
					time:'2022年10月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'得力集团有限公司',
					CertifiedProduct:'P2500DN黑白激光打印机',
					AuthenticationSystem:'V6.0<br>欧拉版V6.0<br>龙蜥版V6.0',
					time:'2024年01月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'北京立思辰计算机技术有限公司',
					CertifiedProduct:'立思辰打印机设备',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				},
				{
					category:'打印机',
					CertifiedManufacturer:'北京立思辰计算机技术有限公司',
					CertifiedProduct:'立思辰扫描仪设备',
					AuthenticationSystem:'V6.0',
					time:'2024年08月',
				}
			]
		},
	]
};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
