<template>
	<div>
		<TopOfProducts :smallTitle="natittle2" :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainMargin">
			<!--<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->-->
			<PlatformProductCustomDevelopment v-show="natittle3 == '平台产品定制开发'"></PlatformProductCustomDevelopment>
			<CrossPlatformPortingDevelopment v-show="natittle3 == '跨平台移植开发'"></CrossPlatformPortingDevelopment>
			<SystemFunctionSoftwareDevelopment v-show="natittle3 == '系统功能软件开发'"></SystemFunctionSoftwareDevelopment>
		</div>
	</div>
</template>

<script>
import PlatformProductCustomDevelopment from './PlatformProductCustomDevelopment.vue';
import CrossPlatformPortingDevelopment from './CrossPlatformPortingDevelopment.vue';
import SystemFunctionSoftwareDevelopment from './SystemFunctionSoftwareDevelopment.vue';
export default {
	name:'CustomDevelopment',
	components:{
	 	PlatformProductCustomDevelopment,
	 	CrossPlatformPortingDevelopment,
	 	SystemFunctionSoftwareDevelopment,
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '定制开发',
			natittle3: this.$route.query.producttitle,
			brief:'',
			brief1:[
				'<h>凝思软件提供三类定制开发服务: 平台类产品定制，跨平台移植，系统层功能软件定制。</h>',
			],
			brief2:[
				'',
			],
			brief3:[
				'',
			],
		}
	},
    watch:{
        $route:{
            immediate:true,
            handler(){
                this.natittle3 = this.$route.query.producttitle;
                if(this.natittle3 == '平台产品定制开发'){
                    this.brief = this.brief1;
                } else if(this.natittle3 == '跨平台移植开发'){
                    this.brief = this.brief2;
                } else if(this.natittle3 == '系统功能软件开发'){
                    this.brief = this.brief3;
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
