<template>
    <div class="buboxfooter">
    	<!-- <img src="./images/logo.png" alt="LOGO"> -->
        <router-link to="/PrivacyPolicy">隐私政策</router-link>
        <router-link to="/LegalNotices">法律声明</router-link>
        <a target="_parent" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021006165号-1</a>
        <a target="_parent" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024724">京公网安备11010802024724号</a> 
        <router-link to="/Home">www.linx-info.com</router-link>
        <p>©{{time}} 北京凝思软件股份有限公司</p>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'foot4',
    data() {
		return {
			time: "",
		};
	},
	mounted() {
      this.nowtime()
    },
    methods:{
    	nowtime() {
			let nowDate = new Date();
			let date = {
				// 获取当前年份
				year: nowDate.getFullYear(),
			};
			//拼接
			this.time = date.year;
		},
    },
}
</script>

<style scoped>
/*手机端*/
@media only screen and (max-width:750px){
	.buboxfooter{
		width: calc(100% - 50px);
		padding:8px 25px;
		height: auto;
		margin: 0 auto;
		position: relative;
		background-color: #14161c;
	}
	.buboxfooter img{
		display:block;
		position:relative;
		width: 81.5px;
		height:auto;
		left:0;
		top:0;
		padding:28px 0 10px;
	}
	.buboxfooter p,.buboxfooter > a{
		position: relative;
		display:block;
		left: 0px;
		top:0px;
		color: #a2a2a3;
		line-height: 36px;
		font-size: 11px;
		text-align: left;
	}
}
</style>
