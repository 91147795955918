import { render, staticRenderFns } from "./Military.vue?vue&type=template&id=6425f560&scoped=true"
import script from "./Military.vue?vue&type=script&lang=js"
export * from "./Military.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6425f560",
  null
  
)

export default component.exports