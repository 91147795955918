<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<!-- 新闻中心 -->
		<div class="font" v-show="title4 == 0" id="fontnews">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
				<input type="text" class="search-val" placeholder="请输入关键字" value="" v-model="searchValue">
				<router-link :to="{path:'/AboutLinx/SearchNews',query:{key:searchValue}}"></router-link>
			</h1>
			<NewsCue :hyear="hyear" v-show="bodyWidthMut > 1170"></NewsCue>
			<NewsCue2 :nowyear.sync="nowyear" v-show="bodyWidthMut <= 1170"></NewsCue2>
            <div id="details">
				<div class="new" v-for="(itemnews,indexnews) in newsDataList" :key="indexnews + '-1'" v-show="bodyWidthMut > 1170 || itemnews.year == nowyear">
					<div :id="'year' + itemnews.year"></div>
					<p class="year">{{itemnews.year}}</p>
					<p v-for="(newsdetail,indexnewsdetail) in itemnews.text" :key="indexnewsdetail + '-2'">
						<a @click="getKeyRouter(newsdetail.tag)">{{newsdetail.tag}}</a>
						<span>｜</span>
						<router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:newsdetail.id}}" v-html="newsdetail.tittle"></router-link>
						<span class="time">{{newsdetail.time}}</span>
					</p>
				</div>
			</div>
		</div>
		<!-- 关键字 -->
		<div
			class="font"
			v-for="(itemtag,indexitemtag) in title4List"
			:key="indexitemtag + '-3'"
			v-show="title4 == indexitemtag+1"
		>
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
            <div id="details">
				<div class="new2">
					<p
						v-for="(News,indexNews) in NewsList"
						:key="indexNews + '-4'"
						v-show="News.tag == title4List[title4].tittle"
					>
						<router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:News.id}}" v-html="News.tittle"></router-link>
						<span class="time">{{News.time}}</span>
					</p>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import NewsCue from './NewsCue.vue';
import NewsCue2 from './NewsCue2.vue';

export default {
	name:'News',
	components:{
		NewsCue,
		NewsCue2
    },
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			natittle1: this.$attrs.tittle,
			natittle2: '新闻中心',
			natittle3: '',
			title4: 0,
			icon: require('../images/新闻icon.png'),
			title4List: [
				{
					tittle:'新闻中心',
				},
				{
					tittle:'要闻'
				},
				{
					tittle:'资质'
				},
				{
					tittle:'项目'
				},
				{
					tittle:'人物'
				},
				{
					tittle:'慈善'
				},
				{
					tittle:'荣誉'
				},
				{
					tittle:'公告'
				}
			],
			searchValue: '',
			NewsList:[],
			hyear:[],
			nowyear:0,
		}
	},
	computed:{
		...mapState({
			newsDataList:(state)=>state.News.newsDataList,
		})
	},
	methods:{
		getKeyRouter(val){
			if (val == '要闻'){
				this.title4 = 1;
			} else if (val == '资质'){
				this.title4 = 2;
			} else if (val == '项目'){
				this.title4 = 3;
			} else if (val == '人物'){
				this.title4 = 4;
			} else if (val == '慈善'){
				this.title4 = 5;
			} else if (val == '荣誉'){
				this.title4 = 6;
			} else if (val == '公告'){
				this.title4 = 7;
			}
		},
	},
	created() {
		if(this.$route.query.title4){
			this.title4 = this.$route.query.title4;
		}
	},
	mounted(){
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
		var n = this.newsDataList.length;
		this.nowyear = 2015 + n;
		for(var i=0;i<n;i++){
			this.hyear[i]=$('#year' + (2016 + i)).offset().top;
		}
	},
	watch:{
		NewsList:{
			immediate:true,
			handler(){
				let n = 0;
				for(let i=0;i<this.newsDataList.length;i++){
					for(let j=0;j<this.newsDataList[i].text.length;j++){
						this.NewsList[n] = this.newsDataList[i].text[j];
						n++;
					}
				}
			}
		},
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
				if(this.title4==0){
					this.natittle2 = false;
				} else {
					this.natittle2 = '新闻中心';
				}
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
/* 搜索框 */
.font h1 input{
    position: absolute;
	top: 17px;
	right: 70px;
    width: 164px;
    height: 32px;
    border: 1px #e8e9eb solid;
    padding-left: 12px;
    outline:none;
    font-size: 14px;
    color: #262b33;
}
.font h1 input::-webkit-input-placeholder {
    color: #f5f6f7;
    font-size: 14px;
}
.font h1 a{
    position: absolute;
	top: 17px;
	right: 32px;
    background:url(../images/search-line.png) no-repeat;
    background-color: #f7f7f7;
    background-position: center center;
    border: none;
    color: white;
    width: 38px;
    height: 32px;
    border-right: 1px #ebebeb solid;
    border-top: 1px #ebebeb solid;
    border-bottom: 1px #ebebeb solid;
    outline:none;
}
.font h1 a:hover{
    background: url(../images/search-line移入.png) no-repeat;
    background-color: #e8e9eb;
    background-position: center center;
}
/* 新闻标题 */
#details .new,#details .new2{
    position: relative;
    margin-left: 8px;
    margin-bottom: 8px;
}
#details #newOfsearch,#details #nonenew{
    position: relative;
    margin-left: 0px;
    margin-bottom: 0px;
}
#details .new div{
    width: 100px;
    height: 80px;
    top: -80px;
    position: absolute;
    /* background-color: #dd1d29; */
    z-index: -1;
}
#details .new p,#details .new2 p,#details #nonenew p{
    margin:0;
    line-height: 40px;
    letter-spacing: 0px;
}
#details .new p > a{
	cursor:pointer;
}
#details #nonenew p{
    text-align: center;
}
#details .new2 p:before,#details #newOfsearch p:before{
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 8px;
    margin-bottom: 2px;
    background: #9b141d;
    content: '';
}
#details .new .year,#details .new2 .year{
    color: #dd1d29;
}
#details .new p a:hover,
#details .new2 p a:hover,
#details .new p a:hover >>> span,
#details .new2 p a:hover >>> span{
    color: #dd1d29;
}
#details .new p .time,#details .new2 p .time{
    font-size: 14px;
    color: #8d9198;
}
#details .new p .time::before,#details .new2 p .time::before{
    content:"闻";
    opacity: 0;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	/* 新闻标题 */
	#details {
	    white-space:nowrap;
	}
	#details .new{
	    position: relative;
	    padding-left: 8px;
	    margin-left:0;
	    margin-bottom: 12px;
	}
	#details #newOfsearch,#details #nonenew{
	    padding-left: 0px;
	    margin-bottom: 4px;
	}
	.newhi{
	    display:none;
	}
	#details .new div{
	    width: 100px;
	    height: 80px;
	    top: -80px;
	    position: absolute;
	    /* background-color: #dd1d29; */
	    z-index: -1;
	}

	#details .new p,#details .new2 p,#details #nonenew p{
	    line-height: 56px;
	    font-weight: 400;
	    letter-spacing: 0px;
	}

	/*新闻标题前红点*/
	#details .new2 p:before,#details #newOfsearch p:before{
	    display: inline-block;
	    width: 6px;
	    height: 6px;
	    margin-right: 8px;
	    margin-bottom: 2px;
	    background: #9b141d;
	    content: '';
	}

	#details .new .year{
	    padding-left:32px;
	    font-size: 22px;
	    line-height: 40px;
	    font-weight: 400;
	    letter-spacing: 0px;
	    text-indent: 0em;
	}
	#details .new .year,#details .new2 .year{
	    color: #dd1d29;
	}
	#details .new p a,#details .new2 p a{
	    color: #262b33;
	}
	#details .new p a:hover,#details .new2 p a:hover{
	    color: #dd1d29;
	}
	#details .new p .time,#details .new2 p .time{
	    font-size: 14px;
	}
	#details .new p .time::before,#details .new2 p .time::before{
	    content:"闻";
	    opacity: 0;
	}


	#details .new2{
	    position: relative;
	    margin-left: 8px;
	    margin-bottom: 8px;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	/* 新闻标题 */
	#details .new p,#details .new2 p,#details #nonenew p{
	    height: 56px;
	    font-weight: 400;
	    letter-spacing: 0px;
	}
	#details .new p > a{
	    display:inline-block;
	    max-width:505px;
	    white-space:nowrap;
	    text-overflow:ellipsis;
	    overflow:hidden; 
	    text-indent: 0em;
	}
	#details .new2 p > a{
	    display:inline-block;
	    max-width:535px;
	    white-space:nowrap;
	    text-overflow:ellipsis;
	    overflow:hidden; 
	    text-indent: 0em;
	}
	#details #newOfsearch p > a{
	    display:inline-block;
	    max-width:495px;
	    white-space:nowrap;
	    text-overflow:ellipsis;
	    overflow:hidden; 
	    text-indent: 0em;
	}
	#details .new p > span,#details .new2 p > span{
	    display:inline-block;
	    max-width:500px;
	    white-space:nowrap;
	    text-overflow:ellipsis;
	    overflow:hidden; 
	    text-indent: 0em;
	}

	/*新闻标题前红点*/
	#details .new2 p:before,#details #newOfsearch p:before{
	    display: inline-block;
	    width: 6px;
	    height: 6px;
	    margin-right: 8px;
	    margin-bottom: 24px;
	    background: #9b141d;
	    content: '';
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	/*搜索框*/
	#fontnews{
		margin-top:54.5px;
	}
	.font h1 input{
		position: absolute;
		top:-33px;
		left: 15px;
		width: calc(100% - 46px - 52.5px);
		height: 35px;
		border: 1px #ebebeb solid;
		padding-left: 15px;
		outline:none;
		font-size: 13px;
		background-color:#fff;
	}
	.font h1 input::-webkit-input-placeholder {
		font-size: 9px;
	}
	.font h1 a,.font h1 a:hover{
		position: absolute;
		top:-33px;
		transform: translateY(0);
		right: 15px;
		background:url(../images/search-line-白.png) no-repeat;
		background-size:14px;
		background-color: #dd1d29;
		background-position: center center;
		border: none;
		color: white;
		width: 52px;
		height: 35px;
		border-right: 1px #dd1d29 solid;
		border-top: 1px #dd1d29 solid;
		border-bottom: 1px #dd1d29 solid;
		outline:none;
	}
	/* 新闻标题 */
	#details .new,#details .new2{
	    width:100%;
	    margin-left: 0px;
	    padding-left: 0px;
	    margin-bottom: 0px;
	}
	#details .new p,#details .new2 p,#details #nonenew p{
	    width:calc(100% - 30px);
	    height: auto;
	    font-size:14px;
	    font-weight: 400;
	    line-height:19px;
	    letter-spacing: 0px;
	    text-indent: 0em;
	    padding:21.5px 0 14.5px;
	    margin:0 auto;
	    border-bottom: 1px #ebebeb solid;
	}
	#details .new .year{
	    padding:0;
	    padding-left:15px;
	    font-size: 18px;
	    line-height: 18px;
	    height: 18px;
	    font-weight: 400;
	    letter-spacing: 0px;
	    text-indent: 0em;
	    border-bottom: 0px #ebebeb solid
	}
	#details .new p > a,#details .new2 p > a,#details #newOfsearch p > a,#details .new p > span,#details .new2 p > span{
	    display:inline;
	    word-wrap:break-word;
	    white-space:normal;
	}

	#details .new p .time,#details .new2 p .time{
	    text-align: right;
	    max-width: 100%;
	    width:100%;
	    display:block;
	    font-size:13px;
	    line-height:32px;
	    font-weight: 300;
	}

	/*新闻标题前红点*/
	#details .new2 p:before,#details #newOfsearch p:before{
	    display: none;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	搜索框
	#fontnews{
		margin-top:144px;
	}
	.font h1 input{
		position: absolute;
		top:-90px;
		left: 44px;
		transform: translateY(0);
		width: calc(100% - 88px - 150px);
		height: 100px;
		border: 1px #ebebeb solid;
		padding-left: 40px;
		outline:none;
		font-size: 38px;
		color: #8f8f8f;
		background-color:#fff;
	}
	.font h1 input::-webkit-input-placeholder {
		color: #b8b8b8;
		font-size: 38px;
	}
	.font h1 a,.font h1 a:hover{
		position: absolute;
		top:-90px;
		right: 44px;
		background-size:38px;
		background-color: #dd1d29;
		background-position: center center;
		border: none;
		color: white;
		width: 151px;
		height: 100px;
		border-right: 1px #dd1d29 solid;
		border-top: 1px #dd1d29 solid;
		border-bottom: 1px #dd1d29 solid;
		outline:none;
	}
	新闻标题
	#details .new,#details .new2{
	    width:100%;
	    margin-left: 0px;
	    padding-left: 0px;
	    margin-bottom: 0px;
	}
	#details .new p,#details .new2 p,#details #nonenew p{
	    width:calc(100% - 88px);
	    height: auto;
	    font-size:40px;
	    font-weight: 400;
	    line-height:56px;
	    letter-spacing: 0px;
	    text-indent: 0em;
	    padding:62px 0 46px;
	    margin:0 auto;
	    border-bottom: 2px #ebebeb solid;
	}
	#details .new .year{
	    padding:0;
	    padding-left:0px;
	    font-size: 52px;
	    line-height: 52px;
	    height: 52px;
	    font-weight: 400;
	    letter-spacing: 0px;
	    text-indent: 0em;
	    border-bottom: 0px #ebebeb solid
	}
	#details .new p > a,#details .new2 p > a,#details #newOfsearch p > a,#details .new p > span,#details .new2 p > span{
	    display:inline;
	    word-wrap:break-word;
	    white-space:normal;
	}

	#details .new p .time,#details .new2 p .time{
	    text-align: right;
	    max-width: 100%;
	    width:100%;
	    display:block;
	    font-size:38px;
	    line-height:86px;
	    font-weight: 300;
	    color: #8f8f8f;
	}

	新闻标题前红点
	#details .new2 p:before,#details #newOfsearch p:before{
	    display: none;
	}
} */
</style>
