<!-- 首页-公司要闻 -->
<template>
    <div class="box" id="news" style="background-color: #fff;">
        <div class="title-font1" id="title-font1-box8">公司要闻
            <div></div>
        </div>
        <div id="box-in-box8">
            <div id="img-in-box" :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1">
                <div class="out ">
                    <ul class="img ">
                        <li
                        	v-for="(bannerNews,index) in bannerNewsList"
                        	:key="index"
                        	:class="index == newsid ? 'li1' : 'li2'"
                        >
                            <router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:bannerNews.id}}">
                                <img :src="bannerNews.imgUrl" :alt="bannerNews.tittle">
                            </router-link>
                            <div class="inImg"></div>
                            <router-link class="newsName" :to="{path:'/AboutLinx/NewsDetails',query:{id:bannerNews.id}}">{{bannerNews.tittle}}</router-link>
                        </li>
                    </ul>
                    <div class="left btn" @click="getnewsid(1)"></div>
                    <div class="right btn" @click="getnewsid(-1)"></div>
                </div>
            </div>
            
            <div id="font-in-box" :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0.15s" data-wow-offset="150"  data-wow-iteration="1">
                <div class="font1-in-box" v-for="(News,index) in NewsList" :key="index">
                    <span class="keys">{{News.tag}}</span>
                    <span class="fen">｜</span>
                    <router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:News.id}}" v-html="News.tittle"></router-link>
                    <span class="time">{{News.time}}</span>
                </div> 
                <div class="font2-in-box">
                    <router-link to="/AboutLinx/News"></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
export default {
    name: 'CompanyHighlights',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
            newsid: 0,
            NewsList:[],
            bannerNewsList:[
                {
                    id: '29',
                    imgUrl:require('/src/images/news/news20210425-01.jpeg'),
                    tittle:'凝思安全操作系统助力能源行业数字化转型',
                },
                {
                    id: '27',
                    imgUrl:require('/src/images/news/news20210113-01.jpg'),
                    tittle:'凝思荣获“2020年中国能源企业信息化方案案例创新奖”',
                },
                {
                    id: '24',
                    imgUrl:require('/src/images/news/news20200828-02.png'),
                    tittle:'凝思软件顺利通过软件能力成熟度最高级别CMMI5级认证',
                },
                {
                    id: '20',
                    imgUrl:require('/src/images/news/news20191018-1.jpg'),
                    tittle:'凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
                },
                {
                    id: '15',
                    imgUrl:require('/src/images/news/news20180915-01.jpeg'),
                    tittle:'凝思软件出席2018世界物联网博览会智能电网峰会 宫敏董事长作主题报告',
                },
                {
                    id: '8',
                    imgUrl:require('/src/images/news/news20171109-3.jpg'),
                    tittle:'凝思软件董事长宫敏博士荣获“功勋程序员”荣誉证书',
                },
                {
                    id: '6',
                    imgUrl:require('/src/images/news/news20170904-01.jpg'),
                    tittle:'国产操作系统结合Power Linux服务器助力行业基础设施转型升级',
                },
                {
                    id: '3',
                    imgUrl:require('/src/images/news/news20170606-01.jpg'),
                    tittle:'国家发改委信息安全专项-凝思安全操作系统产业化项目验收会圆满成功',
                },
            ]
        }
    },
    computed:{
        ...mapState({
            newsDataList:(state)=>state.News.newsDataList,
        })
    },
    methods:{
        getnewsid(val){
            this.newsid = this.newsid + val;
        },
        get(){
            this.newsid ++;
        }
    },
    mounted() {
    	window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
        this.newsid = setInterval(this.get, 3000);
    },
    watch:{
        newsid:{
            immediate:true,
            handler(){
                if (this.newsid >= this.bannerNewsList.length){
                    this.newsid = 0;
                } else if (this.newsid < 0){
                    this.newsid = this.bannerNewsList.length - 1;
                }
            }
        },
        NewsList:{
            immediate:true,
            handler(){
                let n = 0;
                for(let i=0;i<this.newsDataList.length;i++){
                    for(let j=0;j<this.newsDataList[i].text.length;j++){
                        this.NewsList[n] = this.newsDataList[i].text[j];
                        n++;
                        if(n>=6){
                            return false
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
/*pc*/
#news{
	background: url(./images/公司要闻上点阵-pc.png) no-repeat right 29px;
}
#box-in-box8{
    width: 1170px;
    height: 421px;
    margin: 0 auto 130px;
    background-color: #fff;
}
.out {
    width: 561px;
    height: 421px;
    position: relative;
    float: left;
}
.out .inImg{
    position: absolute;
    bottom: 0;
    z-index: 99;
    height: 104px;
    width:100%;
    background: url(./images/新闻标题渐变遮罩.png) no-repeat left bottom;
}
.out .img .li1 {
    position: absolute;
    left:0;
    top:0;
    height: 421px;
    width: 561px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.6s;
    z-index:1;
}
.out .img .li2 {
    position: absolute;
    left:0;
    top:0;
    height: 421px;
    width: 561px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.6s;
    z-index:0;
}
.out .img li a img{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    height: 100%;
    transition:0.5s;  
    transition-timing-function: cubic-bezier(.17,.24,.54,1);
    display: inline-block;
}
.out .img:hover li a img{
    height: 105%;
}
.out .img li .newsName{
    text-decoration: none;
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    z-index: 999;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden; 
    width: 350px;
    display: inline-block; 
    text-align: center;
}
.out .left {
    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 45px;
    width: 45px;
    background: url(./images/新闻箭头-左.png) no-repeat;
    background-position: center center;
    opacity: 0.6;
    transition: opacity .3s,background-color .3s;
    z-index: 100;
    cursor:pointer;
}
.out .right {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 45px;
    width: 45px;
    background: url(./images/新闻箭头-右.png) no-repeat;
    opacity: 0.6;
    transition: opacity .3s,background-color .3s;
    z-index: 100;
    cursor:pointer;
}
.out .left:hover,.out .right:hover{
    background-color: rgba(255,255,255,0.3);
    opacity: 1;
    transition: opacity .3s,background-color .3s;
}

#font-in-box{
    float:right;
    position:relative;
    height: 421px;
    width: 554px;
}
#font-in-box div{
    margin: 10px 0 45px;
}
#font-in-box .keys{
    vertical-align:middle; 
    color: #dd1d29;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0px;
}
#font-in-box .fen{
    vertical-align:middle;
    font-size: 16px;
    line-height: 18px;
}
#font-in-box a{
    text-align: left;
    display: inline-block;
    vertical-align:middle; 
    width: 370px;
    color: #262b33;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden; 
    /* background-color: aqua; */
}
#font-in-box .time{
    float:right;
    color: #898e96;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0px;
}

#font-in-box .font2-in-box a{
    float: right;
    text-align:right;
    width:100px;
    color: #dd1d29;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0px;
}
#font-in-box .font2-in-box a:before{
    content: '更多｜More';
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    #box-in-box8{
        width: 1024px;
        padding: 0px calc(50% - 512px) 219px;
        height: 498px;
        margin: 0 auto;
        overflow: hidden;
    }
    .out {
        margin-left:60px;
        width: 424px;
        height: 498px;
        background:#fff;
        box-shadow: 0px 2px 20px 0px rgba(5,5,5,0.08);
    }
    .out .inImg{
        display:none;
    }
    .out .img .li1 , .out .img .li2{
        width: 424px;
        height: 498px;
    }
    .out .img li a img{
        top:0;
        transform: translateX(-50%);
        height: 336px;
    }
    .out .img:hover li a img{
        height: 336px;
    }
    .out .img li .newsName{
        width:328px;
        bottom: 81px;
        transform: translateX(-50%) translateY(50%);
        color: #141414;
        font-size: 18px;
        line-height:28px;
        font-weight: 400;
        white-space:normal;
        text-overflow:none;
        text-align: left;
    }
    .out .left ,.out .right{
        display:none;
    }

    #font-in-box{
        float:left;
        margin-left:56px;
        height: 498px;
        width: 424px;
    }
    #font-in-box .font1-in-box{
        position: relative;
        height:120px;
        margin:0;
    }
    #font-in-box > .font1-in-box:after{
        position:absolute;
        bottom:0;
        left:0;
        width: 624px;
        height:1px;
        content: ' ';
        background:#ebebeb;
    }
    #font-in-box .keys{
    position:absolute;
    top:34px;
    left:0;
    color: #666;
    font-weight: 400;
    }
    #font-in-box .fen{
        position:absolute;
        top:34px;
        left:34px;
    }
    #font-in-box a{
        position:absolute;
        bottom:34px;
        left:0;
        width: 424px;
        font-weight: 500;
    }
    #font-in-box .time{
        position:absolute;
        top:34px;
        left:50px;
        color: #666;
        font-weight: 400;
    }
    #font-in-box>div:nth-child(5),#font-in-box>div:nth-child(6){
        display:none;
    }
    #font-in-box .font2-in-box a{
        text-decoration: none;
        position: absolute;
        display:block;
        width:210px;
        line-height:57px;
        border-radius:2px;
        margin:32px auto 0px;
        background:#fff;
        text-align:center;
		border: 1px solid #8f8f8f;
        font-weight:500;
        letter-spacing: 0px;
        color: #292929;
        font-size: 16px;
        bottom:-107px;
        left:-134px;
    }
    #font-in-box .font2-in-box a:hover{
        background:#dd1d29;
		border: 1px solid #dd1d29;
        color: #fff;
    }
    #font-in-box .font2-in-box a:before{
        content: '前往新闻中心';
    }
    #font-in-box .font2-in-box a::after{
        position: relative;
        display: inline-block;
        content: '';
        width: 27px;
        height: 24px;
        background: url(../images/箭头-黑色-向右.png) no-repeat right center;
        background-size: 14px 8px;
        top: 5px;
    }
    #font-in-box .font2-in-box a:hover::after{
        content: '';
        width: 27px;
        height: 24px;
        background: url(../images/箭头-白色-向右.png) no-repeat right center;
        background-size: 14px 8px;
        top: 5px;
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
    #box-in-box8{
        width: 100%;
        height: auto;
        margin: 0;
        padding-bottom: 196px;
    }
    .out {
        width: 704px;
        height: 421px;
        float: none;
        position: relative;
        margin: 0 auto;
    }
    .out .inImg{
        display:block;
        height: 104px;
        background: url(./images/新闻标题渐变遮罩.png) no-repeat left bottom;
        background-size:100% 104px;
    }
    .out .img .li1 , .out .img .li2{
        position: absolute;
        left:0;
        top:0;
        height: 421px;
        width: 704px;
        overflow: hidden;
    }
    .out .img li a img{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        display: inline-block;
        height: auto;
        width: 704px;
        min-height: 421px;
        transition:0.5s;  
        transition-timing-function: cubic-bezier(.17,.24,.54,1);
        display: inline-block;
    }
    .out .img:hover li a img{
        height: auto;
        width: 704px;
    }
    .out .img li .newsName{
        text-decoration: none;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        z-index: 999;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden; 
        width: 538px;
        display: inline-block; 
        text-align: center;
    }
    .out .left {
        display: block;
        position: absolute;
        bottom: 8px;
        left: 8px;
        height: 45px;
        width: 45px;
        background: url(./images/新闻箭头-左.png) no-repeat;
        background-position: center center;
        opacity: 0.6;
        transition: opacity .3s,background-color .3s;
        z-index: 100;
    }
    .out .right {
        display: block;
        position: absolute;
        bottom: 8px;
        right: 8px;
        height: 45px;
        width: 45px;
        background: url(./images/新闻箭头-右.png) no-repeat;
        opacity: 0.6;
        transition: opacity .3s,background-color .3s;
        z-index: 100;
    }
    .out .left,.out .right{
        background-color: rgba(255,255,255,0.3);
        opacity: 1;
        transition: opacity .3s,background-color .3s;
    }

#font-in-box{
    position:relative;
    height: auto;
    width: 704px;
    margin: 24px auto 0;
    float:none;
}
#font-in-box .font1-in-box{
    position: relative;
    height:64px;
    margin:0;
}
#font-in-box > .font1-in-box:after{
    display:none;
}
#font-in-box .keys{
    position:relative;
    line-height:64px;
    top:0;
    color: #dd1d29;
    font-size: 18px;
    font-weight: 400;
}
#font-in-box .fen{
    position:relative;
    line-height:64px;
    top:0;
    left:0;
    font-size: 18px;
    font-weight: 400;
}
#font-in-box a{
    position:relative;
    line-height:64px;
    top:0;
    left:0;
    width: 492px;
    font-size: 18px;
    font-weight: 400;
}
#font-in-box .time{
    position:relative;
    line-height:64px;
    top:0;
    left:0;
    color: #666;
    font-weight: 400;
    font-size: 18px;
}
#font-in-box>div:nth-child(5),#font-in-box>div:nth-child(6){
    display:block;
}
#font-in-box .font2-in-box a{
    text-decoration: none;
    position: relative;
    display:block;
    width:210px;
    line-height:57px;
    border-radius:2px;
    margin:32px auto 0px;
    background:#fff;
    text-align:center;
    border: 1px solid #8f8f8f;
    font-weight:500;
    letter-spacing: 0px;
    color: #292929;
    font-size: 16px;
    top:0px;
    right:50%;
    transform: translateX(-50%);
    margin-top:24px;
}
#font-in-box .font2-in-box a:before{
    content: '前往新闻中心';
}
}
/*手机端*/
@media only screen and (max-width:750px){
	#news{
		background: url(./images/公司要闻上点阵-pc.png) no-repeat right 15px;
		background-size:272px 56px;
	}
    #box-in-box8{
        padding-bottom: 150px;
        margin: 0;
    }
    .out {
        width: 320px;
        height: 205px;
        float: none;
        position: relative;
        margin: 0 auto;
    }
.out .inImg{
    display:block;
    height: 40.5px;
    background: url(./images/新闻标题渐变遮罩2.png) no-repeat left bottom;
    background-size:100% 40.5px;
}
.out .img .li1 ,.out .img .li2{
    height: 205px;
    width: 320px;
}
.out .img li a img{
	min-height: 205px;
    width: 320px;
}
.out .img:hover li a img{
    min-height: 205px;
    width: 320px;
}
.out .img li .newsName{
    bottom: 6.5px;
    font-size: 14px;
    width: 293px;
}
.out .left,.out .right{
    display:none;
}


#font-in-box{
    position:relative;
    margin:0 auto;
    height: auto;
    width: 320px;
}
#font-in-box .font1-in-box{
    position: relative;
    height:auto;
    margin:0;
    border-bottom:0.5px solid #ebebeb;
    padding:18px 0;
}
#font-in-box > .font1-in-box:after{
    display:none;
}
#font-in-box .keys{
    position:relative;
    font-size: 14px;
    line-height:26px;
    top:0;
    left:0;
    color: #666;
    font-weight: 400;
}
#font-in-box .fen{
    position:relative;
    font-size: 14px;
    line-height:26px;
    top:0;
    left:0;
    color: #666;
    font-weight: 400;
}
#font-in-box a{
    display:block;
    position:relative;
    width: 320px;
    font-size: 14px;
    line-height:26px;
    top:0;
    left:0;
    color: #141414;
    font-weight: 400;
}
#font-in-box .time{
    position:absolute;
    font-size: 14px;
    line-height:26px;
    top:18px;
    left:42px;
    color: #666;
    font-weight: 400;
}
#font-in-box>div:nth-child(5),#font-in-box>div:nth-child(6){
    display:none;
}
#font-in-box .font2-in-box{
    position: absolute;
    width:232px;
    height:49px;
    margin:28px auto 0;
    left:50%;
    transform: translateX(-50%);
}
#font-in-box .font2-in-box a{
    text-decoration: none;
    display:block;
    position: relative;
    width:230px;
    line-height:47px;
    margin:0 auto;
    border-radius:1px;
    background:#fff;
    text-align:center;
    border: 1px solid #8f8f8f;
    font-weight:500;
    letter-spacing: 0px;
    color: #292929;
    font-size: 13px;
    top:0;
    left:0;
    right:0;
    transform: translateX(0%);
}
    #font-in-box .font2-in-box a:hover{
        background:#dd1d29;
		border: 1px solid #dd1d29;
        color: #fff;
    }
    #font-in-box .font2-in-box a:before{
        content: '前往新闻中心';
    }
    #font-in-box .font2-in-box a::after, #font-in-box .font2-in-box a:hover::after{
		width: 23.5px;
        height: 19px;
		background-size: 13px 7.5px;
        top: 4px;
    }
}
/*手机端1080
@media only screen and (min-height:1300px){
#box-in-box8{
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
}
.out {
    width: 992px;
    height: 590px;
    float: none;
    position: relative;
    margin: 0 auto;
}
.out .inImg{
    display:block;
    height: 117px;
    background: url(./images/新闻标题渐变遮罩2.png) no-repeat left bottom;
    background-size:100% 117px;
}
.out .img .li1 ,.out .img .li2 {
    width: 992px;
    height: 590px;
}
.out .img li a img{
    width: 992px;
    height: auto;
}
.out .img:hover li a img{
    width: 992px;
    height: auto;
}
.out .img li .newsName{
    text-decoration: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    z-index: 999;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden; 
    width: 886px;
    line-height:117px;
    display: inline-block; 
    text-align: center;
}
.out .left,.out .right{
    display:none;
}


#font-in-box{
    position:relative;
    display:block;
    margin:0 auto 441px;
    height: auto;
    width: 100%;
}
#font-in-box .font1-in-box{
    display:block;
    position: relative;
    width: 992px;
    height:auto;
    margin:0 auto;
    border-bottom:2px solid #e0e0e0;
    padding:51px 0;
}
#font-in-box > .font1-in-box:after{
    display:none;
}
#font-in-box .keys{
    position:relative;
    font-size: 40px;
    line-height:74px;
    top:0;
    left:0;
    color: #666;
    font-weight: 300;
}
#font-in-box .fen{
    position:relative;
    font-size: 40px;
    line-height:74px;
    top:0;
    left:0;
    color: #666;
    font-weight: 300;
}
#font-in-box a{
    display:block;
    position:relative;
    width: 992px;
    font-size: 40px;
    line-height:52px;
    top:0;
    left:0;
    color: #141414;
    font-weight: 400;
}
#font-in-box .time{
    position:absolute;
    font-size: 40px;
    line-height:74px;
    top:51px;
    left:125px;
    color: #666;
    font-weight: 300;
}
#font-in-box>div:nth-child(5),#font-in-box>div:nth-child(6){
    display:none;
}
#font-in-box .font2-in-box{
    position: absolute;
    width:669px;
    height:143px;
    margin:80px auto 0;
    border-radius:2px;
    left:50%;
    transform: translateX(-50%);
}
#font-in-box .font2-in-box a{
    text-decoration: none;
    display:block;
    position: relative;
    width:665px;
    line-height:139px;
    margin:0 auto;
    border-radius:2px;
    background:#fff;
    text-align:center;
    border: 2px solid #8f8f8f;
    font-weight:500;
    letter-spacing: 0px;
    color: #292929;
    font-size: 40px;
    top:0;
    left:0;
    right:0;
    transform: translateX(0%);
}
    #font-in-box .font2-in-box a:hover{
        background:#dd1d29;
		border: 2px solid #dd1d29;
        color: #fff;
    }
    #font-in-box .font2-in-box a:before{
        content: '前往新闻中心';
    }
    #font-in-box .font2-in-box a::after, #font-in-box .font2-in-box a:hover::after{
		width: 70px;
        height: 61px;
		background-size: 38px 22px;
        top: 13px;
    }
}*/
</style>
