<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>功能特点：</h2>
					<div class="textDetails figureweight">
						<p class="figure figure1">硬件资源可便捷地加入和退出云平台，提供硬件资源的横向弹性扩展和负载均衡功能，支持大规模业务应用部署。</p>
						<p class="figure figure2">根据系统规模动态调整各类资源配置，按需分配提高资源利用率。</p>
						<p class="figure figure3">集中管理硬件资源，通过完善的监控系统实时获取硬件资源的使用状态，并提供硬件和虚拟机故障的预警机制。</p>
						<p class="figure figure4">凭借凝思安全机制和技术，充分保障服务器主机安全，构建符合等级保护第四级要求的安全计算环境。</p>
						<p class="figure figure5">提供平台内部网络安全防护和对外网络的安全保护措施，防止信息泄露、截获和篡改，保证数据传输的安全可靠。</p>
						<p class="figure figure6">提供云服务器和云数据库整体运行环境，只要启动运行相应服务即可完成，方便易用。</p>
						<p class="figure figure7">通过自动化部署框架和模板，实现应用的一键部署和维护，简便高效。</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'CloudComputing',
}
</script>
<style scoped>

</style>
