<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> --> 
		<div class="font" id="newsdetail">
			<div class="title">
				<h1>
					<span v-html="News.tittle"></span>
				</h1>
				<div id="info">
					<router-link
						:to="{path:'/AboutLinx/News',query:{title4:getKeyRouter(News.tag)}}"
						class="tags"
					>{{News.tag}}</router-link>
					<span>｜日期：{{News.time}}</span>
				</div>
			</div>
			<div class="details">
				<template v-for="(content,index) in News.content">
					<p :key="index + '-1'" v-show="!content.src && !content.leftsrc" v-html="content"></p>
					<figure :key="index + '-2'" v-show="content.src">
						<img  :src="content.src" :alt="content.alt">
						<!-- <p>{{content.alt}}</p> -->
					</figure>
					<figure :key="index + '-3'" v-show="content.leftsrc">
						<img class="imgtwo" :src="content.leftsrc" :alt="content.leftalt">
						<img class="imgtwo" :src="content.rightsrc" :alt="content.rightalt">
						<!-- <p>{{content.alt}}</p> -->
					</figure>
				</template>
				<div class="sign">
					<p v-for="(sign,indexsign) in News.sign" :key="indexsign">{{sign}}</p>
				</div>
				<div class="footnote" v-show="News.footnote">
					<template v-for="(footnote,indexfootnote) in News.footnote">
						<h4 :key="indexfootnote + '-1'">*{{footnote.title}}：</h4>
						<p :key="indexfootnote + '-2'" v-html="footnote.text"></p>
					</template>
				</div>
				<div class="adjunct" v-show="News.adjunct">
					<h4>附</h4>
					<p v-for="(adjunct,indexadjunct) in News.adjunct" :key="indexadjunct" v-html="adjunct"></p>
				</div>
				<div class="fromAndExamine">
					<p>新闻来源：{{News.form}}</p>
					<p v-if='News.examine'>编辑审核：{{News.examine}}</p>
				</div>
			</div>
		</div>
		<div class="Turn">
			<div v-show="NewsLast"><span>上一篇：</span><router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:NewsLast.id}}" v-html="NewsLast.tittle" class="last"></router-link></div>
			<div v-show="NewsNext"><span>下一篇：</span><router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:NewsNext.id}}" v-html="NewsNext.tittle" class="next"></router-link></div>
		</div>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
	name:'NewsDetails',
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			natittle1: this.$attrs.tittle,
			natittle2: '新闻中心',
			natittle3: '新闻详情',
			id: this.$route.query.id,
			NewsList:[],
			News:[],
			NewsLast:'',
			NewsNext:'',
		}
	},
	computed:{
		...mapState({
			newsDataList:(state)=>state.News.newsDataList,
		})
	},
	methods:{
		getKeyRouter(val){
			if (val == '要闻'){
				return 1;
			} else if (val == '资质'){
				return 2;
			} else if (val == '项目'){
				return 3;
			} else if (val == '人物'){
				return 4;
			} else if (val == '慈善'){
				return 5;
			} else if (val == '荣誉'){
				return 6;
			} else if (val == '公告'){
				return 7;
			}
		}
	},
	mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
	watch:{
		NewsList:{
			immediate:true,
			handler(){
				let n = 0;
				for(let i=0;i<this.newsDataList.length;i++){
					for(let j=0;j<this.newsDataList[i].text.length;j++){
						this.NewsList[n] = this.newsDataList[i].text[j];
						if(this.newsDataList[i].text[j].id == this.id){
							this.News = this.NewsList[n];
							var m = n;
						}
						n++;
					}
				}
				if(m == 0){
					this.NewsLast = this.NewsList[m+1];
					this.NewsNext = false;
				}else if(m == (n - 1)){
					this.NewsLast = false;
					this.NewsNext = this.NewsList[m-1];
				}else{
					this.NewsLast = this.NewsList[m+1];
					this.NewsNext = this.NewsList[m-1];
				}
			}
		},
		$route:{
			immediate:true,
			handler(){
				this.id = this.$route.query.id;
				let n = 0;
				for(let i=0;i<this.newsDataList.length;i++){
					for(let j=0;j<this.newsDataList[i].text.length;j++){
						this.NewsList[n] = this.newsDataList[i].text[j];
						if(this.newsDataList[i].text[j].id == this.id){
							this.News = this.NewsList[n];
							var m = n;
						}
						n++;
					}
				}
				if(m == 0){
					this.NewsLast = this.NewsList[m+1];
					this.NewsNext = false;
				}else if(m == (n-1)){
					this.NewsLast = false;
					this.NewsNext = this.NewsList[m-1];
				}else{
					this.NewsLast = this.NewsList[m+1];
					this.NewsNext = this.NewsList[m-1];
				}
			}
		}
	}
}
</script>
<style scoped>
#newsdetail{
	width:100%;
	margin:0;
}
#newsdetail > .title h1{
	line-height:22px;
	margin-bottom:16px;
}
#newsdetail .title #info{
    width: 100%;
    margin:  0px auto 24px;
    text-align: center;
}
#newsdetail .title #info a,#newsdetail .title #info span,#newsdetail .title #info span b{
    font-size: 14px;
    line-height:14px;
    letter-spacing: 0px;
    font-weight: 300; 
    color: #565d69;
}
#newsdetail .title #info a:hover{
    color: rgb(221,29,41);
}
#newsdetail > .details figure{
	margin: 32px auto;
	text-align: center;
}
#newsdetail > .details figure img{
	display: inline-block;
	max-width: 600px;
	max-height: 650px;
}
#newsdetail > .details figure .imgtwo{
	height: 300px;
}
#newsdetail > .details figure p{
	font-size: 14px;
	line-height: 18px;
	margin: 0;
	position: relative;
	top: 8px;
	color: #8d9198;
}
#newsdetail > .details .sign{
	margin: 32px 0;
	text-align: right;
}
#newsdetail > .details .footnote, #newsdetail > .details .adjunct{
	margin: 68px 0 32px;
	position: relative;
}
#newsdetail > .details .footnote:before, #newsdetail > .details .adjunct:before{
	position: absolute;
	content: '';
	width: 124px;
	height: 1px;
	background: #262b33;
	top: 0;
}
#newsdetail > .details .footnote h4, #newsdetail > .details .adjunct h4{
	display: block;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 34px;
	color: #262b33;
	padding: 12px 24px 8px;
}
#newsdetail > .details .footnote p,#newsdetail > .details .adjunct p{
	color: #8d9198;
	margin: 0 24px;
	text-indent: 0;
}
#newsdetail > .details .fromAndExamine p{
		color: #8d9198;
	}
#newsdetail > .details p >>> b {
	font-weight: 500;
}
/* 翻页 */
.Turn{
    width: 100%;
    margin-top: 15px;
    margin-bottom: -9px;
    /* background-color: aquamarine; */
}
.Turn div span{
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 34px;
    font-weight: 400; 
    color: #8f8f8f;
}
.Turn div a{
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 34px;
    font-weight: 400; 
    color: #262b33;
}
.Turn div a:hover{
    color: rgb(221,29,41);
}
/*手机端*/
@media only screen and (max-width:750px){
	#newsdetail{
		margin:0 15px;
		width:calc(100% - 30px);
	}
	#newsdetail > .details{
		margin-bottom: 52px;
	}
	#newsdetail > .details figure img{
		max-width: 100%;
		max-height: 650px;
	}
	#newsdetail > .details .footnote h4, #newsdetail > .details .adjunct h4{
		font-size: 14px;
		line-height: 30px;
		padding: 12px 24px 4px;
	}
	.Turn{
		margin:0 15px;
		width:calc(100% - 30px);
		border-top:0.5px solid #e0e0e0;
	}
	.Turn div{
		padding-top:16px;
	}
	.Turn div+div{
		padding-top:28px;
	}
	.Turn div span,.Turn div a{
		font-size: 14px;
		line-height: 17px;
	}
}
</style>
