<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->   
		<SideCue :title4.sync="title4" :detailTittle.sync="detailTittle" :title4List="title4List"></SideCue>
		<Overview :title4.sync="title4" :detailTittle.sync="detailTittle" :title4List="title4List" v-show="title4 === 0"></Overview>
		<Particulars :title4.sync="title4" :detailTittle.sync="detailTittle" v-show="title4 !== 0 && title4 !== 4 && title4 !== 5"></Particulars>
		<!-- <Cve v-show="title4 === 5"></Cve> -->
	</div>
</template>

<script>
import Overview from './Overview.vue'
import SideCue from './SideCue.vue'
import Particulars from './Particulars.vue'
import Cve from './Cve.vue'

export default {
	name:'CommonQuestions',
	components:{
		Overview,
		SideCue,
		Particulars,
		Cve
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '常见问题',
			natittle3: '常见问题总览',
			title4: 0,
			detailTittle: '',
			icon: require('../images/icon-软件下载.png'),
			title4List: [
				{
					tittle:'常见问题总览'
				},
				{
					tittle:'驱动安装类',
					text:[
						{tittle:'安装nvidia显卡驱动'},
					]
				},
				{
					tittle:'软件服务类',
					text:[
						{tittle:'60系统常用服务启停'},
						{tittle:'60系统单网口网络配置'},
						{tittle:'60系统bond网络配置'},
						{tittle:'80系统常用服务启停'},
						{tittle:'80系统单网口网络配置'},
						{tittle:'80系统bond网络配置'},
						{tittle:'路由设置'},
						{tittle:'网络故障排查流程'},
						{tittle:'cron服务配置'},
						{tittle:'nfs配置单网口网络配置'},
						{tittle:'NTP对时配置'},
						{tittle:'ssh免密登陆配置'},
						{tittle:'ssh允许管理员登录设置'},
						{tittle:'tcpdump使用方法'},
						{tittle:'vim使用介绍'},
					]
				},
				{
					tittle:'系统使用与配置类',
					text:[
						{tittle:'60系统关闭numa功能'},
						//{tittle:'60系统镜像源配置'},
						{tittle:'60系统忘记root密码'},
						//{tittle:'80系统镜像源配置'},
						{tittle:'80系统忘记root密码'},
						{tittle:'串口使用方法'},
						//{tittle:'凝思官网源配置'},
						{tittle:'凝思系统进入单用户模式'},
						{tittle:'凝思系统设置字符编码'},
						{tittle:'凝思系统修改时间'},
						{tittle:'声卡配置'},
						{tittle:'修改网卡速率'},
						{tittle:'修改主机名'},
						{tittle:'parted分区以及挂载操作'},
					]
				},
				// {
				// 	tittle:'CVE'
				// },
			]
			
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>

</style>
