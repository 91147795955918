<!-- 首页-回到顶部 -->
<template>
	<div class="return" v-show="noTop > 300">
		<!-- <a href="javascript:;" id="notice" @click="noticeView = !noticeView" v-show="noticeHide"></a> -->
		<a @click="backTop" id="return-top"></a>
		<!-- <div id="notice-down" v-show="noticeView">
			<div id="new-name">凝思双机热备软件（适用于凝思安全操作系统V6.0.80 non-root环境）V1.0.0.20190410</div>
			<router-link to="" id="down">了解详情</router-link>
			<a href="javascript:;" id="cancel" @click="noticeView = !noticeView">稍后</a>
		</div> -->
	</div>
</template>

<script>
export default {
    name: 'Return',
    data() {
        return {
            noticeView: false,
            noticeHide: 0,
            noTop: 0,
            route: '',
        }
    },
    methods:{
        backTop() {
			document.querySelector('#top').scrollIntoView({
		        behavior:"smooth",
		        block:"start",
		    });
        }
    },
    mounted() {
        window.addEventListener('scroll',()=>{this.noTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset})
    },
    watch:{
        $route:{
            immediate:true,
            handler(){
                this.route = this.$route.path;
                if(this.route == '/Home'){
                    this.noticeHide = 1;
                } else {
                    this.noticeHide = 0;
                }
            }
        }
    }
}
</script>

<style scoped>
/*pc*/
.return #notice{
    position: fixed;
    display:block;
    width: 48px;
    height: 48px;
    bottom: 80px;
    right: 20px;
    border-radius: 4px;
    border: 1px #e0e0e0 solid;
    transition: border .3s;
    background: url(./images/通知静默.png) no-repeat;
    background-position: center center;
    z-index: 9999;
}
.return #notice:hover{
    border: 1px #dd1d29 solid;
}
.return #notice-down{
    position: fixed;
    width: calc(100% - 6px);
    height: 144px;
    bottom: 0px;
    background-color: #fff;
    border: 3px solid #dd1d29;
    z-index: 9999;
}
.return #notice-down #new-name{
    position: absolute;
    width: calc(100% - 160px);
    padding:0 80px;
    text-align: center;
    top:43px;
    font-size: 16px;
    color: #0f0f0f;
}
.return #notice-down #down,.return #notice-down #cancel{
    position: absolute;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    width: 104px;
    height: 33px;
    border: 2px solid #dd1d29;
    border-radius: 0px;
    bottom: 20px;
    color: #dd1d29;
    background-color:#fff;
    transition: color .15s,background-color .15s;
    font-weight:500;
}
.return #notice-down #down{
    left: calc(50% - 118px);
}
.return #notice-down #cancel{
    left: calc(50% + 12px);
}

.return #notice-down #down:hover,.return #notice-down #cancel:hover{
    background-color: #dd1d29;
    color: #fff;
    transition: color .15s,background-color .15s;
}

.return #return-top{
    position: fixed;
    width: 48px;
    height: 48px;
    bottom: calc(50vh - 24px);
    right: 10px;
    border-radius: 4px;
    border: 1px #e0e0e0 solid;
    background: url(./images/返回顶部静默.png) no-repeat center center;
    transition: border .3s;
    z-index: 9999;
    cursor:pointer;
}
.return #return-top:hover{
    border: 1px #dd1d29 solid;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
.return #notice{
    bottom: 20px;
}
.return #return-top{
    display:none;
}
}
/*手机端*/
@media only screen and (max-width:750px){
.return #notice{
    position: fixed;
    display:block;
    width: 57px;
    height: 57px;
    bottom: 64px;
    right: 15px;
    border-radius: 0;
    border: 0px #e0e0e0 solid;
    transition: border .3s;
    background: url(./images/通知-手机端.png) no-repeat;
    background-size: 57px;
    background-position: center center;
    z-index: 9999;
}
.return #notice:hover{
    border: 0px #dd1d29 solid;
}
.return #notice-down{
    position: fixed;
    width: calc(100% - 5px);
    height: auto;
    padding:25px 0 33px;
    bottom: 0px;
    background-color: #fff;
    border: 2.5px solid #dd1d29;
    z-index: 9999;
}
.return #notice-down #new-name{
    position: relative;
    width: 306px;
    padding:0;
    margin:0 auto 25px;
    text-align: center;
    top:0px;
    font-size: 15px;
    line-height: 19px;
    color: #0f0f0f;
}
.return #notice-down #down,.return #notice-down #cancel{
    display:block;
    margin:6px auto;
    position: relative;
    font-size: 12px;
    line-height: 28px;
    font-weight:500;
    text-align: center;
    width: 258px;
    height: 28px;
    border: 0px solid #dd1d29;
    border-radius: 0px;
    bottom: 0px;
    background-color: #dd1d29;
    color: #fff;
    transition: color .15s,background-color .15s;
}
.return #notice-down #down{
    left: 0;
}
.return #notice-down #cancel{
    left: 0;
}
.return #notice-down #down:hover,.return #notice-down #cancel:hover{
    background-color: #dd1d29;
    color: #fff;
    transition: color .15s,background-color .15s;
}
}



/*手机端1080*/
/* @media only screen and (min-height:1300px){
.return #notice{
    position: fixed;
    display:block;
    width: 164px;
    height: 164px;
    bottom: 340px;
    right: 44px;
    border-radius: 0;
    border: 0px #e0e0e0 solid;
    transition: border .3s;
    background: url(./images/通知-手机端1080.png) no-repeat;
    background-position: center center;
    z-index: 9999;
}
.return #notice:hover{
    border: 0px #dd1d29 solid;
}
.return #notice-down{
    position: fixed;
    width: calc(100% - 16px);
    height: auto;
    padding:66px 0 82px;
    bottom: 0px;
    background-color: #fff;
    border: 8px solid #dd1d29;
    z-index: 9999;
}
.return #notice-down #new-name{
    position: relative;
    width: 856px;
    padding:0;
    margin:0 auto 58px;
    text-align: center;
    top:0px;
    font-size: 48px;
    line-height: 64px;
    color: #0f0f0f;
}
.return #notice-down #down,.return #notice-down #cancel{
    display:block;
    margin:16px auto;
    position: relative;
    font-size: 42px;
    line-height: 92px;
    font-weight:500;
    text-align: center;
    width: 742px;
    height: 92px;
    border: 0px solid #dd1d29;
    border-radius: 0px;
    bottom: 0px;
    background-color: #dd1d29;
    color: #fff;
    transition: color .15s,background-color .15s;
}
.return #notice-down #down{
    left: 0;
}
.return #notice-down #cancel{
    left: 0;
}
.return #notice-down #down:hover,.return #notice-down #cancel:hover{
    background-color: #dd1d29;
    color: #fff;
    transition: color .15s,background-color .15s;
}
} */
</style>
