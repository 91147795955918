<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
            <div class="logoDisplay">
            	<div
            		v-for="(item,index) in PartnerList"
					:key="index + '-logo'"
				>
            		<img :src="item.imgUrl" :alt="item.imgAlt">
            	</div>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	name:'EcologicalPartner',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '生态伙伴',
			title4: 0,
			icon: require('../images/生态伙伴.png'),
			title4List: [
				{
					tittle:'生态伙伴',
				}
			],
			PartnerList: [
	    		{
				imgUrl: require('/src/images/logo/华为.png'),
				imgAlt:'华为',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中科曙光.png'),
				imgAlt:'中科曙光',
	    		},
	    		{
				imgUrl: require('/src/images/logo/联想.png'),
				imgAlt:'联想',
	    		},
	    		{
				imgUrl: require('/src/images/logo/新华三.png'),
				imgAlt:'新华三',
	    		},
	    		{
				imgUrl: require('/src/images/logo/浪潮.png'),
				imgAlt:'浪潮',
	    		},
	    		{
				imgUrl: require('/src/images/logo/FiberHome.png'),
				imgAlt:'FiberHome',
	    		},
	    		{
				imgUrl: require('/src/images/logo/宝德.png'),
				imgAlt:'宝德',
	    		},
	    		{
				imgUrl: require('/src/images/logo/长城.png'),
				imgAlt:'长城',
	    		},
	    		{
				imgUrl: require('/src/images/logo/长江计算.png'),
				imgAlt:'长江计算',
	    		},
	    		{
				imgUrl: require('/src/images/logo/清华同方.png'),
				imgAlt:'清华同方',
	    		},
				{
				imgUrl: require('/src/images/logo/研華.png'),
				imgAlt:'研華',
	    		},
	    		{
				imgUrl: require('/src/images/logo/紫光恒越.png'),
				imgAlt:'紫光恒越',
	    		},
				{
					imgUrl: require('/src/images/logo/中科可控.png'),
					imgAlt:'中科可控',
	    		},
				{
					imgUrl: require('/src/images/logo/中科腾龙.png'),
					imgAlt:'中科腾龙',
	    		},
				{
					imgUrl: require('/src/images/logo/宁畅.png'),
					imgAlt:'宁畅',
	    		},
				{
					imgUrl: require('/src/images/logo/熠知.png'),
					imgAlt:'熠知',
	    		},
	    		{
					imgUrl: require('/src/images/logo/澜起.png'),
					imgAlt:'澜起',
	    		},
				{
					imgUrl: require('/src/images/logo/东海.png'),
					imgAlt:'东海',
	    		},
	    		{
				imgUrl: require('/src/images/logo/景嘉微.png'),
				imgAlt:'景嘉微',
	    		},
	    		{
				imgUrl: require('/src/images/logo/鲲鹏.png'),
				imgAlt:'鲲鹏',
	    		},
	    		{
				imgUrl: require('/src/images/logo/龙芯中科.png'),
				imgAlt:'龙芯中科',
	    		},
	    		{
				imgUrl: require('/src/images/logo/兆芯.png'),
				imgAlt:'兆芯',
	    		},
				{
				imgUrl: require('/src/images/logo/申威.png'),
				imgAlt:'申威',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中科海光.png'),
				imgAlt:'中科海光',
	    		},
	    		{
				imgUrl: require('/src/images/logo/飞腾2.png'),
				imgAlt:'飞腾',
	    		},
	    		{
				imgUrl: require('/src/images/logo/达梦数据库.png'),
				imgAlt:'达梦数据库',
	    		},
	    		{
				imgUrl: require('/src/images/logo/南大通用.png'),
				imgAlt:'南大通用',
	    		},
	    		{
				imgUrl: require('/src/images/logo/神舟通用.png'),
				imgAlt:'神舟通用',
	    		},
	    		{
				imgUrl: require('/src/images/logo/东方通.png'),
				imgAlt:'东方通',
	    		},
	    		{
				imgUrl: require('/src/images/logo/普元.png'),
				imgAlt:'普元',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中创中间件.png'),
				imgAlt:'中创',
	    		},
	    		{
				imgUrl: require('/src/images/logo/金蝶.png'),
				imgAlt:'金蝶',
	    		},
	    		{
				imgUrl: require('/src/images/logo/人大金仓.png'),
				imgAlt:'人大金仓',
	    		},
	    		{
				imgUrl: require('/src/images/logo/宝兰德.png'),
				imgAlt:'宝兰德',
	    		},
	    		{
				imgUrl: require('/src/images/logo/瀚高.png'),
				imgAlt:'瀚高',
	    		},
	    		{
				imgUrl: require('/src/images/logo/易鲸捷.png'),
				imgAlt:'易鲸捷',
	    		},
	    		{
				imgUrl: require('/src/images/logo/巨杉数据库.png'),
				imgAlt:'巨杉数据库',
	    		},
	    		{
				imgUrl: require('/src/images/logo/海量数据.png'),
				imgAlt:'海量数据',
	    		},
	    		{
				imgUrl: require('/src/images/logo/山石网科.png'),
				imgAlt:'山石网科',
	    		},
	    		{
				imgUrl: require('/src/images/logo/启明星辰.jpg'),
				imgAlt:'启明星辰',
	    		},
	    		{
				imgUrl: require('/src/images/logo/东软.png'),
				imgAlt:'东软',
	    		},
	    		{
				imgUrl: require('/src/images/logo/金山安全.jpg'),
				imgAlt:'金山安全',
	    		},
	    		{
				imgUrl: require('/src/images/logo/虚谷伟业.png'),
				imgAlt:'虚谷伟业',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中铁信息工程园.png'),
				imgAlt:'中铁信息工程园',
	    		},
	    		{
				imgUrl: require('/src/images/logo/华电众信.png'),
				imgAlt:'华电众信',
	    		},
	    		{
				imgUrl: require('/src/images/logo/五舟.png'),
				imgAlt:'五舟',
	    		},
	    		{
				imgUrl: require('/src/images/logo/珠海鸿瑞.png'),
				imgAlt:'珠海鸿瑞',
	    		},
	    		{
				imgUrl: require('/src/images/logo/Nematron.png'),
				imgAlt:'Nematron',
	    		},
	    		{
				imgUrl: require('/src/images/logo/辰光融信.png'),
				imgAlt:'辰光融信',
	    		},
	    		{
				imgUrl: require('/src/images/logo/超云.png'),
				imgAlt:'超云',
	    		},
	    		{
				imgUrl: require('/src/images/logo/搜狗输入法.png'),
				imgAlt:'搜狗输入法',
	    		},
	    		{
				imgUrl: require('/src/images/logo/思必驰.png'),
				imgAlt:'思必驰',
	    		},
	    		{
				imgUrl: require('/src/images/logo/Fujifilm.png'),
				imgAlt:'Fujifilm',
	    		},
	    		{
				imgUrl: require('/src/images/logo/希云.png'),
				imgAlt:'希云',
	    		},
	    		{
				imgUrl: require('/src/images/logo/德派.png'),
				imgAlt:'德派',
	    		},
	    		{
				imgUrl: require('/src/images/logo/CSTSCK.png'),
				imgAlt:'CSTSCK',
	    		},
	    		{
				imgUrl: require('/src/images/logo/杭州慧瑞信息技术有限公司.png'),
				imgAlt:'杭州慧瑞信息技术有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/杭州立思辰安科科技有限公司.png'),
				imgAlt:'杭州立思辰安科科技有限公司',
	    		},
	    		/*{
				imgUrl: require('/src/images/logo/凌腾科技.png'),
				imgAlt:'凌腾科技',
	    		},*/
	    		{
				imgUrl: require('/src/images/logo/深圳吉斯凯达.png'),
				imgAlt:'深圳吉斯凯达',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京猎鹰安全科技有限公司.png'),
				imgAlt:'北京猎鹰安全科技有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/安超.png'),
				imgAlt:'安超',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京安帝科技有限公司.png'),
				imgAlt:'北京安帝科技有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京可信华泰信息技术有限公司.png'),
				imgAlt:'北京可信华泰信息技术有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京神州慧安科技有限公司.png'),
				imgAlt:'北京神州慧安科技有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京腾凌科技有限公司.png'),
				imgAlt:'北京腾凌科技有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京亚控科技发展有限公司.png'),
				imgAlt:'北京亚控科技发展有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/北京优炫软件股份有限公司.png'),
				imgAlt:'北京优炫软件股份有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/长扬科技(北京)有限公司.png'),
				imgAlt:'长扬科技(北京)有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/科蓝.png'),
				imgAlt:'科蓝',
	    		},
	    		{
				imgUrl: require('/src/images/logo/珞安科技.png'),
				imgAlt:'珞安科技',
	    		},
	    		{
				imgUrl: require('/src/images/logo/上海网强软件有限公司.png'),
				imgAlt:'上海网强软件有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/华雁.png'),
				imgAlt:'华雁',
	    		},
	    		{
				imgUrl: require('/src/images/logo/深信服科技.png'),
				imgAlt:'深信服科技',
	    		},
	    		{
				imgUrl: require('/src/images/logo/深圳市蓝凌软件股份有限公司.png'),
				imgAlt:'深圳市蓝凌软件股份有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/深圳市软派智能技术有限公司.png'),
				imgAlt:'深圳市软派智能技术有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/四川精容数安科技有限公司.png'),
				imgAlt:'四川精容数安科技有限公司',
	    		},
	    		{
				imgUrl: require('/src/images/logo/泰腾恩.png'),
				imgAlt:'泰腾恩',
	    		},
	    		{
				imgUrl: require('/src/images/logo/天地和兴.png'),
				imgAlt:'天地和兴',
	    		},
	    		{
				imgUrl: require('/src/images/logo/天融信.png'),
				imgAlt:'天融信',
	    		},
	    		{
				imgUrl: require('/src/images/logo/联瑞.png'),
				imgAlt:'联瑞',
	    		},
	    		{
				imgUrl: require('/src/images/logo/smartx.png'),
				imgAlt:'smartx',
	    		},
	    		{
				imgUrl: require('/src/images/logo/云宏.png'),
				imgAlt:'云宏',
	    		},
	    		{
				imgUrl: require('/src/images/logo/瑞星.png'),
				imgAlt:'瑞星',
	    		},
	    		{
				imgUrl: require('/src/images/logo/威努特.png'),
				imgAlt:'威努特',
	    		},
	    		{
				imgUrl: require('/src/images/logo/海颐安全.png'),
				imgAlt:'海颐安全',
	    		},
	    		{
				imgUrl: require('/src/images/logo/IBM.png'),
				imgAlt:'IBM',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中兴.png'),
				imgAlt:'中兴',
	    		},
	    		{
				imgUrl: require('/src/images/logo/奥星贝斯.png'),
				imgAlt:'奥星贝斯',
	    		},
	    		{
				imgUrl: require('/src/images/logo/数安行.png'),
				imgAlt:'数安行',
	    		},
	    		{
				imgUrl: require('/src/images/logo/盈泽世纪.png'),
				imgAlt:'盈泽世纪',
	    		},
	    		{
				imgUrl: require('/src/images/logo/中盈.png'),
				imgAlt:'中盈',
	    		},
	    		{
				imgUrl: require('/src/images/logo/东方金信.png'),
				imgAlt:'东方金信',
	    		},
				{
				imgUrl: require('/src/images/logo/泛微.png'),
				imgAlt:'泛微',
	    		},
				{
				imgUrl: require('/src/images/logo/金篆信科.png'),
				imgAlt:'金篆信科',
	    		},
				{
				imgUrl: require('/src/images/logo/康顿数据.png'),
				imgAlt:'康顿数据',
	    		},
				{
				imgUrl: require('/src/images/logo/连用科技.png'),
				imgAlt:'连用科技',
	    		},
				{
				imgUrl: require('/src/images/logo/前海伟创.png'),
				imgAlt:'前海伟创',
	    		},
				{
				imgUrl: require('/src/images/logo/融安.png'),
				imgAlt:'融安',
	    		},
				{
				imgUrl: require('/src/images/logo/天华星航.png'),
				imgAlt:'天华星航',
	    		},
				{
				imgUrl: require('/src/images/logo/听云.png'),
				imgAlt:'听云',
	    		},
				{
				imgUrl: require('/src/images/logo/询星.png'),
				imgAlt:'询星',
	    		},
				{
				imgUrl: require('/src/images/logo/亚信.png'),
				imgAlt:'亚信',
	    		},
				{
				imgUrl: require('/src/images/logo/易联通达.png'),
				imgAlt:'易联通达',
	    		},
				{
				imgUrl: require('/src/images/logo/优刻得.png'),
				imgAlt:'优刻得',
	    		},
				{
				imgUrl: require('/src/images/logo/云涌.png'),
				imgAlt:'云涌',
	    		},
				{
				imgUrl: require('/src/images/logo/天玑科技.png'),
				imgAlt:'天玑科技',
	    		},
				{
				imgUrl: require('/src/images/logo/天玑数据.png'),
				imgAlt:'天玑数据',
	    		},
				{
				imgUrl: require('/src/images/logo/芯瞳.png'),
				imgAlt:'芯瞳',
	    		},
				{
				imgUrl: require('/src/images/logo/云祺.png'),
				imgAlt:'云祺',
	    		},
				{
					imgUrl: require('/src/images/logo/360.png'),
					imgAlt:'360',
	    		},
				{
					imgUrl: require('/src/images/logo/弘正储能.png'),
					imgAlt:'弘正储能',
	    		},
				{
					imgUrl: require('/src/images/logo/华鲲振宇.jpg'),
					imgAlt:'华鲲振宇',
	    		},
				{
					imgUrl: require('/src/images/logo/华夏威科.png'),
					imgAlt:'华夏威科',
	    		},
				{
					imgUrl: require('/src/images/logo/万里开源.png'),
					imgAlt:'万里开源',
	    		},
				{
					imgUrl: require('/src/images/logo/中国海装.png'),
					imgAlt:'中国海装',
	    		},
				{
					imgUrl: require('/src/images/logo/合讯科技.png'),
					imgAlt:'合讯科技',
	    		},
				{
					imgUrl: require('/src/images/logo/绿盟.png'),
					imgAlt:'绿盟',
	    		},
				{
					imgUrl: require('/src/images/logo/思创医信.png'),
					imgAlt:'思创医信',
	    		},
				{
					imgUrl: require('/src/images/logo/同方知网.png'),
					imgAlt:'同方知网',
	    		},
				{
					imgUrl: require('/src/images/logo/未来智安.png'),
					imgAlt:'未来智安',
	    		},
				{
					imgUrl: require('/src/images/logo/壹医云.png'),
					imgAlt:'壹医云',
	    		},
				{
					imgUrl: require('/src/images/logo/百度.png'),
					imgAlt:'百度',
	    		},
				{
					imgUrl: require('/src/images/logo/大普微.png'),
					imgAlt:'大普微',
	    		},
				{
					imgUrl: require('/src/images/logo/京天威.png'),
					imgAlt:'京天威',
	    		},
				{
					imgUrl: require('/src/images/logo/奇安信.png'),
					imgAlt:'奇安信',
	    		},
				{
					imgUrl: require('/src/images/logo/启睿克.png'),
					imgAlt:'启睿克',
	    		},
				{
					imgUrl: require('/src/images/logo/仁和昌盛.png'),
					imgAlt:'仁和昌盛',
	    		},
				{
					imgUrl: require('/src/images/logo/网藤.png'),
					imgAlt:'网藤',
	    		},
				{
					imgUrl: require('/src/images/logo/云屋.png'),
					imgAlt:'云屋',
	    		},
				{
					imgUrl: require('/src/images/logo/盛宝.png'),
					imgAlt:'盛宝',
	    		},
				{
					imgUrl: require('/src/images/logo/沐创.png'),
					imgAlt:'沐创',
	    		},
				{
					imgUrl: require('/src/images/logo/圣博润.png'),
					imgAlt:'圣博润',
	    		},
				{
					imgUrl: require('/src/images/logo/信创桥.png'),
					imgAlt:'信创桥',
	    		},
				{
					imgUrl: require('/src/images/logo/浩辰.png'),
					imgAlt:'浩辰',
	    		},
				{
					imgUrl: require('/src/images/logo/云和恩墨.png'),
					imgAlt:'云和恩墨',
	    		},
				{
					imgUrl: require('/src/images/logo/凯发.png'),
					imgAlt:'凯发',
	    		},
				{
					imgUrl: require('/src/images/logo/六方云.png'),
					imgAlt:'六方云',
	    		},
				{
					imgUrl: require('/src/images/logo/涛思.png'),
					imgAlt:'涛思',
	    		},
				{
					imgUrl: require('/src/images/logo/易景.png'),
					imgAlt:'易景',
	    		},
				{
					imgUrl: require('/src/images/logo/航天联志.png'),
					imgAlt:'航天联志',
	    		},
				{
					imgUrl: require('/src/images/logo/惠而特.png'),
					imgAlt:'惠而特',
	    		},
				{
					imgUrl: require('/src/images/logo/建恒信安.png'),
					imgAlt:'建恒信安',
	    		},
				{
					imgUrl: require('/src/images/logo/快立方.png'),
					imgAlt:'快立方',
	    		},
				{
					imgUrl: require('/src/images/logo/源鲁.png'),
					imgAlt:'源鲁',
	    		},
				{
					imgUrl: require('/src/images/logo/爱捷云.png'),
					imgAlt:'爱捷云',
	    		},
				{
					imgUrl: require('/src/images/logo/积成电子.png'),
					imgAlt:'积成电子',
	    		},
				{
					imgUrl: require('/src/images/logo/齐安.png'),
					imgAlt:'齐安',
	    		},
				{
					imgUrl: require('/src/images/logo/瑞晟博.png'),
					imgAlt:'瑞晟博',
	    		},
				{
					imgUrl: require('/src/images/logo/易思捷.png'),
					imgAlt:'易思捷',
	    		},
				{
					imgUrl: require('/src/images/logo/远算.png'),
					imgAlt:'远算',
	    		},
				{
					imgUrl: require('/src/images/logo/沄熹科技.png'),
					imgAlt:'沄熹科技',
	    		},

				{
					imgUrl: require('/src/images/logo/东方旭能.png'),
					imgAlt:'东方旭能',
	    		},
				{
					imgUrl: require('/src/images/logo/格睿科技.png'),
					imgAlt:'格睿科技',
	    		},
				{
					imgUrl: require('/src/images/logo/硅格.png'),
					imgAlt:'硅格',
	    		},
				{
					imgUrl: require('/src/images/logo/普迅电力.png'),
					imgAlt:'普迅电力',
	    		},
				{
					imgUrl: require('/src/images/logo/思瑞奇.png'),
					imgAlt:'思瑞奇',
	    		},
				{
					imgUrl: require('/src/images/logo/云长.png'),
					imgAlt:'云长',
	    		},
				{
					imgUrl: require('/src/images/logo/中软国际透明logo.png'),
					imgAlt:'中软国际',
	    		},
				{
					imgUrl: require('/src/images/logo/安腾普.png'),
					imgAlt:'安腾普',
	    		},
				{
					imgUrl: require('/src/images/logo/苍穹数码.png'),
					imgAlt:'苍穹数码',
	    		},
				{
					imgUrl: require('/src/images/logo/鼎甲.png'),
					imgAlt:'鼎甲',
	    		},
				{
					imgUrl: require('/src/images/logo/湘江鲲鹏.png'),
					imgAlt:'湘江鲲鹏',
	    		},
				{
					imgUrl: require('/src/images/logo/英方.png'),
					imgAlt:'英方',
	    		},
				{
					imgUrl: require('/src/images/logo/云信达.png'),
					imgAlt:'云信达',
	    		},
				{
					imgUrl: require('/src/images/logo/紫金诚.png'),
					imgAlt:'紫金诚',
	    		},
				{
					imgUrl: require('/src/images/logo/常青云.png'),
					imgAlt:'常青云',
	    		},
				{
					imgUrl: require('/src/images/logo/东方威智.png'),
					imgAlt:'东方威智',
	    		},
				{
					imgUrl: require('/src/images/logo/实在智能.png'),
					imgAlt:'实在智能',
	    		},
				{
					imgUrl: require('/src/images/logo/奥拓电子.png'),
					imgAlt:'奥拓电子',
	    		},
				{
					imgUrl: require('/src/images/logo/得力.png'),
					imgAlt:'得力',
	    		},
				{
					imgUrl: require('/src/images/logo/中科弘云.png'),
					imgAlt:'中科弘云',
	    		},
				{
					imgUrl: require('/src/images/logo/神州灵云.png'),
					imgAlt:'神州灵云',
	    		},
				{
					imgUrl: require('/src/images/logo/威步.png'),
					imgAlt:'威步',
	    		},
				{
					imgUrl: require('/src/images/logo/福升威尔.png'),
					imgAlt:'福升威尔',
	    		},
				{
					imgUrl: require('/src/images/logo/国电联合.png'),
					imgAlt:'国电联合',
	    		},
				{
					imgUrl: require('/src/images/logo/青云.png'),
					imgAlt:'青云',
	    		},
				{
					imgUrl: require('/src/images/logo/同泰怡.png'),
					imgAlt:'同泰怡',
	    		},
				{
					imgUrl: require('/src/images/logo/英得赛克.png'),
					imgAlt:'英得赛克',
	    		},
				{
					imgUrl: require('/src/images/logo/中诚华隆.png'),
					imgAlt:'中诚华隆',
	    		},
				{
					imgUrl: require('/src/images/logo/北信源.png'),
					imgAlt:'北信源',
	    		},
				{
					imgUrl: require('/src/images/logo/和利时.png'),
					imgAlt:'和利时',
	    		},
				{
					imgUrl: require('/src/images/logo/联想云.png'),
					imgAlt:'联想云',
	    		},
				{
					imgUrl: require('/src/images/logo/青藤.png'),
					imgAlt:'青藤',
	    		},
				{
					imgUrl: require('/src/images/logo/易聆科.png'),
					imgAlt:'易聆科',
	    		},
				{
					imgUrl: require('/src/images/logo/云果.jpg'),
					imgAlt:'云果',
	    		},
				{
					imgUrl: require('/src/images/logo/云轴.png'),
					imgAlt:'云轴',
	    		},
				{
					imgUrl: require('/src/images/logo/中泰华电.png'),
					imgAlt:'中泰华电',
	    		},
				{
					imgUrl: require('/src/images/logo/慧点.png'),
					imgAlt:'慧点',
	    		},
				{
					imgUrl: require('/src/images/logo/星环.png'),
					imgAlt:'星环',
	    		},
				{
					imgUrl: require('/src/images/logo/赢达信.png'),
					imgAlt:'赢达信',
	    		},
				{
					imgUrl: require('/src/images/logo/智达数通.png'),
					imgAlt:'智达数通',
	    		},
				{
					imgUrl: require('/src/images/logo/中电安科.png'),
					imgAlt:'中电安科',
	    		},
				{
					imgUrl: require('/src/images/logo/中国电子云.png'),
					imgAlt:'中国电子云',
	    		},
				{
					imgUrl: require('/src/images/logo/中国系统.png'),
					imgAlt:'中国系统',
	    		},
				{
					imgUrl: require('/src/images/logo/广电五舟.png'),
					imgAlt:'广电五舟',
	    		},
				{
					imgUrl: require('/src/images/logo/火绒.png'),
					imgAlt:'火绒',
	    		},
				{
					imgUrl: require('/src/images/logo/九科.png'),
					imgAlt:'九科',
	    		},
				{
					imgUrl: require('/src/images/logo/昆仑.png'),
					imgAlt:'昆仑',
	    		},
				{
					imgUrl: require('/src/images/logo/浪潮云.png'),
					imgAlt:'浪潮云',
	    		},
				{
					imgUrl: require('/src/images/logo/芯动.png'),
					imgAlt:'芯动',
	    		},
				{
					imgUrl: require('/src/images/logo/亚信安慧.png'),
					imgAlt:'亚信安慧',
	    		},
				{
					imgUrl: require('/src/images/logo/灵达.png'),
					imgAlt:'灵达',
	    		},
				{
					imgUrl: require('/src/images/logo/大唐高鸿.png'),
					imgAlt:'大唐高鸿',
	    		},
				{
					imgUrl: require('/src/images/logo/菲力克.png'),
					imgAlt:'菲力克',
	    		},
				{
					imgUrl: require('/src/images/logo/华宇.png'),
					imgAlt:'华宇',
	    		},
				{
					imgUrl: require('/src/images/logo/美名.png'),
					imgAlt:'美名',
	    		},
				{
					imgUrl: require('/src/images/logo/天地（常州）自动化.png'),
					imgAlt:'天地（常州）自动化',
	    		},
				{
					imgUrl: require('/src/images/logo/优特.png'),
					imgAlt:'优特',
	    		},
				{
					imgUrl: require('/src/images/logo/左江.png'),
					imgAlt:'左江',
	    		}
			]
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
.logoDisplay{
	display: grid;
	width:calc(100% - 64px);
	grid-row-gap: 26px;
	justify-content:space-between;
	grid-template-columns: 190px 190px 190px 190px;
	margin:16px auto 26px; 
}
.logoDisplay div{
	width:190px;
	height:104px;
	box-shadow:rgba(05,05, 05, 0.1) 0px 0px 0px;
	transition: box-shadow 0.3s;
}
.logoDisplay div:hover{
	box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
}
.logoDisplay div img{
	display:block;
	position:relative;
	width:126px;
	object-fit:contain;
	max-height:72px;
	margin:0 auto;
	top: 50%;
	transform: translateY(-50%);
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.logoDisplay{
		grid-row-gap: 33px;
		grid-template-columns: 190px 190px 190px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.logoDisplay{
		display: grid;
		width:calc(100% - 30px);
		grid-row-gap: 20px;
		justify-content:space-between;
		grid-template-columns: 106px 106px 106px;
		margin:0 auto; 
	}
	.logoDisplay div{
		width:106px;
		height:65px;
		box-shadow:rgba(05,05, 05, 0.1) 0px 0px 0px;
		transition: box-shadow 0.3s;
	}
	.logoDisplay div:hover{
		box-shadow:rgba(05,05, 05, 0.1) 0px 1px 4px;
	}
	.logoDisplay div img{
		display:block;
		position:relative;
		width:76px;
		object-fit:contain;
		max-height:49px;
		margin:0 auto;
		top: 50%;
		transform: translateY(-50%);
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.logoDisplay{
		display: grid;
		width:calc(100% - 88px);
		grid-row-gap: 60px;
		justify-content:space-between;
		grid-template-columns: 305px 305px 305px;
		margin:0 auto; 
	}
	.logoDisplay div{
		width:305px;
		height:187px;
		box-shadow:rgba(05,05, 05, 0.1) 0px 0px 0px;
		transition: box-shadow 0.3s;
	}
	.logoDisplay div:hover{
		box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
	}
	.logoDisplay div img{
		display:block;
		position:relative;
		width:217px;
		object-fit:contain;
		max-height:139px;
		margin:0 auto;
		top: 50%;
		transform: translateY(-50%);
	}
} */
</style>
