<!-- 案例方案 -->
<template>
    <div>
		<TopOfPage :tittle="tittle" :path="path"></TopOfPage>
		<router-view :tittle="tittle"></router-view> 
    </div>
</template>

<script>
export default {
    name: 'Solutions',
	data() {
		return {
			tittle: "解决方案",
			path: "/Solutions"
		}
	},

}
</script>

<style>
#fontNoBorder > .tittlrImg {
	position: relative;
	padding: 0;
	height: 357px;
	line-height:0px;
	margin-top:52px;
}
#fontNoBorder > .shortTittlrImg{
	height: 227px;
}
.tittlrImgBorder{
	border-bottom:1px solid #e0e0e0;
}
#fontNoBorder > .tittlrImg b{
	display:block;
	position: absolute;
	line-height:74px;
	margin: 43px 57px;
	font-size: 36px;
	letter-spacing: 0px;
	font-weight: 500; 
	color: #fff;
	text-shadow:rgba(0,0, 0, 0.75) 0px 1px 1px ;
}
#fontNoBorder > .tittlrImg b:before{
	display:block;
	position: absolute;
	content:" ";
	width:13px;
	height:13px;
	border-left:3px solid #fff;
	border-top:3px solid #fff;
	left:-19px;
}
#fontNoBorder > .tittlrImg p{
	display:block;
	width:calc(100% - 58px - 58px);
	position: absolute;
	bottom:0;
	margin:0;
	padding:26px 58px;
	background-color:rgba(255,255,255,0.9);
	font-size: 20px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 32px;
	color: #262b33;
	text-indent: 0;
}
h1 + .column{
	margin:76px auto 32px; 
}
.column{
	display: grid;
	width:100%;
	grid-template-columns: 585px 585px;
	margin:60px auto 32px; 
}
.columngray{
	margin:32px auto 0; 
	position: relative;
	padding:60px calc(50vw - 585px) 32px;
	left:calc(585px - 50vw);
	background-color:#f5f5f5;
}
.columnImgFont+.columngray{
	margin:64px auto 0; 
	padding:48px calc(50vw - 585px) 32px;
}
.outColumn4{
	margin-bottom:104px;
}
.NoColumn{
	margin:104px 32px 78px; 
}
.NoColumn2{
	margin:44px 32px 75px; 
}
.column:last-child{
	padding-bottom:90px;
}
.column h1{
	width:484px;
	margin: 0 auto;
	font-size:20px;
	line-height:52px;
	font-weight: 500;
	color: #262b33;
	word-break:keep-all;
	transition: background .3s;
	background-color: rgba(0,0, 0, 0);
}
.column h2{
	width:484px;
	margin: 0 auto;
	line-height:24px;
	font-weight:600;
}
.column h2:before{
	display:inline-block;
	position: absolute;
	content:" ";
	width:26px;
	height:24px;
	left:-26px;
	background:url('../images/菱形.png') no-repeat center;
	background-size:10px 11px;
}
.column .novisical{
	opacity:0;
}
.column p{
	width:484px;
	margin: 14px auto auto;
	text-indent: 0;
}
.column h1+p{
	margin: 10px auto 0;
}
.column h3+p{
	margin: 4px auto 0;
}
.column p span{
	color: #0072ce;
}
.columnOutcountColumn p{
	font-size:20px;
	line-height:32px;
}
.column h3{
	width:484px;
	line-height:28px;
	font-weight: 500;
	color: #262b33;
	margin: 14px auto 0;
	text-indent: 0;
}
.column h3:before{
	display:none;
}
.column div .imgcolum{
	display: grid;
	width:484px;
	grid-template-columns: 161px 161px 161px;
	margin:32px auto 0; 
}
.column div .imgcolum div{
	text-align:center;
}
.column div .imgcolum div img{
	display:inline-block;
	height:40px;
	width:40px;
	margin-top:6px;
}
.column div .imgcolum div p{
	width:100%;
	margin-top:6px;
}
.column:last-child div:last-child:after,.outColumn4 .column4:after,.NoColumn:last-child div:last-child:after{
	content:"";
	display:block;
	position: absolute;
	width:13px;
	height:13px;
	border-right:3px solid #96999c;
	border-bottom:3px solid #96999c;
	right:calc(32px - 3px);
	bottom:calc(122px - 16px - 3px);
}
.column:last-child div:last-child:after{
	bottom:calc(182px - 68px - 3px);
}
.outColumn4 .column4:after{
	bottom:calc(142px - 68px - 3px);
}
.columngray:last-child div:last-child:after{
	right:calc(50vw - 585px + 32px - 3px);
	bottom:calc(142px - 48px - 3px);
}
.noBorder div:after{
	display:none!important;
}
.column div p:first-child{
	margin-top:-2px;
}
.column .countColumn{
	display: grid;
	margin:0 auto;
	grid-gap:0 105px;
	grid-template-columns: 206px 148px;
}
.column .countColumn > div{
	text-align:center;
	position: relative;
	white-space:nowrap;
}
.column .countColumn > div > .count-flop{
	padding-top:10px;
	padding-bottom:10px;
}
.column .countColumn > div > img{
	width:82px;
	height:77px;
	position: absolute;
}
.column .countColumn > div > p{
	width:100%;
	margin:0;
	font-size: 16px;
	line-height: 20px;
	color:#565d69;
	text-align:left;
	text-indent: 36px;
	word-break:keep-all;
}
.column .countColumn > div > div{
	padding-left:34px;
}
.column .countColumn > div > div:after{
	display:inline-block;
	content:" ";
	width:20px;
	height: 61px;
	margin-left:2px;
	background:url('../images/加号.png') no-repeat center top;
	background-size:20px 20px;
}

.outColumn4 h2{
	margin:0;
	font-size: 20px;
	line-height: 52px;
	text-indent: 32px;
}
.outColumn4 .column4{
	display: grid;
	width:100%;
	grid-template-columns: 292.5px 292.5px 292.5px 292.5px ;
}
.outColumn4 .column4 p{
	position:relative;
	text-indent: 0;
	text-align:left;
	margin:10px 32px 10px 50px;
}
.outColumn4 .column4 p:before{
	display:block;
	position: absolute;
	content:" ";
	width:18px;
	height:28px;
	left:-18px;
	background:url('../images/菱形.png') no-repeat left center;
	background-size:10px 11px;
}
.imgautomove{
	position:relative;
	margin:80px 0;
	height:571px;
	padding-top:44px;
	width:100%;
}
.imgautomove .moveblock{
	position: absolute;
	top:88px;
	height:571px;
	width:912px;
	z-index:9;
}
.imgautomove .moveblock .moveblockimg{
	position:relative;
	display:inline-block;
	width:356px;
	height:571px;
}
.imgautomove .moveblock .moveblockfont{
	float:right;
	display:block;
	width:556px;
	height:571px;
	background-color:#23252e;
}
.imgautomove .moveblock .moveblockfont h2{
	position: relative;
	margin: 40px 73px 0px 76px;
	font-size: 36px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 136px;
	color: #fff;
	text-indent: 0em;
}
.imgautomove .moveblock .moveblockfont p{
	position: relative;
	margin: 0px 73px 20px 76px;
	font-size: 20px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 32px;
	color: #fff;
	text-indent: 0em;
}
.imgautomove .staticblock{
	position: absolute;
	height:543px;
	width:186px;
	padding:14px 0 14px 848px;
	right:0;
	background-color:#f5f5f5;
}
.imgautomove .staticblock > div{ 
	width:186px;
	padding:20px 0;
	border-bottom:1px solid #e0e0e0;
}
.imgautomove .staticblock > div:last-child{
	border-bottom:none;
}
.column .countColumn > div > .count-flop{
	padding-top:10px;
	padding-bottom:10px;
}
.imgautomove .staticblock > div > .count-flop:after{
	position: absolute;
	display:inline-block;
	content:" ";
	width:20px;
	height: 61px;
	margin-left:10px;
	top:-10px;
	background:url('../images/加号.png') no-repeat center;
	background-size:20px 20px;
}
.imgautomove .staticblock > div:first-child > .count-flop:after{
	display:none;
}
.imgautomove .staticblock div p{ 
	position:relative;
	margin:0;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 36px;
	height: 36px;
	color:#565d69;
	text-indent: 0;
}
.NoColumn h2{
	margin:0;
	font-size: 20px;
	line-height: 60px;
	text-indent: 0;
}
.NoColumn p{
	margin:0;
	font-size: 16px;
	line-height: 40px;
	text-indent: 0;
}
.NoColumn div p{
	display:inline-block;
	width:107px;
	margin:0;
	font-size: 16px;
	line-height: 56px;
	text-indent: 0;
}
.columnImgFont figure{
	position:relative;
	width:555px;
	height:416px;
	margin:0 auto;
	overflow:hidden;
}
.columnImgFont figure img{
	position:relative;
	height:100%;
	left: 50%;
	transform: translateX(-50%);
}
.column .cornerBorder{
	position:relative;
}
.column .cornerBorder:before{
	content:"";
	display:block;
	position: absolute;
	width:13px;
	height:13px;
	border-right:3px solid #96999c;
	border-top:3px solid #96999c;
	right:0;
	top:0;
}
.column .cornerBorder:after{
	content:"";
	display:block;
	position: absolute;
	width:13px;
	height:13px;
	border-right:3px solid #96999c;
	border-bottom:3px solid #96999c;
	right:0;
	bottom:0;
}
.column .cornerBorder h1{
	margin: 0px 32px 6px;
	font-size: 36px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 92px;
	color: #262b33;
	text-indent: 0;
	background-color: rgba(0,0, 0, 0);
}
.column .cornerBorder p{
	width:auto;
	margin: 0 32px 20px;
}
.rhombusp p{
	position:relative;
	padding-left:18px;
	margin: 5px auto;
}

.rhombusp p b{
	font-weight:500;
}
.rhombusp div h1 + p{
	margin-top: 10px;
}
.rhombusp div p + p{
	margin-top: 20px;
}
.rhombusp p:before{
	display:block;
	position: absolute;
	content:" ";
	width:18px;
	height:40px;
	left:0px;
	background:url('../images/菱形.png') no-repeat left center;
	background-size:10px 11px;
}
.rhombusp div p:before{
	height:28px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#fontNoBorder{
		width: 100%;
		border: none;
		margin: 0 auto;
	}
	#fontNoBorder > .tittlrImg {
		position: relative;
		padding: 0;
		height: 522px;
		line-height:0px;
		margin-top:52px;
	}
	#fontNoBorder > .shortTittlrImg{
		height: 227px;
	}
	#fontNoBorder > .tittlrImg p{
		width:calc(100% - 32px - 32px);
		padding:22px 32px;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
		color: #262b33;
	}
	.column{
		position: relative;
		grid-template-columns: 50% 50%;
	}
	.columngray{
		padding:60px calc(50vw - 50%) 32px;
		left:calc(50% - 50vw);
	}
	.columnImgFont + .columngray{
		padding:60px calc(50vw - 50%) 32px;
	}
	.NoColumn{
		margin:60px 0px 78px; 
	}
	.NoColumn2{
		margin:44px 0px 75px; 
	}
	.columnImgFont{
		margin:52px 0 80px;
	}
	.column:last-child{
		margin-bottom: -56px;
		padding-bottom:80px;
	}
	.column > div:nth-child(2n){
		padding-left:30px;
	}
	.column h1,.column h2,.column p,.column h3+p,.column h1+p,.column h3{
		width:404px;
		margin-left:20px;
	}
	.columnOutcountColumn p{
		width:347px;
	}
	.column div .imgcolum{
		display: grid;
		width:576px;
		grid-template-columns: 192px 192px 192px;
		margin:32px auto 0; 
	}
	.column div .imgcolum div{
		text-align:center;
	}
	.column div .imgcolum div p{
		width:100%;
		margin:6px 0 28px;
	}
	.column:last-child div:last-child:after,.outColumn4 .column4:after,.NoColumn:last-child div:last-child:after{
		content:"";
		display:block;
		position: absolute;
		width:13px;
		height:13px;
		border-right:3px solid #96999c;
		border-bottom:3px solid #96999c;
		right:-16px;
		bottom:calc(112px - 16px - 3px);
	}
	.columngray:last-child div:last-child:after{
		right:calc(50vw - 452px - 16px - 3px);
	}
	.noBorder div h1, .noBorder div p{
		margin-left:0;
	}
	.noBorder div:nth-child(2n) h1, .noBorder div:nth-child(2n) p{
		margin-left:20px;
	}
	.noBorder div:after{
		display:none!important;
	}
	.column div p:first-child{
		margin-top:-2px;
	}
	.column .countColumn{
		grid-gap:0 64px;
	}
	.outColumn4 .column4{
		grid-template-columns: 301px 301px 301px;
	}
	.imgautomove{
		position:relative;
		margin:52px 0;
		height:707px;
		padding-top:0;
		width:100vw;
	}
	.imgautomove .moveblock{
		top:0;
		height:505px;
		width:904px;
	}
	.imgautomove .moveblock .moveblockimg{
		width:314px;
		height:505px;
	}
	.imgautomove .moveblock .moveblockfont{
		position:relative;
		width:590px;
		height:505px;
	}
	.imgautomove .moveblock .moveblockfont h2{
		margin: 40px 86px 0px 87px;
	}
	.imgautomove .moveblock .moveblockfont h2:before{
		content:"";
		display:block;
		position: absolute;
		width:13px;
		height:13px;
		border-top:3px solid #fff;
		border-left:3px solid #fff;
		left:-16px;
		top:30px;
	}
	.imgautomove .moveblock .moveblockfont p{
		margin: 0px 86px 20px 87px;
		font-size: 16px;
		letter-spacing: 0px;
		font-weight: 500;
		line-height: 28px;
		color: #fff;
		text-indent: 0em;
	}
	.imgautomove .staticblock{
		position: absolute;
		left:calc(452px - 50vw);
		height:148px;
		width:100%;
		padding:366px 0 0;
		bottom:0;
	}
	.imgautomove .staticblock > div{ 
		display:inline-block;
		width:256px;
		padding:26.5px 0;
		border-bottom:none;
		margin-left:-4px;
		border-right:1px solid #e0e0e0;
	}
	.imgautomove .staticblock > div:last-child{
		border-right:none;
	}
	.imgautomove .staticblock > div > .count-flop{
		margin-left:60px;
	}
	.imgautomove .staticblock > div > p{ 
		text-indent: 60px;
	}
	.imgautomove .staticblock > div:first-child{ 
		margin-left:calc(50vw - 512px);
	}
	.NoColumn div p{
		display:inline-block;
		width:96px;
		margin-bottom:4px;
		font-size: 16px;
		line-height: 40px;
		text-indent: 0;
	}
	.columnImgFont figure{
		position:relative;
		width:450px;
		height:472px;
		margin:0 auto;
		overflow:hidden;
	}
	.columnImgFont figure img{
		position:relative;
		height:100%;
		left: 50%;
		transform: translateX(-50%);
	}
	.column .cornerBorder:nth-child(2n){
		padding-left:0px;
	}
	.column .cornerBorder:before, .column .cornerBorder:after{
		right:-16px;
	}
	.column .cornerBorder h1{
		margin: 0px 1px 40px 56px;
		font-size: 36px;
		line-height: 36px;
	}
	.column .cornerBorder p{
		margin: 0 1px 20px 56px;
	}
	.rhombusp p{
		margin: 5px 0;
	}
	.rhombusp div:nth-child(2n){
		padding-left: 30px;
	}
	.rhombusp div h1{
		margin: 0;
	}
	.rhombusp div p{
		margin: 0;
		width:386px;
	}
	.rhombusp div:nth-child(2n){
		margin-left:0;
	}
	.padcharge1{
		grid-template-columns: 100%;
	}
	.padcharge1 div:nth-child(2n){
		padding-left: 0px;
	}
	.padcharge1 div p{
		width:auto;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > .tittlrImg {
		height: 416px;
	}
	#fontNoBorder > .shortTittlrImg {
		height: 227px;
	}
	#fontNoBorder > .tittlrImg p{
		font-weight: 500;
	}
	.column{
		grid-template-columns: 100%;
	}
	.columngray{
		padding:60px calc(50vw - 360px) 32px;
		left:calc(360px - 50vw);
	}
	.outColumn4{
		margin-bottom:104px;
	}
	.NoColumn{
		margin:60px 0 78px; 
	}
	.NoColumn2{
		margin:44px 0 75px; 
	}
	.columnImgFont{
		margin:52px 0 80px;
	}
	.column:last-child{
		margin-bottom: -58px;
		padding-bottom:80px;
	}
	.column h1{
		width:100%;
		margin: 0 auto;
		font-size:20px;
		line-height:52px;
		font-weight: 500;
		color: #262b33;
		word-break:keep-all;
		transition: background .3s;
	}
	.column .novisical{
		display:none;
	}
	.column p{
		width:100%;
		margin-left: 0;
	}
	.column h1,.column h2,.column h2~p,.column h3~p,.column h1+p,.column h3{
		width:calc(100% - 18px);
		margin-left:18px;
	}
	.column h2 + h3{
		margin-top:26px;
	}
	.column > div:nth-child(2n){
		padding-left: 0;
	}
	.divBorderbottom{
		border-bottom:1px solid #e0e0e0;
	}
	.column div .imgcolum{
		display: grid;
		width:484px;
		grid-template-columns: 161px 161px 161px;
		margin:32px auto 0; 
	}
	.column div .imgcolum div{
		text-align:center;
	}
	.column div .imgcolum div p{
		width:100%;
		margin-top:6px;
	}
	.column:last-child div:last-child:after,.outColumn4 .column4:after,.NoColumn:last-child div:last-child:after{
		right:0;
		bottom:calc(112px - 16px - 3px);
	}
	.columngray:last-child div:last-child:after{
		right:calc(50vw - 320px - 32px - 7px);
	}
	.noBorder div h1, .noBorder div p,.noBorder div:nth-child(2n) h1, .noBorder div:nth-child(2n) p{
		margin-left:0;
	}
	h1 + .columnOutcountColumn{
		margin-bottom:58px;
	}
	.columnOutcountColumn > div > p{
		position: relative;
		top: 147px;
		width:648px;
		margin: 0 auto 50px!important;
		font-size:18px;
		line-height:30px;
	}
	.column .countColumn{
		position: relative;
		top: -110px;
		grid-gap:0 120px;
		grid-template-columns: 209px 154px;
	}
	.outColumn4 h2{
		text-indent: 0;
	}
	.outColumn4 .column4{
		grid-gap:0 45px;
		grid-template-columns: 210px 210px 210px;
	}
	.outColumn4 .column4 p{
		position:relative;
		text-indent: 0;
		text-align:left;
		margin:10px 0px 10px 18px;
	}
	.outColumn4 .column4 p:before{
		display:block;
		position: absolute;
		content:" ";
		width:18px;
		height:28px;
		left:-18px;
		background:url('../images/菱形.png') no-repeat left center;
		background-size:10px 11px;
	}
	.imgautomove{
		height:736px;
	}
	.imgautomove .moveblock{
		height:535px;
		width:720px;
	}
	.imgautomove .moveblock .moveblockimg{
		width:303px;
		height:535px;
	}
	.imgautomove .moveblock .moveblockfont{
		width:417px;
		height:535px;
	}
	.imgautomove .moveblock .moveblockfont h2{
		margin: 26px 65px 0px 60px;
		font-size: 32px;
		line-height: 132px;
	}
	.imgautomove .moveblock .moveblockfont p{
		margin: 0px 65px 28px 60px;
	}
	.imgautomove .staticblock{
		left:calc(360px - 50vw);
		padding:384px 0 0;
	}
	.imgautomove .staticblock > div{ 
		display:inline-block;
		width:187.5px;
		padding:6.5px 0 46.5px;
		border-bottom:none;
		margin-left:-5px;
		border-right:1px solid #e0e0e0;
	}
	.imgautomove .staticblock > div:last-child{
		border-right:none;
	}
	.imgautomove .staticblock > div > .count-flop{
		margin-left:26px;
	}
	.imgautomove .staticblock > div > p{ 
		text-indent: 26px;
	}
	.imgautomove .staticblock > div:first-child{ 
		margin-left:calc(50vw - 375px);
	}


	.NoColumn div p{
		width:99px;
	}
	.columnImgFont{
		display: grid;
		width:100%;
		grid-template-columns: 360px 360px;
	}
	.columnImgFont figure{
		width:360px;
		height:100%;
		min-height:498px;
	}
	.columnImgFont figure img{
		left: 130px;
	}
	.column .cornerBorder:before{
		content:"";
		display:block;
		position: absolute;
		width:13px;
		height:13px;
		border-right:3px solid #96999c;
		border-top:3px solid #96999c;
		right:0;
		top:0;
	}
	.column .cornerBorder:after{
		content:"";
		display:block;
		position: absolute;
		width:13px;
		height:13px;
		border-right:3px solid #96999c;
		border-bottom:3px solid #96999c;
		right:0;
		bottom:0;
	}
	.column .cornerBorder h1{
		width:calc(100% - 24px);
		margin: 0px 0 34px 24px;
		font-size: 36px;
		line-height: 36px;
	}
	.column .cornerBorder p{
		width:auto;
		margin: 0 0 20px 24px;
	}
	.column .cornerBorder p:last-child{
		margin-bottom:0;
	}
	.rhombusp div:nth-child(2n) {
		margin-left: 0;
	}
	.rhombusp p{
		width:calc(100% - 18px);
		padding-left:18px;
		margin: 5px auto;
	}
	.rhombusp div p{
		width:calc(100% - 18px);
	}
	.rhombusp div h1 + p{
		margin-left: 0px;
	}
	.rhombusp div:first-child p:last-child{
		margin-bottom:10px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder{
		width: 100%;
		min-width:360px;
		border: none;
		margin: 0;
	}
	#fontNoBorder > .tittlrImg {
		position: relative;
		padding-top: 259px;
		width:calc(100% - 30px);
		height: auto;
		line-height:0px;
		margin:20px auto 0;
		box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
	}
	#fontNoBorder > .shortTittlrImg{
		padding:0;
		height: 119px;
	}
	.tittlrImgBorder{
		border-bottom:none;
	}
	#fontNoBorder > .tittlrImg b{
		display:block;
		position: absolute;
		line-height:44px;
		margin: 28px 33.5px;
		top: 0;
		font-size: 20px;
		letter-spacing: 0px;
		font-weight: 500; 
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 0.5px 0.5px ;
	}
	#fontNoBorder > .tittlrImg b:before{
		display:block;
		position: absolute;
		content:" ";
		width:7px;
		height:7px;
		border-left:2px solid #fff;
		border-top:2px solid #fff;
		left:-11.5px;
	}
	#fontNoBorder > .tittlrImg p{
		display:block;
		width:calc(100% - 20px - 18.5px - 1.5px);
		position: relative;
		top:0;
		margin:0;
		padding:6.5px 20px 6.5px 18.5px;
		border-left:1.5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 14px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 23px;
		color: #262b33;
		text-indent: 0;
	}
	#fontNoBorder > .tittlrImg p:nth-child(2){
		padding-top:27.5px;
	}
	#fontNoBorder > .tittlrImg p:last-child{
		padding-bottom:27.5px;
	}
	h1 + .column{
		margin:46.5px auto 16px; 
	}
	.column{
		display: grid;
		width:100%;
		grid-template-columns: 100%;
		margin:51px auto 19px; 
	}
	.columngray{
		margin:51px auto 19px; 
		position: relative;
		padding:51px 0 33.5px;
		left:0;
		background-color:#f5f5f5;
	}

	.outColumn4{
		margin-top:35px;
		padding-top:51px;
		padding-bottom:39px;
		background-color:#f5f5f5;
	}
	.NoColumn,.NoColumn2{
		margin:51px 15px -18px; 
	}
	.columnPhoneChange{
		margin-top:0;
	}
	.column:last-child{
		margin-bottom: -74px;
		padding-bottom:25px;
	}
	.outColumn4:last-child{
		margin-bottom: -74px;
	}
	.column h1{
		width:calc(100% - 30px);
		margin: 0 auto;
		font-size:15px;
		line-height:19px;
		font-weight: 500;
		color: #262b33;
		word-break:normal;
	}
	.column h2{
		font-size: 15px;
		margin: 0 auto;
		line-height:15px;
		width:calc(100% - 18px - 30px);
		margin-left:calc(18px + 15px);
	}
	.column h2:before{
		display:inline-block;
		position: absolute;
		content:" ";
		width:24px;
		height:15px;
		left:-24px;
		background:url('../images/菱形.png') no-repeat center;
		background-size:12px 13px;
	}
	.column .novisical{
		opacity:0;
	}
	.column p{
		width:calc(100% - 30px);
		margin: 15px auto;
		text-indent: 2em;
	}
	.column h1+p{
		margin: 5px auto 0;
	}
	.column h3+p{
		margin: 0 auto 0;
	}
	.column p span{
		color: #0072ce;
	}
	.columnOutcountColumn p{
		font-size:10px;
		line-height:16px;
	}
	.column h3{
		font-size:15px;
		line-height:38px;
		font-weight: 500;
		color: #262b33;
		margin: 16px auto 0;
		text-indent: 0;
	}
	.column h2 + h3{
		margin-top:20.5px;
	}
	.column h3:before{
		display:none;
	}
	.column h2~p,.column h3~p,.column h1+p,.column h3{
		width:calc(100% - 18px - 30px);
		margin-left:calc(18px + 15px);
		text-indent: 0;
	}
	.column > div{
		padding-bottom: 16px;
	}
	.column > div:nth-child(2n){
		padding-left: 0;
	}
	.column div .imgcolum{
		display: grid;
		width:calc(100% - 30px);
		grid-template-columns: 100%;
		margin:16px auto 0; 
	}
	.column div .imgcolum div{
		text-align:center;
	}
	.column div .imgcolum div img{
		display:inline-block;
		height:43px;
		width:43px;
		margin:3px 16px;
	}
	.column div .imgcolum div p{
		vertical-align:top;
		display:inline-block;
		width:100%;
		line-height:43px;
		margin:3px 0 27.5px;
	}
	.column:last-child div:last-child:after,
	.outColumn4 .column4:after,
	.NoColumn:last-child div:last-child:after,
	.NoColumn2:last-child:after,
	.noBorder:last-child:after,
	.padcharge1:last-child:after{
		content:"";
		display:block;
		position: absolute;
		width:7px;
		height:7px;
		border-right:2px solid #96999c;
		border-bottom:2px solid #96999c;
		right:15px;
		bottom:-18px;
	}
	.column:last-child div:last-child:after{
		bottom:36px;
	}
	.padcharge1:last-child:after{
		bottom:calc(52px - 10.5px);
	}
	.columngray:last-child div:last-child:after{
		bottom:calc(52px - 10.5px);
		right:15px;
	}
	.noBorder div h1, .noBorder div p,.noBorder div:nth-child(2n) h1, .noBorder div:nth-child(2n) p{
		margin-left:15px;
	}
	.noBorder div:after{
		display:none!important;
	}
	h1 + .columnOutcountColumn{
		margin-top:51px;
		margin-bottom:29px;
	}
	.columnOutcountColumn > div > p{
		text-align:center;
		position: relative;
		top: 108px;
		width:316px;
		margin: 0 auto 11.5px!important;
		font-size:14px;
		line-height:23px;
		text-indent: 0;
	}
	.column .countColumn{
		position: relative;
		top: -91px;
		grid-gap:0 51px;
		grid-template-columns: 137.5px 127.5px;
	}
	.column .countColumn > div{
		text-align:left;
		position: relative;
	}
	.column .countColumn > div > img{
		position: absolute;
		width:64px;
		height:60px;
	}
	.column .countColumn > div > div{
		display:inline-block;
		position:relative;
		padding-left:22.5px;
		font-size: 36px;
		letter-spacing: 0px;
		font-weight: 600;
		line-height: 60px;
		height: 60px;
		background-image:linear-gradient(125deg,#dd1d29,#e44a54);
		background-clip:text;
		color:transparent;
		text-indent: 0;
	}
	.column .countColumn > div > div:after{
		display:inline-block;
		content:" ";
		width:10px;
		height: 45px;
		margin-left:3px;
		background:url('../images/加号.png') no-repeat center top;
		background-size:10px 10px;
	}
	.column .countColumn > div > p{
		width:100%;
		margin:0;
		font-size: 13px;
		line-height: 15px;
		color:#565d69;
		text-align:left;
		text-indent: 22.5px;
		word-break:keep-all;
	}
	.outColumn4 h2{
		margin:0 0 8px;
		text-indent: 15px;
	}
	.outColumn4 .column4{
		display: grid;
		width:100%;
		grid-template-columns: 100%;
	}
	.outColumn4 .column4 p{
		position:relative;
		text-indent: 0;
		text-align:left;
		margin:7.5px 16px 7.5px 33px;
	}
	.outColumn4 .column4 p:before{
		display:block;
		position: absolute;
		content:" ";
		width:24px;
		height:23px;
		left:-18px;
		background:url('../images/菱形.png') no-repeat left center;
		background-size:12px 13px;
	}
	.imgautomove{
		position:relative;
		margin:20px auto;
		width:calc(100% - 30px);
		height:auto;
		padding-top:0px;
	}
	.imgautomove .moveblock{
		position: relative;
		top:0px;
		height:auto;
		width:100%;
		z-index:9;
		box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
	}
	.imgautomove .moveblock .moveblockimg{
		position:relative;
		display:block;
		width:100%;
		height:259px;
	}
	.imgautomove .moveblock .moveblockfont{
		float:none;
		position:relative;
		background-size:100% auto;
		display:block;
		width:100%;
		height:auto;
		background-color:#fff;
	}
	.imgautomove .moveblock .moveblockfont h2{
		position: absolute;
		display:block;
		top:-259px;
		line-height:44px;
		margin: 28px 33.5px;
		font-size: 20px;
		letter-spacing: 0px;
		font-weight: 500;
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 0.5px 0.5px ;
		text-indent: 0em;
	}
	.imgautomove .moveblock .moveblockfont h2:before{
		display:block;
		position: absolute;
		content:" ";
		width:7px;
		height:7px;
		border-left:2px solid #fff;
		border-top:2px solid #fff;
		top:0;
		left:-11.5px;
	}
	.imgautomove .moveblock .moveblockfont p{
		display:block;
		width:calc(100% - 20px - 18.5px - 1.5px);
		position: relative;
		top:0;
		margin:0;
		padding:6.5px 20px 6.5px 18.5px;
		border-left:1.5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 14px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 23px;
		color: #262b33;
		text-indent: 0;
	}
	.imgautomove .moveblock .moveblockfont p:nth-child(2){
		padding-top:27.5px;
	}
	.imgautomove .moveblock .moveblockfont p:last-child{
		padding-bottom:27.5px;
	}
	.imgautomove .staticblock{
		position: relative;
		height:auto;
		width:100%;
		padding:50px 0 0px;
		left:0;
		background-color:#ffff;
	}
	.imgautomove .staticblock > div{ 
		width:100%;
		padding:0;
		border:none;
		margin-left: 0;
		text-align:center;
		padding-top:25px;
	}
	.imgautomove .staticblock > div:after{ 
		content:' ';
		display:block;
		width:100%;
		height:1px;
		background-image:linear-gradient(to right,#fff,#e0e0e0,#fff);
	}
	.imgautomove .staticblock > div:first-child{
		margin-left: 0;
	}
	.imgautomove .staticblock > div:last-child{
		border:none;
	}
	.imgautomove .staticblock > div:last-child:after{ 
		display:none;
	}
	.imgautomove .staticblock > div > .count-flop{
		margin-left:0;
	}
	.imgautomove .staticblock > div > .count-flop:after{
		position: absolute;
		display:inline-block;
		content:" ";
		width:10px;
		height: 30px;
		margin-left:3px;
		top:5px;
		background:url('../images/加号.png') no-repeat center top;
		background-size:10px 10px;
	}
	.imgautomove .staticblock > div > p{ 
		position:relative;
		margin:0;
		padding-bottom:25px;
		font-size: 13px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 27px;
		height: 27px;
		color:#565d69;
		text-indent: 0;
	}
	.imgautomove .staticblock > div > p:last-child{ 
		padding-bottom:23px;
	}

	.NoColumn h2{
		margin:0;
		font-size: 15px;
		line-height: 15px;
		padding-bottom:16px;
		text-indent: 0;
	}
	.NoColumn p{
		margin:0;
		font-size: 14px;
		line-height: 30px;
		text-indent: 0;
	}
	.NoColumn div p{
		display:inline-block;
		width:19%;
		min-width:56px;
		margin:0;
		font-size: 13px;
		line-height: 30px;
		text-indent: 0;
	}
	.columnImgFont{
		margin:20px auto;
		width: calc(100% - 30px);
		box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
	}
	.columnImgFont figure{
		position:relative;
		width:100%;
		height:259px;
		min-height:259px;
		margin:0 auto;
		overflow:hidden;
	}
	.columnImgFont figure img{
		position:relative;
		width:100%;
		height:auto;
		min-width:345px;
		min-height:259px;
		left: 50%;
		transform: translateX(-50%);
	}
	.column .cornerBorder{
		position:relative;
		padding-bottom: 0px;
	}
	.column .cornerBorder:before,.column .cornerBorder:after{
		display:none;
	}
	.column .cornerBorder h1{
		position: absolute;
		top:-259px;
		line-height:44px;
		margin: 28px 33.5px;
		font-size: 20px;
		letter-spacing: 0px;
		font-weight: 500;
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 0.5px 0.5px ;
		text-indent: 0em;
	}
	.column .cornerBorder h1:before{
		display:block;
		position: absolute;
		content:" ";
		width:7px;
		height:7px;
		border-left:2px solid #fff;
		border-top:2px solid #fff;
		top:0;
		left:-11.5px;
	}
	.column .cornerBorder p{
		display:block;
		width:calc(100% - 20px - 18.5px - 1.5px);
		position: relative;
		top:0;
		margin:0;
		padding:6.5px 20px 6.5px 18.5px;
		border-left:1.5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 14px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 23px;
		color: #262b33;
		text-indent: 0;
	}
	.column .cornerBorder p:nth-child(2){
		padding-top:27.5px;
	}
	.column .cornerBorder p:last-child{
		padding-bottom:27.5px;
	}
	.rhombusp > div{
		padding-bottom: 0px;
	}
	.rhombusp div h1 {
		margin-left:15px;
	}
	.rhombusp p{
		line-height: 23px;
		margin-left: 18px;
		margin-bottom: 15px;
		width: calc(100% - 18px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp p b{
		font-weight:500;
	}
	.rhombusp div h1 + p{
		margin-top: 19.5px;
		margin-left: calc(18px + 15px);
		width: calc(100% - 18px - 30px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp div p + p,.rhombusp div:last-child p:nth-child(2) {
		margin-top: 15px;
		margin-left: calc(18px + 15px);
		width: calc(100% - 18px - 30px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp p:before,.rhombusp div p:before{
		display:block;
		position: absolute;
		content:" ";
		width:24px;
		height:23px;
		left:-24px;
		background:url('../images/菱形.png') no-repeat center;
		background-size:12px 13px;
	}
	.rhombusp div:first-child p:last-child {
    	margin-bottom: 0px;

	}
	.padcharge1{
		grid-template-columns: 100%;
		margin-top:17.5px;
	}
	.padcharge1 div:nth-child(2n){
		padding-left: 0px;
	}
	.padcharge1 div p{
		width: calc(100% - 30px);
		text-indent: 0;
	}
	
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder{
		width: 100%;
		border: none;
		margin: 0;
	}
	#fontNoBorder > .tittlrImg {
		position: relative;
		padding-top: 746px;
		width:calc(100% - 88px);
		height: auto;
		line-height:0px;
		margin:94px auto 0;
		box-shadow:rgba(05,05, 05, 0.1) 0px 4px 16px;
	}
	#fontNoBorder > .shortTittlrImg{
		padding:0;
		height: 343px;
	}
	.tittlrImgBorder{
		border-bottom:none;
	}
	#fontNoBorder > .tittlrImg b{
		display:block;
		position: absolute;
		line-height:126px;
		margin: 80px 100px;
		top: 0;
		font-size: 58px;
		letter-spacing: 0px;
		font-weight: 500; 
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 1px 1px ;
	}
	#fontNoBorder > .tittlrImg b:before{
		display:block;
		position: absolute;
		content:" ";
		width:20px;
		height:20px;
		border-left:6px solid #fff;
		border-top:6px solid #fff;
		left: -36px;
	}
	#fontNoBorder > .tittlrImg p{
		display:block;
		width:calc(100% - 57px - 52px - 5px);
		position: relative;
		top:0;
		margin:0;
		padding:18px 57px 18px 52px;
		border-left:5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 66px;
		color: #262b33;
		text-indent: 0;
	}
	#fontNoBorder > .tittlrImg p:nth-child(2){
		padding-top:83px;
	}
	#fontNoBorder > .tittlrImg p:last-child{
		padding-bottom:83px;
	}
	h1 + .column{
		margin:131px auto 48px; 
	}
	.column{
		display: grid;
		width:100%;
		grid-template-columns: 100%;
		margin:144px auto 48px; 
	}
	.columngray{
		margin:0px auto 48px; 
		position: relative;
		padding:144px 0 105px;
		left:0;
		background-color:#f5f5f5;
	}

	.outColumn4{
		margin-top:77px;
		margin-bottom: -206px;
		padding-top:144px;
		padding-bottom:160px;
		background-color:#f5f5f5;
	}
	.NoColumn,.NoColumn2{
		margin:144px 44px -36px; 
	}
	.columnPhoneChange{
		margin-top:0;
	}
	.column:last-child{
		margin-bottom: -206px;
		padding-bottom:120px;
	}
	.column h1{
		width:calc(100% - 88px);
		margin: 0 auto;
		font-size:44px;
		line-height:44px;
		font-weight: 500;
		color: #262b33;
		word-break:normal;
	}
	.column h2{
		font-size: 44px;
		margin: 0 auto;
		line-height:44px;
		width:calc(100% - 40px - 88px);
		margin-left:calc(40px + 44px);
	}
	.column h2:before{
		display:inline-block;
		position: absolute;
		content:" ";
		width:56px;
		height:44px;
		left:-56px;
		background:url('../images/菱形.png') no-repeat center;
		background-size:24px 26px;
	}
	.column .novisical{
		opacity:0;
	}
	.column p{
		width:calc(100% - 88px);
		margin: 31px auto 0;
		text-indent: 2em;
	}
	.column h1+p{
		margin: 31px auto 0;
		width:calc(100% - 88px);
	}
	.column h3+p{
		margin: 0 auto 0;
	}
	.column p+p{
		margin: 18px auto 0;
	}
	.column p span{
		color: #0072ce;
	}
	.columnOutcountColumn p{
		font-size:20px;
		line-height:32px;
	}
	.column h3{
		font-size:44px;
		line-height:106px;
		font-weight: 500;
		color: rgba(05,05,05,1);
		margin: 52px auto 0;
		text-indent: 0;
	}
	.column h2 + h3{
		margin-top:21px;
	}
	.column h3:before{
		display:none;
	}
	.column h2~p,.column h3~p,.column h3{
		width:calc(100% - 40px - 88px);
		margin-left:calc(40px + 44px);
		text-indent: 0;
	}
	.column > div{
		padding-bottom: 57px;
	}
	.column > div:nth-child(2n){
		padding-left: 0;
	}
	.divBorderbottom{
		border-bottom:2px solid #e0e0e0;
	}
	.column div .imgcolum{
		display: grid;
		width:calc(100% - 88px);
		grid-template-columns: 100%;
		margin:32px auto 0; 
	}
	.column div .imgcolum div{
		text-align:left;
	}
	.column div .imgcolum div img{
		display:inline-block;
		height:86px;
		width:86px;
		margin:75px 50px 13px 50px;
	}
	.column div .imgcolum div p{
		vertical-align:top;
		display:inline-block;
		width:calc(100% - 50px - 86px - 50px - 4px);
		line-height:86px;
		margin:75px 0 13px;
	}
	.column:last-child div:last-child:after,.outColumn4 .column4:after,.NoColumn:last-child div:last-child:after,.NoColumn2:last-child:after,.noBorder:last-child:after{
		content:"";
		display:block;
		position: absolute;
		width:20px;
		height:20px;
		border-right:6px solid #96999c;
		border-bottom:6px solid #96999c;
		right:44px;
		bottom:-48px;
	}
	.columngray:last-child div:last-child:after{
		bottom:calc(218px - 48px);
	}
	.noBorder div h1, .noBorder div p,.noBorder div:nth-child(2n) h1, .noBorder div:nth-child(2n) p{
		margin-left:44px;
		text-indent:0;
	}
	.noBorder div:after{
		display:none!important;
	}
	h1 + .columnOutcountColumn{
		margin-top:144px;
		margin-bottom:0px;
	}
	.columnOutcountColumn > div > p{
		text-align:center;
		position: relative;
		top: 296px;
		width:930px;
		margin: 0 auto 26px!important;
		font-size:40px;
		line-height:66px;
		text-indent: 0;
	}
	.column .countColumn{
		position: relative;
		top: -281px;
		grid-gap:0 144px;
		grid-template-columns: 401px 385px;
	}
	.column .countColumn div{
		text-align:left;
		position: relative;
	}
	.column .countColumn div img{
		position: absolute;
		width:184px;
		height:173px;
	}
	.column .countColumn div div{
		display:inline-block;
		position:relative;
		padding-left:65px;
		font-size: 104px;
		letter-spacing: 0px;
		font-weight: 600;
		line-height: 173px;
		height: 173px;
		background-image:linear-gradient(125deg,#dd1d29,#e44a54);
		background-clip:text;
		color:transparent;
		text-indent: 0;
	}
	.column .countColumn div div b{
		font-size: 58px;
	}
	.column .countColumn div div:after{
		display:inline-block;
		content:" ";
		width:30px;
		height: 120px;
		margin-left:16px;
		background:url('../images/加号.png') no-repeat center;
		background-size:30px 30px;
	}
	.column .countColumn div p{
		width:100%;
		margin:0;
		font-size: 40px;
		line-height: 44px;
		color:#565d69;
		text-align:left;
		text-indent: 65px;
		word-break:keep-all;
	}
	.outColumn4 h2{
		line-height:44px;
		margin:0 0 37px;
		text-indent: 44px;
	}
	.outColumn4 .column4{
		display: grid;
		width:100%;
		grid-template-columns: 100%;
	}
	.outColumn4 .column4 p{
		position:relative;
		text-indent: 0;
		text-align:left;
		margin:35px 44px 35px 84px;
	}
	.outColumn4 .column4 p:before{
		display:block;
		position: absolute;
		content:" ";
		width:56px;
		height:66px;
		left:-40px;
		background:url('../images/菱形.png') no-repeat left center;
		background-size:24px 26px;
	}
	.imgautomove{
		position:relative;
		margin:94px auto;
		width:calc(100% - 88px);
		height:auto;
		padding-top:0px;
	}
	.imgautomove .moveblock{
		position: relative;
		top:0px;
		height:auto;
		width:100%;
		z-index:9;
		box-shadow:rgba(05,05, 05, 0.1) 0px 4px 16px;
	}
	.imgautomove .moveblock .moveblockimg{
		position:relative;
		display:block;
		width:100%;
		height:746px;
	}
	.imgautomove .moveblock .moveblockfont{
		float:none;
		position:relative;
		background-size:100% auto;
		display:block;
		width:100%;
		height:auto;
		background-color:#fff;
	}
	.imgautomove .moveblock .moveblockfont h2{
		position: absolute;
		display:block;
		top:-746px;
		line-height:126px;
		margin: 80px 100px;
		font-size: 58px;
		letter-spacing: 0px;
		font-weight: 500;
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 1px 1px ;
		text-indent: 0em;
	}
	.imgautomove .moveblock .moveblockfont h2:before{
		display:block;
		position: absolute;
		content:" ";
		width:20px;
		height:20px;
		border-left:6px solid #fff;
		border-top:6px solid #fff;
		top:0;
		left:-36px;
	}
	.imgautomove .moveblock .moveblockfont p{
		display:block;
		width:calc(100% - 57px - 52px - 5px);
		position: relative;
		top:0;
		margin:0;
		padding:18px 57px 18px 52px;
		border-left:5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 66px;
		color: #262b33;
		text-indent: 0;
	}
	.imgautomove .moveblock .moveblockfont p:nth-child(2){
		padding-top:83px;
	}
	.imgautomove .moveblock .moveblockfont p:last-child{
		padding-bottom:83px;
	}
	.imgautomove .staticblock{
		position: relative;
		height:auto;
		width:100%;
		padding:73px 0 0px;
		left:0;
		background-color:#ffff;
	}
	.imgautomove .staticblock div{ 
		width:100%;
		padding:0;
		border:none;
		margin-left: 0;
		text-align:center;
	}
	.imgautomove .staticblock div:after{ 
		content:' ';
		display:block;
		width:100%;
		height:2px;
		background-image:linear-gradient(to right,#fff,#e0e0e0,#fff);
	}
	.imgautomove .staticblock div:first-child{
		margin-left: 0;
	}
	.imgautomove .staticblock div:last-child{
		border:none;
	}
	.imgautomove .staticblock div:last-child:after{ 
		display:none;
	}
	.imgautomove .staticblock div h2{ 
		position:relative;
		margin:0;
		padding-top:72px;
		font-size: 104px;
		letter-spacing: 0px;
		font-weight: 600;
		line-height: 144px;
		height: 144px;
		background-image:linear-gradient(125deg,#dd1d29,#e44a54);
		background-clip:text;
		color:transparent;
		text-indent: 0;
	}
	.imgautomove .staticblock div h2 b{
		font-size: 58px;
	}
	.imgautomove .staticblock div h2:after{
		position: absolute;
		display:inline-block;
		content:" ";
		width:30px;
		height: 30px;
		margin-left:16px;
		top:102px;
		background:url('../images/加号.png') no-repeat center;
		background-size:30px 30px;
	}
	.imgautomove .staticblock div:first-child h2:after{
		display:none;
	}
	.imgautomove .staticblock div p{ 
		position:relative;
		margin:0;
		padding-bottom:72px;
		font-size: 38px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 78px;
		height: 78px;
		color:#565d69;
		text-indent: 0;
	}
	.imgautomove .staticblock div:last-child p{ 
		padding-bottom: 0px;
	}
	.NoColumn h2{
		margin:0;
		font-size: 44px;
		line-height: 44px;
		padding-bottom:83px;
		text-indent: 0;
	}
	.NoColumn p{
		margin:0;
		font-size: 40px;
		line-height: 88px;
		text-indent: 0;
	}
	.NoColumn div p{
		display:inline-block;
		width:19%;
		min-width:160px;
		margin:0;
		font-size: 40px;
		line-height: 88px;
		text-indent: 0;
	}
	.columnImgFont{
		margin:94px auto;
		width: calc(100% - 88px);
		box-shadow:rgba(05,05, 05, 0.1) 0px 4px 16px;
	}
	.columnImgFont figure{
		position:relative;
		width:100%;
		height:746px;
		margin:0 auto;
		overflow:hidden;
	}
	.columnImgFont figure img{
		position:relative;
		height:746px;
		left: 50%;
		transform: translateX(-50%);
	}
	.column .cornerBorder{
		position:relative;
		padding-bottom: 0px;
	}
	.column .cornerBorder:before,.column .cornerBorder:after{
		display:none;
	}
	.column .cornerBorder h1{
		position: absolute;
		top:-746px;
		line-height:126px;
		margin: 80px 100px;
		font-size: 58px;
		letter-spacing: 0px;
		font-weight: 500;
		color: #fff;
		text-shadow:rgba(0,0, 0, 0.75) 0px 1px 1px ;
		text-indent: 0em;
	}
	.column .cornerBorder h1:before{
		display:block;
		position: absolute;
		content:" ";
		width:20px;
		height:20px;
		border-left:6px solid #fff;
		border-top:6px solid #fff;
		top:0;
		left: -36px;
	}
	.column .cornerBorder p{
		display:block;
		width:calc(100% - 57px - 52px - 5px);
		position: relative;
		top:0;
		margin:0;
		padding:18px 57px 18px 52px;
		border-left:5px solid #dd1d29;
		background-color:rgba(255,255,255,1);
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 66px;
		color: #262b33;
		text-indent: 0;
	}
	.column .cornerBorder p:nth-child(2){
		padding-top:83px;
	}
	.column .cornerBorder p:last-child{
		padding-bottom:83px;
	}
	.rhombusp > div{
		padding-bottom: 0px;
	}
	.rhombusp div h1 {
		margin-left:44px;
	}
	.rhombusp p{
		line-height: 66px;
		margin-left: 40px;
		margin-bottom: 70px;
		width: calc(100% - 40px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp p b{
		font-weight:500;
	}
	.rhombusp div h1 + p{
		margin-top: 83px;
		margin-left: calc(40px + 44px);
		width: calc(100% - 40px - 88px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp div p + p{
		margin-top: 70px;
		margin-left: calc(40px + 44px);
		width: calc(100% - 40px - 88px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp div:last-child p:nth-child(2) {
		margin-top: 39px;
		margin-left: calc(40px + 44px);
		width: calc(100% - 40px - 88px);
		padding-left: 0px;
		text-indent: 0;
	}
	.rhombusp p:before,.rhombusp div p:before{
		display:block;
		position: absolute;
		content:" ";
		width:56px;
		height:66px;
		left:-56px;
		background:url('../images/菱形.png') no-repeat center;
		background-size:24px 26px;
	}
	.rhombusp div:first-child p:last-child {
    	margin-bottom: 0px;

	}
	.padcharge1:last-child{
		grid-template-columns: 100%;
		margin-top:35px;
		padding-bottom:120px;
	}
	.padcharge1:last-child div:nth-child(2n){
		padding-left: 0px;
	}
	.padcharge1:last-child div p{
		width: calc(100% - 88px);
		text-indent: 0;
	}
} */
</style>
