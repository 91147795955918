<!-- 首页-生态合作 -->
<template>
    <div class="box" id="ecologica">
        <div class="title-font1" id="title-font1-box5">生态合作
            <div></div>
        </div>
        <div id="grid">
            <div
                v-for="(ecologica,index) in ecologicalList"
                :key="index"
                class="card"
                :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}"
                data-wow-duration="1.2s" :data-wow-delay="index*0.3 + 's'" data-wow-offset="150"  data-wow-iteration="1"
            >
                <img :src="ecologica.imgUrl" :alt="ecologica.imgAlt">
                <h1>{{ecologica.tittle}}</h1>
                <p>{{ecologica.text}}</p>
                <router-link
                    :to="ecologica.link"
                    :ref="'card'+index"
                    @mousemove.native="move(index)"
                    @mouseleave.native="leave(index)"
                ></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EcologicalCooperation',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
            ecologicalList:[
                {
                    imgUrl:require('./images/生态合作-合作价值.png'),
                    imgAlt:'生态合作-合作价值',
                    tittle:'合作价值',
                    text:'凝思软件将积极开展与上下游厂商全方位的生态合作。',
                    link:'/EcologicalCooperation/CooperativeValue'
                },
                {
                    imgUrl:require('./images/生态合作-生态实验室.png'),
                    imgAlt:'生态合作-生态实验室',
                    tittle:'生态实验室',
                    text:'凝思软件自建生态实验室，旨在为客户提供完整的国产化解决方案。',
                    link:'/EcologicalCooperation/EcologicalLaboratory'
                },
                {
                    imgUrl:require('./images/生态合作-兼容列表.png'),
                    imgAlt:'生态合作-兼容列表',
                    tittle:'兼容列表',
                    text:'凝思软件长期致力于与众多合作伙伴合力打造更可靠的国产软硬件环境。',
                    link:'/EcologicalCooperation/CompatibleList'
                },
            ]
        }
    },
    mounted() {
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
	},
    methods: {
        leave(index) {
            const card = eval("this.$refs.card"+(index))[0];
            card.$el.style.transform = `perspective(500px) scale(1)`;
        },
        move(index) {
            const card = eval("this.$refs.card"+(index))[0];

            const relX = (event.offsetX + 1) / card.$el.offsetWidth;
            const relY = (event.offsetY + 1) / card.$el.offsetHeight;

            const rotY = `rotateY(${(relX - 0.5) * 10}deg)`;
            const rotX = `rotateX(${(relY - 0.5) * -10}deg)`;
            card.$el.style.transform = `perspective(500px) scale(1) ${rotY} ${rotX}`;
        },

    }
}
</script>

<style scoped>
/*pc*/
#ecologica{
	background: url(./images/生态合作下点阵-pc.png) no-repeat right 130px;
}
#grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(1170px / 3));
    align-items: center;
    justify-content: center;
    width: 1170px;
    margin: 0 auto;
}
#grid .card{
    margin: 0 auto ;
    background-color: #fff;
    width: 364px;
    height: 268px;
    border:1px solid #fff;
    border-radius: 0px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
#grid .card img{
    position:absolute;
    display: inline-block;
    width: auto;
    height: 46px;
    top:49px;
    left:50%;
    transform: translateX(-50%); 
}
#grid .card h1{
    position:absolute;
    width:100%;
    top:134px;
    color: #262b33;
    font-size: 22px;
    text-align:center;
    letter-spacing:0px;
    font-weight: 500;
    line-height: 22px;
}
#grid .card p{
    position:absolute;
    width:258px;
    left:50%;
    transform: translateX(-50%); 
    bottom:53px;
    color: #565d69;
    font-size: 16px;
    text-align:center;
    letter-spacing:0px;
    font-weight: 300;
    line-height: 20px;
}
#grid .card a{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border:1px solid #ebebeb;
    box-shadow: 0px 4px 18px 0px rgba(5,5,5,0);
    transition: box-shadow 0.3s;
    border-radius: 0px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
}
#grid .card a:hover{
    width: 346px;
    height: 250px;
    border:10px solid #fff;
    box-shadow: 0px 4px 18px 0px rgba(5,5,5,0.07);
    transition: box-shadow 0.3s;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    #ecologica{
		background: url(./images/生态合作下点阵-pad.png) no-repeat right 120px;
	}
    #grid {
        grid-template-columns: repeat(auto-fill, calc(1024px / 3));
        width: 1024px;
    }
    #grid .card {
        width: 278px;
        height: 307px;
    }
    #grid .card img{
        top:60px;
    }
    #grid .card h1{
        top:130px;
    }
    #grid .card p{
        width:191px;
        bottom:59px;
        line-height: 26px;
    }
    #grid .card a,#grid .card a:hover{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border:1px solid #ebebeb;
        box-shadow: 0px 4px 18px 0px rgba(5,5,5,0);
        transition: box-shadow 0.3s;
        border-radius: 0px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
    #grid {
        grid-template-columns: repeat(auto-fill, calc(750px / 3));
        width: 750px;
    }
    #grid .card{
        width: 218px;
        height: 331px;
    }
    #grid .card img{
        top:60px;
    }
    #grid .card h1{
        top:130px;
    }
    #grid .card p{
        width:160px;
        bottom:85px;
        line-height: 26px;
    }
    #grid .card:last-child p{
        bottom:59px;
    }
}
/*手机端*/
@media only screen and (max-width:750px){
    #ecologica{
		background: url(./images/生态合作下点阵-手机端.png) no-repeat calc(100vw - 146px - 6px) 112px;
		background-size:146px auto;
		background-color:#f7f7f7;
	}
    #grid {
        grid-template-columns: repeat(auto-fill, 325px);
        width: 325px;
    }
    #grid .card{
        width: 317px;
        height: 183px;
        margin-bottom: 23px;
        border:0.5px solid #ebebeb;
    }
    #grid .card:last-child{
        margin-bottom: 0px;
    }
    #grid .card img{
        top:26.5px;
        height: 31.5px;
    }
    #grid .card h1{
        top:84.5px;
        font-size: 18px;
        line-height: 18px;
    }
    #grid .card p{
        bottom:25.5px;
        width:240px;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
    }
     #grid .card:last-child p{
        bottom:25.5px;
    }
    #grid .card a,#grid .card a:hover{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border:0.5px solid rgba(5,5,5,0);
        transition: box-shadow 0.3s;
        border-radius: 0px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
    }
}
/*手机端1080
@media only screen and (min-height:1300px){
    #ecologica{
		background: url(./images/生态合作下点阵-手机端1080.png) no-repeat right 170px;
		background-color:#f7f7f7;
	}
    #grid {
        grid-template-columns: repeat(auto-fill, 992px);
        width: 992px;
    }
    #grid .card{
        width: 910px;
        height: 525px;
        margin-bottom: 72px;
        border:1px solid #ebebeb;
        box-shadow: 0px 0px 0px 0px rgba(5,5,5,0.07);
    }
    #grid .card img{
        top:74px;
        height: 94px;
    }
    #grid .card h1{
        top:243px;
        font-size: 52px;
        line-height: 52px;
    }
    #grid .card p{
        top:340px;
        width:680px;
        font-size: 40px;
        font-weight: 300;
        line-height: 58px;
    }
    #grid .card a,#grid .card a:hover{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border:1px solid rgba(5,5,5,0);
        transition: box-shadow 0.3s;
        border-radius: 0px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
    }
}*/
</style>
