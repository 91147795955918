/* News仓库 */
const state = {
	JobsList: [
		{
			// society,
			text: [
				{
					tittle:'Linux高级研发工程师（云桌面产品方向）',
					condition:'全职｜经验5-8年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、负责虚拟化底层（Linux kernel, KVM，qemu，spice/vnc qxl、libvirt）的开发和维护, 安全漏洞的快速修复等；',
							'2、负责虚拟化产品的运行故障分析和BUG修复；',
							'3.、针对不同的应用场景，对虚拟化系统进行性能分析和优化。',
						],
						request:[
							'1、计算机相关专业本科及以上学历；',
							'2、具有良好的计算机技术相关基础；',
							'3、熟悉Linux下的python、C相关开发和调试技能；',
							'4、熟悉Linux下KVM虚拟化、Libvirt开发技能；',
							'5、具有良好的团队合作和沟通能力；',
							'6、具备良好的编码风格和文档编写能力。',
							
						],
					}
				},
				{
					tittle:'Linux操作系统开发工程师',
					condition:'全职｜经验3-5年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、操作系统产品相关组件的开发、构建和维护；',
							'2、现有操作系统产品功能升级及问题修复；',
							'3、操作系统产品自动构建平台维护和升级；',
							'4、编写技术文档和故障分析报告；',
							'5、解决用户在使用操作系统产品时遇到的问题。'
						],
						request:[
							'1、计算机相关专业本科及以上学历；',
							'2、熟练使用Linux C语言开发；',
							'3、至少能熟练使用一种脚本语言（例如bash、python、perl）；',
							'4、了解操作系统工作原理，熟悉Linux系统的安装和配置，掌握开源软件的配置、编译和安装方法；',
							'5、了解内核的基本功能，掌握内核模块的编译和使用方法；',
							'6、能熟练阅读中、英文技术文档；',
							'7、责任心强，有较强的学习、沟通能力和独立解决问题的能力；',
							'8、有Linux系统构建经验、熟悉LFS或对开源社区有贡献者优先。',
						],
					}
				},
				{
					tittle:'Linux安全开发工程师',
					condition:'全职｜经验3-5年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、基于Linux平台的安全产品设计、研发、功能完善；',
							'2、完成Linux平台的软件包安全升级，消除安全漏洞；',
							'3、研究Linux平台下安全机制，构建系统级安全加固方案；',
							'4、研究Linux平台下安全防护技术，完善系统的恶意代码防护、入侵检查等安全防护机制。',
						],
						request:[
							'1、计算机相关专业本科及以上学历；',
							'2、熟悉Linux平台下的系统配置、网络配置等基本功能；',
							'3、熟练使用C/C++语言在Linux平台下进行开发工作；',
							'4、有过shell或python编程经历；',
							'5、具有积极主动的工作态度和良好的团队协作能力；',
							'6、较好的英文阅读能力；',
							'7、具有安全攻防或系统安全机制开发经验者优先；',
							'8、熟悉Linux安全加固相关技术，有操作系统安全防护相关工作经历者优先；',
							'9、有过Linux下安全相关模块IMA/EVM、LSM、SELinux、Apparmor 等工作经历者优先。',
						],
					}
				},
				{
					tittle:'Linux应用开发工程师',
					condition:'全职｜经验3-5年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、完成软件系统代码的实现，编写代码注释和开发文档；',
							'2、完成Linux平台上的应用和系统研发任务；',
							'3、建设与维护和研发工作相关的软硬件环境。',
						],
						request:[
							'1、计算机相关专业本科及以上学历；',
							'2、熟练掌握Linux环境下的C语言开发调试技能；',
							'3、熟悉Linux环境下的网络协议及实现；',
							'4、熟悉Linux环境的系统编程；',
							'5、具备良好的代码编写习惯；',
							'6、计算机相关专业本科及以上学历；',
							'7、能承受工作压力；',
							'8、具有良好的沟通和表达能力，具备团队合作精神。',
						],
					}
				},
				{
					tittle:'嵌入式操作系统研发工程师',
					condition:'全职｜经验3-5年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、嵌入式操作系统产品开发；',
							'2、国产嵌入式平台适配优化；',
							'3、内核组件研究开发；',
							'4、操作系统关键技术研究；',
							'5、部门安排的其他工作。',
						],
						request:[
							'1、计算机相关专业本科及以上学历，3-5年相关工作经验；',
							'2、精通C和ARM汇编编程，熟悉ARM硬件平台；',
							'3、精通嵌入式Linux操作系统移植，掌握常见外设驱动开发；',
							'4、熟练阅读英文技术资料，具备良好的文档编写能力；',
							'5、掌握常见嵌入式/实时操作系统实现原理者优先；',
							'6、有操作系统内核组件开发经验者优先 。',
						],
					}
				},
				{
					tittle:'测试工程师',
					condition:'全职｜经验3-5年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、从事操作系统及其相关产品的软件测试工作，包括功能测试、兼容性、可靠性、性能等测试；',
							'2、根据需求编写测试用例，制定测试计划，bug跟踪及确认，编写测试报告；',
							'3、参与需求、测试用例等评审，保证产品的正确性和完整性，并提供测试意见；',
							'4、搭建自动化测试平台，设计自动化测试方案、脚本开发，高效完成软件产品的测试工作。',
						],
						request:[
							'1、计算机、通信、电子信息等相关专业本科及以上学历；',
							'2、熟悉Linux操作系统，具备操作系统、云计算等相关工作经验者优先；',
							'3、熟悉Python、shell等脚本语音，有自动化相关经验优先；',
							'4、熟悉软件测试理论及方法，熟悉软件开发流程者优先；',
							'5、良好的沟通能力、学习能力，积极主动，热衷于新技术的探索。',
						],
					}
				},
				{
					tittle:'产品部副经理',
					condition:'全职｜经验5-8年｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、负责公司产品的全生命周期管理工作；',
							'2、负责部门产品方向的管理工作；',
							'3、负责深入挖掘电力、金融、电信、交通等行业用户需求，整理形成产品需求；',
							'4、负责收集并跟踪行业和产品信息，协助公司领导规划产品战略发展方向；',
							'5、负责产品培训等工作。',
						],
						request:[
							'1、本科及以上学历，计算机、软件、电子等相关专业优先；',
							'2、具有5年以上产品经理工作经验，熟练掌握产品工作方法；',
							'3、有操作系统、虚拟化、信息安全等产品工作经验者优先，有重要行业产品工作经验优先；',
							'4、沟通能力良好，能够与客户建立密切关系；',
							'5、能够挖掘客户需求，并根据客户需求策划产品；',
							'6、有较强的文档撰写能力；',
							'7、有较强的工作责任心和良好的自主学习能力。',
						],
					}
				},
				{
					tittle:'运维工程师',
					condition:'全职｜行业经验1-3年｜本科及以上',
					city:'北京/成都/南京｜技术工程中心',
					content:{
						responsibility:[
							'1、负责公司产品的售后安装、调试和售后技术支持服务，并配合厂家进行测试；',
							'2、与销售工程师协作完成产品销售并进行产品售后跟踪；',
							'3、用户单位现场小规模的用户使用操作培训；',
							'4、具备良好的分析/解决问题和沟通、学习能力，对突发事件能够快速响应、判断故障并有效解决；',
							'5、能承受较大的工作压力，服从工作分配，随时处理突发事件，有较强的运维服务意识。',
						],
						request:[
							'1、具有计算机或电子工程相关专业专科以上学历；',
							'2、熟悉Linux操作系统（包括各种常用服务程序）的管理和配置；',
							'3、编写各类相关的技术文档和规范标准；',
							'4、较强的客户沟通能力，较强的组织协调能力，能适应出差；',
							'5、一年以上linux系统级维护工作经验，有Linux系统参数设置、性能调优、系统安全、故障定位处理经验。',
						],
					}
				},
				{
					tittle:'行业销售总监',
					condition:'全职｜行业经验5-8年｜本科及以上',
					city:'北京/成都｜销售部',
					content:{
						responsibility:[
							'1、负责相关区域及指定行业内的产品销售工作；',
							'2、参与所负责行业内的项目招投标工作，积极推进合同的谈判与签订工作；',
							'3、开发新客户并维护老客户关系，定期完成季度、年度销售任务；',
							'4、按照公司的相关战略开拓市场，努力达成目标；',
							'5、及时收集并向公司领导和相关部门反馈市场、行业需求及竞争者信息；',
							'6、做好售前、售中、和售后服务，和客户保持良好的合作关系。',
						],
						request:[
							'1、大学本科及以上学历，计算机软件或市场营销相关专业优先；',
							'2、在电力、金融、电信、石油石化、铁路、轨道交通等领域有5-8年软件销售经验；',
							'3、在相关行业有丰富的人脉资源；',
							'4、具备独立开发行业市场的能力；',
							'5、具有较强的责任心，希望在一个稳定的平台长期发展；',
							'6、具有良好的亲和力、沟通能力和协调能力。',
						],
					}
				},
			],
		},
		{
			// school,
			text: [
		    	{
					tittle:'初级研发工程师',
					condition:'全职｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、研发方向有Linux操作系统、应用层、安全、网络、嵌入式等，根据专业基础及技术水平确定具体方向；',
							'2、协助完成产品设计及代码编写等工作；',
							'3、针对新的研发任务需求进行技术调研，形成调研文档；',
							'4、建设与维护和研发工作相关的软硬件环境；',
							'5、及时完成领导交办的工作任务。',
						],
						request:[
							'1、计算机科学与技术、电子工程、信息安全、软件工程等相关专业本科及以上学历；',
							'2、热爱编程，熟悉C语言和Linux编程，拥有良好的编码习惯，具备分析和解决问题的能力；',
							'3、对Linux开发有浓厚的兴趣，并有一定基础；',
							'4、自学能力强,具有团队合作精神；',
							'5、熟悉网络协议；',
							'6、具有开发Linux下的C开发实习及项目经验优先。',
						],
					}
		    	},
		    	{
					tittle:'研发工程师（实习生）',
					condition:'实习｜本科及以上',
					city:'北京/成都｜研发中心',
					content:{
						responsibility:[
							'1、配合完成软件系统代码的实现，编写代码注释和开发文档；',
							'2、辅助进行系统的功能定义,程序设计；',
							'3、参与Linux平台上的新技术调研工作；',
							'4、协助建设与维护和研发工作相关的软硬件环境；',
							'5、配合完成Linux平台上的应用和系统研发任务。',
						],
						request:[
							'1、计算机科学与技术、电子工程、信息安全、软件工程及相关专业本科及以上学历，优秀硕士实习生可解决北京户口；',
							'2、熟悉操作系统原理及基础知识；',
							'3、了解Linux平台下C/C++语言开发；',
							'4、具备一定的工程化项目管理能力(包括调试能力、文档编写能力、测试能力等)者优先；',
							'5、有过开源软件代码开发经验及能较好使用脚本语言（Perl/shell/Python等）者优先；',
							'6、有一定硬件基础，能够解决软硬件兼容问题。',
						],
					}
		    	},
		    	{
					tittle:'初级运维工程师',
					condition:'全职｜本科及以上',
					city:'北京/成都/南京｜技术工程中心',
					content:{
						responsibility:[
							'1、负责公司操作系统、云桌面、容器等产品的售后安装、调试和售后技术支持服务，并配合厂家进行测试；',
							'2、与销售工程师协作完成产品销售并进行产品售后跟踪；',
							'3、用户单位现场小规模的用户使用操作培训；',
							'4、具备良好的分析/解决问题和沟通、学习能力，对突发事件能够快速响应、判断故障并有效解决。',
						],
						request:[
							'1、计算机及相关专业本科及以上学历；',
							'2、熟悉Linux操作系统（包括各种常用服务程序）的管理和配置；',
							'3、能够编写各类相关的技术文档和规范标准；',
							'4、较强的客户沟通能力，较强的组织协调能力；',
							'5、有Linux系统参数设置、性能调优、系统安全、故障定位处理经验。',
						],
					}
		    	},
				{
					tittle:'运维工程师（实习生）',
					condition:'实习｜本科及以上',
					city:'北京/成都/南京｜技术工程中心',
					content:{
						responsibility:[
							'1、协助完成公司产品的售后安装、调试和售后技术支持服务，并配合厂家进行测试；',
							'2、与销售工程师协作完成产品销售并进行产品售后跟踪；',
							'3、协助开展用户单位现场小规模的用户使用操作培训；',
							'4、具备较好的分析/解决问题和沟通能力，配合完成突发事件的快速响应及故障的有效解决。',
						],
						request:[
							'1、计算机或电子工程相关专业本科及以上学历；',
							'2、熟悉Linux操作系统（包括各种常用服务程序）的管理和配置；',
							'3、能够编写各类相关的技术文档和规范标准；',
							'4、较强的客户沟通能力，较强的组织协调能力；',
							'5、有Linux系统参数设置、性能调优、系统安全、故障定位处理经验。',
						],
					}
		    	},
			]
		}
	]
};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
