<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg shortTittlrImg" id="tittlrImgPoverIndustry6">
			<b>国网信息化</b>
		</h1>
		<div class="column rhombusp">
			<div>
				<h1>凝思为电网信息通信系统的多个项目提供产品和服务，其中包括：</h1>
				<p><b>网上国网：</b>隔离装置</p>
				<p><b>南瑞信通：</b>隔离装置</p>
				<p><b>全球能源互联网研究院：</b>SQL代理</p>
				<p><b>国网客户服务中心：</b>2017年智能知识体系建设及应用——知识库在省（市）的贯通及深化应用项目</p>
				<p><b>国网甘肃省电力公司：</b>用电信息采集户表改造配套工程</p>
				<p><b>国网湖南省电力公司：</b>国网湖南省检修公司500kV古亭等变电站变电检修移动作业平台建设</p>
				<p><b>国网新疆电力有限公司信息通信公司：</b>基础资源2016年各单位数据中心-软硬件购置项目</p>
			</div>
			<div>
				<h1 class="novisical">典型案例</h1>
				<p><b>国网重庆市电力公司物资分公司：</b>国网重庆客服中心2017年反窃电管理提升升级改造</p>
				<p><b>国网河北省电力有限公司：</b>河北南网用电信息采集系统硬件升级工程</p>
				<p><b>国网湖南省电力有限公司：</b>国网湖南省检修公司运检智能分析决策系统升级改造</p>
				<p><b>国网上海市电力公司：</b>基础资源2016年上海灾备中心资源池建设及存储虚拟化试点</p>
				<p><b>国网内蒙古东部电力有限公司：</b>国网蒙东呼伦贝尔供电公司110kV根河等变电站五防系统改造</p>
				<p><b>国网福建省电力有限公司：</b>各单位数据中心软硬件购置项目</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'StateGridInformatization',
	}
</script>
<style scoped>

</style>
