<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg shortTittlrImg" id="tittlrImgPoverIndustry2">
			<b>配网自动化</b>
		</h1>
		<div class="imgautomove">
			<div class="moveblock" ref="movediv">
				<div class="moveblockimg" id="moveblockimg1"></div>
				<div class="moveblockfont">
					<h2>配网自动化</h2>
					<p>2015年国网针对配网自动化平台提出了国产化的要求，凝思安全操作系统凭借此前在智能电网调度控制系统（D5000系统）项目中的出色表现，继续投身到配网自动化平台国产化的工作中，为业内主要配网应用厂家定制开发安全操作系统产品。</p>
					<p>截至2020年3月，凝思操作系统软件产品已部署于22个省及其下属的上百个地市电力公司，部署总量超过5500套，市场占有率超80%。</p>
				</div>
			</div>
			<div class="staticblock">
				<div>
					<ScrollNumber :val="formatNumber(value,0,0)"></ScrollNumber>
					<p>已部署于22个省</p>
				</div>
				<div>
					<ScrollNumber :val="formatNumber(value2,0,0)"></ScrollNumber>
					<p>上百个地市电力公司</p>
				</div>
				<div>
					<ScrollNumber :val="formatNumber(value3,0,0)"></ScrollNumber>
					<p>部署总量超5500套</p>
				</div>
				<div>
					<ScrollNumber :val="formatNumber(value4,0,0)" :fit="1"></ScrollNumber>
					<p>市场占有率超80%</p>
				</div>
			</div>
		</div>
		<div class="NoColumn">
			<h2>典型案例</h2>
			<p>配网自动化主站项目：</p>
			<div>
				<p>北京</p>
				<p>上海</p>
				<p>天津</p>
				<p>冀北</p>
				<p>河北</p>
				<p>河南</p>
				<p>山东</p>
				<p>山西</p>
				<p>江苏</p>
				<p>浙江</p>
				<p>安徽</p>
				<p>福建</p>
				<p>吉林</p>
				<p>辽宁</p>
				<p>重庆</p>
				<p>四川</p>
				<p>湖北</p>
				<p>湖南</p>
				<p>江西</p>
				<p>陕西</p>
				<p>青海</p>
				<p>蒙东等地</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'DistributionAutomation',
	data() {
		return {
			navShow:document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset,
			value:22,
			value2:100,
			value3:5500,
			value4:80,

		}
	},
	methods: {
		/**
		 * formatNumber()
		 * 将数值四舍五入后格式化.
		 *
		 * @param num 数值(Number或者String)
		 * @param cent 要保留的小数位(Number)
		 * @param isThousand 是否需要千分位 0:不需要, 1:需要(数值类型);
		 * @return 格式的字符串,如'1,234,567.45'
		 * @type String
		*/
		formatNumber(num, cent, isThousand) {
			num = num.toString().replace(/\$|\,/g, "");
			if (isNaN(num)) num = "0";
			var sign = num == (num = Math.abs(num));
			num = parseFloat(num.toFixed(cent));
			num = num.toString();
			var cents;
			if (num.lastIndexOf(".") != -1) {
				let index = num.lastIndexOf(".");
				cents = num.substring(index + 1, num.length);
			} else {
				cents = "";
			}
			num = Math.floor(num * Math.pow(10, cent) + 0.50000000001);
			num = Math.floor(num / Math.pow(10, cent)).toString();
			if (isThousand) {
				for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
				  num = num.substring(0, num.length - (4 * i + 3)) + "," + num.substring(num.length - (4 * i + 3));
			}
			if (cent > 0) {
				if (cents == "") {
					return (sign ? "" : "-") + num;
				} else {
					return (sign ? "" : "-") + num + "." + cents;
				}
			} else {
				return (sign ? "" : "-") + num;
			}
		},
  	},
	mounted () {
        window.addEventListener('scroll',()=>{this.navShow=document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset});
        this.$refs.movediv.style.top = 0 + 'px';
	},
	watch:{
        navShow:{
        	handler(val){
				if(window.innerWidth > 1170){
					// var he = document.body.scrollHeight;
					var he = 1300 - 830;
					var move = 0;
					if (val < 830){
						move = 0;
					} else if(val > 1300){
						move = 88;
					} else {
						move = (88 * (val - 830)) / he;
					}
					console.log(move);
					this.$refs.movediv.style.top = move + 'px';
				}
			}
        },
	}
}
</script>
<style scoped>
#moveblockimg1{
	background:url('../images/配网自动化.png') no-repeat;
	background-size:  auto 100%;
}
@media only screen and (max-width:750px){
	#moveblockimg1{
		background:url('../images/配网自动化.png') no-repeat 0 -168px;
		background-size: 100% auto;
	}
}
@media only screen and (min-height:1300px){
	#moveblockimg1{
		background:url('../images/配网自动化.png') no-repeat 0 -258px;
		background-size: 100% auto;
	}
}
</style>
