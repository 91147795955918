<template>
	<section id="caseDisplay">
		<router-link
			class="caseDisplayDetail"
			v-for ="(item,index) in caseDisplay"
			:key="index"
			:to="{path:path,query:{tittle:item.tittle}}"
		>
			<div class="caseDisplayBackground" ><img :src="item.backgroundImgUrl"/></div>
			<!-- <div
				:class="item.tittle=='配网自动化' ? 'caseDisplayIcon':'caseDisplayImage'"
			><img :src="item.IconUrl"/></div> -->
			<div
				class="caseDisplayImage"
			>
				<img v-show="bodyWidthMut > 750" :src="item.IconUrl"/>
				<img v-show="bodyWidthMut <= 750" :src="item.IconUrl2"/>
			</div>
			<div class="caseDisplayContent">
				<h2>{{item.tittle}}</h2>
				<h4>{{item.text}}</h4>
				<p>查看详情<img class="caseDisplayBackground" src="../../images/箭头-蓝色-向右.png"/></p>
			</div>
		</router-link>
	</section>
</template>

<script>
export default {
    name: 'CaseDisplay',
	data() {
		return {
			caseDisplay: this.$attrs.caseDisplay,
			path: this.$attrs.path,
			bodyWidthMut: document.body.clientWidth,
		}
	},
	mounted() {
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
}
</script>

<style scoped>
#caseDisplay{
	display: grid;
	width:100%;
	grid-gap: 32px 18px;
	grid-template-columns: repeat(auto-fill,378px);
	margin-top:28px; 
}
#caseDisplay .caseDisplayDetail{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	position: relative;
	background: #fff;
	text-decoration: none;
	overflow: hidden;
	border:1px solid #e0e0e0;
}
#caseDisplay .caseDisplayDetail .caseDisplayBackground{
	position: absolute;
	width: 100%;
	height: 176px;
	overflow: hidden;
}
#caseDisplay .caseDisplayDetail .caseDisplayBackground img{
	width: auto;
	height: 100%;
	transform:scale(1);
	transition:transform 0.6s;
}
#caseDisplay .caseDisplayDetail:hover .caseDisplayBackground img{
	transform:scale(1.05);
}
#caseDisplay .caseDisplayDetail .caseDisplayImage{
	position: relative;
	margin:29px auto 0;
	width: 318px;
	height: 117px;
}
#caseDisplay .caseDisplayDetail .caseDisplayImage img{
	width: 318px;
	height: 117px;
}
/*#caseDisplay .caseDisplayDetail .caseDisplayIcon{
	margin:34px auto 4px;
	position: relative;
	width: 318px;
	height: 123px;
}
#caseDisplay .caseDisplayDetail .caseDisplayIcon img{
	width: auto;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}*/
#caseDisplay .caseDisplayDetail .caseDisplayContent{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	position: relative;
	background: #fff;
	padding: 0px 24px 32px;
	margin: 0 29px;
}
#caseDisplay .caseDisplayDetail .caseDisplayContent>h2{
	margin: 0;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 600;
	line-height: 58px;
	color: #262b33;
	text-indent: 0em;
}
#caseDisplay .caseDisplayDetail .caseDisplayContent>h4{
	margin: 0 0 auto;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 300;
	line-height: 24px;
	color: #262b33;
	text-indent: 0em;
	vertical-align:top;
}
#caseDisplay .caseDisplayDetail .caseDisplayContent>p{
	position: relative;
	margin: 20px 0 0;
	width:80px;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 32px;
	color: #0072ce;
	text-indent: 0em;
}
#caseDisplay .caseDisplayDetail .caseDisplayContent>p img{
	position: relative;
	width: 14px;
	height: 8px;
	margin-bottom:2px;
	left:8px;
	transition:left 0.3s;
}
#caseDisplay .caseDisplayDetail .caseDisplayContent p:hover img{
	left:16px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#caseDisplay{
		width:774px;
		margin:28px auto 0; 
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#caseDisplay{
		grid-gap: 32px 28px;
		grid-template-columns: repeat(auto-fill,346px);
		margin-top:28px; 
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent{
		margin: 0 13px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#caseDisplay{
		display: grid;
		width:330px;
		grid-gap: 32px 0;
		grid-template-columns: 100%;
		margin:28px auto 0;
	}
	#caseDisplay .caseDisplayDetail{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		position: relative;
		background: #fff;
		text-decoration: none;
		overflow: hidden;
		border:1px solid #e0e0e0;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayBackground{
		position: absolute;
		width: 100%;
		height: 154px;
		overflow: hidden;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayBackground img{
		width: 100%;
		height: auto;
		min-height:100%;
		transform:scale(1);
		transition:transform 0.6s;
	}
	#caseDisplay .caseDisplayDetail:hover .caseDisplayBackground img{
		transform:scale(1.05);
	}
	#caseDisplay .caseDisplayDetail .caseDisplayImage{
		position: relative;
		margin:26px auto 0;
		width: 275.5px;
		height: 101.5px;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayImage img{
		width: 275.5px;
		height: 101.5px;
	}
	/*#caseDisplay .caseDisplayDetail .caseDisplayIcon{
		margin:25px auto 9px;
	}*/
	#caseDisplay .caseDisplayDetail .caseDisplayContent{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		flex-grow: 1;
		position: relative;
		background: #fff;
		width: calc(275.5px - 22px - 22px);
		padding: 0px 22px 10.5px;
		margin: 0 auto;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent h2{
		padding-top: 5.5px;
		font-size: 16px;
		letter-spacing: 0px;
		font-weight: 600;
		line-height: 47px;
		text-indent: 0em;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent h4{
		margin: 0;
		font-size: 15px;
		letter-spacing: 0px;
		font-weight: 300;
		line-height: 24px;
		text-indent: 0em;
		vertical-align:middle;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p{
		margin: auto 0 0;
		width:85px;
		font-size: 15px;
		letter-spacing: 0px;
		font-weight: 500;
		line-height: 58px;
		text-indent: 0em;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p img{
		position: relative;
		width: 13.5px;
		height: 8.5px;
		margin-bottom:1px;
		left:8px;
		transition:left 0.3s;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p:hover img{
		left:16px;
	}
}
/*手机端1080
@media only screen and (min-height:1300px){
	#caseDisplay{
		display: grid;
		width:calc(100% - 88px);
		grid-gap: 102px 0;
		grid-template-columns: 100%;
		margin:124px auto 0;
	}
	#caseDisplay .caseDisplayDetail{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		position: relative;
		background: #fff;
		text-decoration: none;
		overflow: hidden;
		border:2px solid #e0e0e0;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayBackground{
		position: absolute;
		width: 100%;
		height: 508px;
		overflow: hidden;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayBackground img{
		width: auto;
		height: 100%;
		transform:scale(1);
		transition:transform 0.6s;
	}
	#caseDisplay .caseDisplayDetail:hover .caseDisplayBackground img{
		transform:scale(1.05);
	}
	#caseDisplay .caseDisplayDetail .caseDisplayImage{
		position: relative;
		margin:100px auto 0;
		width: 828px;
		height: 326px;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayIcon{
		margin:72px auto 28px;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		flex-grow: 1;
		position: relative;
		background: #fff;
		width: calc(828px - 66px - 66px);
		padding: 0px 66px 33px;
		margin: 0 auto;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent h2{
		margin-top: 19px;
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 600;
		line-height: 130px;
		color: #141414;
		text-indent: 0em;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent h4{
		margin: 0;
		font-size: 44px;
		letter-spacing: 0px;
		font-weight: 300;
		line-height: 74px;
		color: #141414;
		text-indent: 0em;
		vertical-align:middle;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p{
		margin: auto 0 0;
		width:210px;
		font-size: 38px;
		letter-spacing: 0px;
		font-weight: 500;
		line-height: 168px;
		color: #0072ce;
		text-indent: 0em;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p img{
		position: relative;
		width: 38px;
		height: 22px;
		margin-bottom:2px;
		left:16px;
		transition:left 0.3s;
	}
	#caseDisplay .caseDisplayDetail .caseDisplayContent p:hover img{
		left:32px;
	}
}*/
</style>
