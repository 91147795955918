/* 全局都要使用的模块仓库 */
const state = {
    windowWidths:0,
    windowHeights:0,
    windowTopHeights:0,
};
const mutations = {
};
const actions = {
};
const getters = {

};
export default {
	state,
	mutations,
	actions,
	getters
}
