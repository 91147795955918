/* Home官网首页模块仓库 */
//state:仓库存储数据的地方
const state = {
	//凝思产品
	productList:[
        {
            imgUrl:require('./images/凝思产品-操作系统.png'),
            imgAlt:'凝思产品-操作系统',
            tittle:'操作<br>系统',
            text:'基于自主研发的安全内核，提供底层软件系统的安全支持',
            link:'/Products/ProductsCatalog',
            goid:'OperatingSystem'
        },
		{
            imgUrl:require('./images/凝思产品-系统功能增强软件.png'),
            imgAlt:'凝思产品-系统功能增强软件',
            tittle:'系统功能增强<br>软件',
            text:'提供高可用性、高可靠性以及更多功能和服务',
            link:'/Products/ProductsCatalog',
            goid:'SystemEnhancements'
        },
		{
            imgUrl:require('./images/凝思产品-平台软件.png'),
            imgAlt:'凝思产品-平台软件',
            tittle:'平台<br>软件',
            text:'分布式文件系统、云计算平台、大数据平台、虚拟化管理平台',
            link:'/Products/ProductsCatalog',
            goid:'PlatformSoftware'
        },
        {
            imgUrl:require('./images/凝思产品-定制开发.png'),
            imgAlt:'凝思产品-定制开发',
            tittle:'定制<br>开发',
            text:'平台类产品定制、跨平台移植、系统层功能软件定制',
            link:'/Products/ProductsCatalog',
            goid:'CustomDevelopment'
        },
    ],
	storiesList:[
        {
            id:'01',
            imgUrl:require('./images/电力.png'),
            tittle:'电力行业',
            text:'凝思安全操作系统已经部署于电力系统发、输、变、配、用、储全生产业务环节，总装机量超过20万套。',
            link: '/Solutions/PoverIndustry'
        },
        {
            id:'02',
            imgUrl:require('./images/轨道.png'),
            tittle:'轨道交通',
            text:'凝思安全操作系统全面支撑售检票系统、12306电子支付平台的平稳运行，并通过网络安全标记协助国铁集团构建了达到等级保护第四级的应用系统。',
            link: '/Solutions/Rail'
        },
        {
            id:'03',
            imgUrl:require('./images/军队.png'),
            tittle:'军队',
            text:'凝思安全操作系统全面支撑了总参某部的网站服务、邮件服务、文件服务、业务软件和专用加密卡的安全运行，使用凝思双机热备软件保证其高可用性。',
            link: '/Solutions/Army'
        },
        {
            id:'04',
            imgUrl:require('./images/科研.png'),
            tittle:'科研院所',
            text:'凝思安全操作系统全面支撑了江苏省信息技术应用研究所、北京信息技术研究所的邮件服务、DNS服务CA和数据库等服务的平稳运行。',
            link: '/Solutions/ResearchInstitutes'
        },
        {
            id:'05',
            imgUrl:require('./images/政府.png'),
            tittle:'政府机关',
            text:'凝思软件为财政部、农业部、气象局等20个部委的业务运行提供凝思安全服务器。为安全部在全国31个省厅部署凝思安全服务器支持其CA平台业务。',
            link: '/Solutions/Government'
        },
    ],
};
const mutations = {

};
const actions = {

};
const getters = {
    
};
export default {
	state,
	mutations,
	actions,
	getters
}
