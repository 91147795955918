<!-- 首页-生态伙伴 -->
<template>
        <div class="box" id="partners">
            <div class="title-font1" id="title-font1-partners">生态伙伴
                <div></div>
            </div>
            <div id="box-in-partners" :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1">
                <ul class="cell-in-partners"> 
                    <li><router-link to="/EcologicalCooperation/EcologicalPartner"><img src="./images/生态伙伴.png"></router-link></li> 
                    <li><router-link to="/EcologicalCooperation/EcologicalPartner"><img src="./images/生态伙伴.png"></router-link></li> 
                </ul>
            </div>
        </div>
</template>

<script>
export default {
    name: 'EcologicalPartners',
    data() {
      return {
        bodyWidthMut: document.body.clientWidth,
      }
    },
	mounted() {
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
	},
}
</script>

<style scoped>
#partners .title-font1{
    padding-top: 90px;
    line-height: 100px;
}
#partners .title-font1 div{
    bottom: 0px;
}
#box-in-partners{
    padding: 40px 0 0;
    width: 100%;
    overflow: hidden;
    position:relative;
} 
.cell-in-partners{ /*通过在轮播图后面增加同样的图片来遮挡每次移动结束后的空白间隙，所以需要给足够容纳ul里面所有图片的长度的值*/ 
    width:20000px;
    position:relative;
    list-style: none; 
} 
.cell-in-partners > li{
    display:inline-block;
    position:relative;
    animation: imgAnimation 130s linear infinite; /*infinite为无限循环，让轮播无止尽的循环下去*/    
} 
.cell-in-partners > li img{ 
    vertical-align: middle;
    height:432px;
} 
@keyframes imgAnimation{ 
    from{
	    transform: translateX(0%); 
    } 
    to{ /*设置让动画每移动到添加之前的长度之后从新恢复到起始位置*/ 
        transform: translateX(-100%); 
    } 
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#box-in-partners{
		padding: 40px 0;
	} 
}
/*手机端*/
@media only screen and (max-width:750px){
    #partners{
		background-color:#f7f7f7;
	}
	#partners .title-font1{
		line-height: 100px;
		padding-top:75px;
	}
	#partners .title-font1 div{
		bottom: 15px;
	}
	#box-in-partners{
		padding: 25px 0 38px;
		margin-bottom:-18px;
	}
    .cell-in-partners > li img{ 
        height: 340px;
    } 
}
/*手机端1080
@media only screen and (min-height:1300px){
    #partners{
		margin-top:-40px;
		background-color:#f7f7f7;
	}
	#partners .title-font1{
		line-height: 300px;
	}
	#partners .title-font1 div{
		bottom: 46px;
	}
    #box-in-partners{
        padding: 40px 0 108px;
        margin-bottom:-40px;
        width: 100%;
        overflow: hidden;
        position:relative;
    } 
    .cell-in-partners{ 通过在轮播图后面增加同样的图片来遮挡每次移动结束后的空白间隙，所以需要给足够容纳ul里面所有图片的长度的值
        width:40000px;
        position:relative;
        list-style: none; 
    } 
    .cell-in-partners > li{
        display:inline-block;
        position:relative;
        width:12539px;
    
    } 
    .cell-in-partners > li img{ 
        display:inline-block;
        width:12539px;
        vertical-align: middle;
    } 
}*/
</style>
