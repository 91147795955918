<template>
	<div class="Catalogshrink" :class="opc ? '' :'Catalogshrinkclose'">
        <p v-for ="(item,index) in introduction" :key="index">{{item}}</p>
        <div class="opc" :class="opc ? 'open' :'close'"><span
            class="click-oc"
            @click="opc = !opc"
        ><img src="../../../images/收起.png"/></span></div>
    </div>
</template>

<script>
export default {
    name: 'Catalogshrink',
    data(){
        return{
            opc:false,
			introduction: this.$attrs.introduction,
        }
    },   
}
</script>

<style scoped>
/*文本展开*/
.CatalogDetails .Catalogshrink{
	position: relative;
	width:calc(100% - 2px);
	background-color:#f5f6f7;
	padding:28px 0 64px;
	border:1px solid #edeff0;
	margin-top:10px;
	overflow:hidden;
}
.CatalogDetails .Catalogshrinkclose{
	height:calc(248px - 28px - 64px - 2px);
}
.CatalogDetails .Catalogshrink p{
	margin:12px auto 0;
	width:816px;
	line-height:24px;
	color:#262b33;
	font-weight:300;
}
.CatalogDetails .Catalogshrink p:first-child{
	margin-top:0;
}
.CatalogDetails .Catalogshrink div{
	position: absolute;
	width:100%;
	height:123px;
	left:0;
	bottom:0;
}
.CatalogDetails .Catalogshrink .close{
	background-image:linear-gradient(rgba(245,245,245,0) 0, rgba(245,245,245,1) 51px);
}
.CatalogDetails .Catalogshrink div span{
	font-size: 16px;
	position: absolute;
	left:35px;
	letter-spacing: 0px;
	font-weight: 400;  
	line-height: 16px;
	color:#565d69;
	cursor:pointer;
	bottom:32px;
	text-indent: 0;
}
.CatalogDetails .Catalogshrink .close span:before{
	content:"展开更多";
}
.CatalogDetails .Catalogshrink .open span:before{
	content:"收起";
}
.CatalogDetails .Catalogshrink div span img{
	width:13px;
	height:8px;
	margin-bottom:2px;
	margin-left:8px;
	transition:transform 0.15s;
}
.CatalogDetails .Catalogshrink .close span img{
	transform:rotate(0deg);
}
.CatalogDetails .Catalogshrink .open span img{
	transform:rotate(180deg);
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.CatalogDetails .Catalogshrink p, .CatalogDetails .CatalogshrinkShort p{
		width: 656px;

	}
	/*产品展示*/
	.CatalogDetails .productDisplay{
		grid-gap: 35px 40px;
		justify-content:center;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	/*文本展开*/
	.CatalogDetails .Catalogshrink{
		width:calc(100% - 1px - 30px);
		padding:21.5px 0 52px;
		border:0.5px solid #edeff0;
		margin:20px auto 30px;
	}
	.CatalogDetails .Catalogshrinkclose{
		height:calc(197px - 20.5px - 52px - 1px);
	}
	.CatalogDetails .Catalogshrink p{
		margin:16px 30px 0;
		width:calc(100% - 60px);
		line-height:21px;
		text-indent: 2em;
	}
	.CatalogDetails .Catalogshrink div{
		height: 102px;
	}
	.CatalogDetails .Catalogshrink .close{
		background-image:linear-gradient(rgba(245,245,245,0) 0, rgba(245,245,245,1) 40.5px);
	}
	.CatalogDetails .Catalogshrink div span{
		left:auto;
		right: 30px;
		font-size:13px;
		line-height: 13px;
		font-weight:500;
		cursor:pointer;
		bottom:25px;
		text-indent: 0;
	}
	.CatalogDetails .Catalogshrink div span img{
		width:10px;
		height:6px;
		margin-bottom:1px;
		margin-left:4px;
	}
}
/*手机端1080
@media only screen and (min-height:1300px){
	.CatalogDetails .Catalogshrink{
		width:calc(100% - 4px - 88px);
		padding:62px 0 144px;
		border:2px solid #edeff0;
		margin:58px auto 86px;
	}
	.CatalogDetails .Catalogshrinkclose{
		height:calc(386px - 62px - 144px - 4px);
	}
	.CatalogDetails .Catalogshrink p{
		margin:24px 86px 0;
		line-height:60px;
		text-indent: 0;
	}
	.CatalogDetails .Catalogshrink div{
		height:283px;
	}
	.CatalogDetails .Catalogshrink .close{
		background-image:linear-gradient(rgba(245,245,245,0) 0, rgba(245,245,245,1) 131px);
	}
	.CatalogDetails .Catalogshrink div span{
		left:auto;
		right: 86px;
		font-size:38px;
		line-height: 38px;
		font-weight:500;
		cursor:pointer;
		bottom:72px;
		text-indent: 0;
	}
	.CatalogDetails .Catalogshrink div span img{
		width:28px;
		height:17px;
		margin-bottom:4px;
		margin-left:12px;
	}
}*/
</style>
