<template>
	<div class="fontmain">
		<Verification v-if="verShow"></Verification>
		<SideNavigation :title4.sync="title4" :title4List="DocumentDownloadList"></SideNavigation>    
		<div
			class="font"
			v-for="(item,index) in DocumentDownloadList"
			:key="index"
			v-show="title4 == index"
			id="downfont"
		>
			<h1>
				<img :src="icon" alt="item.tittle">
				<span class="titleOfh1" v-html="item.tittle"></span>
				<span class="tips">*以下可通过点击直接下载</span>
			</h1>
			<h2>
                <span class="name">文档名称</span>
                <span class="md5">md5值</span>
                <span class="time">发布日期</span>
            </h2>
			<p v-for="(text,indextext) in item.text" :key="indextext">
                <a
					class="Pname"
					:style="nowUrls.includes(text.downUrl) ? 'pointer-events:none' : ''"
					@click="testing(text.downUrl)"
				>{{text.name}}</a>
                <span class="Pmd5">{{text.md5}}</span>
                <span class="Ptime">{{text.time}}</span>
            </p>
        </div>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Verification from '@/components/Verification'

export default {
	name:'DocumentDownload',
	components: {
		Verification
	},
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '文档下载',
			natittle3: '操作系统',
			title4: 0,
			icon: require('../images/icon-文档下载.png'),
		}
	},
	computed:{
		...mapState({
			DocumentDownloadList:(state)=>state.Service.DocumentDownloadList,
			nowUrls:(state)=>state.Service.nowUrls,
			verShow:(state)=>state.Service.verShow,
		})
	},
	methods: {
		testing(url) {
			this.$store.commit('getNowUrl', url)
			this.$store.commit('getVerShow', true)
		}
	},
	mounted() {
		if(this.$route.query.title4){
			console.log(1)
			this.title4 = this.$route.query.title4;
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.DocumentDownloadList[this.title4].tittle;
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
.titleOfh1 >>> br{
	display: none;
}
</style>
