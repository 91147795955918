<template>
	<div>
		<div id="boxTittle3" class="boxTittle3h">
			<div id="tittle3">
				<a @click="ptitle=0" class="celltittle3" :class="{intittle3:ptitle==0}">凝思安全操作系统<br>V6.0.42</a>
				<a @click="ptitle=1" class="celltittle3" :class="{intittle3:ptitle==1}">凝思安全操作系统<br>V6.0.60</a>
				<a @click="ptitle=2" class="celltittle3" :class="{intittle3:ptitle==2}">凝思安全操作系统<br>V6.0.80</a>
				<a @click="ptitle=5" class="celltittle3" :class="{intittle3:ptitle==5}">凝思安全操作系统<br>龙蜥版V6.0.98</a>
				<a @click="ptitle=6" class="celltittle3" :class="{intittle3:ptitle==6}">凝思安全操作系统<br>欧拉版V6.0.99</a>
				<a @click="ptitle=3" class="celltittle3" :class="{intittle3:ptitle==3}">凝思安全操作系统<br>V6.0.100</a>
				<a @click="ptitle=4" class="celltittle3" :class="{intittle3:ptitle==4}">凝思工业互联网安<br>全操作系统</a>
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<!-- 凝思安全操作系统V6.0.42 -->
			<div class="fontBox" v-if="ptitle==0">
				<div class="textBox">
					<h2>凝思安全操作系统V6.0.42</h2>
					<p>凝思安全操作系统V6.0.42提供了<span>强制访问控制、强制运行控制、强制能力控制、访问控制列表、四权分立的系统管理、安全增强的对外服务、安全辅机、GPS时钟、热备与互备</span>等安全机制和安全措施。</p>
					<div class="textDetails">
						<p>作为一个<span class="overstriking">具备等保四级要求</span>的安全操作系统，达到了以下安全目标：</p>
						<div class="figureweight">
							<p class="figure figure1">抵御网络环境下以“代码注入”为代表的复杂技术攻击。</p>
							<p class="figure figure2">记录用户的安全相关行为，同时审计信息必须得到正确和完整的记录。</p>
							<p class="figure figure3">任何错误/恶意的软件或用户都不能绕过系统的安全政策。</p>
							<p class="figure figure4">系统中的用户和进程只能具备特定的访问权限，而该权限必须在访问控制安全策略的限制之下。</p>
							<p class="figure figure5">能够检测和响应系统的不安全事件。</p>
						</div>
						<div>
							<p class="figure figure6">根据用户的身份控制资源访问，只有授权用户才能指定资源可以被哪些用户访问。</p>
							<p class="figure figure7">保证其安全状态如实地传达给管理员或用户。</p>
							<p class="figure figure8">在出现特定故障时，系统能继续提供服务；在排除故障过程中，服务不会终止。</p>
							<p class="figure figure9">保证组织安全策略得以在目的环境中得到实施。</p>
							<p class="figure figure10">保证以维护系统安全的方式控制和管理系统。</p>
						</div>
					</div>
				</div>
				<div class="textBox textBoxBackground">
					<h2>V6.0.42版本特点</h2>
					<!-- <FeatureBox :feature="v42"></FeatureBox> -->
					<div class="featureBox">
						<div class="featureText1">
							<div class="text">
								<h4>高安全性</h4>
								<p>高安全性是凝思安全操作系统的主要特征。凝思安全操作系统拥有一套完整的安全机制，这些安全机制均实现于操作系统核心，能够充分保证安全策略的强制性。系统还对应用程序的各个层次进行安全增强，使系统成为安全的有机整体。这些安全机制的合理配置和综合应用可实现多种安全策略，有效防止网络攻击对服务器系统造成的破坏，防止病毒的感染和传播，避免木马植入引起的敏感信息泄露，大大减少系统安全隐患，提高系统的安全性。</p>
							</div>
							<div class="text">
								<h4>高可用性</h4>
								<p>凝思安全操作系统发布前进行了长时间的压力测试，能够保证在高内存和CPU负载环境下稳定运行，为各类应用提供稳定的运行平台。</p>
								<p class="notextIndent">为进一步提高操作系统稳定性，凝思安全操作系统还提供多种冗余容错机制，降低部件故障引起的整机失效。这些机制包括：</p>
								<div>
									<div>
										<p><span>磁盘冗余技术</span>提供软RAID技术，通过磁盘冗余降低磁盘故障引起的系统风险。支持在线重建，减少系统恢复时间，提高系统稳定性。</p>
										<p><span>网卡冗余技术</span>供网卡的负载均衡和冗余备份，当两块网卡自身及其链路正常时，可提供网卡负载均衡功能，提高网络传输效率；当一块网卡或其链路发生故障时，另一网卡仍可继续提供服务，提高网络的整体可靠性。</p>
										<p><span>磁盘阵列卡冗余技术</span>支持磁盘阵列卡的主备机制，当主卡或其通信链路发生故障时，凝思安全操作系统将自动切换至备份卡进行设备访问，保证磁盘阵列数据的连续性。</p>
									</div>
									<div>
										<p><span>软件固化技术</span>支持关键操作系统数据以只读形式存储于电子盘中，防止恶意或偶然操作破坏系统数据。支持无硬盘工作模式，系统运行在基于电子盘的环境中，进一步提高系统稳定性。</p>
										<p><span>双机热备和服务热切换机制</span>支持分布式双机数据热备份和服务热切换机制，当主服务器当机时服务将自动漂移至辅服务器，由于数据实现实时同步，在服务器单点故障时，服务仍不会终止，系统的整体稳定性得以充分保障。</p>
									</div>
								</div>
							</div>
							<div class="text">
								<h4>高兼容性</h4>
								<p>凝思安全操作系统适用于从大型计算到桌面办公等各种环境，支持各类通用和专业应用，具有良好的软硬件兼容性。凝思安全操作系统的API接口和实用工具完全遵循POSIX标准，并支持LSB和FHB等Linux相关标准，能够二进制兼容各类为Linux系统开发的应用软件，并可在二进制保持与其它Linux发行版的兼容。支持基于Java的跨平台软件，可实现基于多款中间件软件的Web应用系统。支持32位和64位应用程序，使用户在32位系统上开发的软件可直接运行于64系统，缩短用户应用系统的研发周期。凝思安全操作系统提供丰富的驱动程序，支持各类主流磁盘驱动器、网卡驱动器和显示控制器等硬件设备。兼容国内外各大主流厂商的多款服务器和桌面计算机。凝思软件还可协助第三方硬件厂商完成驱动程序的研发和移植，实现加密卡等专用硬件设备支持。</p>
							</div>
							<div class="text">
								<h4>高效性</h4>
								<p>凝思安全操作系统可针对服务器、工作站、专用设备和桌面环境进行特别优化，获得比通用操作系统更高的运行效率。在特定项目的应用环境中，凝思可以还可针对应用系统的特性对操作系统做进一步的优化，使系统的运行效率最大化。各个操作系统内核组件的定制和优化，可充分发挥硬件平台的性能，对应用程序提供最佳支持，构建稳定、高效的计算环境。定制的内容包括：</p>
								<div>
									<div>
										<p>定制和剪裁最小应用软件运行环境，保证系统组件的可控性，提高系统的运行效率和可用性。</p>
										<p>定制进程调度策略，减少调度开销，提高操作系统和应用程序的响应速度和运行效率。</p>
									</div>
									<div>
										<p>定制文件系统类型和存储模式，提供可靠和有效的文件系统支持，使应用程序能够快速访问文件数据。</p>
										<p>定制驱动程序，实现用户程序对设备数据的快速访问，降低设备访问的系统资源开销，提高应用系统的数据处理能力。</p>
									</div>
								</div>
							</div>
							<div class="text">
								<h4>易维护性</h4>
								<p>凝思安全操作系统的配置、使用和维护方法与传统UNIX和Linux保持一致，提供丰富的管理和维护软件，既可通过命令行工具完成系统配置和维护，又可通过图形界面完成相关操作，简化用户操作步骤，减少管理员的维护工作量。支持键盘/显示器、串口和网络等多种接入方式，便于管理员的本地和远程管理。基于网络的远程管理支持加密通道的远程登录，可使用命令行方式管理远程系统；同时支持远程图形化管理和远程桌面重定向，为管理员和用户提供图形界面的系统维护和操作。服务自启动功能使系统无须人工干预即可自动进入服务提供状态，减少系统故障恢复时间，提高系统可获得性。丰富的审计日志使管理员能够准确分析并定位各类系统故障，为快速排除问题，恢复系统正常运行提供支持。凝思安全操作系统还提供状态显示、声音报警、邮件和短信通知等多种报警机制，使管理员能够及时掌握系统的运行状态，第一时间获取系统紧急故障和安全性信息。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思安全操作系统V6.0.60 -->
			<div class="fontBox" v-if="ptitle==1">
				<div class="textBox">
					<h2>凝思安全操作系统V6.0.60</h2>
					<p>凝思安全操作系统V6.0.60进行了以下改进和完善：</p>
					<div class="textDisplay">
						<div>
							<h4>硬件兼容性</h4>
							<p>凝思系统V6.0.60支持x86、x86_64、PPC等CPU，IDE、RAID、USB和SCSI控制器，HBA控制器，NVIDIA、ATI显卡，能在主流厂商的服务器、PC、公共机及笔记本上正常运行。</p>
						</div>
						<div>
							<h4>软件兼容性</h4>
							<p>凝思安全操作系统V6.0.60上可稳定运行多种应用软件。</p>
						</div>
						<div>
							<h4>软件包管理机制</h4>
							<p>凝思安全操作系统V6.0.60采用deb包管理方式，能够更好地处理软件依赖关系，便于本地管理以及远程升级软件包。</p>
						</div>
						<div>
							<h4>网络部署</h4>
							<p>凝思安全操作系统V6.0.60提供网络部署模块，支持全自动网络安装，提高大规模部署的工作效率，降低安装出错几率。</p>
						</div>
						<div>
							<h4>能量控制</h4>
							<p>在能量控制研究方面，经过仔细分析计算机中CPU、硬盘、显卡等硬件的耗电情况，根据实际应用要求，最终确定以控制CPU频率、内核节电配置和用内存盘缓存硬盘读写的方案实现能耗的降低。通过方案的实施，达到了相对原有系统能耗降低0.5%的目标。</p>
						</div>
						<div>
							<h4>速率优化</h4>
							<p>在速率优化方面，分别针对AMD和Intel的体系结构进行了研究。主要在内核中针对不同体系结构的选项进行筛选，在编译系统的过程中，为不同的体系结构配置了不同的编译选项。</p>
						</div>
						<div class="longGrid">
							<h4>凝思安全机制</h4>
							<h5>强制访问控制（Mandatory Access Control，简称MAC）</h5>
							<p>在6.0.42系统实现的强制访问控制功能中，强制访问控制策略中的规则是固定的，数据流动需同时满足BLP策略和BIBA 策略。规则固定，主体和客体标签的含义也是固定的，分别代表BLP级别（category）、BLP类别（class）以及BIBA 的完整性级别（integrity）。而6.0.60系统中实现的强制访问控制的规则是可以配置的，标签的含义也是可以配置的，并且可以配置为完全覆盖6.0.42系统的强制访问控制机制。另外，与6.0.42不同的是，6.0.60的标签是全系统的，每个主体或客体都带有标签。</p>
							<h5>强制行为控制（Mandatory Behavior Control，简称MBC）</h5>
							<p>MBC是6.0.42系统中MEC（Mandatory Execution Control，强制运行控制）的增强。MEC是对进程执行系统调用execve进行控制，允许或拒绝进程execve某些程序文件。MBC在MEC的基础上将控制的行为从执行扩展到读、写、和执行。</p>
							<h5>强制能力控制（Mandatory Capability Control，简称MCC）</h5>
							<p>MCC利用能力，针对传统UNIX模型进行优化，将特权细分成不同的能力，从而可以避免因挟持特权进程而产生的安全威胁。这和6.0.42系统的目的是一致的，但是我们采用了完全不一样的实现方式。</p>
						</div>
					</div>
				</div>
				<div class="textBox textBoxBackground">
					<h2>凝思安全操作系统V6.0（服务器版和工作站版）安全功能特点</h2>
					<!-- <FeatureBox6 :feature="v60"></FeatureBox6> -->
					<div class="featureBox">
						<div class="featureText2">
							<div>
								<div class="text">
									<h4>身份鉴别</h4>
									<p>对登录用户的身份进行强化鉴别。</p>
									<p>可以严格限制登录系统的用户，保证身份安全。</p>
								</div>
								<div class="text">
									<h4>强制行为控制MBC</h4>
									<p>在操作系统核心监视受限进程的行为范围，一旦出现越权行为即立即终止该进程，并在日志中记录该过程。</p>
									<p>可以有效防止以“代码注入”等方法获得系统的最高控制权，充分抵御网络攻击。</p>
								</div>
								<div class="text">
									<h4>强制能力控制MCC</h4>
									<p>支持对操作系统数据进行强制的访问限制。</p>
									<p>实现最小特权的原则，降低系统安全隐患，增强系统的整体安全性。</p>
								</div>
								<div class="text">
									<h4>访问控制列表ACL</h4>
									<p>支持对操作系统数据访问的细粒度控制。</p>
									<p>可以按照组织策略来规定数据的访问规则，限制敏感信息的流动。</p>
								</div>
								<div class="text">
									<h4>强制访问控制MAC</h4>
									<p>支持对操作系统数据进行强制的访问限制。</p>
									<p>可以按照组织策略来规定数据的访问规则，限制敏感信息的流动。</p>
								</div>
								<div class="text">
									<h4>安全审计</h4>
									<p>提供操作系统各项操作的审计日志。</p>
									<p>丰富的审计日志使管理员可以准确了解当前系统和应用的运行状况，在问题发生时及时定位故障。</p>
								</div>
							</div>
							<div>
								<div class="text">
									<h4>四权分立</h4>
									<p>消除系统中的超级用户，将超级用户的管理权限分给不同的管理员。</p>
									<p>避免管理员由于误操作给系统带来安全威胁。</p>
								</div>
								<div class="text">
									<h4>无root运行环境</h4>
									<p>系统中各类进程均以非root身份运行。</p>
									<p>系统遭受攻击后，即使进程身份被窃取，也无法对系统造成打击。</p>
								</div>
								<div class="text">
									<h4>内核级的防火墙</h4>
									<p>提供内核集成的防火墙工具，有效实现IP数据包的信息过滤。</p>
									<p>可以有效控制出入系统的网络数据类型和访问许可范围。</p>
								</div>
								<div class="text">
									<h4>冗余容错功能</h4>
									<p>在操作系统层面支持硬盘、网卡等核心部件的冗余。</p>
									<p>当硬件故障时，支持在线重建，减少系统恢复时间，提高系统稳定性。</p>
								</div>
								<div class="text">
									<h4>高可用性</h4>
									<p>操作系统层支持双机或多机热备。</p>
									<p>可以保障操作系统和上层应用的连续运行。</p>
								</div>
								<div class="text">
									<h4>软件固化</h4>
									<p>支持将操作系统或业务系统的核心数据以只读形式存储在电子盘中。</p>
									<p>防止恶意或偶然操作破坏系统数据。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思安全操作系统V6.0.80 -->
			<div class="fontBox"  v-if="ptitle==2">
				<div class="textBox">
					<h2>凝思安全操作系统V6.0.80</h2>
					<p>凝思安全操作系统V6.0.80进行了以下改进和完善：</p>
					<div class="textDetails figureweight">
						<div>
							<p class="figure figure1">采用V4.9.x内核；</p>
							<p class="figure figure2">支持x86_64、PPC64el架构CPU；</p>
							<p class="figure figure3">采用systemd，支持更快的启动速度，兼容原sysvinit机制；</p>
							<p class="figure figure4">支持qemu2.1、vmware9.4、virtualbox4.3.36等虚拟化软件；</p>
							<p class="figure figure5">支持cm/cdh 5.9、华三大数据平台等；</p>
							<p class="figure figure6">支持docker 1.11.2；</p>
							<p class="figure figure7">支持gcc4.9.2、perl5.2、python2.7.9、php5.6.30、openjdk7u75等多种开发语言；</p>
							<p class="figure figure8">采用2.19版libc；</p>
							<p class="figure figure9">支持gnome 3.14、kde 4.11、mate 1.8、xfce 4.10等多种图形环境；</p>
						</div>
						<div>
							<p class="figure figure10">支持LibreOffice 4.3.3、icedove 31.6、iceweasel 31.6等多种办公软件；</p>
							<p class="figure figure11">提供强制访问控制（MAC）机制，支持基于安全标记，按照组织安全策略决定访问许可；</p>
							<p class="figure figure12">提供强制行为控制（MBC）机制，控制进程可执行程序的范围，防范恶意代码攻击；</p>
							<p class="figure figure13">提供强制能力控制（MCC）机制，缩小系统TCB，消除超越安全机制的特权程序，降低系统安全隐患；</p>
							<p class="figure figure14">提供四权分立的系统管理机制，各系统管理员相互牵制，不能独立控制系统，防止管理员恶意或偶然操作破坏系统安全；</p>
							<p class="figure figure15">提供无root运行模式，禁止root登录，禁止用户进程切换为root身份，保护关键数据的私密性和完整性。</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思安全操作系统龙蜥版V6.0.98 -->
			<div class="fontBox"  v-if="ptitle==5">
				<div class="textBox">
					<h2>凝思安全操作系统龙蜥版V6.0.98</h2>
					<p>凝思安全操作系统龙蜥版基于OpenAnolis社区版，凝聚自身多年系统层创新技术发布，内置凝思固域内核安全子系统，兼容CentOS生态，支持 X86、ARM、RISC-V 等多种主流芯片架构和计算场景，完善适配Intel、飞腾、海光、兆芯、鲲鹏等芯片，面向云端深度优化，为云上典型场景带来40%的综合性能提升，故障率降低50%，支持一键迁移，提供全栈国密算法安全特性，适用于云计算、云原生、高性能计算等应用场景，为各行业应用提供专业稳定的系统支撑。</p>
					<div class="textDetails specifications">
						<h2>产品规格</h2>
						<div v-for="(item, index) of v98" :key="index">
							<h4>{{ item.t }}</h4>
							<p>{{ item.p }}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思安全操作系统欧拉版V6.0.99 -->
			<div class="fontBox"  v-if="ptitle==6">
				<div class="textBox">
					<h2>凝思安全操作系统欧拉版V6.0.99</h2>
					<p>凝思安全操作系统欧拉版是面向政企、金融、能源、通信等各行业设计的通用企业级服务器操作系统，在openEuler社区版的基础上，集成凝思固域内核安全子系统，融入众多的自研竞争力特性，提供高性能、稳定可靠、安全易用的业务体验，进一步完善生态支持，并提供专业的技术服务，适用于服务器、云计算、边缘计算、嵌入式等应用场景。</p>
					<div class="textDetails specifications">
						<h2>产品规格</h2>
						<div v-for="(item, index) of v99" :key="index">
							<h4>{{ item.t }}</h4>
							<p>{{ item.p }}</p>
						</div>
					</div>
					<div class="textDetails download">
						<h2>相关资源</h2>
						<div>
							<router-link to="/ServiceAndSupport/SoftwareDownload">软件下载</router-link>
							<router-link :to="{path:'/ServiceAndSupport/DocumentDownload',query:{'title4': 4}}">文档下载</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思安全操作系统V6.0.100 -->
			<div class="fontBox"  v-if="ptitle==3">
				<div class="textBox">
					<h2>凝思安全操作系统V6.0.100</h2>
					<p>凝思安全操作系统V6.0.100在以下方面对系统进行了改进和完善：</p>
					<div class="textDetails figureweight">
						<div>
							<p class="figure figure1"><b>内核版本</b>采用4.19.146版内核；</p>
							<p class="figure figure2"><b>CPU架构</b>支持X86_64、ARM64、PPC64el、PowerPC、LoongArch64等多种CPU；</p>
							<p class="figure figure3"><b>系统后台服务</b>采用systemd，支持更快的启动速度，兼容原先的sysvinit；</p>
							<p class="figure figure4"><b>虚拟化软件</b>支持qemu 3.1等虚拟化软件；</p>
							<p class="figure figure5"><b>大数据平台</b>支持cm/cdh 6.x、华三大数据平台等；</p>
						</div>
						<div>
							<p class="figure figure6"><b>容器支持</b>支持docker 1.5；</p>
							<p class="figure figure7"><b>开发语言</b>支持gcc-8.3.0、perl 5.28、python 2.7/3.7、php 7.3、jdk1.11等多种开发语言；</p>
							<p class="figure figure8"><b>libc版本</b>采用2.28版libc；</p>
							<p class="figure figure9"><b>图形环境</b>支持gnome 3.30、kde 3.53、mate 1.20、xfce 4.12等多种图形环境；</p>
							<p class="figure figure10"><b>办公软件</b>支持LibreOffice 6.1.5、thunderbird 68.12、iceweasel 68.12。</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 凝思工业互联网安全操作系统 -->
			<div class="fontBox" v-if="ptitle==4">
				<div class="textBox ">
					<div class="textImage">
						<div>
							<h2>凝思工业互联网安全操作系统</h2>
							<p>凝思工业互联网安全操作系统面向<span>电力、石油、交通等工业控制领域</span>的应用需求，满足工控系统对可靠性、实时性、安全性、连续性的特殊要求，基于工控领域主流CPU和安全可控CPU芯片，突破支持抢先的实时微内核、支持抢先的宏内核实时功能增强、国产处理器适配与优化、网络功能增强、安全功能增强、可靠性增强、存储管理优化等关键技术。</p>
						</div>
						<img src="./images/工业互联网.png"/>
					</div>
				</div>
				<div class="textBox">
					<h2>凝思工业互联网安全操作系统具有以下技术特点：</h2>
					<div class="textDetails figureweight">
						<p class="figure figure1">任务处理实时性强，处理时间确定：支持实时抢先进程调度，任务响应延时&lt;50us。</p>
						<p class="figure figure2">系统稳定可靠，能够连续不中断运行：操作系统平均无故障时间&gt;20000小时。</p>
						<p class="figure figure3">具备高安全性，应对来自主机和网络的安全挑战：实现了网络安全标记、虚拟机网络受控通信、基于标记的软件受控运行、强制访问控制、强制行为控制、强制能力控制等安全机制，保证工控系统的安全。</p>
						<p class="figure figure4">适应多种硬件平台混合，实时非实时混合应用的场景：微宏协同的分层内核架构兼顾系统的实时性和应用支持，支持灵活拆分组合，适应实时、非实时和实时非实时混合的场景，满足工业领域全系统栈的需求。</p>
						<p class="figure figure5">支持工业以太网，提供实时网络协议栈：实现了基于通用控制器的实时网络协议栈、基于IP选项扩展的网络容错协议、精确时间协议等关键技术，解决了工业以太网制造商提供的网络协议栈和编程接口彼此不兼容、工控系统通信网络的容错能力较差，受制于国外专利、以太网的定时同步能力无法满足对实时性较高的工控系统要求等问题。</p>
						<p class="figure figure6">支持国产CPU和工控主流CPU：面向国产处理器的体系架构，发挥硬件特色，实现工控操作系统对国产处理器的支持，包括飞腾和龙芯等。</p>
						<p class="figure figure7">提供标准POSIX编程接口，降低应用程序开发难度：应用程序接口及实用工具符合POSIX标准，任何按照POSIX标准编写的应用程序均可以运行。</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'OperatingSystem',
	data() {
		return {
			ptitle:0,
			v42:[
				{
					id:'feature1',
					title:'高安全性',
					text:[
						'高安全性是凝思安全操作系统的主要特征。凝思安全操作系统拥有一套完整的安全机制，这些安全机制均实现于操作系统核心，能够充分保证安全策略的强制性。系统还对应用程序的各个层次进行安全增强，使系统成为安全的有机整体。这些安全机制的合理配置和综合应用可实现多种安全策略，有效防止网络攻击对服务器系统造成的破坏，防止病毒的感染和传播，避免木马植入引起的敏感信息泄露，大大减少系统安全隐患，提高系统的安全性。',
					],
				},
				{
					id:'feature2',
					title:'高可用性',
					text:[
						'凝思安全操作系统发布前进行了长时间的压力测试，能够保证在高内存和CPU负载环境下稳定运行，为各类应用提供稳定的运行平台。',
						'为进一步提高操作系统稳定性，凝思安全操作系统还提供多种冗余容错机制，降低部件故障引起的整机失效。这些机制包括：',
						'<span>磁盘冗余技术</span>：提供软RAID技术，通过磁盘冗余降低磁盘故障引起的系统风险。支持在线重建，减少系统恢复时间，提高系统稳定性。',
						'<span>网卡冗余技术</span>：供网卡的负载均衡和冗余备份，当两块网卡自身及其链路正常时，可提供网卡负载均衡功能，提高网络传输效率；当一块网卡或其链路发生故障时，另一网卡仍可继续提供服务，提高网络的整体可靠性。',
						'<span>磁盘阵列卡冗余技术</span>：支持磁盘阵列卡的主备机制，当主卡或其通信链路发生故障时，凝思安全操作系统将自动切换至备份卡进行设备访问，保证磁盘阵列数据的连续性。',
						'<span>软件固化技术</span>：支持关键操作系统数据以只读形式存储于电子盘中，防止恶意或偶然操作破坏系统数据。支持无硬盘工作模式，系统运行在基于电子盘的环境中，进一步提高系统稳定性。',
						'<span>双机热备和服务热切换机制</span>：支持分布式双机数据热备份和服务热切换机制，当主服务器当机时服务将自动漂移至辅服务器，由于数据实现实时同步，在服务器单点故障时，服务仍不会终止，系统的整体稳定性得以充分保障。',
					],
				},
				{
					id:'feature3',
					title:'高兼容性',
					text:[
						'凝思安全操作系统适用于从大型计算到桌面办公等各种环境，支持各类通用和专业应用，具有良好的软硬件兼容性。凝思安全操作系统的API接口和实用工具完全遵循POSIX标准，并支持LSB和FHB等Linux相关标准，能够二进制兼容各类为Linux系统开发的应用软件，并可在二进制保持与其它Linux发行版的兼容。支持基于Java的跨平台软件，可实现基于多款中间件软件的Web应用系统。支持32位和64位应用程序，使用户在32位系统上开发的软件可直接运行于64系统，缩短用户应用系统的研发周期。凝思安全操作系统提供丰富的驱动程序，支持各类主流磁盘驱动器、网卡驱动器和显示控制器等硬件设备。兼容国内外各大主流厂商的多款服务器和桌面计算机。凝思软件还可协助第三方硬件厂商完成驱动程序的研发和移植，实现加密卡等专用硬件设备支持。',
					],
				},
				{
					id:'feature4',
					title:'高校性',
					text:[
						'凝思安全操作系统可针对服务器、工作站、专用设备和桌面环境进行特别优化，获得比通用操作系统更高的运行效率。在特定项目的应用环境中，凝思可以还可针对应用系统的特性对操作系统做进一步的优化，使系统的运行效率最大化。各个操作系统内核组件的定制和优化，可充分发挥硬件平台的性能，对应用程序提供最佳支持，构建稳定、高效的计算环境。定制的内容包括：',
						'<span>定制和剪裁最小应用软件运行环境，保证系统组件的可控性，提高系统的运行效率和可用性。</span>',
						'<span>定制进程调度策略，减少调度开销，提高操作系统和应用程序的响应速度和运行效率。</span>',
						'<span>定制文件系统类型和存储模式，提供可靠和有效的文件系统支持，使应用程序能够快速访问文件数据。</span>',
						'<span>定制驱动程序，实现用户程序对设备数据的快速访问，降低设备访问的系统资源开销，提高应用系统的数据处理能力。',
					],
				},
				{
					id:'feature5',
					title:'易维护性',
					text:[
						'凝思安全操作系统的配置、使用和维护方法与传统UNIX和Linux保持一致，提供丰富的管理和维护软件，既可通过命令行工具完成系统配置和维护，又可通过图形界面完成相关操作，简化用户操作步骤，减少管理员的维护工作量。支持键盘/显示器、串口和网络等多种接入方式，便于管理员的本地和远程管理。基于网络的远程管理支持加密通道的远程登录，可使用命令行方式管理远程系统；同时支持远程图形化管理和远程桌面重定向，为管理员和用户提供图形界面的系统维护和操作。服务自启动功能使系统无须人工干预即可自动进入服务提供状态，减少系统故障恢复时间，提高系统可获得性。丰富的审计日志使管理员能够准确分析并定位各类系统故障，为快速排除问题，恢复系统正常运行提供支持。凝思安全操作系统还提供状态显示、声音报警、邮件和短信通知等多种报警机制，使管理员能够及时掌握系统的运行状态，及时获取系统紧急故障和安全性信息。',
					],
				},
			],
			v60:[
				{
					id:'feature6',
					title:'身份鉴别',
					text:[
						'特性:对登录用户的身份进行强化鉴别。',
						'优点:可以严格限制登录系统的用户，保证身份安全。',
					],
				},
				{
					id:'feature7',
					title:'高安全性',
					text:[
						'特性:在操作系统核心监视受限进程的行为范围，一旦出现越权行为即立即终止该进程，并在日志中记录该过程。',
						'优点:可以有效防止以“代码注入”等方法获得系统的最高控制权，充分抵御网络攻击。',
					],
				},
				{
					id:'feature8',
					title:'强制能力控制MCC',
					text:[
						'特性:支持对操作系统数据进行强制的访问限制。',
						'优点:实现最小特权的原则，降低系统安全隐患，增强系统的整体安全性。',
					],
				},
				{
					id:'feature9',
					title:'访问控制列表ACL',
					text:[
						'特性:支持对操作系统数据访问的细粒度控制。',
						'优点:可以按照组织策略来规定数据的访问规则，限制敏感信息的流动。',
					],
				},
				{
					id:'feature10',
					title:'强制访问控制MAC',
					text:[
						'特性:支持对操作系统数据进行强制的访问限制。',
						'优点:可以按照组织策略来规定数据的访问规则，限制敏感信息的流动。',
					],
				},
				{
					id:'feature11',
					title:'安全审计',
					text:[
						'特性:提供操作系统各项操作的审计日志。',
						'优点:丰富的审计日志使管理员可以准确了解当前系统和应用的运行状况，在问题发生时及时定位故障。',
					],
				},
				{
					id:'feature12',
					title:'四权分立',
					text:[
						'特性:消除系统中的超级用户，将超级用户的管理权限分给不同的管理员。',
						'优点:避免管理员由于误操作给系统带来安全威胁。',
					],
				},
				{
					id:'feature13',
					title:'无root运行环境',
					text:[
						'特性:系统中各类进程均以非root身份运行。',
						'优点:系统遭受攻击后，即使进程身份被窃取，也无法对系统造成打击。',
					],
				},
				{
					id:'feature14',
					title:'内核级的防火墙',
					text:[
						'特性:提供内核集成的防火墙工具，有效实现IP数据包的信息过滤。',
						'优点:可以有效控制出入系统的网络数据类型和访问许可范围。',
					],
				},
				{
					id:'feature15',
					title:'冗余容错功能',
					text:[
						'特性:在操作系统层面支持硬盘、网卡等核心部件的冗余。',
						'优点:当硬件故障时，支持在线重建，减少系统恢复时间，提高系统稳定性。',
					],
				},
				{
					id:'feature16',
					title:'高可用性',
					text:[
						'特性:操作系统层支持双机或多机热备。',
						'优点:可以保障操作系统和上层应用的连续运行。',
					],
				},
				{
					id:'feature17',
					title:'软件固化',
					text:[
						'特性:支持将操作系统或业务系统的核心数据以只读形式存储在电子盘中。',
						'优点:防止恶意或偶然操作破坏系统数据。',
					],
				},
			],
			v98: [
				{
					t: '内核版本',
					p: '4.19.91'
				},
				{
					t: 'CPU架构',
					p: '支持同源兼容ARM64、X86_64等多种架构CPU'
				},
				{
					t: '内存',
					p: '不小于4GB'
				},
				{
					t: '硬盘',
					p: '不小于32GB'
				},
				{
					t: '安装方式',
					p: '支持光盘、U盘、网络等多种方式，提供图形或字符界面安装'
				},
				{
					t: 'Glibc 版本',
					p: '2.28'
				},
				{
					t: 'GCC 版本',
					p: '8.5.0'
				},
				{
					t: 'Java 版本',
					p: '支持OpenJDK 1.8.0、11、17'
				},
				{
					t: '虚拟化',
					p: '支持KVM、QEMU、libvirt'
				},
				{
					t: '容器',
					p: '支持Docker、Podman'
				},
				{
					t: '大数据平台',
					p: '支持Hadoop'
				},
				{
					t: '图形环境',
					p: '支持GNOME、MATE'
				},
				{
					t: '软件兼容',
					p: '支持主流国产数据库产品以及通用数据库，支持主流中间件，以及各类办公软件和行业应用'
				},
				{
					t: '应用开发环境',
					p: '支持POSIX标准，提供丰富的开发工具，支持Java、C、C++、Python、Perl、Shell、Ruby、PHP、Tcl/Tk、Lisp等编程语言，提供Emacs等集成开发环境。'
				},
				{
					t: '升级方式',
					p: '支持本地升级，支持单包/补丁包升级'
				}
			],
			v99: [
				{
					t: '内核版本',
					p: '4.19.90'
				},
				{
					t: 'CPU架构',
					p: '支持同源兼容ARM64、x86_64架构的CPU'
				},
				{
					t: '内存',
					p: '不小于4GB'
				},
				{
					t: '硬盘',
					p: '不小于32GB'
				},
				{
					t: '安装方式',
					p: '支持光盘、U盘、网络等多种方式，提供图形或字符界面安装'
				},
				{
					t: 'Glibc 版本',
					p: '2.28'
				},
				{
					t: 'GCC 版本',
					p: '8.3.0'
				},
				{
					t: 'Java 版本',
					p: '支持OpenJDK 1.8.0、11'
				},
				{
					t: '虚拟化',
					p: '支持KVM、QEMU、libvirt'
				},
				{
					t: '容器',
					p: '支持Docker、iSulad'
				},
				{
					t: '大数据平台',
					p: '支持Hadoop'
				},
				{
					t: '图形环境',
					p: '支持GNOME、MATE'
				},
				{
					t: '软件兼容',
					p: '支持主流国产数据库产品以及通用数据库，支持主流中间件，以及各类办公软件和行业应用'
				},
				{
					t: '应用开发环境',
					p: '支持POSIX标准，提供丰富的开发工具，支持Java、C、C++、Python、Perl、Shell、Ruby、PHP、Tcl/Tk、Lisp等编程语言，提供Eclipse，KDevelop，Emacs等集成开发环境。'
				},
				{
					t: '升级方式',
					p: '支持本地升级，支持单包/补丁包升级'
				}
			]
		}
	},
	watch:{
        $route:{
            immediate:true,
            handler(){
                if(this.$route.query.ptitle >=0 && this.$route.query.ptitle < 7){
                    this.ptitle = this.$route.query.ptitle;
                }else {
                	this.ptitle = 0;
                }
            }
        }
    }
}
</script>
<style scoped>
#fontPdetail .textBox .specifications {
	margin: 48px 0 0;
}
#fontPdetail .textBox .specifications div {
	display: grid;
	margin: 0;
	width: 100%;
	grid-template-columns: 180px auto;
	border-bottom: 1px solid #dbdbdb;
}
#fontPdetail .textBox .specifications h2 + div {
	margin-top: 24px;
	border-top: 1px solid #dbdbdb;
}
#fontPdetail .textBox .specifications div h4 {
	text-align: center;
	font-size: 18px;
	line-height: 90px;
	font-weight: 500;
	color: #1b1e24;
}
#fontPdetail .textBox .specifications div p {
	font-size: 18px;
	line-height: 24px;
	font-weight: 300;
	color: #262b33;
	margin-bottom: 0;
	padding: 33px 60px;
}
#fontPdetail .textBox .download {
	margin: 64px 0 0;
}
#fontPdetail .textBox .download div {
	margin: 16px 0 0;
	width: 100%;
	display: grid;
	grid-gap: 28px;
	grid-template-columns: repeat(2, auto);
}
#fontPdetail .textBox .download div a {
	display: block;
	font-size: 18px;
	font-weight: 400;
	line-height: 60px;
	color: #262b33;
	text-align: center;
	border: 1px solid #ccc;
}
#fontPdetail .textBox .download div a:hover {
	background: #df2d38;
	color: #fff;
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontPdetail .textBox .specifications div {
		margin: 0 auto;
		width: calc(100% - 30px);
		grid-template-columns: 90px auto;
	}
	#fontPdetail .textBox .specifications div h4 {
		text-align: left;
		font-size: 15px;
		line-height: 50px;
	}
	#fontPdetail .textBox .specifications div p {
		font-size: 15px;
		line-height: 18px;
		padding: 16px 30px;
	}
	#fontPdetail .textBox .download div {
		width: calc(100% - 30px);
		margin: 0 auto;
	}
}
</style>
