/* News仓库 */
const state = {
	productsList: [
		{
			tittle:'产品概述',
			id:'ProductDescription',
			introduction:[
				'凝思的主要产品是凝思安全操作系统，凝思安全操作系统作为承上启下的基础软件，提供丰富的设备驱动程序，能够高效地完成硬件资源调度；提供丰富的接口函数库和实用工具，具有良好的软件兼容性，支持各类实时和非实时大型业务系统的安全、稳定和可靠运行。凝思固域安全子系统完全由凝思软件自主设计和研发，通过内核、接口函数库和实用工具层面的实现，与操作系统其它组件构成了有机整体，形成了高等级安全操作系统。',
				'凝思安全操作系统是北京凝思软件股份有限公司（简称：凝思软件）自主研发、拥有完全自主知识产权的操作系统，遵循国内外安全操作系统GB17859、GB/T18336、GJB4936、GJB4937、GB/T20272以及POSIX、TESEC、ISO 15408等标准，凝思还独立开发了其它系统软件，包括：凝思双机热备软件、凝思多路径软件、凝思资源控制软件、凝思触摸屏软件、凝思分布式文件系统、凝思网络冗余容错软件、凝思安全中文域名系统软件、IPV6/IPV4互访代理软件等。',
				'为满足用户特定的功能和性能需求，凝思软件为工信部某单位等用户开发了诸多定制产品，包括：海量连接系统、沙箱集群系统、零拷贝系统、透明代理系统、PE和非PE恶意代码检测系统等。',
				'为紧跟新技术发展方向，以系统性整体解决方案模式推广安全操作系统产品，凝思软件相继开展了下列软件系统的研发工作：凝思云平台软件系统、凝思大数据软件系统、互联网架构软件系统。',
				'凝思安全操作系统产品作为载体，承载了凝思软件所具备的能力、技术和服务。通过安全操作系统产品以及定制产品的深入开发，凝思积累了丰富的技术和经验，锻炼了人才队伍，使用户加深了对操作系统的认识，扩大了产品的应用领域，加速了凝思安全操作系统作为独立基础软件产品的推广。',
			],
		},
		{
			tittle:'操作系统',
			id:'OperatingSystem',
			link:'/Products/OperatingSystem',
			introduction:[
				'凝思安全操作系统是北京凝思软件股份有限公司（简称：凝思软件）自主研发、拥有完全自主知识产权的操作系统，遵循国内外安全操作系统GB17859、GB/T18336、GJB4936、GJB4937、GB/T20272以及POSIX、TESEC、ISO 15408等标准。',
				'凝思安全操作系统基于公司自主研发的安全子系统，提供底层软件系统的安全支持，能够充分防范缓冲器溢出、代码注入、病毒、木马和非授权访问等安全威胁，满足用户的各类高安全要求。',
				'凝思安全操作系统是国内首款符合信息系统安全等级保护四级（结构化保护级）标准并经公安部信息安全检测中心检验合格的安全操作系统，适用于等级保护四级及以下各级别的应用系统。同时，凝思安全操作系统也满足国家保密局分级保护标准对操作系统的要求，适用于各类涉密系统。',
				'在科技部863信息安全项目和国家发改委信息安全项目的支持下，基于凝思安全操作系统推出的安全服务器系统获得军用信息安全产品军B级认证，并成功地部署到政府和军队的关键部门。凝思安全操作系统也在国家电力、电信、安全、国防、机要、和政务等重点行业和部门得到广泛应用。',
				'凝思安全操作系统分为服务器版和图形工作站版，适用于各类涉密系统和等级保护四级及以下各级别的应用系统。',
			],
			productDisplay:[
				{
					title:'服务器版',
					text:[
						'完全自主设计构建，不存在技术和政治风险，具有高可靠性、高安全性、高性能和良好兼容性等特性',
					],
				},
			]
		},
		{
			tittle:'系统功能增强软件',
			id:'SystemEnhancements',
			link:'/Products/SystemEnhancements',
			introductionShort:[
				'凝思系统功能增强软件是在凝思安全操作系统基础之上构建出的软件包。用户使用这些软件包可以获得高可用性、高可靠性以及更多功能和服务。',
			],
			productDisplay:[
				{
					title:'凝思多路径软件',
					text:[
						'具备路径的管理、冗余、选择、调度、负载均衡、故障处理、恢复等功能，为用户提供安全、成熟、稳定、易用的多路径解决方案',
					],
				},
				{
					title:'凝思双机热备软件',
					text:[
						'通过对计算机硬件、网络、文件系统、进程等资源的监控，提供高可用、易管理的主备模式运行平台，保障业务系统连续运行',
					],
				},
				{
					title:'凝思资源控制软件',
					text:[
						'对系统中应用程序进程使用服务器软硬件资源的控制，有效解决应用程序对资源的无限制申请和占用问题',
					],
				},
				{
					title:'凝思触摸屏软件',
					text:[
						'可同时支持多个触摸屏幕，支持单点、两点触摸，提供简单、易用、成熟的触摸屏操作过程和体验',
					],
				},
			]
		},
		{
			tittle:'平台软件',
			id:'PlatformSoftware',
			link:'/Products/PlatformSoftware',
			introductionShort:[
				'凝思软件的主要平台软件包括凝思分布式文件系统、凝思云计算平台软件、凝思大数据平台软件、凝思虚拟化管理平台、凝思云桌面系统。',
			],
			productDisplay:[
				{
					title:'凝思云桌面系统',
					text:[
						'基于企业级虚拟化架构自主研发的终端桌面交付与管理系统，融合云计算、安全及审计、网络高可用等技术',
					],
				},
				{
					title:'凝思云计算平台软件',
					text:[
						'构建从中小企业到大型行业的私有云和公有云平台。可大幅降低企业IT成本，提高资源利用率；有力增强企业自身信息安全和防护能力',
					],
				},
				{
					title:'凝思大数据平台软件',
					text:[
						'提供海量级大数据的采集、传输、清洗、转换、存储、管理、查询和分析的统一平台',
					],
				},
				{
					title:'凝思分布式文件系统',
					text:[
						'通过网络将标准服务器的本地存储资源统一组织管理，构建去中心化、可动态扩展的存储资源池',
					],
				},
				{
					title:'凝思虚拟化管理平台',
					text:[
						'通过虚拟化技术将硬件资源融合为资源池。实现IT资源的动态分配、灵活调度、跨域共享，提高IT资源利用率',
					],
				},
			]
		},
		
		{
			tittle:'定制开发',
			id:'CustomDevelopment',
			link:'/Products/CustomDevelopment',
			introductionShort:[
				'凝思软件提供三类定制开发服务: 平台类产品定制，跨平台移植，系统层功能软件定制。',
			],
			productDisplay:[
				{
					title:'平台产品定制开发',
					text:[
						'平台类定制开发服务可以满足用户的个性化需求',
					],
				},
				{
					title:'跨平台移植开发',
					text:[
						'凝思软件为国家电网提供了跨平台移植开发服务',
					],
				},
				{
					title:'系统功能软件开发',
					text:[
						'异构系统文件共享、虚拟专用网络、统一标记集中管理、多屏显示、零拷贝系统、安全容器系统、海量连接系统、指纹仪认证平台软件、内网主机安全监管软件等',
					],
				},
			]
		},
		{
			tittle:'凝思安全服务器平台',
			id:'SecureServerPlatform',
			link:'/Products/SecureServerPlatform',
			productDisplay:[
				{
					title:'凝思安全服务器平台',
					text:[
						'是一款优秀的基础系统支撑平台，可以作为数据库服务、网页服务、邮件服务、文件共享服务、域名解析服务和CA服务等业务系统的基础支撑平台',
					],
				},
			]
		},
	],
};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
