<template>
	<div class="shrink" :class="openClose ? '' :'shrinkclose'">
		<p v-for ="(item,index) in shrinkList" :key="index">{{item}}</p>
		<div :class="openClose ? 'open' :'close'"><span
            class="click-oc"
            @click="openClose = !openClose"
        ><img src="../../images/收起.png"/></span></div>
	</div>
</template>

<script>
export default {
    name: 'Shrink',
	data() {
		return {
			openClose: false,
			shrinkList: this.$attrs.shrinkList,
		}
	},
}
</script>

<style scoped>
.shrink{
	position: relative;
	width:calc(1170px - 128px);
	background-color:#f5f5f5;
	padding:28px 64px 64px;
	margin-top:28px;
	margin-bottom:32px;
	overflow:hidden;
}
.shrinkclose{
	height:calc(248px - 28px - 64px);
}
.shrink p{
	margin:12px auto 0;
	line-height:24px;
	color:#262b33;
	font-weight:300;
}
.shrink p:first-child{
	margin-top:0;
}
.shrink div{
	position: absolute;
	width:1170px;
	height:123px;
	left:0;
	bottom:0;
}
.shrink .close{
	background-image:linear-gradient(rgba(245,245,245,0) 0px, rgba(245,245,245,1) 51px);
}
.shrink div span{
	font-size: 16px;
	position: absolute;
	left:64px;
	letter-spacing: 0px;
	font-weight: 400;  
	line-height: 16px;
	color:#565d69;
	cursor:pointer;
	bottom:32px;
	text-indent: 0;
	cursor:pointer;
}
.shrink .close span:before{
	content:"展开更多";
}
.shrink .open span:before{
	content:"收起";
}
.shrink div span img{
	width:13px;
	height:8px;
	margin-bottom:2px;
	margin-left:8px;
	transition:transform 0.15s;
}
.shrink .close span img{
	transform:rotate(0deg);
}
.shrink .open span img{
	transform:rotate(180deg);
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	.shrink{
		width:calc(100% - 128px);
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	/*文本展开*/
}
/*手机端*/
@media only screen and (max-width:750px){
	.shrink{
		position: relative;
		width:auto;
		background-color:#fff;
		padding:0px 0px 23.5px;
		margin:23px 15px 44px;
		overflow:hidden;
	}
	.shrinkclose{
		height:calc(147px - 23.5px);
	}
	.shrink p{
		margin-top:13px;
	}
	.shrink p:first-child{
		margin-top:0;
	}
	.shrink div{
		position: absolute;
		width:100%;
		height:147px;
		left:0;
		bottom:0;
	}
	.shrink .close{
		background-image:linear-gradient(rgba(255,255,255,0) 58px, rgba(255,255,255,1) 118px);
	}
	.shrink div span{
		position: absolute;
		left:15px;
		letter-spacing: 0px;
		font-weight: 400;  
		font-size:13px;
		cursor:pointer;
	}
	.shrink .close span{
		bottom:0;
		line-height: 13px;
	}
	.shrink .open span{
		bottom:0;
		line-height: 13px;
	}
	.shrink div span img{
		width:10px;
		height:6px;
		margin-bottom:1px;
		margin-left:5px;
		transition:transform 0.15s;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.shrink{
		position: relative;
		width:auto;
		background-color:#fff;
		padding:0px 0px 115px;
		margin:81px 44px 0;
		overflow:hidden;
	}
	.shrinkclose{
		height:calc(286px - 115px);
	}
	.shrink p{
		margin:18px 77px 0;
	}
	.shrink p:first-child{
		margin-top:0;
	}
	.shrink div{
		position: absolute;
		width:100%;
		height:286px;
		left:0;
		bottom:0;
	}
	.shrink .close{
		background-image:linear-gradient(rgba(255,255,255,0) 0, rgba(255,255,255,1) 175px);
	}
	.shrink div span{
		position: absolute;
		left:77px;
		letter-spacing: 0px;
		font-weight: 400;  
		font-size:40px;
		color:#333;
		cursor:pointer;
	}
	.shrink .close span{
		bottom:0;
		line-height: 115px;
	}
	.shrink .open span{
		bottom:0;
		line-height: 115px;
	}
	.shrink div span img{
		width:28px;
		height:17px;
		margin-bottom:4px;
		margin-left:16px;
		transition:transform 0.15s;
	}
} */
</style>
