<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<div class="font" id="fontNoBorder">
			<!-- <Shrink :shrinkList="shrinkList"></Shrink> -->
			<CaseDisplay :caseDisplay="caseDisplay" :path="path"></CaseDisplay>
		</div>
	</div>
</template>
<script>
export default {
	name:'Government',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '政府机关',
			path: '/Solutions/GovernmentDetails',
			shrinkList:[
				'缺少简介内容。',
			],
			caseDisplay:[
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'政府机关',
					text:'国家安全部及各省厅为保障CA系统的安全稳定使用基于凝思安全操作系统的凝思安全服务器'
				},
			]
		}
	}
}
</script>
