<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<ScientificResearch v-show="natittle3 == '科研院所'"></ScientificResearch>
	</div>
</template>

<script>
import ScientificResearch from './ScientificResearch.vue';

export default {
	name:'ResearchInstitutesDetails',
	components:{
		ScientificResearch
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	mounted(){
        if(this.natittle3 == '科研院所'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '科研院所'
        }
    },
}
</script>
<style>
#fontNoBorder > #tittlrImgResearchInstitutes1{
	background:url('../images/标题背景-科研.jpg') no-repeat center -71px;
	background-size: 1176px 784px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#fontNoBorder > #tittlrImgResearchInstitutes1{
		background:url('../images/标题背景-科研.jpg') no-repeat center -38px;
		background-size:100% auto;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > #tittlrImgResearchInstitutes1{
		background:url('../images/标题背景-科研.jpg') no-repeat center -19px;
		background-size:100% auto;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder > #tittlrImgResearchInstitutes1{
		background:url('../images/标题背景-科研.jpg') 0 0;
		background-size: auto 280px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder > #tittlrImgResearchInstitutes1{
		background:url('../images/标题背景-科研.jpg') no-repeat -16px 0;
		background-size: auto 748px;
	}
} */
</style>
