<!-- 生态合作 -->
<template>
    <div>
		<TopOfPage :tittle="tittle" :path="path"></TopOfPage>
		<router-view :tittle="tittle"></router-view> 
    </div>
</template>

<script>
export default {
    name: 'EcologicalCooperation',
	data() {
		return {
			tittle: "生态合作",
			path: "/EcologicalCooperation"
		}
	},

}
</script>

<style scoped>
</style>
