<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思资源控制软件功能特点：</h2>
					<div class="textDetails">
						<div>
							<p class="figure figure1"><b>对CPU资源的使用控制</b>可设定应用具体使用哪些CPU，同时可设定单个CPU的使用比率。</p>
							<p class="figure figure2"><b>对内存资源的使用控制</b>可设定应用对物理内存使用的最大空间和交换分区使用的最大空间。</p>
							<p class="figure figure3"><b>对设备资源访问的使用控制</b>可设定应用对磁盘IO带宽的分配和使用，可允许和禁止应用对指定设备的访问。</p>
							<p class="figure figure4"><b>对网卡带宽资源的使用控制</b>可设定应用对网卡带宽的分配，可设定应用占用的百分比。</p>
							<p class="figure figure5"><b>资源使用统计报告</b>统计对资源的使用情况，并生成使用报告。</p>
							<p class="figure figure6"><b>进程组优先级控制</b>可设定某些进程优先使用指定的资源，比如CPU资源。</p>
							<p class="figure figure7"><b>进程组隔离和控制</b>可实现不同进程组使用不同的空间，并可将进程组挂起和恢复。</p>
						</div>
						<div>
							<p class="figure figure8"><b>自定义配置</b>采用机制和策略分离，用户可对策略进行灵活的配置。支持用户、用户组、单个进程粒度的配置。</p>
							<p class="figure figure9"><b>应用程序无关性</b>资源控制器软件的部署和使用不需要修改应用程序和代码，实现应用透明性。</p>
							<p class="figure figure10"><b>不影响系统和应用性能</b>资源控制器软件基于内核实现，对系统性能和应用性能不产生影响。</p>
							<p class="figure figure11"><b>保证系统运行的稳定性</b>可防止应用程序对资源的无限申请和占用导致系统机器失去响应，从而保证系统长期运行稳定可靠。</p>
							<p class="figure figure12"><b>系统资源的合理分配和布局</b>根据实际应用需求，合理对资源的申请和使用进行分配。</p>
							<p class="figure figure13"><b>辅助发现资源使用方面的问题</b>通过对资源使用控制的配置，可辅助排查应用程序和系统的错误配置，及时发现和解决问题。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'ResourceControl',
}
</script>
<style scoped>

</style>
