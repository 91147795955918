import 'babel-polyfill';
import Vue from 'vue';
import 'es6-promise';
import App from './App.vue';

/* 全局组件 */
import TopOfPage from './components/TopOfPage';
Vue.component(TopOfPage.name,TopOfPage);
import TopOfProducts from './components/TopOfProducts';
Vue.component(TopOfProducts.name,TopOfProducts);
import NaIn from './components/NaIn';
Vue.component(NaIn.name,NaIn);
import SideNavigation from './components/SideNavigation';
Vue.component(SideNavigation.name,SideNavigation);
import CaseDisplay from './components/CaseDisplay';
Vue.component(CaseDisplay.name,CaseDisplay);
import Shrink from './components/Shrink';
Vue.component(Shrink.name,Shrink);
import FeatureBox from './components/FeatureBox';
Vue.component(FeatureBox.name,FeatureBox);
import FeatureBox6 from './components/FeatureBox6';
Vue.component(FeatureBox6.name,FeatureBox6);
import ScrollNumber from './components/ScrollNumber';
Vue.component(ScrollNumber.name,ScrollNumber);

// import $ from 'jquery';
//引入路由器
import router from './router';

//引入仓库
import store from './store';

//引入swiper样式
import "swiper/css/swiper.css";

// 验证码
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import animated from "animate.css";
Vue.use(animated);

import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow


// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap,}{
    
// })

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as moment from 'moment'
Vue.prototype.$moment = moment

Vue.config.productionTip = false
new Vue({
    render: h => h(App),
    //注册路由
    router,
    store,
}).$mount('#app')
