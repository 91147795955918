<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思双机热备软件技术特点：</h2>
					<div class="textDisplay">
						<div>
							<h4>服务自动切换</h4>
							<p>在双机热备的两台节点机中，一台节点机提供应用服务，另外一台节点机处于热待机状态。当提供应用服务的节点机无法提供服务时，处于热待机状态的节点机将启动该机上的服务，实现应用服务的自动切换。</p>
						</div>
						<div>
							<h4>远程登录管理</h4>
							<p>提供加密的SSH远程登录管理，实现本地远程无差异管理，降低管理员的负担，提高系统的可维护性。</p>
						</div>
						<div>
							<h4>服务自动启动和重启</h4>
							<p>在服务器启动或重启时能够实现服务的自动启动，不需要系统管理员的任何操作。当服务不存在时可以按顺序依次重新启动应用服务，保证服务的运行。</p>
						</div>
						<div>
							<h4>良好的兼容性</h4>
							<p>能够支持多种应用服务，包括Web服务、电子邮件服务、DNS服务、数据库服务、OA服务、CA服务、目录服务、公文服务、加密服务、文件服务等，具有良好的兼容性。</p>
						</div>
						<div>
							<h4>声音报警</h4>
							<p>提供对系统及服务的监控。可按用户设定发出不同的声音报警信号提醒系统管理员，从而缩短系统管理员发现问题的时间，便于系统的维护和管理。</p>
						</div>
						<div>
							<h4>日志归档管理</h4>
							<p>提供日志归档管理。保证日志及时按日归档保存，同时可以灵活方便地进行查找，方便管理员的管理。</p>
						</div>
						<div>
							<h4>邮件信息通知</h4>
							<p>通过SMTP协议，将系统及服务的相关信息发送到系统管理员的电子信箱，便于管理员的日常管理。另外，电子邮件的内容可以定制。</p>
						</div>
						<div>
							<h4>不间断业务服务</h4>
							<p>双机热备系统能够有效地解决多种硬件、系统和服务等故障造成的服务间断问题，能够保证服务的不间断运行。</p>
						</div>
						<div>
							<h4>STONITH功能</h4>
							<p>加入STONITH，防止机器出现故障导致资源不能正常运行。增加多条链路的STONITH功能，保证可靠性。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'DualSystem',
}
</script>
<style scoped>

</style>
