<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<!-- <Solution1 v-show="natittle3 == '解决方案'"></Solution1> -->
	</div>
</template>

<script>
// import Solution1 from './Solution1.vue';

export default {
	name:'SolutionDetails',
	// components:{
	// 	Solution1
    // },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	mounted(){
        if(this.natittle3 == '解决方案'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '解决方案'
        }
    },
}
</script>
<style scoped>

</style>
