<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
            <div class="Linx">
                <div>
                    <h2>凝思联合实验室是凝思软件与软硬件厂商</h2>
                    <h2>遵循“优势互补、平等互利”原则，</h2>
                    <h2>结合凝思与软硬件厂商的优势和特色建立的实体平台。</h2>
                </div>
            </div>
            <div class="LaboratoryBlock">
           		<h2>实验室职能：</h2>
                <p>提供专业机房，可存放200台2U服务器；</p>
                <p>实验室测试间可提供各类软硬件产品完备的测试环境；</p>
                <p>承担凝思安全操作系统与整机、板卡、外设等设备的兼容性适配；</p>
                <p>承担凝思安全操作系统与数据库、中间件、云平台等软件产品的兼容性适配；</p>
                <p>承担与电力、金融、交通等行业合作伙伴联合解决方案的场景搭建，供客户远程浏览和试用；</p>
                <p>为合作伙伴提供远程实验环境，供其进行产品与凝思安全操作系统的兼容性适配。</p>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	name:'EcologicalLaboratory',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '生态实验室',
			title4: 0,
			icon: require('../images/生态实验室.png'),
			title4List: [
				{
					tittle:'生态实验室',
				}
			],
		}
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
.font .Linx{
	width: 839px;
	height: 256px;
	margin:0 auto 56px;
	background: url(../images/生态实验室背景.png) no-repeat;
	background-size:auto 256px;
	position: relative;
}
.font .Linx div{
	position: absolute;
	bottom: 40px;
	padding-left: 48px;
}
.font .Linx div h2{
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
	font-size: 20px;
	line-height:32px;
	font-weight: 500;
	letter-spacing:1px;
	margin: 0;
	text-indent: 0em;
}
.font .Linx:before{
	position: absolute;
	display:block;
	content:'';
	width:24px;
	height:2px;
	top:44px;
	left:48px;
	background-color:#fff;
}
.font .LaboratoryBlock{
	margin:34px 0 64px;
}
.font .LaboratoryBlock p{
	position: relative;
	text-indent: 0;
	margin-left:60px;
}
.font .LaboratoryBlock p:before{
	position: absolute;
	display:inline-block;
	content:'';
	width:18px;
	height:18px;
	top:5px;
	left:-30px;
	background: url(../images/向右标记.png) no-repeat center;
	background-size:18px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.font .Linx{
		width: calc(100% - 64px);
		height: 256px;
		margin:0 auto auto;
		background: url(../images/生态实验室背景.png) no-repeat;
		background-size:auto 256px;
		position: relative;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.font .Linx{
		width: 100%;
		height: 150.5px;
		background: url(../images/生态实验室背景.png);
		background-size:auto 150.5px;
	}
	.font .Linx div{
		position: absolute;
		bottom: 20px;
		padding-left: 15px;
	}
	.font .Linx div h2{
		text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.6);
		font-size: 14px;
		line-height:21px;
		letter-spacing:0.5px;
	}
	.font .Linx:before{
		width:18px;
		height:1.5px;
		top:22px;
		left:15px;
	}
	.font .LaboratoryBlock{
		margin:17px 0 0px;
	}
	.font .LaboratoryBlock p{
		margin-left:37px;
	}
	.font .LaboratoryBlock p:before{
		width:14px;
		height:14px;
		top:4px;
		left:-22px;
		background-size:14px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.font .Linx{
		width: 100%;
		height: 301px;
		background-size:auto 301px;
	}
	.font .Linx div{
		position: absolute;
		bottom: 40px;
		padding-left: 30px;
	}
	.font .Linx div h2{
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
		font-size: 28px;
		line-height:42px;
		letter-spacing:1px;
	}
	.font .Linx:before{
		width:36px;
		height:3px;
		top:44px;
		left:30px;
	}
	.font .LaboratoryBlock{
		margin:34px 0 0px;
	}
	.font .LaboratoryBlock p{
		margin-left:74px;
	}
	.font .LaboratoryBlock p:before{
		width:28px;
		height:28px;
		top:8px;
		left:-44px;
		background-size:28px;
	}
} */
</style>
