<!-- 首页-服务与支持 -->
<template>
    <div class="box" id="support">
        <div class="title-font1 wow fadeIn" id="title-font1-support" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1">
            服务与支持
            <div></div>
        </div>
        <div id="div-in-support">
            <div id="box-in-support">
                <div
                    class="cell-in-support"
                    :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}"
                    v-for="(item,index) in supportList"
                    :key="index"
                    data-wow-duration="1.2s" :data-wow-delay="index*0.15 + 's'" data-wow-offset="150"  data-wow-iteration="1"
                >
                    <img :src="item.imgUrl" alt="item.imgAlt">
                    <h1>{{item.tittle}}</h1>
                    <p class="p1">{{item.p1}}</p>
                    <p class="p2">{{item.p2}}</p>
                    <p class="p3">{{item.p3}}</p>
                    <router-link :to="item.link">查看更多<img src="../../../images/箭头-蓝色-向右.png"></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceAndSupport',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
            supportList:[
                {
                    imgUrl:require('./images/服务与支持-软件下载.png'),
                    imgAlt:'服务与支持-软件下载',
                    tittle:'软件下载',
                    p1:'操作系统',
                    p2:'应用软件',
                    p3:'安全升级',
                    link:'/ServiceAndSupport/SoftwareDownload'
                },
                {
                    imgUrl:require('./images/服务与支持-文档下载.png'),
                    imgAlt:'服务与支持-文档下载',
                    tittle:'文档下载',
                    p1:'凝思安全操作系统',
                    p2:'凝思双机热备软件',
                    p3:'凝思多路径软件',
                    link:'/ServiceAndSupport/DocumentDownload'
                },
                {
                    imgUrl:require('./images/服务与支持-服务流程.png'),
                    imgAlt:'服务与支持-服务流程',
                    tittle:'服务流程',
                    p1:'服务流程概况',
                    p2:'基本服务',
                    p3:'服务',
                    link:'/ServiceAndSupport/ServicePrecess'
                },
                {
                    imgUrl:require('./images/服务与支持-常见问题.png'),
                    imgAlt:'服务与支持-常见问题',
                    tittle:'常见问题',
                    p1:'系统安装类',
                    p2:'系统使用与配置',
                    p3:'驱动安装类',
                    link:'/ServiceAndSupport/CommonQuestions'
                },
                {
                    imgUrl:require('./images/服务与支持-安全公告.png'),
                    imgAlt:'服务与支持-安全公告',
                    tittle:'安全公告',
                    p1:'凝思安全操作系统',
                    p2:'安全公告',
                    p3:'',
                    link:'/ServiceAndSupport/SecurityNotice'
                },
            ]
        }
    },
    mounted(){
    	window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    }
}
</script>

<style scoped>
/*pc*/
    #support{
		height: 678px;
		margin: 33px auto 0px;
		background: url(./images/服务与支持背景-pc.png) fixed;
	}
	#support .title-font1{
		background: url(./images/服务与支持上点阵-pc.png) no-repeat;
		color: #fff;
		padding-top:0;
	}
	#support .title-font1 div{
		background-color: #fff;
	}
    #box-in-support{
        height: 355px;
        width: 1170px;
        margin:0 auto;
        text-align:center;
        column-count:5;
    }
    .cell-in-support{
        display: inline-block;
        position: relative;
        width: 218px;
        height:355px;
        background-color: rgba(255,255,255,0.95);
        transition: all .3s;
    }
    .cell-in-support:hover{
        background-color: rgba(255,255,255,1);
        transition: all .3s;
    }
    .cell-in-support:before{
    	content:'';
    	position:absolute;
        transition: all .2s;
        width: 218px;
        height: 355px;
        border:0px solid #e8e9eb;
        box-sizing: border-box;
        top:0;
        left:0;
    }
    .cell-in-support:hover:before{
        border:8px solid #e8e9eb;
    }
    .cell-in-support img{
        position:absolute;
        display: inline-block;
        width: auto;
        height: 50px;
        top:48px;
        left:50%;
        transform: translateX(-50%); 
    }
    .cell-in-support h1{
        position:absolute;
        width:100%;
        top:155px;
        color: #262b33;
        font-size: 22px;
        line-height: 22px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 500;
    }
    .cell-in-support p{
        position:absolute;
        width:174px;
        left:50%;
        transform: translateX(-50%); 
        color: #565d69;
        font-size: 16px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 300;
        line-height: 26px;
        width: 150px;
        text-align: center;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }
    .cell-in-support .p1{
        top: 196px;
    }
    .cell-in-support .p2{
        top: 222px;
    }
    .cell-in-support .p3{
        top: 248px;
    }
    .cell-in-support > a{
        text-decoration: none;
        position:absolute;
        width: 100%;
        text-align:center;
        bottom:27px;
        left:0;
        font-weight:500;
        letter-spacing: 0px;
        color: #0072ce;
        font-size: 16px;
        opacity:0;
        transition: all .15s;
    }
    .cell-in-support:hover > a{
        opacity:1;
        bottom:39px;
        transition: all .15s;
    }
    .cell-in-support > a > img{
        position: relative;
        top: -3px;
        left: 12px;
        display: inline;
        padding-left: 0;
        width: 14px;
        height: 8px;
        transition: left .3s;
    }
    .cell-in-support > a:hover > img{
        left: 18px;
        transition: left .3s;
    }
/*pad横屏*/
@media only screen and (max-width:1170px){
    #support{
		background: url(./images/服务支持背景-pad横屏.png) fixed;
	}
	#support .title-font1{
		background: url(./images/服务与支持上点阵-pad.png) no-repeat;
		color: #fff;
	}
    #div-in-support{
        height: 355px;
        width:100%;
        overflow-x:auto;
        overflow-y:hidden;
    }
    #box-in-support{
        width:1350px;
        padding:0 108px;
    }
    .cell-in-support{
        width: 236px;
        height: 352px;
    }
    .cell-in-support:before{
    	content:'';
    	position:absolute;
        transition: all .2s;
        width: 236px;
        height: 352px;
        border:8px solid #e8e9eb;
        box-sizing: border-box;
        top:0;
        left:0;
    }
    .cell-in-support h1{
        top:147px;
    }
    .cell-in-support p{
        width:174px;
    }
    .cell-in-support .p1{
        top: 188px;
    }
    .cell-in-support .p2{
        top: 214px;
    }
    .cell-in-support .p3{
        top: 240px;
    }
    .cell-in-support > a{
        bottom:40px;
        opacity:1;
    }
    .cell-in-support:hover > a{
        bottom:40px;
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#support{
		background: url(./images/服务支持背景-pad竖屏.png) fixed bottom right;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
    #support{
		height: 581.5px;
		margin: 16.5px auto 0px;
		background: url(./images/服务支持背景-手机端.png) no-repeat left bottom;
        background-size: 200%;
	}
	#support .title-font1{
		background: url(./images/服务与支持上点阵-手机端.png) no-repeat left 10px;
        background-size: 50% auto;
        padding-top:33px;
	}
    #div-in-support{
        width:100%;
        height: auto;
    }
    #box-in-support{
        height: 315px;
        width:1262.5px;
        padding:0px 61.5px;
    }
    .cell-in-support{
        width: 200px;
        height: 306px;
        border:4.5px solid #ebebeb;
        background-color: rgba(255,255,255,1);
    }
    .cell-in-support:before{
    	display:none;
    }
    .cell-in-support img{
        height: 41px;
        top:37.5px;
    }
    .cell-in-support h1{
        top:117.5px;
        color: #141414;
        font-size: 18px;
        line-height: 18px;
    }
    .cell-in-support p{
        width:152px;
        color: #4c4c4c;
        font-size: 14px;
        line-height: 14px; 
    }
    .cell-in-support .p1{
        top: 159.5px;
    }
    .cell-in-support .p2{
        top: 187.5px;
    }
    .cell-in-support .p3{
        top: 215.5px;
    }
    .cell-in-support > a{
        bottom:35.5px;
        color: #0072ce;
        font-size: 13px;
        opacity:1;
    }
    .cell-in-support:hover > a{
        bottom:35.5px;
    }
    .cell-in-support > a > img{
        position: relative;
        top: -1.5px;
        left: 9px;
        display: inline-block;
        width: 13px;
        height: 7.5px;
        transition: left .3s;
    }
    .cell-in-support > a:hover img{
        left: 14px;
        transition: left .3s;
    }
}
/*手机端1080
@media only screen and (min-height:1300px){
    #support{
		height: 1675px;
		margin: 33px auto 0px;
		background: url(./images/服务支持背景-手机端1080.png) fixed left bottom;
	}
	#support .title-font1{
		padding-top:55px;
		background: url(./images/服务与支持上点阵-手机端1080.png) no-repeat left 30px;
	}
    #div-in-support{
        width:100%;
    }
    #box-in-support{
        height: 908px;
        width:3635px;
        padding:32px calc(50vw - 363.5px);
    }
    .cell-in-support{
        width: 584px;
        height: 880px;
        border:14px solid #ebebeb;
        background-color: rgba(255,255,255,1);
    }
    .cell-in-support img{
        height: 124px;
        top:105px;
    }
    .cell-in-support h1{
        top:337px;
        color: #141414;
        font-size: 52px;
        line-height: 52px;
    }
    .cell-in-support p{
        width:432px;
        color: #4c4c4c;
        font-size: 40px;
        line-height: 40px; 
    }
    .cell-in-support .p1{
        top: 455px;
    }
    .cell-in-support .p2{
        top: 533px;
    }
    .cell-in-support .p3{
        top: 611px;
    }
    .cell-in-support > a{
        bottom:100px;
        color: #0072ce;
        font-size: 40px;
        opacity:1;
    }
    .cell-in-support:hover > a{
        bottom:100px;
    }
    .cell-in-support > a > img{
        position: relative;
        top: -4px;
        left: 30px;
        display: inline-block;
        width: 38px;
        height: 22px;
        transition: left .3s;
    }
    .cell-in-support > a:hover img{
        left: 40px;
        transition: left .3s;
    }
}*/
</style>
