<template>
    <div class="fontmain" id="noticeDetailFont">
		<div class="font" id="noticeDetail">
            <div class="section_article">
                <div class="header_title">
                    <h1 class="title_content" v-if="bodyWidthMut>750">【公告编号：{{ infoData.linxNumber}}】</h1>
                    <h1 class="title_content" v-if="bodyWidthMut<=750">
                        <span>公告编号：</span>
                        <span>{{ infoData.linxNumber}}</span>
                    </h1>
                    <p class="title_info">
                        发布日期：{{ time(infoData.noticePublished) }}
                    </p>
                </div>
                <el-tabs
                    v-model="activeName"
                    class="demo-tabs"
                >
                    <el-tab-pane label="概述" name="first">
                        <div class="article_body">
                            <h3 class="m3">01 基本信息</h3>
                            <div v-if="bodyWidthMut<=750">
                                <div class="noticeInfo">
                                    <div :style="'width:' + 130 * infoDataLength + 'px'">
                                        <a
                                            v-for="(item, index) of infoData.noticeInfo"
                                            :key="index"
                                            @click="getInfoDetail(index, item)"
                                            :class="{'is-active':index === activeCve}"
                                        >
                                            <span class="level" :style="'background:' + security_level[item.bugLevel-1].color">{{security_level[item.bugLevel-1].dictLabel}}</span>
                                            <p>{{item.cveNumber.slice(0,item.cveNumber.indexOf('-')) + '-'}}</p>
                                            <p>{{item.cveNumber.slice(item.cveNumber.indexOf('-')+1)}}</p>
                                        </a>
                                    </div>
                                </div>
                                <div class="infoDetail">
                                    <p>漏洞编号：
                                        <span v-if="infoDetail.cveNumber !== null && infoDetail.cveNumber !== 'LINX-20231031-1' && infoDetail.cveNumber !== 'CVE-2024-3094'&& infoDetail.cveNumber !== 'CVE-2024-1086'&& infoDetail.cveNumber !== 'CVE-2024-6345'&& infoDetail.cveNumber !== 'CVE-2024-4076'&& infoDetail.linxNumber !== 'LINX-20240930-1'">
                                            <a
                                                target="_blank"
                                                class="buttonText"
                                                :href="'https://www.cve.org/CVERecord?id=' + infoDetail.cveNumber"
                                            >{{ infoDetail.cveNumber }}</a>
                                        </span>
                                        <span v-if="infoDetail.cveNumber === null">-</span>
                                        <span v-if="infoDetail.cveNumber === 'LINX-20231031-1' || infoDetail.cveNumber === 'CVE-2024-3094' || infoDetail.cveNumber === 'CVE-2024-1086' || infoDetail.cveNumber === 'CVE-2024-6345' || infoDetail.cveNumber === 'CVE-2024-4076'|| infoDetail.linxNumber === 'LINX-20240930-1'">{{ infoDetail.cveNumber }}</span>
                                    </p>
                                    <p class="desc">描述：<span>{{infoDetail.bugDesc}}</span></p>
                                    <p>评分：<span>{{infoDetail.bugScore}}</span></p>
                                    <p>级别：<span :style="{color: infoDetail.color}">{{ infoDetail.dictLabel }}</span></p>
                                    <p>发布时间：<span>{{infoDetail.cvePublished}}</span></p>
                                    <p>更新时间：<span>{{infoDetail.cveUpdateTime}}</span></p>
                                </div>
                            </div>
                            <div v-if="bodyWidthMut>750">
                                <el-table
                                    :data="infoData.noticeInfo"
                                >
                                    <el-table-column label="漏洞编号" align="center" width="auto" min-width="14%">
                                        <template slot-scope="scope">
                                            <a
                                                target="_blank"
                                                class="buttonText"
                                                :href="'https://www.cve.org/CVERecord?id=' + scope.row.cveNumber"
                                                v-if="scope.row.cveNumber !== null && scope.row.cveNumber !== 'LINX-20231031-1' && scope.row.cveNumber !== 'CVE-2024-3094' && scope.row.cveNumber !== 'CVE-2024-1086' && scope.row.cveNumber !== 'CVE-2024-6345' && scope.row.cveNumber !== 'CVE-2024-4076'&& infoData.linxNumber !== 'LINX-20240930-1'"
                                            >{{ scope.row.cveNumber }}</a>
                                            <span v-if="scope.row.cveNumber === null">-</span>
                                            <span v-if="scope.row.cveNumber === 'LINX-20231031-1' || scope.row.cveNumber === 'CVE-2024-3094' || scope.row.cveNumber === 'CVE-2024-1086' || scope.row.cveNumber === 'CVE-2024-6345' || scope.row.cveNumber === 'CVE-2024-4076' || infoData.linxNumber === 'LINX-20240930-1'">{{ scope.row.cveNumber }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="描述" width="auto" min-width="50%">
                                        <p slot-scope="scope" style="font-size: 14px; color: #606266;word-break: keep-all;">
                                            {{ scope.row.bugDesc }}
                                        </p>
                                    </el-table-column>
                                    <el-table-column prop="bugScore" label="评分" width="auto" min-width="6%" align="center"></el-table-column>
                                    <el-table-column prop="bugLevel" label="级别" width="auto" min-width="6%" align="center">
                                        <template slot-scope="scope">
                                            <el-tag
                                                size="mini"
                                                :type="security_level[scope.row.bugLevel-1].listClass"
                                            >{{ security_level[scope.row.bugLevel-1].dictLabel }}</el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="cvePublished" label="发布时间" width="auto" min-width="12%" align="center"></el-table-column>
                                    <el-table-column prop="cveUpdateTime" label="更新时间" width="auto" min-width="12%" align="center"></el-table-column>
                                </el-table>

                                <!-- <el-descriptions title="垂直带边框列表" direction="vertical" :column="4" border>
                                    <el-descriptions-item label="漏洞编号">{{ infoData.cveNumber !== null ? infoData.cveNumber : "-" }}</el-descriptions-item>
                                    <el-descriptions-item label="安全级别">
                                        <el-tag
                                            size="small"
                                            :type="security_level[infoData.bugLevel-1].listClass"
                                        >{{ security_level[infoData.bugLevel-1].dictLabel }}</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="受影响产品">{{ infoData.affectedProducts }}</el-descriptions-item>
                                    <el-descriptions-item label="受影响组件">{{  infoData.affectedCompoment }}</el-descriptions-item>
                                </el-descriptions> -->
                            </div>
                        </div>
                        <!-- <div class="article_body">
                            <h3 class="m3">02 描述</h3>
                            <p v-html="infoData.noticeDesc"></p>
                        </div>
                        <el-divider /> -->
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240606-1'">
                            <h3 class="m3">02 影响范围</h3>
                            <div class="refer">           
                                <!-- <p>以下是99系统受影响的内核版本范围</p> -->
                                <el-table
                                    :data="LINX20240606Data"
                                    border
                                    style="margin: 12px auto"
                                >
                                    <el-table-column
                                        prop="version"
                                        label="系统版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="status"
                                        label="受影响情况">
                                    </el-table-column>
                                    <el-table-column
                                        label="修复版本"
                                    >
                                        <template slot-scope="scope">
                                            <div v-html="scope.row.repair.split(';').join('<br/>')"></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240918-1'">
                            <h3 class="m3">02 影响范围</h3>
                            <div class="refer">           
                                <el-table
                                    :data="LINX20240918Data"
                                    border
                                    style="margin: 12px auto"
                                >
                                    <el-table-column
                                        prop="version"
                                        label="系统版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="status"
                                        label="受影响情况">
                                    </el-table-column>
                                    <el-table-column
                                        label="修复版本"
                                    >
                                        <template slot-scope="scope">
                                            <div v-html="scope.row.repair.split(';').join('<br/>')"></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240918-2'">
                            <h3 class="m3">02 影响范围</h3>
                            <div class="refer">           
                                <el-table
                                    :data="LINX202409182Data"
                                    border
                                    style="margin: 12px auto"
                                >
                                    <el-table-column
                                        prop="version"
                                        label="系统版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="status"
                                        label="受影响情况">
                                    </el-table-column>
                                    <el-table-column
                                        label="修复版本"
                                    >
                                        <template slot-scope="scope">
                                            <div v-html="scope.row.repair.split(';').join('<br/>')"></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240930-1'">
                            <h3 class="m3">02 影响范围</h3>
                            <p>凝思安全操作系统发布版本受影响情况见下表：</p>
                            <div class="refer">           
                                <el-table
                                    :data="LINX202409301Data"
                                    border
                                    style="margin: 12px auto"
                                >
                                    <el-table-column
                                        prop="version"
                                        label="系统版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="status"
                                        label="受影响情况">
                                    </el-table-column>
                                </el-table>
                            </div>
                            <p>若无法确认是否通过其他方式安装或运行cups组件，可以通过以下方法确认是否受到影响：</p>
                            <p style="text-indent: 1em">42系统通过以下命令检查cups版本是否为1.3.9、1.3.8、1.2.7三个版本。</p>
                            <p style="text-indent: 2em">pkginfo -i | grep -w cups</p>
                            <p style="text-indent: 1em">60系统通过以下命令检查cups版本是否为1.4.4版本，此版本不会受到影响。</p>
                            <p style="text-indent: 2em">dpkg -l | grep -w cups</p>
                            <p style="text-indent: 1em">80、90、100、98、99可以通过下面的端口检查命令确认是否使用了受影响的服务</p>
                            <p style="text-indent: 2em">netstat -unlp | grep -w 631</p>
                            <p style="text-indent: 1em">确认打印结果中不存在631端口及不受影响。</p>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber !== 'LINX-20240331-1' && infoData.linxNumber !== 'LINX-20240918-1' && infoData.linxNumber !== 'LINX-20240918-2' && infoData.linxNumber !== 'LINX-20240930-1'">
                            <h3 class="m3">{{infoData.linxNumber === 'LINX-20240606-1' ? '03' : '02'}} 修复方式</h3>
                            <div class="refer" v-if="infoData.linxNumber === 'LINX-20231031-1'">           
                                <p>安装openssh版本需根据系统中原openssh确认，执行下列命令进行查看：</p>
                                <p>dpkg -l | grep openssh</p>
                                <p>记录 openssh-client、openssh-server、openssh-sftp-server、ssh-askpass-gnome 等包的版本。</p>
                                <p>根据系统当前的 openssh 版本,决定升级时,使用哪款升级包进行操作。</p>
                                <p>下载"软件包链接"中对应系统版本所有安装包，执行以下命令进行修复：</p>
                                <p>详情可参考<a href="https://www.linx-info.com/download/document/CVE/北京凝思软件OpenSSH升级作业指导书.pdf">北京凝思软件OpenSSH升级作业指导书</a>。</p>
                            </div>
                            <div class="refer" v-if="infoData.linxNumber === 'LINX-20240606-1'">           
                                <p>升级内核到不受影响的版本。</p>
                                <p>注意：内核升级后需要重启系统生效，对应系统需要升级对应内核，若对升级操作存在疑问请联系凝思销售人员确认。</p>
                            </div>
                            <h4 v-if="archData.length !== 0">aarch64</h4>
                            <p
                                v-for="item in archData"
                                :key="item.packageName"
                            > {{ 'rpm -i ' + item.packageName }}</p>
                            <h4 v-if="x86Data.length !== 0">x86_64</h4>
                            <p
                                v-for="item in x86Data"
                                :key="item.packageName"
                            > {{ 'rpm -i ' + item.packageName }}</p>
                            <h4 v-if="o80Data.length !== 0">Linxos-6.0.80:</h4>
                            <p
                                v-for="item in o80Data"
                                :key="item.packageName"
                            > {{ 'dpkg -i ' + item.packageName }}</p>
                            <p
                                v-for="item in c80Data"
                                :key="item.packageName + '80'"
                            > {{ 'dpkg -i ' + item.packageName }}</p>
                            <h4 v-if="o100Data.length !== 0">Linxos-6.0.100:</h4>
                            <p
                                v-for="item in o100Data"
                                :key="item.packageName"
                            > {{ 'dpkg -i ' + item.packageName }}</p>
                            <p
                                v-for="item in c100Data"
                                :key="item.packageName + '100'"
                            > {{ 'dpkg -i ' + item.packageName }}</p>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240331-1'">
                            <h3 class="m3">02 影响范围</h3>
                            <div class="refer">           
                                <p>经确认该后门影响的 xz 版本为 5.6.0 和 5.6.1 版本，经验证凝思安全操作系统各个发行版本中，XZ 组件版本情况如下：</p>
                                <el-table
                                    :data="LINX20240331Data"
                                    border
                                    style="margin: 12px auto"
                                >
                                    <el-table-column
                                        prop="version"
                                        label="系统版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="xz"
                                        label="xz 版本">
                                    </el-table-column>
                                    <el-table-column
                                        prop="status"
                                        label="受影响情况">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240331-1'">
                            <h3 class="m3">03 结论</h3>
                            <div class="refer">           
                                <p>经过上述分析，凝思安全操作系统使用的 xz 软件不在受影响的范围内，不会受到该后门问题的影响。</p>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240930-1'">
                            <h3 class="m3">03 修复措施</h3>
                            <div class="refer">           
                                <p>系统默认不会受到漏洞影响。</p>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240606-1'">
                            <h3 class="m3">04 缓解措施</h3>
                            <div class="refer">           
                                <p>对于暂时无法升级内核或重启的系统，可以采用以下方式规避漏洞带来的提权风险</p>
                                <p>方法一：</p>
                                <p style="text-indent: 1em">对于不需要使用防火墙的系统，可以将 nf_tables 模块卸载</p>
                                <p style="text-indent: 1em">卸载方法：</p>
                                <p style="text-indent: 2em">执行 modprobe -r nf_tables 命令卸载模块</p>
                                <p style="text-indent: 2em">执行以下命令重命名模块防止其重新加载</p>
                                <p style="text-indent: 2em">mv /lib/modules/`uname -r`/kernel/net/netfilter/nf_tables.ko /lib/modules/`uname -r`/kernel/net/netfilter/nf_tables-bak.k</p>
                                <p>方法二：</p>
                                <p style="text-indent: 1em">禁用非特权的用户空间，此方法可能会影响系统上的容器服务，若存在正在使用的容器服务，需要确认配置后容器服务是否正常。</p>
                                <p style="text-indent: 1em">禁用方法：</p>
                                <p style="text-indent: 2em">修改/etc/sysctl.conf 文件，在文件最后写入 kernel.unprivileged_userns_clone = 0 一行</p>
                                <p style="text-indent: 2em">修改保存后执行 sysctl -p 命令使其生效</p>
                            </div>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240606-1'">
                            <h3 class="m3">05 升级方法</h3>
                            <h4>linxos-6.0.80</h4>
                            <h5>系统架构为 x86_64，内核版本为 4.9.130，执行下行命令进行升级：</h5>
                            <p>dpkg -i linux-kbuild-4.9_4.9.130-2linx23_amd64.deb</p>
                            <p>dpkg -i linux-libc-dev_4.9.130-2linx23_amd64.deb</p>
                            <p>dpkg -i linux-compiler-gcc-4.9-x86_4.9.130-2linx23_amd64.deb</p>
                            <p>dpkg -i linux-image-4.9.0-8-linx-security-amd64_4.9.130-2linx23_amd64.deb</p>
                            <h5>系统架构为 x86_64，内核版本为 4.9.320，执行下行命令进行升级：</h5>
                            <p>dpkg -i linux-kbuild-4.9_4.9.320-1linx10_amd64.deb</p>
                            <p>dpkg -i linux-libc-dev_4.9.320-1linx10_amd64.deb</p>
                            <p>dpkg -i linux-compiler-gcc-4.9-x86_4.9.320-1linx10_amd64.deb</p>
                            <p>dpkg -i linux-headers-4.9.0-19-common-linx-security_4.9.320-1linx10_all.deb</p>
                            <p>dpkg -i linux-headers-4.9.0-19-linx-security-amd64_4.9.320-1linx10_amd64.deb</p>
                            <p>dpkg -i linux-image-4.9.0-19-linx-security-amd64_4.9.320-1linx10_amd64.deb</p>
                            <h4>linxos-6.0.90</h4>
                            <h5>系统架构为 arm64，内核版本为 4.19.12，执行下行命令进行升级：</h5>
                            <p>dpkg -i linux-kbuild-4.19_4.19.12-1~bpo9+1linx12_arm64.deb</p>
                            <p>dpkg -i linux-libc-dev_4.19.12-1~bpo9+1linx12_arm64.deb</p>
                            <p>dpkg -i linux-headers-4.19.0-0.bpo.1-common-linx-security_4.19.12-1~bpo9+1linx12_all.deb</p>
                            <p>dpkg -i linux-headers-4.19.0-0.bpo.1-linx-security-arm64_4.19.12-1~bpo9+1linx12_arm64.deb</p>
                            <p>dpkg -i linux-image-4.19.0-0.bpo.1-linx-security-arm64-unsigned_4.19.12-1~bpo9+1linx12_arm64.deb</p>
                            <p>dpkg -i linx-security-module_3.8-linx_arm64.deb</p>
                            <h4>linxos-6.0.98</h4>
                            <h5>系统架构为 x86_64，内核版本为 4.19.91，执行下行命令进行升级</h5>
                            <p>rpm -Uvh kernel-core-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-modules-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-tools-libs-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-tools-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh python3-perf-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh perf-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-headers-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-devel-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh bpftool-4.19.91-26.2.lx8.linx98_8.x86_64.rpm</p>
                            <p>rpm -Uvh linx-security-module-2.19-linx.x86_64.rpm</p>
                            <h4>linxos-6.0.99 欧拉版 22.03</h4>
                            <h5>系统架构为 aarch64，内核版本为 5.10.0，执行下行命令进行升级：</h5>
                            <p>rpm -Uvh perf-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh python3-perf-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh kernel-tools-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh kernel-headers-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm </p>
                            <p>rpm -Uvh kernel-devel-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm </p>
                            <p>rpm -Uvh bpftool-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh kernel-5.10.0-136.15.0.91.vlx16.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh linx-security-module-2.19-linx.aarch64.rpm</p>
                            <h5>安装后需要执行以下命令生效：</h5>
                            <p>grub2-mkconfig -o /boot/grub2/grub.cfg</p>
                            <h5>系统架构为 x86_64，内核版本为 5.10.0，执行下行命令进行升级：</h5>
                            <p>rpm -Uvh perf-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-tools-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-headers-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-devel-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh bpftool-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh kernel-5.10.0-136.15.0.91.vlx14.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh linx-security-module-2.19-linx.aarch64.rpm</p>
                            <h4>linxos-6.0.100</h4>
                            <h5>系统架构为 x86_64，内核版本为 4.19.146，执行下行命令进行升级：</h5>
                            <p>dpkg -i linux-kbuild-4.19_4.19.146-1linx19_amd64.deb</p>
                            <p>dpkg -i linux-libc-dev_4.19.146-1linx19_amd64.deb</p>
                            <p>dpkg -i linux-perf-4.19_4.19.146-1linx19_amd64.deb</p>
                            <p>dpkg -i linux-headers-4.19.0-11-common-linx-security_4.19.146-1linx19_all.deb</p>
                            <p>dpkg -i linux-headers-4.19.0-11-linx-security-amd64_4.19.146-1linx19_amd64.deb</p>
                            <p>dpkg -i linux-image-4.19.0-11-linx-security-amd64-unsigned_4.19.146-1linx19_amd64.deb</p>
                            <h5>系统架构为 arm64，内核版本为 4.19.90，执行下行命令进行升级：</h5>
                            <p>dpkg -i linux-kbuild-4.19_4.19.90-1linx15_arm64.deb</p>
                            <p>dpkg -i linux-libc-dev_4.19.90-1linx15_arm64.deb</p>
                            <p>dpkg -i linux-perf-4.19_4.19.90-1linx15_arm64.deb</p>
                            <p>dpkg -i linux-image-4.19.0-11-linx-security-arm64-unsigned_4.19.90-1linx15_arm64.deb</p>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240918-1'">
                            <h3 class="m3">03 升级方法</h3>
                            <h4>6.0.80</h4>
                            <h5>升级包安装方法：</h5>
                            <p>dpkg -i python-pkg-resources_5.5.1-1linx1_all.deb</p>
                            <p>dpkg -i python-setuptools-doc_5.5.1-1linx1_all.deb</p>
                            <p>dpkg -i python-setuptools-whl_5.5.1-1linx1_all.deb</p>
                            <p>dpkg -i python-setuptools_5.5.1-1linx1_all.deb</p>
                            <p>dpkg -i python3-pkg-resources_5.5.1-1linx1_all.deb</p>
                            <p>dpkg -i python3-setuptools_5.5.1-1linx1_all.deb</p>
                            <h4>6.0.100</h4>
                            <h5>升级包安装方法：</h5>
                            <p>dpkg -i pypy-pkg-resources_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i pypy-setuptools_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i python-pkg-resources_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i python-setuptools-doc_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i python-setuptools_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i python3-pkg-resources_40.8.0-1linx2_all.deb</p>
                            <p>dpkg -i python3-setuptools_40.8.0-1linx2_all.deb</p>
                            <h4>6.0.99-20.03</h4>
                            <h5>6.0.99 20.03 x86_64 升级包安装方法：</h5>
                            <p>rpm -Uvh python-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python-setuptools-help-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python2-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python3-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <h5>6.0.99 20.03 aarch64 升级包安装方法：</h5>
                            <p>rpm -Uvh python-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python-setuptools-help-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python2-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <p>rpm -Uvh python3-setuptools-44.1.1-2.vlx1.v99_2003.noarch.rpm</p>
                            <h4>6.0.99-22.03</h4>
                            <h5>6.0.99 22.03 x86_64 升级包安装方法：</h5>
                            <p>rpm -Uvh python-setuptools-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh python-setuptools-help-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh python3-setuptools-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                            <h5>6.0.99 22.03 aarch64 升级包安装方法：</h5>
                            <p>rpm -Uvh python-setuptools-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh python-setuptools-help-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh python3-setuptools-59.4.0-5.vlx1.v99_2203.noarch.rpm</p>
                        </div>
                        <div class="article_body" v-if="infoData.linxNumber === 'LINX-20240918-2'">
                            <h3 class="m3">03 升级方法</h3>
                            <h4>6.0.99-22.03</h4>
                            <h5>6.0.99 22.03 aarch64 升级包安装方法：</h5>
                            <p>rpm -Uvh bind-9.16.23-23.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh bind-dnssec-doc-9.16.23-23.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh bind-license-9.16.23-23.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh bind-utils-9.16.23-23.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh bind-libs-9.16.23-23.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh bind-dnssec-utils-9.16.23-23.v99_2203.aarch64.rpm</p>
                            <p>rpm -Uvh bind-chroot-9.16.23-23.v99_2203.aarch64.rpm</p>
                            <h5>6.0.99 22.03 x86_64 升级包安装方法：</h5>
                            <p>rpm -Uvh bind-9.16.23-23.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh bind-dnssec-doc-9.16.23-23.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh bind-license-9.16.23-23.v99_2203.noarch.rpm</p>
                            <p>rpm -Uvh bind-utils-9.16.23-23.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh bind-libs-9.16.23-23.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh bind-dnssec-utils-9.16.23-23.v99_2203.x86_64.rpm</p>
                            <p>rpm -Uvh bind-chroot-9.16.23-23.v99_2203.x86_64.rpm</p>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="软件包链接" name="second" v-if="infoData.linxNumber !== 'LINX-20240331-1' && infoData.linxNumber !== 'LINX-20240930-1'">
                        <div class="download">
                            <h3 class="m3" v-if="archData.length !== 0">aarch64</h3>
                            <el-table :data="archData" :show-header="false" v-if="archData.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_arch+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="x86Data.length !== 0">x86_64</h3>
                            <el-table :data="x86Data" :show-header="false" v-if="x86Data.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_x86+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <el-divider v-if="o80Data.length !== 0"/> -->
                            <h3 class="m3" v-if="o80Data.length !== 0">Linxos-6.0.80:</h3>
                            <el-table :data="o80Data" :show-header="false" v-if="o80Data.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_o80+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table :data="c80Data" :show-header="false" v-if="c80Data.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_c80+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <el-divider v-if="o100Data.length !== 0"/> -->
                            <h3 class="m3" v-if="o100Data.length !== 0">Linxos-6.0.100:</h3>
                            <el-table :data="o100Data" :show-header="false" v-if="o100Data.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_o100+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <el-divider v-if="c100Data.length !== 0"/> -->
                            <el-table :data="c100Data" :show-header="false" v-if="c100Data.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_c100+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="x86Data_0606.length !== 0">x86</h3>
                            <el-table :data="x86Data_0606" :show-header="false" v-if="x86Data_0606.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_x86_0606+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="armData_0606.length !== 0">arm</h3>
                            <el-table :data="armData_0606" :show-header="false" v-if="armData_0606.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_arm_0606+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <h3 class="m3" v-if="kernel_linx23.length !== 0 || kernel_linx10.length !== 0">linxos-6.0.80</h3>
                            <h5 v-if="kernel_linx23.length !== 0">kernel 4.9.130-linx23</h5>
                            <el-table :data="kernel_linx23" :show-header="false" v-if="kernel_linx23.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_kernel_linx23+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h5 v-if="kernel_linx10.length !== 0">kernel 4.9.320-1linx10</h5>
                            <el-table :data="kernel_linx10" :show-header="false" v-if="kernel_linx10.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_kernel_linx10+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="linxos90.length !== 0">linxos-6.0.90</h3>
                            <el-table :data="linxos90" :show-header="false" v-if="linxos90.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos90+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="linxos98.length !== 0">linxos-6.0.98</h3>
                            <el-table :data="linxos98" :show-header="false" v-if="linxos98.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos98+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="linxos992203_x86_64.length !== 0 || linxos992203_aarch64.length !== 0">linxos-6.0.99 欧拉版 22.03</h3>
                            <h5 v-if="linxos992203_x86_64.length !== 0">x86_64</h5>
                            <el-table :data="linxos992203_x86_64" :show-header="false" v-if="linxos992203_x86_64.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos992203_x86_64+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h5 v-if="linxos992203_aarch64.length !== 0">aarch64</h5>
                            <el-table :data="linxos992203_aarch64" :show-header="false" v-if="linxos992203_aarch64.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos992203_aarch64+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="linxos100_x86_64.length !== 0 || linxos100_arm64.length !== 0">linxos-6.0.100</h3>
                            <h5 v-if="linxos100_x86_64.length !== 0">x86_64</h5>
                            <el-table :data="linxos100_x86_64" :show-header="false" v-if="linxos100_x86_64.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos100_x86_64+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h5 v-if="linxos100_arm64.length !== 0">arm64</h5>
                            <el-table :data="linxos100_arm64" :show-header="false" v-if="linxos100_arm64.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_linxos100_arm64+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="o80_0918.length !== 0">6.0.80</h3>
                            <el-table :data="o80_0918" :show-header="false" v-if="o80_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_o80_0918+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="o100_0918.length !== 0">6.0.100</h3>
                            <el-table :data="o100_0918" :show-header="false" v-if="o100_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_o100_0918+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="sp3_0918.length !== 0 || aar_0918.length !== 0">6.0.99-20.03</h3>
                            <h5 v-if="sp3_0918.length !== 0">6.0.99 20.03 x86_64</h5>
                            <el-table :data="sp3_0918" :show-header="false" v-if="sp3_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_sp3_0918+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h5 v-if="aar_0918.length !== 0">6.0.99 20.03 aarch64</h5>
                            <el-table :data="aar_0918" :show-header="false" v-if="aar_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_aar_0918+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h3 class="m3" v-if="x86_0918.length !== 0 || aar2_0918.length !== 0">6.0.99-22.03</h3>
                            <h5 v-if="x86_0918.length !== 0">6.0.99 22.03 x86_64</h5>
                            <el-table :data="x86_0918" :show-header="false" v-if="x86_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_x86_0606+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <h5 v-if="aar2_0918.length !== 0">6.0.99 22.03 aarch64</h5>
                            <el-table :data="aar2_0918" :show-header="false" v-if="aar2_0918.length !== 0">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <el-link :href="baseUrl_arm_0606+scope.row.packageName">{{ scope.row.packageName }}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
	name:'NoticeDetails',
	data() {
		return {
            bodyWidthMut: document.body.clientWidth,

            natittle1: this.$attrs.tittle,
			natittle2: '安全公告',
			natittle3: '公告详情',
            id: this.$route.query.id,

            activeName: 'first',
            total: 1,
            annocementList: [],
            security_level: [
                {
                    dictLabel: "严重",
                    listClass: "danger",
                    color: '#ff3838'
                },
                {
                    dictLabel: "高危",
                    listClass: "danger",
                    color: '#e2424c'
                },
                {
                    dictLabel: "中危",
                    listClass: "warning",
                    color: '#ff8000'
                },
                {
                    dictLabel: "低危",
                    listClass: "success",
                    color: '#21bb7e'
                },
                {
                    dictLabel: "无",
                    listClass: "default",
                    color: '#898e96'
                }
            ],
            infoData: [],
            infoDataLength: 0,

            archData: [],
            x86Data: [],
            o80Data: [],
            c80Data: [],
            o100Data: [],
            c100Data: [],
            baseUrl_x86: "http://mirrors.linx-info.com/mirrors-update/mirrors-6.0.99/20.03-sp3/x86_64/Packages/",
            baseUrl_arch: "http://mirrors.linx-info.com/mirrors-update/mirrors-6.0.99/20.03-sp3/aarch64/Packages/",
            baseUrl_o80: "https://www.linx-info.com/mirrors/mirrors-6.0.80/linx/pool/main/o/openssh/",
            baseUrl_c80: "https://www.linx-info.com/mirrors/mirrors-6.0.80/linx/pool/main/l/linx-noroot-conf/",
            baseUrl_o100: "https://www.linx-info.com/mirrors/mirrors-6.0.100/linx/pool/main/o/openssh/",
            baseUrl_c100: "https://www.linx-info.com/mirrors/mirrors-6.0.100/linx/pool/main/l/linx-noroot-conf/",

            baseUrl_x86_0606: "https://www.linx-info.com/mirrors/mirrors-6.0.99/22.03-sp1/everything/x86_64/Packages/",
            baseUrl_arm_0606: "https://www.linx-info.com/mirrors/mirrors-6.0.99/22.03-sp1/everything/aarch64/Packages/",
            x86Data_0606: [],
            armData_0606: [],

            baseUrl_kernel_linx23: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.80/x86_64/4.9.130-2linx23-deb/",
            baseUrl_kernel_linx10: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.80/x86_64/4.9.320-1linx10-deb/",
            baseUrl_linxos90: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.90/arm64/4.19_4.19.12-1~bpo9+1linx12_deb/",
            baseUrl_linxos98: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.98/x86_64/4.9.91-26.2.lx8.linx98_8-deb/",
            baseUrl_linxos992203_x86_64: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.992203/x86_64/5.10.0-136.15.0.91.vlx14-deb/",
            baseUrl_linxos992203_aarch64: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.992203/arm64/5.10.0-136.15.0.91.vlx16-deb/",
            baseUrl_linxos100_x86_64: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.100/x86_64/4.19.146-1linx19_deb/",
            baseUrl_linxos100_arm64: "https://www.linx-info.com/download/security/CVE2024-1086/6.0.100/arm64/4.19.90-1linx15-deb/",
            kernel_linx23: [],
            kernel_linx10: [],
            linxos90: [],
            linxos98: [],
            linxos992203_x86_64: [],
            linxos992203_aarch64: [],
            linxos100_x86_64: [],
            linxos100_arm64: [],

            baseUrl_o80_0918: "https://www.linx-info.com/mirrors/mirrors-6.0.80/security/pool/main/p/python-setuptools/",
            baseUrl_o100_0918: "https://www.linx-info.com/mirrors/mirrors-6.0.100/security/pool/main/p/python-setuptools/",
            baseUrl_sp3_0918: "https://www.linx-info.com/mirrors/mirrors-6.0.99/20.03-sp3/everything/x86_64/Packages/",
            baseUrl_aar_0918: "https://www.linx-info.com/mirrors/mirrors-6.0.99/20.03-sp3/everything/aarch64/Packages/",
            o80_0918: [],
            o100_0918: [],
            sp3_0918: [],
            aar_0918: [],
            x86_0918: [],
            aar2_0918: [],


            activeCve: 0,
            infoDetail: [],

            LINX20240331Data: [
                {
                    version: '6.0.42',
                    xz: '4.999.9',
                    status: '不受影响'
                },
                {
                    version: '6.0.60',
                    xz: '5.0.0',
                    status: '不受影响'
                },
                {
                    version: '6.0.80',
                    xz: '5.1.1 ',
                    status: '不受影响'
                },
                {
                    version: '6.0.99',
                    xz: '5.2.5',
                    status: '不受影响'
                },
                {
                    version: '6.0.100',
                    xz: '5.2.4',
                    status: '不受影响'
                }
            ],
            LINX20240606Data: [
                {
                    version: 'linxos-6.0.42',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: 'linxos-6.0.60',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: 'linxos-6.0.80',
                    status: '受影响',
                    repair:'kernel 4.9.130-linx23;kernel 4.9.320-1linx10'
                },
                {
                    version: 'linxos-6.0.90',
                    status: '受影响',
                    repair:'kernel 4.19.12-1~bpo9+1linx12'
                },
                {
                    version: 'linxos-6.0.98',
                    status: '受影响',
                    repair:'kernel 4.19.91-26.2.lx8.linx98_8'
                },
                {
                    version: 'linxos-6.0.99 欧拉版 20.03',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: 'linxos-6.0.99 欧拉版 22.03 X86_64',
                    status: '受影响',
                    repair:'kernel 5.10.0-136.15.0.91.vlx14'
                },
                {
                    version: 'linxos-6.0.99 欧拉版 22.03 arm64 ',
                    status: '受影响',
                    repair:'kernel 5.10.0-136.15.0.91.vlx16'
                },
                {
                    version: 'linxos-6.0.100 ',
                    status: '受影响',
                    repair:'kernel 4.19.146-linx19;kernel 4.19.90-1linx15'
                }
            ],
            LINX20240918Data: [
                {
                    version: '6.0.42',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.60',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.80',
                    status: '受影响',
                    repair:'5.5.1-1linx1'
                },
                {
                    version: '6.0.100',
                    status: '受影响',
                    repair:'40.8.0-1linx2'
                },
                {
                    version: '6.0.99-20.03',
                    status: '受影响',
                    repair:'44.1.1-2.vlx1.v99_2003'
                },
                {
                    version: '6.0.99-22.03',
                    status: '受影响',
                    repair:'59.4.0-5.vlx1.v99_2203'
                }
            ],
            LINX202409182Data: [
                {
                    version: '6.0.42',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.60',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.80',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.100',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.99-20.03',
                    status: '不受影响',
                    repair:''
                },
                {
                    version: '6.0.99-22.03',
                    status: '受影响',
                    repair:'9.16.23-23.v99_2203'
                }
            ],
            LINX202409301Data: [
                {
                    version: '6.0.42',
                    status: '不受影响',
                },
                {
                    version: '6.0.60',
                    status: '不受影响',
                },
                {
                    version: '6.0.80',
                    status: '不受影响',
                },
                {
                    version: '6.0.100',
                    status: '不受影响',
                },
                {
                    version: '6.0.98',
                    status: '不受影响',
                },
                {
                    version: '6.0.99',
                    status: '不受影响',
                }
            ]
		}
	},
    created() {
        this.getList()
    },
    mounted(){
    	window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
    methods:{
        async getList() {
            let i = 0
            var nowTotal = 0
            let fileName = ''
            while(nowTotal < this.total) {
                i++
                fileName = '/db/notice/home_cve'+i+'.json'
                await axios.get(fileName).then((res) => {
                    this.annocementList = [...this.annocementList, ...res.data.rows];
                    this.total = res.data.total;
                })
                nowTotal = i*100
            }
            this.infoData=this.annocementList.filter(x => x.linxNumber === this.id)[0]
            this.infoDataLength = this.infoData.noticeInfo.length
            this.infoDetail = this.infoData.noticeInfo[this.activeCve]
            this.infoDetail.color = this.security_level[this.infoDetail.bugLevel-1].color
            this.infoDetail.dictLabel = this.security_level[this.infoDetail.bugLevel-1].dictLabel
            this.infoData.packageInfo.forEach(item => {
                if (item.packageType === 'aarch64') {
                    this.archData.push(item)
                } else if (item.packageType === 'x86_64') {
                    this.x86Data.push(item)
                } else if (item.packageType === 'Linxos-6.0.80') {
                    this.o80Data.push(item)
                } else if (item.packageType === 'Linxos-6.0.80-2') {
                    this.c80Data.push(item)
                } else if (item.packageType === 'Linxos-6.0.100') {
                    this.o100Data.push(item)
                } else if (item.packageType === 'Linxos-6.0.100-2') {
                    this.c100Data.push(item)
                } else if (item.packageType === 'x86') {
                    this.x86Data_0606.push(item)
                } else if (item.packageType === 'arm') {
                    this.armData_0606.push(item)
                } else if (item.packageType === 'linxos-6.0.80') {
                    if (item.packageType2 === 'kernel 4.9.130-linx23') {
                        this.kernel_linx23.push(item)
                    } else if (item.packageType2 === 'kernel 4.9.320-1linx10') {
                        this.kernel_linx10.push(item)
                    }
                } else if (item.packageType === 'linxos-6.0.90') {
                    this.linxos90.push(item)
                } else if (item.packageType === 'linxos-6.0.98') {
                    this.linxos98.push(item)
                } else if (item.packageType === 'linxos-6.0.99 欧拉版 22.03') {
                    if (item.packageType2 === 'x86_64') {
                        this.linxos992203_x86_64.push(item)
                    } else if (item.packageType2 === 'aarch64') {
                        this.linxos992203_aarch64.push(item)
                    }
                } else if (item.packageType === 'linxos-6.0.100') {
                    if (item.packageType2 === 'x86_64') {
                        this.linxos100_x86_64.push(item)
                    } else if (item.packageType2 === 'arm64') {
                        this.linxos100_arm64.push(item)
                    }
                } else if (item.packageType === '6.0.80') {
                    this.o80_0918.push(item)
                } else if (item.packageType === '6.0.100') {
                    this.o100_0918.push(item)
                } else if (item.packageType === '6.0.99-20.03') {
                    if (item.packageType2 === '6.0.99 20.03-sp3 x86_64') {
                        this.sp3_0918.push(item)
                    } else if (item.packageType2 === '6.0.99 20.03 aarch64') {
                        this.aar_0918.push(item)
                    }
                } else if (item.packageType === '6.0.99-22.03') {
                    if (item.packageType2 === '6.0.99 22.03 x86_64') {
                        this.x86_0918.push(item)
                    } else if (item.packageType2 === '6.0.99 22.03 aarch64') {
                        this.aar2_0918.push(item)
                    }
                }
            });
        },
        getSercurityLevel() {
            axios.get('/db/security_level.json').then((res) => {
                this.security_level = res.data.data
            })
        },
        time(val) {
            return moment(val).format('YYYY-MM-DD')
        },
        getInfoDetail(index, item) {
            this.activeCve = index
            this.infoDetail = item
            this.infoDetail.color = this.security_level[this.infoDetail.bugLevel-1].color
            this.infoDetail.dictLabel = this.security_level[this.infoDetail.bugLevel-1].dictLabel
        }
    }
}
</script>
<style scoped>
#noticeDetail {
    padding: 0 32px;
    width:calc(100% - 64px);
	margin:0;
}
#noticeDetail a {
    color: #0072ce;
    text-decoration:underline;
}
#noticeDetail a:hover {
    color: #005395;
}
#noticeDetail .section_article {
    width: 100%;
}
#noticeDetail .container-announcementdetail {
    background-color: #F5F5F5;
    height: auto;
}
#noticeDetail .section_subnav {
    font-size: 20px;
    margin-bottom: 30px;
    margin-left: 10%;
}
#noticeDetail .subnav_title {
    margin-top: 30px;
}
#noticeDetail .header_title {
    margin-top: 50px;
    text-align: left;
    margin-bottom: 30px;
    text-align: center;
}
#noticeDetail .header_title .title_info span{
    color: #262b33;
}
#noticeDetail .title_content {
    font-size: 30px;
    text-align: center;
}
#noticeDetail .info_date {
    text-align: center;
}
#noticeDetail .article_body {
    text-align: left;
    margin-bottom: 48px;
}
#noticeDetail .article_body p{
    margin: 0;
    text-indent: 0;
}
#noticeDetail h5 {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 400;
    line-height: 28px;
    color: #262b33;
}
#noticeDetail p+h5,
#noticeDetail div+h5 {
    margin-top: 18px;
}
#noticeDetail .share_copyright {
    font-size: 13px;
    text-align: right;
    margin-bottom: 20px;
}
#noticeDetail .m3 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    text-indent: 0;
}
#noticeDetail .el-table + .m3 {
    margin-top: 48px;
}
#noticeDetail .m4 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
    text-indent: 0;
}
#noticeDetail h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 18px 0 6px;
    text-indent: 0;
}
#noticeDetail .m3::before {
    display: none;
}
#noticeDetail .section_article .download {
    margin-bottom: 64px;
}
/*手机端*/
@media only screen and (max-width:750px){
    #noticeDetail {
        padding: 0;
        width: 100%;
    }
    #noticeDetail .section_article .header_title{
        margin: 31px 0;
        text-align: left;
        text-align: center;
    }
    #noticeDetail .section_article .header_title h1 span {
        display: block;
        font-size: 20px;
        color: #262b33;
        font-weight: 400;
        line-height: 32px;
    }
    #noticeDetail .section_article .header_title h1 span:last-child {
        font-size: 30px;
        font-weight: 600;
        line-height: 42px;
    }
    #noticeDetail .section_article .header_title .title_info {
        margin: 0;
        font-size: 14px;
        color: #565d69;
        font-weight: 400;
        line-height: 42px;
        text-indent: 0;
    }
    #noticeDetail .section_article .demo-tabs >>> .el-tabs__header {
        margin-bottom: 20px;
    }
    #noticeDetail .section_article .demo-tabs >>> .el-tabs__header .el-tabs__nav-wrap{
        background-color: #fff;
    }
    #noticeDetail .section_article .demo-tabs >>> .el-tabs__header .el-tabs__item{
        width: 50vw;
        margin: 0 !important;
        padding: 0;
        text-align: center;
        font-size: 15px;
        color: #262b33;
        font-weight: 500;
        line-height: 39px;
        border-bottom: 1px #dbdbdb solid;
    }
    #noticeDetail .section_article .demo-tabs >>> .el-tabs__header .is-active{
        color: #dd1d29;
    }
    #noticeDetail .section_article .demo-tabs >>> .el-tabs__header .el-tabs__active-bar {
        background-color: #dd1d29;
    }
    #noticeDetail .m3{
        position: relative;
        font-size: 20px;
        color: #262b33;
        font-weight: 500;
        line-height: 60px;
        text-indent: 8.5px;
        margin: 0 15px;
    }
    #noticeDetail .el-table + .m3 {
        margin-top: 0px;
    }
    #noticeDetail .m3::before {
        content: '';
        display: block;
        position: absolute;
        margin: 0;
        width: 2.5px;
        height: 16px;
        background-color: #dd1d29;
        left: 0;
        top: 22px;
        border-radius: 1px;
    }
    #noticeDetail .article_body {
        margin-bottom: 37px;
    }
    #noticeDetail .article_body:last-child {
        margin-bottom: 0px;
    }
    #noticeDetail .article_body .noticeInfo {
        width: 100%;
        overflow-x: auto;
    }
    #noticeDetail .article_body .noticeInfo div {
        padding-left: 15px;
    }
    #noticeDetail .article_body .noticeInfo div a {
        position: relative;
        margin-right: 15px;
        display: inline-block;
        width: 83px;
        background-color: #f5f5f5;
        border: 1px #dbdbdb solid;
        border-radius: 6px 8px 6px 6px;
        padding: 9.5px 15px;
        cursor: pointer;
        text-decoration: none;
    }
    #noticeDetail .article_body .noticeInfo div a:hover,
    #noticeDetail .article_body .noticeInfo div .is-active {
        background-color: #ebf3fa;
        border: 1px #337bb5 solid;
    }
    #noticeDetail .article_body .noticeInfo div a p {
        font-size: 15px;
        color: #262b33;
        font-weight: 500;
        line-height: 20px;
    }
    #noticeDetail .article_body .noticeInfo div a:hover p,
    #noticeDetail .article_body .noticeInfo div .is-active p {
        color: #005395;
    }
    #noticeDetail .article_body .noticeInfo div a span {
        display: block;
        position: absolute;
        border-radius: 0px 6px;
        border: 2px #fff solid;
        top: -1px;
        right: -1px;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
        line-height: 12px;
        padding: 1.5px 4.5px;
    }
    #noticeDetail .article_body .infoDetail {
        position: relative;
        background-color: #f5f5f5;
        border-radius: 5px;
        margin: 15px 15px;
    }
    #noticeDetail .article_body .infoDetail::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #dbdbdb;
        bottom: -28px;
    }
    #noticeDetail .article_body .infoDetail p {
        position: relative;
        padding: 5.5px 15px;
        font-size: 14px;
        color: #565d69;
        font-weight: 500;
        line-height: 35px;
        border-bottom: 1px solid #dbdbdb;
    }
    #noticeDetail .article_body .infoDetail p:last-child {
        border-bottom: none;
    }
    #noticeDetail .article_body .infoDetail p span {
        display: block;
        position: absolute;
        top: 14.5px;
        right: 15px;
        font-size: 15px;
        color: #262b33;
        font-weight: 500;
        line-height: 18px;
    }
    #noticeDetail .article_body .infoDetail .desc {
        padding-bottom: 14.5px;
    }
    #noticeDetail .article_body .infoDetail .desc span {
        position: relative;
        top: 0;
        right: 0;
    }
    #noticeDetail .article_body .infoDetail p span a:hover {
        color: #005395;
    }
    #noticeDetail .article_body h4 {
        margin: 0 15px;
        font-size: 18px;
        color: #262b33;
        font-weight: 500;
        line-height: 35px;
    }
    #noticeDetail .article_body > p {
        margin: 0 15px;
        font-size: 14px;
        color: #262b33;
        font-weight: 400;
        line-height: 29px;
    }
    #noticeDetail .article_body .refer p {
        margin: 0 15px;
        font-size: 14px;
        color: #262b33;
        font-weight: 400;
        line-height: 29px;
    }
    #noticeDetail .section_article .download {
        margin-bottom: 0;
        padding: 0 15px;
    }
}
</style>
