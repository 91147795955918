<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div
			class="font"
			v-for="(honor,indexhonor) in honorList"
			:key="indexhonor + '-1'"
			v-show="title4 == indexhonor"
		>
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
			<div class="masonry">
			  	<div
				  	class="item"
					v-for="(honordetail,indexhonordetail) in honor.text"
					:key="indexhonordetail + '-2'"
					:class="honordetail.class"
				>
						<div>
				  			<img class='thum-img' :src="honordetail.imgUrl" :alt="honordetail.imgAlt" @click="clickImg($event)" v-show="honordetail.imgUrl != ''">
				  			<p v-show="honordetail.imgUrl == ''" v-html="honordetail.imgAlt"></p>
						</div>
			  	</div>
			</div>
			<!-- <BigImg v-if="showImg" @clickit="viewImg" :imgSrc="imgSrc" :imgAlt="imgAlt"></BigImg>-->
		</div>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import BigImg from './BigImg.vue';

export default {
	name:'QualificationHonor',
	components: {
	    BigImg
	},
	data() {
		return {
			honorListLength: 0,
			natittle1: this.$attrs.tittle,
			natittle2: '资质荣誉',
			natittle3: '',
			title4: 0,
			icon: require('../images/资质icon.png'),
			title4List: [
				{
					tittle:'公司资质',
				},
				{
					tittle:'产品资质'
				},
				// {
				// 	tittle:'专利证书'
				// },
				{
					tittle:'公司荣誉'
				}
			],
			showImg: false,
			imgSrc: '',
			imgAlt: '',
		}
	},
	computed:{
		...mapState({
			honorList:(state)=>state.Honor.honorList,
		}),
	},
	methods:{
		clickImg(e){
			this.showImg = true;
			this.imgSrc = e.currentTarget.src;
			this.imgAlt = e.currentTarget.alt;
		},
		viewImg(){
			this.showImg = false;
		},
	},
	watch:{
		title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
                if(this.$route.query.title4){
                	this.title4 = this.$route.query.title4;
                }
            },
            deep: true,
        },
	}
}
</script>
<style scoped>
.masonry {
	width: calc(100% - 64px);
	margin:0px 32px 20px;
	display: grid;
	grid-gap: 14px 0;
	grid-template-columns: repeat(auto-fill,264px);
	grid-auto-flow:row dense;
	justify-content:space-around;
}
.masonry .item {
	width: 100%;
	background: #fff;
	color: #000;
	box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	/*cursor:pointer;*/
	justify-self:center;
}
.masonry .item:hover{
	box-shadow: 0px 2px 8px 0px rgba(5,5,5,0.12);
}

.masonry .icontent-lar {
	grid-row-end:span 2;
}
.masonry .item div{
	position: relative;
	width:100%;
	height:100%;
	min-height:162px;
	text-align: center;
	align-items: center;
}
.masonry .item div img{
	position: relative;
	display:block;
	max-width: 95%;
	max-height: 95%;
	left:50%;
    transform: translateX(-50%); 
    padding:10px 0;
}
.masonry .item div p{
	text-indent: 0;
	margin:0;
	position: relative;
	top:calc(50% - 28px);
}
/*手机端*/
@media only screen and (max-width:750px){
	.masonry {
		width: calc(100% - 30px);
		margin:0px 15px 10px;
		display: grid;
		grid-gap: 15px;
		grid-template-columns: repeat(auto-fill,155px);
		grid-template-rows: repeat(auto-fill, 110px);
	}
	.masonry .item {
		width: 155px;
	}
	.masonry .item div{
		min-height:108px;
	}
	.masonry .item div img{
		max-width: calc(100% - 10px);
		max-height: calc(100% - 10px);
		padding:5px;
	}
	.masonry .item div p{
		top:calc(50% - 23px);
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.masonry {
		width: calc(100% - 44px);
		max-width:1036px;
		min-width:992px;
		margin:0px 44px 20px ;
	}
	.masonry .item {
		width: 474px;
		margin-right: calc(100% - 948px - 44px);
		margin-bottom: calc(100% - 948px - 44px);
		background: #fff;
		color: #000;
		text-align: center;
		align-items: center;
		box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	}
	.masonry .item .icontent-lar {
		height: 666px;
		padding-top: 28px;
	}
	.masonry .item .icontent-mid {
		height: 315px;
		padding-top: 20px;
	}
} */
</style>
