<template>
	<div id="cuebox">
        <div class="catalogdiv">
            <p
                v-for="(itemnews,indexnews) in newsDataList"
                :key="indexnews"
                :class="{inyear:itemnews.year == nowyear}"
                @click="changenowyear(itemnews.year)"
            >{{itemnews.year}}</p>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name:'NewsCue2',
    props:['nowyear'],
    computed:{
		...mapState({
			newsDataList:(state)=>state.News.newsDataList,
		})
	},
    methods:{
        changenowyear(val) {
            this.$emit('update:nowyear', val);
        }
    },
}
</script>
<style scoped>
#cuebox{
    display: none;
}
/*pad*/
@media only screen and (max-width:1170px){
	#cuebox{
		display: block;
		position: relative;
		/* height: 40px; */
		width: 100%;
		overflow-x: auto;
		margin-bottom:40px;
	}
	#cuebox .catalogdiv{
		display: block;
		white-space:nowrap;
		height: 70px;
	}
	#cuebox .catalogdiv p{
		display: inline-block;
		position: relative;
		padding-top: 20px;
		margin: 0;
		line-height: 46px;
		font-size: 16px;
		font-weight: 400;
		color: #8d9198;
		height: 46px;
		width: 128px;
		cursor:pointer;
		text-indent:0;
		text-align:center;
	}
	#cuebox .catalogdiv p:hover, #cuebox .catalogdiv .inyear{
		color: rgba(221,29,41,0.9);
	}
	#cuebox .catalogdiv p:before{
		position: absolute;
		content: '';
		width: 100%;
		height: 1px;
		top: 10px;
		left: 0;
		background-color: #cacbcc;
	}
	#cuebox .catalogdiv p:last-child:before{
		width: 50%;
	}
	#cuebox .catalogdiv p:after{
		content: '';
		width: 7px;
		height: 7px;
		background-color: #cacbcc;
		border: 2px solid #fff;
		border-radius: 50%;
		display: inline-block;
		position: absolute;
		left:50%;
		top: 5px;
		transform: translateX(-50%) translateY(0);
	}
	#cuebox .catalogdiv p:hover:after, #cuebox .catalogdiv .inyear:after{
		content: '';
		width: 7px;
		height: 7px;
		background-color: #fff;
		border: 2px solid #cacbcc;
		border-radius: 50%;
		display: inline-block;
		position: absolute;
		left:50%;
		top: 5px;
		transform: translateX(-50%) translateY(0);
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#cuebox .catalogdiv p{
		width: 102px;
	}
}
</style>
