<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<p>凝思软件为国家电网提供了跨平台移植开发服务，成功配合中国电科院和国网电科院实现了应用系统从Tru64平台向凝思安全操作系统平台的移植，完成了开发环境构建、系统机制移植和程序故障排查等任务，实现了凝思安全操作系统从X86体系架构向IA64体系架构的移植。在铁路交通行业的项目中，在国内全面支持了惠普IA64架构的SuperDome高端服务器，并支持BL8系列刀片服务器。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'CrossPlatformPortingDevelopment',
}
</script>
<style scoped>

</style>
