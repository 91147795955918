<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg tittlrImgBorder" id="tittlrImgArmy1">
			<b>军队</b>
			<p>凝思参与多项军队专用设备的研制，多款产品已经成为列装设备。</p>
		</h1>
		<div class="column">
			<div>
				<h2>总参某所</h2>
				<p>当前全国各个部门运用现代通信、网络技术，开展网络办公。WWW服务和DNS服务基本上每个用户都可能进行应用，这是网络系统被入侵的一个不安全因素。网络具有地域广、自由度大等特点，同时上网的有各种各样的用户，他们可能在某些站点上放置一些病毒或者木马，甚至利用某些手段获得你的关键信息（如用户名和口令），如果不小心接触到了这些，可能会给内部网络安全造成极大的威胁。针对这些情况，凝思在<span>凝思安全操作系统</span>的基础上推出了安全<span>WWW服务和DNS服务</span>，确保经由服务器传达的信息总能够在到达目的地时<span>没有任何增加、改变、丢失或被他人非法读取</span>，保证系统服务有一定的安全保护。</p>
			</div>
			<div>
				<h2>总参某系统</h2>
				<p>总参某专业系统承担我军信息系统的重要任务，该系统必须保证该网络上和各种应用服务器的安全和可靠运行，否则会造成不可弥补的重大损失。数据库服务器是一些应用服务器的基础，同时也是黑客攻击的重要目标。必须首先保证数据库服务器的安全可靠运行，才能保证其他应用服务器的安全可靠运行。所以，总参某部对数据库服务器的建设非常重视，在充分调研的基础上，慎重地选择北京凝思软件股份有限公司作为他们的<span>数据库系统的建设单位。</span></p>
			</div>
		</div>
		<div class="column columngray">
			<div>
				<h2>军队项目 - 总参某部某所</h2>
				<p>凝思安全服务器平台支持了数据库管理系统，使用了服务热备切换技术保证其高可用性（列装）</p>
			</div>
			<div>
				<h2>
军队项目 - 总参某部某中心</h2>
				<p>凝思安全服务器平台支持了网站服务与邮件服务</p>
			</div>
			<div>
				<h2>军队项目 - 总参某部某中心</h2>
				<p>凝思提供安全服务器平台和文件服务软件的整体解决方案</p>
			</div>
			<div>
				<h2>
军队项目 - 总参某所</h2>
				<p>凝思安全服务器平台支持了该所专用业务软件和专用加密卡（列装）</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'Military',
	}
</script>
<style scoped>

</style>
