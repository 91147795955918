/* News仓库 */
const state = {
	newsDataList: [
		{
			year: '2024',
			text: [
				{
					id: '97',
					tittle: '成都市委、市政府部门领导一行莅临凝思软件总部调研走访',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-10-08',
					content: [
						'9月27日，成都市委组织部副部长、市公务员局局长魏冰，成都市科技局党组书记、局长丁小斌，成都市委国资国企工委书记、市国资委党组书记、主任李长文等市级部门领导一行莅临北京凝思软件股份有限公司（以下简称“凝思软件”）总部实地调研走访。公司董事长宫敏、总经理李明源、副总经理兼四川凝思负责人彭志航等公司领导陪同调研，双方就人才引育、科技创新和成果转化等方面的经验做法举行座谈交流，共话合作、共叙情谊。',
						{
							src:require('../../images/news/news20241008-01.jpg'),
							alt:'成都市委、市政府部门领导一行莅临凝思软件总部调研走访',
						},
						'座谈会上，宫敏董事长对魏部长一行来访表示欢迎，并感谢成都市委、市政府长期以来对凝思软件和四川凝思的关注与支持。2022年引入成都产业集团旗下的成都科创投基金战略投资，大力推动了凝思在蓉业务的对接、落地。考虑到成都高度聚集的产业人才、良好的营商环境及广阔的市场需求，公司于2022年4月在锦江区成立全资子公司四川凝思软件有限公司，注册资本2亿元，凝思软件正式成为成都市、锦江区“招商引智”项目落户锦江区。公司在锦江区白鹭湾科技生态园投资数亿元购置2万平米西南总部办公楼，组建研发中心、销售中心、工程中心和数据中心。凝思软件将在蓉重点进行人才建设，锻炼、培养出合格的操作系统人才，构建凝思软件北京、成都“双中心”布局。凝思软件副总经理、四川凝思负责人彭志航为到访领导详细介绍了凝思软件的发展历程、核心产品及四川子公司的发展规划，并提出了恳请成都市委、市政府支持事项。',
						{
							src:require('../../images/news/news20241008-02.jpg'),
							alt:'成都市委、市政府部门领导一行莅临凝思软件总部调研走访',
						},
						'成都市委、市政府部门领导对凝思软件的发展历程和取得的成绩表示充分的肯定，并积极回应了企业的诉求。',
						{
							src:require('../../images/news/news20241008-03.jpg'),
							alt:'成都市委、市政府部门领导一行莅临凝思软件总部调研走访',
						},
						'魏部长首先代表成都市委、市政府向宫敏博士团队对成都经济社会发展特别是软件产业发展给予的大力帮助和关心支持表示衷心的感谢。此次拜访主要目的是加强沟通交流，共同推动双方合作迈向更高水平、取得更多成果。',
						'魏部长深入剖析了成都市在创新创业领域的发展环境与人才政策的独特优势。他指出，成都正积极抢抓国家重大战略机遇，培育壮大电子信息、装备制造2个万亿级和航空航天、生物医药等10个千亿级产业集群，吸引了大量国家级科技创新平台和国家高新技术企业的入驻。成都市创新指数已跃升至全球第24位，展现出强大的创新实力。为进一步推动创新，成都实施了顶尖人才及后备人选引育专项计划，为顶尖人才提供个性化、全链条的支持与服务，旨在吸引全球范围内的顶尖人才来蓉创新创业。',
						'魏部长还强调，成都高度重视高层次人才的生活品质，通过提供人才公寓、发放蓉城人才绿卡等举措，为人才营造了优渥的生活和工作环境。他诚挚地希望宫敏董事长等科技创新领军人才，能够深化与成都的合作，共同为成都建设吸引集聚人才平台贡献力量。',
						'成都市科技局党组书记、局长丁小斌，成都市委国资国企工委书记、市国资委党组书记、主任李长文，成都市委组织部部务委员、市人才发展促进中心副主任，市委人才办专职副主任阳夷，成都市人社局党组成员、副局长李果等领导分别致辞，并对公司在科技成果转化、政策推动落地、市场产品拓展、校企合作、人才引进政策等诉求进行了详细解答。他们表示，将进一步加强现有政策的推广与落地，并根据企业的建议进行政策改进。',
						'最后，魏部长热忱地邀请宫敏董事长和各位公司高管亲临成都进行实地考察，亲身体验这座城市的蓬勃生机与创新活力。他表示，无论是从国家战略的高度，还是从技术发展的视角，成都与凝思软件在未来都存在着诸多契合点与广阔的发展空间。他期待双方能够加强务实高效合作，共同开创互利共赢的新局面。',
						'宫敏董事长表示，凝思软件将把更多的精力投入到技术持续创新与市场深度拓展上，深化北京、成都“双中心”布局。在成都市委、市政府的坚强后盾下，凝思软件定能更加稳健地前行，不断向公司的初心无限靠近，为成都软件产业发展、高端软件人员培养、新质生产力的形成和发展贡献更大的力量。',
						{
							src:require('../../images/news/news20241008-04.jpg'),
							alt:'成都市委、市政府部门领导一行莅临凝思软件总部调研走访',
						},
						'此次调研交流不仅加深了成都市委、市政府主管部门与凝思软件之间的了解与合作，也为凝思软件在蓉未来的发展奠定了坚实基础。双方高度认同秉承“和成都一起成长、和伟大企业相互成就”的初衷，将建立更加紧密的合作关系，共同推动成都基础软件产业的繁荣发展，为国家和成都的信息化、智能化、自动化建设，为成都的高端就业和税收，为成都新质生产力的形成和发展，贡献更大的力量。',
						'成都产业集团党委专职副书记、董事、工会主席张栩，成都科创投集团总经理刘新常，成都市委组织部、成都市政府驻京办、锦江区组织部相关负责人，凝思软件副总经理、董事会秘书高春生，副总经理夏孙洲陪同调研。'
					]
				},
				{
					id: '96',
					tittle: '2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-09-27',
					content: [
						'9月25日至27日，由中国工业和信息化部主办的“2024年国际信息通信展”（以下简称“PT展”）在北京国家会议中心成功举办。本届大会以“推动数实深度融合 共筑新质生产力”为主题，为全行业搭建起展示、交流、合作、共享的舞台。北京凝思软件股份有限公司（以下简称“凝思软件”）亮相本次盛会，并荣获“最受欢迎品牌奖”。',
						{
							src:require('../../images/news/news20240927-01.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						'PT展作为泛ICT行业影响力盛会，自1990年创办以来，已成功举办31届，是反映信息通信行业发展最新成果的重要窗口与促进交流合作的重要桥梁，被誉为“ICT市场的创新基地和风向标”。本届大会汇聚了众多信息通信行业精英人士、知名专家学者，分享研究成果，交流发展思路，共同推动数实深度融合发展。',
						{
							src:require('../../images/news/news20240927-02.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						{
							src:require('../../images/news/news20240927-03.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						{
							src:require('../../images/news/news20240927-04.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						'展会期间，凝思软件重点展示了凝思安全终端及行业应用案例。工信部相关司局领导先后莅临凝思软件展位参观指导，并对凝思软件取得的创新成果和应用案例给予充分肯定。除此之外还吸引了运营商代表、专家学者及行业客户等前来沟通交流，凝思软件产品和解决方案获得广泛认可。',
						{
							src:require('../../images/news/news20240927-05.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						'凝思软件作为国内先进的安全操作系统厂商，具有强大的研发能力和长期、丰富的操作系统产品运维经验，一直致力于为中国通信行业信息化建设提供更为安全、可靠的操作系统环境。目前，凝思软件已累积服务某运营商迁移、XC政务云调优、安全云桌面行业应用等多个项目，助力行业数字化和智能化建设。',
						{
							src:require('../../images/news/news20240927-06.jpg'),
							alt:'2024年国际信息通信展|凝思软件获评“最受欢迎品牌奖”',
						},
						'此次获评“最受欢迎品牌奖”，充分体现了通信行业对凝思软件技术研发实力与产品创新能力的认可。未来，凝思软件将一如既往地发挥自己在安全操作系统领域的优势，有力地支撑中国通信行业的业务发展，为通信行业的数字化建设贡献力量。'
					]
				},
				{	id: '95',
					tittle: '凝思软件亮相2024年电力信息通信新技术大会，助力智能电力建设',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-08-19',
					content: [
						'8月14日至16日，由中国能源研究会主办的2024年电力信息通信新技术大会在北京召开。大会以“数智赋能新质生产力·助力新型电力系统建设”为主题，聚焦数字化智能化发展新要求，深入探讨“人工智能+”、“数据要素×”等技术在电力领域的最新发展动态与未来趋势。北京凝思软件股份有限公司（以下简称“凝思软件”）作为深耕电力领域多年的国产安全操作系统厂商，受邀作为本次大会的联合支持单位亮相盛会，并发表主题演讲。',
						{
							src:require('../../images/news/news20240819-01.jpg'),
							alt:'凝思软件亮相2024年电力信息通信新技术大会，助力智能电力建设',
						},
						'电力信息通信新技术大会历时多届，受到业内人士的广泛关注，已经成为我国电力信息通信领域的重要会议之一。本次大会主要内容包括数字化智能化主旨会议，以及电力信创、大数据、人工智能、通信网络、数据通信、感知与物联等六场专题会议。众多来自电网企业、发电企业、电力建设企业、信息通信公司、科研院所、信息通信技术领域的相关专家学者共同探讨新型电力系统建设。',
						{
							src:require('../../images/news/news20240819-02.jpg'),
							alt:'凝思软件亮相2024年电力信息通信新技术大会，助力智能电力建设',
						},
						'在电力信息技术应用创新专题会议上，凝思软件研发中心副总经理曾宏安发表《安全可靠操作系统助力智能电力建设》主题演讲，重点介绍了安全可靠操作系统在数据处理、实时监控和故障预警中的优势。借助实际案例，展示了该系统如何提升电力系统的运行效率和稳定性，同时降低运营风险，为推动智能电力建设提供了参考。',
						{
							src:require('../../images/news/news20240819-03.jpg'),
							alt:'凝思软件亮相2024年电力信息通信新技术大会，助力智能电力建设',
						},
						{
							src:require('../../images/news/news20240819-04.jpg'),
							alt:'凝思软件亮相2024年电力信息通信新技术大会，助力智能电力建设',
						},
						'在数字技术创新成果示范宣传交流区，工信部、国家电网、南方电网等单位领导莅临凝思软件展位参观指导。凝思软件深耕电力行业多年所积累的经验案例、解决方案收获了多位领导和专家的肯定与好评。',
						'在数字化与智能化浪潮下，电力行业正迎来深刻变革。安全可靠的操作系统作为电力系统的核心技术之一，扮演着至关重要的角色。凝思软件凭借优异的产品质量和完善的管理体系，顺利通过了安全可靠测评，有能力为电力行业用户提供高安全、高可靠、高稳定的操作系统产品。未来，凝思软件也将不断深化技术研发能力，为构建智慧电力网络提供坚实的技术支撑，助力电力行业高质量发展。'
					]
				},
				{	id: '94',
					tittle: '凝思软件亮相2024中国国际金融展，助力数字金融高效发展',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-07-22',
					content: [
						'7月19日至21日，由商务部批准、中国金融电子化集团有限公司主办的2024中国国际金融展（以下简称“金融展”）在北京国家会议中心举办。北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内先进的安全操作系统厂商，携凝思安全操作系统与行业解决方案亮相本次展会。同时，凝思“域控融合安全可靠系统”荣膺2024金鼎奖——“优秀金融科技应用创新案例奖”。',
						'金融展历时30年，是我国规格最高、历史最久的金融科技展。本届展会以“数字金融引领未来 · 守正创新共筑金融新生态”为主题，凝聚发展共识、聚焦数字转型，吸引了全国多家金融机构、创新科技企业以及众多行业专家参展。',
						{
							src:require('../../images/news/news20240722-01.jpg'),
							alt:'凝思软件亮相2024中国国际金融展，助力数字金融高效发展',
						},
						'在科技赋能产业创新展区，凝思软件展台备受关注。中国证券监督管理委员会科技监管司蒋副司长、北京金融科技产业联盟聂秘书长等领导先后到凝思展位莅临参观。除此之外还有众多知名金融机构代表、行业客户等前来沟通交流，凝思产品和解决方案获得广泛认可。凭借超高的人气，凝思展台荣获“展台人气奖”。',
						{
							src:require('../../images/news/news20240722-02.jpg'),
							alt:'凝思软件亮相2024中国国际金融展，助力数字金融高效发展',
						},
						'在凝思展台，凝思安全操作系统吸引了众多观众围观。凝思安全操作系统是凝思软件面向金融、能源、通信等各行业设计的一款通用企业级服务器操作系统，集成凝思固域内核安全子系统，融入众多的自研竞争力特性，可以为用户提供稳定可靠、安全易用、性能卓越的业务体验。目前，凝思安全操作系统已在多家股份制银行、区域性商行、证券、基金等金融机构进行测试和应用。',
						'在金融行业的数智化发展过程中，安全是发展的前提。凝思软件凭借优异的产品质量和完善的管理体系顺利通过了安全可靠测评，凝思软件有能力为金融行业用户提供高安全、高可靠、高稳定的操作系统产品，并为金融行业用户提供全面的生态支持和技术保障服务。',
						{
							src:require('../../images/news/news20240722-05.jpg'),
							alt:'凝思软件亮相2024中国国际金融展，助力数字金融高效发展',
						},
						{
							src:require('../../images/news/news20240722-03.jpg'),
							alt:'凝思软件亮相2024中国国际金融展，助力数字金融高效发展',
						},
						'随着“金鼎奖”的颁布，为期三天的金融展也画上了一个圆满的句号。经过专家和行业机构评审，凝思“域控融合安全可靠系统”被评选为2024金鼎奖——“优秀金融科技应用创新案例奖”，这充分体现了金融行业对凝思软件技术研发实力与产品创新能力的认可。未来，凝思软件将会带着这份信任，结合行业用户需求，持续推进技术研发与产品创新，为金融科技创新、数字化转型提供坚实的基础和保障。'
					]
				},
				{	id: '93',
					tittle: '2024年北京市属国有企业软件采购谈判结果发布，凝思软件顺利入选助力软件正版化',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-07-10',
					content: [
						'“2024年北京市属国有企业软件采购谈判工作会”于5月召开，此次会议由北京市使用正版软件联席会议办公室主办，北京市正版软件服务工作站承办。北京凝思软件股份有限公司（以下简称“凝思软件”）作为北京市重点操作系统企业，受邀出席。',
						{
							src:require('../../images/news/news20240710-01.jpg'),
							alt:'2024年北京市属国有企业软件采购谈判结果发布，凝思软件顺利入选助力软件正版化',
						},
						'近日，北京市正版软件服务工作站发布2024年市属国有企业软件采购谈判结果公告，凝思安全操作系统顺利入选。',
						'凝思安全操作系统是面向政企、金融、能源、通信等行业设计，为用户提供安全可靠、性能卓越、稳定易用的通用服务器操作系统，并内置自主研发的固域内核安全子系统，适用于服务器、AI高性能计算、云计算、云原生等场景，具备完善的生态，支持鲲鹏、海光、龙芯、飞腾、兆芯、申威等处理器，已顺利通过安全可靠测评。',
						'同时，凝思软件拥有一支行业专家带头、研发能力强、迁移经验丰富的高素质研发和技术服务团队，具备遍布全国的驻场服务及全天候快速响应能力，为各行业用户提供坚实的技术保障。',
						'本次谈判工作会为凝思软件与用户搭建了沟通的桥梁，凝思软件将充分发挥自身优势，以优质的产品和服务助力市属国有企业用户实现操作系统正版化与国产化替代，为软件正版化工作贡献力量。'
					]
				},
				{	id: '92',
					tittle: '凝思软件受邀出席2024证券基金业数智科技创新高层论坛，助力金融业关键信息国产化',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-07-04',
					content: [
						'6月28日，2024证券基金业数智科技创新高层论坛在北京举行。本次论坛由思行汇科教育科技（北京）有限公司主办，数字经济产业中心支持。论坛以“创新驱动，数智未来”为主题，邀请证券、基金、期货和资管等领域科技专家，围绕基金、期货信创的未来发展、建设思路等展开深度讨论。北京凝思软件股份有限公司（以下简称“凝思软件”）受邀出席本论坛，并发表主题演讲。',
						{
							src:require('../../images/news/news20240704-01.jpg'),
							alt:'凝思软件受邀出席2024证券基金业数智科技创新高层论坛，助力金融业关键信息国产化',
						},
						'随着《证券期货业科技发展“十四五”规划》、《证券期货业网络和信息安全管理办法》等政策的陆续发布，加快及强化金融信创的落地实施已成为证券基金行业的要务之一。在金融行业的数智化发展过程中，安全是发展的前提，推进金融数字化进程，必须坚持“安全、自主、可控”的发展路线，而为各项金融服务提供安全、稳定、可靠支撑环境的操作系统更是发展的重中之重。',
						{
							src:require('../../images/news/news20240704-02.jpg'),
							alt:'凝思软件受邀出席2024证券基金业数智科技创新高层论坛，助力金融业关键信息国产化',
						},
						'论坛上，凝思软件葛佰勇发表《凝思软件助力金融业关键信息国产化》主题演讲。凝思软件始终秉承“基于开源，但不依赖于开源，更不受制于任何商业发行版”的理念，采用自主可控的技术路线，研发了凝思安全操作系统及其相关产品。凝思安全操作系统已通过国家安全可靠测评，获得国家权威机构认可；其内置自主研发的“固域”内核安全子系统，符合信息安全等级保护第四级要求；适配国内外主流的软硬件平台等满足金融核心业务系统国产化改造需求。目前，凝思安全操作系统已在多家股份制银行、区域性商行、证券、基金等金融机构进行测试和应用。',
						'凝思软件作为国内先进的安全操作系统厂商，自成立以来就以“实现国家大型基础设施行业基础软件国产化、安全化”为使命，致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务。未来，凝思软件将继续发挥自身优势，联合金融用户，结合用户的实际场景，在云原生、分布式、AI等前沿领域进行课题探索和创新研究，持续助力金融行业数字化转型升级，不断赋能金融行业信创建设。'
					]
				},
				{	id: '91',
					tittle: '凝思安全操作系统顺利通过安全可靠测评',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-05-21',
					content: [
						'5月20日，中国信息安全测评中心、国家保密科技测评中心联合发布了《安全可靠测评结果公告（2024年第1号）》，将安全可靠测评结果予以公布。其中，凝思安全操作系统顺利通过测评。',
						{
							src:require('../../images/news/news20240521-01.jpg'),
							alt:'凝思安全操作系统顺利通过安全可靠测评',
						},
						'安全可靠测评主要面向计算机终端和服务器搭载的中央处理器（CPU）、操作系统以及数据库等基础软硬件产品，通过对产品及其研发单位的核心技术、安全保障、持续发展等方面开展评估，评定产品的安全性和可持续性，实现对产品研发设计、生产制造、供应保障、售后维护等全生命周期安全可靠性的综合度量和客观评价。',
						'北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内先进的安全操作系统厂商，自成立以来坚持以自有技术、产品和服务为国家大型基础设施行业提供更安全、更可信的自主操作系统环境，为国家关键领域的建设保驾护航。凝思安全操作系统具有高安全性、高性能、高稳定性等特性，内置凝思完全自主研发的“固域”内核安全子系统，具有多层次、结构化的安全体系。目前，凝思安全操作系统在电力、电信运营商、轨道交通、金融、政府等重点行业的生产及关键业务系统已成功部署数十万套，在线稳定运行多年，产品的安全性、稳定性经过了充分的在线验证。',
						'凝思安全操作系统此次通过安全可靠测评，标志着凝思软件的自主研发实力和产品能力得到了国家权威机构的认可。未来，凝思软件将持续优化自身技术研发能力与产品创新能力，为用户提供更安全、更可信的操作系统环境，助力用户数字化转型升级，持续推动产业数字化发展。'
					]
				},
				{	id: '90',
					tittle: '喜报|凝思安全操作系统欧拉版V6.0.99荣获“新一代信息技术创新产品”奖',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-04-22',
					content: [
						'4月17日，由赛迪顾问股份有限公司主办的2024IT市场年会在北京召开，北京凝思软件股份有限公司（以下简称“凝思软件”）受邀出席本次会议。凝思软件所申报的“凝思安全操作系统欧拉版V6.0.99”成功通过“产品创新”评选，荣获“新一代信息技术创新产品”奖。',
						{
							src:require('../../images/news/news20240422-01.jpg'),
							alt:'喜报|凝思安全操作系统欧拉版V6.0.99荣获“新一代信息技术创新产品”奖',
						},
						'大会以“智引前沿，数造未来”为主题，邀请众多行业专家学者，围绕人工智能、大数据、云计算、电子信息、软件与信息服务、数字转型等领域，对其发展现状与行业趋势展开探讨，共同探寻IT行业发展新路径。在工业控制系统论坛闭门会上，凝思软件与行业企业、专家及用户分享自身对推动工业操作系统转型升级的经验与见解，与大家携手推动工业控制系统高质量发展，为赋能新型工业化，形成制造业新质生产力贡献力量。',
						'凝思安全操作系统欧拉版V6.0.99是凝思软件基于openEuler开源社区版开发的一款服务器安全操作系统。此款操作系统支持X86、ARM、LoongArch等架构，内置凝思自主设计研发的固域内核安全子系统，组成了多层次、结构化的安全体系，具有高可靠性、高安全性、高性能和良好兼容性等特性，能够为用户在操作系统层面提供有力支撑，保障生产及关键业务系统的安全稳定运行。',
						'凝思软件作为国内先进的安全操作系统厂商，自成立以来就以“实现国家大型基础设施行业基础软件国产化、安全化”为使命，致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行。在数字经济蓬勃发展的今天，凝思软件将持续优化自身技术研发能力与产品创新能力，以自有技术、产品和服务为更多行业客户提供更安全、更可信的自主操作系统环境，助力企业数字化转型升级，为数字经济发展提供新动能。'
					]
				},
				{	id: '89',
					tittle: '不受影响！凝思安全操作系统各版本均不受XZ后门影响',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-04-02',
					content: [
						'3月29日，XZ Utils 5.6.0和5.6.1版本存在严重后门风险的消息一经披露，北京凝思软件股份有限公司（以下简称“凝思软件”）立即对旗下产品展开排查分析，经验证凝思安全操作系统各个发行版均不受影响，可放心使用。',
						'<b>漏洞描述：</b>',
						'XZ是一种无损数据压缩格式和软件，XZ Utils是一套旨在压缩和解压 XZ 格式文件的实用工具集合，通常被集成在很多Linux发行版中。liblzma是XZ-Utils依赖的一个压缩库，提供了类似于zlib的编程接口，用于实现LZMA算法，允许开发者在他们的应用程序中集成LZMA压缩和解压缩功能。',
						'从5.6.0版本开始，XZ的上游tarball中发现被投毒植入了恶意代码。 恶意代码修改了XZ Utils包中liblzma库的函数，可能导致任何链接到XZ库的软件能够拦截和修改数据。在特定条件下，该后门可能允许恶意行为者破坏sshd认证，从而获得对受影响系统的访问权限。这一安全漏洞致使众多Linux发行版受到波及。',
						'<b>漏洞评分：</b>',
						'10.0 （高危）',
						'<b>影响版本：</b>',
						'XZ-Utils 5.6.0或 5.6.1',
						'<b>安全范围：</b>',
						'XZ-Utils <5.6.0',
						'<b>凝思安全操作系统各版本分析结果：</b>',
						{
							src:require('../../images/news/news20240402-01.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'6.0.42版本不受影响，安全！',
						{
							src:require('../../images/news/news20240402-02.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'6.0.60版本不受影响，安全！',
						{
							src:require('../../images/news/news20240402-03.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'6.0.80版本不受影响，安全！',
						{
							src:require('../../images/news/news20240402-04.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'6.0.99版本不受影响，安全！',
						{
							src:require('../../images/news/news20240402-05.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						{
							src:require('../../images/news/news20240402-06.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'6.0.100版本不受影响，安全！',
						{
							src:require('../../images/news/news20240402-07.png'),
							alt:'不受影响！凝思安全操作系统各版本均不受XZ后门影响',
						},
						'经上述分析，凝思安全操作系统各版本均已被验证不在该漏洞影响范围内，用户可放心使用。',
						'未来，凝思软件将进一步加强软件构建和上游供应链检查，将安全始终放在最高优先级，版本选择方面优先选择发布已有一段时间、久经考验且并未发现重大安全漏洞的版本，从而为用户提供更为可靠的安全保障。'
					]
				},
				{	id: '88',
					tittle: '凝思软件成功中标中国电信2024年服务器操作系统集中采购项目',
					tag: '要闻',
					form: '销售部',
					examine: '市场部',
					time: '2024-02-22',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）凭借优异的产品和专业的工程及售后服务优势，成功中标中国电信2024年服务器操作系统集中采购项目，成为中国电信服务器操作系统的产品、服务综合供应商。',
						'服务器操作系统作为上承应用软件，下接硬件基础设施的关键基础软件，在确保通信业务的稳定性和安全性方面发挥着至关重要的作用。凝思服务器安全操作系统由凝思软件完全自主构建，具有高可靠性、高安全性、高性能和良好兼容性等特性。同时，内置凝思“固域”内核安全子系统，具有多层次、结构化的安全体系，能够在操作系统底层充分保障业务系统的安全。',
						'凝思软件一直致力于为中国通信行业信息化建设提供更为安全、可靠的操作系统环境,此次成为中国电信服务器操作系统的产品、服务供应商，标志着中国电信对凝思软件产品和服务的充分认可与肯定。未来，凝思软件将一如既往地发挥自己在安全操作系统领域的优势，有力地支撑中国通信行业的业务发展，为我国通信安全筑牢坚实底座。'
					]
				},
				{	id: '87',
					tittle: '凝思软件登榜2023中国最具投资价值操作系统企业',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2024-01-04',
					content: [
						'近日，赛迪顾问发布了《2023中国最具投资价值基础软件企业》十强榜单，北京凝思软件股份有限公司（以下简称“凝思软件”）成功登榜当选！',
						{
							src:require('../../images/news/news20240104-03.png'),
							alt:'凝思软件登榜2023中国最具投资价值操作系统企业',
						},
						'此次评价体系包含赛道分和企业分两部分。赛道分主要由行业发展空间及结构衡量和赛道情况两部分组成。企业分主要由商业模式、企业基本面、市场壁垒等七部分组成。通过对35家基础软件企业的调研访谈，评定出2023中国最具投资价值基础软件企业榜单。凝思软件凭借过硬的研发和创新能力等优势，获得赛迪评委团高度认可。',
						'赛迪点评指出：凝思软件在市场上具有技术引领性，是操作系统的头部企业，也是电力行业中的绝对龙头企业；公司完全自主研发的“固域”内核安全子系统，在国内同类厂商中具有稀缺性；凝思软件已完成多轮战略融资工作，于2023年8月3日在深交所创业板顺利过会。',
						'凝思软件作为国内先进的国产安全操作系统厂商，自成立以来就以“实现国家大型基础设施行业基础软件国产化、安全化”为使命，致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行。凝思软件的核心竞争力在于以电力行业为首的技术强度和应用深度，产品具有较高的标准机构认可度。目前，公司正逐步推进金融、通信、轨道交通、石油石化等其他国家关键基础设施行业应用，依托高安全、高稳定、高可靠的特性及大型基础行业丰富的迁移运维经验优势，持续拓展行业市场。',
						'赛迪报告指出，未来三年，操作系统市场将保持稳定增长，市场增量主要来自于数据中心建设带来的大量服务器操作系统需求。对此，凝思软件将顺应操作系统发展趋势，抓住发展机遇，不断提高技术开发能力与自主创新能力，以自有技术、产品和服务为更多行业客户提供更安全、更可信的自主操作系统环境，助力企业数字化转型升级，持续推动产业数字化发展。',
					]
				},
				{	id: '86',
					tittle: '凝思安全操作系统（移动设备版）V6.0荣获“2023四川省优秀软件产品”奖',
					tag: '要闻',
					form: '综合管理部',
					examine: '市场部',
					time: '2024-01-04',
					content: [
						'近日，四川省软件行业协会发布了《2023四川省优秀软件产品公示名单》。四川凝思软件有限公司申报的凝思安全操作系统（移动设备版）V6.0经过专家评审，完全符合《软件产品竞争力评价规范T/SCSIA2-2019》,同时该产品还是本次唯一荣获“2023四川省优秀软件产品”奖的基础软件类产品。',
						{
							src:require('../../images/news/news20240104-01.jpg'),
							alt:'凝思安全操作系统（移动设备版）V6.0荣获“2023四川省优秀软件产品”奖',
						},
						{
							src:require('../../images/news/news20240104-02.jpg'),
							alt:'凝思安全操作系统（移动设备版）V6.0荣获“2023四川省优秀软件产品”奖',
						},
						'四川凝思软件有限公司（以下简称“四川凝思”）于2022年在成都市锦江区成立，为北京凝思软件股份有限公司全资子公司，注册资本6500万元，是锦江区重点招商引资企业。目前，凝思软件在锦江区白鹭湾科技园投资数亿元组建研发中心、销售中心、工程中心和数据中心，构建北京、成都“双中心”布局。',
						'凝思安全操作系统（移动设备版）V6.0荣获“2023四川省优秀软件产品”奖，体现了四川省软件行业协会对四川凝思的软件开发能力、技术水平和自主创新能力的充分认可，对塑造凝思软件品牌形象，提高企业市场竞争力具有重要作用。未来，四川凝思将继续发挥自身技术和研发优势，以“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，助力四川省软件产业发展。'
					]
				},
				{	id: '85',
					tittle: '四川凝思获2023年第二批“高新技术企业”认定',
					tag: '要闻',
					form: '综合管理部',
					examine: '市场部',
					time: '2024-01-02',
					content: [
						'近日，全国高新技术企业认定管理工作领导小组办公室发布了《对四川省认定机构2023年认定报备的第二批高新技术企业拟进行备案的公示》，四川凝思软件有限公司（以下简称“四川凝思”）依托扎实的研发与创新能力，成功获得2023年第二批“高新技术企业”认定。',
						{
							src:require('../../images/news/news20240102-01.png'),
							alt:'四川凝思获2023年第二批“高新技术企业”认定',
						},
						{
							src:require('../../images/news/news20240102-02.png'),
							alt:'四川凝思获2023年第二批“高新技术企业”认定',
						},
						'四川凝思软件有限公司（以下简称“四川凝思”）于2022年在成都市锦江区成立，为北京凝思软件股份有限公司全资子公司，注册资本6500万元，是锦江区重点招商引资企业。目前，凝思软件在锦江区白鹭湾科技园投资数亿元组建研发中心、销售中心、工程中心和数据中心，构建北京、成都“双中心”布局。',
						'四川凝思本次顺利通过四川省高新技术企业的审查认定，标志着四川凝思在国产安全操作系统领域的技术实力、研发水平和自主创新能力得到充分认可，对提升凝思软件品牌影响力和市场竞争力具有重要作用。未来，四川凝思将继续加大技术研发人才和资金投入，进一步提高自主创新能力，为国产安全操作系统持续发展贡献力量。'
					]
				}
			]
		},
		{
			year: '2023',
			text: [
				{	id: '84',
					tittle: '凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-12-22',
					content: [
						'“操作系统大会&openEuler Summit 2023”于12月15日—16日在北京国家会议中心隆重举办。本次大会由开放原子开源基金会、中国电子技术标准化研究院、工信安全、中国软件行业协会共同主办，北京凝思软件股份有限公司（以下简称“凝思软件”）作为协办单位积极参与了本次大会，并荣获“2023openEuler社区突出贡献单位奖”，“CPM4OSSP—UI”项目也成功入选“openEuler2023年度优秀项目”。',
						{
							src:require('../../images/news/news20231222-01.jpg'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						'数字化、智能化浪潮正汹涌而来。大会旨在汇聚全球产业界创新力量，构筑坚实的基础软件根基，推动基础软件技术持续创新，共建全球开源新生态。院士、产业组织及全球开源基金会代表、学术领袖、领先行业代表、技术专家等1000+位海内外嘉宾受邀出席本次大会，共探操作系统产业发展方向和未来机遇。',
						{
							src:require('../../images/news/news20231222-02.jpg'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						{
							src:require('../../images/news/news20231222-03.png'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						{
							src:require('../../images/news/news20231222-04.jpg'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						'本次大会组织召开了两场主论坛、十余场分论坛、三十余场社区专题会议，布置了近百个展位。主论坛颁奖环节，大会主办方为凝思软件颁发“2023openEuler社区突出贡献单位奖”，以表彰凝思软件在过去一年中对openEuler社区所作出的突出贡献。凝思软件设计研发的“CPM4OSSP—UI”项目，即“操作系统软件包集中管理平台”，也成功入选“openEuler2023年度优秀项目”，该软件可以实现多个服务器的软件包安装、升级、卸载统一管理的功能。这些充分展现了openEuler社区对凝思软件所作贡献与技术实力的认可。同时，凝思展位所演示的“凝思安全操作系统欧拉版” 以及“凝思实时微内核系统”也备受关注，成为展区亮点之一。除此之外，凝思软件还受邀出席迁移与运维分论坛和安全分论坛进行演讲分享。',
						{
							src:require('../../images/news/news20231222-05.png'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						'在迁移与运维分论坛，凝思软件研发中心副总经理曾宏安带来了《基于X2工具迁移方案实践分享》的主题演讲，随着主流服务器操作系统发布策略的变化和企业数字化转型的深入，许多企业都面临操作系统迁移的问题。依托openEuler社区技术能力，凝思软件构建了一站式运维流程体系，具备openEuler迁移部署、运维巡检、定制化等端到端的服务能力，助力企业高效进行操作系统迁移。',
						{
							src:require('../../images/news/news20231222-06.png'),
							alt:'凝思软件受邀出席“操作系统大会&openEuler Summit 2023”',
						},
						'在安全技术分论坛，凝思软件研发中心总工程师张国栋分享了《基于openEuler的工控行业可信解决方案》的主题演讲。该方案面向工控行业控制系统进行主动免疫式可信安全防护体系建设，分别针对系统的控制器、服务器、网络设备、边界设备等核心终端进行可信安全增强。具体实施过程中，凝思软件通过在系统层部署凝思自研的可信支撑模块、操作系统可信增强系统等系列可信计算软件来实现可信安全增强的效果。该方案还同时满足等保2.0标准安全通用要求及工业控制系统安全扩展要求对可信验证的要求，实现可信安全与合法合规并举。基于该方案，凝思软件可以帮助工控行业用户有效解决做好工业控制安全监管工作，防范生产事故的痛点问题，保障工业控制系统安全稳定运行，提升用户的业务体验。',
						'操作系统作为数字基础设施的底座，已经成为推动产业数字化、智能化发展的核心力量。凝思软件作为国内先进的安全操作系统厂商，将继续依托openEuler社区这一开放平台，与社区用户、上下游伙伴等携手并进，构筑坚实的软件根基，共创数智新未来。'
					]
				},
				{	id: '83',
					tittle: '助力新型工业化发展，凝思软件亮相2023工业控制系统产业大会',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-11-08',
					content: [
						'11月3日，“2023 工业控制系统产业大会”在长沙隆重召开。此次会议以“聚焦核心技术，助推新型工业化发展”为主题，共同探讨新一代信息技术与工控系统相集成，赋能制造业高质量发展。北京凝思软件股份有限公司（以下简称“凝思软件”）作为协办单位亮相本次大会并发表《工业互联网安全云边融合解决方案》主题演讲。',
						{
							src:require('../../images/news/news20231108-01.jpg'),
							alt:'助力新型工业化发展，凝思软件亮相2023工业控制系统产业大会',
						},
						'此次大会由中国高科技产业化研究会、湖南省科学技术协会、国防科技大学、湖南大学、中南大学共同主办，工业控制系统产业联盟、长沙经济技术开发区管委会等单位共同承办，凝思软件等单位协办。来自中国工程院、湖南省科学技术协会、中国高科技产业化研究会、工业控制系统产业联盟、湖南大学等单位以及业内的专家学者，围绕能源电力、轨道交通等行业面临的突出问题、技术需求、发展趋势、产业应用等重点话题展开讨论，共同探索助推新型工业化新路径。',
						{
							src:require('../../images/news/news20231108-02.jpg'),
							alt:'助力新型工业化发展，凝思软件亮相2023工业控制系统产业大会',
						},
						'凝思软件副总经理彭志航受邀出席工控智能创新发展论坛暨工业控制与新一代信息技术融合创新高峰论坛，并发表《工业互联网安全云边融合解决方案》主题演讲。凝思作为国内先进的操作系统厂商，利用操作系统和云计算的产品能力和技术优势，搭建了“云-网-边-端”分层次、立体化的产品架构体系。其中凝思“服务器集群+分布式存储+网络虚拟化+虚拟机+容器+业务应用微服务”的云边融合解决方案拥有物理资源虚拟化，按需灵活分配，动态调整；基于健康检查机制与丰富调度算法，负载均衡高可用；快速创建与回收实例，根据负载弹性扩缩；集中高效管理、编排，统一安全策略配置；数据多副本冗余，持久安全存储；业务轻松上云，支持丰富应用，覆盖广泛场景等优势，可以为工业互联网安全提供重要安全保障，提升用户的业务体验。',
						{
							src:require('../../images/news/news20231108-03.jpg'),
							alt:'助力新型工业化发展，凝思软件亮相2023工业控制系统产业大会',
						},
						'作为“云-网-边-端”全栈产品的建设者，凝思持续通过生态协同，连通产业链上下游合作伙伴，为其提供优质的产品能力支持，推动凝思产品和安全体系在电力、能源、交通等多个行业的融合和应用。未来，凝思软件将一如既往地以云、网、边、端的新技术、新产品，为工业互联网提供安全的云边融合、全栈融合的一体化解决方案，推动工业化与信息化的“两化”融合，创造工控领域的数字化未来。',
					]
				},
				{	id: '82',
					tittle: '奉献亚运、保电有我——凝思软件顺利完成亚运会期间保电任务',
					tag: '要闻',
					form: '凝思软件江苏分公司',
					examine: '市场部',
					time: '2023-10-11',
					content: [
						'秋分送爽，桂子飘香。杭州奥体中心体育场“大莲花”华灯璀璨，流光溢彩。9月23日晚第十九届亚洲运动会隆重开幕，历经16天，10月8日完美收官。恭喜我国运动健儿荣获201枚金牌、111枚银牌、71枚铜牌。',
						'自杭州亚运会开赛以来，为坚决贯彻国家电网各项工作措施，北京凝思软件股份有限公司（以下简称：“凝思软件”）技术工程人员在赛前就加大对浙江省公司调度系统的巡检力度和频次，消除系统运行隐患。亚运会期间，凝思软件工程人员驻扎一线，对重点业务系统进行全天不间断巡检，及时处理突发事件，保障业务系统稳定运行，顺利完成亚运会期间保电任务。',
						{
							src:require('../../images/news/news20231011-01.jpg'),
							alt:'奉献亚运、保电有我——凝思软件顺利完成亚运会期间保电任务',
						},
						'凝思安全操作系统在电力系统发电、输电、变电、配电、用电、储电等生产业务方面均有大规模部署。公司自成立以来先后参与了河北电力与山东电力的信息安全专项行动、国家电网安全大检查等实战性攻击检查活动，安全保障能力得到了相关部门的认可,还参与配合一带一路会议、厦门金砖国家峰会、冬奥会与冬残奥会等国家重大会议及赛事期间的电力保障工作，出色地完成了电力安全保障任务。凝思系统的安全性在实战性攻击检查和生产安全防护中得到充分验证，有力保障了大型行业的生产安全和信息安全。',
						'未来，凝思软件将一如既往地发挥自己在安全操作系统领域的优势，以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境，为国家关键领域的建设保驾护航！',
					]
				},
				{	id: '81',
					tittle: '凝思软件获批加入证券基金行业信息技术应用创新联盟',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-09-21',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）顺利获批成为证券基金行业信息技术应用创新联盟成员单位。凝思软件作为国内先进的安全操作系统厂商，以自有技术、产品和服务不断赋能金融行业信创建设。',
						{
							src:require('../../images/news/news20230921-01.jpg'),
							alt:'凝思软件获批加入证券基金行业信息技术应用创新联盟',
						},
						'证券基金行业信息技术应用创新联盟是由中国证券监督管理委员会科技监管局、上海市经济和信息化委员会、上海证券交易所理事会科技发展委员会指导，上交所联合行业券商倡议发起成立的非营利性社会组织，旨在促进证券基金行业信创关键技术研究、应用和服务。',
						'随着《证券期货业科技发展“十四五”规划》、《网络和信息安全三年提升计划（2023-2025）》等政策的陆续发布，加快及强化金融信创的落地实施已成为证券基金行业的要务之一。经过多年实践，凝思软件相关产品完全满足金融行业各类应用场景的使用要求。完全自研的“固域”内核安全子系统，符合信息安全等级保护第四级要求，满足金融核心业务系统国产化改造需求。同时，凝思安全操作系统、凝思虚拟化管理平台、凝思安全云桌面系统已顺利通过金融信创生态实验室的适配验证。目前，凝思安全操作系统已在多家股份制银行、区域性商行、证券、基金等金融机构进行测试和应用。',
						'此次获批加入证券基金行业信息技术应用创新联盟，既是业界对凝思软件在金融信创领域自主创新能力和成果的充分肯定，也表明了凝思软件持续助力金融行业数字化升级与信创建设的坚定决心。未来，凝思软件将继续发挥自身优势，为金融客户提供稳定、可靠、安全的基础软件服务支撑，推动金融行业关键核心技术创新应用发展，携手构建金融开放自主生态新体系。',
					]
				},
				{	id: '80',
					tittle: '凝思软件受邀参加第八届中国（成都）智慧产业国际博览会',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-09-01',
					content: [
						'近日，由四川省贸促会和四川省科学技术协会主办，中国国际商会与四川省互联网信息办公室、四川省密码管理局等18个省市相关部门支持的第八届中国（成都）智慧产业国际博览会在成都举行。北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内先进的安全操作系统厂商受邀亮相本届智博会。',
						{
							src:require('../../images/news/news20230901-01.jpg'),
							alt:'凝思软件受邀参加第八届中国（成都）智慧产业国际博览会',
						},
						'本届成都智博会以“发展数字经济 建设智慧社会”为主题，以产业发展和市场需求为导向，围绕金融、政务服务、卫生健康、交通、教育、能源、国防等重点应用领域，融合推动智慧产业、信创产业创新发展，共有140多家信创产业头部企业、知名电子信息企业集中参展。同期还举办了“2023年中国信创产业发展大会”“科技实景运用新产品与技术推介会”等活动，助力中国信息技术产业链的快速发展。',
						'凝思软件参展的“安全操作系统&生态”、“替代CentOS解决方案”和“密码应用解决方案”成为了“信息技术创新应用”主题展区的亮点之一，受到行业人士的广泛关注和认可。凝思软件自成立以来坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境，目前已成为国内大型基础行业国产操作系统的中坚力量。',
						{
							src:require('../../images/news/news20230901-02.png'),
							alt:'凝思软件受邀参加第八届中国（成都）智慧产业国际博览会',
						},
						{
							src:require('../../images/news/news20230901-03.jpg'),
							alt:'凝思软件受邀参加第八届中国（成都）智慧产业国际博览会',
						},
						{
							src:require('../../images/news/news20230901-04.jpg'),
							alt:'凝思软件受邀参加第八届中国（成都）智慧产业国际博览会',
						},
						'凝思安全操作系统由凝思软件完全自主设计构建，内置凝思完全自主开发的“固域”内核安全子系统，具有多层次结构化的安全体系，国内首批符合信息系统安全等级保护4级（结构化保护级）标准，具有高可靠性、高安全性、高性能和良好兼容性等特性，是用户建设高等保安全级别业务系统的首选。',
						'凝思“替代CentOS解决方案”是凝思软件为行业客户提供的从评估规划、迁移实施到验证上线、服务跟踪等一系列完善、成熟的解决方案，可满足存量替换、新增扩容、安全接管等多样场景，凝思安全迁移方案已经在能源、轨交、金融等关键领域获得客户的高度认可。',
						'凝思“密码应用解决方案”严格遵循《GB/T 22239-2019 网络安全保护基本要求》、《GB/T 39786-2021 信息系统密码应用基本要求》等国家标准，面向物理和环境安全、网络和通信安全、设备和计算安全、应用和数据安全、密钥管理安全、安全管理制度等重要环节，结合密码安全对操作系统的要求进行统筹规划，充分发挥凝思软件的技术和经验优势，以多层次、系统性、结构化的设计，结合自主开发的商用密码产品，帮助用户顺利完成密改，实现密码应用的快速落地。',
						'得益于中国数字经济的迅猛发展,中国信创市场释放出前所未有的活力。凝思软件将围绕“自主、创新、生态”三个关键点，不断发挥多年的内核研发能力、安全保障能力、技术服务能力以及深度生态适配等优势，助力四川信创产业高质量发展，为推动国产数字化转型升级贡献凝思力量。',
					]
				},
				{	id: '79',
					tittle: '金鼎奖揭晓，凝思软件安全可靠操作系统替代项目荣获“优秀网信产品基础软硬件奖”',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-08-25',
					content: [
						'近日，中国国际金融展组委会对外公布2023年金融展“金鼎奖”获奖名单。北京凝思软件股份有限公司（以下简称“凝思软件”）申报的“结算对接平台安全可靠操作系统替代”项目荣获“优秀网信产品基础软硬件奖”。',
						{
							src:require('../../images/news/news20230828-01.png'),
							alt:'金鼎奖揭晓，凝思软件安全可靠操作系统替代项目荣获“优秀网信产品基础软硬件奖”',
						},
						'中国国际金融展（以下简称“金融展”）是经国家商务部批准，由中国金融电子化集团有限公司主办、国内主要金融机构协办的国际性金融展览。金融展历经30载发展，现已成为亚太地区具有较大影响力的年度金融展会。“金鼎奖”作为金融展的重要活动之一，每年的评选都会收到国内众多厂商的关注和申报。今年的奖项评选活动以《金融科技发展规划（2022—2025年）》为指引，以助力实现金融业数字化转型更深化为目标，针对金融机构和科技企业设置金融科技和金融服务两大奖项类别，评奖范围涵盖产品、服务、解决方案、基础软硬件等。获奖的银行机构和科技公司均是业内知名品牌，含金量不言而喻。',
						'自成立以来，凝思软件始终致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品，坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境，在操作系统层面保障生产及关键业务系统的安全稳定运行,现已成为国内大型基础行业国产操作系统的中坚力量。',
						'自红帽宣布将对CentOS停止维护后，为了保障用户业务的持续性、安全性，消除停服带来的风险，凝思软件联合关键行业的合作伙伴积极推进CentOS迁移工作。凝思软件为行业客户提供从评估规划、迁移实施到验证上线、服务跟踪等一系列完善、成熟的解决方案，可满足存量替换、新增扩容、安全接管等多样场景，凝思安全迁移方案已经在能源、轨交、金融等关键领域获得客户的高度认可。',
						'此次获奖的“结算对接平台安全可靠操作系统替代”项目面向银行与商场、医院、园区等广泛的结算对接场景，凭借凝思软件的技术能力和多年工程经验，将结算业务系统从国外操作系统整体迁移到凝思安全操作系统上，进行整体性能优化和更有力的运维支持，构建优秀的基础软硬件平台，解决纷乱复杂的数据对接问题，满足了银行多种场景的结算需求。同时通过多层次结构化的凝思安全体系，解决服务器的主机安全问题，系统运行更加稳定、可靠、安全、高效。项目上线稳定运行一年多以来，新平台安全能力和业务处理性能大幅提升，帮助用户实现全栈信创改造，降本增效，可以广泛应用于银行多种业务场景。',
						'未来，凝思软件将继续发挥自身技术能力及创新优势，为更多金融机构数字化转型提供有力的支撑与帮助。 ',
					]
				},
				{	id: '78',
					tittle: '再中标！凝思软件国产操作系统技术实力获中国移动认可',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-07-26',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）以综合评分第二名的优异成绩，成功中标中国移动“云能力中心2023-2024年省专公司操作系统迁移技术服务项目”的包2标段，成为中国移动国产操作系统迁移项目的服务提供商。凝思软件丰富的操作系统研发、工程实施和售后服务的经验赢得中国移动的肯定和信任。',
						{
							src:require('../../images/news/news20230726-01.png'),
							alt:'再中标！凝思软件国产操作系统技术实力获中国移动认可',
						},
						'凝思软件是国内先进的安全操作系统服务商，自成立以来，始终坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境。公司自主研发的“固域”安全子系统国产化率高达97.37%，通过多个内核安全机制及核外配套工具，实现了全系统安全增强，成为全球规模化部署的三大 Linux 配套安全模块之一，其中两项安全机制已达国际领先水平。',
						'凝思软件拥有一支行业专家带头、研发能力强、迁移经验丰富的高素质研发团队和技术服务团队。并具备遍布全国的驻场服务及全天候的快速响应能力，为各行业用户提供坚实的技术保障。',
						'电信运营商作为国家信息基础设施建设的主力军，对于安全性的要求不言而喻。此次操作系统迁移技术服务项目是中国移动重要的国产化信创业务项目，也是凝思软件积极助推运营商数字化应用技术创新的重要里程碑。凝思软件将携手中国移动，大力推进全场景业务系统底层操作系统的迁移改造工作，为我国通信安全的发展筑牢坚实底座。',
					]
				},
				{	id: '77',
					tittle: '凝思软件入围中国移动云能力中心2023年第一批DICT合作伙伴集成库',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-07-18',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）成功入围中国移动云能力中心2023年第一批DICT合作伙伴集成库，成为中国移动云能力中心第一批国产化操作系统供应商。',
						{
							src:require('../../images/news/news20230718-01.jpg'),
							alt:'凝思软件入围中国移动云能力中心2023年第一批DICT合作伙伴集成库',
						},
						'云能力中心是中国移动云设施构建者、云服务提供者、云生态汇聚者，可为客户提供云网一体、安全可控的专业云服务。DICT是通信技术（CT）、信息技术（IT）、云和大数据技术(DT)的融合型智能应用服务。此次合作伙伴的招募，希冀可通过合作伙伴间的相互赋能，创造更多的融合型智能应用，携手共赢商业市场。',
						'凝思软件是国内先进的国产安全操作系统厂商，自成立以来，始终坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境。此次作为行业内首批入围的操作系统厂商，将为中国移动云能力中心的项目提供全国产化、功能更加丰富、安全性更高的国产操作系统支持。除产品支持外，凝思软件还将为中国移动云能力中心提供全面的售前支撑服务、项目实施服务、售后运维服务和产品的定制化开发服务等。通过合作，中国移动也丰富了国产化操作系统的产品清单，可以为不同需求的客户提供多样的国产操作系统选择。',
						'本次成功入围中国移动云能力中心DICT合作伙伴集成库，是中国移动对凝思软件技术和服务的信任和肯定。未来，凝思软件将携手中国移动，将数字技术和智能应用全面融合，为千行百业的数字化转型提供强劲动力。',
					]
				},
				{	id: '76',
					tittle: '凝思软件顺利通过CMMI V2.0成熟度五级认证评估',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-07-05',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）顺利通过CMMI V2.0成熟度五级认证评估，这标志着凝思软件在软件研发能力、项目管理能力、方案交付能力等方面提升到了一个新的高度，是公司研发规范化、体系化的重要里程碑。',
						{
							src:require('../../images/news/news20230705-01.jpg'),
							alt:'凝思软件顺利通过CMMI V2.0成熟度五级认证评估',
						},
						'CMMI（Capability Maturity Model Integration），即“软件能力成熟度模型集成”，是国际公认的衡量软件企业研发能力成熟度和项目管理水平的权威标准，代表着国际上最先进的软件工程管理方法。作为全球软件工程领域规格最高、难度最大的认证之一，CMMI认证已成为衡量企业软件工程开发能力的重要标志。其中，CMMI 5级是CMMI体系的最高等级，是企业软件研发标准化、规范化、成熟度等优异性能的最高认证。据CMMI官网数据统计，截至2023年7月4日, 全球12241家通过CMMI认证且在有效期内的企业中,达到5级认证的不到17%。',
						'凝思软件自2020年通过CMMI V1.3成熟度五级认证评估以来，不断优化对开发流程标准化和规范化的有效管理，确保项目进度及质量。同时，不断推动成本控制、建立完备的知识体系、持续改善流程以提高质量和效率。相比之前的版本， CMMI V2.0更加注重实践，更加聚焦于解决组织实际的问题。此次凝思软件顺利通过CMMI V2.0 5级认证，证明公司在软件研发标准化、规范化、成熟度等方面保持着国际先进水平。',
						'作为国内先进的安全操作系统服务商，凝思软件一直高度重视研发管理的体系化建设，持续不断地对研发管理体系进行改进和完善。未来，凝思软件将持续优化公司软件研发及管理能力,不断深化自主创新，提升产品开发质量，打造更具创新能力、更值得信赖的国产操作系统软件及相关产品，为行业和客户创造更高的价值。',
					]
				},
				{	id: '75',
					tittle: '凝思软件捐赠“secShield盾御”项目，助力openEuler社区蓬勃发展',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-06-16',
					content: [
						'6月11-13日， 2023开放原子全球开源峰会在北京开幕。本届峰会以“开源赋能，普惠未来”为主题，以立足中国、面向世界的姿态，推动全球开源事业的交流合作。作为具有全球影响力的开源盛会，本届峰会聚集了国内外顶级开源基金会/开源社区负责人、开源意见领袖、开源厂商代表、优秀开源企业用户、顶尖开源开发者，为全球开源爱好者们带来一场产业技术盛宴。',
						'北京凝思软件股份有限公司（下称：凝思软件）作为开源活动重要推动者与共建方受邀参加此次峰会，并向openEuler 项目群捐赠了“secShield 盾御”凝思主导的操作系统安全子系统。盾御是一个轻量级，灵活的安全子系统，为云原生环境提供高级别的威胁防护。除具备强制访问控制功能外，盾御可自动识别安全威胁并动态调整安全策略，提供更加细粒度的安全隔离与访问控制。',
						{
							src:require('../../images/news/news20230616-01.jpg'),
							alt:'凝思软件捐赠“secShield盾御”项目，助力openEuler社区蓬勃发展',
						},
						'在数字化时代，开源已经成为技术升级和产业发展的主流模式，是推动科技创新的核心动力之一。未来，凝思软件将继续携手 openEuler 社区，深化联合创新，贡献更多开源项目，共享开源价值，积极推动中国开源软件生态的繁荣发展，同时向内整合能力协调资源，向外联合生态伙伴，推动开源事业发展，促进数字经济发展。',
					]
				},
				{	id: '74',
					tittle: '凝思软件受邀亮相第十三届中国城市商业银行信息化发展创新座谈会',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-06-14',
					content: [
						'6月7日—9日，由中国金融电子化集团有限公司与南京市建邺区人民政府、中国人民银行南京分行联合主办的“2023中国金融业数字化转型发展大会暨第十三届中国城市商业银行信息化发展创新座谈会”在南京国际博览中心举行。大会以“数驱转型·智创未来”为主题，围绕金融科技发展热点领域及城市商业银行信息技术重点工作，集中展示了我国金融业数字化的新进展和新成果。作为国内先进的安全操作系统服务商，北京凝思软件股份有限公司（以下简称“凝思软件”）携凝思CentOS精准替代解决方案亮相此次大会。',
						'金融安全是国家安全的重要组成部分，安全是发展的前提，推进金融数字化进程，必须坚持“安全、自主、可控”的发展路线，而为各项金融服务提供安全、稳定、可靠支撑环境的操作系统更是发展的重中之重。凝思软件秉承“基于开源，但不依赖于开源，更不受制于任何商业发行版”的理念，面向不同的应用场景，采用自主可控的技术路线，不受国外操作系统厂商“断供”、“闭源”等影响。目前，除大规模部署的凝思安全操作系统外，凝思软件积极响应国家有关国产化工作的整体部署，基于国内开源社区推出了凝思欧拉版/龙蜥版操作系统，助力降低安全风险。',
						'展会中，凝思CentOS精准替代解决方案展位现场吸引众多城商行客户围观交流、体验。为了保障用户业务的持续性、安全性，消除停服带来的风险，凝思软件很早就联合关键行业的合作伙伴积极推进CentOS替代工作，为行业客户提供从评估规划、迁移实施到验证上线、服务跟踪等一系列完善、成熟的解决方案，可满足存量替换、新增扩容、安全接管等多样场景，凝思CentOS精准替代方案已经在能源、轨交、金融等关键领域获得客户的高度认可。',
						{
							src:require('../../images/news/news20230614-01.png'),
							alt:'凝思软件受邀亮相第十三届中国城市商业银行信息化发展创新座谈会',
						},
						'此次展会现场北京金融科技产业联盟（以下简称“联盟”）秘书长聂丽琴为联盟各专委会优秀项目牵头单位颁发了荣誉证书，凝思软件参编的《金融数据中心网络数字化能力建设研究报告》荣获2023年信息基础设施专委会优秀课题，凝思的技术能力得到了金融行业的高度认可。',
						{
							src:require('../../images/news/news20230614-02.jpg'),
							alt:'凝思软件受邀亮相第十三届中国城市商业银行信息化发展创新座谈会',
						},
						'凝思软件自成立以来始终坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境。金融行业是公司产品重点应用的行业之一，凝思软件将不断发挥多年的内核研发能力、安全保障能力、技术服务能力以及深度生态适配等优势，积极为金融客户提供稳定、可靠、安全的软件服务支撑，推动金融行业关键核心技术创新应用发展，携手客户构建金融开放自主生态新体系。',
					]
				},
				{	id: '73',
					tittle: '国网英大集团领导一行莅临凝思软件现场调研',
					tag: '要闻',
					form: '董事会办公室',
					examine: '市场部',
					time: '2023-06-13',
					content: [
						'6月6日，国家电网公司副总经济师兼英大集团、英大股份董事长、党委书记杨东伟，英大集团党委委员、英大产业基金董事长、党支部书记吕胜一行莅临凝思软件现场调研。公司董事长宫敏及部分高管陪同调研，并与英大集团领导进行座谈交流。',
						'董事长宫敏对杨董事长一行来访表示欢迎，对英大集团的关心和支持表示感谢。英大产业基金投资凝思两年多来，对凝思的发展规划、资本运作、公司治理等方面提供了很多有益建议，赋能凝思高质量发展。未来，凝思将继续加强研发，推进操作系统的自主安全可控，为新型电力系统的构建贡献力量。杨董事长表示，通过本次调研进一步了解了凝思的发展情况及在电网产业链安全稳定运行方面所做的工作，高度认可公司技术报国、产业报国的情怀，希望凝思软件和英大集团发挥各自的特长,在合作中实现共赢。双方共同展望信息技术发展良好前景，期待在金融数字化转型、信息安全等领域进行深入合作。',
						'会后，杨董事长一行实地参观了公司研发办公区，公司领导陪同参观。',
						'英大集团办公室、研究室、数字金融部、英大产业基金相关负责人参加调研。',
					]
				},
				{	id: '72',
					tittle: '凝思软件受邀参加2023第二届数字安全发展交流大会',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-05-19',
					content: [
						'5月17日，由四川省通信学会及四川省社会公共安全设备及器材制造行业协会共同主办的2023第二届数字安全发展交流大会在成都召开，北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内先进的安全操作系统厂商受邀参加本次交流大会。',
						{
							src:require('../../images/news/news20230519-01.jpg'),
							alt:'凝思软件受邀参加2023第二届数字安全发展交流大会',
						},
						'本次大会以“筑牢网络安全防护屏障，护航数字经济发展建设”为主题，邀请数字安全行业的专家学者和产业精英对城市数字安全、数据安全、物联网安全、5G应用安全等内容展开广泛而深入的讨论，旨在助力千行百业加快数字化转型进程，实现网络安全与数字经济协调发展。',
						{
							src:require('../../images/news/news20230519-02.jpg'),
							alt:'凝思软件受邀参加2023第二届数字安全发展交流大会',
						},
						'随着新一轮科技革命和产业变革的深入发展，数字安全已成为重塑行业竞争新格局的战略支点，底层自主构建是数字化转型中最为关键的一环。凝思软件自成立以来一直致力于操作系统底层开发能力和安全机制的深入研究，为国内关键用户提供高安全等级的凝思安全操作系统及其相关产品和服务，在操作系统层面保障生产业务系统和关键业务系统的安全稳定运行。经过多年持续努力，凝思安全操作系统及其相关产品的用户遍及电力、政府、金融、交通、通信等重点行业。活动现场，凝思软件的自主安全体系、安全类产品与密评解决方案凭借着对网络安全基础建设与业务应用的深入理解，吸引到众多行业人士前来参观洽谈，备受关注与认可。',
						'为了更好地响应国家网络安全政策，凝思软件将充分利用公司现有的技术、研发及管理经验优势，为行业用户提供高质量的安全可信产品与服务，并将携手生态合作伙伴，共同推进数字安全产业生态体系建设，为数字安全经济可持续发展保驾护航。'
					]
				},
				{	id: '71',
					tittle: '凝思软件解决方案入围工信部“2022年信息技术应用创新典型解决方案”',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-05-09',
					content: [
						'近日，由工业和信息化部网络安全产业发展中心（工业和信息化部信息中心）牵头的第六届数字中国建设峰会——数字技术融合创新应用研讨会在福州盛大开幕。研讨会上重磅发布了2022数字技术融合创新应用解决方案征集工作成果，北京凝思软件股份有限公司（以下简称“凝思软件”）的“工业互联网安全操作系统的创新与规模化应用”入围“工信部2022年信息技术应用创新典型解决方案”。',
						'据悉，该项评审历经方案征集、资格初审、区域评议、答辩终评、综合复议等诸多环节，凝思软件解决方案最终在千余个有效方案中脱颖而出，成功入围。',
						{
							src:require('../../images/news/news20230509-01.png'),
							alt:'奖牌-典型解决方案',
						},
						'伴随着新一代信息技术的发展，工业互联网作为新一代信息通信技术与工业系统深度融合所形成的关键基础设施，已经成为工业创新转型的关键依托。工业互联网操作系统是工业互联网软件平台体系的核心，其向下适配工业控制硬件系统，发挥工业终端产品和制造装备的基础效能，向上支撑制造、检测、运营、监控等生产管理应用软件，决定工业生产制造过程的最终效果。多年来工业互联网操作系统一直是国际企业竞争的焦点，也是各国政府发展工业互联网的重点。',
						'凝思软件根据工业控制领域的应用需求，基于工控领域主流CPU和安全可控CPU，研制了凝思工业互联网安全操作系统，突破了安全性、实时性、可靠性与连续性等关键技术，采用微宏协同内核架构，支持灵活拆分，适用于实时、非实时以及混合的各种应用，支持“云网边端”架构的丰富场景，为国家重要行业工控系统提供了一套完整的信息技术应用创新解决方案，并实现了电力、石油、交通、电信等多个行业的产业化和规模化应用，对于工业控制领域建设全国产化生态环境，提升国家关键基础行业安全具有重大战略意义和示范效应。',
						'此次成功入围“2022年度信息技术应用创新典型解决方案”是对凝思软件产品技术能力与行业应用价值的双重认可。未来，凝思软件还将继续凭借对工控领域的深刻理解，依托深厚的自主研发能力和生态整合能力，不断加强数字技术与业务场景的融合创新，为千行百业信息技术应用创新发展增效赋能。'
					]
				},
				{	id: '70',
					tittle: '凝思软件受邀参加2023中国国际金融展，科技创新能力获金融机构高度认可',
					tag: '要闻',
					form: '市场部',
					examine: '市场部',
					time: '2023-04-28',
					content: [
						'2023年4月25－27日，由中国金融电子化集团有限公司与北京市石景山区政府共同主办的2023中国国际金融展在北京首钢会展中心成功举办。北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内安全操作系统优秀代表厂商受邀参展并在金融信创操作系统及中间件闭门分会场发表主题演讲。',
						'中国国际金融展历时30年，是我国规格最高、历史最久的金融科技展。本届金融展以“荟萃金融科技成果，展现数字金融力量，谱写金融服务中国式现代化新篇章”为主题，全面展示金融业为完善科技创新体系、加快实现高水平科技自立自强，持续提升金融服务水平、助力经济高质量发展的丰硕成果。中国人民银行行长易纲、北京市委常委、副市长靳伟出席开幕式，百余家国内金融机构和金融科技头部企业受邀出席，为与会观众带来最前沿的金融科技新体验。',
						{
							src:require('../../images/news/news20230428-01.jpg'),
							alt:'凝思软件受邀参加2023中国国际金融展，科技创新能力获金融机构高度认可',
						},
						'“自主、安全”是凝思软件展台的宣传标签，凝思操作系统固域安全内核是凝思软件技术能力的主要体现，也是凝思软件的核心竞争力之一。经中国电子学会评定，固域是全球规模化部署的三大Linux 安全模块之一，其中“基于标记的进程强制行为控制方法”和“基于强制能力控制的无 root 运行模式”达到国际领先水平。同时，经中国软件测评中心代码扫描，固域代码自主率达到97.37%。凝思操作系统、凝思操作系统欧拉版\龙蜥版均内置固域安全内核，面向高安全等级业务系统应用。',
						{
							src:require('../../images/news/news20230428-02.jpg'),
							alt:'凝思软件受邀参加2023中国国际金融展，科技创新能力获金融机构高度认可',
						},
						{
							src:require('../../images/news/news20230428-03.jpg'),
							alt:'凝思软件受邀参加2023中国国际金融展，科技创新能力获金融机构高度认可',
						},
						{
							src:require('../../images/news/news20230428-04.jpg'),
							alt:'凝思软件受邀参加2023中国国际金融展，科技创新能力获金融机构高度认可',
						},
						'2020年，红帽宣布将对CentOS停止维护，为了保障用户业务的持续性、安全性，消除停服带来的风险，凝思软件联合关键行业的合作伙伴积极推进CentOS迁移工作。凝思软件为行业客户提供从评估规划、迁移实施到验证上线、服务跟踪等一系列完善、成熟的解决方案，可满足存量替换、新增扩容、安全接管等多样场景，凝思迁移方案已经在能源、轨交、金融等关键领域获得客户的高度认可。',
						'“凝思软件多年的内核研发能力、安全保障能力、技术服务能力以及项目型生态适配等优势，是凝思软件开拓金融行业的底气和信心，我们有能力做好金融行业数字化转型路上的战略伙伴。”凝思软件副总经理彭志航先生的演讲获得与会者的高度认可。未来，凝思软件将继续发挥自身优势，为金融客户提供稳定、可靠、安全的软件服务支撑，推动金融行业关键核心技术创新应用发展，携手构建金融开放自主生态新体系。'
					]
				},
				{	id: '69',
					tittle: '2023 IT市场权威榜单发布凝思软件荣获“新一代信息技术创新企业”称号',
					tag: '要闻',
					form: '市场部',
					time: '2023-04-23',
					content: [
						'4月20日，在赛迪顾问举办的2023 IT市场年会上，年度IT市场权威榜单正式揭晓。北京凝思软件股份有限公司（下称：凝思软件）作为国内先进的国产安全操作系统厂商，凭借丰富的创新产品、出色的技术实力，被评为“新一代信息技术创新企业”。',
						'作为中国IT业界延续时间最长的年度顶级盛会，自2000年以来，IT市场年会秉持“精准把脉科学谋划”的理念，致力于聚集业界精英力量，剖析行业最新热点，探索IT核心价值，共同推动IT产业创新发展。',
						'凝思安全操作系统是保障国民经济重点行业新基建以及信息安全的利器。国民经济重点行业是国家的经济命脉和拉动经济发展的重要引擎。行业的信息基础设施逐步从大型主机架构向互联网架构，分布式集群化，原生云应用转型，以至于后续的虚拟化、容器化逐步成为云计算、人工智能、移动互联网时代基础设施的基本技术特征，操作系统已经成为基础设施与通用CPU并列的最重要的制约环节。',
						{
							src:require('../../images/news/news20230423-03.jpg'),
							alt:'2023 IT市场权威榜单发布凝思软件荣获“新一代信息技术创新企业”称号',
						},
						'本次大会上，凝思软件获评“新一代信息技术创新企业”，再次体现了国产基础软件领域的技术实力和行业认可度。伴随着产业升级和各行业数字化建设的不断推进，凝思软件将持续释放技术能量和生态优势，发挥操作系统核心支撑作用，助力构建安全可信的数字世界。'
					]
				},
				{	id: '68',
					tittle: 'openEuler Developer Day 2023召开凝思软件助力openEuler全场景走向深入',
					tag: '要闻',
					form: '市场部',
					time: '2023-04-23',
					content: [
						'【中国，上海，2023年4月21日】openEuler Developer Day 2023于4月20-21日在线上和线下同步举办。本次大会由开放原子开源基金会指导，中国软件行业协会、openEuler社区、边缘计算产业联盟共同主办，北京凝思软件股份有限公司（下称：凝思软件）作为协办单位积极参与了本次大会。',
						'<b style="color: #0072ce">「openEuler进入开源治理新的阶段」</b>',
						'openEuler开源三年，发展超出预期，已进入正循环、自加速的生态发展体系。截至目前，openEuler社区生态伙伴已经超过800多家，贡献者超过13000名，全球下载量过百万，每日活跃开发者近4000人，每日新增讨论超过2000次、每月新增创新项目10个，已经与国际主流开源社区相当。openEuler在商业装机量上实现指数级增长，累计部署量超300万套，2022年新增市场份额占比超过25%。openEuler正在成为数字转型的首选技术路线，成为千行百业坚实的软件根基。中国工程院院士、openEuler社区顾问专家委员会委员倪光南院士在本次大会致辞中表示，“在世界开源工作者的共同努力下，openEuler已经发展成为一个具有国际影响力的开源社区。社区汇聚了一大批有活力、有热情、有智慧的开发者， openEuler成为中国开源的一个典范。”',
						'“继2022年12月openEuler成立项目群后，openEuler进入开源治理新的阶段。”开放原子开源基金会理事长孙文龙表示：“下一步，开放原子开源基金会将加快国际化发展，助力openEuler开源项目走向全球。” ',
						{
							src:require('../../images/news/news20230423-01.jpg'),
							alt:'openEuler Developer Day 2023召开凝思软件助力openEuler全场景走向深入',
						},
						'<b style="color: #0072ce">「凝思软件担任迁移运维分论坛的出品人」</b>',
						'在此次大会中，凝思软件担任迁移运维分论坛的出品人，凝思软件北京研发中心总经理曾宏安分享了《凝思操作系统与openEuler在容器运维方面的探索》的主题演讲。近几年行业用户业务规模的增长，容器集群规模不断扩张，IT运维压力也成比例增大。各种软、硬件故障而造成的业务中断，成为稳定性影响的重要因素之一。针对目前云原生领域存在的问题，凝思软件在openEuler社区发起了CPDS（容器故障检测系统）开源项目，该项目开发的系统实现了对容器集群的故障检测、诊断与策略执行，解决了行业内的一个痛点问题。',
						{
							src:require('../../images/news/news20230423-02.jpg'),
							alt:'openEuler Developer Day 2023召开凝思软件助力openEuler全场景走向深入',
						},
						'每一位开发者的创新都是社区创新及发展的源动力，每一位开发者的贡献是社区倍速增长的发动机。万涓汇流，奔涌向前，凝思软件将继续与社区用户、上下游伙伴等携手并进，构筑坚实的软件根基，共创数智新未来。'
					]
				},
				{	id: '67',
					tittle: '凝思软件中标中国农业银行四川省分行软件开发项目',
					tag: '要闻',
					form: '市场部',
					time: '2023-04-19',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）凭借卓越的软件研发能力、丰富的项目管理经验，在多家竞标厂商中脱颖而出，成功中标中国农业银行四川省分行软件开发项目。',
						'凝思软件作为国内先进的安全操作系统厂商，一直致力于提供“高安全性、高性能、高稳定性”的操作系统软件及相关产品和服务。操作系统作为基础软件的核心，是软件研发必备的底层关键技术。完全自主设计、自主构建操作系统是凝思软件技术能力的核心竞争力所在。此次金融项目中标就充分体现了凝思软件在软件研发上的领先优势，从信任到信赖，凝思软件持续为金融客户提供从安全底层到应用层安全的整体解决方案。',
						'金融数字化转型是金融机构改革创新提升竞争力的重要举措，凝思软件将依托自身多年的专业技术积累和金融行业服务经验，助力金融行业高质量发展，夯实金融行业安全可靠新基座。',
					]
				},
				{	id: '66',
					tittle: '<span>凝思软件科技成果荣获</span><span>2022年度中国电子学会科学技术奖</span>',
					tag: '要闻',
					form: '市场部',
					time: '2023-03-01',
					content: [
						'近日，中国电子学会发布2022年中国电子学会科学技术奖的获奖名单。北京凝思软件股份有限公司（下称：凝思软件）申报的“凝思工业控制安全操作系统研制及产业化应用”项目荣获2022中国电子学会科学技术奖科技进步二等奖。',
						{
							src:require('../../images/news/news20230301-01.jpg'),
							alt:'凝思软件科技成果荣获2022年度中国电子学会科学技术奖',
						},
						'当前，全球新一轮科技革命和产业变革正在孕育兴起，信息技术发展日新月异并加速与各领域技术深度融合，引发了经济社会发展的深刻变革。伴随着新一代信息技术的突破和扩散，基于工业互联网的工业控制系统作为新一代信息通信技术与工业系统深度融合所形成的关键基础设施，已经成为工业创新转型的关键依托，对传统工业的发展理念、发展方式、发展模式产生了颠覆性、革命性的影响，并将重塑全球制造业格局。',
						'凝思软件经过多年的操作系统研发和行业实践，认为在工业控制领域内，   一个能够满足工控系统对可靠性、实时性、安全性、连续性特殊要求的操作系统将是工业控制软件平台体系的核心，其向下适配工业控制硬件系统，发挥工业终端产品和制造装备的基础效能，向上支撑制造、检测、运营、监控等生产管理应用软件，决定工业生产制造过程的最终效果。同时，以工业控制安全操作系统为核心，上层集成必要的中间件、应用软件平台，并向第三方开放API接口，再辅以相关的IDE工具，就可以形成一个统一的工业生产应用开发与服务提供环境，基于该环境平台拥有者可自行积聚产业力量构建一个业务发展创新的完整生态体系，并以此为基础成为产业发展的领导者并决定未来的发展走向。正因如此，多年来，工业控制操作系统一直是凝思软件研究和布局的重点方向。',
						'未来，凝思软件将继续发挥自身能力优势，与各界生态伙伴紧密携手，合力探索工业控制操作系统的新技术、新模式、新方法，持续推动科技创新，为我国产业的高质量发展贡献更多力量。',
						'关于“中国电子学会科学技术奖”:',
						'中国电子学会是工业和信息化部直属单位、中国科协的重要组成部分，多年来为推动我国电子信息科学技术和产业发展发挥了重要作用。“中国电子学会科学技术奖”是经国家科学技术奖励工作办公室批准设立的奖项，旨在奖励电子信息领域科学研究、技术开发与创新、科技成果推广应用和实现产业化方面做出突出贡献的个人和集体。',
					],
					adjunct: [
						'2022中国电子学会科学技术奖公告',
						'<a style="color:#0072ce;text-decoration:none;" href="https://www.cie.org.cn/list_43/11174.html">https://www.cie.org.cn/list_43/11174.html</a>',
					],
				},
				{	id: '65',
					tittle: '四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
					tag: '要闻',
					form: '市场部',
					time: '2023-01-10',
					content: [
						'近日，由四川省地方金融监督管理局、成都市地方金融监督管理局指导，四川天府新区管委会和四川省金融科技学会共同主办的“壹至无限 守正共赢”——第五届金融科技发展峰会在成都天府国际会议中心成功举行。四川凝思软件有限公司（以下简称“四川凝思”）受邀参加并获得2022年度优秀科技服务商称号。',
						{
							src:require('../../images/news/news20230110-01.jpg'),
							alt:'四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
						},
						{
							src:require('../../images/news/news20230110-02.jpg'),
							alt:'四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
						},
						{
							src:require('../../images/news/news20230110-03.jpg'),
							alt:'四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
						},
						'本次峰会以平台汇聚力量，以对话推动合作，邀请国内外金融科技行业顶尖专家和学者，分享对金融科技的前瞻性思考及实践，共同打造守正创新、聚力发展的行业新生态。在圆桌论坛环节，四川凝思总经理文林宁先生代表公司参加并发表观点，就新时代下金融科技发展的创新模式、技术难点、人才培养以及助力乡村振兴等问题与其他与会领导展开深入讨论，勾勒科技赋能金融行业发展新蓝图。',
						{
							src:require('../../images/news/news20230110-04.jpg'),
							alt:'四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
						},
						{
							src:require('../../images/news/news20230110-05.jpg'),
							alt:'四川凝思受邀参加第五届金融科技发展峰会并获得2022年度优秀科技服务商称号',
						},
						'四川凝思母公司北京凝思软件股份有限公司（以下简称“凝思软件”）由“中国Linux先行者”宫敏博士创办，具有多年的技术积累。同时，在操作系统国产化替代中，除了安全这个硬性指标，客户更关注稳定性，凝思软件具有在大型基础设施行业中丰富的全国性规模工程部署和运维保障经验，这也是有别其他厂商的优势所在。凝思安全操作系统在电力行业关键业务系统已连续平稳运行多年。同时，以凝思安全操作系统为纽带，凝思软件不断优化、创新生态合作，全力打造关键基础设施行业全国产化生态圈，推动各行业数字化高质量发展。',
						'此外，在金融行业的数智化发展过程中，底层技术的“国产化”和“安全性”尤为重要。金融行业以安全为纲，实现自主可控能力升级，需通过一套国产操作系统解决多芯片架构、多种应用，同时有效的解决安全、性能、稳定性等方面的需求，凝思软件有能力也有经验为金融行业用户解决上述问题。',
						'凝思软件愿与金融客户携手，与生态伙伴一起共同实现金融科技自主可控，为推动数字金融可持续发展不断注入新动能。',
					],
				},
			]
		},
		{
			year: '2022',
			text: [
				{	id: '64',
					tittle: '四川凝思保障高校缴费系统安全高效运行',
					tag: '要闻',
					form: '市场部',
					time: '2022-12-15',
					content: [
						'近期，四川凝思软件有限公司（以下简称“四川凝思”）收到来自西南石油大学（以下简称“西南石大”）的一份感谢信，信中对凝思安全操作系统对校园缴费销账系统的安全支持给与肯定，并对技术人员专业的服务表示感谢！',
						'据悉，2021年底，西南石大对校园服务器主机例行安全漏洞扫描时发现校园缴费销账系统服务主机存在严重高危漏洞，校方对上述安全隐患高度重视，立即要求基础软件服务商提供解决方案。四川凝思经过两个月的时间，成功实现校园缴费销账系统向凝思安全操作系统的移植、适配和测试。目前，已经成功上线运行半年，期间多次经过省测评中心及学校网信中心的安全检测，均无问题。',
						'当前，信创安全大势所趋，教育新型基础设施建设是国家新基建的重要组成部分，是实现教育信创的必经之路。作为国内先进的国产安全操作系统厂商，凝思软件致力于在操作系统层面保障国内大型基础行业生产及关键业务系统的安全稳定运行，此次在西南石大关键业务系统的优秀支撑能力再一次证明了凝思安全操作系统的高安全、高可靠性。后续，凝思软件将不断助力教育信创稳步推进，让学校和学生真正受益其中。',
					],
				},
				{	id: '63',
					tittle: '四川凝思喜获首项发明专利授权',
					tag: '要闻',
					form: '市场部',
					time: '2022-11-29',
					content: [
						'近日，四川凝思软件有限公司（以下简称“四川凝思”）获得国家知识产权局授予的一项关于“基于口令派生的密钥协商方法、装置、设备及介质”的发明专利证书，这是四川凝思成立以来获得的第一个专利证书。',
						{
							src:require('../../images/news/news20221129-01.jpg'),
							alt:'四川凝思喜获首项发明专利授权',
						},
						'本发明专利涉及加解密技术、密钥协商、传输安全领域，是一种软件密码模块的配置管理协议会话密钥协商方法。可解决软件密码模块中对于配置管理终端与模块间的传输安全与轻量级协商问题，使密钥协商脱离对基础密钥管理设施的依赖。基于口令派生的密钥协商和初次认证预设鉴别数据的更替，减少交互过程，脱离对基础密钥管理设施的依赖，易于实施和更新。',
						'四川凝思自今年4月份成立以来，依托自身的技术能力，持续创新，不断完善子公司资质体系建设，通过软件著作权和专利的申请对公司软实力进行保护，提高技术话语权，提升行业影响力。',
						'操作系统所在的基础软件是成都市“建圈强链”的重点领域之一，四川凝思作为安全操作系统厂商，在北京、成都“双技术中心”布局下，将以凝思安全操作系统为纽带，全力打造地方性关键基础设施行业全国产化生态圈建设，不断建圈强链，书写成都基础软件产业高质量发展新篇章。',
					],
				},
				{	id: '62',
					tittle: '喜报！凝思软件荣获“龙蜥社区优秀合作企业”称号',
					tag: '要闻',
					form: '市场部',
					time: '2022-11-04',
					content: [
						'11月4日，云栖大会-龙蜥操作系统峰会在杭州云栖小镇举行，北京凝思软件股份有限公司（以下简称“凝思软件”）受邀参加本次峰会，并荣获“龙蜥社区优秀合作企业”称号。',
						{
							src:require('../../images/news/news20221107-01.jpg'),
							alt:'凝思软件荣获“龙蜥社区优秀合作企业”称号',
						},
						'本次峰会以“开放算力·云启未来”为主题，聚焦龙蜥社区技术实践，社区演进、生态发展和开发者培养四大维度。呈现一个拥有自我演进能力、可靠开源软件供应链、满足复杂应用场景的开源社区全貌，探索以“原生操作系统”为核心的发展路径，面向云时代推动操作系统产业形成良性循环。',
						{
							src:require('../../images/news/news20221107-02.jpg'),
							alt:'凝思软件荣获“龙蜥社区优秀合作企业”称号',
						},
						'凝思软件作为国内安全操作系统的中坚力量，一直积极参与国家的“信创”事业。凝思软件按照国家的指导加入龙蜥社区并发布商业发行版，提供替代CentOS的解决方案，帮助各行业用户实现平滑迁移，降低作业风险，提高执行效率，并充分发挥凝思软件“高可靠、高安全、高性能”的产品优势，为用户关键业务系统提供更多方案价值。',
						'此次“龙蜥社区优秀合作企业”称号的取得，是对凝思软件技术实力、服务能力的又一次认可，凝思软件将携手龙蜥社区将高可靠、高安全、高性能等优秀特性的操作系统赋能客户的每一个决策，同时，凝思软件将积极与龙蜥社区共同推动操作系统生态繁荣，促进操作系统产业蓬勃向上发展！',
					],
				},
				{	id: '61',
					tittle: '四川凝思与建行成都第八支行签订战略合作协议',
					tag: '要闻',
					form: '市场部',
					time: '2022-10-20',
					content: [
						'近日，四川凝思软件有限公司（简称“四川凝思”）与中国建设银行股份有限公司成都第八支行（简称“建行成都第八支行”）签署战略合作协议。',
						'此次签约仪式在四川建行大厦举行，建行成都第八支行副行长蒲枫华、四川凝思总经理文林宁代表双方签署战略合作协议。建行成都第八支行行长尹欣、建行四川省分行科技金融部总经理刘帆、建行四川分行公司部副总经理张亦娴、北京凝思软件股份有限公司副总经理彭志航等相关领导共同见证签约仪式。',
						'双方参会人员就后续工作开展举行了业务座谈会，双方将在资源整合、技术创新、业务提升、服务能力等方面进行全方位合作，共同推进建行金融科技的高质量发展。',
						{
							src:require('../../images/news/news20221020-01.png'),
							alt:'四川凝思与建行成都第八支行签订战略合作协议',
						},
						{
							src:require('../../images/news/news20221020-02.png'),
							alt:'四川凝思与建行成都第八支行签订战略合作协议',
						},
						'四川凝思作为建行“官方认证”集采供应商，长期以来一直与建行保持深度交流合作。此次与建行成都第八支行达成战略合作，是凝思软件签约工行成都春熙支行后的又一里程碑事件，是公司携手金融机构促进地方性金融数字化转型的重要举措，定将谱写地方银企合作共赢新篇章。',
					],
				},
				{	id: '60',
					tittle: '凝思安全操作系统欧拉版V6.0.99正式发布',
					tag: '要闻',
					form: '市场部',
					time: '2022-09-06',
					content: [
						'北京凝思软件股份有限公司（简称：凝思软件）积极参与欧拉开源社区工作，近日正式发布基于欧拉开源操作系统的凝思安全操作系统V6.0.99（欧拉版)。此款操作系统具有高可靠性、高安全性、高性能和良好兼容性等特性，能够为用户在操作系统层面提供有力支撑，保障生产及关键业务系统的安全稳定运行。',
						'<b>基础参数</b>',
						'版本信息：基于 openEuler 20.03 LTS SP3 开发；',
						'架构支持：支持鲲鹏、ARM、x86 等多种处理器架构；',
						'核心组件版本：Linux Kernel 4.19.90 、Glibc 2.28 、GCC 7.3.0 、Systemd 243；',
						'标准符合度：符合 POSIX 标准；',
						'核心支持能力：支持的最大内存为 2TB 、最大文件大小 16TB （ext4 文件系统） 、最大支持 1EB 硬盘（ext4文件系统）；',
						'安装引导：提供文本/图形安装界面 、提供光盘、U盘安装方式 、支持 Kickstart 自动化安装、x86 下支持 LEGACY 和 UEFI 两种，ARM 下支持 UEFI 模式；',
						'系统工具：提供软件包升级工具，支持 dnf/yum 软件包管理和升级 、提供防火墙的配置管理工具；',
						'虚拟化支持：提供对 KVM 的虚拟化支持（ KVM 版本和内核一致） 、提供对 QEMU 和 Libvirt 的支持 、提供虚拟化管理工具实现单机环境下的虚拟机创建、配置与管理；',
						'容器支持：提供对 Docker 的支持 、提供对轻量化容器引擎 iSulad 的支持；',
						'开发工具：支持 GCC 包含的 C、C++、Objective C，支持 Java、Go 的支持库 、支持 Python2、Python3、Perl、Shell 等脚本语言；',
						'IO 性能优化：优化 IOVA 页表查找和页表释放算法，提升 IO MMU 子系统性能；',
						'软件源：支持 openEuler 官方源；',
						'内核特性增强：支持 NVDIMM 提升大数据等业务场景性能、支持飞腾 CPU FT2000+/64 通用计算、支持 iscsi work 线程按 numa 亲和性绑核，提升 IO 性能、文件缓存 percpu 免锁优化，减少原子开销，提升并发访问文件性能，提升 Nginx 场景性能；',
						'安全特性增强：特有的凝思固域安全模块，支持强制行为控制、支持强制访问控制、支持强制能力控制、支持四权分立的系统管理；全系统安全增强；',
						'虚拟化特性增强：ARM 虚拟化支持 CPU/内存热插、提高资源配置灵活性、使能 KVM CPU 可配置为 custom 模式（ARM），实现虚拟机侧 CPU feature 的自定义配置、运维工具 VMTOP，支持虚拟机陷入陷出等性能指标快速采集、虚拟化支持安全启动，提高虚拟机安全性。',
						'凝思软件一直积极参与欧拉开源社区各项活动，同时也一如既往地秉持开放共赢的态度，与优秀的合作伙伴携手共筑产业生态。未来，凝思软件会继续发挥自身优势，加大核心技术研发投入，不断完善操作系统及相关产品，促进国产安全操作系统健康发展和广泛应用，在欧拉开源社区贡献更大力量，并携手国产软硬件厂商，共同打造更加稳定、可靠和高性能的操作系统解决方案，促进国产基础软件生态健康蓬勃发展！',
					],
				},
				{	id: '59',
					tittle: '凝思软件亮相2022中国信创产业发展大会',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-29',
					content: [
						'2022年8月26－28日，由四川省贸促会、四川省互联网信息办公室和四川省科学技术协会共同主办的2022中国信创产业发展大会在成都召开，本次大会是第七届中国（成都）智慧产业国际博览会的主题活动。北京凝思软件股份有限公司（以下简称“凝思软件”）作为国内先进的安全操作系统厂家受邀参加论坛和展览活动。',
						{
							src:require('../../images/news/news20220829-01.jpg'),
							alt:'凝思软件亮相2022中国信创产业发展大会',
						},
						'在大会圆桌论坛环节中，四川信创产业联盟负责人与信创头部企业代表围绕“信创生态建设与应用机遇”共同发声。',
						{
							src:require('../../images/news/news20220829-02.jpg'),
							alt:'凝思软件亮相2022中国信创产业发展大会',
						},
						{
							src:require('../../images/news/news20220829-03.jpg'),
							alt:'凝思软件亮相2022中国信创产业发展大会',
						},
						'凝思四川子公司总经理文林宁深度分享了凝思软件在电力行业16年的实践经验和历程。凝思安全操作系统从D5000调度系统到新一代主站系统、新一代变电站系统的建设，在电力领域的众多项目中均表现出色，广泛应用于发、输、变、配、用全业务系统，以产品、场景、应用、生态的“四个全面”能力，助力电力数字化体系建设。同时，文总表示，凝思软件不仅仅在能源行业，在金融、运营商、政府、军队等行业均有不俗表现，凝思软件愿与行业伙伴共同推动各行业的数字化转型和高质量发展。   ',
						'在展览环节中，基于凝思安全操作系统为安全底座的行业解决方案吸引了参会者的高度关注。与会期间，凝思软件还发布了凝思CentOS替代解决方案，保障用户业务系统平滑迁移并稳定安全运行，得到了参会者的高度认可。 ',
						{
							src:require('../../images/news/news20220829-04.jpg'),
							alt:'凝思软件亮相2022中国信创产业发展大会',
						},
						{
							src:require('../../images/news/news20220829-05.jpg'),
							alt:'凝思软件亮相2022中国信创产业发展大会',
						},
						'作为信创领域的头部企业，凝思软件将以此次信创展为契机，发挥公司在技术、产品、服务方面的优势，面向客户需求提供全场景国产化解决方案，为数字经济发展提供强有力的支持，筑牢数智社会安全底座。',
					],
				},
				{	id: '58',
					tittle: '凝思软件助力金融信创生态实验室金融信创人才培养',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-24',
					content: [
						'近年来，信息技术应用创新（以下简称“信创”）作为数据安全、网络安全的基础，在数字经济发展中的作用愈发重要。金融行业作为关系国民经济命脉的重要行业，其安全问题是信创发展的重要课题，提升关键基础软件技术应用创新是金融行业立足于数字化发展的必要保障。',
						'为切实推进金融信创发展进程，连通产业成果与行业需求，为金融机构信创项目的总体规划、选型策略、实施路径等提供参考与支撑，金融信创生态实验室（以下简称“实验室”）特邀国内多家信创头部企业，基于金融业务场景，以线上+线下模式开展信创培训工作，以培养金融信创从业人才，推广信创应用及相关信创技术。',
						'北京凝思软件股份有限公司（简称“凝思软件”）作为国内操作系统头部厂商，受邀与实验室签订了培训课程的相关协议，双方将进行资源深度整合，充分发挥各自优势，共同促进金融行业信创人才培养建设。',
						'金融行业其应用场景是多元化、复杂化的。凝思安全操作系统从底层为金融行业的各类业务系统提供高稳定、高可靠的安全保障。凝思大数据平台可为金融用户充分挖掘客户价值，实现精准定位营销并提升客户转化率。同时依托自身软件开发能力和本地化优势为金融业务场景输出定制化产品及服务。',
						'自实验室成立以来，凝思软件已与实验室开展众多合作，包含适配验证、标准规范制定、对接产金会议及助力人才培养等。未来，凝思软件将与实验室继续保持深度合作，资源共享，更好的为金融行业数字化转型和高质量发展提供强力支撑，筑牢金融行业数字化转型安全底座。',
					],
					footnote: [
						{
							title:'金融信创生态实验室',
							text:'2020年11月30日，由中国人民银行范一飞副行长和北京市殷勇副市长为实验室揭牌。在中国金融电子化集团有限公司牵头下组建，重要金融机构和产业机构合作，金融生态产学研用相关单位参与，遵循“共商、共建、共赢、共享”原则，专注金融信息技术创新的重要基础设施和专业化实验平台，是唯一国家级金融信创生态实验室。',
						},
					],
				},
				{	id: '57',
					tittle: '凝思软件受邀参加2022年（第五届）电力信息通信新技术大会暨数字化高峰论坛',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-19',
					content: [
						'8月19日，2022年（第五届）电力信息通信新技术大会暨数字化高峰论坛在广州顺利召开，本届大会以“数字技术创新融合、助力新型电力系统建设”为主题探讨信息技术创新发展趋势，分享电力行业国产化实践经验，助力新型电力系统建设。北京凝思软件股份有限公司（以下简称“凝思软件”）作为深耕电力领域近20年的安全操作系统厂商受邀参加本次峰会。',
						'此次大会大咖云集，来自电网企业、信息通信公司、电力科研院所、国内外信息通信技术或设备研发制造单位、信息通信技术等领域的专家学者共同探讨数字化发展之路。',
						{
							src:require('../../images/news/news20220819-01.jpg'),
							alt:'凝思软件受邀参加2022年（第五届）电力信息通信新技术大会暨数字化高峰论坛',
						},
						'在电力信息技术应用创新专题论坛演讲环节，凝思软件副总经理彭志航以《凝思安全操作系统在电网数字化建设中的实践》为主题分享了凝思在电力行业的多年实践经验和历程，从D5000调度系统到新一代主站系统、新一代变电站系统的建设，在电力领域的众多项目中凝思产品表现出色，能够全面支持调度、配网自动化、变电站等各类业务系统，满足用户连续稳定、安全可靠、易管易用的立体化要求，全面覆盖工业互联网“云网边端”丰富的应用场景。广泛应用于发、输、变、配、用全业务系统，以产品、场景、应用、生态的“四个全面”能力，助力电力数字化体系建设。',
						{
							src:require('../../images/news/news20220819-02.jpg'),
							alt:'凝思软件受邀参加2022年（第五届）电力信息通信新技术大会暨数字化高峰论坛',
						},
						'随着电力领域数字化进程的不断发展，为了适应电力系统的更高要求，凝思软件会以此为导向进一步优化操作系统及相关产品的性能，打造“横向覆盖丰富场景、纵向贯穿各层设备”的全产品体系，积极与国产软硬件产品进行适配共建国产化生态，为电力行业的发展提供源源不断的动力。',
					],
				},
				{	id: '56',
					tittle: '凝思软件成功入选第四批国家级专精特新“小巨人”企业',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-18',
					content: [
						'近日，工业和信息化部统一部署，北京市经济和信息化局发布《关于北京市第四批专精特新“小巨人”企业和第一批专精特新“小巨人”复核通过企业名单的公示》，北京凝思软件股份有限公司（以下简称“凝思软件”）成功入选第四批国家级专精特新“小巨人”企业。',
						{
							src:require('../../images/news/news20220818-01.jpg'),
							alt:'凝思软件成功入选第四批国家级专精特新“小巨人”企业',
						},
						'专精特新“小巨人”企业是指企业位于产业基础核心领域和产业链关键环节，专注于细分市场，创新能力强、市场占有率高、掌握关键核心技术、质量效益优，是未来产业链的重要支撑，是强链补链的主力军。国家级的专精特新“小巨人”企业更是“小巨人”中的排头兵，在行业中将起到示范引领的关键作用。',
						{
							src:require('../../images/news/news20220818-02.jpg'),
							alt:'凝思软件成功入选第四批国家级专精特新“小巨人”企业',
						},
						'凝思软件此次入选国家级专精特新“小巨人”企业，是对公司创新能力、技术实力等综合发展实力的充分认可，有利于提升公司核心竞争力，进一步增强公司在行业的影响力。 后续，凝思软件将会继续深耕专业领域、聚焦关键技术，不断研发“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品，提升专业化服务能力。同时发挥“国家小巨人排头兵”的标杆作用，携手基础软件伙伴一起，推动中国数字经济更高质量发展！',
					],
				},
				{	id: '55',
					tittle: '凝思软件西南总部签约落地成都·助力成都基础软件产业“建圈强链”',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-04',
					content: [
						'近日，北京凝思软件股份有限公司（简称“凝思软件”）全资子公司四川凝思软件有限公司（简称“四川凝思”）在成都市锦江区白鹭湾科技园正式签约落地。四川凝思作为西南总部，将建设研发中心、销售中心、工程中心和数据中心，构建北京、成都“双中心”布局。',
						{
							src:require('../../images/news/news20220804-01.jpg'),
							alt:'凝思软件西南总部签约落地成都·助力成都基础软件产业“建圈强链”',
						},
						'凝思软件致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件、相关产品和服务，在操作系统层面保障工业业务系统的安全。凝思软件西南总部顺利落地成都，将大力推进公司在电力、金融、电信和交通等重点行业业务的快速发展。',
						{
							src:require('../../images/news/news20220804-02.jpg'),
							alt:'凝思软件西南总部签约落地成都·助力成都基础软件产业“建圈强链”',
						},
						'2022年“建圈强链”被写入了成都市党代会报告，坚持把做强做优实体经济作为主攻方向，打好产业基础高级化和产业链现代化攻坚战，以产业建圈强链理念变革产业发展方式，推动产业链、创新链、供应链深度融合，提升现代产业体系区域带动力和发展竞争力。',
						'操作系统软件等基础软件，是成都市“建圈强链”的重点领域之一。凝思软件作为操作系统厂商落地成都将加强对国产软硬件的生态适配，结合自身技术的优势携手国产软硬件生态伙伴，推动本地产业建圈强链，助力成都打造基础软件核心竞争力。',
					],
				},
				{	id: '54',
					tittle: '开源汇智·凝思软件助力openEuler社区生态繁荣发展',
					tag: '要闻',
					form: '市场部',
					time: '2022-08-01',
					content: [
						'7月27日，2022开放原子全球开源峰会OpenAtom openEuler(以下简称“openEuler”) 分论坛在北京成功举办。论坛以“openEuler志高远，开源汇智创未来”为主题，为业界充分展示 openEuler 开源项目的运作成果，传递开源开放的 openEuler 发展模式。北京凝思软件股份有限公司（以下简称“凝思软件”）作为openEuler项目的关键成员单位共同参与本次盛会。',
						{
							src:require('../../images/news/news20220801-01.jpg'),
							alt:'开源汇智·凝思软件助力openEuler社区生态繁荣发展',
						},
						'本次分论坛上，openEuler 社区理事长江大勇发表了《openEuler面向数字基础设施的开源操作系统》的演讲，他从社区、技术、生态、商业、人才培养几个方面总结了openEuler的进展。凝思软件作为国内先进的操作系统厂商，推出基于openEuler社区的相关操作系统版本，为社区开源操作系统多元化发展贡献力量。',
						{
							src:require('../../images/news/news20220801-02.jpg'),
							alt:'开源汇智·凝思软件助力openEuler社区生态繁荣发展',
						},
						'当前，开源已经成为全球基础创新和操作系统发展的新模式，是现代信息技术发展的核心动力。凝思软件会加大对开源社区的贡献力度，与社区生态伙伴一起繁荣开源产业生态，促进数字经济的更高质量发展。',
					],
					footnote: [
						{
							title:'openEuler 开源社区',
							text:'通过开放的社区形式与全球的开发者共同构建一个开放、多元和架构包容的软件生态体系，孵化支持多种处理器架构、覆盖数字基础设施全场景，推动企业数字基础设施软硬件、应用生态繁荣发展。',
						},
					],
				},
				{	id: '53',
					tittle: '凝思安全云桌面产品顺利通过金融信创生态实验室适配验证',
					tag: '资质',
					form: '市场部',
					time: '2022-06-30',
					content: [
						'近日，北京凝思软件股份有限公司（简称“凝思软件”）与金融信创生态实验室开展的凝思安全云桌面系统适配验证工作顺利通过测试并取得适配验证报告。',
						'本次测试基于全国产IT环境，结合典型金融业务场景，测试项100%符合通过，测试结果表明凝思安全云桌面的功能性和安全性能够为金融行业提供专业化、定制化的解决方案，可以全面支持金融行业的业务需求。',
						{
							src:require('../../images/news/news20220630-01.jpg'),
							alt:'凝思安全云桌面产品顺利通过金融信创生态实验室适配验证',
						},
						'凝思安全云桌面基于虚拟化技术，使业务、应用、数据与操作终端分离，通过远程桌面实现终端任意延伸，并优化通信协议极大减少带宽占用率，简化运维管理，提升用户使用体验，达到可用、可靠、安全及易用的目标。',
						'未来，凝思软件将持续与金融信创生态实验室展开更深入、更广泛的战略合作，并结合自身产品和技术优势携手国产软硬件生态伙伴，为金融行业提供一揽子国产化解决方案，为金融信息技术应用创新发展注入强劲动力。',
					],
				},
				{	id: '52',
					tittle: '凝思软件中标中国农业银行四川省分行场景金融项目',
					tag: '项目',
					form: '市场部',
					time: '2022-06-28',
					content: [
						'近日，中国农业银行四川省分行某经营平台开发项目评审工作结束，北京凝思软件股份有限公司（简称“凝思软件”）成功中标。',
						{
							src:require('../../images/news/news20220628-01.jpg'),
							alt:'凝思软件中标中国农业银行四川省分行场景金融项目',
						},
						'凝思软件在与行业用户的联合开发和项目实施过程中积累了大量软件定制开发经验，能够为行业用户提供符合行业应用特点的定制开发及一揽子解决方案。正是凭借着卓越的技术开发能力以及对金融场景的深刻理解，凝思软件脱颖而出成功中标。',
						'未来，凝思软件将继续深耕金融行业，持续加大技术研发投入力度，积极助力金融机构数字化转型和可持续发展。',
					],
				},
				{	id: '51',
					tittle: '凝思“工业物联网安全操作系统项目”入选工信部2021年物联网示范项目名单',
					tag: '荣誉',
					form: '市场部',
					time: '2022-06-24',
					content: [
						'工信部发布〔2022〕480号文件，确定了2021年物联网示范项目名单，并予以公布。北京凝思软件股份有限公司（以下简称“凝思软件”）所申报的工业物联网安全操作系统项目，被列入2021年物联网示范项目名单。',
						{
							src:require('../../images/news/news20220624-01.jpg'),
							alt:'凝思“工业物联网安全操作系统项目”入选工信部2021年物联网示范项目名单',
						},
						{
							src:require('../../images/news/news20220624-02.jpg'),
							alt:'凝思“工业物联网安全操作系统项目”入选工信部2021年物联网示范项目名单',
						},
						'工信部强调各地工业和信息化主管部门及项目推荐单位结合本地区实际，在技术创新、应用落地、政府服务等方面对入选项目加大支持力度，加强实施效果跟踪评估，推进优秀成果推广应用，进一步发挥物联网在推动数字经济发展、赋能传统产业转型升级方面的重要作用。',
						'操作系统等数字软硬件是数字经济创新发展的重要载体，在各领域拓展数字软硬件的应用场景，能够推动数字国产软硬件与传统产业的融合，促进传统产业的转型升级。凝思软件的此次项目的推广应用为操作系统和物联网场景的融合提供了丰富的实践经验，同时也为物联网推进数字化经济发展发挥了积极作用。',
					],
					adjunct: [
						'工信部2021年物联网示范项目名单',
						'<a style="color:#0072ce;text-decoration:none;" href="https://wap.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2022/art_9fe812343d3546e78884c47c47493174.html">https://wap.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2022/art_9fe812343d3546e78884c47c47493174.html</a>',
					],
				},
				{	id: '50',
					tittle: '凝思软件四川子公司、江苏分公司成立',
					tag: '要闻',
					form: '综合管理部',
					time: '2022-06-20',
					content: [
						'根据公司战略发展规划需要，北京凝思软件股份有限公司全资子公司四川凝思软件有限公司、北京凝思软件股份有限公司江苏分公司于近日正式成立。',
						'本次设立分、子公司，是基于公司战略规划，结合业务推广总体要求，充分利用公司现有的技术、研发及管理经验优势，开拓国产软件业务，组建公司未来的研发中心、工程中心、运维中心及数据中心。分、子公司的成立，有助于公司进一步优化组织架构，提升区域核心竞争力和整体盈利水平，同时更快速地响应客户需求，为客户提供更加优质的服务。',
					],
				},
				{	id: '49',
					tittle: '凝思软件喜获2022“蓉漂杯”高层次人才创新创业大赛二等奖并与成都科创投现场签约',
					tag: '荣誉',
					form: '市场部',
					time: '2022-06-18',
					content: [
						'6月12日上午，由四川省委人才办指导、成都市委人才办主办，成都产业集团协办的2022“蓉漂人才日”高峰荟活动在天府国际会议中心举行。四川省委常委、成都市委书记施小琳，四川省委常委、组织部部长于立军出席并致辞。活动现场举行了2022“蓉漂杯”获奖项目颁奖仪式，北京凝思软件股份有限公司（以下简称“凝思软件”）的凝思安全操作系统项目经过层层选拔、脱颖而出，荣获大赛二等奖。',
						{
							src:require('../../images/news/news20220618-01.jpg'),
							alt:'凝思软件喜获2022“蓉漂杯”高层次人才创新创业大赛二等奖并与成都科创投现场签约',
						},
						'2022“蓉漂杯”大赛开赛以来共举办29场专题赛事，从21个国家、85个城市的1453个报名项目中筛选产生563个项目参加路演，达成意向投资金额102亿元，汇聚高层次人才2301人，有效推动人才、项目、资本精准对接。',
						{
							src:require('../../images/news/news20220618-02.jpg'),
							alt:'凝思软件喜获2022“蓉漂杯”高层次人才创新创业大赛二等奖并与成都科创投现场签约',
						},
						'颁奖现场还举行了项目签约仪式，涵盖电子信息、人工智能、医药健康、智能制造4个领域的9个高层次人才项目签约落地，凝思软件与成都科创投现场签约。',
						{
							src:require('../../images/news/news20220618-03.jpg'),
							alt:'凝思软件喜获2022“蓉漂杯”高层次人才创新创业大赛二等奖并与成都科创投现场签约',
						},
						'凝思软件由中国Linux先行者宫敏博士创办，是国内先进的国产安全操作系统厂商，以“实现国家大型基础设施行业基础软件国产化安全化”为使命，致力于研发生产“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行。目前，凝思软件已完成多轮战略融资工作，此次引入成都科创投产业基金将进一步助力公司产业融合和规范运营。',
						'凝思安全操作系统具备良好的自主可控能力，不依赖于任何基于Linux的商业发行版，充分避免了国外公司的技术风险、版权风险和商业风险及隐患，随着我国各行业国产替代和自主可控进程的加速，凝思操作系统及安全内核的自主开发能力将进一步提高公司的市场竞争力。',
					],
				},
				{	id: '48',
					tittle: '凝思软件完成B+轮战略融资工作',
					tag: '要闻',
					form: '董事会办公室',
					time: '2022-06-17',
					content: [
						'近日,北京凝思软件股份有限公司（以下简称“凝思软件”）顺利完成B+轮战略融资工作。本次融资引入的两家机构股东分别为联通创新基金和成都科创投集团。',
						'联通创新基金是联通资本平台下的产业投资基金，投资方向主要围绕通信运营商产业上下游相关的大联接、大计算、大数据、大应用、大安全领域具有协同互补、创新发展潜力布局的企业。',
						'成都科创投是成都市政府设立的科技创新投资集团。公司聚焦科技创新和新经济领域,重点投向硬核科技项目和高校院所重大原创技术落地转化项目、拥有全球顶尖创新资源的重大技术创新平台以及具备科技成果转化价值的产业链节点企业。',
						'凝思软件是北京市“专精特新”小巨人企业，通过多年技术研究和工程实践的积累，形成了强大的技术能力。操作系统研发能力处于国内前列，并与国际同步。凝思安全操作系统荣获工信部2021年物联网“关键技术攻关类”示范项目,参与了多项国家、行业操作系统相关标准的制定,拥有多项专利和软件著作权。',
						'目前，凝思软件拥有安全操作系统、安全云桌面系统、容器系统、分布式文件系统、虚拟化管理平台、大数据平台、可信验证系统、内网主机安全监管软件、网络高可用软件、Windows应用运行平台等系列产品,用户遍及电力、政府、金融、铁路、电信等重点行业,在大型基础行业实现规模化部署, 已经成为国家大型基础设施行业信息化建设的坚强基石。',
						'此前,凝思软件已完成A、B两轮战略融资工作, 引入国开制造业转型升级国家大基金、国家电网、南方电网、三峡集团、中信建投、深创投、江苏省投管等中央企业和国资背景产业基金。',
						'在本次B+轮融资将进一步优化公司股权结构。同时,联通创新基金和成都科创投将分别为公司通信运营商及金融业务赋能, 助力公司产业融合和规范运营。',
					],
				},
				{	id: '47',
					tittle: '携手合作，共赢精彩！中讯邮电咨询设计院有限公司领导到访凝思软件',
					tag: '项目',
					form: '市场部',
					time: '2022-06-16',
					content: [
						{
							src:require('../../images/news/news20220615-04.jpg'),
							alt:'中讯邮电咨询设计院有限公司领导到访凝思软件',
						},
						'6月14日上午，中讯邮电咨询设计院有限公司（简称“中讯院”）副总经理刘晓甲、总工程师钟志刚、网络能力开放中心经理符刚、无线技术支撑中心总监陈任翔、创新业务部物联网安全中心总监王首媛一行五人到访北京凝思软件股份有限公司（简称“凝思软件”）。',
						{
							src:require('../../images/news/news20220615-05.jpg'),
							alt:'中讯邮电咨询设计院有限公司领导到访凝思软件',
						},
						'中讯院的领导与凝思软件董事长宫敏博士、副总经理彭志航、张军、北京研发中心副总经理曾宏安、总工程师张国栋等公司领导进行了深入的技术和业务交流。',
						{
							src:require('../../images/news/news20220615-06.jpg'),
							alt:'中讯邮电咨询设计院有限公司领导到访凝思软件',
						},
						{
							src:require('../../images/news/news20220615-07.jpg'),
							alt:'中讯邮电咨询设计院有限公司领导到访凝思软件',
						},
						'在交流中，刘总听取了凝思软件对公司及产品情况的介绍，对凝思软件的产品和技术能力、以及在电力等行业中的广泛应用表示高度认可，并介绍了中讯院在网络安全产业链建设中的规划，就中讯院和凝思软件进行技术合作和产品建设，与凝思软件领导进行了深入交流和讨论，达成了高度共识和广泛合作意向。双方对携手合作、共赢精彩的发展前景进行了展望。',
					],
				},
				{	id: '46',
					tittle: '凝思软件受邀参加成都2022第一届数字安全发展交流大会',
					tag: '要闻',
					form: '市场部',
					time: '2022-06-15',
					content: [
						'6月10日，由四川省通信学会主办的2022第一届数字安全发展交流大会在成都隆重召开。作为国内安全操作系统的头部企业，北京凝思软件股份有限公司（以下简称“凝思软件”）受邀参加本次交流大会。',
						{
							src:require('../../images/news/news20220615-01.jpg'),
							alt:'凝思软件受邀参加成都2022第一届数字安全发展交流大会',
						},
						'本次大会聚焦两会网络安全重要提案,重点解读数字安全建设、数据安全、网络安全基础设施建设、物联网安全、5G应用安全等内容，吸引了包括安恒信息、深信服等在内的众多国内知名安全厂家参与。在数字安全展区内，凝思软件“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品吸引了众多参展者的驻足并深入交流。',
						{
							src:require('../../images/news/news20220615-02.jpg'),
							alt:'凝思软件受邀参加成都2022第一届数字安全发展交流大会',
						},
						{
							src:require('../../images/news/news20220615-03.jpg'),
							alt:'凝思软件受邀参加成都2022第一届数字安全发展交流大会',
						},
						'在数字化上升为国家战略的大背景下，将计算机安全、网络安全升维到数字安全，才能紧跟国家产业数字化发展的要求。基础操作系统作为数字安全底座，在行业数字化转型中扮演着至关重要的角色。凝思软件自成立以来坚持以自有技术和产品为国家大型基础行业提供更安全、更可信的自主操作系统环境，同时凝思软件有着丰富的全国性规模部署和运维保障经验，能够在操作系统层面为行业数字化转型提供有力的支撑，为国家关键领域的信息化建设保驾护航。',
						'值得一提的是5月16日国内首个跨省域国家级网络安全产业园区—国家网络安全产业园区（成渝）获批，后续将在政策实施、项目布局、企业培育、试点示范等方面给予川渝两地积极支持，这为凝思软件全资子公司四川凝思软件有限公司的发展打造了新引擎、激发了新活力，凝思软件后续会积极结合相关支持政策和市场需求，加强对同态加密、隐私计算等相关前沿安全技术研究，推进机密计算、可信执行环境（TEE）等应用软件研究，建设有示范性的数字安全软件基础设施并引领行业技术发展。',
					],
				},
				{	id: '45',
					tittle: '百分百中国“智”造!白鹤滩水电站机组总装工作全部完成',
					tag: '项目',
					form: '市场部',
					time: '2022-05-30',
					content: [
						'5月20日，白鹤滩水电站右岸地下厂房内的9号机组总装工作完成，进入调试阶段。至此，白鹤滩水电站16台百万千瓦水轮发电机组总装全部完成，向全面投产目标发起最后冲刺。',
						'白鹤滩水电站是实施“西电东送”的国家重大工程，水电站共设计安装16台百万千瓦机组，总装机容量1600万千瓦，左右两岸各布置8台。',
						{
							src:require('../../images/news/news20220530-01.jpg'),
							alt:'凝思软件成功入选工信部2021年物联网示范项目',
						},
						'白鹤滩水电站是实施“西电东送”的国家重大工程，水电站共设计安装16台百万千瓦机组，总装机容量1600万千瓦，左右两岸各布置8台。',
						'凝思安全操作系统及相关产品在电力系统发电、输电、变电、配电、用电、储电等生产业务方面都有着深度合作。至今已经部署了超过15万套系统，产品的成熟度和稳定性得到了充分的在线考验。',
						{
							src:require('../../images/news/news20220530-02.jpg'),
							alt:'凝思软件成功入选工信部2021年物联网示范项目',
						},
						'在白鹤滩项目的建设过程中，凝思软件积极配合相关单位，随时响应项目工程的技术保障工作，协同攻关多种技术难题，完成系统在不同场景下的安装、调试等工作，最终顺利通过现场验收。并在后续的运维保障工作中提供完善即时的技术支持，为项目的落地建成贡献了凝思的力量。',
						'白鹤滩水电站机组全部投产后，预计年平均发电量624.43亿千瓦时，每年节约标准煤消耗约1968万吨，减少二氧化碳排放约5200万吨。 凝思软件作为国内先进的安全操作系统厂商，在操作系统层面为国家各类关键业务系统和基础设施生产业务系统的建设保驾护航，对实现碳达峰、碳中和目标贡献智慧和力量。',
					],
				},
				{	id: '44',
					tittle: '凝思软件中标国网新一代调度项目',
					tag: '项目',
					form: '市场部',
					time: '2022-05-27',
					content: [
						'近日，国家电网有限公司2022年数字化项目第一次设备招标采购发布调度类软件安全操作系统中标公告，北京凝思软件股份有限公司（简称“凝思软件”）成功中标。凝思软件将为国家电网总部及多省市电力公司提供国网新一代调度技术支持建设工程凝思安全操作系统的安装、调试、售后等服务。',
						'随着国际形势不断地变化，对于电力行业这种关系国计民生的重要产业，所搭载的基础软件必须自主可控，才能够保证信息技术的安全自主。作为一家国产安全操作系统厂商，凝思软件在安全操作系统和其他产品的研发中创立了多项重要技术，在可靠性、安全性、可用性等多方面实现了突破，聚焦于电力的发、输、变、配、用、储等场景，均能够满足电力行业的复杂需求。',
						'凝思软件作为先进的国产安全操作系统厂商，以“实现国家大型基础设施行业基础软件国产化安全化”为使命，致力于研发生产“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行多年,目前已成为国内大型基础行业国产操作系统的中坚力量。',
						'未来凝思软件会继续发挥自身优势，不断完善操作系统及相关产品，促进国产安全操作系统健康发展和广泛应用，为客户业务的持续性、安全性、稳定性提供有力的保障。',
					],
				},
				{	id: '43',
					tittle: '凝思软件获得知识产权管理体系认证证书',
					tag: '资质',
					form: '市场部',
					time: '2022-04-07',
					content: [
						'2022年3月31日，凝思软件获得知识产权管理体系认证证书，覆盖范围包括安全操作系统的开发、安全服务所涉及的知识产权管理。此次认证成功标志着凝思软件的知识产权管理工作进入新阶段。',
						{
							src:require('../../images/news/news20220407-01.jpg'),
							alt:'凝思软件获得知识产权管理体系认证证书',
						},
						{
							src:require('../../images/news/news20220407-02.jpg'),
							alt:'凝思软件获得知识产权管理体系认证证书',
						},
						'获得知识产权管理体系认证证书，能够帮助凝思软件全面落实知识产权战略精神，提高知识产权对企业经营发展的贡献水平。知识产权工作也是凝思软件科技创新能力的重要体现。凝思软件高度重视知识产权工作，多举措提升知识产权管理、创新、保护、运用的能力，以知识产权支撑和助力凝思软件的发展，促进企业知识产权规范化管理和高质量运营。',
					],
				},
				{
					id: '42',
					tittle: '凝思软件完成B轮战略融资工作',
					tag: '要闻',
					form: '董事会办公室、市场部',
					time: '2022-03-22',
					content: [
						'近日，北京凝思软件股份有限公司（以下简称“凝思软件”）顺利完成B轮战略融资工作。本次融资引入<b>深圳市创新投资集团有限公司（以下简称“深创投集团”）及其旗下北京红土优势产业投资基金中心（有限合伙）（以下简称“红土基金”）。</b>',
						'深创投集团实力雄厚，投资眼光独特，在资本市场享有盛誉。公司成立23年来，深耕战略新兴行业，聚焦投资硬科技、专精特新中小企业，已成功投资和服务超过1400个项目，<b>累计助推212家被投企业顺利上市</b>，并在生态协同等方面为被投企业创造了广泛的发展空间。',
						'红土基金是由深创投集团控股的新锐基金，成立以来专注于新一代信息技术、软件与信息服务和智能装备等领域的投资机会。',
						'凝思软件作为北京市“专精特新”小巨人企业，是国内领先的安全操作系统厂商，以“实现国内大型基础设施行业基础软件国产化、安全化”为使命，致力于提供“高安全性、高性能、高稳定性”的基础操作系统软件、相关产品和服务，在操作系统层面保障工业业务系统的安全,目前已成为国内这一领域的中坚力量。',
						'经过二十年的持续努力，凝思安全操作系统系列产品、安全平台系列产品和定制开发产品的用户广泛应用于电力、铁路、电信等重点行业及政府、军队，产品成熟，性能卓越，系统的安全性、稳定性和可靠性得到充分验证，成为国家基础设施行业的坚强基石。',
						'凝思软件A轮融资投资机构包括国开制造业转型升级国家大基金及国网英大、南网建鑫、中信建投资本和三峡资本等中央企业产业基金。',
						'本次B轮融资将进一步优化公司股权结构，发挥各自资源协同优势，助力公司可持续快速发展。',
					],
					sign: [
						'北京凝思软件股份有限公司',
						'2022年3月28日',
					],
				},
				{
					id: '41',
					tittle: '凝思软件荣获北京市2021年度专精特新“小巨人”称号',
					tag: '荣誉',
					form: '市场部',
					time: '2022-03-22',
					content: [
						'近日，北京市经济和信息化局发布组织开展完成了2021年度第二批北京市专精特新“小巨人”企业申报、审查、评审、名单公示及认定工作，凝思软件成功入选。',
						{
							src:require('../../images/news/news20220321-05.jpg'),
							alt:'凝思软件荣获北京市2021专精特新“小巨人”称号',
						},
						'2021年1月23日，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》将通过中央财政资金引导，促进上下联动，将培优中小企业与做强产业相结合，加快培育一批专注于细分市场、聚焦主业、创新能力强、成长性好的专精特新“小巨人”企业，推动提升专精特新“小巨人”企业数量和质量，助力实体经济特别是制造业做实做强做优，提升产业链供应链稳定性和竞争力。',
						'专精特新“小巨人”企业是国家工信部围绕提升产业基础能力、产业链现代化水平和抢占未来产业制高点，在全国26个省份（含计划单列市）中筛选创新能力强、市场竞争优势突出的企业而认定的。专精特新“小巨人”企业主导产品应优先聚焦国家制造业短板弱项，围绕重点产业链开展关键基础技术和产品的产业化攻关，从事细分产品市场属于制造业核心基础零部件、先进基础工艺和关键基础材料，企业发展方向符合制造强国战略十大重点产业领域或属于新一代信息技术与实体经济深度融合的创新产品。',
						{
							src:require('../../images/news/news20220321-06.jpg'),
							alt:'凝思软件荣获北京市2021专精特新“小巨人”称号',
						},
						'凝思安全操作系统是保障国民经济重点行业新基建以及信息安全的利器。国民经济重点行业是国家的经济命脉和拉动经济发展的重要引擎。凝思软件经过二十一年的行业大型工程历练，具备了多方位、综合性的研发服务能力，能够完成安全操作系统自主构建、操作系统组件研制、安全机制自主研制、深度定制开发、系统性能优化、系统底层故障排查和解决等深层次技术任务，成为行业用户的重要选择。',
						'未来，凝思将借助国家发展专精特新“小巨人”的东风，在推进创新驱动理念的征程上持续深耕，积极发挥北京市专精特新“小巨人”示范引领作用，同时提高产业竞争力，浓厚专精特新“小巨人”发展氛围，助力打造专精特新“小巨人”企业集聚新高地。',
					],
					adjunct: [
						'北京市经济和信息化局关于对2021年度第二批北京市专精特新“小巨人”企业名单公告的通知_通知公告_北京市经济和信息化局',
						'<a style="color:#0072ce;text-decoration:none;" href="http://jxj.beijing.gov.cn/jxdt/tzgg/202203/t20220301_2619269.html">http://jxj.beijing.gov.cn/jxdt/tzgg/202203/t20220301_2619269.html</a>',
					],
				},
				{
					id: '40',
					tittle: '凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
					tag: '要闻',
					form: '市场部',
					time: '2022-03-21',
					content: [
						'北京冬奥会和冬残奥会已正式落下帷幕，“两个奥运，同样精彩”，中国向世界奉献一届无与伦比的冬奥会，为全球体育事业发展作出新贡献。',
						'在运动赛场上，中国运动员表现亮眼，无数冰雪荣耀时刻令人难忘；在赛场外，数以万计的企事业工作人员参与奥运城市运行保障工作，凝思软件作为其中的一员，协助国家电网为冬奥张家口赛区的电力保障工作贡献了一份自己的力量。在为期52天的冬奥供电保障工作期间，凝思软件技术人员积极配合国家电网工作，对操作系统及时进行巡检、排查等相关工作，保障系统安全稳定运行。',
						{
							src:require('../../images/news/news20220321-01.jpg'),
							alt:'凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
						},
						'北京冬奥会和冬残奥会的四场开闭幕式期间，国家电网的保电工作进入特级保电状态，凝思软件对于操作系统的巡查也进入到特级状态，工作人员在调度机房对操作系统的cpu负载、内存使用、系统剩余空间、双机数据库服务器的逻辑卷状态进行实时检测，确保系统状态运行良好，为北京冬奥的电力保障工作打下坚实的基础。',
						{
							src:require('../../images/news/news20220321-02.jpg'),
							alt:'凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
						},
						'3月14日，国家电网冀北电力有限公司张家口供电公司向凝思发来感谢信，对凝思软件精心选派业务骨干，全力调配支援物资，日夜兼程支援张家口赛区保电工作。各支援人员履责担当、无私奉献，放弃阖家团圆的春节假期，坚守保电关键岗位，为“精彩、非凡、卓越”的冬奥盛会贡献了重要力量表示衷心的感谢，也充分彰显了敢打硬仗、能打胜仗的铁军风采和精神。',
						'凝思安全操作系统有着可视、可控、自动化、规范化的优点，以及高安全性的等保四级、卓越的稳定性和优秀的兼容能力。从2008年北京夏季奥运会到2022年北京冬季奥运会，再到国家级大型重要会议和重大活动期间，凝思软件均高质量完成了电力保障工作。同时在电力系统发电、输电、变电、配电、用电、储电等生产业务方面均有大规模部署和深度合作，凝思安全操作系统总装机量已超二十万套，连续稳定运行超过14年，产品成熟度和稳定性得到充分的在线考验。',
						{
							src:require('../../images/news/news20220321-03.jpg'),
							alt:'凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
						},
						{
							src:require('../../images/news/news20220321-04.jpg'),
							alt:'凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
						},
						{
							src:require('../../images/news/news20220321-07.jpg'),
							alt:'凝思软件圆满完成冬奥会与冬残奥会电力保障工作',
						},
					],
				},
				{
					id: '39',
					tittle: '凝思软件与工行成都春熙支行签订战略合作协议',
					tag: '项目',
					form: '市场部',
					time: '2022-03-18',
					content: [
						'近日，北京凝思软件股份有限公司（简称“凝思软件”）与中国工商银行股份有限公司成都春熙支行（简称“工行成都春熙支行”）签署战略合作协议。双方将在资源整合、技术创新、业务提升、服务能力等方面进行全方位交流，共同推进工行金融科技的高质量发展。',
						{
							src:require('../../images/news/news20220318-01.jpg'),
							alt:'凝思软件与工行成都春熙支行签订战略合作协议',
						},
						{
							src:require('../../images/news/news20220318-02.jpg'),
							alt:'凝思软件与工行成都春熙支行签订战略合作协议',
						},
						'签约仪式在工行成都春熙支行举行，凝思软件四川分公司总经理文林宁、工行成都春熙支行副行长郑果代表双方签署战略合作协议。',
						'成都市锦江区新经济和科技局局长凌刚、工行成都春熙支行行长张岚、工行成都分行金融业务部副总经理尹波及凝思软件副总经理彭志航、四川分公司销售总监李海霞等领导共同出席并见证此次签约。',
						'此次凝思软件与工行成都春熙支行达成战略合作，是共同促进金融数字化转型的重要举措。双方将以此次战略合作为契机，充分发挥各自优势，不断提升综合金融服务与现代服务产业融合发展的广度和深度，谱写银企合作共赢新篇章。',
						{
							src:require('../../images/news/news20220318-03.jpg'),
							alt:'凝思软件与工行成都春熙支行签订战略合作协议',
						},
						{
							src:require('../../images/news/news20220318-04.jpg'),
							alt:'凝思软件与工行成都春熙支行签订战略合作协议',
						},
					],
					footnote: [
						{
							title:'工行成都春熙支行',
							text:'工行成都春熙支行是工商银行四川境内资产规模较大的分支机构之一。近年来，在发展传统金融业务的同时积极转型，在全面推动新动能、新经济行业的业务发展上，不断进行创新开拓，走在了同业前列。',
						},
					],
				},
				{
					id: '38',
					tittle: '凝思两款软件产品顺利通过金融信创生态实验室的适配验证',
					tag: '资质',
					form: '市场部',
					time: '2022-02-28',
					content: [
						'当前，金融业坚持<b>关键核心技术创新应用</b>，是落实国家创新发展战略，推动金融数字化转型的重要内容。为了更好地响应国家政策，切实<b>护航金融行业数字化转型</b>，北京凝思软件股份有限公司（简称“凝思软件”）与人民银行金融信创生态实验室开展了<b>凝思安全操作系统V6.0、凝思虚拟化管理平台软件V1.0</b>的金融信创应用适配验证测试，两款产品均顺利通过测试，取得适配验证报告。',
						{
							src:require('../../images/news/news20220228-01.jpg'),
							alt:'凝思安全操作系统适配验证报告盖章页',
						},
						{
							src:require('../../images/news/news20220228-02.jpg'),
							alt:'凝思虚拟化管理平台软件适配验证报告盖章页',
						},
						'此次测试基于典型金融业务场景，在产品功能性、性能效率、兼容性、易用性、可靠性等方面进行了适配验证，<b>测试100% 符合通过</b>。这意味着<b>凝思安全操作系统和凝思虚拟化管理平台软件完全符合金融业务系统所需</b>，为在金融领域的推广奠定了良好基础。',
						'<b>凝思安全操作系统</b>自主设计、自主构建、独立开发，深度定制开发内核模块，全面优化系统性能，实现与金融领域应用以及国内主流软硬件厂商产品的高兼容。',
						'<b>凝思虚拟化管理平台软件</b>以凝思安全操作系统为基础，使用虚拟化技术重新定义划分IT 资源，可以实现IT 资源的动态分配、灵活调度、跨域共享，为金融领域和其他领域的数据中心基础设施资源，提高IT 资源利用率。',
						'当下，金融业信息技术及应用创新迎来历史机遇期，凝思软件将积极与金融机构开展广泛的业务交流和合作，以信息技术应用创新和数字化转型为契机，持续夯实底层能力，不断拓展上层业务边界，与金融科技企业的同仁一道共建金融开放自主生态体系，为中国金融信创产业未来的发展贡献力量。',
					],
					footnote: [
						{
							title:'金融信创生态实验室',
							text:'成立于2020年11月，由中国人民银行领导，其直属单位中国金融电子化公司牵头组建，专注金融信息技术创新的重要基础设施和专业化实验平台。是央行指导下的金融信创生态实验室，旨在汇聚产业各方力量，解决金融共性问题，推进金融信息技术生态的持续发展。',
						},
					],
				},
				{
					id: '37',
					tittle: '<span>北京凝思软件股份有限公司入选北京市2021年度</span><span>第六批“专精特新”中小企业名单</span>',
					tag: '荣誉',
					form: '市场部',
					time: '2022-01-06',
					content: [
						'近日，北京市经济和信息化局公布了《关于2021年度第六批“专精特新”中小企业的公告》，<b>北京凝思软件股份有限公司</b>凭借在企业创新能力、专业化程度、精细化程度等综合实力上的优异表现，成功入选北京市2021年度第六批<b>“专精特新”</b>中小企业名单，并获得证书。',
						{
							src:require('../../images/news/news20220106-01.jpg'),
							alt:'北京凝思软件股份有限公司入选北京市2021年度第六批“专精特新”中小企业名单',
						},
						'<b>“专精特新”企业是指具备“专业化、精细化、特色化、新颖化”</b>四大特点的企业，是目前全国中小企业评定工作中等级较高、较为权威的荣誉称号。2021年7月30日，政治局会议提出，要“开展补链强链专项行动，加快解决“卡脖子”难题，发展专精特新中小企业”，这是首次在中央层面强调这一群体，并将之与补链强链联系到一起引发市场高度关注。',
						{
							src:require('../../images/news/news20220106-02.jpg'),
							alt:'北京凝思软件股份有限公司入选北京市2021年度第六批“专精特新”中小企业名单',
						},
						{
							src:require('../../images/news/news20220106-03.jpg'),
							alt:'北京凝思软件股份有限公司入选北京市2021年度第六批“专精特新”中小企业名单',
						},
						'<b>操作系统</b>产品一直是中国关键技术产业链中重要的一环，<b>凝思</b>作为专业的安全操作系统及相关产品和服务提供商，已<b>历经二十余年的沉淀与积累</b>，其完全自主设计、自主构建的凝思安全操作系统，首创内核安全机制，适应虚拟化、云计算、大数据、区块链对操作系统<b>可靠性、安全性、高性能、兼容性和实时性</b>的要求。未来，凝思将不断深化<b>“专业化、精细化、特色化、新颖化”</b>的要求，继续发挥技术优势，为行业用户的关键应用打造安全稳定的运行环境，携手生态合作伙伴，共同推进产业生态体系建设。',
					],
				},
			]
		},
		{
			year: '2021',
			text: [
				{
					id: '36',
					tittle: '<span>凝思软件受邀出席“中小银行数字化转型研讨会”</span><span>探讨金融数字化转型发展趋势</span>',
					tag: '要闻',
					form: '市场部',
					time: '2021-12-15',
					content: [
						'12月14日，由四川省计算机学会金融分会、中国人民银行成都分行主办，四川天府银行承办，以“<b>关注金融科技创新，持续助力中小银行数字化转型</b>”为主题的“中小银行数字化转型研讨会”，在成都成功举办。北京凝思软件股份有限公司<b>金融事业总监张山、四川分公司销售总监李海霞</b>等受邀出席了本次论坛，张山发表了讲话。',
						{
							src:require('../../images/news/news20211215-01.jpg'),
							alt:'凝思软件受邀出席“中小银行数字化转型研讨会”',
						},
						'中小银行作为我国金融体系的重要组成部分，在服务民营和小微企业、支持地方经济发展、助力脱贫攻坚、支持防疫抗疫等方面，扮演着不可或缺的角色。本次研讨会聚焦“<b>如何通过数字化转型驱动银行发展转型</b>”，直击区域性银行数字化转型发展中的重点、难点以及困惑，旨在通过内外部合力助推中小银行加快数字化转型脚步。',
						'2021年10月21日，我国五部委联合发布《关于规范金融业开源技术应用与发展的意见》，要求金融机构在使用开源技术时，应遵循“<b>安全可控、合规使用、问题导向、开放创新</b>”等原则，《意见》鼓励金融机构将开源技术应用纳入自身信息化发展规划，制定合理的开源技术应用策略；同时，鼓励金融机构积极参与开源生态建设，加强与产学研交流合作力度。',
						{
							src:require('../../images/news/news20211215-03.jpg'),
							alt:'凝思软件受邀出席“中小银行数字化转型研讨会”',
						},
						{
							src:require('../../images/news/news20211215-04.jpg'),
							alt:'凝思软件受邀出席“中小银行数字化转型研讨会”',
						},
						'随着行业信创的不断推进，<b>“开源”正成为金融数字化转型的关键</b>。掌握着金融行业信息数据命脉的操作系统及其生态建设，更是事关国家信息系统层面的数据安全。作为一家深耕国产安全操作系统多年的科技公司，凝思软件坚持<b>自主设计、自主构建、独立开发</b>，完全自研的固域内核安全子系统代码自主率达到<b>97.37%</b>，是自主研发大规模商用的LINUX内核安全模块之一。独创强制行为控制（MBC）机制，实现“高安全性、高性能、高稳定性”，全速助力金融业务系统实现安全、稳定、高效、可靠发展。 ',
						{
							src:require('../../images/news/news20211215-05.jpg'),
							alt:'凝思软件受邀出席“中小银行数字化转型研讨会”',
						},
						'为了更好地响应国家政策，全面护航金融行业信息系统建设，凝思在金融领域已积极开展凝思安全操作系统和金融业务系统以及关键应用所需平台软件的全面兼容性测试，经测试，均满足金融行业信息系统运行环境和部署要求。此外，凝思与金融信创生态实验室开展的凝思安全操作系统、凝思虚拟化管理平台两项测试，均在顺利进行中，预计年底即可取得相关资质。目前，凝思正积极与众多金融机构，开展广泛的业务交流和沟通合作。凝思软件愿与各中小银行金融机构以及金融科技企业同仁一道，共同<b>描绘我国区域性银行数字化的新蓝图</b>。',
						{
							src:require('../../images/news/news20211215-06.jpg'),
							alt:'凝思软件受邀出席“中小银行数字化转型研讨会”',
						},
					],
				},
				{
					id: '35',
					tittle: '凝思安全操作系统V6.0.90获得软件产品证书',
					tag: '资质',
					time: '2021-12-07',
					content: [
						'近日，根据《进一步鼓励软件产业和集成电路产业发展的若干政策》和《软件产品评估标准》（T/SIA003 2019）的有关规定，经北京软件和信息服务业协会审核，我公司自主研发的凝思安全操作系统V6.0.90，符合《软件产品评估标准》（T/SIA003-2019）相关规定，于2021年11月29日被授予《软件产品》证书。',
						{
							src:require('../../images/honor/凝思安全操作系统V6.0.90（北京软协）.jpg'),
							alt:'凝思安全操作系统V6.0.90（北京软协）',
						},
						{
							src:require('../../images/honor/凝思安全操作系统V6.0.90（中国软协）.jpg'),
							alt:'凝思安全操作系统V6.0.90（中国软协）',
						},
						'我公司从2001年成立至今，始终将独立自主研发创新放在首位，形成了一套科学的创新管理体系，并研发了一系列具有竞争力的产品：凝思安全操作系统、凝思云桌面系统、凝思容器系统、凝思双机热备系统软件、凝思虚拟化管理平台等，致力于为我国各领域行业的客户，提供不同场景下的多样化业务需求。',
						'此次产品软件认定的通过，显示了北京凝思软件股份有限公司整体研发实力，标志着我公司的产品不仅赢得了广大用户的信赖，更赢得了业内专家的认可，对扩大品牌影响力具有积极意义。',
						'未来，凝思软件将不断提升科技研发能力，扩大在金融、能源、电力、党政等关键领域的知名度，树立良好的品牌形象，为广大客户及业界提供更优质的产品和服务。',
					],
				},
				{
					id: '34',
					tittle: '凝思软件再获“软件企业”称号',
					tag: '资质',
					time: '2021-12-06',
					content: [
						'11月29日，北京凝思软件股份有限公司顺利完成“软件企业”年度评估认证工作，并再次获得由北京软件和信息服务业协会评估、中国软件行业协会颁发的证书。',
						'“软件企业证书”是依据国家相关政策和规范要求，由各地软件行业协会对企业的资质、企业诚信、研发能力、经营收入、质量保证、软件产品及服务等方面进行能力评估后，颁发给合格软件企业的一项证明。',
						{
							src:require('../../images/honor/软件企业证书（北京软协）.jpg'),
							alt:'软件企业证书（北京软协）',
						},
						{
							src:require('../../images/honor/软件企业证书（中国软协）.jpg'),
							alt:'软件企业证书（中国软协）',
						},
						'本项证书的获得，表明了北京凝思软件股份有限公司在信息服务领域，连年取得重大成绩，是公司在研发能力、创新能力、综合实力与竞争力等方面的综合体现，为公司进一步跟随国家信息化国产化的脚步、赋能各行业关键领域安全高效发展、实现其规模与效益同步增长等方面，起到积极的促进作用。',
						'凝思软件作为国产服务器操作系统的头部厂商，将在我国国产化浪潮中发挥更大的价值和作用，真诚的希望与生态伙伴一起，共创中国软件辉煌的未来！',
					],
				},
				{
					id: '33',
					tittle: '凝思软件通过ISO20000和ISO27001体系认证',
					tag: '资质',
					time: '2021-11-16',
					content: [
						'凝思软件于2021年11月15日顺利获得ISO20000信息技术服务管理标准和ISO27001信息安全管理体系标准国际认证证书。',
						{
							src:require('../../images/honor/北京凝思软件股份有限公司IT(1)-1.jpg'),
							alt:'ISO27001',
						},
						{
							src:require('../../images/honor/北京凝思软件股份有限公司IT(1)-2.jpg'),
							alt:'ISO27001-英文',
						},
						{
							src:require('../../images/honor/北京凝思软件股份有限公司IT(1)-3.jpg'),
							alt:'ISO20000',
						},
						{
							src:require('../../images/honor/北京凝思软件股份有限公司IT(1)-4.jpg'),
							alt:'ISO20000-英文',
						},
						'此次认证成功，标志着凝思软件已经具备国际化标准要求的信息技术服务和信息安全管理能力，将在信息安全领域为客户提供更优质的产品和服务，对提升凝思软件公司形象、提高公司规范化管理有着积极的推动作用，是公司持续稳定发展的重要保证。凝思软件将进一步优化和规范各项管理制度，不断提高体系运行的有效性和公司的管理水平，共同推进公司的快速发展。',
					],
					sign: [
						'北京凝思软件股份有限公司',
						'2021年11月16日',
					],
				},
				{
					id: '32',
					tittle: '北京凝思软件股份有限公司完成与中科可控全系列服务器兼容性认证',
					tag: '要闻',
					form: '市场部、测试部',
					time: '2021-10-28',
					content: [
						'近日，北京凝思软件股份有限公司完成与中科可控全系列服务器兼容性认证。经北京凝思软件股份有限公司与中科可控联合测试，凝思安全操作系统V6.0与中科可控的H系列服务器、R系列服务器、W系列服务器、X系列服务器兼容性良好，可以顺利安装、配置，并稳定运行。',
						{
							src:require('../../images/news/news20211028-01.jpg'),
							alt:'凝思-中科可控H系列',
						},
						{
							src:require('../../images/news/news20211028-02.jpg'),
							alt:'凝思-中科可控R系列',
						},
						{
							src:require('../../images/news/news20211028-03.jpg'),
							alt:'凝思-中科可控W系列',
						},
						{
							src:require('../../images/news/news20211028-04.jpg'),
							alt:'凝思-中科可控X系列',
						},
						'凝思安全操作系统具有高兼容、高可用、高安全、高稳定的特性。在国产化软硬件生态领域，凝思安全操作系统已经适配了多个主流国产服务器如浪潮、曙光、联想、华为、方正、同方、宝德、华三等。',
						'未来凝思会继续携手生态伙伴，助力国产软硬件产业生态体系的建设，不断加强与社会各领域的合作，促进国产化的发展。',
					],
					//考虑到之后可能会给图片加标题，因此不用俩俩一排的方式
					sign: [
						'北京凝思软件股份有限公司',
						'2021年10月28日',
					],
				},
				{
					id: '31',
					tittle: '凝思软件完成战略融资工作',
					tag: '要闻',
					time: '2021-08-20',
					content: [
						'近日，北京凝思软件股份有限公司完成战略融资工作，由国网英大产业基金公司与国开制造业转型升级基金联合领投，南网产业基金、中信建投资本、三峡资本、江苏省投管、维京投资、万有引力资本与诚美资本联合跟投。融资金额总计3.57亿元人民币，北京中易汇才科技服务有限公司担任此轮战略融资财务顾问。',
						'凝思软件成立以来始终秉承和坚持 “以自己的技术、产品和服务，为国家基础设施生产业务系统及各类关键业务系统提供更安全、更可信的自主操作系统环境”的经营理念，持续开发自主设计的国产安全操作系统系列产品。凝思软件的操作系统及其相关软件产品在电力、铁道、电信及政府军队得到了广泛应用和好评，证明了凝思软件的操作系统及其系列平台软件产品，是完全可以满足和保证国家基础设施生产业务系统和各类关键业务系统建设的需求，因此获得了国家大基金、央企基金及其他投资基金的一致认可。',
						'通过本轮融资，凝思软件优化了公司股权结构平台，增加了国家大基金及央企作为公司股东，将大大提升凝思软件的企业品牌形象，对公司新行业市场领域开发和人才引进必将产生积极的作用。凝思软件将在各基金的支持和赋能下，加大研发的投入和市场应用领域的开拓，持之以恒为国家各类关键业务系统和基础设施生产业务系统的建设保驾护航。',
					],
					sign: [
						'北京凝思软件股份有限公司',
						'2021年8月20日',
					],
				},
				{
					id: '30',
					tittle: '凝思软件向河南省慈善总会“防汛抗洪 驰援河南”慈善项目捐款200万元',
					tag: '慈善',
					form: '人力资源部',
					time: '2021-07-29',
					content: [
						'近日河南全省各地连续遭遇大暴雨、特大暴雨，受灾严重，北京凝思软件股份有限公司时刻关注河南灾情。',
						'经公司董事会商议批准，凝思软件于7月28日向河南省慈善总会“防汛抗洪 驰援河南”慈善项目捐款200万元，全部用于紧急采购救灾物资及河南省灾后重建工作。',
						'大雨无情人有情，凝思软件愿献出一份微薄力量，与河南人民携手共度难关。',
						'河南加油！',
					],
					sign: [
						'北京凝思软件股份有限公司',
						'2021年7月29日',
					],
				},
				{
					id: '28',
					tittle: '《凝思操作系统安全增强系统(Linux版)V6.0》通过国家保密科技测评中心保密检测',
					tag: '资质',
					form: '市场部',
					time: '2021-02-09',
					content: [
						'2021年1月16日，凝思自主研发的《凝思操作系统安全增强系统(Linux版)V6.0》正式通过国家保密科技测评中心安全保密检测，检测结果证明《凝思操作系统安全增强系统(Linux版)V6.0》完全符合《涉及国家秘密的信息系统网络安全管理产品技术要求》的相关要求。',
						{
							src:require('../../images/news/news20210209-01.jpg'),
							alt:'涉密信息系统产品检测证书',
						},
						'国家保密科技测评中心，是国家级的测评机构，在技术上具有先进性、权威性和科学性。涉密信息系统产品检测证书的取得，意味着凝思产品在涉密安全性方面得到权威性的认可，也是对凝思科研技术水平的充分肯定。',
						'以安全操作系统为代表，凝思面对国家、行业在网络与信息安全领域的严峻形势和迫切需求，在国产安全操作系统建设等方面扎实创新积极探索，将适应工控系统对可靠性、实时性、安全性和连续性的特殊要求与先进信息技术、信息安全紧密结合，有效解决在信息化应用和推进过程中的共性问题。该项资质的取得将有助于凝思开拓市场，拓宽经营范围，为实现公司多元化、可持续发展提供强劲动力。',
					],
				},
			]
		},
		{
			year: '2020',
			text: [
				{
					id: '27',
					tittle: '凝思荣获“2020年中国能源企业信息化方案案例创新奖”',
					tag: '荣誉',
					form: '市场部',
					time: '2021-01-13',
					content: [
						'2021年1月8日，由中国信息协会主办的“第十届中国能源企业信息化大会”在北京隆重召开。当前，以信息技术为核心的新一轮科技革命正在孕育兴起，信息化和工业化的深度融合日益成为能源创新驱动发展、提升企业核心竞争力的先导力量。为了深入推进能源企业的信息化建设，建设清洁、高效、安全、可持续的现代能源体系，各大型能源企业及信息化领域代表厂商齐聚一堂。',
						'大会以“引领新时代，赋能新未来”为主题，分享数字化转型伙伴的先进经验与典型案例，共同探讨产业数字化思路。会上，中国信息协会从产品性能，应用适用性，部署环境，易用性，开发环境等维度评选出“产品技术创新奖”及“方案案例创新奖”。',
						'在大会成果发布环节，凝思软件“凝思能源行业大型基础设施案例介绍”荣获主办方颁发的“2020年中国能源企业信息化方案案例创新奖”。凝思安全操作系统是保障国民经济重点行业新基建以及信息安全的利器。国民经济重点行业是国家的经济命脉和拉动经济发展的重要引擎。行业的信息基础设施逐步从大型主机架构向互联网架构，分布式集群化，原生云应用转型，以至于后续的虚拟化、容器化逐步成为云计算、人工智能、移动互联网时代基础设施的基本技术特征，操作系统已经成为基础设施与通用CPU并列的最重要的制约环节。',
						'20210113-1.jpg 第十届中国能源企业信息化大会',
						{
							src:require('../../images/news/news20210113-01.jpg'),
							alt:'第十届中国能源企业信息化大会',
						},
						'电力行业关系到国家能源安全和国民经济命脉。凝思安全操作系统部署在电网调度系统四大类27大应用，遍及I、II、III区核心业务。凝思还与国内多家知名服务器硬件厂商以及存储厂商通力合作，对系统和硬件的兼容性、稳定性进行了全面深入的测试，并针对电力调度自动化系统可用性、安全性方面的特殊需求设计出相应的解决方案。为国家电网提供了安全可靠的底层服务平台，全方位支持电力调度自动化系统的稳定可靠运行。',
						{
							src:require('../../images/news/news20210113-02.jpg'),
							alt:'2020中国能源企业信息化方案案例创新奖',
						},
						'能源是现代化的基础和动力。未来，凝思软件将牢牢把握时代脉搏和发展机遇，助力能源信息化、智慧化发展，为能源行业客户提供高兼容、高可用、高安全、高稳定的国产操作系统，共创能源行业的美好新时代！',
					],
				},
				{
					id: '26',
					tittle: '凝思公告',
					tag: '公告',
					form: '人力资源部',
					time: '2020-12-11',
					content: [
						'近日CentOS 官方发文称CentOS 8将于2021年结束并停止维护， CentOS 7也将会于其生命周期到期结束。目前CentOS在国内服务器操作系统市场占据了很大份额，国内也有些操作系统厂商使用CentOS进行系统构建。CentOS此举无疑会给国内操作系统厂商和用户带来巨大冲击和影响。',
						'北京凝思软件股份有限公司（以下简称“凝思”）自成立之初就一直秉承“独立自主、完全可控”的产品研发理念，采取“不依赖RedHat、SUSE及Ubuntu等任何国外商业发行版，自主构建OS系统并自行开发固域Linux安全内核”的基本技术路线。因此凝思安全操作系统不会受到国外任何商业发行版闭源的影响，将保证为国内用户提供安全可靠的产品和服务。',
						'作为全球商业部署量前列的三个Linux安全内核之一的凝思“固域”（英文名DomSec）Linux安全内核,是国内操作系统厂家替换国外SELinux和AppArmor安全内核的推荐选择，凝思“固域”安全内核中发明的MBC高安全机制的精髓已被国军标采纳。',
						'凝思愿携手更多的合作伙伴一起共筑自主可控新生态，为各行业客户提供安全、可靠、适用的国产自主可控产品，共同推动国产化自主可控产业的新发展。',
					],
					sign: [
						'北京凝思软件股份有限公司',
						'二〇二〇年十二月十一日',
					],
				},
				{
					id: '25',
					tittle: '凝思积极参与工业互联网创新发展生态建设',
					tag: '要闻',
					form: '市场部',
					time: '2020-09-22',
					content: [
						'由中国工业互联网研究院（以下简称工联院）与海尔卡奥斯物联生态科技有限公司（以下简称卡奥斯）发起的北京市朝阳区工业互联网创新发展产业集群生态聚集中心于9月18日成立。',
						{
							src:require('../../images/news/news20200922-01.png'),
							alt:'北京市朝阳区工业互联网创新发展产业集群生态集聚中心启动仪式',
						},
						'北京凝思软件股份有限公司（以下简称凝思）作为卡奥斯生态合作伙伴，一直积极参与工业互联网创新发展产业集群生态建设。凝思为国内大型行业用户提供坚实的底层软件平台和符合技术发展趋势的平台架构设计，致力于国家新型基础设施建设，服务于国民经济重点行业，实现基础软件国产化，为大型基础设施的信息基础设施提供高安全、高可靠、高性能操作系统软件及其相关的关键产品，从系统软件基础层面开始，全面保障信息安全。在国家电力行业、国铁售票系统、电信行业、政府部门和军队用户都有大规模的部署应用。',
						'作为国内安全操作系统领域的领跑者，凝思愿与工联院、卡奥斯等生态合作伙伴一道推动工业互联网的创新发展，发挥企业自身的优势，强强联手、形成合力，携手打造工业互联网的新生态。',
					],
				},
				{
					id: '24',
					tittle: '凝思软件顺利通过软件能力成熟度CMMI5级认证',
					tag: '资质',
					form: '市场部',
					time: '2020-08-27',
					content: [
						'2020年8月11日，北京凝思软件股份有限公司（下称：凝思）正式通过软件能力成熟度领域最CMMI5级认证。这标志着凝思软件过程能力已经达到国际公认的优化管理级，在软件研发的过程组织能力、技术研发能力、实施交付能力、项目管理能力等各方面均已达到国际水平。',
						{
							src:require('../../images/news/news20200828-01.jpg'),
							alt:'CMMI5级认证证书',
						},
						'CMMI认证体系是国际软件业衡量软件企业能力成熟度和项目管理水平的权威标准，CMMI5级为最高级别。截至2020年7月，全球通过CMMI认证且在有效期内的企业中仅有12.5%达到了CMMI5级，其中符合CMMI5级标准的中国企业仅占9%。',
						'据悉，在CMMI5级评估过程中，评估专家组成员按照CMMI5级标准对凝思的产品研发管理体系进行了全面、严格的审查，对凝思过程改进现状及研发管理能力进行了成熟度等级的综合评定，最终一致评定凝思达到了CMMI5级标准要求。',
						{
							src:require('../../images/news/news20200828-02.png'),
							alt:'凝思软件顺利通过软件能力成熟度CMMI5级认证',
						},
						'作为一家以大型基础设施的信息基础设施提供高安全、高可靠、高性能操作系统软件及其相关的关键产品的高科技企业，凝思始终坚持在软件产品研发、生产、服务等方面进行全面科学化、规范化的管理工作，并不断取得突破。',
						'未来，凝思将继续发挥自身的技术、管理优势，把CMMI5级过程改进取得的丰硕成果持续固化在各产品研发项目中，在高成熟度的体系研发管理框架下，不断提升产品创新能力与开发能力，构建系统化服务能力，为客户提供更加安全、可靠、性能稳定的高端产品和服务。',
					],
				},
				{
					id: '23',
					tittle: '凝思软件与中国长城完成产品兼容性互认证',
					tag: '要闻',
					form: '市场部',
					time: '2020-03-13',
					content: [
						'日前，北京凝思软件股份有限公司凝思安全操作系统V6.0版本与中国长城科技集团股份有限公司擎天DF720服务器完成产品兼容性互认证测试。此次互认证经凝思与中国长城联合测试得出：双方产品相互兼容，稳定运行，表现优异，能够满足用户关键性应用需求。',
						{
							src:require('../../images/news/news20200312-02.png'),
							alt:'产品兼容性互认证明',
						},
						'<b>凝思安全操作系统V6.0</b>是凝思软件自主研发、拥有完全自主知识产权、达到等级保护第四级和军B级安全级别的操作系统，是国内首家达到安全服务器安全目标EAL3级别的安全产品。凝思操作系统V6.0安全服务器版除了提供强制访问控制、强制行为控制、强制能力控制、四权分立的系统管理、安全增强的对外服务，还提供安全辅机等系统安全机制和安全措施，具有高安全、高可用、高效、易维护和良好的兼容性等特点。',
						'<b>长城擎天DF720服务器</b>是中国长城自主研发的通用2U机架式服务器，是一款基于飞腾FT-2000+/64核处理器、国产化中文BIOS系统与自主安全操作系统所构建，具备完全自主知识产权的通用服务器。DF720适用于IT核心业务、云计算、虚拟化、分布式存储、大数据、高性能计算、企业业务应用等多种应用场景。DF720服务器的BIOS、BMC均为长城自主研发，对FT-2000+/64核处理器与硬件系统进行深度调优，使整机性能更高，更稳定，更可用。',
						'此次凝思与中国长城完成兼容互认证，充分展示了国内信息化生态系统的协同发展与日臻完善。凝思会继续完善自身产品，并不断推进与上下游厂商的协同合作，以形成更加安全高效的国产操作系统生态体系，更好地服务于广大用户。凝思也期待与更多的产商实现兼容，共同破解生态瓶颈。让我们共同携手，共建完整的产业生态圈。',
					],
				},
				{
					id: '22',
					tittle: '<span>凝思软件与天津飞腾完成产品兼容性互认证</span><span>助力芯片国产化发展<span>',
					tag: '要闻',
					form: '市场部',
					time: '2020-03-12',
					content: [
						'日前，经北京凝思软件股份有限公司与天津飞腾信息技术有限公司严格测试，共同完成了凝思安全操作系统V6.0版本与飞腾FT-2000+系列处理器兼容性适配测试认证工作。',
						'测试认证结果表明，凝思安全操作系统V6.0版本与飞腾处理器兼容性良好，性能优异，运行安全稳定，满足行业客户关键性应用需要。',
						{
							src:require('../../images/news/news20200312-01.png'),
							alt:'产品兼容性互认证明',
						},
						'凝思作为飞腾合作伙伴，其产品已覆盖多个行业和领域，此次与飞腾完成兼容性互认证，体现了凝思安全操作系统V6.0作为自主创新的安全操作系统具有高安全、高可用、开放性和兼容性的优良特性，能够完全支持并兼容飞腾芯片。也表明了飞腾对凝思产品安全稳定等方面的高度认可，同时也是双方加强产业生态圈建设迈出的重要一步。',
						'伴随国家信息产业转型升级的不断推进，全面国产化进程已经开始。凝思紧跟国产化IT建设步伐，全面适配国产软硬件，目前已完成与飞腾、龙芯、兆芯、申威、海光等国产软硬件完成产品兼容性互认证。',
					],
				},
			]
		},
		{
			year: '2019',
			text: [
				{
					id: '21',
					tittle: '凝思圆满完成国网首届配电自动化专业技能竞赛赛前培训和技术支持工作',
					tag: '要闻',
					form: '市场部',
					time: '2019-11-06',
					content: [
						'2019年10月，国家电网有限公司首届配电自动化专业技能竞赛在苏州举行。',
						{
							src:require('../../images/news/news20191106-01.png'),
							alt:'凝思圆满完成国网首届配电自动化专业技能竞赛赛前培训和技术支持工作',
						},
						'为更好的配合本次国家电网技能大赛，北京凝思软件股份有限公司成立了大赛筹备小组，为大赛提供了充足技术培训人员和系统保障。凝思软件选派了多名资深技术专家为国家电网各省公司精心组织进行了本次大赛赛前省级培训，确保了大赛的专业性和权威性。并在大赛期间全面支持系统保障工作，确保了大赛的稳定性和公平性。',
						{
							src:require('../../images/news/news20191106-02.png'),
							alt:'凝思圆满完成国网首届配电自动化专业技能竞赛赛前培训和技术支持工作',
						},
						'本次大赛吸引到来自国网公司的27支参赛队伍216名优秀选手参赛。经过激烈角逐，最终国网江苏省电力有限公司荣获本次大赛一等奖，国网山东省电力有限公司、国网浙江省电力有限公司荣获本次大赛二等奖，国网福建省电力有限公司、国网重庆市电力有限公司、国网安徽省电力有限公司荣获本次大赛三等奖。此外，大赛还决出了个人主站方向、终端方向一、二、三等奖。',
						{
							src:require('../../images/news/news20191106-03.png'),
							alt:'凝思圆满完成国网首届配电自动化专业技能竞赛赛前培训和技术支持工作',
						},
						'通过以赛促训，交流经验、切磋技能、展示形象，进一步提高了国网公司一线配电自动化专业员工技能素质，为国网公司挖掘培养出一批配电自动化专业优秀人才。',
						'凝思软件作为本次大赛操作系统服务商，有效支撑配电自动化实用化水平提升，更好地发挥配电自动化建设成效。为国网公司未来智能配电网的建设、运维工作提供了坚强支撑。',
					],
				},
				{
					id: '20',
					tittle: '凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
					tag: '要闻',
					form: '市场部',
					time: '2019-10-18',
					content: [
						'四时俱可喜，最好新秋时。2019年10月17-19日，由江苏省人民政府、工业和信息化部、中国工程院、中国科学技术协会共同主办的2019世界智能制造大会在南京拉开帷幕。本次大会以“智能新视界，工业新未来”为主题，从“新思维、新业态、新技术”三个维度出发，创设发布会、高峰会、嘉年华和博览会四大板块体系。',
						{
							src:require('../../images/news/news20191018-01.jpg'),
							alt:'凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
						},
						'北京凝思软件股份有限公司作为工业控制系统产业联盟重要成员单位，在南京国际博览中心5号展厅迎接八方来宾，生动展现国产操作系统的飞速发展。期间工业和信息化部辛国斌副部长一行来到凝思展位前，认真听取了凝思软件在国产操作系统新技术、新发展、新模式的介绍，辛国斌副部长对凝思软件目前在国内市场取得的成绩给予高度肯定。本次大会上，凝思软件全方位展示了其在工控领域的科技创新成果，国产操作系统稳定安全的实力得到充分印证。',
						{
							src:require('../../images/news/news20191018-02.jpg'),
							alt:'凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
						},
						'作为工业控制行业的领跑者，凝思软件董事长宫敏博士受邀在工控发展与智能制造论坛作“工业互联网安全操作系统”的报告，他从新工业全球化背景出发，从德国工业4.0、振兴美国制造和创新法案、中国智能制造详细介绍了新工业的内涵，结合凝思自身产品技术优势全面向与会专家学者讲解了凝思近年来在工控领域做出的贡献与成果，期间会场吸睛无数，广受好评。',
						{
							src:require('../../images/news/news20191018-03.jpg'),
							alt:'凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
						},
						'数字时代，千帆竞发、百舸争流，凝思软件作为国产工控领域解决方案提供商，为电力、交通、金融、电信运营商等不同领域企业客户提供创新技术与产品解决方案，让更多国内行业用户使用我们自主研发的安全操作系统。',
						{
							src:require('../../images/news/news20191018-04.jpg'),
							alt:'凝思软件参加2019年世界智能制造大会，公司董事长宫敏应邀作专题报告',
						},
					],
				},
				{
					id: '19',
					tittle: '“不忘初心、牢记使命”凝思顺利完成国庆服务保障工作',
					tag: '要闻',
					form: '人力资源部',
					time: '2019-10-08',
					content: [
						'2019年10月1日，庆祝中华人民共和国成立70周年大会在天安门广场隆重拉开帷幕。礼炮轰鸣，歌声嘹亮，整齐的队伍，威严的军容，晴朗的天空，欢呼的人们，无不显现出这一次庆典的隆重与壮观。而在举国同庆共祝祖国70华诞的背后是成千上万保障人员倾力工作、默默奉献，成为见证这份精彩的守护者。',
						'作为国庆保障的重要力量，凝思人与国家电网、某中心单位一起在各个指挥中心全力安全保障，以自己特有的方式表达对祖国的祝福。',
						'安全保障责任重大，从9月份开始，全公司以"防风险、治隐患、保安全、迎大庆"为工作主线，技术人员分别奔赴国家电网各省调、市调、区调及某中心单位，真情服务，主动作为，积极排查消除安全隐患，确保电网、互联网运行正常，为保障电网、互联网安全运行夯实基础。确保各项庆祝活动安全优质服务，工作万无一失，为全国人民欢度佳节提供安全可靠优质的服务保障。',
						'从国网电力现场指挥部到某中心单位，凝思人总是战斗值守在工作第一线，为了保障电网、互联网的安全运行，国庆期间，他们无法与家人团聚，献给电网、互联网的却是责任与坚守。他们在岗位上用专业技能与无私付出为电网、互联网运行保驾护航，抒发着凝思人热忱地爱国情怀。',
					],
				},
				{
					id: '18',
					tittle: '国家电网有限公司项目中标快讯',
					tag: '项目',
					form: '销售部',
					time: '2019-08-26',
					content: [
						'我公司于2019年8月15日收到国家电网有限公司2019年第三次信息化设备招标采购-调度类软件标段中标通知书，涉及辽宁、冀北、河北、山东、天津、浙江、福建多家省级公司的项目。',
					],
				},
				{
					id: '17',
					tittle: '国家电网有限公司项目中标快讯',
					tag: '项目',
					form: '销售部',
					time: '2019-07-22',
					content: [
						'我公司于2019年7月22日收到国家电网有限公司2019年第二次信息化设备招标采购-调度类软件标段中标通知书，涉及华北、华东、西南三个分部和多家省级公司的项目。随着国家信息安全与国产化进程的不断推进，凝思国产安全操作系统及相关产品在国网调度、配网以及场站等各领域得到充分应用和考验，已全面支撑国家电网调度通信的业务系统。',
					],
				},
			]
		},
		{
			year: '2018',
			text: [
				{
					id: '16',
					tittle: '凝思出席2018浪潮商用机器先行者峰会',
					tag: '要闻',
					form: '人力资源部',
					time: '2018-10-19',
					content: [
						'2018年10月19日，以“卓于融通 创变自主”为主题的2018浪潮商用机器先行者峰会—POWER9饕餮盛典在北京盛大召开，浪潮商用机器有限公司、浪潮电子信息产业股份有限公司（简称“浪潮”）和国际商业机器（中国）有限公司（简称“IBM”）高层均出席了峰会，北京凝思软件股份有限公司受邀参加会议。此次峰会推出了基于POWER9处理器的全线新产品及解决方案。',
						{
							src:require('../../images/news/news20181019-01.jpeg'),
							alt:'凝思出席2018浪潮商用机器先行者峰会',
						},
						'凝思作为先进的国产安全操作系统厂商，为广大行业用户提供坚实的基础软件产品和符合技术发展趋势的平台架构设计方案，为用户业务系统构建安全、高效、稳定、可靠、可扩展的安全可控平台。凝思安全操作系统不仅友好支持了power架构产品，还在行业用户中得到了广泛应用，在某行业POWER8服务器全面安装了凝思安全操作系统，在用户实际业务环境中，结合凝思对系统的深刻理解和强大的优化能力使得平台主要性能指标达到了X86服务器的两位数字的超越。目前相关厂商也在积极探讨基于POWER9平台的合作。结合性能优越的POWER9平台，国产凝思安全操作系统将会“大有作为”。',
					],
				},
				{
					id: '15',
					tittle: '凝思软件出席2018世界物联网博览会智能电网峰会 宫敏董事长作主题报告',
					tag: '要闻',
					form: '人力资源部',
					time: '2018-09-15',
					content: [
						{
							src:require('../../images/news/news20180915-01.jpeg'),
							alt:'博览会智能电网峰会',
						},
						'2018年9月14-15日，由工业和信息化部、科学技术部和江苏省人民政府联合主办的2018世界物网博览会在无锡举行。北京凝思软件股份有限公司作为智能电网工业互联网领域的重要参与单位出席了此次博览会智能电网峰会，本届峰会以“物联世界，打造高端智能电网”为主题。',
						{
							src:require('../../images/news/news20180915-02.png'),
							alt:'博览会智能电网峰会　宫敏做主题报告',
						},
						'公司董事长、中国Linux先驱宫敏博士在峰会期间作了名为“工业互联网安全操作系统是新工业的基石”的主题报告，表示新一轮工业革命所需要的数字化、网络化和智能化，需要一个适应工业互联网环境的安全、可靠并且鲁棒（Robust）的操作系统；凝思软件会以自己的知识、经验及能力积极投入中国制造2025，推进工控安全操作系统的推广和应用，全力打造新工业的基石。',
						'会前，受工控联盟的委托，凝思软件牵头组织互联互通测试，凝思软件组织搭建测试环境并编写测试用例和测试程序，与其他厂商按照微内核操作系统标准草案进行了第二次互联互通测试，通过测试后的技术交流，各厂商加深对工业操作系统的理解，并制定标准修订和产品的研发方向。',
						'中国工程院原副院长、国家能源咨询专家委员会副主任杜祥琬院士、中国电力科学研究院名誉院长周孝信院士、天津大学精密仪器与光电子工程学院名誉院长姚建铨院士、国家电网公司家电力调度控制中心原副主任、工业控制系统产业联盟理事长辛耀中以及来自国内知名院校、科研机构、电力行业和国内主流设备供应商、电力相关企业嘉宾代表共300余人参加了此次智能电网峰会。',
					],
				},
				{
					id: '14',
					tittle: '凝思软件助力国网储能电站建设',
					tag: '项目',
					form: '销售市场部',
					time: '2018-07-06',
					content: [
						'2018年7月6日，江苏电力调度控制中心成功组织了10kV建山、10kV长旺储能电站自动发电控制（AGC）联网试验，标志着国内首批纳入电网统一协调控制的电网侧储能电站顺利并网投运。',
						'国网江苏公司于2018年开始建设包括建山、长旺、丹阳、扬中、镇江新区的8个储能电站。该项目电网侧总功率为101兆瓦，总容量为202兆瓦时，是目前世界范围内最大规模的电池储能电站项目。该项目建成后，可以为电网运行提供调峰、调频、备用、黑启动、需求响应等多种服务，充分发挥电网调峰的作用，促进镇江地区电网削峰填谷，有效缓解今年夏季镇江东部电网供电压力。',
						'本次储能电站AGC联网试验由江苏电力调度控制中心精心组织，在凝思软件、江苏电科院、中国电科院、长园深瑞、镇江供电公司、江苏综合能源服务公司、山东电工集团等单位的大力支撑下协作完成。凝思软件作为储能电站的安全操作系统厂商，中国电科院、长园深瑞作为储能电站功率控制系统厂商，共同保障储能电站系统和设备的正常运行。',
						'本次联调设定了功率试验、AGC自动调节、计划曲线运行等内容。在凝思安全操作系统的支撑及凝思现场工程师的支持保证下，经试验验证，储能电站在调节精度、响应时间、调节时间、充放电转换时间等方面均满足规范要求，部分性能优于常规的火电机组。本次联调的顺利完成为江苏电网储能电站接入提供了宝贵的工程经验，为大型电网的运行控制提供了更为丰富的调峰、调频资源。',
					],
				},
				{
					id: '13',
					tittle: '凝思软件喜获2017年度IBM优秀PowerLinux解决方案奖',
					tag: '荣誉',
					form: '销售市场部',
					time: '2018-04-11',
					content: [
						'2018年4月11日，IBM THINK论坛在中国大饭店盛大召开，本次盛会IBM展示了云平台通过多云化管理，集成了人工智能、区块链等技术帮助企业挖掘数据价值；通过容器技术、微服务等方式，帮助企业在支持云原生应用的同时保留传统应用；通过混合云环境等构件，确保核心安全。同时IBM分享了一直致力于计算力方面的研究和摸索，包括类脑计算、模拟计算和量子计算。以及包括量子叠加、量子纠缠、量子容量等在内的与量子计算相关的概念。量子计算将解决很多今天的计算机无法解决的问题，如流程优化问题、机器学习问题以及物理、化学相关的模拟问题。同时IBM也推出了多项解决方案包括认知教育、认知零食、认知制造、认知银行和认知医疗解决方案。本次论坛浪潮、兴业数金、中电数据、电科医疗、北明软件、凝思软件等合作伙伴合作推出的一系列解决方案。会上北京凝思软件股份有限公司喜获2017年度IBM优秀PowerLinux解决方案奖。',
						{
							src:require('../../images/news/news20180411-01.jpg'),
							alt:'凝思软件喜获2017年度IBM优秀PowerLinux解决方案奖',
						},
					],
				},
				{
					id: '12',
					tittle: '国家电网公司中标快讯',
					tag: '项目',
					form: '销售市场部',
					time: '2018-03-28',
					content: [
						'2018年3月23日，我公司在国家电网有限公司2018年第一次信息化设备招标采购—信息化软件及调度化软件两个标段招标中均成功中标。',
						'此次中标标志着公司产品受到国网用户的充分认可。公司需进一步做好国网信通定制国产安全操作系统及产品实施部署，以及国网调度项目操作系统国产化工作。',
					],
				},
				{
					id: '11',
					tittle: '公司被授予工业控制系统产业联盟副理事长单位证书',
					tag: '荣誉',
					form: '人力资源部',
					time: '2018-03-16',
					content: [
						'2018年3月16日，工业控制系统产业联盟第一次理事会议在北京国家会议中心正式召开。国家电网、北京凝思软件、龙芯中科、国防科技大学、新华三、海尔等50多家产学研单位出席了会议。会议表决通过了联盟章程、缴费及管理办法，颁发了联盟理事长单位、副理事长单位证书及副理事长、理事专家聘书，并汇报了联盟2018年工作计划。指导委员会由沈昌祥院士担任主任，会议表决通过辛耀中担任理事会理事长，北京凝思软件股份有限公司为副理事单位，公司董事长宫敏先生被聘为副理事长。',
						{
							src:require('../../images/news/news20180316-01.jpg'),
							alt:'公司被授予工业控制系统产业联盟副理事长单位证书',
						},
						{
							src:require('../../images/news/news20180316-02.jpg'),
							alt:'公司被授予工业控制系统产业联盟副理事长单位证书',
						},
						'工业控制系统产业联盟意在搭建政府、用户、企业、科研院所、高等院校之间的交流平台，发挥纽带与桥梁作用，促进相关主体之间的交流和深度合作，促进供需对接和知识分享，形成优势互补，有效推进我国工业控制系统产业发展，保障关键基础设施安全稳定运行，支撑中国工业健康可持续发展。',
						'北京凝思软件作为国产安全操作系统开发的践行人，一直紧跟国家发展脚步，努力研究符合国家发展安全需求的产品。近几年加强关于工业控制系统的相关研究，并于2017年正式获得《凝思工业互联网安全操作系统V1》软件著作权证书。作为基础软件厂商，积极参与开展工业控制系统产业技术、标准和产业研究，与联盟成员共同推进中国工业控制系统新发展。',
					],
				},
				{
					id: '10',
					tittle: '声明',
					tag: '公告',
					form: '人力资源部',
					time: '2018-03-12',
					content: [
						'近期发现有不法分子伪造我公司证照、冒充我公司名义，通过网络、即时通讯软件、电话等方式，以办理淘宝、京东等平台购物刷单为理由，骗取参与者资金，进行诈骗活动。',
						'为维护我公司合法权益，现郑重声明：',
						'一、 我公司从未有过刷单、代刷单等相关业务。',
						'二、 正在冒用我公司名义进行诈骗等非法活动的任何组织和个人，应立即停止此类非法行为。',
						'三、 对冒充我公司名义进行不法行为的任何组织和个人，我公司保留追究其法律责任的权利。',
						'望广大群众提高警惕，切勿向陌生账号汇款，谨防上当受骗。如自身权益受到损害，应及时向公安机关报案，维护自身权益。',
						'特此声明。',
					],
					sign: [
						'北京凝思软件股份有限公司',
					],
				},
				{
					id: '9',
					tittle: '北京凝思软件股份有限公司关于熔断和幽灵漏洞的声明',
					tag: '公告',
					form: '北京凝思软件股份有限公司',
					time: '2018-01-22',
					content: [
						'近日，Google公司Project Zero等团队相继公布了Intel、AMD和ARM CPU的两组漏洞，分别是熔断漏洞（Meltdown）与幽灵漏洞（Spectre）。这两组漏洞来源于芯片厂商为提高CPU性能而引入的两种特性：乱序执行（Out-of-Order Execution）和预测执行（Speculative Execution）。熔断漏洞对应CVE-2017-5754， 幽灵漏洞对应CVE-2017-5753和CVE-2017-5715。',
						'北京凝思软件股份有限公司针对熔断和幽灵漏洞制作了内核补丁。通过KPTI（内核页表隔离，Kernel Page Table Isolation）机制消除熔断漏洞，通过IBRS（Indirect Branch Restricted Speculation，间接分支限制性预测）和IBPB（Indirect Branch Prediction Barriers，间接分支预测屏障）机制消除幽灵漏洞。',
						'Intel也发布了Linux处理器微代码数据文件，用于修复Intel CPU中的熔断和幽灵漏洞。',
						'凝思安全操作系统的用户可通过综合运用凝思操作系统内核补丁和Intel的CPU微代码补丁的方式消除熔断和幽灵漏洞。',
						'凝思需要特别提示用户的是，无论操作系统内核补丁和微代码补丁均会引起系统性能下降。应用不同的基准测试工具，操作系统补丁引起的性能下降范围为2%-14%，CPU微代码补丁引起的性能下降范围为1%-18%。而且，幽灵和熔毁漏洞被利用后都只能读取敏感信息，不能修改内存信息，也不能执行恶意代码。同时，熔断和幽灵漏洞均为本地攻击，而非远程网络攻击，必须具备本机执行的条件。幽灵漏洞通过诱骗指令执行的方式泄漏信息，使被攻击的进程受到非授权访问，缺少特权升级能力，而且需要针对被攻击程序的软件环境进行定制，利用难度相对较大，目前尚未出现基于幽灵漏洞的信息泄露事件。熔断漏洞虽更容易被触发利用，但仅在虚拟机、云计算、Docker和LXC等共享环境下，通过宿主机操作系统窃取其它租户的敏感信息，适用场景和破坏程度有限。',
						'为此，凝思安全操作系统用户在进行操作系统补丁升级前，建议咨询凝思的专业操作系统安全人员，根据用户业务系统的应用场景和运行模式，综合评估补丁升级的必要性。凝思也会持续跟踪Spectre和Meltdown漏洞的进展，为用户提供更优化的解决方案。',
					],
				},
			]
		},
		{
			year: '2017',
			text: [
				{
					id: '8',
					tittle: '凝思软件董事长宫敏博士荣获“功勋程序员”荣誉证书',
					tag: '人物',
					form: '人力资源部',
					time: '2017-11-09',
					content: [
						'2017年11月9日，首届“全球程序员节”在西安市开幕。全球程序员与来自海内外的软件行业专家、国家部委领导、“一带一路”沿线国家代表、国内“一带一路”门户城市政府、知名软件企业、软件创新企业、高校和科研机构等各界嘉宾荟聚西安。',
						"news20171109img1.jpg 凝思软件董事长宫敏博士荣获'功勋程序员'荣誉证书",
						{
							leftsrc:require('../../images/news/news20171109-01.jpg'),
							leftalt:`凝思软件董事长宫敏博士荣获'功勋程序员'荣誉证书`,
							rightsrc:require('../../images/news/news20171109-02.jpg'),
							rightalt:`凝思软件董事长宫敏博士荣获'功勋程序员'荣誉证书`,
						},
						'为表彰我国历代程序员为中国软件产业和社会发展所做出的贡献，首届全球程序员节评选出了王永民、严援朝、宫敏、求伯君、雷军、张小龙、丁磊、王小川、张一鸣、印奇共10位功勋程序员，北京凝思软件股份有限公司董事长宫敏博士荣誉当选。中国电子信息行业联合会常务副会长曲维枝、工业和信息化部信息化和软件服务业司司长谢少锋、陕西省工业和信息化厅厅长蒋跃、西安市委常委、副市长高杲共同为功勋程序员代表颁奖。',
						{
							src:require('../../images/news/news20171109-03.jpg'),
							alt:`凝思软件董事长宫敏博士荣获'功勋程序员'荣誉证书"`,
						},
						'软件产业已经成为推动科技创新、促进经济转型、改善社会服务的重要力量，成为建设制造强国和网络强国的重要支撑。活动期间，围绕“程序员精神”这一主题,我公司董事长--中国自由软件程序员、中国自由软件库联合创始人宫敏博士，在会上发表演讲，与不同时代、不同地域的程序员代表们共同就程序员精神与程序员的未来进行了深入的讨论。',
						{
							src:require('../../images/news/news20171109-04.jpg'),
							alt:`凝思软件董事长宫敏博士荣获'功勋程序员'荣誉证书"`,
						},
					],
				},
				{
					id: '7',
					tittle: '凝思安全操作系统成功入围中央国家机关采购项目',
					tag: '项目',
					form: '销售市场部',
					time: '2017-10-31',
					content: [
						'2017年10月31日，中央国家机关采购中心发布了《中央国家机关2017年软件协议供货采购项目中标公告》。凝思安全操作系统成功入围中央国家机关采购第二包“Linux服务器操作系统”。',
					],
				},
				{
					id: '6',
					tittle: '国产操作系统结合Power Linux服务器助力行业基础设施转型升级',
					tag: '要闻',
					form: '销售市场部',
					time: '2017-09-04',
					content: [
						'IBM公司于2017年9月1日在北京主办了架构人机同行创行者高峰论坛，来自金融、医疗、制造等领域的企业、合作伙伴参加此次高峰论坛，IBM公司将多年来的研究成果、全新的技术工具、行业实践经验与参会者分享，前沿技术与落地方案并行。业界单位代表也进行了专题演讲，一起分享先进理念和前沿技术。',
						'北京凝思软件股份有限公司作为特邀单位参加了此次盛会，并由公司副总经理彭志航做了《国产操作系统结合Power Linux服务器助力行业基础设施转型升级》专题演讲，彭总不但分享了凝思软件多年来一直专注于国产安全操作系统的研制和推广，为国内大型行业用户提供坚实的底层软件平台和符合技术发展趋势的平台架构设计，为用户构建安全、高效、稳定、可靠、可扩展的业务系统的经验，同时也分享了凝思软件与IBM公司一起齐心协力努力满足行业用户新需求，大幅提高了单机整体性能和安全性、降低了功耗、节省了空间的经验，演讲获得了热烈反响和好评。',
						'凝思软件秉承研发先进产品的理念，致力与世界前沿技术同步发展！',
						{
							src:require('../../images/news/news20170904-01.jpg'),
							alt:'国产操作系统结合Power Linux服务器助力行业基础设施转型升级',
						},
					],
				},
				/*{
					id: '5',
					tittle: '核高基专项课题立项及中央财政资金预算获正式批复',
					tag: '项目',
					form: '人力资源部',
					time: '2017-08-15',
					content: [
						'2017年8月11日，接工信部核高基重大专项实施办公室通知，我公司申请的工业互联网、物联网安全操作系统产业化及规模化应用课题已获得正式批复，配套财政部下达的核高基重大专项2017年中央财政资金预算，用于课题实施相关工作。',
						'本专项课题由北京凝思软件股份有限公司作为牵头单位，中国人民解放军国防科技大学、国电南瑞科技股份有限公司作为联合单位共同申报。项目主要研究内容是面向电力、石油、交通、水利等工业领域的应用需求，适应工控系统对可靠性、实时性、安全性、连续性的特殊要求，基于工控领域主流CPU和安全可控CPU芯片，突破支持抢先的实时微内核、支持抢先的宏内核实时功能增强、国产处理器适配与优化、网络功能增强、安全功能增强、可靠性增强、存储管理优化等关键技术，形成工业互联网安全操作系统，并实现规模化应用。',
						'本专项课题实施周期为2017-2019年度，公司将严格按照《国家科技重大专项（民口）资金管理办法》有关规定执行，加强资金管理，严格专款专用。同时，认真落实承诺的其他渠道资金，确保及时、足额到位，与中央财政下达的专项资金统筹使用，保证课题顺利实施。',
					],
				},*/
				{
					id: '4',
					tittle: '凝思操作系统成功入围中直机关2017年协议供货采购项目',
					tag: '项目',
					form: '销售市场部',
					time: '2017-08-02',
					content: [
						'北京凝思软件股份有限公司（“凝思安全操作系统V6.0”、“凝思操作系统通用服务器版V6.0”）成功入围《中直机关2017年货物类产品协议供货采购项目》。中标公告链接：<a style="color:#0072ce;text-decoration:none;" href="http://zzcg.ccgp.gov.cn/zzcg/cgxx/jggg/webinfo/2017/07/1500433514783728.htm">http://zzcg.ccgp.gov.cn/zzcg/cgxx/jggg/webinfo/2017/07/1500433514783728.htm</a>',
					],
				},
				{
					id: '3',
					tittle: '国家发改委信息安全专项-凝思安全操作系统产业化项目验收会圆满成功',
					tag: '项目',
					form: '销售市场部',
					time: '2017-06-06',
					content: [
						'2017年6月6日下午，由北京市发展和改革委员会主持的国家发改委信息安全专项-凝思安全操作系统产业化项目验收会在北京举行，刘澎、陈钟、翟起滨、高昆仑、李新明、梁丽等知名专家出席，对凝思安全操作系统产业化项目进行验收，并提出指导性意见和建议。',
						{
							src:require('../../images/news/news20170606-01.jpg'),
							alt:'凝思安全操作系统产业化项目验收圆满成功',
						},
						'凝思安全操作系统是由北京凝思软件股份有限公司自主研发。凝思软件成立于2016年，致力于研发及生产高安全等级的国产安全操作系统及功能增强软件。经过多年研发，凝思安全操作系统成为国内安全等级高、支持体系结构多的自主知识产权操作系统。目前，国家电网、南方电网、中国铁路总公司、三大电信运营商、中央办公厅等党和国家政府机构大量采用了凝思安全操作系统，为其网络信息安全保驾护航。',
						{
							src:require('../../images/news/news20170606-02.jpg'),
							alt:'凝思安全操作系统产业化项目验收圆满成功',
						},
						'随着党中央对网信事业发展进步的高度重视，预计5年内国产安全操作系统在国家重要机构、公共安全基础设施、涉密信息系统等领域将会逐渐取代国外操作系统。在十八届五中全会就专门提出了“实施网络强国战略”。近年来，互联网基础环境不断优化，网络空间法治化快速推进，网络空间日渐清朗，互联网国际话语权日益提升，国家自主研发的安全操作系统是发展的必然趋势，凝思安全操作系统的诞生并得到相关部门的应用，也正预示这一天的即将到来。',
						'在国家政策支持的大背景下，凝思保持着傲人的业绩，中国三大电信运营商、铁路总公司、国家电网、南方电网及电力公司的应用，说明各行业部门安全意识的增强，采用国家自主研发的操作系统，让网络信息安全不再受制于人；另一方面则着实体现出凝思安全操作系统的优势，大大降低用户应用成本，而且安全有保证。',
						'凝思安全操作系统在国家电网等行业的大规模应用也表明，国产Linux安全操作系统发展已经逐渐趋于成熟，而凝思安全操作系统产业化项目大大超额完成了立项指标要求,顺利通过国家验收，代表凝思安全操作系统在国内市场取得的重要进展，实现了基于国产化环境的研发适配、综合集成和信息安全防护能力提升。',
						'中国工程院院士倪光南也表示，“国产系统首先将在政府和重要领域中推广，然后推广到一般市场。”这也是凝思的发展步伐，如今凝思安全操作系统已经应用到国家重要部门，而且反应非常好，这不仅是国家自主安全系统的骄傲，也意味着“网络强国”的崛起。',
						'我们坚信，在不久的将来，国产安全操作系统，不仅仅是政府部门的标配，而且也会被广大用户所接受，以Windows为代表的国外操作系统，将逐渐被取代，没有人愿意自己的隐私处于‘裸奔’状态。',
					],
				},
				{
					id: '2',
					tittle: '凝思软件取得三项软件著作权证书',
					tag: '资质',
					form: '人力资源部',
					time: '2017-05-23',
					content: [
						'近日，北京凝思软件股份有限公司申请的凝思云计算平台软件、凝思大数据平台软件、凝思多网卡并行数据零拷贝与高速分发平台软件三项软件著作权已顺利获得国家版权局授权批准。',
						'凝思依托良好的社会经济环境，过硬的市场拓展技能，自主研发的开发能力，优秀精良的产品品质，健全的售后服务机制，近年来逐步扩大企业规模，赢得了客户的一致好评与市场。公司自成立以来，非常重视科技创新、产品创新，注重自主知识产权的保护。此次三项软著的取得，有助于提高公司产品稳定性及生产效率，有利于加强公司自主知识产权保护，充分发挥了公司在计算机软件领域的技术优势，推动了公司科技创新的步伐，提升了企业的核心竞争力。',
						{
							src:require('../../images/news/news20170523-01.jpg'),
							alt:'凝思云计算平台软件V1.0',
						},
						{
							src:require('../../images/news/news20170523-02.jpg'),
							alt:'凝思大数据平台软件V1.0',
						},
						{
							src:require('../../images/news/news20170523-03.jpg'),
							alt:'凝思多网卡并行数据零拷贝与高速分发平台软件V1.0',
						},
					],
				},
			]
		},
		{
			year: '2016',
			text: [
				{
					id: '1',
					tittle: '北京凝思软件股份有限公司成立',
					tag: '要闻',
					form: '新闻中心',
					time: '2016-02-29',
					content: [
						'北京凝思软件股份有限公司由“中国Linux先行者”宫敏博士于2016年2月29日创办，以“实现国家大型基础设施行业基础软件国产化安全化”为使命，致力于研发生产“高安全性、高性能、高稳定性”的基础操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行。',
					],
				},
			]
		},
		/*
		{
			year: '2015',
			text: [
				{
					id: '86',
					tittle: '应邀参加由银监会主办的2015年中国银行业年会',
					tag: '要闻',
					form: '新闻中心',
					time: '2015-12-03',
					content: [
						'12月3日，北京凝思科技有限公司应邀参加了由银监会主办的中国银行业信息科技风险管理2015年会，会议主题为“科技引领创新，开放共享未来”。会议期间，与会领导参观了会场中凝思科技“用国产安全操作系统构建新型IT基础设施”成果展示区，对凝思的产品及解决方案给予极高的赞誉。',
						{
							src:require('../../images/news/news20151203-01.jpg'),
							alt:'凝思安全操作系统产业化项目验收圆满成功',
						},
						{
							src:require('../../images/news/news20151203-02.jpg'),
							alt:'凝思安全操作系统产业化项目验收圆满成功',
						},
					],
				},
				{
					id: '85',
					tittle: '参加第八届中国保险业信息化发展高峰论坛',
					tag: '要闻',
					form: '新闻中心',
					time: '2015-07-30',
					content: [
						'2015年7月30日，《金融电子化》杂志社在青海西宁市举办了以“互联网+保险”为主题的“第八届全国保险业信息化发展高峰论坛”。与会代表有中保、人保、人寿、人保财产、太平洋、平安等保险公司主管科技的领导以及工信部软件云计算中心领导。大家围绕本次的会议议题，探讨了如何在当今社会更好的利用科技手段进行保险行业的发展及革新。凝思科技出席了本次会议。',
					],
				},
				{
					id: '84',
					tittle: '国家电网公司西南分中心工程项目再传捷报',
					tag: '项目',
					form: '新闻中心',
					time: '2015-07-06',
					content: [
						'国家电网公司西南电力调控分中心智能电网调度控制系统新建工程调度软件的公开招标项目我公司再度中标。此项目中，我公司将为新成立的西南电力调控分中心提供凝思安全操作系统、凝思磐石双机热备系统软件和凝思多路径软件等产品。',
						'至此，凝思安全操作系统及功能模块软件已全面部署在国家电力调度控制中心、华北、华东、华中、东北、西北、西南六大电网调度分中心和包括北京、上海、天津、冀北、河北、山东、山西、江苏、浙江、安徽、福建、吉林、重庆、湖南、青海、四川、江西和蒙东在内的十八个省、直辖市电力公司和八十三个地市电力公司的电力自动化调度系统中。',
					],
				},
				{
					id: '83',
					tittle: '凝思科技参加2015年智能电网展',
					tag: '要闻',
					form: '新闻中心',
					time: '2015-06-17',
					content: [
						'2015年智能电网展在6月15日至17日在国家会议中心举行。我公司在展会上展示了凝思安全操作系统、凝思云平台、凝思网络冗余容错解决方案、凝思磐石双机热备系统，并介绍了公司参与国家电网项目的情况以及公司的产品和为电网定制的解决方案。展会期间参展人员接待了来自国网调度系统、南网调度系统、中国电科院、广东省电力设计院的专家和领导。',
					],
				},
				{
					id: '82',
					tittle: '凝思科技与中国移动通信集团公司成功签约',
					tag: '要闻',
					form: '新闻中心',
					time: '2015-05-15',
					content: [
						'北京凝思科技有限公司凭借优秀的产品、丰富的工程经验以及完善的售后服务体系，成功的与中国移动通信集团公司就某安全加固项目成功合作并签约。',
						'至此，北京凝思科技有限公司的凝思安全操作系统，成功的部署到运营商全国31个省份以及直辖市并安全稳定的支持了所有硬件以及上层应用。凝思安全操作系统在成功支撑硬件及应用的前提下，通过自身的安全特性成功的解决了运营商在线运行中遇到的安全威胁。通过本项目的成功实施，充分展示了凝思安全操作系统对软件硬件的良好兼容性以及大规模网络数据处理能力和全国全网性工程支持能力。',
					],
				},
				{
					id: '81',
					tittle: '我公司被评为海淀区2014年度纳税信用A级企业',
					tag: '荣誉',
					form: '新闻中心',
					time: '2015-04-29',
					content: [
						'2015年4月29日,我公司被北京市海淀区地方税务局评为2014年度纳税信用A级企业，税务机关对纳税信用评价为Ａ级的纳税人将给予向社会公告、增值税发票领取和调整用量、提供绿色通道等多项涉税优惠服务。',
					],
				},
				{
					id: '80',
					tittle: '公司取得一种网络连接通信的方法和装置新专利',
					tag: '荣誉',
					form: '新闻中心',
					time: '2015-04-29',
					content: [
						'日前，公司取得一种网络连接通信的方法和装置新专利。',
						'随着互联网技术的发展和硬件性能的提高，针对高性能、大规模网络应用的开发需求也越来越多。虽然硬件技术有了飞速发展，但是并不能解决高性能、大规模网络应用中的所有问题。这主要表现在两个方面：第一，虽然通过硬件技术可以获得性能的提高，但是高性能的硬件却价格不菲，不利于大规模部署；第二，硬件的性能虽然高，但是未经优化的软件系统架构往往不能充分发挥硬件性能，许多网络服务器在处理千万级客户的并发访问请求时，系统容易出现性能的非线性下降，严重的将导致服务器系统的崩溃（这往往被称为C10K问题）。',
						'为了提高系统的网络吞吐性能和系统的网络连接维护能力，在硬件和驱动、操作系统以及应用程序等方面均有多种技术实现。',
						'在CPU和计算机体系结构方面，多路多核的计算机架构已经被广泛应用。在网卡硬件和驱动方面，针对多核和多路处理器的广泛应用，网卡硬件已经可以提供双端口和四端口的以太网接入。MSI-X技术的发展也使得网卡硬件能够向多个CPU核心发送中断信号。在数据传输方面，除支持DMA外，还支持直接缓存访问（DCA）技术。DCA技术可以使处理器提前从内存读取数据，减少缓存不命中的机会。除此之外，为了防止数据缓冲区溢出，还发展了多队列接收技术；为了防止CPU对网卡硬件中断的过度响应，发展了中断延迟技术等。',
						'断技术以及零拷贝（Zero Copy）技术等。 在应用程序开发方面，有内核空间与用户空间的数据共享技术，处理网络连接的高性能epoll开发接口，多线程的实时调度策略技术以及CPU亲和技术等。',
						'虽然采用上述种种技术可以在很大程度上提高系统的网络吞吐性能和网络连接维护能力，但这些技术彼此之间缺乏有效联系，在系统中很难形成有效配合，从而使得系统的网络性能仍然不能得到较大限度的发挥。',
						'本发明公开了一种支持千万级并发网络连接通信的方法，包括：S1.在多路多核操作系统中增加多个网卡动态调度的中断分配；S2.数据包被复制到所述操作系统CPU内核的内存空间中；S3.根据操作系统对多个网卡中断的动态调度与分配结果，向不同的CPU核心发出硬件中断信号；S4.根据分配的中断执行硬件中断处理程序,网络协议栈线程对数据包进行协议解析；S5.数据包解析完成后放到内核的内存空间中，由操作系统调度相应的应用程序处理数据包。本发明还公开了一种支持千万级并发网络连接通信的装置及系统。本发明使得并发的网络连接数据在计算机系统中能够以并行化方式进行处理，从而达到支持千万级并发网络连接通信的目的。实施本发明的一种支持千万级并发网络连接通信的方法和装置，具有以下有益的技术效果：使得并发的网络连接数据在计算机系统中能够以并行化方式进行处理，从而达到支持千万级并发网络连接通信的目的。',
					],
				},
				{
					id: '79',
					tittle: '公司取得一种中文习惯地址解析方法新专利',
					tag: '荣誉',
					form: '新闻中心',
					time: '2015-01-07',
					content: [
						'日前，公司取得一种中文习惯地址解析方法新专利。',
						'DNS（Domain Name System、域名系统）是因特网的一项重要服务，其分布式数据库用于记录域名和IP地址之间的映射关系，使人不用记住能够被服务器直接处理的IP地址，直接通过域名的方式访问互联网资源，目前，域名仅限于ASCII（American Standard Code for Information Interchange、美国信息互换标准代码）字符的子集，使很多地方语言无法正确表示他们的名字和单词，通过根据RFC3492标准制定的Punycode编码系统，将地方语言所采用的Unicode字符串映射为有效的DNS字符集。',
						'在编排中文域名时，DNS系统默认按照英文地址的编排习惯编排，以“中国教育部网站”为例，其域名为“www.moe.edu.cn”，对应的中文习惯域名为“网站.教育部.教育.中国”，这种小地址在前、大地址在后的编排方式不符合中国地址的编排习惯，从而使用户体验降低。',
						'本发明提出一种中文习惯地址解析方法，包括步骤：接收查询名为中文地址顺序的查询报文；转置查询名顺序为标准地址顺序；在本地记录中查找域名信息；若本地记录中不存在域名信息，再转置查询名顺序为中文地址顺序，发送递归查询报文给下一服务器，由下一服务器完成后续解析过程；若本地记录中存在域名信息，再转置查询名顺序为中文地址顺序；发送查询结果报文给客户端。本发明可实现对中文习惯的网址进行有效解析，对于大多数中国人来说，网址输入按域名范围从大到小排列更符合中国人的习惯，在地址栏中输入中文习惯的网址不仅方便，而且更容易记忆，从而加速中文域名的推广和应用。',
						'相较于现有技术，本发明具有如下有益结果：本发明可以实现对中文地址习惯的网址进行有效解析，对于大多数中国人来说，网址输入按域名范围从大到小排列更符合中国人的习惯，在地址栏中输入中文地址习惯的网址不仅方便，而且更容易记忆，从而加速中文域名的推广和使用。',
					],
				},
			]
		},
		{
			year: '2014',
			text: [
				{
					id: '78',
					tittle: '公司取得一种网络数据及其偏移量设置方法新专利',
					tag: '荣誉',
					form: '新闻中心',
					time: '2014-10-08',
					content: [
						'日前，公司取得一种网络数据及其偏移量设置方法新专利。',
						'随着互联网技术的发展和硬件性能的提高，针对高性能、大规模网络应用的开发需求也越来越多。',
						'虽然硬件技术有了飞速发展，但是并不能解决高性能、大规模网络应用中的所有问题。如：操作系统内核完成数据包的处理后，会将数据包复制到用户的内存空间中，这种内存的复制操作会占用大量CPU运算资源。还有，在NUMA（Non Uniform Memory Access Achitecture）架构多路多核计算机系统中，每路CPU均有独立的内存空间，如有两个内存空间，如果在两路CPU的内存空间之间进行数据的复制操作，性能将更低。虽然应用程序可以在多个CPU核心上并行化运行，但是应用程序对数据包进行处理的过程仅依靠操作系统进行调度。操作系统会根据系统当前的运行状况，将应用程序调度到合适的CPU核心上运行。这种调度可能造成CPU硬件中缓存的程序执行代码失效，使CPU频繁缓存程序执行代码，造成性能下降；另一方面，如果应用程序与要处理的数据包不在同一路CPU的内存空间中，应用程序对数据包的处理性能也将降低。 为了解决以上的技术问题，本发明提供一种网络数据缓冲区及其偏移量设置方法。',
						'本发明公开了一种网络数据缓冲区，包括与应用程序单元相连的多个缓冲区，所述的缓冲区包括读缓冲区以及写缓冲区，所述的读缓冲区用于存储接收到的网络数据，所述的写缓冲区用于存储要发送到网络的数据，接收到的网络数据会被保存到读缓冲区中，应用程序单元可直接读取该缓冲区中的数据，应用层发送数据时，将数据写入写缓冲区中，并由应用程序单元调度数据的发送。本发明开辟数据缓冲区，避免了数据在内核空间与用户空间之间的复制操作，提高了系统对网络数据包的处理性能。',
						'实施本发明的一种网络数据缓冲区及其偏移量设置方法，具有以下有益的技术效果：开辟数据缓冲区，避免了数据在内核空间与用户空间之间的复制操作，提高了系统对网络数据包的处理性能。',
					],
				},
				{
					id: '77',
					tittle: '公司取得一种系统层安全DNS防护方法新专利',
					tag: '荣誉',
					form: '新闻中心',
					time: '2014-09-17',
					content: [
						'日前，公司取得一种系统层安全DNS防护方法新专利。',
						'DNS（Domain Name System、域名系统）是因特网的一项重要服务，由域名解析服务软件和分布式数据库组成，分布式数据库用于保存域名与IP地址间的映射关系，从而，使得用户不需要记住能够被服务器直接识别、处理的IP地址，更加方便地以域名的方式访问互联网资源，作为因特网一个重要的组成部分，DNS一旦出现问题，将会导致诸多因特网服务的不可获得。DNS是因特网的基础，对DNS的攻击将对整个因特网造成严重影响，DNS面临的安全问题主要包括DNS欺骗、拒绝服务攻击、分布式拒绝服务攻击以及缓冲区漏洞溢出攻击。',
						'本发明提出一种可以有效保证系统层安全DNS防护方法，包括如下步骤：执行强制运行控制模块、强制能力控制模块、强制访问控制模块；通过所述强制运行控制模块设定DNS服务进程不能运行根目录下的任何程序；通过所述强制能力控制模块设定DNS服务进程以普通用户运行，只赋予绑定53端口的权限；通过所述强制访问控制模块设定除域名服务管理员之外的其他用户对DNS服务进程仅具有可读权限。',
						'相较于现有技术，本发明具有如下有益结果：通过使DNS服务进程不能运行根目录下的程序使得恶意代码不能被执行，可有效阻止攻击者对DNS服务器的攻击；通过强制能力控制模块设定DNS服务进程以普通用户运行，使得攻击者不能利用DNS服务器提升自己的权限而成为超级用户，进而威胁到整个系统，同时，也不会影响到DNS服务器对外提供服务；通过强制访问控制模块设定除安全管理员之外的其他用户对DNS服务进程仅具有可读权限，可以防止管理员及其它用户篡改区文件记录，进一步保证DNS服务器的安全。',
					],
				},
				{
					id: '76',
					tittle: '凝思科技2014年再获电网项目大单',
					tag: '项目',
					form: '新闻中心',
					time: '2014-08-11',

					content: [
						'在刚刚结束的国家电网公司2014年调度自动化系统软件采购-公开竞谈项目中，我公司再次中标，将为19个包中的12个包提供凝思安全操作系统、凝思磐石双机热备系统软件和凝思多路径软件产品，其中操作系统采购数量1818套。',
						'在此前进行的2014年江苏省电力公司智能电网调度技术支持系统建设工程项目物资采购招标中，我公司承担了江苏省十三个地市全部项目的操作系 统供货任务。凝思科技将向这十三个地市提供1700多套凝思安全操作系统和30余套双机热备系统软件。',
						'截至目前，我公司在国家电网中销售和部署的操作系统数量已超过一万套。',
					],
				},
				{
					id: '75',
					tittle: '市场呼唤国产操作系统',
					tag: '要闻',
					form: '新闻中心',
					time: '2014-04-11',
					content: [
						'最近，微软公司的XP操作系统停止服务一事在国内市场引起了广泛讨论。用户该如何应对这一情况和以后还可能发生的同类事件？北京青年报4月10日刊登新华社记者的一篇题为《XP停服呼唤国产替代》的文章。文章列举了包括凝思在内的几家国内操作系统厂商的产品，指出国产系统在广大用户的使用中不断完善，会越来越好。',
					],
				},
				{
					id: '74',
					tittle: '中标南网OS2网级主站（一期）建设项目',
					tag: '项目',
					form: '新闻中心',
					time: '2014-04-02',
					content: [
						'日前，在南方电网公司OS2网级主站（一期）建设项目的招标中，南京南瑞继保电器有限公司成功中标。根据技术招标书“第2册技术规范书”中对“运行关键应用的服务器，以及网络边界处的通信网关，Web服务器等，应该使用安全操作系统或对系统进行安全加固”的要求，南瑞继保公司选择使用凝思安全操作系统。这是南方电网公司在其一体化电网运行智能系统中采用国产操作系统，这充分表明了市场对国产操作系统的认可和信任。',
					],
				},
				{
					id: '73',
					tittle: '公司取得资源控制软件著作权登记证书',
					tag: '资质',
					form: '新闻中心',
					time: '2014-01-27',
					content: [
						'日前，凝思资源控制软件取得了著作权证书。该软件可提高系统的可用性和可维护性，现已应用在国家电网调度自动化系统当中。',
					],
				},
			]
		},
		{
			year: '2013',
			text: [
				{
					id: '72',
					tittle: '公司取得多路径软件著作权登记证书',
					tag: '资质',
					form: '新闻中心',
					time: '2013-12-15',
					content: [
						'日前，凝思多路径软件取得了著作权证书。该软件可提高系统的可用性和可维护性，现已应用在国家电网调度自动化系统当中。',
					],
				},
				{
					id: '71',
					tittle: '凝思再获国家电网公司大单',
					tag: '项目',
					form: '新闻中心',
					time: '2013-08-26',
					content: [
						'2013年8月中旬，国家电网公司第四次“安全操作系统竞争性谈判”结果公布，凝思在评标过程中综合排名前列，获得60％的市场份额。继双机热备系统之后，多路径软件也进入了集采目录。含本次的中标结果，凝思在国家电网公司的总装机量已超过7000套。凝思安全操作系统广泛部署于各类机架服务器、刀片集群、图形工作站和安全传输类设备，全面支撑了智能电网调度系统实时监控与预警、调度管理、调度计划和安全校核4大类26种专业应用。',
					],
				},
				{
					id: '70',
					tittle: '凝思安全操作系统V6.0获四级销售许可证',
					tag: '资质',
					form: '新闻中心',
					time: '2013-04-22',
					content: [
						'2013年4月19日，凝思安全操作系统Ｖ６.０（四级）获得公安部公共信息网络安全监察局颁发的销售许可证书。',
					],
				},
				{
					id: '69',
					tittle: '凝思参加2013国防信息化装备展',
					tag: '要闻',
					form: '新闻中心',
					time: '2013-04-04',
					content: [
						'凝思于2013年4月1—3日参加了2013国防信息化装备展。参展商规模达300家。参观者众多，包括总参、总后、公安、航天、中电等行业客户都对凝思安全操作系统及其一体机非常感兴趣。凝思参展员工给他们做了详细讲解，并提供了测试盘。',
					],
				},
			]
		},
		{
			year: '2012',
			text: [
				{
					id: '68',
					tittle: '信息安全专项项目获国家发改委批准',
					tag: '项目',
					form: '新闻中心',
					time: '2012-06-30',
					content: [
						'2012年6月，凝思所报2011年信息安全专项项目申请“凝思安全操作系统产业化项目”已经得到国家发改委批准，并同意将此项目列入2011年国家高科技产业发展项目计划。',
					],
				},
				{
					id: '67',
					tittle: '中标国家电网2012年软件协议库存项目',
					tag: '项目',
					form: '新闻中心',
					time: '2012-04-10',
					content: [
						'2012年4月6日，《国家电网公司2012年协议库存（软件类）》新鲜出炉，我公司”凝思安全操作系统”在连续三年高市场份额中标国网采集项目之后，再次以”操作系统”分项的中标国调2012年软件协议库存项目。项目包含天津、河北、山东、上海、福建、江西、冀北、安徽、东北、蒙东等十多个单位，标志着凝思产品全面获得国网公司”智能调度技术支持系统”项目建设的高度认可。',
					],
				},
			]
		},
		{
			year: '2011',
			text: [
				{
					id: '66',
					tittle: '中标国家电网安全操作系统实施项目',
					tag: '项目',
					form: '新闻中心',
					time: '2011-04-26',
					content: [
						'2011年，凝思中标国家电网公司“智能电网调度技术支持系统”8个地区的安全操作系统实施项目：国家电网公司、河北省电力公司、上海市电力公司、西北电网有限公司、安徽省电力公司、浙江省电力公司、吉林省电力有限公司，山西省电力公司。',
					],
				},
				{
					id: '65',
					tittle: '凝思磐石双机热备系统软件获著作权登记证书',
					tag: '资质',
					form: '新闻中心',
					time: '2011-04-26',
					content: [
						'2011年4月18日，公司获得凝思磐石双机热备系统软件V1.0版的著作权登记证书。',
					],
				},
			]
		},
		{
			year: '2010',
			text: [
				{
					id: '64',
					tittle: '我公司操作系统在国家电网规模部署',
					tag: '要闻',
					form: '新闻中心',
					time: '2010-08-31',
					content: [
						'2010年，凝思成功中标国家电网公司智能电网调度一体化系统安全操作系统竞谈标段。同年，凝思磐石安全操作系统全面部署于国家电网调度系统的主备调项目。共计20家单位如下：国调、华东网调、华中网调、华北网调、东北网调、西北网调等主备调系统及吉林、黑龙江、辽宁、河北、山东、山西、江苏、浙江、福建、江西、四川、重庆、北京、天津、衡水、苏州等省地市调度系统。同时，凝思安全操作系统在国家电网特高压项目、风电等新能源项目、国家电网信息中心安全装置项目都成功应用取得新的突破。',
					],
				},
				{
					id: '63',
					tittle: '美国国家工程院院士Richard Stallman先生到访凝思',
					tag: '要闻',
					form: '新闻中心',
					time: '2010-08-15',
					content: [
						'2010年8月10日，受凝思总经理宫敏博士的邀请，Richard Stallman（美国自由软件创始人、自由软件基金会的创立者、美国国家工程院院士）专程来凝思访问。Richard Stallman先生与宫敏总经理就自由软件的发展现状及前景进行了交流与展望，双方一致看好自由软件的未来发展前景。随后，Richard Stallman给凝思员工做了精彩的演讲。',
					],
				},
				{
					id: '62',
					tittle: '凝思中标国家电网公司集中招标采购项目',
					tag: '项目',
					form: '新闻中心',
					time: '2010-03-31',
					content: [
						'2010年3月，国家电网公司集中招标采购“智能电网调度技术支持安全操作系统”，根据采购工作组的评审结果，经国家电网公司招标领导小组批准，“凝思安全操作系统V4.2”被确认为包1安全操作系统的中选人。',
					],
				},
				{
					id: '61',
					tittle: '凝思总经理宫敏博士当选共创软件联盟副理事长',
					tag: '人物',
					form: '新闻中心',
					time: '2010-03-12',
					content: [
						'2010年3月5日，中国软件行业协会共创软件分会为表彰长期以来对其工作的支持、配合所做出的突出贡献，授予凝思优秀会员单位奖，并经共创软件联盟理事会选举，宫敏当选为副理事长，凝思为副理事长单位。',
					],
				},
			]
		},
		{
			year: '2009',
			text: [
				{
					id: '60',
					tittle: '我公司安全服务器通过发改委信息安全专项项目验收',
					tag: '项目',
					form: '新闻中心',
					time: '2009-12-31',
					content: [
						'2009年12月29日在自动化大厦13层第一会议室，北京市发改委主持召开了“凝思磐石安全服务器系统系列产品验收报告会”。会议由发改委费处长和验收专家小组组长倪光南院士主持。通过专家认真评议，验收小组一致认为，该项目完成了信息安全专项项目所要求的产业化目标，符合验收条件，同意通过验收。',
					],
				},
				{
					id: '59',
					tittle: '宫敏博士入选《负笈海外汇聚中华》大型画册',
					tag: '人物',
					form: '新闻中心',
					time: '2009-09-30',
					content: [
						'2009年9月24日，北京市海外学人工作三十年回顾“负笈海外汇聚中华”大型画册发布仪式举行。我司宫敏总经理作为海外留学回国人员的杰出代表入选画册中。',
					],
				},
				{
					id: '58',
					tittle: '凝思获国家第三批高新企业认定',
					tag: '荣誉',
					form: '新闻中心',
					time: '2009-07-28',
					content: [
						'2009年7月，公司正式获得国家第三批高新企业认定，今后将享受国家对高新技术企业的一系列优惠政策，包括人才引进、税收优惠等。',
					],
				},
				{
					id: '57',
					tittle: '我公司产品列入中央政府采购目录',
					tag: '要闻',
					form: '新闻中心',
					time: '2009-04-15',
					content: [
						'2009年4月10日，中央国家机关2009年计算机等产品政府集中采购协议供货项目 （总第十四期）中标公告发布。凝思安全操作系统和凝思磐石安全服务器系统顺利中标，列入2009年中央政府采购目录。',
					],
				},
				{
					id: '56',
					tittle: '我公司产品列入北京市自主创新产品目录',
					tag: '要闻',
					form: '新闻中心',
					time: '2009-03-20',
					content: [
						'2009年3月16日，根据《北京市自主创新产品认定办法（修订）》的规定，经专家评审，市科委、市发改委、市建委、市工促局、中关村管委会认定，凝思磐石安全服务器系统和凝思安全操作系统两大产品列入北京市第二批自主创新产品目录。',
					],
				},
				{
					id: '55',
					tittle: '凝思独创安全机制获软件著作权证书',
					tag: '资质',
					form: '新闻中心',
					time: '2009-03-20',
					content: [
						'2009年3月11日，凝思独创的安全机制“强制运行控制系统”取得软件著作权证书，同时获得软件著作权证书的还有凝思的“强制能力控制系统”、“强制访问控制系统”和凝思磐石网卡数据零拷贝与高速分发系统V2.0。',
					],
				},
				{
					id: '54',
					tittle: '凝思操作系统首获四级安全专用产品销售许可证书',
					tag: '资质',
					form: '新闻中心',
					time: '2009-02-10',
					content: [
						'2009年2月6日，凝思磐石安全操作系统V4.1获得公安部四级安全专用产品销售许可证书，凝思成为目前全国首家且较早获得安全操作系统四级销售许可的供应商。',
					],
				},
				{
					id: '53',
					tittle: '凝思磐石安全操作系统通过公安部等保第四级检测',
					tag: '资质',
					form: '新闻中心',
					time: '2009-02-07',
					content: [
						'2009年2月4日，凝思磐石安全操作系统V4.1通过公安部信息安全监督检验中心四级检测，并取得报告书。',
					],
				},
			]
		},
		{
			year: '2008',
			text: [
				{
					id: '52',
					tittle: '我公司安全操作系统通过四川电网备用调度系统验收',
					tag: '要闻',
					form: '新闻中心',
					time: '2008-12-20',
					content: [
						'2008年12月17日，凝思磐石安全操作系统作为四川电网备用调度自动化系统底层支撑平台，其基本功能和性能指标满足《四川电网备用调度能量管理系统技术方案》，测试验收合格，顺利通过现场验收。',
					],
				},
				{
					id: '51',
					tittle: '凝思总经理宫敏博士获政府择优资助荣誉奖',
					tag: '人物',
					form: '新闻中心',
					time: '2008-12-15',
					content: [
						'2008年12月11日，凝思总经理宫敏博士获人力资源和社会保障部留学人员择优资助荣誉奖，并获匹配资金支持。',
					],
				},
				{
					id: '50',
					tittle: 'ICT评测信息安全隐患分析方法研究课题通过验收',
					tag: '要闻',
					form: '新闻中心',
					time: '2008-12-12',
					content: [
						'2008年12月10日，凝思承担的国家测评中心项目“ICT测评信息安全隐患分析方法研究”课题任务通过验收。',
					],
				},
				{
					id: '49',
					tittle: '凝思服务平台软件入选中关村政府采购推荐目录',
					tag: '要闻',
					form: '新闻中心',
					time: '2008-11-30',
					content: [
						'2008年11月27日，凝思磐石安全服务平台软件入选中关村政府采购推荐目录，凝思总经理宫敏博士在发布会上做了主题发言。',
					],
				},
				{
					id: '48',
					tittle: '凝思服务器系统产业化项目获北京市政府推荐',
					tag: '要闻',
					form: '新闻中心',
					time: '2008-06-28',
					content: [
						'2008年6月23日，凝思磬石安全服务器系统系列产品产业化项目获得北京市人事局向人力资源和社会保障部的留学人员科技活动择优资助人选和项目推荐。',
					],
				},
				{
					id: '47',
					tittle: '宫敏博士获第三届留学人员创业奖',
					tag: '人物',
					form: '新闻中心',
					time: '2008-04-20',
					content: [
						'2008年4月16日，凝思总经理宫敏博士获第三届留学人员创业奖，北京市共有10位留学人员当选。',
					],
				},
				{
					id: '46',
					tittle: '凝思获得CMMI3级认证',
					tag: '资质',
					form: '新闻中心',
					time: '2008-03-06',
					content: [
						'2008年3月1日，凝思获得CMMI3级认证。',
					],
				},
				{
					id: '45',
					tittle: '凝思获批承担国家测评中心项目',
					tag: '项目',
					form: '新闻中心',
					time: '2008-01-06',
					content: [
						'2008年1月1日，凝思承担国家测评中心项目“ICT测评信息安全隐患分析方法研究”获批。',
					],
				},
			]
		},
		{
			year: '2007',
			text: [
				{
					id: '44',
					tittle: '我公司担纲的“十一五”863重点科研项目获准立项',
					tag: '项目',
					form: '新闻中心',
					time: '2007-12-31',
					content: [
						'2007年12月30日，科技部批准凝思承担的“十一五”863计划“开源软件IP资源库关键技术及系统”重点科研项目立项（项目编号：2007AA010602）。',
					],
				},
				{
					id: '43',
					tittle: '凝思磐石安全服务器平台系统应用于监察部举报系统',
					tag: '要闻',
					form: '新闻中心',
					time: '2007-11-20',
					content: [
						'日前，凝思科技与监察部签订合同，为监察部举报系统提供凝思磐石安全服务器平台系统。',
					],
				},
				{
					id: '42',
					tittle: '我公司获电子政务工程产品供应商资格证书',
					tag: '资质',
					form: '新闻中心',
					time: '2007-09-22',
					content: [
						'2007年9月20日，凝思获国防科学技术工业委员会信息中心政务信息化项目办公室颁发的国防科技工业“十一五”电子政务工程产品供应商资格证书。',
					],
				},
				{
					id: '41',
					tittle: '我公司获技术创新基金证书',
					tag: '资质',
					form: '新闻中心',
					time: '2007-07-12',
					content: [
						'2007年7月10日，凝思获科技部科技型中小企业技术创新基金管理中心颁发的创新基金证书。',
					],
				},
				{
					id: '40',
					tittle: '中办DNS系统选用我公司系统',
					tag: '要闻',
					form: '新闻中心',
					time: '2007-06-02',
					content: [
						'2007年5月中办信息中心在建设中办DNS系统时选择了凝思磐石安全服务器系统，该服务器的高安全性和高可靠性是其选择的主要考虑。',
					],
				},
			]
		},
		{
			year: '2006',
			text: [
				{
					id: '39',
					tittle: '我公司产品获解放军国防通讯网设备器材进网许可证',
					tag: '要闻',
					form: '新闻中心',
					time: '2006-11-03',
					content: [
						'2006年10月29日，凝思磐石安全服务器系统获得中国人民解放军国防通讯网设备器材进网许可证。',
					],
				},
				{
					id: '38',
					tittle: '外交部选用凝思磐石安全服务器',
					tag: '要闻',
					form: '新闻中心',
					time: '2006-05-05',
					content: [
						'日前，外交部邮件系统的建设选用了凝思磐是安全服务器系统作为基础平台，以提高系统的安全性和可靠性。',
					],
				},
				{
					id: '37',
					tittle: '凝思磐石安全服务器系统获EAL3级认证',
					tag: '资质',
					form: '新闻中心',
					time: '2006-03-31',
					content: [
						'2006年3月，凝思磐石安全服务器系统通过国家信息安全产品测评认证中心认证，认证级别为EAL3，是目前国内较早获得EAL3级认证的安全服务器系统。',
					],
				},
			]
		},
		{
			year: '2005',
			text: [
				{
					id: '36',
					tittle: '国调再次批量采购电力安全文件网关',
					tag: '要闻',
					form: '新闻中心',
					time: '2005-11-30',
					content: [
						'日前国家电力调度通信中心再次向凝思批量采购11台电力安全文件网关。凝思电力安全文件网关自去年部署到电力调度中心后工作稳定，其高保密性、高安全性、处理自动化及易维护等特点受到用户的好评。',
					],
				},
				{
					id: '35',
					tittle: '我公司获涉及国家秘密的计算机信息系统集成资质证书',
					tag: '资质',
					form: '新闻中心',
					time: '2005-09-30',
					content: [
						'2005年9月，凝思取得国家保密局颁发的《涉及国家秘密的计算机信息系统集成资质证书》-软件开发（全国）。',
					],
				},
				{
					id: '34',
					tittle: '我公司安全服务器产品产业化项目获批',
					tag: '项目',
					form: '新闻中心',
					time: '2005-09-30',
					content: [
						'2005年9月，凝思向北京市发展和改革委员会申报的2005年国家信息安全专项项目“凝思磐石安全服务器系统系列产品产业化”资金申请报告已由国家发展和改革委员会批准，同意列入2005年国家高技术产业发展项目。',
					],
				},
				{
					id: '33',
					tittle: '宫敏博士获中国开源软件杰出贡献奖',
					tag: '人物',
					form: '新闻中心',
					time: '2005-08-28',
					content: [
						'2005年8月23日，中国软件行业协会共创软件分会、共创软件联盟为宫敏博士颁发“2004中国开源软件杰出贡献奖”。',
					],
				},
				{
					id: '32',
					tittle: '宫敏博士任中、日、韩优秀Linux课程评选评委会主席',
					tag: '人物',
					form: '新闻中心',
					time: '2005-08-08',
					content: [
						'2005年8月2日，宫敏博士应中国软件行业协会的邀请，作为评委主席参加了由教育部主办，软件行业协会承办的《中、日、韩优秀Linux课程评选》会议。',
					],
				},
			]
		},
		{
			year: '2004',
			text: [
				{
					id: '31',
					tittle: '国家电网批量采购电力安全文件网关',
					tag: '要闻',
					form: '新闻中心',
					time: '2004-10-28',
					content: [
						'2004年10月，国家电力调度通信中心与凝思签订采购合同，购买30台电力安全文件网关。该电力安全文件网关是一款针对电力系统文件传输特殊要求设计生产的产品，具有高保密性、高安全性、文件传输处理自动化和易维护等特点。',
					],
				},
				{
					id: '30',
					tittle: '凝思参与国家电力中心“863”项目',
					tag: '项目',
					form: '新闻中心',
					time: '2004-10-28',
					content: [
						'2004年10月，凝思参与国家电力调度中心“863”项目“国家电网调度中心安全防护体系研究及示范”-2002AA145040。',
					],
				},
				{
					id: '29',
					tittle: '凝思参加Linux产业与应用高峰论坛暨开源应用成果展',
					tag: '要闻',
					form: '新闻中心',
					time: '2004-06-30',
					content: [
						'2004年6月，公司受邀参加“Linux产业与应用高峰论坛暨开源应用成果展览会”。',
					],
				},
				{
					id: '28',
					tittle: '凝思磐石安全服务器在监察部部署',
					tag: '要闻',
					form: '新闻中心',
					time: '2004-04-25',
					content: [
						'2004年4月，凝思磐石安全服务器成功应用于国家监察系统。',
					],
				},
				{
					id: '27',
					tittle: '凝思取得软件企业证书',
					tag: '资质',
					form: '新闻中心',
					time: '2004-02-25',
					content: [
						'2004年2月，凝思取得软件企业证书。',
					],
				},
				{
					id: '26',
					tittle: '我公司承担国家计算机网络和信息安全管理中心项目',
					tag: '项目',
					form: '新闻中心',
					time: '2004-01-15',
					content: [
						'日前，凝思与启明星辰信息技术有限公司联合承担了信息产业部国家计算机网络和信息安全管理中心某项目，凝思负责向安管中心提供双系统隔离服务器定制产品。',
					],
				},
				{
					id: '25',
					tittle: '宫敏博士被评为对中国软件开发具有重大影响的人',
					tag: '人物',
					form: '新闻中心',
					time: '2004-01-15',
					content: [
						'2004年1月，《程序员》杂志评选出对中国软件开发具有重大影响的20人（2004年第1期），凝思董事长宫敏博士入选其中，被科技部、信息产业部联合授予“开源杰出贡献奖”。',
					],
				},
			]
		},
		{
			year: '2003',
			text: [
				{
					id: '24',
					tittle: '凝思被评为科技拥军先进单位',
					tag: '荣誉',
					form: '新闻中心',
					time: '2003-12-28',
					content: [
						'2003年12月，凝思被中国人民解放军总装备部评为科技拥军先进单位。',
					],
				},
				{
					id: '23',
					tittle: '我公司安全服务器被评为年度最佳Linux安全产品',
					tag: '荣誉',
					form: '新闻中心',
					time: '2003-12-28',
					content: [
						'2003年12月，凝思磐石安全服务器被赛迪评测评为“2003年度最佳Linux安全产品”。',
					],
				},
				{
					id: '22',
					tittle: '凝思安全平台取得《计算机软件著作权登记证书》',
					tag: '资质',
					form: '新闻中心',
					time: '2003-12-28',
					content: [
						'2003年12月，“凝思安全平台”获得国家版权局《计算机软件著作权登记证书》。',
					],
				},
				{
					id: '21',
					tittle: '凝思磐石安全服务器通过电力科学研究院评测',
					tag: '要闻',
					form: '新闻中心',
					time: '2003-11-30',
					content: [
						'2003年11月，受国家电力调度通信中心委托，电力科学研究院信息安全实验室对凝思磐石安全服务器进行了评测，凝思磐石安全服务器通过评测。',
					],
				},
				{
					id: '20',
					tittle: '凝思磐石安全服务器进入电力市场',
					tag: '要闻',
					form: '新闻中心',
					time: '2003-08-28',
					content: [
						'2003年8月，凝思磐石安全服务器成功应用于国家电力调度通信中心系统。',
					],
				},
				{
					id: '19',
					tittle: '凝思磐石安全服务器系统获3C认证',
					tag: '资质',
					form: '新闻中心',
					time: '2003-07-30',
					content: [
						'2003年7月，凝思磐石安全服务器系统获得中国电磁兼容认证中心颁发的《中国国家强制性产品认证证书》（3C认证）。',
					],
				},
				{
					id: '18',
					tittle: '凝思参加拥军捐赠活动',
					tag: '慈善',
					form: '新闻中心',
					time: '2003-07-30',
					content: [
						'日前，为庆祝中国人民解放军建军76周年，凝思参加了中国人民解放军建军76周年科技拥军捐赠活动，在活动中捐赠了凝思磐石安全服务器。',
					],
				},
				{
					id: '17',
					tittle: '我公司产品获计算机信息系统安全专用产品销售许可证',
					tag: '资质',
					form: '新闻中心',
					time: '2003-06-26',
					content: [
						'2003年6月，凝思磐石安全服务器系统获得公安部颁发的《计算机信息系统安全专用产品销售许可证》。',
					],
				},
				{
					id: '16',
					tittle: '国安部门选用凝思磐石安全服务器系统',
					tag: '要闻',
					form: '新闻中心',
					time: '2003-04-20',
					content: [
						'日前，国家安全部门选择凝思磐石安全服务器作为其CA系统的服务器系统平台，凝思磐石安全服务器已成功部署到国家安全部门。',
					],
				},
				{
					id: '15',
					tittle: '总参某部部署凝思磐石安全服务器',
					tag: '要闻',
					form: '新闻中心',
					time: '2003-04-20',
					content: [
						'日前，凝思磐石安全服务器成功应用于中国人民解放军总参某部容灾服务平台。',
					],
				},
				{
					id: '14',
					tittle: '凝思与总参61所联合承担“863”项目',
					tag: '项目',
					form: '新闻中心',
					time: '2003-03-06',
					content: [
						'2003年2月，凝思与总参61所联合承担题为“基于自主安全操作系统的军事信息网安全认证与信息发布”－2003AA1Z2250的国家“863”项目。',
					],
				},
			]
		},
		{
			year: '2002',
			text: [
				{
					id: '13',
					tittle: '解放军某部选用凝思磐石安全服务器',
					tag: '要闻',
					form: '新闻中心',
					time: '2002-12-02',
					content: [
						'2002年11月，凝思磐石安全服务器部署到中国人民解放军某部队。',
					],
				},
				{
					id: '12',
					tittle: '凝思磐石安全服务器通过国家保密局鉴定',
					tag: '要闻',
					form: '新闻中心',
					time: '2002-09-28',
					content: [
						'日前，凝思磐石安全服务器经国家保密局测试和检验，通过了国家保密局鉴定专家组的鉴定。',
					],
				},
				{
					id: '11',
					tittle: '凝思磐石安全服务器通过军用信息安全产品认证',
					tag: '资质',
					form: '新闻中心',
					time: '2002-05-20',
					content: [
						'2002年5月10日，凝思磐石安全服务器（2U+）获得中国人民解放军信息安全评测认证中心《军用信息安全产品认证证书》。',
					],
				},
				{
					id: '10',
					tittle: '凝思成为中国信息产业商会信息安全产业分会会员',
					tag: '要闻',
					form: '新闻中心',
					time: '2002-04-02',
					content: [
						'2002年3月29日，凝思参加了中国信息产业商会信息安全产业分会开幕式，并成为分会的普通会员。',
					],
				},
				{
					id: '9',
					tittle: '三大报刊文报道凝思磐石安全服务器',
					tag: '要闻',
					form: '新闻中心',
					time: '2002-04-02',
					content: [
						'继《计算机世界》和《计算机报》刊文报道凝思磐石安全服务器之后，《经济日报》、《人民日报》和《解放军报》分别于2002年3月18日、3月25日和3月30日刊文报道凝思磐石安全服务器，称该服务器解决了黑客利用缓冲器溢出和其他类似的方法，绕过身份验证和访问权限限制等保障措施对服务器进行攻击的问题（解放军报），据军事部门试用证实，该服务器达到美国军标B1级以上安全水平，在国际也属先进（经济日报）。',
					],
				},
				{
					id: '8',
					tittle: '《计算机世界》第7期刊文报道凝思磐石安全服务器',
					tag: '要闻',
					form: '新闻中心',
					time: '2002-03-06',
					content: [
						'2002年3月4日，《计算机世界》第7期刊文《我国自主开发的安全服务器诞生》称：2002年2月28日，凝思正式发布了由该公司自主研制的凝思磐石-- I型安全服务器。该产品已经通过了国家信息安全测评中心的认证。作为国家“863”信息安全应急计划“服务器安全增强技术”课题的成果，其特点是解决了利用缓冲器溢出和其他雷系方法，弱国身份验证和访问权限等保护措施对服务器进行攻击的问题，确保系统设计的安全策略不被违反。',
					],
				},
				{
					id: '7',
					tittle: '凝思磐石安全服务器正式发布',
					tag: '公告',
					form: '新闻中心',
					time: '2002-03-01',
					content: [
						'日前，凝思召开了凝思磐石安全服务器的新闻发布会，凝思磐石安全服务器的发布在业内引起很大反响。',
					],
				},
				{
					id: '6',
					tittle: '国内唯一通过国家测评认证中心认证的安全服务器',
					tag: '荣誉',
					form: '新闻中心',
					time: '2002-01-31',
					content: [
						'2002年1月，凝思磐石安全服务器通过国家信息安全测评认证中心测评，成为目前国内较早通过国家测评认证中心认证的安全服务器。',
					],
				},
			]
		},
		{
			year: '2001',
			text: [
				{
					id: '5',
					tittle: '凝思在南京理工大学做Linux培训及移植',
					tag: '要闻',
					form: '新闻中心',
					time: '2001-11-30',
					content: [
						'2001年11月，公司为南京理工大学做了Linux培训及移植，并讲解凝思磐石安全服务器的工作原理。',
					],
				},
				{
					id: '4',
					tittle: '凝思受邀参加军用操作系统标准的制订',
					tag: '要闻',
					form: '新闻中心',
					time: '2001-06-03',
					content: [
						'2001年5月，公司受邀参加军用操作系统标准制订。宫敏为军用安全操作系统联合专家组成员、军用安全操作系统标准和技术规范联合编写组成员。',
					],
				},
				{
					id: '3',
					tittle: '宫敏博士做关于缓冲器溢出的报告',
					tag: '人物',
					form: '新闻中心',
					time: '2001-04-26',
					content: [
						'2001年4月，公司参加了通讯兵部信息安全产品展览，宫敏博士在展会上做关于缓冲器溢出的报告。',
					],
				},
				{
					id: '2',
					tittle: '凝思安全服务器原理样机获领导和专家的好评',
					tag: '要闻',
					form: '新闻中心',
					time: '2001-03-02',
					content: [
						'2001年2月，在北展国家863十五周年成果展上，凝思安全服务器原理样机在保密馆展出引起轰动，并得到前来参观的中央领导、各部委领导及专家的好评。',
					],
				},
				{
					id: '1',
					tittle: '国家863信息安全应急项目通过验收',
					tag: '要闻',
					form: '新闻中心',
					time: '2001-03-02',
					content: [
						'2001年2月，“服务器安全增强技术”通过国家863信息安全技术发展战略研究专家组验收。',
					],
				},
			]
		}*/
	]
};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
