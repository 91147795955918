<!-- 隐私政策 -->
<template>
	<div class="NotpageBackground"> 
		<div>
			<router-link to="/Home">回到首页</router-link>
			<div>
				<p>您也可以前往以下板块</p>
				<div>
					<router-link to="/Products/ProductsCatalog">产品中心</router-link>
					<router-link to="/Solutions/Government">解决方案</router-link>
					<router-link to="/EcologicalCooperation/CooperativeValue">生态合作</router-link>
					<router-link to="/ServiceAndSupport/SecurityNotice">服务与支持</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>
<style scope>
.NotpageBackground{
	background: url(./images/404背景-pc.png) no-repeat center top;
	background-size: 100% auto;
	padding:108px 0;

}
.NotpageBackground > div{
	background: url(./images/404-pc.png) no-repeat center 102px;
	margin:0 auto;
	width:calc(100% - 281px - 281px);
	min-width:990px;
	height:calc(864px - 612px );
	padding-top:612px;
	background-color:#fff;
	border:1px solid #e0e0e0;
	border-radius:10px;
	box-shadow: 10px 0px 30px rgba(4,4,5,0.08);
}
.NotpageBackground > div > a{
	display:block;
	margin:0 auto;
	font-size:18px;
	font-weight:500;
	line-height:54px;
	height:54px;
	width:142px;
	color:#fff;
	background-color:#dd1d29;
	border-radius:4px;
	text-align:center;
}
.NotpageBackground > div > div > p{
	margin:0 auto;
	font-size:18px;
	font-weight:300;
	line-height:42px;
	margin-top:48px;
	color:#5e6570;
	text-align:center;
}
.NotpageBackground > div > div > div{
	text-align:center;
}
.NotpageBackground > div > div > div > a{
	position: relative;
	display:inline-block;
	font-size:18px;
	font-weight:400;
	line-height:40px;
	color:#262b33;
	text-indent:24px;
}
.NotpageBackground > div > div > div > a+a{
	margin-left:52px;
}
.NotpageBackground > div > div > div > a:before{
	position: absolute;
	display:block;
	content:'';
	top:0;
	left:0;
	width:16px;
	height:40px;
	background: url(./images/404箭头.png) no-repeat left center;
	background-size:14px 8px;
}
.NotpageBackground > div > div > div > a:hover:before{
	background: url(./images/404箭头-移入.png) no-repeat left center;
	background-size:14px 8px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	.NotpageBackground{
		background: url(./images/404背景.png) no-repeat center top;
		background-size: 100% auto;
		padding:104px 0;

	}
	.NotpageBackground > div{
		background: url(./images/404-pad横屏.png) no-repeat center 68px;
		width:904px;
		min-width:904px;
		height:calc(575px - 403px );
		padding-top:403px;
		background-color:#fff;
	}
	.NotpageBackground > div > a{
		font-size:16px;
		line-height:43px;
		height:43px;
		width:110px;
	}
	.NotpageBackground > div > div > p{
		font-size:16px;
		line-height:34px;
		margin-top:19px;
	}
	.NotpageBackground > div > div > div > a{
		font-size:14px;
		line-height:32px;
		text-indent:22px;
	}
	.NotpageBackground > div > div > div > a:before{
		width:12px;
		height:32px;
		background-size:12px 7px;
	}
	.NotpageBackground > div > div > div > a:hover:before{
		background-size:12px 7px;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.NotpageBackground > div{
		background: url(./images/404-pad竖屏.png) no-repeat center 68px;
		width:720px;
		min-width:720px;
		height:calc(588px - 403px );
		padding-top:403px;
		background-color:#fff;
	}
	.NotpageBackground > div > div > div > a+a{
		margin-left:48px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	.NotpageBackground{
		background: url(./images/404背景.png) no-repeat center top;
		background-size: 100% auto;
		padding:94px 0;

	}
	.NotpageBackground > div{
		background: url(./images/404-手机端.png) no-repeat center 48px;
		background-size:calc(100% - 30px) auto;
		margin:0 auto;
		width:calc(100% - 30px);
		min-width:330px;
		height:auto;
		padding:calc(48px + 67.5% - 20px) 0 32px;
		background-color:#fff;
		border:1px solid #e0e0e0;
		border-radius:5px;
		box-shadow: 5px 0px 15px rgba(4,4,5,0.08);
	}
	.NotpageBackground > div > a{
		display:block;
		margin:0 auto;
		font-size:16px;
		font-weight:500;
		line-height:40px;
		height:40px;
		width:113.5px;
		background-color:#dd1d29;
		border-radius:3px;
		text-align:center;
	}
	.NotpageBackground > div > div > p{
		margin:0 auto;
		font-size:15px;
		font-weight:300;
		line-height:40px;
		margin-top:23.5px;
		text-align:center;
	}
	.NotpageBackground > div > div > div > a{
		position: relative;
		display:block;
		width:346px;
		margin:0 auto;
		font-size:15px;
		font-weight:400;
		line-height:40px;
		text-indent:0;
	}
	.NotpageBackground > div > div > div > a+a{
		margin:4px auto 0;
	}
	.NotpageBackground > div > div > div > a:before{
		left:calc(50% - 30px - 22px);
		width:10px;
		height:40px;
		background-size:10px 5.5px;
	}
	.NotpageBackground > div > div > div > a:last-child:before{
		left:calc(50% - 37.5px - 22px)
	}
	.NotpageBackground > div > div > div > a:hover:before{
		background-size:10px 5.5px;
	}
}
</style>
