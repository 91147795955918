<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<DispatchingSystem v-show="natittle3 == '电网调度系统'"></DispatchingSystem>
		<DistributionAutomation v-show="natittle3 == '配网自动化'"></DistributionAutomation>
		<Transformersubstation v-show="natittle3 == '变电站'"></Transformersubstation>
		<PowerGenerationGroup v-show="natittle3 == '发电集团'"></PowerGenerationGroup>
		<StateGridInformatization v-show="natittle3 == '国网信息化'"></StateGridInformatization>
	</div>
</template>

<script>
import DispatchingSystem from './DispatchingSystem.vue';
import DistributionAutomation from './DistributionAutomation.vue';
import Transformersubstation from './Transformersubstation.vue';
import PowerGenerationGroup from './PowerGenerationGroup.vue';
import StateGridInformatization from './StateGridInformatization.vue';

export default {
	name:'PoverIndustryDetails',
	components:{
		DispatchingSystem,
		DistributionAutomation,
		Transformersubstation,
		PowerGenerationGroup,
		StateGridInformatization
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '电力行业',
			natittle3: this.$route.query.tittle,
		}
	},
	created(){
		this.natittle3 = this.$route.query.tittle;
        if(this.$route.query.tittle == '电力行业'){
        	this.natittle2 = false;
        } else {
        	this.natittle2 = '电力行业'
        }
    },
}
</script>
<style>
#fontNoBorder > #tittlrImgPoverIndustry1{
	background:url('../images/标题背景-调度系统.jpg') no-repeat -10px -20px;
	background-size: 1172px 551px;
}
#fontNoBorder > #tittlrImgPoverIndustry2{
	background:url('../images/标题背景-配网自动化.png') no-repeat 0px 0px;
	background-size: 1170px 227px;
}
#fontNoBorder > #tittlrImgPoverIndustry6{
	background:url('../images/标题背景-信息化.png') no-repeat center;
	background-size: 1170px 277px;
}
@media only screen and (max-width:1170px){
	#fontNoBorder > #tittlrImgPoverIndustry1{
		background:url('../images/标题背景-调度系统.jpg') no-repeat 0px 0px;
	}
	#fontNoBorder > #tittlrImgPoverIndustry2{
		display:none;
	}
	#fontNoBorder > #tittlrImgPoverIndustry6{
		background:url('../images/标题背景-信息化.png') no-repeat center;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > #tittlrImgPoverIndustry1{
		background:url('../images/标题背景-调度系统.jpg') no-repeat -32px bottom;
		background-size:913px auto;
	}
	#fontNoBorder > #tittlrImgPoverIndustry2{
		background:url('../images/标题背景-调度系统.jpg') no-repeat -32px bottom;
		background-size:913px auto;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder > #tittlrImgPoverIndustry1{
		background:url('../images/标题背景-调度系统.jpg') -60.5px 0px;
		background-size: auto 275.5px;
	}
	#fontNoBorder > #tittlrImgPoverIndustry2{
		background:url('../images/标题背景-调度系统.jpg') -60.5px 0px;
		background-size: auto 275.5px;
	}
	#fontNoBorder > #tittlrImgPoverIndustry6{
		background:url('../images/标题背景-信息化.png') center;
		background-size: auto 119px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder > #tittlrImgPoverIndustry1{
		background:url('../images/标题背景-调度系统.jpg') no-repeat -3px 0px;
		background-size: auto 746px;
	}
	#fontNoBorder > #tittlrImgPoverIndustry2{
		background:url('../images/标题背景-调度系统.jpg') no-repeat -3px 0px;
		background-size: auto 746px;
	}
	#fontNoBorder > #tittlrImgPoverIndustry6{
		background:url('../images/标题背景-信息化.png') no-repeat center;
		background-size: auto 343px;
	}
} */
</style>
