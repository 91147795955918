<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>功能特点：</h2>
					<div class="textDetails figureweight">
						<p class="figure figure1">提供分布式并行处理机制和框架，将复杂的大数据分析任务自动分配到不同的节点进行分布式并行处理。</p>
						<p class="figure figure2">采用分布式文件系统，提供PB级的数据存储和高速可靠读写，支持POSIX文件访问规范。</p>
						<p class="figure figure3">采用并行IO技术，实现IO的自动分配和汇聚，达到数据的高速读写，通过将数据分布到不同节点，可支持GB级的数据读写操作。</p>
						<p class="figure figure4">支持结构化和非结构化数据的存取，提供上层接口。</p>
						<p class="figure figure5">利用分布式缓存技术，将大数据的分析效率提高一个数量级。</p>
						<p class="figure figure6">提供丰富的应用接口，为第三方应用分析软件接入大数据平台提供简便的入口。</p>
						<p class="figure figure7">凭借凝思安全机制和功能，安全可靠地访问平台。</p>
						<p class="figure figure8">可在不同层次提供扩展功能，保证大数据平台的先进性和兼容性。</p>
					</div>
				</div>
				<div class="textBox caseDisplayBox">
					<h2>典型案例：</h2>
					<CaseDisplay :caseDisplay="caseDisplay" :path="path"></CaseDisplay>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'BigData',
	data() {
		return {
			path: '/Solutions/PoverIndustryDetails',
			caseDisplay:[
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'电网调度系统',
					text:'凝思成为电力调度自动化系统的首选厂商参加中国电科院、国网电科院的移植工作'
				},
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'变电站',
					text:'凝思安全操作系统在全国范围内的变电站大量应用'
				},
			]
		}
	}
}
</script>
<style scoped>

</style>
