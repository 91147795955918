<template>
  <div>
    <Header></Header>
    <router-view id="top" :class="{bodyback:bodyback}"></router-view>
    <Footer></Footer>
    <Return/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import Return from './components/Return';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Return
    },
    data() {
      return {
        bodyback: 0,
      }
    },
    watch: {
	    $route:{
		    immediate:true,
		    handler(){
				if(this.$route.path == '/Home'){
					this.bodyback = 0;
				} else {
					this.bodyback = 1;
				}
			}
		},
	},
}
</script>

<style>
/* * {

background: #000 !important;

color: #0f0 !important;

outline: solid #f00 1px !important;

} */
 pc
html{
  /* overflow-x: hidden; */
  border-bottom: 1px solid #14161c;
	width:100vw; 
  overflow-x:hidden;
}
.bodyback{
	background: url(./pages/images/二级页顶背景.png) no-repeat center -128px;
  background-size: 100% auto;
  position: relative;
}
/*页面内容区*/
	.fontmain{
		position: relative;
		width: 1170px;
		margin: 0px auto;
		padding-bottom:130px;
		white-space:nowrap;
	}
	.fontmainNoBottom{
		padding-bottom:0;
	}
	.fontmainNotitle1{
		padding-top: 124px;
    width: 100%;
	}
	.fontmainMargin{
		margin: 0 auto;
		padding-bottom:0px;
	}

/*通用*/
.font{
  position: relative;
  display: inline-block;
  margin-left: 16px;
  /* top: -1px; */
  width: 902px;
  min-height: 485px;
  border: #e0e0e0 1px solid;
  background-color: #fff;
  white-space:normal;
}
#fontNoMax{
  min-height: 0;
}
#newsdetail{
	width: 1168px;
	margin: 0;
}
#font1{
  width: 1170px;
  margin: 0 calc(50vw - 585px);
}
#fontNoBorder{
	width: 1170px;
	border: none;
	margin: 0;
	background-color:rgba(0,0,0,0);
}
/*文章标题*/
.font > h1 {
  padding: 0 32px;
  line-height: 68px;
  position: relative;
}
.font > h1 img{
	display:inline-block;
	position: relative;
  	max-height:20px;
  	max-width:20px;
  	top:2px;
}
.font > h1 span{
  margin-left: 5px;
  font-size: 20px;
  letter-spacing: 0px;
  font-weight: 500; 
  color: #262b33;
}
.font h2{
    margin: 14px 32px 0px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;  
    line-height: 34px;
    color: #262b33;
    text-indent:0em;
    position: relative;
}
.font h3{
    margin: 0px 32px 0px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 400;  
    line-height: 34px;
    color: #262b33;
    text-indent:2em;
}
.font h3:before{
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: auto 5px 2px;
    background: #9b141d;
    content: '';
}
/*文章正文*/
.font p{
  margin: 14px 32px 0px;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 400;  
  line-height: 28px;
  color: #262b33;
  text-indent:2em;
}
.font p span{
    color: #b11721;
}
#details .new p a,#details .new2 p a,#details .new p a span,#details .new2 p a span{
    color: #262b33;
}
.font h1 + p{
  margin-top: 0px;
}
.font p:last-child{
  margin-bottom: 26px;
}
.font > figure{
  margin: 32px auto;
  text-align: center;
}/*只有pc*/
.font > figure img{
  max-width: 90%;
}

/*法律声明 隐私政策*/
.font > .title{
  width: 1106px;
  margin:  0 32px;
  border-bottom: 1px solid #e0e0e0;
}
.font > .title h1{
  width: 1106px;
  margin: 32px auto 24px;
  text-align: center;
}
.font > .title h1 span{
  display: block;
  margin: 0px auto 9px;
  font-size: 22px;
  letter-spacing: 0px;
  font-weight: 500; 
  color: #262b33;
}
.font > .details{
    width: 1106px;
    margin: 23px auto;
}
.font > .details h2{
    margin: 0px 0px 2px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;  
    line-height: 34px;
    /* background-color: aquamarine; */
    color: #262b33;
}
.font > .details p{
    margin: 0px 0px 2px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 400;  
    line-height: 34px;
    /* background-color: aquamarine; */
    color: #262b33;
    text-indent:2em;
}

.font > .details p+h2{
    margin: 22px 0px 2px;
}
.font > .details .point:before, .font .po1:before{
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: auto 5px 2px;
    background: #9b141d;
    content: '';
}
/*pad横屏*/
@media only screen and (max-width:1170px){
  .bodyback{
  	background: url(./pages/images/二级页顶背景.png) no-repeat center 0px;
		background-size:100vw auto;
	}	
	/*页面内容区*/
  .fontmain{
    width: 904px;
    white-space:normal;
  }
  .fontmainNotitle1{
    padding: 144px calc(50vw - 452px) 0;
  }

  /*通用*/
  .font{
      width: 902px;
      margin: 0px auto;
  }
  #font1 {
    width: 904px;
    margin: 0 auto 56px;
  }
  #newsdetail{
	width: 902px;
	margin: 0;
}
  /*文章标题*/
  .font > h1 {
    padding: 0 32px 12px;
  }

  /*法律声明 隐私政策*/
  .font > .title{
      width:calc(100% - 64px);
  }
  .font > .title h1{
      width: 100%;
  }
  .font > .details{
    width: calc(100% - 64px);
  }
  /*导航页*/
  #pandectfont .questionnav div a{
    display:block;
    width: 166px;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 0;
    color: #565d69;
    font-weight: 400; 
  }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
html {

  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  width: 100vw;
}
	/*页面内容区*/
  .fontmain{
    width: 720px;
	margin:0 auto;
  }
  .fontmainNoBottom {
		margin-bottom: 0;
	}
  .fontmainNotitle1 {
    padding: 144px calc(50vw - 361px) 0;
  }


  /*文章标题*/
  .font{
    width: 718px;
  }
  #newsdetail {
		width: 718px;
	}
  #font1 {
    width: 720px;
    margin: 0 auto 56px;
  }
  .font > h1 {
      padding: 0 32px;
      line-height: 68px;
  }
  .font > h1 span{
      font-size: 20px;
      letter-spacing: 0px;
      font-weight: 500; 
      color: #262b33;
  } 

  /*产品中心*/
  .font > .st1 > div{
      display: inline-block;
      width: 265px;
  }
  
  /*导航页*/
  #pandectfont .questionnav div{
      column-count: 3;
  }
}
/*手机端*/
@media only screen and (max-width:750px){
html{
  	overflow-x:auto;
  	margin:0;
  	padding:0;
  	border:0;
}
body{
	margin:0;
  	padding:0;
  	border:0;
}
  .bodyback{
    background: url(./pages/images/二级页顶背景.png) no-repeat center 0;
    background-size: 100% auto;
  }
	/*页面内容区*/
  .fontmain{
    margin: 0px auto;
    width: 100%;
    padding-bottom: 73px;
  }
  .fontmainNotitle1{
    padding: 96px 15px 0;
    width:calc(100vw - 30px);
  }
  #font1 {
    width: 100%;
    margin: 0;
    padding-bottom:56px;
  }
  /*通用*/
  .font{
    width: 100%;
    min-height: 242.5px;
    border: rgba(0,0,0,0) 0px solid;
    background-color: rgba(0,0,0,0);
  }
  #newsdetail {
		width: 100%;
	}
  /*文章标题*/
  .font > h1 {
    padding: 4px 16px 0;
    line-height: 59px;
  }
  .font > h1 img{
    height:13px;
  }
  .font > h1 span{
    font-size: 15px;
  } 
  .font h2{
    margin: 15px 16px;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500;  
    line-height: 20px;
    color: #262b33;
    text-indent:0em;
  }
  .font h3{
    margin: 0px 16px;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 400;  
    line-height: 20px;
    color: #262b33;
    text-indent:2em;
  }
  .font h3:before{
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: auto 5px 3px;
    background: #9b141d;
    content: '';
  }
  /*文章正文*/
  .font p{
      margin: 15px 16px;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0px;
      font-weight: 400;  
      color: #262b33;
      text-indent:2em;
  }

  /*法律声明 隐私政策*/
  .font > .title{
      width: 100%;
      margin:  0 auto;
      border-bottom: 0.5px solid #e0e0e0;
  }
  .font > .title h1{
      margin: 16px auto 15px;
  }
  .font > .title h1 span{
      font-size: 18px;
  }
  .font > .details{
      width:100%;
      padding:0;
      margin: 11.5px auto;
  }
  .font > .details h2{
      margin: 0px 0px 1px;
      font-size: 14px;
      letter-spacing: 0px;
      font-weight: 500;  
      line-height: 23px;
      /* background-color: aquamarine; */
      color: #262b33;
  }
  .font > .details p{
      margin: 0px auto 1px;
      font-size: 14px;
      letter-spacing: 0px;
      font-weight: 400;  
      line-height: 23px;
      /* background-color: aquamarine; */
      color: #262b33;
      text-indent:2em;
  }
  .font > .details p+h2{
      margin: 15px 0px 1px;
  }
  .font > .details .point:before, .font .po1:before{
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: auto 5px 3px;
    background: #9b141d;
    content: '';
  }


  /*详情页*/
  #detailsfont h1 .titleOfh1{
      font-size: 22px;
      font-weight: 400; 
  }
  #detailsfont .fontOfdetails{
      padding: 0px 15px;
  }
  #detailsfont .fontOfdetails > h2{
      font-size: 15px;
      line-height: 37px;
      color: #262b33;
      font-weight: 500; 
      margin: 0;
      margin-top: 0.5px;
  }
  /*段-标题*/
  #detailsfont .fontOfdetails p+h2{
      margin-top: 15px;
  }
  #detailsfont .fontOfdetails p{
      font-size: 14px;
      line-height: 19px;
      color: #262b33;
      font-weight: 400; 
      text-indent:1em;
      margin: 0;
  }
  #detailsfont .fontOfdetails .pform1{
      line-height: 22px;
      text-indent:0em;
  }
  #detailsfont .fontOfdetails .pform1:before, #detailsfont .fontOfdetails p span:before{
      display: inline-block;
      width: 2px;
      height: 2px;
      margin: 4px 3.5px;
      background-color: #666;
      content: " ";
  }
  #detailsfont .fontOfdetails > div{
    margin-top: 13.5px;
  }
  #detailsfont .fontOfdetails div h3{
      font-size: 14px;
      line-height: 19px;
      color: #262b33;
      font-weight: 400; 
      margin: 0;
  }
  /*解决问题下第一个方案标题*/
  #detailsfont .fontOfdetails h2+div{
      margin-top: 1.5px;
  }
  #detailsfont .fontOfdetails div div div{
      background-color: #ecf5f0;
      border-radius:4px;
      margin: 9.5px 0;
      padding: 15.5px 16px;  
  }
}
/*手机端1080
@media only screen and (min-height:1300px){
	页面内容区
  .fontmain{
    margin: 0px auto 205px;
    width: 100%;
  }
  .fontmainNotitle1{
    padding-top: 266px;
  }


  通用
  .font{
      width: 100%;
      min-height: 485px;
      border: rgba(0,0,0,0) 1px solid;
      background-color: rgba(0,0,0,0);
  }
  文章标题
  .font > h1 {
    padding: 15px 44px 0;
    line-height: 152px;
  }
  .font > h1 img{
    height:38px;
  }
  .font > h1 span{
    font-size: 42px;
  }
  .font h2{
    margin: 44px 32px;
    font-size:40px;
    letter-spacing: 0px;
    font-weight: 500;  
    line-height: 70px;
    color:rgba(05,05,05,1);
    text-indent:0em;
  }
  .font h3{
    margin: 0px 32px;
    font-size: 40px;
    letter-spacing: 0px;
    font-weight: 400;  
    line-height: 70px;
    color:rgba(41,41,41,0.8);
    text-indent:2em;
  }
  .font h3:before{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: auto 15px 9px;
    background: #9b141d;
    content: '';
  }
  文章正文
  .font p{
    margin: 44px 44px;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 0px;
    font-weight: 400;  
    color:#333;
    text-indent:2em;
  }

  法律声明 隐私政策
  .font > .title{
      width: 992px;
      margin:  0 auto;
      border-bottom: 1px solid #e0e0e0;
  }
  .font > .title h1{
      margin: 0px auto 70px;
  }
  .font > .title h1 span{
      font-size: 60px;
  }
  .font > .details{
      width: 992px;
      padding:0;
      margin: 57px auto 0;
  }
  .font > .details h2{
      margin: 0px 0px 0px;
      font-size: 40px;
      letter-spacing: 0px;
      font-weight: 500;  
      line-height: 66px;
      color:#333;
  }
  .font > .details p{
      margin: 0px auto 0px;
      font-size: 40px;
      letter-spacing: 0px;
      font-weight: 400;  
      line-height: 66px;
      color:#333;
      text-indent:2em;
  }
  .font > .details p+h2{
      margin: 44px 0px 0px;
  }
  .font > .details .point:before, .font .po1:before{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: auto 15px 9px;
    background: #9b141d;
    content: '';
  }

  导航页
  #pandectfont .questionnav{
      margin: 70px 44px 104px;
  }
  #pandectfont .questionnav:last-child{
      margin: 70px 44px 0px;
  }
  #pandectfont .questionnav h3{
      font-size: 46px;
      line-height: 46px;
      color: #141414;
      font-weight: 500; 
  }
  #pandectfont .questionnav div{
      column-count: 1;
  }
  #pandectfont .questionnav div a, #pandectfont .questionnav div a:hover{
      display:block;
      width: 100%;
      font-size: 40px;
      line-height: 104px;
      padding: 0;
      color: #5c5c5c;
      font-weight: 400; 
  }
  #pandectfont .questionnav div a:before, #pandectfont .questionnav div a:hover:before{
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 10px;
      margin-left: 0px;
      background-color: #5c5c5c;
      content: " ";
  }
  详情页
  #detailsfont h1 .titleOfh1{
    font-size: 62px;
    font-weight: 400; 
  }
  #detailsfont .fontOfdetails{
      padding: 0px 44px;
  }
  #detailsfont .fontOfdetails > h2{
      font-size: 46px;
      line-height: 106px;
      color: #141414;
      font-weight: 500;
      margin: 0; 
      margin-top: 11px;
  }
  段-标题
  #detailsfont .fontOfdetails > p+h2{
      margin-top: 44px;
  }
  #detailsfont .fontOfdetails p{
      font-size: 40px;
      line-height: 54px;
      color: #333;
      font-weight: 400; 
      text-indent:1em;
      margin: 0;
  }
  #detailsfont .fontOfdetails .pform1{
      line-height: 64px;
      text-indent:0em;
  }
  #detailsfont .fontOfdetails .pform1:before, #detailsfont .fontOfdetails p span:before{
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 10px;
      background-color: #666;
      content: " ";
  }
  #detailsfont .fontOfdetails div{
      margin-top: 34px;
  }
  #detailsfont .fontOfdetails div h3{
      font-size: 40px;
      line-height: 54px;
      color: #141414;
      font-weight: 400; 
      margin: 0;
  }
  解决问题下第一个方案标题
  #detailsfont .fontOfdetails h2+div{
      margin-top: 5px;
  }
  #detailsfont .fontOfdetails div div div+h3{
      margin-top: 41px;
  }
  #detailsfont .fontOfdetails div div div{
      background-color: #ecf5f0;
      border-radius:8px;
      margin: 25px 0;
      padding: 41px 44px;  
  }
}*/
</style>
