<!-- 首页顶部轮播图 -->
<template>
	<div class="swiper-container" id="banner-swiper4">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(banner,index) in bannerList" :key="index">
				<div class="img-fix">
					<div class="img-inner">
						<img :src="banner.imgUrl" class="img" id="bannerimg1" :alt="banner.imgUrl">
						<router-link :to="{path:'/AboutLinx/NewsDetails',query:{id:banner.id}}" v-show="banner.id"></router-link>
						<router-link :to="banner.path" v-if="banner.path"></router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-nav">
			<div class="swiper-pagination"></div>
		</div>
		<div class="swiper-button-next" id="under-swiper-button-next"></div>
		<div class="swiper-button-next"></div>
		<div class="swiper-button-prev" id="under-swiper-button-prev"></div>
		<div class="swiper-button-prev"></div>
	</div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'banner1',
    data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			bannerList:[
				{
					path:'/Products/ProductsCatalog',
					imgUrl:require('./images/banner-手机端-01.png'),
				},
				{
					path:'/AboutLinx/Company',
					imgUrl:require('./images/banner-手机端-03.png'),
				},
				{
					id:'91',
					imgUrl:require('./images/banner-手机端-04.png'),
				},
			]
		}
	},
	mounted(){
		var mySwiper4 = new Swiper('#banner-swiper4', {
			speed: 1000,
			longSwipes: false,
			loop: true,
			autoplay: {
				delay:6000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			on:{
				resize:function () {
					setTimeout(() => {
						mySwiper4.update()
					},500)
				}
			}
		});
    },

}
</script>

<style>

</style>
