<!-- 首页-成功案例 -->
<template>
    <div class="box" id="certify">
        <div class="title-font1">解决方案
            <div></div>
        </div>
        <div class="swiper-container" :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1" id="certify-swiper" ref="mySwiper">
            <div class="swiper-wrapper" ref="certifySw">
                <div class="swiper-slide" v-for="stories in storiesList" :key="stories.id">
                    <img :src="stories.imgUrl" />
                    <div class="cover"></div>
                    <div class="gallery-item-desc">
                        <div>{{stories.id}}</div>
                        <h3 class="typography-label">{{stories.tittle}}</h3>
                        <p class="typography-body">{{stories.text}}</p>
                        <router-link :to="stories.link">查看更多<img src="../../../images/箭头-蓝色-向右.png"></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper';
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
    name: 'Solutions',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
        }
    },
    computed:{
		...mapState({
			storiesList:(state)=>state.Home.storiesList,
		})
	},
    mounted(){
    	window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
        this.certifySwiper = new Swiper('#certify-swiper', {
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            // loopedSlides: 4,
            autoplay: {// 自动滑动
                delay: 5000, //3秒切换一次
                disableOnInteraction: false
            }
        });
    },
}
</script>
<style scoped>
    /*pc*/
    #certify {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background-color: #fff;
    }
    #certify .swiper-container {
        cursor: ew-resize;
    }
    #certify  .swiper-slide {
        position: relative;
        width: 924px;
        height: 576px;
        margin: 0 35px;
    }
    #certify .cover{
        position:absolute;
        width: 924px;
        height: 576px;
        top:0px;
        right: 0px;
        background-color: rgba(23, 23, 23, 0.45);
    }
    #certify .gallery-item-desc {
        position:absolute;
        width: 394px;
        height: 444px;
        top:63px;
        right: 62px;
        background-color: #fff;
        opacity: 0;
        box-shadow: 2px 0px 26px rgba(15,17,20,0.12);
    }
#certify .swiper-slide-active .cover{
    opacity: 0.1!important;
}
#certify .swiper-slide-active .gallery-item-desc{
    opacity: 1!important;
}
    #certify  .swiper-slide img{
        display:block;
        width: 100%;
    }
    #certify .gallery-item-desc div{
        position:absolute;
        width: 74px;
        height: 59px;
        background-color: #dd1d29;
        top:-63px;
        right:38px;
        padding-top: 82px;
        font-size: 40px;
        color: #fff;
        font-weight: 500;
        letter-spacing:2px;
        text-align:center;
    }
    #certify .gallery-item-desc .typography-label {
        position:absolute;
        top: 96px;
        left: 60px;
        line-height:  84px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #262b33;
        font-size: 52px;
    }
    #certify .gallery-item-desc .typography-body {
        position:absolute;
        top: 190px;
        left: 64px;
        right: 92px;
        line-height:28px;
        font-weight:300;
        letter-spacing: 0px;
        color: #565d69;
        font-size: 16px;
    }
    #certify .gallery-item-desc a {
        text-decoration: none;
        position:absolute;
        bottom: 72px;
        left: 64px;
        font-weight:500;
        letter-spacing: 0px;
        color: #0072ce;
        font-size: 16px;
    }
    #certify .gallery-item-desc a img{
        position: relative;
        top: -3px;
        left: 12px;
        display: inline-block;
        padding-left: 0;
        width: 14px;
        height: 8px;
        transition: left .3s;
    }
    #certify .gallery-item-desc a:hover img{
        left: 17px;
        transition: left .3s;
    }
/*pad横屏*/
@media only screen and (max-width:1170px){
    #certify  .swiper-slide {
        margin: 0 31px;
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
#main{
    width:100%;
    height:672px;
}
#certify  .swiper-slide {
    width: 100%;
    height: auto;
    margin: 0;
}
#certify .cover{
    width: 100%;
    height: auto;
}
#certify .gallery-item-desc {
    position:absolute;
    width: 338px;
    height: 380px;
    top:47px;
    right: 52px;
}
#certify .gallery-item-desc div{
    width: 64px;
    height: 46px;
    top:-47px;
    right:32px;
    padding-top: 72px;
    font-size: 34px;
}
#certify .gallery-item-desc .typography-label {
    top: 94px;
    left: 55px;
    line-height:  54px;
    font-size: 44px;
}
#certify .gallery-item-desc .typography-body {
    top: 155px;
    left: 55px;
    width: 207px;
    line-height:22px;
}
#certify .gallery-item-desc a {
    bottom: 51px;
    left: 55px;
}
}
/*手机端*/
@media only screen and (max-width:750px){
#main{
    width:100%;
    height:540px;
}
#certify  .swiper-slide {
    width: 100%;
    height: 404px;
    overflow:hidden;
}
#certify  .swiper-slide > img{
    position: relative;
    width: auto;
    left:-100px;
    min-height: 404px;
}
#certify .cover{
    width: 100%;
    height: auto;
}
#certify .gallery-item-desc {
    width: 317px;
    height: 346px;
    top:29px;
    left: 50%;
    transform: translateX(-50%); 
}
#certify .gallery-item-desc div{
    width: 64px;
    height: 74px;
    top:-29px;
    right:22px;
    padding-top: 29.5px;
    font-size: 29px;
    line-height:74px;
}
#certify .gallery-item-desc .typography-label {
    top: 96px;
    left: 44px;
    line-height: 36px;
    font-size: 36px;
}
#certify .gallery-item-desc .typography-body {
    top: 152px;
    left: 44px;
    width: 210px;
    font-size: 14px;
    line-height:22px;
}
#certify .gallery-item-desc a {
    bottom: 48px;
    left: 44px;
    font-size: 14px;
}
#certify .gallery-item-desc a > img{
    position: relative;
    top: -1.5px;
    left: 0;
    display: inline-block;
    padding-left: 10px;
    width: 13px;
    height: 7.5px;
    transition: left .3s;
}
#certify .gallery-item-desc a:hover img{
    left: 6px;
    transition: left .3s;
}
}
/*手机端1080
@media only screen and (min-height:1300px){
#main{
    width:100%;
    height:1573px;
}
#certify  .swiper-slide {
    width: 100%;
    height: 1165px;
    overflow:hidden;
    margin: 0;
}
#certify  .swiper-slide img{
    position: relative;
    width: auto;
    left:-200px;
    height: 1165px;
}
#certify .cover{
    width: 100%;
    height: auto;
}
#certify .gallery-item-desc {
    width: 912px;
    height: 997px;
    top:84px;
    left: 50%;
    transform: translateX(-50%); 
}
#certify .gallery-item-desc div{
    width: 184px;
    height: 204px;
    top:-84px;
    right:64px;
    padding-top: 94px;
    font-size: 84px;
    line-height:204px;
}
#certify .gallery-item-desc .typography-label {
    top: 297px;
    left: 124px;
    line-height: 104px;
    font-size: 104px;
}
#certify .gallery-item-desc .typography-body {
    top: 487px;
    left: 124px;
    width: 600px;
    font-size: 40px;
    line-height:80px;
}
#certify .gallery-item-desc a {
    bottom: 141px;
    left: 124px;
    font-size: 40px;
}
#certify .gallery-item-desc a > img{
    position: relative;
    top: -4px;
    left: 0;
    display: inline-block;
    padding-left: 20px;
    width: 38px;
    height: 22px;

    transition: left .3s;
}
#certify .gallery-item-desc a:hover img{
    left: 12px;
    transition: left .3s;
}
}*/
</style>
