<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<p>凝思软件在研发凝思安全操作系统的过程中，积累了大量的操作系统内核和Linux环境应用软件实现的技术经验，在与行业用户的联合开发和项目实施过程中积累了大量工程经验，能够为最终用户提供符合行业应用特点的软件开发、性能优化和安全增强方案。凝思软件在应用程序开发方面也具有丰富的技术积累和实践经验，可为用户开发特定用途的服务端和客户端软件，也可配合用户完成应用软件的设计、实现和后期调试，为用户提供研发级的技术支持。凝思软件还可配合第三方厂商完成凝思安全机制的相关配置，实现应用程序安全增强，提升用户程序的安全性。</p>
					<p>凝思软件的平台类定制开发服务可以满足用户的个性化需求，定制和裁剪应用软件最小运行环境，定制符合特定需求的开发环境和硬件驱动程序，定制开发专用软件和软件固化，并可以实现定制的安全策略。 凝思软件充分利用所掌握的操作系统核心技术和开发经验，研制了多款软硬一体的设备平台，实现特定用户环境下的产品定制。</p>
				</div>
			</div>
			<div class="textBox tableinBox">
				<table v-show="bodyWidthMut > 750" >
					<tr>
						<th>设备名称</th>
						<th>设备用途</th>
						<th>部署情况</th>
					</tr>
					<tr v-for="(equipment,index) in equipmentList" :key="index">
						<td>{{equipment.name}}</td>
						<td>{{equipment.purpose}}</td>
						<td>{{equipment.situation}}</td>
					</tr>
				</table>
				<div
					v-show="bodyWidthMut <= 750"
					class="swiper-container"
					id="CustomDevelopmentSwiper"
				>
				    <div class="swiper-wrapper">
				        <div
				        	class="swiper-slide"
				        	v-for="(equipment,index2) in equipmentList"
				        	:key="index2 + '-1'"
				        >
				            <div>
				            	<p><b>设备名称</b>{{equipment.name}}</p>
				            </div>
				            <div>
				            	<p><b>设备用途</b>{{equipment.purpose}}</p>
				            </div>
				            <div>
				            	<p><b>部署情况</b>{{equipment.situation}}</p>
				            </div>
				        </div>
				    </div>
				    <div class="bottom-nav">
						<div class="swiper-pagination"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swiper from 'swiper';

export default {
	name:'PlatformProductCustomDevelopment',
	data() {
      return {
        bodyWidthMut: document.body.clientWidth,
        equipmentList:[
        	{
        		name:'凝思电力安全文件网关',
        		purpose:'为国家电网调度系统研制的专用安全文件传输设备',
        		situation:'广泛部署在国家电网调度中心、各级网省调度中心及重要的变电站、换流站',
        	},
        	{
        		name:'凝思双系统隔离平台',
        		purpose:'工信部定制的专用网络数据传输平台',
        		situation:'广泛部署于电信网络各关键节点',
        	},
        	{
        		name:'凝思安全管理平台',
        		purpose:'总参定制的军队列装设备',
        		situation:'广泛部署于全军系统',
        	},
        ]
      }
    },
    mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
      this.CustomDevelopmentSwiper = new Swiper('#CustomDevelopmentSwiper', {
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: false,
            // loopedSlides: 4,
            autoplay: false,
            pagination: {
				el: '.swiper-pagination',
				clickable: false,
				bulletClass:'my-bullet',
				bulletActiveClass: 'my-bullet-active',
			}
        });
    },
}
</script>
<style scope>
/*手机端*/
@media only screen and (max-width:750px){
	#CustomDevelopmentSwiper{
		margin:6px 15px 0;
		width: calc(100% - 30px);
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div{
		position:relative;
		height:104px;
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div:nth-child(1){
		background-color:#00a4b7;
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div:nth-child(2){
		background-color:#1f609e;
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div:nth-child(3){
		background-color:#96999c;
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div p{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		font-size:14px;
		color:#fff;
		line-height:18px;
		font-weight:500;
		margin:0 30px;
		padding:0;
	}
	#CustomDevelopmentSwiper .swiper-wrapper .swiper-slide div p b{
		display:block;
		font-size:14px;
		color:#fff;
		line-height:18px;
		font-weight:400;
		margin:0 0 7px;
	}
	#CustomDevelopmentSwiper .bottom-nav{
		position:relative;
		height:60px;
		padding-top:16px;
		text-align:center;
	}
	#CustomDevelopmentSwiper .bottom-nav .swiper-pagination{
		width:100%;
		text-align:center;
	}
	.my-bullet{
		display:inline-block;
		width: 5px;
		height: 5px;
		border-radius:50%;
		border: 0.5px solid #dd1d29;
		margin: 0 3px;
		background-color: #fff;
	}
	.my-bullet-active {
		background-color: #dd1d29;
	}
}
element {

}
#CustomDevelopmentSwiper .bottom-nav .swiper-pagination-bullet {

    width: 5px;
    height: 5px;
    border: 0.5px solid #dd1d29;
    margin: 0 3px;
    background-color: #fff;

}
.swiper-pagination-clickable .swiper-pagination-bullet {

    cursor: pointer;

}
.swiper-pagination-bullet-active {

    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));

}

</style>
