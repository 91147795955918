<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思多路径软件主要功能:</h2>
					<div class="textDisplay">
						<div>
							<h4>物理路径管理</h4>
							<p>能够将应用服务器和存储系统之间的多条路径通过内核模块和多路径应用服务模块虚拟了一个设备，为系统和上层应用屏蔽了多条路径的细节，解决了多条路径引起的数据读写间的冲突和错误，提高了数据读写的安全性和可靠性。</p>
						</div>
						<div>
							<h4>路径故障切换</h4>
							<p>多路径软件可以自动检测路径故障，并将有故障的路径甄别出来，选择其他正常的路径继续进行数据读写，避免了路径故障引起的业务服务的中断。</p>
						</div>
						<div>
							<h4>路径恢复</h4>
							<p>路径的故障得到解决后，该路径可以立即加入到路径调度中，正常进行数据IO（Input Output）。</p>
						</div>
						<div>
							<h4>数据IO负载均衡</h4>
							<p>多路径软件实现在了多个路径中的数据传输的均衡分布，可根据配置策略进行数据IO的调度。</p>
						</div>
						<div>
							<h4>可配置性</h4>
							<p>多路径软件提供配置能力，可配置块设备，可配置路径，适应不同的配置需求。</p>
						</div>
						<div>
							<h4>硬件兼容性</h4>
							<p>凝思多路径软件支持主流的存储阵列。</p>
						</div>
						<div>
							<h4>操作系统兼容性</h4>
							<p>多路径软件与凝思安全操作系统做了充分的兼容性测试，保证了多路径软件运行的稳定性和可靠性。</p>
						</div>
						<div>
							<h4>系统软件兼容性</h4>
							<p>与LVM（Logical Volume Manager）逻辑卷软件的兼容性良好，可满足不同的分区配置需求，可方便后续存储分区扩容和维护。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'Multipath',
}
</script>
<style scoped>

</style>
