<!-- 隐私政策 -->
<template>
    <div class="fontmain fontmainNotitle1"> 
        <div class="font" id="font1">
          <div class="title">
              <h1>
                  <span>隐私政策</span>
              </h1>
          </div>
          <div class="details">
              <h2>声明</h2>
              <p>保护您的隐私对于北京凝思软件股份有限公司（以下简称“凝思软件”）而言至关重要，我们希望始终获得您的信任。</p>
              <p>本隐私条约解释了凝思软件的信息、数据收集和使用行为。我们希望这将有助于您在知情的情况下，就和我们共享个人信息的问题做出决定。</p>
              <h2>关于隐私的问题和访问</h2>
              <p>如果您有任何疑问和建议，可以通过本网站上的联系方式与凝思软件取得联系，凝思软件将尽最大的努力去解决。</p>
              <h2>本隐私条约的修改</h2>
              <p>本隐私条约的修改权和解释权属于凝思软件。凝思软件会随时更新本条约并予以公布，更新的内容自公布之日起生效，请您定期访问，以便及时了解。</p>
              <h2>到非凝思软件网站站点的链接</h2>
              <p>本网站可能包含与其他站点的链接，凝思软件不对其他站点的隐私条约或内容负责。</p>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
}
</script>
