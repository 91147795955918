<!-- 二级页面顶部 -->
<template>
    <div id="topPage">
        <div id="title1">{{this.$attrs.tittle}}</div>
        <div id="under-title1"><p></p></div>
        <div id="outOftitle2">
            <!-- 解决方案 -->
            <div class="title2 title2OfSolutions" v-show="this.$attrs.path == '/Solutions'">
                <router-link
                    to="/Solutions/Government"
                    :class="this.$route.path.includes('/Solutions/Government') ? 'title3_1' : 'title3'"
                    :id="this.$route.path.includes('/Solutions/Government') ? 'title31_21' : 'title3_21'"
                ><span>政府机关</span></router-link>
                <router-link
                    to="/Solutions/PoverIndustry"
                    :class="this.$route.path.includes('/Solutions/PoverIndustry') ? 'title3_1' : 'title3'"
                    :id="this.$route.path.includes('/Solutions/PoverIndustry') ? 'title31_22' : 'title3_22'"
                ><span>电力行业</span></router-link>
                <router-link
                    to="/Solutions/Rail"
                    :class="this.$route.path.includes('/Solutions/Rail') ? 'title3_1' : 'title3'"
                    :id="this.$route.path.includes('/Solutions/Rail') ? 'title31_23' : 'title3_23'"
                ><span>轨道交通</span></router-link>
                <router-link
                    to="/Solutions/Army"
                    :class="this.$route.path.includes('/Solutions/Army') ? 'title3_1' : 'title3'"
                    :id="this.$route.path.includes('/Solutions/Army') ? 'title31_24' : 'title3_24'"
                ><span>军队</span></router-link>
                <router-link
                    to="/Solutions/ResearchInstitutes"
                    :class="this.$route.path.includes('/Solutions/ResearchInstitutes') ? 'title3_1' : 'title3'"
                    :id="this.$route.path.includes('/Solutions/ResearchInstitutes') ? 'title31_25' : 'title3_25'"
                ><span>科研院所</span></router-link>
            </div>
            <!-- 生态合作 -->
            <div class="title2 title2OfEcologicalCooperation" v-show="this.$attrs.path == '/EcologicalCooperation'">
                <router-link
                    to="/EcologicalCooperation/CooperativeValue"
                    :class="this.$route.path == '/EcologicalCooperation/CooperativeValue' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/EcologicalCooperation/CooperativeValue' ? 'title31_31' : 'title3_31'"
                ><span>合作价值</span></router-link>
                <router-link
                    to="/EcologicalCooperation/EcologicalPartner"
                    :class="this.$route.path == '/EcologicalCooperation/EcologicalPartner' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/EcologicalCooperation/EcologicalPartner' ? 'title31_32' : 'title3_32'"
                ><span>生态伙伴</span></router-link>
                <router-link
                    to="/EcologicalCooperation/EcologicalLaboratory"
                    :class="this.$route.path == '/EcologicalCooperation/EcologicalLaboratory' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/EcologicalCooperation/EcologicalLaboratory' ? 'title31_33' : 'title3_33'"
                ><span>生态实验室</span></router-link>
                <router-link
                    to="/EcologicalCooperation/CompatibleList"
                    :class="this.$route.path == '/EcologicalCooperation/CompatibleList' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/EcologicalCooperation/CompatibleList' ? 'title31_34' : 'title3_34'"
                ><span>兼容列表</span></router-link>
            </div>
            <!-- 服务与支持 -->
            <div class="title2 title2OfServiceAndSupport" v-show="this.$attrs.path == '/ServiceAndSupport'">
                <router-link
                    to="/ServiceAndSupport/SoftwareDownload"
                    :class="this.$route.path == '/ServiceAndSupport/SoftwareDownload' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/ServiceAndSupport/SoftwareDownload' ? 'title31_41' : 'title3_41'"
                ><span>软件下载</span></router-link>
                <router-link
                    to="/ServiceAndSupport/DocumentDownload"
                    :class="this.$route.path == '/ServiceAndSupport/DocumentDownload' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/ServiceAndSupport/DocumentDownload' ? 'title31_42' : 'title3_42'"
                ><span>文档下载</span></router-link>
                <router-link
                    to="/ServiceAndSupport/ServicePrecess"
                    :class="this.$route.path == '/ServiceAndSupport/ServicePrecess' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/ServiceAndSupport/ServicePrecess' ? 'title31_43' : 'title3_43'"
                ><span>服务流程</span></router-link>
                <router-link
                    to="/ServiceAndSupport/CommonQuestions"
                    :class="this.$route.path == '/ServiceAndSupport/CommonQuestions' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/ServiceAndSupport/CommonQuestions' ? 'title31_44' : 'title3_44'"
                ><span>常见问题</span></router-link>
                <router-link
                    to="/ServiceAndSupport/SecurityNotice"
                    :class="this.$route.path == '/ServiceAndSupport/SecurityNotice' || this.$route.path == '/ServiceAndSupport/NoticeDetails' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/ServiceAndSupport/SecurityNotice' || this.$route.path == '/ServiceAndSupport/NoticeDetails' ? 'title31_45' : 'title3_45'"
                ><span>安全公告</span></router-link>
            </div>
            <!-- 关于凝思 -->
            <div class="title2 title2OfAboutLinx" v-show="this.$attrs.path == '/AboutLinx'">
                <router-link
                    to="/AboutLinx/Company"
                    :class="this.$route.path == '/AboutLinx/Company' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/Company' ? 'title31_51' : 'title3_51'"
                ><span>公司简介</span></router-link>
                <router-link
                    to="/AboutLinx/Founder"
                    :class="this.$route.path == '/AboutLinx/Founder' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/Founder' ? 'title31_52' : 'title3_52'"
                ><span>创始人简介</span></router-link>
                <!-- <router-link
                    to="/AboutLinx/DevelopmentPath"
                    :class="this.$route.path == '/AboutLinx/DevelopmentPath' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/DevelopmentPath' ? 'title31_53' : 'title3_53'"
                ><span>发展历程</span></router-link> -->
               <router-link
                    to="/AboutLinx/QualificationHonor"
                    :class="this.$route.path == '/AboutLinx/QualificationHonor' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/QualificationHonor' ? 'title31_54' : 'title3_54'"
                ><span>资质荣誉</span></router-link>
                <router-link
                    to="/AboutLinx/News"
                    :class="this.$route.path == '/AboutLinx/News' || this.$route.path == '/AboutLinx/NewsDetails' || this.$route.path == '/AboutLinx/SearchNews' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/News' || this.$route.path == '/AboutLinx/NewsDetails' || this.$route.path == '/AboutLinx/SearchNews' ? 'title31_55' : 'title3_55'"
                ><span>新闻中心</span></router-link>
                <router-link
                    to="/AboutLinx/Enrollful"
                    :class="this.$route.path == '/AboutLinx/Enrollful' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/Enrollful' ? 'title31_58' : 'title3_58'"
                ><span>招贤纳士</span></router-link>
                <router-link
                    to="/AboutLinx/Contact"
                    :class="this.$route.path == '/AboutLinx/Contact' ? 'title3_1' : 'title3'"
                    :id="this.$route.path == '/AboutLinx/Contact' ? 'title31_59' : 'title3_59'"
                ><span>联系我们</span></router-link>
            </div>
            <!-- <div class="swiper-button" v-show="this.$attrs.path == '/AboutLinx' || this.$attrs.path == '/Solutions'">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'TopOfPage',
    data() {
        return {
            sPerView: 7.6,
            windowWidth:window.innerWidth,
            windowHeight:window.innerHeight,
            whether:false
        }
    },
    mounted(){
        window.onresize = () =>{
            return (() => {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
          	})()
        }
        window.addEventListener("popstate", function(e) { 
			location. reload()
		}, false);
        this.swiper = new Swiper('.swiper-container', {
            slidesPerView: this.sPerView,
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
		    loop:false
        });
    },
    watch:{
        windowWidth:{
            immediate:true,
            handler(){
                if(window.innerHeight >= 1300){
                    this.sPerView = (window.innerWidth - 64) / 309;
                }else if(window.innerWidth <= 750){
                    this.sPerView = (window.innerWidth - 28) / 230;
                }else if(window.innerWidth <= 1024){
                    this.sPerView = (window.innerWidth - 28) / 142;
                }else if(window.innerWidth <= 1170){
                    this.sPerView = (window.innerWidth - 100) / 142;
                }else{
                    this.sPerView = 1074 / 142;
                }
            }
        }
    }
}
</script>

<style scoped>
#topPage{
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 154px;
    z-index: 1;
    padding-bottom: 28px;
}
#title1{
    width: 1170px;
    color: #141414;
    font-size: 40px;
    letter-spacing:2px;
    margin: 0 auto;
    font-weight: 500;
}
#under-title1{
    height: 84px;
    width: 1170px;
    margin: 0 auto;
    padding-top: 24px;
}
#under-title1 p{
    width: 40px;
    height: 4px;
    background-color: #cbcbcb;
}

.title2{
    position: relative;
    margin-left: calc(50% - 585px);
}
.title3{
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: 124px;
    height: 94px;
    word-break: break-all;
    margin-right: 16px;
    border: rgb(221,29,41) 1px solid;
    font-size: 18px;
    letter-spacing:0px;
    color: rgb(221,29,41);
    background-repeat: no-repeat;
    background-position:center left;
    font-weight: 500;
}
.title3 span,.title3_1 span{
    position: relative;
    line-height: 22px;
    display: inline-block;
    width: 72px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-repeat: no-repeat;
    background-position:center left;
    white-space:nowrap;
}
.title3_1,.title3:hover{
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: 124px;
    height: 94px;
    word-break: break-all;
    margin-right: 16px;
    border: rgb(221,29,41) 1px solid;
    color: rgb(255,255,255);
    background-color: rgb(221,29,41);
    font-size: 18px;
    letter-spacing:0px;
    background-repeat: no-repeat;
    background-position:center left;
    font-weight: 500;
}
.swiper-container {
    width: calc(1170px - 88px);
    margin-left: calc(50% - 585px + 44px)!important;
}
.swiper-container .swiper-slide .title3,
.swiper-container .swiper-slide .title3:hover,
.swiper-container .swiper-slide .title3_1{
    display: block;
    margin: 0 auto!important;
}
.swiper-button{
    width: 1170px;
    margin: 0 auto;
    position: relative;
    top: -100px;
}
.swiper-button .swiper-button-next{
    display: block;
	height: 94px;
    width: 32px;
    border: #cbcbcb 1px solid;
    color: rgba(0, 0, 0,0);
    opacity: 1;
    background: url("./images/新闻中心-向右-不可用.png") no-repeat;
    background-position: center center;
    right: 0px;
    top:26px;
    outline: none;
}
.swiper-button .swiper-button-prev{
    display: block;
	height: 94px;
    width: 32px;
    border: #cbcbcb 1px solid;
    color: rgba(0, 0, 0,0);
    opacity: 1;
    background: url("./images/新闻中心-向左-不可用.png") no-repeat;
    background-position: center center;
    left: 0px;
    top:26px;
    outline: none;
}
.swiper-button .swiper-button-next:hover{
    background:url("./images/新闻中心-向右.png") no-repeat;
    background-position: center center;
    border: rgb(221,29,41) 1px solid;
}
.swiper-button .swiper-button-prev:hover{
    background:url("./images/新闻中心-向左.png") no-repeat;
    background-position: center center;
    border: rgb(221,29,41) 1px solid;
}
/* 五个字*/
#title3_33 span, #title31_33 span,
#title3_52 span, #title31_52 span,
#title3_57 span, #title31_57 span{
    width: 90px;
}
/* 两个字*/
#title3_24 span, #title31_24 span{
    width: 36px;
}
/*Solutions*/
#title3_21{
    background-image: url(./images/政府.png);
}
#title3_22{
    background-image: url(./images/电力.png);
}
#title3_23{
    background-image: url(./images/轨道.png);
}
#title3_24{
    background-image: url(./images/军队.png);
}
#title3_25{
    background-image: url(./images/科研.png);
}
#title31_21, #title3_21:hover{
    background-image: url(./images/政府-白.png);
}
#title31_22, #title3_22:hover{
    background-image: url(./images/电力-白.png);
}
#title31_23, #title3_23:hover{
    background-image: url(./images/轨道-白.png);
}
#title31_24, #title3_24:hover{
    background-image: url(./images/军队-白.png);
}
#title31_25, #title3_25:hover{
    background-image: url(./images/科研-白.png);
}
/*EcologicalCooperation*/
#title3_31{
    background-image: url(./images/合作价值-静默.png);
}
#title3_32{
    background-image: url(./images/生态伙伴-静默.png);
}
#title3_33{
    background-image: url(./images/生态实验室-静默.png);
}
#title3_34{
    background-image: url(./images/兼容列表-静默.png);
}
#title31_31, #title3_31:hover{
    background-image: url(./images/合作价值-选中.png);
}
#title31_32, #title3_32:hover{
    background-image: url(./images/生态伙伴-选中.png);
}
#title31_33, #title3_33:hover{
    background-image: url(./images/生态实验室-选中.png);
}
#title31_34, #title3_34:hover{
    background-image: url(./images/兼容列表-选中.png);
}
/*ServiceAndSupport*/
#title3_41{
    background-image: url(./images/下载-静默.png);
}
#title3_42{
    background-image: url(./images/文档下载-静默.png);
}
#title3_43{
    background-image: url(./images/服务流程-静默.png);
}
#title3_44{
    background-image: url(./images/问题-静默.png);
}
#title3_45{
    background-image: url(./images/安全公告-静默.png);
}
#title31_41, #title3_41:hover{
    background-image: url(./images/下载-选中.png);
}
#title31_42, #title3_42:hover{
    background-image: url(./images/文档下载-选中.png);
}
#title31_43, #title3_43:hover{
    background-image: url(./images/服务流程-选中.png);
}
#title31_44, #title3_44:hover{
    background-image: url(./images/问题-选中.png);
}
#title31_45, #title3_45:hover{
    background-image: url(./images/安全公告-选中.png);
}
/*AboutLinx*/
#title3_51{
    background-image: url(./images/公司.png);
}
#title3_52{
    background-image: url(./images/创始人.png);
}
#title3_53{
    background-image: url(./images/发展.png);
}
#title3_54{
    background-image: url(./images/资质.png);
}
#title3_55{
    background-image: url(./images/新闻.png);
}
#title3_56{
    background-image: url(./images/合作.png);
}
#title3_57{
    background-image: url(./images/投资者.png);
}
#title3_58{
    background-image: url(./images/招贤.png);
}
#title3_59{
    background-image: url(./images/联系.png);
}
#title31_51, #title3_51:hover{
    background-image: url(./images/公司-白.png);
}
#title31_52, #title3_52:hover{
    background-image: url(./images/创始人-白.png);
}
#title31_53, #title3_53:hover{
    background-image: url(./images/发展-白.png);
}
#title31_54, #title3_54:hover{
    background-image: url(./images/资质-白.png);
}
#title31_55, #title3_55:hover{
    background-image: url(./images/新闻-白.png);
}
#title31_56, #title3_56:hover{
    background-image: url(./images/合作-白.png);
}
#title31_57, #title3_57:hover{
    background-image: url(./images/投资者-白.png);
}
#title31_58, #title3_58:hover{
    background-image: url(./images/招贤-白.png);
}
#title31_59, #title3_59:hover{
    background-image: url(./images/联系-白.png);
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    body{
        background-size:100%;
    }
    /* 顶部导航栏 */
    #topPage{
        padding-top: 136px;
    }
    #title1{
        width: calc(100% - 60px);
        padding-left: 60px;
    }
    #under-title1{
        height: 51px;
        width: calc(100% - 60px);
        margin-left: 60px;
        padding-top: 26px;
    }
    #under-title1 p{
        height: 3px;
    }
    .title2{
        margin-left: 60px;
        height: 96px;
    }
    .swiper-container{
        width: calc(100% - 100px);
        padding:0 50px;
        margin: 0 auto!important;
    }
    .swiper-button{
        display:none;
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
    body{
        background-size:1024px;
    }
/* 顶部导航栏 */
    #topPage{
        padding-top: 128px;
    }
    #title1{
        width: calc(100% - 24px);
        padding-left: 24px;
    }
    #under-title1{
        height: 51px;
        width: calc(100% - 24px);
        margin-left: 24px;
        padding-top: 26px;
    }

    #outOftitle2{
        overflow-y: hidden;
        overflow-x: auto;
        white-space:nowrap;
    }
    #outOftitle2 .title2OfSolutions{
        width: 710px;
        margin: 0;
        padding-left: 24px;
        padding-right: 8px;
    }
    #outOftitle2 .title2OfEcologicalCooperation{
        width: 568px;
        margin: 0;
        padding-left: 24px;
        padding-right: 8px;
    }
    #outOftitle2 .title2OfServiceAndSupport{
        width: 710px;
        margin: 0;
        padding-left: 24px;
        padding-right: 8px;
    }
    #outOftitle2 .title2OfAboutLinx{
        width: 852px;
        margin: 0;
        padding-left: 24px;
        padding-right: 8px;
    }
    .swiper-container{
        width: calc(100% - 28px);
        padding:0 14px;
        margin: 0 auto;
    }
}
/*手机端*/
@media only screen and (max-width:750px){
    /* 顶部导航栏  */
    #topPage{
        min-width: 0px;
        padding-top: 90px;
        padding-bottom: 28px;
    }
    #title1{
        /* width: calc(100% - 15px); */
        padding-left: 15px;
        margin: 0;
        font-size: 26px;
    }
    #under-title1{
        height:34px;
        width: calc(100% - 15px);
        margin-left: 15px;
        padding-top: 20px;
    }
    #under-title1 p{
        width: 28px;
        height: 2px;
    }
    .title2{
        margin: 0;
        height: 61px;
    }
    .title3,.title3_1,.title3:hover{
        width: 97px;
        height: 59px;
        border: rgb(221,29,41) 1px solid;
        font-size: 13px;
    }
    .title3 span,.title3_1 span{
        line-height: 16px;
        width: 52px;
    }
    /* 五个字*/
    #title3_33 span, #title31_33 span,
    #title3_52 span, #title31_52 span,
    #title3_57 span, #title31_57 span{
        width: 65px;
    }
	#title3_24 span, #title31_24 span{
		width: 26px;
	}
    .title2 a{
        background-image: none!important;
    }
    #outOftitle2{
        overflow-y: hidden;
        overflow-x: auto;
        white-space:nowrap;
    }
    #outOftitle2 .title2OfSolutions{
        width: 535px;
        margin: 0;
        padding-left: 15px;
        padding-right: 7px;
    }
    #outOftitle2 .title2OfEcologicalCooperation{
        width: 428px;
        margin: 0;
        padding-left:15px;
        padding-right: 7px;
    }
    #outOftitle2 .title2OfServiceAndSupport{
        width: 535px;
        margin: 0;
        padding-left: 15px;
        padding-right: 7px;
    }
    #outOftitle2 .title2OfAboutLinx{
        width: 688px;
        margin: 0;
        padding-left: 15px;
    }
    .swiper-container{
        width: calc(100% - 14px);
        padding:0 7px;
        margin: 0 auto!important;
    }
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
    body{
        background-size:1080px;
    }
    顶部导航栏
    #topPage{
        min-width: 0px;
        padding-top: 266px;
        padding-bottom: 70px;
    }
    #title1{
        padding:0;
        width: calc(100% - 88px);
        font-size: 74px;
    }
    #under-title1{
        height:78px;
        width: calc(100% - 88px);
        margin-left: 44px;
        padding-top: 56px;
    }
    #under-title1 p{
        width: 80px;
        height: 6px;
    }
    .title2{
        margin: 0;
        height: 176px;
    }

    .title3,.title3_1,.title3:hover{
        width: 281px;
        height: 172px;
        border: rgb(221,29,41) 2px solid;
        font-size: 40px;
    }
    .title3 span,.title3_1 span{
        line-height: 40px;
        width: 160px;
    }
    五个字
    #title3_33 span, #title31_33 span,
    #title3_52 span, #title31_52 span,
    #title3_57 span, #title31_57 span{
        width: 200px;
    }
	#title3_24 span, #title31_24 span{
		width: 80px;
	}
    .title2 a{
        background-image: none!important;
    }
    #outOftitle2{
        overflow-y: hidden;
        overflow-x: scroll;
        white-space:nowrap;
    }
    #outOftitle2 .title2OfSolutions{
        width: 1505px;
        margin: 0;
        padding-left: 44px;
        padding-right: 28px;
    }
    #outOftitle2 .title2OfEcologicalCooperation{
        width: 1204px;
        margin: 0;
        padding-left: 44px;
        padding-right: 28px;
    }
    #outOftitle2 .title2OfServiceAndSupport{
        width: 1505px;
        margin: 0;
        padding-left: 44px;
        padding-right: 28px;
    }
    .swiper-container{
        width: calc(100% - 64px);
        padding:0 32px;
        margin: 0 auto!important;
    }
} */
</style>
