/* 常见问题使用的模块仓库 */
const state = {
	detailList:[
		//&&index4 != 4
		{
			tittle:'安装nvidia显卡驱动',
			text:{
				problem:[
					'机器插有英伟达显卡时需要安装显卡驱动'
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 查看显卡在系统是否识别，并确认显卡型号，根据显卡型号在英伟达官网下载对应驱动',
						detail:[
							{
								code:[
									'~# lspci |grep -i vga',
									'~# lspci -knn | grep NVIDIA',
									'<b>04:00.0 vGA compatibie controller [0300]: NVIDIA Corporation GM107GL [Quadro K2200] [10de:13ba] (rev a2)</b>',
								]
							}
						]
					},
					{
						tittle:'2. 进入单用户模式或字符模式（ctrl+alt+f2），关闭图形服务并禁用nouveau模块，如下以字符模式为例，进入字符模式后根据系统版本执行以下命令',
						detail:[
							{
								tittle:['<span>60系统</span>'],
								code:[
									'~# /etc/init.d/gdm3 stop<span>#关闭图形服务</span>',
									'~# /etc/init.d/gdm3 status<span>#确认服务已关闭</span>',
									'<b>gdm3 is not running ... failed!</b>',
									'~# rmmod nouveau',
									'~# echo "blacklist nouveau" >> /etc/modprobe.d/fbdev-blacklist.conf',
									'~# update-initramfs -u',
								]
							},
							{
								tittle:['<span>80系统和100系统</span>'],
								code:[
									'~# systemctl stop lightdm.service<span>#关闭图形服务</span>',
									'~# systemctl status lightdm.service<span>#确认服务已关闭</span>',
									'<b>●  lightdm.service - Light Display Manager</b>',
									'<b>&#160;&#160;&#160;Loaded: loaded (/lib/systemd/system/lightdm.service; enabled)</b>',
									'<b>&#160;&#160;&#160;Active: inactive (dead) since 四 2021-06-24 19:24:04 CST; 7s ago</b>',
									'<b>&#160;&#160;&#160;......</b>',
									'~# rmmod nouveau',
									'~# echo "blacklist nouveau" >> /etc/modprobe.d/fbdev-blacklist.conf',
									'~# update-initramfs -u',
								]
							},
						]
					},
					{
						tittle:'3. 查看系统是否已安装nvidia驱动，如果有需要先卸载，过程中选yes确定卸载',
						detail:[
							{
								code:[
									'~# cat /proc/driver/nvidia/version',
									'~# apt-get remove nvidia-*',
								]
							}
						]
					},
					{
						tittle:'4. 将显卡驱动拷贝至目标机器进行安装，nvidia驱动版本大于384.130的要添加参数--no-opengl-files，过程中出现提示输入yes回车下一步即可，如下以390.77版本为例',
						detail:[
							{
								code:[
									'~# chmod +x NVIDIA-Linux-x86_64-390.77.run',
									'~# ./NVIDIA-Linux-x86_64-390.77.run --ui=none --no-opengl-files',
								]
							}
						]
					},
					{
						tittle:'5. 安装完成后重启机器或者启动图形服务即可进入系统'
					},
					{
						tittle:'6. 查看英伟达驱动版本的两种命令',
						detail:[
							{
								code:[
									'~# nvidia-smi -a',
									'~# cat /proc/driver/nvidia/version',
								]
							}
						]
					},
				],
			},
		},
		//软件服务类
		{
			tittle:'60系统常用服务启停',
			text:{
				problem:[
					'6.0.60系统常用服务启停'
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. ftp服务',
						detail:[
							{
								tittle:[
									'<span>端口：21</span>',
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/proftpd start',
									'停止：/etc/init.d/proftpd stop',
									'重启：/etc/init.d/proftpd restart',
									'查看状态：/etc/init.d/proftpd status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig proftpd on',
									'开机不启动：chkconfig proftpd off',
								]
							}
						]
					},
					{
						title:'2. ssh服务',
						detail:[
							{
								tittle:[
									'<span>端口：22</span>',
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/ssh start',
									'停止：/etc/init.d/ssh stop',
									'重启：/etc/init.d/ssh restart',
									'查看状态：/etc/init.d/ssh status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig ssh on',
									'开机不启动：chkconfig ssh off',
								]
							}
						]
					},
					{
						tittle:'3. networking网络服务',
						detail:[
							{
								tittle:[
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/networking start',
									'停止：/etc/init.d/networking stop',
									'重启：/etc/init.d/networking restart',
									'查看状态：/etc/init.d/networking status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig proftpd on',
									'开机不启动：chkconfig proftpd off',
								]
							}
						]
					},
					{
						tittle:'4. telnet服务',
						detail:[
							{
								tittle:[
									'<span>端口：23</span>',
									'<span>服务启停</span>',
								],
								code:[
									'停止：sed -i "/^telnet/s/^/#/" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
									'开启：sed -i "/^#telnet/s/#//" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
								]
							}
						]
					},
					{
						tittle:'5. dns服务',
						detail:[
							{
								tittle:[
									'<span>端口：23</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/bind9 start',
									'停止：/etc/init.d/bind9 stop',
									'重启：/etc/init.d/bind9 restart',
									'查看状态：/etc/init.d/bind9 status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig bind9 on',
									'开机不启动：chkconfig bind9 off',
								]
							}
						]
					},
					{
						tittle:'6. apache服务',
						detail:[
							{
								tittle:[
									'<span>端口：80</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/apache2 start',
									'停止：/etc/init.d/apache2 stop',
									'重启：/etc/init.d/apache2 restart',
									'查看状态：/etc/init.d/apache2 status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig apache2 on',
									'开机不启动：chkconfig apache2 off',
								]
							}
						]
					},
					{
						tittle:'7. tomcat服务',
						detail:[
							{
								tittle:[
									'<span>端口：8080</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/tomcat6 start',
									'停止：/etc/init.d/tomcat6 stop',
									'重启：/etc/init.d/tomcat6 restart',
									'查看状态：/etc/init.d/tomcat6 status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig tomcat6 on',
									'开机不启动：chkconfig tomcat6 off',
								]
							}
						]
					},
					{
						tittle:'8. ntp服务',
						detail:[
							{
								tittle:[
									'<span>端口：123</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/ntp start',
									'停止：/etc/init.d/ntp stop',
									'重启：/etc/init.d/ntp restart',
									'查看状态：/etc/init.d/ntp status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig ntp on',
									'开机不启动：chkconfig ntp off',
								]
							}
						]
					},
					{
						tittle:'9. samba服务',
						detail:[
							{
								tittle:[
									'<span>端口：445</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/samba start',
									'停止：/etc/init.d/samba stop',
									'重启：/etc/init.d/samba restart',
									'查看状态：/etc/init.d/samba status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig samba on',
									'开机不启动：chkconfig samba off',
								]
							}
						]
					},
					{
						tittle:'10. swat服务',
						detail:[
							{
								tittle:[
									'<span>端口：901</span>',
									'<span>服务启停</span>',
								],
								code:[
									'停止：sed -i "/^swat/s/^/#/" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
									'开启：sed -i "/^#swat/s/#//" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
								]
							}
						]
					},
					{
						tittle:'11. gdm3图形服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/gdm3 start',
									'停止：/etc/init.d/gdm3 stop',
									'重启：/etc/init.d/gdm3 restart',
									'查看状态：/etc/init.d/gdm3 status',
								]
							}
						]
					},
					{
						tittle:'12. cron计划任务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/cron start',
									'停止：/etc/init.d/cron stop',
									'重启：/etc/init.d/cron restart',
									'查看状态：/etc/init.d/cron status',
								]
							}
						]
					},
					{
						tittle:'13. mysql服务',
						detail:[
							{
								tittle:[
									'<span>端口：3306</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/mysql start',
									'停止：/etc/init.d/mysql stop',
									'重启：/etc/init.d/mysql restart',
									'查看状态：/etc/init.d/mysql status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig mysql on',
									'开机不启动：chkconfig mysql off',
								]
							}
						]
					},
					{
						tittle:'14. rsyslog日志服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/rsyslog start',
									'停止：/etc/init.d/rsyslog stop',
									'重启：/etc/init.d/rsyslog restart',
									'查看状态：/etc/init.d/rsyslog status',
								]
							}
						]
					},
					{
						tittle:'15. mcelog日志服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/mcelog start',
									'停止：/etc/init.d/mcelog stop',
									'重启：/etc/init.d/mcelog restart',
									'查看状态：/etc/init.d/mcelog status',
								]
							}
						]
					},
					{
						tittle:'16. cups打印机服务',
						detail:[
							{
								tittle:[
									'<span>端口：631</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/cups start',
									'停止：/etc/init.d/cups stop',
									'重启：/etc/init.d/cups restart',
									'查看状态：/etc/init.d/cups status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig cups on',
									'开机不启动：chkconfig cups off',
								]
							}
						]
					},
					{
						tittle:'17. nfs服务',
						detail:[
							{
								tittle:[
									'<span>端口：2049</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/nfs-common start',
									'/etc/init.d/nfs-kernel-server start',
									'/etc/init.d/portmap start',
									'停止：/etc/init.d/nfs-common stop',
									'/etc/init.d/nfs-kernel-server stop',
									'/etc/init.d/portmap stop',
									'重启：/etc/init.d/nfs-common restart',
									'/etc/init.d/nfs-kernel-server restart',
									'/etc/init.d/portmap restart',
									'查看状态：/etc/init.d/nfs-common status',
									'/etc/init.d/nfs-kernel-server status',
									'/etc/init.d/portmap status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig nfs-common on',
									'chkconfig nfs-kernel-server on',
									'chkconfig portmap on',
									'开机不启动：chkconfig nfs-common on',
									'chkconfig nfs-kernel-server on',
									'chkconfig portmap on',
								]
							}
						]
					},
					{
						tittle:'18. snmpd服务',
						detail:[
							{
								tittle:[
									'<span>端口：161</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/snmpd start',
									'停止：/etc/init.d/snmpd stop',
									'重启：/etc/init.d/snmpd restart',
									'查看状态：/etc/init.d/snmpd status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig snmpd on',
									'开机不启动：chkconfig snmpd off',
								]
							}
						]
					},
					{
						tittle:'19. postfix服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/postfix start',
									'停止：/etc/init.d/postfix stop',
									'重启：/etc/init.d/postfix restart',
									'查看状态：/etc/init.d/postfix status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig postfix on',
									'开机不启动：chkconfig postfix off',
								]
							}
						]
					},
					{
						tittle:'20. avahi-daemon服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/avahi-daemon start',
									'停止：/etc/init.d/avahi-daemon stop',
									'重启：/etc/init.d/avahi-daemon restart',
									'查看状态：/etc/init.d/avahi-daemon status',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：chkconfig avahi-daemon on',
									'开机不启动：chkconfig avahi-daemon off',
								]
							}
						]
					}
				],
			},
		},
		{
			tittle:'60系统单网口网络配置',
			text:{
				problem:[
					'单个网络接口设备静态、dhcp以及子网等网络配置方法'
				],
				scope:[
					'linx-6.0.60'
				],
				resolvent:[
					{
						tittle:'1. 打开终端，使用cd命令切换到网络配置目录',
						detail:[
							{
								code:[
									'~# cd /etc/network/',
									'~# ls',
									'<b>if-down.d if-pre-up.d interfaces routes if-post-down.d  if-up.d run</b>',
								]
							},
							{
								tittle:[
									'<span>interfaces：网络配置文件</span>',
									'<span>if-down.d if-post-down.d if-pre-up.d if-up.d：是网络关闭前、网络关闭后、网络建立前、网络建立后都会到/etc/network/里运行相应目录下的脚本</span>',
								]
							}
						]
					},
					{
						tittle:'2. 编辑interfaces网络配置文件，如下以设置eth0静态IP为例',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<br>',
									'# The loopback network interface',
									'<b>auto lo</b><span>#  lo接口统启动时被自动配置</span>',
									'<b>iface lo inet loopback</b><span>#  将lo接口设置为一个本地回环（loopback）地址</span>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto eth0</b><span>#  eth0接口统启动时被自动配置</span>',
									'<b>allow-hotplug eth0</b><span>#  将网络接口设置为热插拔模式</span>',
									'<b>iface eth0 inet static</b><span>#  eth0接口具有一个静态的（static）IP配置</span>',
									'<b><span>address 192.168.1.100</span></b><span>#  ip地址</span>',
									'<b><span>netmask 255.255.255.0</span></b><span>#  掩码</span>',
									'<b><span>network 192.168.1.0</span></b><span>#  网段</span>',
									'<b><span>gateway 192.168.1.254</span></b><span>#  网关</span>',
								]
							}
						]
					},
					{
						tittle:'3. 重新启动网络服务，使ip配置生效',
						detail:[
							{
								code:[
									'~# /etc/init.d/networking restart',
								]
							}
						]
					},
					{
						tittle:'4. 检查ip网络配置是否生效',
						detail:[
							{
								code:[
									'~# ifconfig',
									'<b><span style="text-indent:4em;">Link  encap:Ethernet   HWaddr  00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">inet  addr:192.168.1.100   Bcast:192.168.1.255   Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6  addr:  fe80::20c:29ff:fe7f:98f6/64  Scope:Link</span></b>',
									'<b><span style="text-indent:4em;">UP  BROADCAST  RUNNING  MULTICAST   MTU:1500   Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX  packets:17805  errors:0  dropped:0  overruns:0  frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX  packets:904  errors:0  dropped:0  overruns:0  carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX  bytes:1708959  (1.6  MiB)   TX  bytes:125068  (122.1  KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:8 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:8 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:512 (512.0 B)  TX bytes:512 (512.0 B)</span></b>',
									'~# ping -c 2 192.168.1.1',
									'<b>PING  192.168.1.1  (192.168.1.1)  56(84)  bytes  of  data.</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=1  ttl=64  time=0.057  ms</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=2  ttl=64  time=0.115  ms</b>',
									'<br>',
									'<b>---  192.168.1.1  ping  statistics  ---</b>',
									'<b>2  packets  transmitted,  2  received,  0%  packet  loss,  time  1027ms</b>',
									'<b>rtt  min/avg/max/mdev  =  0.054/0.082/0.132/0.021  ms</b>'
								]
							}
						]
					},
					{
						tittle:'5. 创建子网配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.254</span></b>',
									'<br>',
									'<b>auto eth0:0</b>',
									'<b>allow-hotplug  eth0:0</b>',
									'<b>iface  eth0:0  inet static</b>',
									'<b><span>address  192.168.2.100</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>'

								]
							}
						]
					},
					{
						tittle:'6. 多个ip网络设置如下',
						detail:[
							{
								tittle:[
									'<span>先确定网口数量以及对应名称</span>',
								],
								code:[
									'~# ifconfig -a',
									'<b><span style="text-indent:4em;">Link  encap:Ethernet   HWaddr  00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">inet  addr:192.168.1.100   Bcast:192.168.1.255  Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6  addr:  fe80::20c:29ff:fe7f:98f6/64  Scope:Link</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">TX packets:1232 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:12393 (12.1 KiB)  TX bytes:711382 (694.7 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:00</span><span>eth1</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:116 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:1232 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:12393 (12.1 KiB)  TX bytes:711382 (694.7 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth2</span></b>',
									'<b><span style="text-indent:4em;">UPUP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:13642 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:2696472 (2.5 MiB)  TX bytes:13176 (12.8 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:12 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:712 (712.0 B)  TX bytes:712 (712.0 B)</span></b>'

								]
							},
							{
								tittle:[
									'<span>增加配置eth1和eth2，其中配置文件interfaces只能存在一个默认网关gateway参数，其他网口以添加路由的方式设置</span>'
								],
								code:[
									' ~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.254</span></b>',
									'<br>',
									'<b>auto eth1</b>',
									'<b>allow-hotplug eth1</b>',
									'<b>iface  eth1  inet static</b>',
									'<b><span>address  192.168.2.100</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>',
									'<br>',
									'<b>auto eth2</b>',
									'<b>allow-hotplug eth2</b>',
									'<b>iface eth2 inet static</b>',
									'<b><span>address 192.168.3.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.3.0</span></b>'

								]
							}
						]
					},
					{
						tittle:'7. DHCP自动获取IP地配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<br>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet dhcp</b><span>#动态获取IP地址</span>'

								]
							}
						]
					},
				],
			},

		},
		{
			tittle:'60系统bond网络配置',
			text:{
				problem:[
					'bond静态、子网以及解除bond等网络配置方法',
				],
				scope:[
					'linx-6.0.60'
				],
				resolvent:[
					{
						tittle:'1. bond介绍',
						detail:[
							{
								tittle:[
									'<span>网卡bond（绑定），也称作网卡捆绑，就是将两个或者更多的物理网卡绑定成一个虚逻辑网卡，实现本地网卡的冗余，带宽扩容和负载均衡，在应用部署中是一种常用的技术。多网卡绑定实际上需要提供一个额外的软件的bond驱动程序实现。通过驱动程序可以将多块网卡屏蔽。对TCP/IP协议层只存在一个bond网卡，在 bond程序中实现网络流量的负载均衡，即将一个网络请求重定位到不同的网卡上，来提高总体网络的可用性。</span>',
									'<span>网卡绑定的目的：提高网卡的吞吐量，增强网络的高可用，同时也能实现负载均衡。</span>',
									'<span>bond模式分为以下几种</span>',
									'<span style="text-indent:1em;">Mode=0(balance-rr) Round-robin policy平衡负载模式：平衡轮询策略，具有负载平衡和容错功能bond的网卡MAC为当前活动的网卡的MAC地址，需要交换机设置聚合模式，将多个网卡绑定为一条链路。</span>',
									'<span style="text-indent:1em;">Mode=1(active-backup) 自动备援模式：具有容错功能，只有一块网卡是active,另外一块是备的standby，这时如果交换机配的是捆绑，将不能正常工作，因为交换机往两块网卡发包，有一半包是丢弃的。</span>',
									'<span style="text-indent:1em;">Mode=5(balance-tlb) Adaptive transmit load balancing适配器传输负载均衡，并行发送，无法并行接收，解决了数据发送的瓶颈。是根据每个slave的负载情况选择slave进行发送，接收时使用当前轮到的slave。</span>',
									'<span style="text-indent:1em;">Mode=6(balance-alb) Adaptive load balancing平衡负载模式：在5的tlb基础上增加了rlb。适配器负载均衡模式并行发送，并行接收数据包。</span>',
									'<span style="text-indent:1em;">其实mode=6与mode=0的区别：mode=6，先把eth0流量占满，再占eth1，….ethX；而mode=0的话，会发现2个口的流量都很稳定，基本一样的带宽。而mode=6，会发现第一个口流量很高，第2个口只占了小部分流量。</span>',
								],
							},
						]
					},
					{
						tittle:'2. 编辑网络配置文件，如下将eth0和eth2设置为静态bond0',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<br>',
									'# The loopback network interface',
									'<b>auto lo bond0</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true',
									'<br>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.60</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.254</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth2</span></b>',
									'<b><span>down ifenslave bond0 -d eth0  eth2</span></b>',
									'',
								]
							},
						]
					},
					{
						tittle:'3. 配置bond模块开机加载，在/etc/modules文件末尾加上bonding',
						detail:[
							{
								code:[
									'~# echo "bonding" >> /etc/modules',
								]
							},
						]
					},
					{
						tittle:'4. 指定bond服务模式及主网卡',
						detail:[
							{
								code:[
									'~# vim /etc/modprobe.d/aliases-bond.conf',
									'<b>alias bond0 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=1  miimon=100  downdelay=200  updelay=200  primary=eth0</b>',
								]
							},
							{
								tittle:[
									'<span>参数解释</span>',
									'<span style="text-indent:1em;">mode=1：主备模式</span>',
									'<span style="text-indent:1em;">miimon=100：系统每100ms检测一次链路链接状态</span>',
									'<span style="text-indent:1em;">max_bonds=1：最大的网卡绑定数量为1；当配置二个bond时，参数需要改为2 </span>',
									'<span style="text-indent:1em;">primary=eth0：指定eth0网卡为主网卡，eth2自动为备用网卡</span>',
								],
							},
						]
					},
					{
						tittle:'5. 重启系统让网络配置生效',
					},
					{
						tittle:'6. 检查bond服务配置及网络配置是否生效',
						detail:[
							{
								code:[
									'<b>root@linx:</b>~# cat /proc/net/bonding/bond0',
									'<b>Ethernet Channel Bonding Driver: v3.7.1 (April 27, 2011)</b>',
									'<br>',
									'<b>Bonding Mode: fault-tolerance (active-backup)</b><span>#显示为主备模式</span>',
									'<b>Primary Slave: None</b>',
									'<b>Currently Active Slave: eth0</b>',
									'<b>MII Status: up</b>',
									'<b>MII Polling Interval (ms): 100</b>',
									'<b>Up Delay (ms): 0</b>',
									'<b>Down Delay (ms): 0</b>',
									'<br>',
									'<b>Slave Interface: eth0</b>',
									'<b>MII Status: up</b>',
									'<b>Speed: 1000 Mbps</b>',
									'<b>Duplex: full</b>',
									'<b>Link Failure Count: 0</b>',
									'<b>Permanent HW addr: 00:0c:29:7f:98:f6</b>',
									'<b>Slave queue ID: 0</b>',
									'<br>',
									'<b>Slave Interface: eth2</b>',
									'<b>MII Status: up</b>',
									'<b>Speed: 1000 Mbps</b>',
									'<b>Duplex: full</b>',
									'<b>Link Failure Count: 0</b>',
									'<b>Permanent HW addr: 00:0c:29:7f:98:0a</b>',
									'<b>Slave queue ID: 0</b>',
									'<br>',
									'~# ifconfig',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>bond0</span></b>',
									'<b><span style="text-indent:4em;">inet addr:192.168.1.60  Bcast:192.168.1.255  Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: fe80::20c:29ff:fe7f:98f6/64 Scope:Link</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MASTER MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:90100 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:17628 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:10653054 (10.1 MiB)  TX bytes:10493003 (10.0 MiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING SLAVE MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:45050 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:17616 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:5326579 (5.0 MiB)  TX bytes:10479827 (9.9 MiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING SLAVE MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:45052 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:5326595 (5.0 MiB)  TX bytes:13176 (12.8 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:653 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:653 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:130908 (127.8 KiB)  TX bytes:130908 (127.8 KiB)</span></b>',
									'~# ping -c 2 192.168.1.1',
									'<b>PING  192.168.1.1  (192.168.1.1)  56(84)  bytes  of  data.</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=1  ttl=64  time=0.057  ms</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=2  ttl=64  time=0.115  ms</b>',
									'<br>',
									'<b>---  192.168.1.1  ping  statistics  ---</b>',
									'<b>2  packets  transmitted,  2  received,  0%  packet  loss,  time  1027ms</b>',
									'<b>rtt  min/avg/max/mdev  =  0.054/0.082/0.132/0.021  ms</b>'

								]
							},
						]
					},
					{
						tittle:'7. 创建子网配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<br>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto bond0</b>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.60</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.254</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth2</span></b>',
									'<b><span>down ifenslave bond0 -d eth0  eth2</span></b>',
									'<b><span></span></b>',
									'<br>',
									'<b>auto bond0:0</b>',
									'<b>allow-hotplug  bond0:0</b>',
									'<b>iface bond0:0 inet static</b>',
									'<b><span>address  192.168.2.60</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>',
								]
							},
						]
					},
					{
						tittle:'8. 多bond配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<br>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>pre-up /sbin/nameif -s 1 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s 2 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s 3 00:0c:29:7f:98:0a || true</b>',
									'<b>pre-up /sbin/nameif -s eth0 00:0c:29:7f:98:f6 || true</b>',
									'<b>pre-up /sbin/nameif -s eth1 00:0c:29:7f:98:00 || true</b>',
									'<b>pre-up /sbin/nameif -s eth2 00:0c:29:7f:98:0a || true</b>',
									'<br>',
									'<b>auto bond0</b>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.60</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.254</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth2</span></b>',
									'<b><span>down ifenslave bond0 -d eth0  eth2</span></b>',
									'<br>',
									'<b>auto bond1</b>',
									'<b>allow-hotplug bond1</b>',
									'<b>iface bond1 inet static</b>',
									'<b><span>address 192.168.2.60</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.2.0</span></b>',
									'<b><span>up ifenslave bond1 eth1 eth3</span></b>',
									'<b><span>down ifenslave bond1 -d eth1  eth3</span></b>',
									'<br>',
									'~# vim /etc/modprobe.d/aliases-bond.conf',
									'<b>alias bond0 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=2  miimon=100  downdelay=200  updelay=200  primary=eth0</b>',
									'<b>alias bond1 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=2  miimon=100  downdelay=200  updelay=200  primary=eth1</b>',
								]
							},
						]
					},
					{
						tittle:'9. 解除网卡绑定',
						detail:[
							{
								tittle:[
									'<span>取消bonding服务相关配置，注释/etc/modprobe.d/aliases-bond.conf文件里内容</span>',
								],
								code:[
									'~# vim /etc/modprobe.d/aliases-bond.conf',
									'#alias bond0 bonding',
									'#options  bonding  mode=1  max_bonds=1  miimon=100  downdelay=200  updelay=200  primary=eth0',
								]
							},
							{
								tittle:[
									'<span>注释bonding模块</span>',
								],
								code:[
									'~# vim /etc/modules',
									'# /etc/modules: kernel modules to load at boot time.',
									'#',
									'# This file contains the names of kernel modules that should be loaded ',
									'# at boot time, one per line. Lines beginning with "#" are ignored.',
									'# Parameters can be specified after the module name.',
									'<br>',
									'<b>loop</b>',
									'<b>linx_sec</b>',
									'<b>ipv6</b>',
									'#bonding',
								]
							},
							{
								tittle:[
									'<span>取消网络配置文件/etc/network/interfaces下的bond0设置</span>',
									'<span>重启系统生效</span>',
								]
							},
						]
					},
				],
			},
		},
		{
			tittle:'80系统常用服务启停',
			text:{
				problem:[
					'6.0.80系统常用服务启停',
				],
				scope:[
					'linx-6.0.60'
				],
				resolvent:[
					{
						tittle:'1. ftp服务',
						detail:[
							{
								tittle:[
									'<span>端口：21</span>',
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/proftpd start或systemctl start proftpd.service',
									'停止：/etc/init.d/proftpd stop 或systemctl stop proftpd.service',
									'重启：/etc/init.d/proftpd restart或systemctl restart proftpd.service',
									'查看状态：/etc/init.d/proftpd status或systemctl status proftpd.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  proftpd.service',
									'开机不启动：systemctl disable proftpd.service',
								]
							},
						]
					},
					{
						tittle:'2. ssh服务',
						detail:[
							{
								tittle:[
									'<span>端口：22</span>',
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/ssh start或systemctl start ssh.service',
									'停止：/etc/init.d/ssh stop 或systemctl stop ssh.service',
									'重启：/etc/init.d/ssh restart或systemctl restart ssh.service',
									'查看状态：/etc/init.d/ssh status或systemctl status ssh.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  ssh.service',
									'开机不启动：systemctl disable ssh.service',
								]
							},
						]
					},
					{
						tittle:'3. networking网络服务',
						detail:[
							{
								tittle:[
									'<span>服务启停命令</span>',
								],
								code:[
									'启动：/etc/init.d/networking start或systemctl start networking.service',
									'停止：/etc/init.d/networking stop 或systemctl stop networking.service',
									'重启：/etc/init.d/networking restart或systemctl restart networking.service',
									'查看状态：/etc/init.d/networking status或systemctl status networking.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  networking.service',
									'开机不启动：systemctl disable networking.service',
								]
							},
						]
					},
					{
						tittle:'4. telnet服务',
						detail:[
							{
								tittle:[
									'<span>端口：23</span>',
									'<span>服务启停</span>',
								],
								code:[
									'停止：sed -i "/^telnet/s/^/#/" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
									'开启：sed -i "/^#telnet/s/#//" /etc/inetd.conf',
									'/etc/init.d/openbsd-inetd restart',
								]
							},
						]
					},
					{
						tittle:'5. dns服务',
						detail:[
							{
								tittle:[
									'<span>端口：23</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/bind9 start或systemctl start bind9.service',
									'停止：/etc/init.d/bind9 stop 或systemctl stop bind9.service',
									'重启：/etc/init.d/bind9 restart或systemctl restart bind9.service',
									'查看状态：/etc/init.d/bind9 status或systemctl status bind9.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  bind9.service',
									'开机不启动：systemctl disable bind9.service',
								]
							},
						]
					},
					{
						tittle:'6. apache服务',
						detail:[
							{
								tittle:[
									'<span>端口：80</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/apache2 start或systemctl start apache2.service',
									'停止：/etc/init.d/apache2 stop 或systemctl stop apache2.service',
									'重启：/etc/init.d/apache2 restart或systemctl restart apache2.service',
									'查看状态：/etc/init.d/apache2 status或systemctl status apache2.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  apache2.service',
									'开机不启动：systemctl disable apache2.service',
								]
							},
						]
					},
					{
						tittle:'7. tomcat服务',
						detail:[
							{
								tittle:[
									'<span>端口：8080</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/tomcat7 start或systemctl start tomcat7.service',
									'停止：/etc/init.d/tomcat7 stop 或systemctl stop tomcat7.service',
									'重启：/etc/init.d/tomcat7 restart或systemctl restart tomcat7.service',
									'查看状态：/etc/init.d/tomcat7 status或systemctl status tomcat7.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  tomcat7.service',
									'开机不启动：systemctl disable tomcat7.service',
								]
							},
						]
					},
					{
						tittle:'8. rpcbind服务',
						detail:[
							{
								tittle:[
									'<span>端口：111</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/rpcbind start或systemctl start rpcbind.service',
									'停止：/etc/init.d/rpcbind stop 或systemctl stop rpcbind.service',
									'重启：/etc/init.d/rpcbind restart或systemctl restart rpcbind.service',
									'查看状态：/etc/init.d/rpcbind status或systemctl status rpcbind.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  rpcbind.service',
									'开机不启动：systemctl disable rpcbind.service',
								]
							},
						]
					},
					{
						tittle:'9. ntp服务',
						detail:[
							{
								tittle:[
									'<span>端口：123</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/ntp start或systemctl start ntp.service',
									'停止：/etc/init.d/ntp stop 或systemctl stop ntp.service',
									'重启：/etc/init.d/ntp restart或systemctl restart ntp.service',
									'查看状态：/etc/init.d/ntp status或systemctl status ntp.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  ntp.service',
									'开机不启动：systemctl disable ntp.service',
								]
							},
						]
					},
					{
						tittle:'10. samba服务',
						detail:[
							{
								tittle:[
									'<span>端口：137、138、139、445</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/samba start或systemctl start samba.service',
									'/etc/init.d/nmbd start或systemctl start nmbd.service',
									'/etc/init.d/smbd start或systemctl start smbd.service',
									'停止：/etc/init.d/samba stop 或systemctl stop samba.service',
									'/etc/init.d/nmbd stop 或systemctl stop nmbd.service',
									'/etc/init.d/smbd stop 或systemctl stop smbd.service',
									'重启：/etc/init.d/ntp restart或systemctl restart ntp.service',
									'/etc/init.d/nmbd restart或systemctl restart nmbd.service',
									'/etc/init.d/smbd restart或systemctl restart smbd.service',
									'查看状态：/etc/init.d/samba status或systemctl status samba.service',
									'/etc/init.d/nmbd status或systemctl status nmbd.service',
									'/etc/init.d/smbd status或systemctl status smbd.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  samba.service',
									'systemctl enable  nmbd.service',
									'systemctl enable  smbd.service',
									'开机不启动：systemctl disable samba.service',
									'systemctl disable nmbd.service',
									'systemctl disable smbd.service',
								]
							},
						]
					},
					{
						tittle:'11. lightdm图形服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/lightdm start或systemctl start lightdm.service',
									'停止：/etc/init.d/lightdm stop 或systemctl stop lightdm.service',
									'重启：/etc/init.d/lightdm restart或systemctl restart lightdm.service',
									'查看状态：/etc/init.d/lightdm status或systemctl status lightdm.service',
								]
							},
						]
					},
					{
						tittle:'12. cron计划任务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/cron start或systemctl start cron.service',
									'停止：/etc/init.d/cron stop 或systemctl stop cron.service',
									'重启：/etc/init.d/cron restart或systemctl restart cron.service',
									'查看状态：/etc/init.d/cron status或systemctl status cron.service',
								]
							},
						]
					},
					{
						tittle:'13. mysql服务',
						detail:[
							{
								tittle:[
									'<span>端口：3306</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/mysql start或systemctl start mysql.service',
									'停止：/etc/init.d/mysql stop 或systemctl stop mysql.service',
									'重启：/etc/init.d/mysql restart或systemctl restart mysql.service',
									'查看状态：/etc/init.d/mysql status或systemctl status mysql.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  mysql.service',
									'开机不启动：systemctl disable mysql.service',
								]
							},
						]
					},
					{
						tittle:'14. rsyslog日志服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/rsyslog start或systemctl start rsyslog.service',
									'停止：/etc/init.d/rsyslog stop 或systemctl stop rsyslog.service',
									'重启：/etc/init.d/rsyslog restart或systemctl restart rsyslog.service',
									'查看状态：/etc/init.d/rsyslog status或systemctl status rsyslog.service',
								]
							},
						]
					},
					{
						tittle:'15. mcelog日志服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/mcelog start或systemctl start mcelog.service',
									'停止：/etc/init.d/mcelog stop 或systemctl stop mcelog.service',
									'重启：/etc/init.d/mcelog restart或systemctl restart mcelog.service',
									'查看状态：/etc/init.d/mcelog status或systemctl status mcelog.service',
								]
							},
						]
					},
					{
						tittle:'16. cups打印机服务',
						detail:[
							{
								tittle:[
									'<span>端口：631</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/cups start或systemctl start cups.service',
									'停止：/etc/init.d/cups stop 或systemctl stop cups.service',
									'重启：/etc/init.d/cups restart或systemctl restart cups.service',
									'查看状态：/etc/init.d/cups status或systemctl status cups.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  cups.service',
									'开机不启动：systemctl disable cups.service',
								]
							},
						]
					},
					{
						tittle:'17. nfs服务',
						detail:[
							{
								tittle:[
									'<span>端口：2049</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/nfs-common start或systemctl start nfs-common.service',
									'/etc/init.d/nfs-kernel-server start或systemctl start nfs-kernel-server.service',
									'停止：/etc/init.d/nfs-common stop 或systemctl stop nfs-common.service',
									'/etc/init.d/nfs-kernel-server stop 或systemctl stop nfs-kernel-server.service',
									'重启：/etc/init.d/nfs-common restart或systemctl restart nfs-common.service',
									'/etc/init.d/nfs-kernel-server restart或systemctl restart nfs-kernel-server.service',
									'查看状态：/etc/init.d/nfs-common status或systemctl status nfs-common.service',
									'/etc/init.d/nfs-kernel-server status或systemctl status nfs-kernel-server.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  nfs-common.service',
									'systemctl enable  nfs-kernel-server.service',
									'开机不启动：systemctl disable nfs-common.service',
									'systemctl disable nfs-kernel-server.service',
								]
							},
						]
					},
					{
						tittle:'18. lwresd服务',
						detail:[
							{
								tittle:[
									'<span>端口：921</span>',
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/lwresd start或systemctl start lwresd.service',
									'停止：/etc/init.d/lwresd stop 或systemctl stop lwresd.service',
									'重启：/etc/init.d/lwresd restart或systemctl restart lwresd.service',
									'查看状态：/etc/init.d/lwresd status或systemctl status lwresd.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  lwresd.service',
									'开机不启动：systemctl disable lwresd.service',
								]
							},
						]
					},
					{
						tittle:'19. postfix服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/postfix start或systemctl start postfix.service',
									'停止：/etc/init.d/postfix stop 或systemctl stop postfix.service',
									'重启：/etc/init.d/postfix restart或systemctl restart postfix.service',
									'查看状态：/etc/init.d/postfix status或systemctl status postfix.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  postfix.service',
									'开机不启动：systemctl disable postfix.service',
								]
							},
						]
					},
					{
						tittle:'20. avahi-daemon服务',
						detail:[
							{
								tittle:[
									'<span>服务启停</span>',
								],
								code:[
									'启动：/etc/init.d/avahi-daemon start或systemctl start avahi-daemon.service',
									'停止：/etc/init.d/avahi-daemon stop 或systemctl stop avahi-daemon.service',
									'重启：/etc/init.d/avahi-daemon restart或systemctl restart avahi-daemon.service',
									'查看状态：/etc/init.d/avahi-daemon status或systemctl status avahi-daemon.service',
								]
							},
							{
								tittle:[
									'<span>开机自启和关闭命令</span>',
								],
								code:[
									'开机自启：systemctl enable  avahi-daemon.service',
									'开机不启动：systemctl disable avahi-daemon.service',
								]
							},
						]
					},
				],
			},
		},
		{
			tittle:'80系统单网口网络配置',
			text:{
				problem:[
					'单个网络接口设备静态、dhcp以及子网等网络配置方法',
				],
				scope:[
					'linx-6.0.80'
				],
				resolvent:[
					{
						tittle:'1. 打开终端，使用cd命令切换到网络配置目录',
						detail:[
							{
								code:[
									'~# cd /etc/network/',
									'~# ls',
									'<b>if-down.d if-pre-up.d interfaces routes if-post-down.d  if-up.d run</b>',
								]
							},
							{
								tittle:[
									'<span>interfaces：网络配置文件</span>',
									'<span>if-down.d if-post-down.d if-pre-up.d if-up.d：是网络关闭前、网络关闭后、网络建立前、网络建立后都会到/etc/network/里运行相应目录下的脚本</span>',
								],
							},
						]
					},
					{
						tittle:'2. 编辑interfaces网络配置文件，如下以设置eth0静态IP为例',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'source /etc/network/interfaces.d/*',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<br>',
									'<b>auto eth0</b><span>#  eth0接口统启动时被自动配置</span>',
									'<b>allow-hotplug eth0</b><span>#  将网络接口设置为热插拔模式</span>',
									'<b>iface eth0 inet static</b><span>#  eth0接口具有一个静态的（static）IP配置</span>',
									'<b><span>address 192.168.1.100</span></b><span>#  ip地址</span>',
									'<b><span>netmask 255.255.255.0</span></b><span>#  掩码</span>',
									'<b><span>network 192.168.1.0</span></b><span>#  网段</span>',
									'<b><span>gateway 192.168.1.254</span></b><span>#  网段</span>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b><span># pre-up激活网卡之前需要需要执行的命令</span>',
								]
							},
						]
					},
					{
						tittle:'3. 重新启动网络服务，使ip配置生效',
						detail:[
							{
								code:[
									'~# systemctl restart networking.service',
								]
							},
						]
					},
					{
						tittle:'4. 检查ip网络配置是否生效',
						detail:[
							{
								code:[
									'~# ifconfig',
									'<b><span style="text-indent:4em;">Link  encap:Ethernet   HWaddr  00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">inet  addr:192.168.1.100   Bcast:192.168.1.255   Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6  addr:  fe80::20c:29ff:fe7f:98f6/64  Scope:Link</span></b>',
									'<b><span style="text-indent:4em;"> UP  BROADCAST  RUNNING  MULTICAST   MTU:1500   Metric:1</span></b>',
									'<b><span style="text-indent:4em;"> RX  packets:17805  errors:0  dropped:0  overruns:0  frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX  packets:904  errors:0  dropped:0  overruns:0  carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX  bytes:1708959  (1.6  MiB)   TX  bytes:125068  (122.1  KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:8 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:8 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:512 (512.0 B)  TX bytes:512 (512.0 B)</span></b>',
									'~# ping -c 2 192.168.1.1',
									'<b>PING  192.168.1.1  (192.168.1.1)  56(84)  bytes  of  data.</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=1  ttl=64  time=0.057  ms</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=2  ttl=64  time=0.115  ms</b>',
									'<br>',
									'<b>---  192.168.1.1  ping  statistics  ---</b>',
									'<b>2  packets  transmitted,  2  received,  0%  packet  loss,  time  1027ms</b>',
									'<b>rtt  min/avg/max/mdev  =  0.054/0.082/0.132/0.021  ms</b>'
								]
							},
						]
					},
					{
						tittle:'5. 创建子网配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<b>source /etc/network/interfaces.d/*</b>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.1</span></b>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b>',
									'<b>auto eth0:0</b>',
									'<b>allow-hotplug  eth0:0</b>',
									'<b>iface  eth0:0  inet static</b>',
									'<b><span>address  192.168.2.100</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>'

								]
							}
						]
					},
					{
						tittle:'6. 多个ip网络设置如下',
						detail:[
							{
								tittle:[
									'<span>先确定网口数量以及对应名称</span>',
								],
								code:[
									'~# ifconfig -a',
									'<b><span style="text-indent:4em;">Link  encap:Ethernet   HWaddr  00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">inet  addr:192.168.1.100   Bcast:192.168.1.255  Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6  addr:  fe80::20c:29ff:fe7f:98f6/64  Scope:Link</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">TX packets:1232 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:12393 (12.1 KiB)  TX bytes:711382 (694.7 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:00</span><span>eth1</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:116 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:1232 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:12393 (12.1 KiB)  TX bytes:711382 (694.7 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth2</span></b>',
									'<b><span style="text-indent:4em;">UPUP BROADCAST RUNNING MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:13642 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:2696472 (2.5 MiB)  TX bytes:13176 (12.8 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:12 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:712 (712.0 B)  TX bytes:712 (712.0 B)</span></b>'

								]
							},
							{
								tittle:[
									'<span>增加配置eth1和eth2，其中配置文件interfaces只能存在一个默认网关gateway参数，其他网口以添加路由的方式设置</span>'
								],
								code:[
									' ~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<b>source /etc/network/interfaces.d/*</b>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.1</span></b>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b>',
									'<b>auto eth1</b>',
									'<b>allow-hotplug eth1</b>',
									'<b>iface  eth1  inet static</b>',
									'<b><span>address  192.168.2.100</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b>',
									'<b>auto eth2</b>',
									'<b>allow-hotplug eth2</b>',
									'<b>iface eth2 inet static</b>',
									'<b><span>address 192.168.3.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.3.0</span></b>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b>',

								]
							}
						]
					},
					{
						tittle:'7. DHCP自动获取IP地配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'<b>source /etc/network/interfaces.d/*</b>',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<br>',
									'<b>auto eth0</b>',
									'<b>allow-hotplug eth0</b>',
									'<b>iface eth0 inet dhcp</b>'

								]
							}
						]
					},
				],
			},
		},
		{
			tittle:'80系统bond网络配置',
			text:{
				problem:[
					'bond静态、子网以及解除bond等网络配置方法',
				],
				scope:[
					'linx-6.0.80'
				],
				resolvent:[
					{
						tittle:'1. bond介绍',
						detail:[
							{
								tittle:[
									'<span>网卡bond（绑定），也称作网卡捆绑，就是将两个或者更多的物理网卡绑定成一个虚逻辑网卡，实现本地网卡的冗余，带宽扩容和负载均衡，在应用部署中是一种常用的技术。多网卡绑定实际上需要提供一个额外的软件的bond驱动程序实现。通过驱动程序可以将多块网卡屏蔽。对TCP/IP协议层只存在一个bond网卡，在 bond程序中实现网络流量的负载均衡，即将一个网络请求重定位到不同的网卡上，来提高总体网络的可用性。</span>',
									'<span>网卡绑定的目的：提高网卡的吞吐量，增强网络的高可用，同时也能实现负载均衡。</span>',
									'<span>bond模式分为以下几种</span>',
									'<span style="text-indent:1em;">Mode=0(balance-rr) Round-robin policy平衡负载模式：平衡轮询策略，具有负载平衡和容错功能bond的网卡MAC为当前活动的网卡的MAC地址，需要交换机设置聚合模式，将多个网卡绑定为一条链路。</span>',
									'<span style="text-indent:1em;">Mode=1(active-backup) 自动备援模式：具有容错功能，只有一块网卡是active,另外一块是备的standby，这时如果交换机配的是捆绑，将不能正常工作，因为交换机往两块网卡发包，有一半包是丢弃的。</span>',
									'<span style="text-indent:1em;">Mode=5(balance-tlb) Adaptive transmit load balancing适配器传输负载均衡，并行发送，无法并行接收，解决了数据发送的瓶颈。是根据每个slave的负载情况选择slave进行发送，接收时使用当前轮到的slave。</span>',
									'<span style="text-indent:1em;">Mode=6(balance-alb) Adaptive load balancing平衡负载模式：在5的tlb基础上增加了rlb。适配器负载均衡模式并行发送，并行接收数据包。</span>',
									'<span style="text-indent:1em;">其实mode=6与mode=0的区别：mode=6，先把eth0流量占满，再占eth1，….ethX；而mode=0的话，会发现2个口的流量都很稳定，基本一样的带宽。而mode=6，会发现第一个口流量很高，第2个口只占了小部分流量。</span>',
								],
							},
						]
					},
					{
						tittle:'2. 编辑网络配置文件，如下将eth0和eth2设置为静态bond0',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'source /etc/network/interfaces.d/*',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>auto bond0</b>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.1</span></b>',
									'<b><span>pre-up ip addr flush dev $IFACE</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth1</span></b>',
									'<b><span>down ifenslave -d bond0 eth0 eth1</span></b>',
								]
							},
						]
					},
					{
						tittle:'80系统bonding功能需要ifenslave 2.9及以上版本，通过以下命令确认',
						detail:[
							{
								code:[
									'~ # dpkg -l | grep ifenslave',
									'<b>ii  ifenslave      2.9linx1   all     configure network interfaces for parallel routing (bonding)</b>',
									'<b>ii  ifenslave-2.6      2.9linx1   all     transitional package, use "ifenslave"</b>',
								]
							},
						]
					},
					{
						tittle:'4. 配置bond模块开机加载，在/etc/modules文件末尾加上bonding',
						detail:[
							{
								code:[
									'~# echo "bonding" >> /etc/modules',
								]
							}
						]
					},
					{
						tittle:'5. 指定bond服务模式及主网卡',
						detail:[
							{
								code:[
									'~# vim /etc/modprobe.d/bonding.conf',
									'<b>alias bond0 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=1  miimon=100  downdelay=200  updelay=200  primary=eth0</b>',
								]
							},
							{
								tittle:[
									'<span>参数解释</span>',
									'<span style="text-indent:1em;">mode=1：主备模式</span>',
									'<span style="text-indent:1em;">miimon=100：系统每100ms检测一次链路链接状态</span>',
									'<span style="text-indent:1em;">max_bonds=1：最大的网卡绑定数量为1；当配置二个bond时，参数需要改为2</span>',
									'<span style="text-indent:1em;">primary=eth0：指定eth0网卡为主网卡，eth2自动为备用网卡</span>',
								],
							},
						]
					},
					{
						tittle:'6. 重启系统让网络配置生效',
					},
					{
						tittle:'7. 检查bond服务配置及网络配置是否生效',
						detail:[
							{
								code:[
									'<b>~# cat /proc/net/bonding/bond0</b>',
									'<b>Ethernet Channel Bonding Driver: v3.7.1 (April 27, 2011)</span>',
									'<br>',
									'<b>Bonding Mode: fault-tolerance (active-backup)</b><span>#显示为主备模式</span>',
									'<b>Primary Slave: eth0 (primary_reselect always)</b><span>#主网口设备</span>',
									'<b>Currently Active Slave: eth0</b>',
									'<b>MII Status: up</b>',
									'<b>MII Polling Interval (ms): 100</b>',
									'<b>Up Delay (ms): 200</b>',
									'<b>Down Delay (ms): 200</b>',
									'<br>',
									'<b>Slave Interface: eth0</b>',
									'<b>MII Status: up</b>',
									'<b>Speed: 1000 Mbps</b>',
									'<b>Duplex: full</b>',
									'<b>Link Failure Count: 0</b>',
									'<b>Permanent HW addr: 00:0c:29:0b:14:47</b>',
									'<b>Slave queue ID: 0</b>',
									'<br>',
									'<b>Slave Interface: eth1</b>',
									'<b>MII Status: up</b>',
									'<b>Speed: 1000 Mbps</b>',
									'<b>Duplex: full</b>',
									'<b>Link Failure Count: 0</b>',
									'<b>Permanent HW addr: 00:0c:29:0b:14:51</b>',
									'<b>Slave queue ID: 0</b>',
									'<br>',
									'~# ifconfig',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>bond0</span></b>',
									'<b><span style="text-indent:4em;">inet addr:192.168.1.60  Bcast:192.168.1.255  Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: fe80::20c:29ff:fe7f:98f6/64 Scope:Link</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING MASTER MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:90100 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:17628 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:10653054 (10.1 MiB)  TX bytes:10493003 (10.0 MiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING SLAVE MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:45050 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:17616 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:5326579 (5.0 MiB)  TX bytes:10479827 (9.9 MiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Ethernet  HWaddr 00:0c:29:7f:98:f6</span><span>eth1</span></b>',
									'<b><span style="text-indent:4em;">UP BROADCAST RUNNING SLAVE MULTICAST  MTU:1500  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:45052 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:12 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:1000</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:5326595 (5.0 MiB)  TX bytes:13176 (12.8 KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">inet6 addr: ::1/128 Scope:Host</span></b>',
									'<b><span style="text-indent:4em;">UP LOOPBACK RUNNING  MTU:65536  Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX packets:653 errors:0 dropped:0 overruns:0 frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX packets:653 errors:0 dropped:0 overruns:0 carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0 txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX bytes:130908 (127.8 KiB)  TX bytes:130908 (127.8 KiB)</span></b>',
									'~# ping -c 2 192.168.1.1',
									'<b>PING  192.168.1.1  (192.168.1.1)  56(84)  bytes  of  data.</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=1  ttl=64  time=0.057  ms</b>',
									'<b>64  bytes  from  192.168.1.1:  icmp_seq=2  ttl=64  time=0.115  ms</b>',
									'<br>',
									'<b>---  192.168.1.1  ping  statistics  ---</b>',
									'<b>2  packets  transmitted,  2  received,  0%  packet  loss,  time  1027ms</b>',
									'<b>rtt  min/avg/max/mdev  =  0.054/0.082/0.132/0.021  ms</b>'
								]
							},
						]
					},
					{
						tittle:'8. 创建子网配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'source /etc/network/interfaces.d/*',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>auto bond0</b>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.1</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth1</span></b>',
									'<b><span>down ifenslave bond0 -d eth0  eth1</span></b>',
									'<b>auto bond0:0</b>',
									'<b>allow-hotplug  bond0:0</b>',
									'<b>iface bond0:0 inet static</b>',
									'<b><span>address  192.168.2.100</span></b>',
									'<b><span>netmask  255.255.255.0</span></b>',
									'<b><span>network  192.168.2.0</span></b>',
								]
							},
						]
					},
					{
						tittle:'9. 多bond配置如下',
						detail:[
							{
								code:[
									'~# vim /etc/network/interfaces',
									'# This file describes the network interfaces available on your system',
									'# and how to activate them. For more information, see interfaces(5).',
									'source /etc/network/interfaces.d/*',
									'# The loopback network interface',
									'<b>auto lo</b>',
									'<b>iface lo inet loopback</b>',
									'<b>auto bond0</b>',
									'<b>allow-hotplug bond0</b>',
									'<b>iface bond0 inet static</b>',
									'<b><span>address 192.168.1.100</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.1.0</span></b>',
									'<b><span>gateway 192.168.1.1</span></b>',
									'<b><span>up ifenslave bond0 eth0 eth1</span></b>',
									'<b><span>down ifenslave bond0 -d eth0  eth1</span></b>',
									'<br>',
									'<b>auto bond1</b>',
									'<b>allow-hotplug bond1</b>',
									'<b>iface bond1 inet static</b>',
									'<b><span>address 192.168.2.60</span></b>',
									'<b><span>netmask 255.255.255.0</span></b>',
									'<b><span>network 192.168.2.0</span></b>',
									'<b><span>up ifenslave bond1 eth2 eth3</span></b>',
									'<b><span>down ifenslave bond1 -d eth2  eth3</span></b>',
									'<br>',
									'~# vim /etc/modprobe.d/bonding.conf',
									'<b>alias bond0 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=2  miimon=100  downdelay=200  updelay=200  primary=eth0</b>',
									'<b>alias bond1 bonding</b>',
									'<b>options  bonding  mode=1  max_bonds=2  miimon=100  downdelay=200  updelay=200  primary=eth2</b>',
								]
							},
						]
					},
					{
						tittle:'10. 解除网卡绑定',
						detail:[
							{
								tittle:[
									'<span>取消bonding服务相关配置，注释/etc/modprobe.d/bonding.conf文件里内容</span>',
								],
								code:[
									'~# vim /etc/modprobe.d/aliases-bond.conf',
									'#alias bond0 bonding',
									'#options  bonding  mode=1  max_bonds=1  miimon=100  downdelay=200  updelay=200  primary=eth0',
								]
							},
							{
								tittle:[
									'<span>注释bonding模块</span>',
								],
								code:[
									'~# vim /etc/modules',
									'# /etc/modules: kernel modules to load at boot time.',
									'#',
									'# This file contains the names of kernel modules that should be loaded ',
									'# at boot time, one per line. Lines beginning with "#" are ignored.',
									'# Parameters can be specified after the module name.',
									'<br>',
									'<b>loop</b>',
									'<b>linx_sec</b>',
									'<b>ipv6</b>',
									'#bonding',
								]
							},
							{
								tittle:[
									'<span>取消网络配置文件/etc/network/interfaces下的bond0设置</span>',
									'<span>重启系统生效</span>',
								]
							},
						]
					},
				],
			},
		},
		{
			tittle:'路由设置',
			text:{
				problem:[
					'增加路由与删除路由',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						detail:[
							{
								tittle:[
									'<span style="text-indent:-1em;">终端执行route命令方式添加路由后，重启机器后会重新刷新，需要再次重新添加。可以将route添加的路由写入/etc/rc.local文件中exit 0前面，让开机自动添加路由</span>',
									'<span style="text-indent:-1em;">/etc/network/interfaces文件中只能写一个网关gateway参数，其他网关以路由方式添加</span>',
								],
							}
						]
					},
					{
						tittle:'1. 主机路由是路由选择表中指向单个IP地址或主机名的路由记录，主机路由的Flags字段为H',
						detail:[
							{
								tittle:[
									'<span>添加主机路由</span>',
								],
								code:[
									'~# route add –host 192.168.1.22 dev eth0',
									'~# route add –host 192.168.1.22 gw 192.168.1.254',
								]
							},
							{
								tittle:[
									'<span>删除主机路由</span>',
								],
								code:[
									'~# route del -host 192.168.1.22 dev eth0',
									'~# route del –host 192.168.1.22 gw 192.168.1.254',
								]
							},
						]
					},
					{
						tittle:'2. 网络路由是代表主机可以到达的网络，网络路由的Flags字段为N',
						detail:[
							{
								tittle:[
									'<span>添加网络路由</span>',
								],
								code:[
									'~# route add –net 192.168.1.22 netmask 255.255.255.0 eth0',
									'~# route add –net 192.168.1.22 netmask 255.255.255.0 gw 192.168.1.200',
									'~# route add –net 192.168.1.22/24 eth0',
								]
							},
							{
								tittle:[
									'<span>删除网络路由</span>',
								],
								code:[
									'~# route del –net 192.168.1.22 netmask 255.255.255.0 eth0',
									'~# route del –net 192.168.1.22 netmask 255.255.255.0 gw 192.168.1.200',
									'~# route del –net 192.168.1.22/24 eth0',
								]
							},
						]
					},
					{
						tittle:'3. 当主机不能在路由表中查找到目标主机的IP地址或网络路由时，数据包就被发送到默认路由（默认网关）上，默认路由的Flags字段为G',
						detail:[
							{
								tittle:[
									'<span>添加默认路由</span>',
								],
								code:[
									'~# route add default gw 192.168.1.254',
								]
							},
							{
								tittle:[
									'<span>删除默认路由</span>',
								],
								code:[
									'~# route del default gw 192.168.1.254',
								]
							},
						]
					},
				],
			},
		},
		{
			tittle:'网络故障排查流程',
			text:{
				problem:[
					'网络故障排查流程',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 检查系统是否激活，如果是测试序列号请注意是否过期',
						detail:[
							{
								code:[
									'~# cat /boot/grub/grub.cfg<span># 序列号存在，说明已经序列号已经写入gurb启动项</span>',
									'~# cat /proc/cmdline<span># 序列号存在，说明系统已经加载了序列号，已写入内核</span>',
									'~# cat /proc/linxsn_info<span># 文件存在且有序列号输出，说明序列号生效</span>',
								]
							},
						],
						
					},
					{
						tittle:'2. 核对网络配置文件单词以及语法是否填写正确，并确保ifconfig输出的ip与配置相同',
						detail:[
							{
								code:[
									'~# ifconfig',
									'<b><span style="text-indent:4em;">Link  encap:Ethernet   HWaddr  00:0c:29:7f:98:f6</span><span>eth0</span></b>',
									'<b><span style="text-indent:4em;">inet  addr:192.168.1.100   Bcast:192.168.1.255   Mask:255.255.255.0</span></b>',
									'<b><span style="text-indent:4em;">UP  BROADCAST  RUNNING  MULTICAST   MTU:1500   Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX  packets:432  errors:0  dropped:0  overruns:0  frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX  packets:205  errors:0  dropped:0  overruns:0  carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0  txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;"> RX  bytes:43118  (42.1  KiB)   TX  bytes:23795  (23.2  KiB)</span></b>',
									'<br>',
									'<b><span style="text-indent:4em;">Link encap:Local Loopback</span><span>lo</span></b>',
									'<b><span style="text-indent:4em;">inet addr:127.0.0.1  Mask:255.0.0.0</span></b>',
									'<b><span style="text-indent:4em;">UP  LOOPBACK  RUNNING   MTU:16436   Metric:1</span></b>',
									'<b><span style="text-indent:4em;">RX  packets:4  errors:0  dropped:0  overruns:0  frame:0</span></b>',
									'<b><span style="text-indent:4em;">TX  packets:4  errors:0  dropped:0  overruns:0  carrier:0</span></b>',
									'<b><span style="text-indent:4em;">collisions:0  txqueuelen:0</span></b>',
									'<b><span style="text-indent:4em;">RX  bytes:340  (340.0  B)   TX  bytes:340  (340.0  B)</span></b>',
								]
							},
						],
						
					},
					{
						tittle:'3. 检查网口是否正确连接',
						detail:[
							{
								tittle:[
									'<span></span>',
								],
								code:[
									'~#  ethtool  eth0',
									'<b>Settings  for  eth0:</b>',
									'<b>Supported  ports:  [  TP  ]</b>',
									'<b><span style="text-indent:12em;">10baseT/Half  10baseT/Full</span><span>Supported  link  modes:</span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half  100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b>Supported  pause  frame  use:  No</b>',
									'<b>Supports  auto-negotiation:  Yes</b>',
									'<b><span style="text-indent:12em;">10baseT/Half  10baseT/Full</span><span>Advertised  link  modes:</span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half  100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b>Advertised  pause  frame  use:  No</b>',
									'<b>Advertised  auto-negotiation:  Yes</b>',
									'<b>Speed:  1000Mb/s</b>',
									'<b>Duplex:  Full</b>',
									'<b>Port:  Twisted  Pair</b>',
									'<b>PHYAD:  0</b>',
									'<b>Transceiver:  internal</b>',
									'<b>Auto-negotiation:  on</b>',
									'<b>MDI-X:  Unknown</b>',
									'<b>Supports  Wake-on:  umbg</b>',
									'<b>Wake-on:  d</b>',
									'<b><span style="text-indent:12em;">0x00000007  (7)</span><span>Current  message  level:</span></b>',
									'<b><span style="text-indent:12em;">drv  probe  link</span></b>',
									'<b>Link  detected:  yes</b><span>#检测链接状态，yes标明网线连接正常</span>',
									'<b>~# ethtool -p eth0</b><span>#查看eth0对应机器网口是否出现灯光闪烁，此命令用于确定网线是否插对网口</span>',
								]
							},
						],
						
					},
					{
						tittle:'4. 检查网络连通性(测试相同网段，不同网段之间需要提前添加路由)',
						detail:[
							{
								code:[
									'~# ping -c 2 10.0.2.6',
									'<b>PING  10.0.2.6  (10.0.2.6)  56(84)  bytes  of  data.</b>',
									'<b>64  bytes  from  10.0.2.6:  icmp_seq=1  ttl=64  time=0.057  ms</b>',
									'<b>64  bytes  from  10.0.2.6:  icmp_seq=2  ttl=64  time=0.115  ms</b>',
									'<br>',
									'---  10.0.2.6  ping  statistics  ---',
									'2  packets  transmitted,  2  received,  0%  packet  loss,  time   1027ms',
									'rtt  min/avg/max/mdev  =  0.057/0.086/0.115/0.029  ms',
								]
							},
						],
						
					},
				],
			},
		},
		{
			tittle:'cron服务配置',
			text:{
				problem:[
					'cron服务配置',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 系统下所有用户的cron定时任务，可以放到/etc/crontab文件中，从而方便对cron定时任务的管理。当前用 户的计划任务也可以直接执行crontab -e编辑',
						
					},
					{
						tittle:'2. /etc/crontab文件格式如下， 每行定时任务，包含7个字段',
						detail:[
							{
								code:[
									'm(inute) h(our)  dom(day of month)  mon(month) dow(day of week) username command',
									'#星号（\*)：代表所有可能的取值，例如month字段如果是星号，则表示在满足其它字段的制约条件后，每月都执行该命令操作。',
									'#逗号（,）：可以用逗号隔开的值指定一个列表范围，例如，“1,2,5,7,8,9”。',
									'#中杠（-）：可以用整数之间的中杠表示一个整数范围，例如“2-6”表示“2,3,4,5,6”。',
									'#正斜线（/）：可以用正斜线指定时间的间隔频率，例如“0-23/2”表示每两小时执行一次。',
									'#同时正斜线可以和星号一起使用，例如*/10，如果用在minute字段，表示每十分钟执行一次。',
									'# minute<span>0-59（取值范围）,“分钟”字段</span>',
									'# hour<span>0-23（取值范围），“小时”字段</span>',
									'# day of month<span>1-31（取值范围），“某月中的某天”字段</span>',
									'# month<span>1-12 (取值范围)，“月”字段</span>',
									'# day of week<span>0-7 (取值范围，0和7表示周日)，“某周中某天”字段</span>',
									'# username<span>系统中存在的用户名，“用户名”字段</span>',
									'# command<span>具体运行的命令、脚本及参数，“命令”字段</span>',
								]
							},
						],
						
					},
					{
						tittle:'3. 示例',
						detail:[
							{
								tittle:[
									'<span>每天晚上八点执行</span>',
								],
								code:[
									'0 20 * * * audadmin /usr/sbin/runjobs /etc/cron/daily',
								]
							},
							{
								tittle:[
									'<span>每月1号的晚上八点十分执行</span>',
								],
								code:[
									'10 20 1 * * audadmin /usr/sbin/runjobs /etc/cron/monthly',
								]
							},
							{
								tittle:[
									'<span>每分钟执行</span>',
								],
								code:[
									'*/1 * * * * sysadmin /usr/sbin/update_time.sh',
								]
							},
						],
						
					},
					{
						tittle:'4. 重启服务使修改生效',
						detail:[
							{
								code:[
									'~# /etc/init.d/cron restart',
								]
							},
						],
						
					},
				],
			},
		},
		{
			tittle:'nfs配置单网口网络配置',
			text:{
				problem:[
					'nfs配置',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
				],
				resolvent:[
					{
						tittle:'<b>6.0.60搭建nfs</b>',
					},
					{
						tittle:'<span>服务端</span>',
						detail:[
							{
								tittle:[
									'1. 启动服务',
								],
								code:[
									'~#/etc/init.d/portmap start',
									'~#/etc/init.d/nfs-kernel-server start',
									'~# mkdir /export',
									'~# chmod -R 777 /export',
								]
							},
							{
								tittle:[
									'2. 配置文件',
								],
								code:[
									'~#vim /etc/exports',
									'<b>  /export      192.168.134.0/24(rw,sync)</b>',
									'~#/etc/init.d/nfs-kernel-server restart',
								]
							},
							{
								tittle:[
									'3. 开机启动',
								],
								code:[
									'~# insserv portmap',
									'~# insserv nfs-kernel-server',
								]
							},
							{
								tittle:[
									'4. 查看本地共享',
								],
								code:[
									'~# exportfs -v',
								]
							},
							{
								tittle:[
									'5. 重新加载配置文件',
								],
								code:[
									'~# exportfs -r',
								]
							},
						],
						
					},
					{
						tittle:'<span>客户端</span>',
						detail:[
							{
								tittle:[
									'1. 先启动portmap服务：',
								],
								code:[
									'~#/etc/rc.d/init.d/portmap start',
								]
							},
							{
								tittle:[
									'2. 查看远程共享',
								],
								code:[
									'~# showmount -e 172.16.6.141',
								]
							},
							{
								tittle:[
									'3. 手动挂载',
								],
								code:[
									'~# mkdir /export',
									'~# mount -t nfs -o nolock 172.16.6.141:/export /export',
									'',
									'',
								]
							},
							{
								tittle:[
									'4. 开机自动挂载',
								],
								code:[
									'~#vim /etc/rc.d/rc.local',
									'<b>mount  -t  nfs -o nolock　172.16.6.141:/export /export</b>',
								]
							},
						],
					},
					{
						tittle:'<b>6.0.80搭建nfs</b>',
					},
					{
						tittle:'1. 服务',
						detail:[
							{
								code:[
									'~# /etc/init.d/nfs-common start',
									'~# /etc/init.d/nfs-kernel-server start',
								]
							},
						],
					},
					{
						tittle:'2. 服务开机自启',
						detail:[
							{
								code:[
									'~# systemctl enable nfs-common',
									'~# systemctl enable nfs-kernel-server',
								]
							},
						],
					},
					{
						tittle:'3. 其他配置参考6.0.60',
					},
					{
						tittle:'<b>备注</b>',
					},
					{
						tittle:'<span>服务端配置文件说明</span>',
						detail:[
							{
								tittle:[
									'1. 配置文件定义',
									'<b>NFS的配置文件为 /etc/exports,内容格式如下：</b>',
									'<b><共享目录> 客户端1(选项) [客户端2(选项) ...]</b>',
									'<b>共享目录：NFS共享给客户机的目录。</b>',
									'<b>客户端：网络中可以访问此目录的主机。多个客户端以空格分隔。</b>',
									'<b>选项：设置目录的访问权限、用户映射等，多个选项以逗号分隔。</b>',
									'<b>例如：</b>',
									'<b>/opt/public 192.168.1.0/24(rw,insecure,sync,all_squash,anonuid= 65534,anongid=65534)</b>',
								],
							},
							{
								tittle:[
									'2. 客户端的指定方式',
									'<b>指定ip地址的主机：192.168.0.100</b>',
									'<b>指定子网中的所有主机：192.168.0.0/24 或 192.168.0.0/255.255.255.0</b>',
									'<b>指定域名的主机：nfs.test.com</b>',
									'<b>指定域中的所有主机：*.test.com</b>',
									'<b>所有主机：*</b>',
								],
							},
							{
								tittle:[
									'2. 客户端的指定方式',
									'<b>ro：共享目录只读；</b>',
									'<b>rw：共享目录可读可写；</b>',
									'<b>all_squash：所有访问用户都映射为匿名用户或用户组；</b>',
									'<b>no_all_squash（默认）：访问用户先与本机用户匹配，匹配失败后再映射为匿名用户或用户组；</b>',
									'<b>root_squash（默认）：将来访的root用户映射为匿名用户或用户组；</b>',
									'<b>no_root_squash：来访的root用户保持root帐号权限；</b>',
									'<b>anonuid=<UID>：指定匿名访问用户的本地用户UID，默认为nfsnobody（65534）；</b>',
									'<b>anongid=<GID>：指定匿名访问用户的本地用户组GID，默认为nfsnobody（65534）；</b>',
									'<b>secure（默认）：限制客户端只能从小于1024的tcp/ip端口连接服务器；</b>',
									'<b>insecure：允许客户端从大于1024的tcp/ip端口连接服务器；</b>',
									'<b>sync：将数据同步写入内存缓冲区与磁盘中，效率低，但可以保证数据的一致性；</b>',
									'<b>async：将数据先保存在内存缓冲区中，必要时才写入磁盘；</b>',
									'<b>wdelay（默认）：检查是否有相关的写操作，如果有则将这些写操作一起执行，这样可以提高效率；</b>',
									'<b>no_wdelay：若有写操作则立即执行，应与sync配合使用；</b>',
									'<b>subtree_check（默认） ：若输出目录是一个子目录，则nfs服务器将检查其父目录的权限；</b>',
									'<b>no_subtree_check ：即使输出目录是一个子目录，nfs服务器也不检查其父目录的权限，这样可以提高效率</b>',
								],
							},
						],
					},
					{
						tittle:'<span>客服端挂载共享目录</span>',
						detail:[
							{
								tittle:[
									'1. 临时挂载',
									'<b>mount -t nfs 192.168.1.1:/opt/public /mnt/public</b>',
									'<b><共享目录> 客户端1(选项) [客户端2(选项) ...]</b>',
									'<b>共享目录：NFS共享给客户机的目录。</b>',
									'<b>客户端：网络中可以访问此目录的主机。多个客户端以空格分隔。</b>',
									'<b>选项：设置目录的访问权限、用户映射等，多个选项以逗号分隔。</b>',
									'<b>例如：</b>',
									'<b>/opt/public 192.168.1.0/24(rw,insecure,sync,all_squash,anonuid= 65534,anongid=65534)</b>',
								],
							},
							{
								tittle:[
									'2. 启动挂载',
									'<b>将挂载信息写入fstab文件</b>',
									'<b>vi /etc/fstab</b>',
									'<b>192.168.1.1:/opt/public /mnt/public nfs defaults</b>',
									'<b>保存退出</b>',
									'<b>mount -a</b>',
								],
							},
							{
								tittle:[
									'3. mount参数',
									'<b>ro 以只读模式加载。</b>',
									'<b>rw 以可读写模式加载。</b>',
									'<b>sync 以同步方式执行文件系统的输入输出动作。</b>',
									'<b>async 以非同步的方式执行文件系统的输入输出动作。</b>',
									'<b>defaults 使用默认的选项。默认选项为rw、suid、dev、exec、anto nouser与async。</b>',
									'<b>atime 每次存取都更新inode的存取时间，默认设置，取消选项为noatime。</b>',
									'<b>dev 可读文件系统上的字符或块设备，取消选项为nodev。</b>',
									'<b>nodev 不读文件系统上的字符或块设备。</b>',
									'<b>exec 可执行二进制文件，取消选项为noexec。</b>',
									'<b>noexec 无法执行二进制文件。</b>',
									'<b>auto 必须在/etc/fstab文件中指定此选项。执行-a参数时，会加载设置为auto的设备，取消选取为noauto。</b>',
									'<b>noauto 无法使用-a参数来加载。</b>',
									'<b>suid 启动set-user-identifier(设置用户ID)与set-group-identifer(设置组ID)设置位，取消选项为nosuid。</b>',
									'<b>nosuid 关闭set-user-identifier(设置用户ID)与set-group-identifer(设置组ID)设置位。</b>',
									'<b>user 普通用户可以执行加载操作。</b>',
									'<b>nouser 普通用户无法执行加载操作，默认设置。</b>',
									'<b>remount 重新加载设备。通常用于改变设备的设置状态。</b>',
									'<b>rsize 读取数据缓冲大小，默认设置1024。</b>',
									'<b>wsize 写入数据缓冲大小，默认设置1024。</b>',
									'<b>fg 以前台形式执行挂载操作，默认设置。在挂载失败时会影响正常操作响应。</b>',
									'<b>bg 以后台形式执行挂载操作。</b>',
									'<b>hard 硬式挂载，默认设置。如果与服务器通讯失败，让试图访问它的操作被阻塞，直到服务器恢复为止。</b>',
									'<b>soft 软式挂载。如果服务器通讯失败，让试图访问它的操作失败，返回一条出错消息。这项功能对于避免进程“挂”在无关紧要的安装操作上来说非常有用。</b>',
									'<b>retrans=n 指定在以软方式安装的文件系统上，在返回一条出错消息之前重复发出请求的次数。</b>',
									'<b>nointr 不允许用户中断，默认设置。</b>',
									'<b>intr 允许用户中断被阻塞的操作（并且让它们返回一条出错消息）。</b>',
									'<b>timeo=n 设置请求的超时时间（以十分之一秒为单位）。</b>',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'NTP对时配置',
			text:{
				problem:[
					'如何使用ntp服务进行对时',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
				],
				resolvent:[
					{
						tittle:'1. 如果需要本机（客户端）对时其他服务器（服务端），则修改本机ntp配置文件，配置文件中server后面添加对时主机ip地址，如下，本机对时192.168.11.88的主机',
						detail:[
							{
								code:[
									'~# vim /etc/ntp.conf',
									'<b>......</b>',
									'# 其余server使用#注释掉',
									'<b>server 192.168.11.88 iburst minpoll 4 maxpoll 4</b>',
									'<b>......</b>',
								]
							},
						],
					},
					{
						tittle:'2. 被对时机器（服务端）ntp配置文件需要放开本机（客户端）的网段，允许对时，restrict后面添加本机对时IP网段',
						detail:[
							{
								code:[
									'~# vim /etc/ntp.conf',
									'<b>......</b>',
									'<b>restrict 192.168.11.0  mask 255.255.255.0</b>',
									'<b>......</b>',
								]
							},
						],
					},
					{
						tittle:'3. 重启ntp服务，使修改过的对时配置文件生效',
						detail:[
							{
								code:[
									'~# /etc/init.d/ntp restart',
								]
							},
						],
					},
					{
						tittle:'4. 查看本机（客户端）对时是否生效，对时服务器ip前有*星号即对时成功，ntp首次对时大概需要5分钟左右',
						detail:[
							{
								code:[
									'~# ntpq -p',
									'<b>remote       refid      st  t   when  poll  reach   delay   offset  jitter</b>',
									'<b>==================================================================</b>',
									'<b>*192.168.11.88   10.240.241.5  4  u   53   64  377      0.240    0.374   0.240</b>',
								]
							},
						],
					},
					{
						tittle:'5. 两台对时主机时差超过600s需要先使用ntpdate手动对时，ntpdate对时前需要关闭ntp服务',
						detail:[
							{
								code:[
									'~# /etc/init.d/ntp stop',
									'~# ntpdate 	192.168.11.88',
								]
							},
						],
					},
				],
			},
		},
		{
			tittle:'ssh免密登陆配置',
			text:{
				problem:[
					'如何设置两台或多台机器之间ssh免密登陆',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 在A机，以root用户为例，ssh-keygen -t rsa  生成密匙',
						detail:[
							{
								code:[
									'~# ssh-keygen -t rsa',
									'<b>Generating public/private rsa key pair.</b>',
									'<b>Enter file in which to save the key (/root/.ssh/id_rsa):</b>',
									'<b>/root/.ssh/id_rsa already exists.</b>',
									'<b>Overwrite (y/n)? y</b>',
									'<b>Enter passphrase (empty for no passphrase):</b>',
									'<b>Enter same passphrase again:</b>',
									'<b>Your identification has been saved in /root/.ssh/id_rsa.</b>',
									'<b>Your public key has been saved in /root/.ssh/id_rsa.pub.</b>',
									'<b>The key fingerprint is:</b>',
									'<b>HA256:VBQCp7n34tJCZSHwOgW7Y1+xhGKdQqHAQ1JKD5xhLx4 root@linx</b>',
									"<b>The key's randomart image is:</b>",
									'<b>+---[RSA 2048]----+</b>',
									'<b>|*B+ =o..o.+.     |</b>',
									'<b>|+B+o =.*.o       |</b>',
									'<b>|.Eoo= O.+.       |</b>',
									'<b>|. o. * +oo       |</b>',
									'<b>| .  * .oS        |</b>',
									'<b>|   . +.o .       |</b>',
									'<b>|     .... .      |</b>',
									'<b>|      o...       |</b>',
									'<b>|       o.        |</b>',
									'<b>+----[SHA256]-----+</b>',
								]
							},
						],
					},
					{
						tittle:'2. root用户的家目录为/root，进到家目录下的.ssh/目录里，将A机生成好的id_rsa.pub公钥拷贝至B机并导入authorized_keys文件，能实现A机登录B机免密',
						detail:[
							{
								code:[
									'~# scp /root/.ssh/id_rsa.pub 192.168.88.121:/root/',
									'~# ssh root@192.168.88.121',
									'~# cat /root/id_rsa.pub >> /root/.ssh/authorized_keys',
								]
							},
						],
					},
					{
						tittle:'3. 以上只是A机登录B机单向免密，如果B登录A机免密，则在B机生成公钥，将其导入追加至B机的authorized_keys文件，最后将authorized_keys拷贝至A机的.ssh目录下一份，就能实现双向登录ssh免密',
					},
					{
						tittle:'4. 根据以上操作同理可得，只要authorized_keys内有哪个主机的公钥，哪个主机就能登录免密。多台机器相互免密，则将多台机器生成的公钥导入追加至authorized_keys文件并在多台机器使用同一个authorized_keys，则能实现多台机器相互免密',
					},
				],
			},
		},
		{
			tittle:'ssh允许管理员登录设置',
			text:{
				problem:[
					'如何设置允许root、sysadmin、audadmin、netadmin、secadmin等管理用户登录ssh，如下是登陆时被禁止',
				],
				code_p:[				
					'<b>ssh root@127.0.0.1</b>',
					"<b>Warning: Permanently added '127.0.0.1' (RSA) to the list of known hosts.</b>",
					"<b>root@127.0.0.1's password:</b>",
					'<b>Permission denied, please try again.</b>',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 在被登录主机修改ssh服务端配置文件/etc/ssh/sshd_config',
						detail:[
							{
								tittle:[
									'<span>找到"PermitRootLogin"字段将其取消注释并修改，如下</span>',
								],
								code:[
									'~# vim /etc/ssh/sshd_config',
									'<b>PermitRootLogin  yes</b>',
								]
							},
							{
								tittle:[
									'<span>确保"DenyUsers"后没有需要解除权限的管理员账户，如需全部解除，如下可以选择注释</span>',
								],
								code:[
									'~# vim /etc/ssh/sshd_config',
									'#DenyUsers sysadmin secadmin audadmin netadmin',
								]
							},
						],
					},
					{
						tittle:'2. 重启ssh服务让ssh配置文件生效',
						detail:[
							{
								code:[
									'~# /etc/init.d/ssh restart',
									'<b>Restarting OpenBSD Secure Shell server: sshd.</b>',
								]
							},
						],
					},
				],
			},
		},
		{
			tittle:'tcpdump使用方法',
			text:{
				problem:[
					'tcpdump是Linux自带的抓包工具，可以详细看到计算机通信中详细报文内容',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. -i 指定要抓取数据包的网卡名称,抓取eth0网卡的数据包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0',
								]
							},
						],
					},
					{
						tittle:'2. -c 指定抓取的包的个数,只抓取10个包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -c 10',
								]
							},
						],
					},
					{
						tittle:'3. -w 把抓取到的数据存放到文件中供以后分析',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -c 10 -w mypackets.pcap',
								]
							},
						],
					},
					{
						tittle:'4. -r 读取抓包文件',
						detail:[
							{
								code:[
									'~# tcpdump -r mypackets.pcap',
								]
							},
						],
					},
					{
						tittle:'5. -n 不解析ip，默认会 将ip解析成域名',
						detail:[
							{
								code:[
									'~# tcpdump -n -i eth0 port 80',
									'~# tcpdump -n -i eth0 host www.baidu.com and port 80',
								]
							},
						],
					},
					{
						tittle:'6. 抓取192.168.88.111访问本机22端口的数据包',
						detail:[
							{
								code:[
									'~# tcpdump -n -i eth0 port 80',
									'~# tcpdump -n -i eth0 host www.baidu.com and port 80',
								]
							},
						],
					},
					{
						tittle:'7. 只抓源地址是192.168.88.111的数据包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -nn src host 192.168.88.111',
								]
							},
						],
					},
					{
						tittle:'8. 只抓取目标地址是192.168.88.0/24网段的数据包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -nn dst net 192.168.88.0/24',
								]
							},
						],
					},
					{
						tittle:'9. 抓取除80端口的tcp数据包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -vnn tcp and not port 80',
								]
							},
						],
					},
					{
						tittle:'10. 抓取网段为192.168.88.0/24访问80端口的数据包',
						detail:[
							{
								code:[
									'~# tcpdump -i eth0 -vnn src net 192.168.88.0/24 and dst port 80',
								]
							},
						],
					},
					{
						tittle:'11. 抓取访问主机192.168.88.111访问22端口或者主机192.168.88.112访问80端口的数据包',
						detail:[
							{
								code:[
									"~# tcpdump -i eth0 -vnn 'port 22 and host 192.168.88.111' or 'port 80 and host 192.168.88.112",
								]
							},
						],
					},
				],
			},
		},
		{
			tittle:'vim使用介绍',
			text:{
				problem:[
					'vim使用介绍',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'<b>介绍</b>',
					},
					{
						tittle:'<span>vim是一个由vi增强版的编辑工具，是一个开源免费的软件，它功能丰富，使用快捷，应用广泛，。vim也是    大多数Linux系统上的默认编辑器，用于对文本文件进行建立，显示，编辑，删除，复制等操作,需要用命令进  行控制。</span>',
					},
					{
						tittle:'<b>帮助信息</b>',
					},
					{
						tittle:'<span>在命令行输入vim --help 或 vimtutor 可以查看vim的用法和信息</span>',
					},
					{
						tittle:'<b>vim的基本概念</b>',
					},
					{
						tittle:'<span>基本上vim可以分为三种状态，分别是命令模式（command mode）、插入模式（Insert mode）和底行模式（last line mode），各模式的功能区分如下：</span>',
						detail:[
							{
								tittle:[
									'<span>命令行模式command mode），控制屏幕光标的移动，字符、字或行的删除，移动复制某区段及进入Insert mode下，或者到 last line mode。</span>',
									'<span>插入模式（Insert mode），只有在Insert mode下，才可以做文字输入，按「ESC」键可回到命令行模式。</span>',
									'<span>末行模式（last line mode），将文件保存或退出vim，也可以设置编辑环境，如寻找字符串、列出行号……等。</span>',
								],
							},
						],
					},
					{
						tittle:'<b>vim的基本操作</b>',
					},
					{
						tittle:'1. 进入vim，vim加上任意一个已经存在或是想创建的文件名，如果系统还不存在该文件，就意味着创建文件，如果系统存   在该文件，就意味着编辑该文件。',
						detail:[
							{
								code:[
									'~# vim   filename',
								]
							},
						],
					},
					{
						tittle:'2. 模式切换，vim进入文件后，处于命令行模式',
						detail:[
							{
								tittle:[
									'<span>插入模式</span>',
									'在进入命令行模式之后，按下i、a、o等键可进入插入模式。进入插入模式后可以对文件进行编辑，左下角出现INSERT或插入',
									'<span style="text-indent:1em;">i 在光标所在字符前插入</span>',
									'<span style="text-indent:1em;">I 在光标所在行行首插入</span>',
									'<span style="text-indent:1em;">a 在光标所在字符后插入</span>',
									'<span style="text-indent:1em;">A 在光标所在行尾插入</span>',
									'<span style="text-indent:1em;">o 在光标下插入新行</span>',
									'<span style="text-indent:1em;">O 在光标上插入新行</span>',
									'<span>从插入模式切换为命令行模式，按「ESC」键。</span>',
									'<span>处于命令模式后，按下冒号:，即可进入末行模式。</span>',
								],
							},
						],
					},
					{
						tittle:'3. 退出vim及保存文件，进入末行模式，例如',
						detail:[
							{
								tittle:[
									'<span>:w	保存不退出</span>',
									'<span>:wq   保存退出</span>',
									'<span>:q	文件未做修改时退出</span>',
									'<span>:q!	不保存强制退出</span>',
									'<span>:wq!  保存强制退出</span>',
									'<span>:x	保存退出</span>',
								],
							},
						],
					},
					{
						tittle:'<b>vim使用技巧</b>',
					},
					{
						tittle:'1. 命令行模式',
						detail:[
							{
								tittle:[
									'<span>移动光标，vim可以直接用键盘上的光标来上下左右移动，但正规的vim是用小写英文字母「h」、「j」、「k」、「l」分别控制光标左、下、上、右移一格。</span>',
									'<span style="text-indent:1em;">按「ctrl」+「b」：屏幕往“后”移动一页。</span>',
									'<span style="text-indent:1em;">按「ctrl」+「f」：屏幕往“前”移动一页。</span>',
									'<span style="text-indent:1em;">按「ctrl」+「u」：屏幕往“后”移动半页。</span>',
									'<span style="text-indent:1em;">按「ctrl」+「d」：屏幕往“前”移动半页。</span>',
									'<span style="text-indent:1em;">按数字「0」：移到文章的开头。</span>',
									'<span style="text-indent:1em;">按「G」：移动到文章的最后。</span>',
									'<span style="text-indent:1em;">按「$」：移动到光标所在行的“行尾”。</span>',
									'<span style="text-indent:1em;">「^」：移动到光标所在行的“行首”</span>',
									'<span style="text-indent:1em;">按「w」：光标跳到下个字的开头</span>',
									'<span style="text-indent:1em;">按「e」：光标跳到下个字的字尾按「b」：光标回到上个字的开头</span>',
									'<span style="text-indent:1em;">按「#l」：光标移到该行的第#个位置，如：5l，56l。</span>',
									'<span>删除</span>',
									'<span style="text-indent:1em;">「x」：每按一次，删除光标所在位置的“后面”一个字符。</span>',
									'<span style="text-indent:1em;">「#x」：例如，「6x」表示删除光标所在位置的“后面”6个字符。</span>',
									'<span style="text-indent:1em;">「X」：大写的X，每按一次，删除光标所在位置的“前面”一个字符。</span>',
									'<span style="text-indent:1em;">「#X」：例如，「20X」表示删除光标所在位置的“前面”20个字符。</span>',
									'<span style="text-indent:1em;">「dd」：删除光标所在行。</span>',
									'<span style="text-indent:1em;">「#dd」：从光标所在行开始删除#行。</span>',
									'<span>复制</span>',
									'<span style="text-indent:1em;">「yw」：将光标所在之处到字尾的字符复制到缓冲区中。</span>',
									'<span style="text-indent:1em;">「#yw」：复制#个字到缓冲区</span>',
									'<span style="text-indent:1em;">「yy」：复制光标所在行到缓冲区。</span>',
									'<span style="text-indent:1em;">「#yy」：例如，「6yy」表示拷贝从光标所在的该行“往下数”6行文字。</span>',
									'<span style="text-indent:1em;">「p」：将缓冲区内的字符贴到光标所在位置。注意：所有与“y”有关的复制命令都必须与“p”配合才   能完成复制与粘贴功能。</span>',
									'<span>替换</span>',
									'<span style="text-indent:1em;">「r」：替换光标所在处的字符。</span>',
									'<span style="text-indent:1em;">「R」：替换光标所到之处的字符，直到按下「ESC」键为止。</span>',
									'<span>撤回</span>',
									'<span style="text-indent:1em;">「u」：如果您误执行一个命令，可以马上按下「u」，回到上一个操作。按多次“u”可以执行多次  回复。</span>',
									'<span>跳至指定的行</span>',
									'<span style="text-indent:1em;">「ctrl」+「g」列出光标所在行的行号。</span>',
									'<span style="text-indent:1em;">「#G」：例如，「15G」，表示移动光标至文章的第15行行首。</span>',
									'<span style="text-indent:1em;">「shift」+「g」跳至文件尾行</span>',
									'<span style="text-indent:1em;">「gg」跳至文件首行</span>',
								],
							},
						],
					},
					{
						tittle:'2. 末行模式',
						detail:[
							{
								tittle:[
									'<span>列出行号</span>',
									'<span style="text-indent:1em;">:set nu	列出行号</span>',
									'<span style="text-indent:1em;">:set nonu	取消行号</span>',
									'<span>查找字符</span>',
									'<span style="text-indent:1em;">/关键字」：先按「/」键，再输入您想寻找的字符，如果第一次找的关键字不是想要的，可以一  直按「n」会往后寻找到您要的关键字为止。</span>',
									'<span style="text-indent:1em;">「?关键字」：先按「?」键，再输入您想寻找的字符，如果第一次找的关键字不是想要的，可以一直按「n」会往前寻找到您要的关键字为止。</span>',
									'<span>字符的替换</span>',
									'<span style="text-indent:1em;">:%s/源字符/替换字符	将每行出现的第一个源字符替换为目标字符。</span>',
									'<span style="text-indent:1em;">:%s/源字符/替换后字符/g	将全文源字符替换为目标字符。</span>',
									'<span style="text-indent:1em;">:8,10s/源字符/替换后字符/g	替换第八行到第十行的字符。</span>',
								],
							},
						],
					},
					{
						tittle:'3. vim打开多个文件、同时显示多个文件、在文件之间切换',
						detail:[
							{
								tittle:[
									'<span>打开多个文件</span>',
									'<span style="text-indent:1em;">vim还没有启动</span>',
									'<span style="text-indent:2em;">vim ﬁle1 ﬁle2 ... ﬁlen便可以打开所有想要打开的文件。</span>',
									'<span style="text-indent:1em;">vim已经启动</span>',
									'<span style="text-indent:2em;">:open ﬁle 再打开一个文件，并且此时vim里会显示出ﬁle文件的内容。</span>',
									'<span style="text-indent:1em;">在文件之间切换焦点</span>',
									'<span style="text-indent:2em;">Ctrl+6—最后访问的两个文件之间切换 :bn—下一个文件 :bp—上一个文件分。</span>',
									'<span>栏显示</span>',
									'<span style="text-indent:1em;">直接以分栏方式打开多个文件</span>',
									'<span style="text-indent:2em;">vim -on ﬁle1 ﬁle2 ... ﬁlen	上下分栏打开n个文件</span>',
									'<span style="text-indent:2em;">vim -On ﬁle1 ﬁle2 ... ﬁlen	左右分栏打开n个文件</span>',
									'<span style="text-indent:1em;">当前编辑文件分栏，两栏内容相同</span>',
									'<span style="text-indent:2em;">:sp 上下分割</span>',
									'<span style="text-indent:2em;">:vsp 左右分割</span>',
									'<span style="text-indent:1em;">打开新文件新文件并与当前文件分栏</span>',
									'<span style="text-indent:2em;">:sp xxx 在当前文件上方栏打开文件xxx，且xxx变为当前编辑文件</span>',
									'<span style="text-indent:2em;">:vsp xxx 在当前文件左侧栏打开文件xxx，且xxx变为当前编辑文件</span>',
									'<span>在各栏切换的方法</span>',
									'<span style="text-indent:1em;">Ctrl+w+h/j/k/l ——切换到前／下／上／后栏Ctrl+w+方向键 ——功能同上</span>',
									'<span style="text-indent:1em;">Ctrl+ww	——切换到下一个窗格中，周而复始</span>',
									'<span style="text-indent:1em;">:set mouse=a 开启鼠标控制，鼠标切换窗口</span>',
									'<span>关闭分栏</span>',
									'<span style="text-indent:1em;">Ctrl+w+c	松开Ctrl再按c，否则命令无效</span>',
									'<span>调整当前分栏大小（省略n只缩放1行/列）</span>',
									'<span style="text-indent:1em;">Ctrl+w+n> 左右扩大n列</span>',
									'<span style="text-indent:1em;">Ctrl+w+n< 左右缩小n列</span>',
									'<span style="text-indent:1em;">Ctrl+w+n+ 上下扩大n行</span>',
									'<span style="text-indent:1em;">Ctrl+w+n- 上下缩小n行</span>',
								],
							},
						],
					},
					{
						tittle:'<b>vim恢复机制</b>',
					},
					{
						tittle:'<span>vim正在编辑某个文件时都会同时存在.ﬁle.swp，此文件用来暂存，帮助恢复文件内容，当vim正常关闭此文件时，.ﬁlename.swp文件会消失。</span>',
					},
					{
						tittle:'1. 我们看到如下所示说明了出现此界面的两个原因：',
						detail:[
							{
								code:[
									'E325∶注意',
									'发现交换文件".1. txt.Swp"',
									'<span style="text-indent:5em;">所有者∶root  日期: Sat Feb 22 07∶19∶32 2020</span>',
									'<span style="text-indent:5em;">文件名∶~root/1.txt</span>',
									'<span style="text-indent:5em;">修改过∶否</span>',
									'<span style="text-indent:5em;">用 户名∶ root     主机名∶ linx3</span>',
									'<span style="text-indent:5em;">进程 ID∶2381 （仍在运行）</span>',
									'正在打开文件 "1.txt"',
									'<span style="text-indent:5em;">日期∶ Sat Feb 22 07∶19∶27 2020</span>',
									'<br>',
									'（1）另一个程序可能也在编辑同一个文件。',
									'<span style="text-indent:2em;">如果是这样，修改时请注意避免同一个文件产生两个不同的版本。</span>',
									'<span style="text-indent:2em;">退出 ，或小心地继续。</span>',
									'<br>',
									'（2）上次编辑此文 件时崩溃。',
									'<span style="text-indent:2em;">如果是这样，请用 "∶recover" 或 "vim-r 1.txt"</span>',
									'<span style="text-indent:2em;">恢复修改的内容 （请见 "∶heLp recovery"）。</span>',
									'<span style="text-indent:2em;">如果你已经进行了恢复，请删除交换文件 ".1.txt.Swp"</span>',
									'<span style="text-indent:2em;">以避 免再 看 到此消息。',
									'<br>',
									'交换文件 ".1.txt.Swp" 已存在 !',
									'以只读方式打开（[O]），直接编辑（（E）），恢复（（R）），退出（（Q）），中止（（A））∶',
								]
							},
							{
								tittle:[
									'<span>多人同时编辑此文件：因为Linux是多用户的操作系统，因此可能两个人同时登陆并编辑此文件，如果A进入系统开始编辑1.txt文件，则会出现.1.txt.swp，当A还没编辑完但B也想进去编辑时，因为此文件的目录中存在.1.txt.swp，则就会出现上图的界面；</span>',
									'<span>非正常关闭文件；</span>',
								],
							},
						],
					},
					{
						tittle:'2. 上述代码框中，最后一行说明了我们此时可以采取的几个动作：',
						detail:[
							{
								tittle:[
									'<span>O：以只读方式打开；</span>',
									'<span>R：恢复，即从swp文件中恢复，但是swp文件不会随着vim关闭而删除，需要手动删除；</span>',
									'<span>Q：退出vim。</span>',
								],
							},
						],
					},
					{
						tittle:'3. 恢复',
						detail:[
							{
								tittle:[
									'<span>若果原文件没有被删除，那么直接删除 rm -rf.ﬁlename.swap 就可以了。</span>',
									'<span>如果源文件已经被删除，那么 vim -r .ﬁlename.swap或者vim -r ﬁlename 利用swp文件恢复原文件（前提是系统没有重启，系统重启后，该文件也会消失），恢复以后把.swap文件删掉 rm -rf  .ﬁlename.swap。</span>',
								],
							},
						],
					},
				],
			},
		},
		//系统使用与配置类
		{
			tittle:'60系统关闭numa功能',
			text:{
				problem:[
					'60系统关闭numa功能',
				],
				scope:[
					'linx-6.0.60',
				],
				resolvent:[
					{
						tittle:'1. 编辑文件/etc/default/grub，如下图位置添加numa=off',
						detail:[
							{
								code:[
									'~# vim /etc/default/grub',
								],
								img:[
									require('../../images/question/60系统关闭numa功能-01.png'),
								],
							},
						],
					},
					{
						tittle:'2. 运行update-grub，使以上更改生效，然后重启系统',
						detail:[
							{
								code:[
									'~# update-grub',
								],
								img:[
									require('../../images/question/60系统关闭numa功能-02.png'),
								],
							},
						],
					},
				],
			},
		},
		/*{
			tittle:'60系统镜像源配置',
			text:{
				problem:[
					'60系统配置本地镜像源',
				],
				scope:[
					'linx-6.0.60',
				],
				resolvent:[
					{
						tittle:'1. 将镜像文件拷贝至/opt目录',
						detail:[
							{
								code:[
									'~# ls /opt',
									'<b>linx-6.0.60-700-20180514-amd64-DVD.iso</b>',
								],
							},
						],
					},
					{
						tittle:'2. 创建目录，并将镜像挂载至此目录',
						detail:[
							{
								code:[
									'~# mkdir -p /rocky/iso',
									'~# mount -o loop /opt/linx-6.0.60-700-20180514-amd64-DVD.iso /rocky/iso',
									'~# df -h',
									'<b>文件系统        容量  已用  可用 已用% 挂载点</b>',
									'<b>udev             10M      0   10M    0% /dev</b>',
									'<b>tmpfs          388M   11M  378M     3% /run</b>',
									'<b>/dev/sda1        95G  9.7G   80G   11% /</b>',
									'<b>tmpfs           970M   72K  970M    1% /dev/shm</b>',
									'<b>tmpfs            5.0M  4.0K  5.0M    1% /run/lock</b>',
									'<b>tmpfs            970M     0  970M    0% /sys/fs/cgroup</b>',
									'<b>tmpfs          194M  4.0K  194M    1% /run/user/119</b>',
									'<b>tmpfs           194M   20K  194M    1% /run/user/0</b>',
									'<b>/dev/loop0      4.3G  4.3G     0  100% /rocky/iso</b>',
								],
							},
						],
					},
					{
						tittle:'3. 修改配置文件/etc/apt/sources.list，添加镜像源',
						detail:[
							{
								code:[
									'~# vim /etc/apt/sources.list',
									'<b>deb file:///rocky/iso/ squeeze contrib main non-free</b>',
								],
							},
						],
					},
					{
						tittle:'4. 保存后更新源',
						detail:[
							{
								code:[
									'~# apt-get update',
								],
							},
						],
					},
					{
						tittle:'5. 搜索软件',
						detail:[
							{
								code:[
									'~# apt-cache search package_name',
								],
							},
						],
					},
					{
						tittle:'6. 安装软件',
						detail:[
							{
								code:[
									'~# apt-get install package_name',
								],
							},
						],
					},
					{
						tittle:'7. 清理包缓存',
						detail:[
							{
								code:[
									'~# apt-get clean',
								],
							},
						],
					},
				],
			},
		},
		*/
		{
			tittle:'60系统忘记root密码',
			text:{
				problem:[
					'60系统忘记root密码后，可选择以下方式修改',
				],
				scope:[
					'linx-6.0.60',
				],
				resolvent:[
					{
						tittle:'1. 未忘记secadmin账户密码（默认为R0ck9）的情况下，直接登录secadmin账户，打开终端，执行passwd命令修改root密码即可',
						detail:[
							{
								code:[
									'~# passwd root',
								],
							},
						],
					},
					{
						tittle:'2. 如果secadmin密码也忘记无法登录，则进入单用户模式修改root密码',
						detail:[
							{
								tittle:[
									'<span>重启机器，在grub界面按e键</span>',
								],
								img:[
									require('../../images/question/60系统忘记root密码-01.png'),
								],
							},
							{
								tittle:[
									'<span>在quiet前面加上init=/bin/bash后，ctrl x进入系统</span>',
								],
								img:[
									require('../../images/question/60系统忘记root密码-02.png'),
								],
							},
							{
								tittle:[
									'<span>进入bash后，需要将根挂载可读写，再修改密码</span>',
								],
								code:[
									'~#  mount  -o  remount,rw  /',
									'~#  passwd root',
								],
							},
						],
					},
				],
			},
		},
		/*
		{
			tittle:'80系统镜像源配置',
			text:{
				problem:[
					'80系统配置本地镜像源',
				],
				scope:[
					'linx-6.0.80',
				],
				resolvent:[
					{
						tittle:'1. 将镜像文件拷贝至/opt目录',
						detail:[
							{
								code:[
									'~# ls /opt',
									'<b>Linx-6.0.80-20180821-amd64-DVD.iso</b>',
								],
							},
						],
					},
					{
						tittle:'2. 创建目录，并将镜像挂载至此目录',
						detail:[
							{
								code:[
									'~# mkdir -p /rocky/iso',
									'~# mount -o loop /opt/linx-6.0.60-700-20180514-amd64-DVD.iso /rocky/iso',
									'~# df -h',
									'<b>文件系统        容量  已用  可用 已用% 挂载点</b>',
									'<b>udev             10M      0   10M    0% /dev</b>',
									'<b>tmpfs          388M   11M  378M     3% /run</b>',
									'<b>/dev/sda1        95G  9.7G   80G   11% /</b>',
									'<b>tmpfs           970M   72K  970M    1% /dev/shm</b>',
									'<b>tmpfs            5.0M  4.0K  5.0M    1% /run/lock</b>',
									'<b>tmpfs            970M     0  970M    0% /sys/fs/cgroup</b>',
									'<b>tmpfs          194M  4.0K  194M    1% /run/user/119</b>',
									'<b>tmpfs           194M   20K  194M    1% /run/user/0</b>',
									'<b>/dev/loop0      4.3G  4.3G     0  100% /rocky/iso</b>',
								],
							},
						],
					},
					{
						tittle:'3. 修改配置文件/etc/apt/sources.list，添加镜像源',
						detail:[
							{
								code:[
									'~# vim /etc/apt/sources.list',
									'<b>deb file:///rocky/iso/ jessie contrib main non-free</b>',
								],
							},
						],
					},
					{
						tittle:'4. 保存后更新源',
						detail:[
							{
								code:[
									'~# apt-get update',
								],
							},
						],
					},
					{
						tittle:'5. 搜索软件',
						detail:[
							{
								code:[
									'~# apt-cache search package_name',
								],
							},
						],
					},
					{
						tittle:'6. 安装软件',
						detail:[
							{
								code:[
									'~# apt-get install package_name',
								],
							},
						],
					},
					{
						tittle:'7. 清理包缓存',
						detail:[
							{
								code:[
									'~# apt-get clean',
								],
							},
						],
					},
				],
			},
		},
		*/
		{
			tittle:'串口使用方法',
			text:{
				problem:[
					'怎么看哪个串口设备是可以使用的？以及串口的简单调试？',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
				],
				resolvent:[
					{
						tittle:'1. 如何查看系统上有哪些串口设备可以使用',
						detail:[
							{
								tittle:[
									'<span>在凝思操作系统中，串口设备位于/dev目录下，如果没有外插串口卡的话，一般以/dev/ttySx(x表示数字，如ttyS1)的形式表示．</span>',
									'<span>如果是usb转串口的话，一般以/dev/ttyUSBx(x表示数字，如ttyUSB1)的形式表示．</span>',
									'<span>有以下三种方式可以查看当前可用的串口：</span>',
								],
							},
						],
					},
					{
						tittle:'2. 如何查看串口驱动信息',
						detail:[
							{
								tittle:[
									'<span>通过查看系统的串口驱动信息来查看可用的串口设备．</span>',
								],
								code:[
									'~# cat /proc/tty/driver/serial',
								],
							},
							{
								tittle:[
									'<span>结果如下：</span>',
								],
								code:[
									'~# cat /proc/tty/driver/serial',
									'<b>serinfo:1.0 driver revision:</b>',
									'<b>0: uart:16550A port:000003F8 irq:4 tx:4 rx:0 CTS|DSR|CD</b>',
									'<b>1: uart:unknown port:000002F8 irq:3</b>',
									'<b>2: uart:unknown port:000003E8 irq:4</b>',
									'<b>3: uart:unknown port:000002E8 irq:3</b>',
								],
							},
							{
								tittle:[
									'<span>解析：上述是串口的驱动信息，其中有参数的表示该串口可用，结果显示第0号串口可用，即ttyS0可用．</span>',
								],
							},
						],
					},
					{
						tittle:'3. 查看dmesg',
						detail:[
							{
								tittle:[
									'<span>通过查看系统启动的dmesg信息来查看可用的串口设备．</span>',
								],
								code:[
									'~# dmesg | grep ttyS*',
								],
							},
							{
								tittle:[
									'<span>结果如下：</span>',
								],
								code:[
									'~# dmesg | grep ttyS*',
									'<b>[    6.854382] console [tty0] enabled</b>',
									'<b>[    7.310367] serial8250: ttyS0 at I/O 0x3f8 (irq = 4) is a 16550A</b>',
									'<b>[    7.310616] 00:04: ttyS0 at I/O 0x3f8 (irq = 4) is a 16550A</b>',
									'<b>解析：上述是系统启动过程中初始化的串口设备，如上初始化了ttyS0,结果显示ttyS0可用．</b>',
								],
							},
							{
								tittle:[
									'<span>解析：上述是串口的驱动信息，其中有参数的表示该串口可用，结果显示第0号串口可用，即ttyS0可用．</span>',
								],
							},
						],
					},
					{
						tittle:'4. 向串口发送数据',
						detail:[
							{
								tittle:[
									'<span>通过向串口设备发送数据来查看可用的串口设备</span>',
								],
								code:[
									'~# echo “xxx” > /dev/ttyS*',
								],
							},
							{
								tittle:[
									'<span>结果如下:</span>',
								],
								code:[
									'~# echo "aa" > /dev/ttyS0',
									'~# echo "aa" > /dev/ttyS1',
									'<b>-bash: echo: 写错误: 输入/输出错误</b>',
									'~# echo "aa" > /dev/ttyS2',
									'<b>-bash: echo: 写错误: 输入/输出错误</b>',
								],
							},
							{
								tittle:[
									'<span>解析：上述分别向ttyS0,ttyS1,ttyS2三个串口写信息，只有ttyS0写入成功，所以ttyS0可用．</span>',
									'<span>如果使用usb转串口设备时,通过以下方式查看设备名：</span>',
									'<span>查看 /proc/tty/driver/usbserial</span>',
								],
								code:[
									'~# cat /proc/tty/driver/usbserial',
									'<b>usbserinfo:1.0 driver:2.0</b>',
									'<b>0: module:cp210x name:"cp210x" vendor:10c4 product:ea60 num_ports:1 port:0 path:usb-0000:00:14.0-6</b>',
								],
								// img:[
								// 	require('../../images/question/'),
								// ],
							},
							{
								tittle:[
									'<span>查看kern.log日志，该usb转串口设备初始化为ttyUSB0,所以设备名为：/dev/ttyUSB0.</span>',
								],
								code:[
									'<b>2018-10-17T17:04:55.094434+08:00 Linxc kernel: [ 2894.661957] usbserial: USB Serial Driver core</b>',
									'<b>2018-10-17T17:04:55.256400+08:00 Linxc kernel: [ 2894.824012] USB Serial support registered for pl2303</b>',
									'<b>2018-10-17T17:04:55.256435+08:00 Linxc kernel: [ 2894.824546] pl2303 2-2:1.0: pl2303 converter detected</b>',
									'<b>2018-10-17T17:04:55.312853+08:00 Linxc kernel: [ 2894.880223] usb 2-2: pl2303 converter now attached to ttyUSB0</b>',
								],
							},
							{
								tittle:[
									'<span>小结：</span>',
									'<span>如果是串口线的话，系统设备一般为：/dev/ttyS\*.</span>',
									'<span>如果是usb转串口的话，系统设备一般为：/dev/ttyUSB\*.</span>',
									'<span>设备号从0开始，以此类推．</span>',
								],
							},
						],
					},
					{
						tittle:'5. 如何进行一些简单串口调试',
						detail:[
							{
								tittle:[
									'<span></span>',
								],
							}
						],
					},
					{
						tittle:'6. stty的方式',
						detail:[
							{
								tittle:[
									'<span>首先,查看当前参数设置：</span>',
								],
								code:[
									'~# stty -F /dev/ttyS* -a',
								],
							},
							{
								tittle:[
									'<span>或者</span>',
								],
								code:[
									'~# stty -F /dev/ttyUSB* -a',
								],
							},
							{
								tittle:[
									'<span>该命令用于查看指定串口设备的通信参数，-F:指定设备．-a:完整信息．</span>',
									'<span>结果如下：</span>',
								],
								code:[
									'~# stty  -F /dev/ttyS0 -a',
									'<b>speed 9600 baud; rows 0; columns 0; line = 0;</b>',
									'<b>intr = ^C; quit = ^\; erase = ^?; kill = ^U; eof = ^D; eol = <undef>;</b>',
									'<b>eol2 = <undef>; swtch = <undef>; start = ^Q; stop = ^S; susp = ^Z; rprnt = ^R;</b>',
									'<b>werase = ^W; lnext = ^V; flush = ^O; min = 1; time = 0;</b>',
									'<b>-parenb -parodd cs8 hupcl -cstopb cread clocal -crtscts</b>',
									'<b>-ignbrk -brkint -ignpar -parmrk -inpck -istrip -inlcr -igncr icrnl ixon -ixoff</b>',
									'<b>-iuclc -ixany -imaxbel -iutf8</b>',
									'<b>opost -olcuc -ocrnl onlcr -onocr -onlret -ofill -ofdel nl0 cr0 tab0 bs0 vt0 ff0</b>',
									'<b>isig icanon iexten echo echoe echok -echonl -noflsh -xcase -tostop -echoprt</b>',
									'<b>echoctl echoke</b>',
								],
							},
							{
								tittle:[
									'<span>一般我们只需要关心<b>speed,cs8, parenb,cstopb,ixon</b>参数即可．</span>',
									'<span><h>speed</h>：波特率(波特率越高传输速率越快，但可靠性会降低).</span>',
									'<span><h>cs8</h>：字符大小为8bit(可选cs5,cs6,cs7).</span>',
									'<span><h>parenb</h>：奇偶校验．(-parenb表示禁止校验)．</span>',
									'<span><h>cstopb</h>：设置停止位(cstopb表示两个停止位，-cstopb表示一个停止位)．</span>',
								],
							},
							{
								tittle:[
									'<span>然后,进行简单配置：</span>',
								],
								code:[
									'~# stty -F /dev/ttyS0 ispeed 115200 ospeed 115200 cs8  -cstopb　-parenb',
								],
							},
							{
								tittle:[
									'<span>表示设置/dev/ttyS0串口设备：输入输出波特率都为115200,8数据位，1停止位，无校验位．</span>',
									'<span>备注：以上设置项可进行调整，但前提是保持串口两端参数一致（发送端与接收端）．</span>',
									'<span>最后,进行简单测试：</span>',
									'<span>作为接收端：</span>',
								],
								code:[
									'~# cat /dev/ttyS*',
								],
							},
							{
								tittle:[
									'<span>这时候如果发送端正在发送数据，此时就会自动读取到数据．</span>',
									'<span>作为发送端：</span>',
								],
								code:[
									'~# echo “xxx” > /dev/ttyS*',
								],
							},
							{
								tittle:[
									'<span>使用echo向串口写数据．在接收端就能读取到数据．</span>',
								],
							},
						],
					},
					{
						tittle:'7. minicom的方式',
						detail:[
							{
								tittle:[
									'<span>启动minicom</span>',
								],
								code:[
									'~# sudo minicom -s',
								],
							},
							{
								tittle:[
									'<span>使用组合键 CTRL+A 然后松开按Z键进入minicom配置界面</span>',
								],
								code:[
									'<b>Welcome to minicom 2.7.1</b>',
									'<br>',
									'<b>OPTIONS: I18n</b>',
									'<b>Compiled on Aug 13 2017, 15:25:34.</b>',
									'<b>Port /dev/ttyUSB0, 10:53:53</b>',
									'<br>',
									'<b>Press CTRL-A Z for help on special keys</b>',
									'<b>+-------------------------------------------------------------------+</b>',
									'<b>|                      Minicom Command Summary                      |</b>',
									'<b>|                                              |</b>',
									'<b>|              Commands can be called by CTRL-A <key>             |</b>',
									'<b>|                                              |</b>',
									'<b>|             Main Functions                  Other Functions     |</b>',
									'<b>|                                              |</b>',
									'<b>| Dialing directory..D  run script (Go)....G | Clear Screen.......C |</b>',
									'<b>| Send files.........S  Receive files......R | cOnfigure Minicom..O |</b>',
									'<b>| comm Parameters....P  Add linefeed.......A | Suspend minicom....J |</b>',
									'<b>| Capture on/off.....L  Hangup.............H | eXit and reset.....X |</b>',
									'<b>| send break.........F  initialize Modem...M | Quit with no reset.Q |</b>',
									'<b>| Terminal settings..T  run Kermit.........K | Cursor key mode....I |</b>',
									'<b>| lineWrap on/off....W  local Echo on/off..E | Help screen........Z |</b>',
									'<b>| Paste file.........Y  Timestamp toggle...N | scroll Back........B |</b>',
									'<b>| Add Carriage Ret...U                                             |</b>',
									'<b>|                                              |</b>',
									'<b>|             Select function or press Enter for none.              |</b>',
									'<b> +-------------------------------------------------------------------+</b>',
								],
							},
							{
								tittle:[
									'<span>进入minicom配置菜单界面，按O选择`cOnfigure Minicom`进入配置界面</span>',
								],
								code:[
									'<b>+-----[configuration]------+</b>',
									'<b>| Filenames and paths      |</b>',
									'<b>| File transfer protocols  |</b>',
									'<b>| Serial port setup        |</b>',
									'<b>| Modem and dialing        |</b>',
									'<b>| Screen and keyboard      |</b>',
									'<b>| Save setup as dfl        |</b>',
									'<b>| Save setup as..          |</b>',
									'<b>| Exit                    |</b>',
									'<b>+--------------------------+</b>',
								],
							},
							{
								tittle:[
									'<span>用上下键选择<h>Serial port setup</h>，回车．此时光标</span>',
									'<span>在<h>change which setting?</h>后面停留，它的上面有如下菜单：</span>',
								],
								code:[
									'<b>+----------------------------------------+</b>',
									'<b>| A - Serial Device : /dev/ttyUSB0       |</b>',
									'<b>| B - Lockfile Location : /var/lock      |</b>',
									'<b>| C - Callin Program :                   |</b>',
									'<b>| D - Callout Program -:                 |</b>',
									'<b>| E - Bps/Par/Bits : 9600 8N1            |</b>',
									'<b>| F - Hardware Flow Control : No         |</b>',
									'<b>| G - Software Flow Control : No         |</b>',
									'<b>| Change which setting?                  |</b>',
									'<b>+----------------------------------------+</b>',
								],
							},
							{
								tittle:[
									'<span>我们只需输入上面对应的字母，就可以进如相应的菜单进行设置。</span>',
									'<span>设置完成，回车，光标会回到<h>change which setting</h>后面，</span>',
									'<span>如此重复。完成后按回车返回主菜单即可。</span>',
									'<span>返回主菜单后，选择<h>Save setup as df1</h>，将其保存为默认设置（默认保存到/etc/minicom目录下），</span>',
									'<span>然后选择X  <h>eXit and reset.....X</h></span>',
								],
								code:[
									'<b>+----------------------+</b>',
									'<b>|    Leave Minicom?    |</b>',
									'<b>|     Yes       No     |</b>',
									'<b>+----------------------+</b>',
								],
							},
							{
								tittle:[
									'<span>Yes退出。</span>',
									'<span>需退出后重新打开minicom，软件才会使用上述参数进行初始化。</span>',
									'<span>配置解析：</span>',
									'<span>1. serial device:串口设备，如果沒有使用USB转串口，而是直接使用串口，那么Serial Device要配置为/dev/ttyS0。</span>',
									'<span>2. Bps/Par/Bits:9600 8N1对应了第一种方法的：波特率(9600)，数据位(8)，校验位(N)，停止位(1)四个参数。</span>',
									'<span>3. Hardware Flow Control:硬件流控，一般为no。</span>',
									'<span>4. Software Flow Control:软件流控，一般为no。</span>',
									'<span>完成上述配置之后，可以进行简单的测试：</span>',
									'<span>作为接收端：</span>',
								],
								code:[
									'~# minicom',
								],
							},
							{
								tittle:[
									'<span>这时候如果发送端正在发送数据，此时就会自动读出串口数据。</span>',
									'<span>作为发送端：</span>',
								],
								code:[
									'~# minicom',
								],
							},
							{
								tittle:[
									'<span>这时候在终端向串口写数据(eg:直接键盘输入)，在接收端就能读取到数据。</span>',
								],
							},
						],
					},
					{
						tittle:'8. 总结',
						detail:[
							{
								tittle:[
									'<span>1. 以上两种方式在配置串口时，必须保持串口两端的参数一致（发送端与接收端），避免接收不到或者乱码等现象。</span>',
									'<span>2. 执行minicom时需要root用户下，如果不是,需要执行sudo minicom。</span>',
								],
							},
						],
					},
				],
			},
		},
		/*
		{
			tittle:'凝思官网源配置',
			text:{
				problem:[
					'配置凝思官网源',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 配置好网络与DNS，确定凝思官网能ping通',
						detail:[
							{
								code:[
									'~# vim /etc/resolv.conf',
									'<b>nameserver 114.114.114.114</b>',
									'~# ping www.linx-info.com',
									'<b>PING www.linx-info.com (172.31.255.3) 56(84) bytes of data.</b>',
									'<b>64 bytes from 172.31.255.3: icmp_seq=1 ttl=61 time=36.1 ms</b>',
									'<b>64 bytes from 172.31.255.3: icmp_seq=2 ttl=61 time=36.3 ms</b>',
									'<b>64 bytes from 172.31.255.3: icmp_seq=3 ttl=61 time=36.9 ms</b>',
								],
							},
						],
					},
					{
						tittle:'2. 修改配置文件/etc/apt/sources.list，添加凝思官网源',
						detail:[
							{
								tittle:[
									'<span>6.0.60系统</span>',
								],
								code:[
									'~#  vim /etc/apt/sources.list',
									'<b>deb http://www.linx-info.com/mirrors/mirrors-6.0.60 squeeze main non-free contrib</b>',
								],
							},
							{
								tittle:[
									'<span>6.0.80系统</span>',
								],
								code:[
									'~#  vim /etc/apt/sources.list',
									'<b>deb http://www.linx-info.com/mirrors/mirrors-6.0.80 jessie main contrib  non-free</b>',
								],
							},
							{
								tittle:[
									'<span>6.0.100系统</span>',
								],
								code:[
									'~#  vim /etc/apt/sources.list',
									'<b>deb  http://www.linx-info.com/mirrors/mirrors-6.0.100/  buster main contrib non-free</b>',
								],
							},
						],
					},
					{
						tittle:'3. 保存后更新源',
						detail:[
							{
								code:[
									'~#  apt-get update',
								],
							},
						],
					},
					{
						tittle:'4. 搜索软件',
						detail:[
							{
								code:[
									'~#  apt-cache search package_name',
								],
							},
						],
					},
					{
						tittle:'5. 安装软件',
						detail:[
							{
								code:[
									'~#  apt-get install package_name',
								],
							},
						],
					},
					{
						tittle:'6. 清理包缓存',
						detail:[
							{
								code:[
									'~#  apt-get clean',
								],
							},
						],
					},
				],
			},
		},
		*/
		{
			tittle:'凝思系统进入单用户模式',
			text:{
				problem:[
					'凝思系统进入单用户模式，在此模式下，用户可以访问修复错误配置的文件。也可以在此模式下安装显卡驱动，解决和已加载驱动的冲突问题。',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 重新启动系统，在grub界面（如下图）按"e"键，进入引导编辑',
						detail:[
							{
								tittle:[
									'<span>60系统</span>',
								],
								img:[
									require('../../images/question/凝思系统进入单用户模式-01.png'),
								],
							},
							{
								tittle:[
									'<span>80和100系统</span>',
								],
								img:[
									require('../../images/question/凝思系统进入单用户模式-02.png'),
								],
							},
						],
					},
					{
						tittle:'2. 在如下位置quiet前添加"init=/bin/bash"参数，完成后按"ctrl+x"即可引导进入单用户模式',
						detail:[
							{
								tittle:[
									'<span>60系统</span>',
								],
								img:[
									require('../../images/question/凝思系统进入单用户模式-03.png'),
								],
							},
							{
								tittle:[
									'<span>80和100系统</span>',
								],
								img:[
									require('../../images/question/凝思系统进入单用户模式-04.png'),
								],
							},
						],
					},
					{
						tittle:'3. 进入单用户模式后重新挂载/',
						detail:[
							{
								code:[
									'@(none):/#  mount -o remount,rw /',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'凝思系统设置字符编码',
			text:{
				problem:[
					'凝思系统如何设置字符编码',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 查看当前正在使用的编码',
						detail:[
							{
								code:[
									'~# locale',
									'<b>LANG=zh_CN.GB18030</b>',
									'<b>LANGUAGE=zh_CN:zh</b>',
									'<b>LC_CTYPE="zh_CN.GB18030"</b>',
									'<b>LC_NUMERIC="zh_CN.GB18030"</b>',
									'<b>LC_TIME="zh_CN.GB18030"</b>',
									'<b>LC_COLLATE="zh_CN.GB18030"</b>',
									'<b>LC_MONETARY="zh_CN.GB18030"</b>',
									'<b>LC_MESSAGES="zh_CN.GB18030"</b>',
									'<b>LC_PAPER="zh_CN.GB18030"</b>',
									'<b>LC_NAME="zh_CN.GB18030"</b>',
									'<b>LC_ADDRESS="zh_CN.GB18030"</b>',
									'<b>LC_TELEPHONE="zh_CN.GB18030"</b>',
									'<b>LC_MEASUREMENT="zh_CN.GB18030"</b>',
									'<b>LC_IDENTIFICATION="zh_CN.GB18030"</b>',
									'<b>LC_ALL=</b>',
								],
							},
						],
					},
					{
						tittle:'2. 使用dpkg-reconfigure locales命令进行配置',
						detail:[
							{
								code:[
									'~# dpkg-reconfigure locales',
								],
							},
						],
					},
					{
						tittle:'3. 选择需要增加的语言编码，如果不需要增加直接点击确定进行下一步，配置已经存在的语言编码',
						detail:[
							{
								img:[
									require('../../images/question/凝思系统设置字符编码-01.png'),
								],
							},
						],
					},
					{
						tittle:'4. 目前是zh_CN.GB18030，改为zh_CN.UTF-8，选择确定即可',
						detail:[
							{
								img:[
									require('../../images/question/凝思系统设置字符编码-02.png'),
								],
							},
							{
								code:[
									'<b>Generating locales (this might take a while)...</b>',
									'<b>zh_CN.GB2312... done</b>',
									'<b>zh_CN.GB18030... done</b>',
									'<b>zh_CN.GBK... done</b>',
									'<b>zh_CN.UTF-8... done</b>',
									'<b>Generation complete</b>',
								],
							},
						],
					},
					{
						tittle:'5. 80系统注销后登陆即生效，60系统需要在输入密码界面，左下角手动选择编码再登陆才生效',
						detail:[
							{
								img:[
									require('../../images/question/凝思系统设置字符编码-03.jpg'),
								],
							},
						],
					},
					{
						tittle:'6. 登陆后查看环境编码已成功修改',
						detail:[
							{
								code:[
									'root@rocky60-180514:~# locale',
									'<b>LANG=zh_CN.UTF-8</b>',
									'<b>LANGUAGE=zh_CN:zh</b>',
									'<b>LC_CTYPE="zh_CN.UTF-8"</b>',
									'<b>LC_NUMERIC="zh_CN.UTF-8"</b>',
									'<b>LC_TIME="zh_CN.UTF-8"</b>',
									'<b>LC_COLLATE="zh_CN.UTF-8"</b>',
									'<b>LC_MONETARY="zh_CN.UTF-8"</b>',
									'<b>LC_MESSAGES="zh_CN.UTF-8"</b>',
									'<b>LC_PAPER="zh_CN.UTF-8"</b>',
									'<b>LC_NAME="zh_CN.UTF-8"</b>',
									'<b>LC_ADDRESS="zh_CN.UTF-8"</b>',
									'<b>LC_TELEPHONE="zh_CN.UTF-8"</b>',
									'<b>LC_MEASUREMENT="zh_CN.UTF-8"</b>',
									'<b>LC_IDENTIFICATION="zh_CN.UTF-8"</b>',
									'<b>LC_ALL=</b>',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'凝思系统修改时间',
			text:{
				problem:[
					'修改系统时间以及写入硬件时间',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 使用date -s命令修改系统时间（填写具体时间）',
						detail:[
							{
								code:[
									'~# date -s  "2020:12:28 13:00"',
								],
							},
						],
					},
					{
						tittle:'2. 写入硬件时间，重启后则不会出现时间"还原"的现象',
						detail:[
							{
								code:[
									'~# hwclock -w',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'声卡配置',
			text:{
				problem:[
					'声卡配置方法',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
				],
				resolvent:[
					{
						tittle:'1. 关闭集成声卡的方法',
						detail:[
							{
								tittle:[
									'<span>华为服务器上调试usb声卡时,发现系统启动时,能发现两个声卡,一个是独立显卡集成的声卡, 另一个是外接的usb声卡,而且在系统启动时,这两个声卡的顺序不固定,如果usb声卡的顺序为0时,能正常播放声音,如果usb声卡的顺序为1时 , 不能正常播放声音</span>',
									'<span>运行lsmod,发现集成声卡对应的模块为snd_hda_intel</span>',
								],
								code:[
									'~# vim /etc/modprobe.d/blacklist.conf',
								],
							},
							{
								tittle:[
									'<span>在文件中增加一行(即在黑名单中增加一行,系统启动时,就不加载集成声卡的驱动):</span>',
								],
								code:[
									'<b>blacklist snd_hda_intel</b>',
								],
							},
							{
								tittle:[
									'<span>然后,重启系统后,就只能识别 usb 声卡了,也就可以正常播放声音了。</span>',
									'<span>注:在/ect/sysconfig/modules 文件中的每行的内容是白名单,指系统启动时,需要加载的驱动等模块;而/etc/modprobe.d/blacklist.conf 文件中的内容是黑名单,即系统启动时强制不加载的模块。</span>',
								],
							},
						],
					},
					{
						tittle:'2. 声卡初始化及音量调整',
						detail:[
							{
								tittle:[
									'<span>以root用户运行 alsaconf命令 可以对声卡进行初始化</span>',
									'<span>以root用户运行alsamixer命令可以调节声卡音量</span>',
								],
							},
						],
					},
					{
						tittle:'3. USB声卡设置',
						detail:[
							{
								tittle:[
									'<span>检查/etc/asound.conf 和~/.asoundrc 配置文件,可能需要将其中除 device 参数值之外的参数值由“0”修改为“1”,其中 device 参数值一般为 0,表示每块声卡的设备编号(从 0 开始);其它参数表示卡的编号(从 0 开始)。如果家目录下没有此配置文件则需手动创建。</span>',
									'<span> /etc/asound.conf 文件内容如下:</span>',
								],
								code:[
									'<b>defaults.pcm.card 1</b>',
									'<b>defaults.pcm.device 1</b>',
									'<b>defaults.ctl.card 1</b>',
									'<b>defaults.timer.card 1</b>',
								],
							},
							{
								tittle:[
									'<span>~/.asoundrc 文件的内容如下:</span>',
								],
								code:[
									'<b>defaults.ctl.card 1</b>',
									'<b>defaults.pcm.card 1</b>',
									'<b>defaults.pcm.device 1</b>',
									'<b>defaults.timer.card 1</b>',
								],
							},
						],
					},
					{
						tittle:'4. 验证声卡是否正常',
						detail:[
							{
								tittle:[
									'<span>系统自带有声音文件，可使用play命令播放系统自带的声音文件来确认声卡是否正常。</span>',
								],
								code:[
									'root@localhost:/usr/share/sounds# play KDE-Window-Move.ogg',
									'<br>',
									'<b> KDE-Window-Move.ogg:</b>',
									'<br>',
									'<b>File Size: 13.6k     Bit Rate: 71.2k</b>',
									'<b>  Encoding: Vorbis</b>',
									'<b>  Channels: 1 @ 16-bit</b>',
									'<b>Samplerate: 44100Hz</b>',
									'<b>Replaygain: off</b>',
									'<b>  Duration: 00:00:01.52</b>',
									'<br>',
									'<b>In:100%  00:00:01.52 [00:00:00.00] Out:73.2k [      |      ]        Clip:0</b>',
									'<b>Done.</b>',
								],
							},
						],
					},
					{
						tittle:'5. 检查声卡的相关命令',
						detail:[
							{
								code:[
									'~#lsusb<span># 可查看是否插入了 usb 声卡</span>',
									'~#aplay -l<span># 可查看系统识别的是哪种声卡</span>',
									'~#aplay -L<span># 查看系统配置使用的是哪种声卡</span>',
									'~#lspci -nn|grep -i audio<span># 查看有几块声卡</span>',
									'~#cat /proc/asound/cards<span># 查看有几块声卡</span>',
									'~#play /usr/share/sounds/KDE_Window_Open.wav',
									'~#rocky6.0.42.41 下,播放wav 文件',
									'~#play /usr/share/sounds/alsa/Side_Right.wav<span>#linx6.0 下,播放 wav 文件</span>',
									'~#cd /usr/share/doc/libasound2/examples',
									'~#gzip -d asound.txt.gz; or gunzip asound.txt.gz<span># 解开该帮助文件</span>',
								],
							},
						],
					},
					
				],
			},
		},
		{
			tittle:'修改网卡速率',
			text:{
				problem:[
					'修改网卡速率，将自适应变为指定速率',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 查看网卡速率，通过speed的值得出当前自适应的网速为1000Mb/s',
						detail:[
							{
								code:[
									'~# ethtool eth0',
									'<b>Settings for eth0:</b>',
									'<b><span>Supported ports: [ TP ]</span></b>',
									'<b><span>Supported link modes:   10baseT/Half 10baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half 100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b><span>Supported pause frame use: No</span></b>',
									'<b><span>Supports auto-negotiation: Yes</span></b>',
									'<b><span>Advertised link modes:  10baseT/Half 10baseT/Full </span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half 100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
							
									'<b><span>Advertised pause frame use: No</span></b>',
									'<b><span>Advertised auto-negotiation: Yes</span></b>',
									'<b><span>Speed: 1000Mb/s</span></b>',
									'<b><span>Duplex: Full</span></b>',
									'<b><span>Port: Twisted Pair</span></b>',
									'<b><span>PHYAD: 0</span></b>',
									'<b><span>Transceiver: internal</span></b>',
									'<b><span>Auto-negotiation: on</span></b>',
									'<b><span>MDI-X: off (auto)</span></b>',
									'<b><span>Supports Wake-on: d</span></b>',
									'<b><span>Wake-on: d</span></b>',
									'<b><span>Current message level: 0x00000007 (7)</span></b>',
									'<b><span style="text-indent:8em;">drv probe link</span></b>',
									'<b><span>Link detected: yes</span></b>',
								],
							},
						],
					},
					{
						tittle:'Link detected: yes',
						detail:[
							{
								code:[
									'~# ethtool -s eth0 speed 100 duplex full autoneg off',
								],
							},
						],
					},
					{
						tittle:'3. 查看网卡速率，网速变为指定的100Mb/s',
						detail:[
							{
								code:[
									'~# ethtool eth0',
									'<b>Settings for eth0:</b>',
									'<b><span>Supported ports: [ TP ]</span></b>',
									'<b><span>Supported link modes:   10baseT/Half 10baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half 100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b><span>Supported pause frame use: No</span></b>',
									'<b><span>Supports auto-negotiation: Yes</span></b>',
									'<b><span>Advertised link modes:  Not reported</span></b>',
									'<b><span>Advertised pause frame use: No</span></b>',
									'<b><span>Advertised auto-negotiation: Yes</span></b>',
									'<b><span>Speed: 1000Mb/s</span></b>',
									'<b><span>Duplex: Full</span></b>',
									'<b><span>Port: Twisted Pair</span></b>',
									'<b><span>PHYAD: 0</span></b>',
									'<b><span>Transceiver: internal</span></b>',
									'<b><span>Auto-negotiation: on</span></b>',
									'<b><span>MDI-X: off (auto)</span></b>',
									'<b><span>Supports Wake-on: d</span></b>',
									'<b><span>Wake-on: d</span></b>',
									'<b><span>Current message level: 0x00000007 (7)</span></b>',
									'<b><span style="text-indent:8em;">drv probe link</span></b>',
									'<b><span>Link detected: yes</span></b>',
								],
							},
						],
					},
					{
						tittle:'4. 恢复网卡自适应模式',
						detail:[
							{
								code:[
									'~# ethtool -s eth0 autoneg on',
									'~# ethtool eth0',
									'<b>Settings for eth0:</b>',
									'<b><span>Supported ports: [ TP ]</span></b>',
									'<b><span>Supported link modes:   10baseT/Half 10baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half 100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b><span>Supported pause frame use: No</span></b>',
									'<b><span>Supports auto-negotiation: Yes</span></b>',
									'<b><span>Advertised link modes:  10baseT/Half 10baseT/Full </span></b>',
									'<b><span style="text-indent:12em;">100baseT/Half 100baseT/Full</span></b>',
									'<b><span style="text-indent:12em;">1000baseT/Full</span></b>',
									'<b><span>Advertised pause frame use: No</span></b>',
									'<b><span>Advertised auto-negotiation: Yes</span></b>',
									'<b><span>Speed: 1000Mb/s</span></b>',
									'<b><span>Duplex: Full</span></b>',
									'<b><span>Port: Twisted Pair</span></b>',
									'<b><span>PHYAD: 0</span></b>',
									'<b><span>Transceiver: internal</span></b>',
									'<b><span>Auto-negotiation: on</span></b>',
									'<b><span>MDI-X: off (auto)</span></b>',
									'<b><span>Supports Wake-on: d</span></b>',
									'<b><span>Wake-on: d</span></b>',
									'<b><span>Current message level: 0x00000007 (7)</span></b>',
									'<b><span style="text-indent:8em;">drv probe link</span></b>',
									'<b><span>Link detected: yes</span></b>',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'修改主机名',
			text:{
				problem:[
					'修改主机名',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 临时修改主机名，在终端中执行hostname 新主机名，重启会复原',
						detail:[
							{
								code:[
									'~# hostname xxx',
								],
							},
						],
					},
					{
						tittle:'2. 修改主机名文件，可永久生效，如下，以主机名为linx11为例',
						detail:[
							{
								code:[
									'~# echo “linx11”  >  /etc/hostname',
								],
							},
							{
								tittle:[
									'<span>注销并重新登录用户使配置生效</span>',
								],
							},
						],
					},
				],
			},
		},
		{
			tittle:'parted分区以及挂载操作',
			text:{
				problem:[
					'现场情况为：工作站共有两块磁盘，用户在安装系统时没有直接把第二块磁盘分区，此时系统安装完成后想挂载第二块磁盘，需进行手动挂载磁盘配置',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'1. 使用fdisk -l查看磁盘',
						detail:[
							{
								code:[
									'~# fdisk -l',
									'<br>',
									'<b>Disk /dev/sda: 53 GB, 53686402560 bytes</b>',
									'<b>255 heads, 63 sectors/track, 6527 cylinders</b>',
									'<b>Units = cylinders of 16065 * 512 = 8225280 bytes</b>',
									'<br>',
									'<b>   Device Boot      Start         End      Blocks   Id  System</b>',
									'<b>/dev/sda1   *          1         487     3911796   82  Linux swap</b>',
									'<b>Warning: Partition 1 does not end on cylinder boundary.</b>',
									'<b>/dev/sda2             487        6527    48516300    5  Extended</b>',
									'<b>Warning: Partition 2 does not end on cylinder boundary.</b>',
									'<b>/dev/sda5             487        1946    11719417   83  Linux</b>',
									'<b>Warning: Partition 5 does not end on cylinder boundary.</b>',
									'<b>/dev/sda6             1946        4377    19527007   83  Linux</b>',
									'<b>Warning: Partition 6 does not end on cylinder boundary.</b>',
									'<b>/dev/sda7             4377        5593     9767520   83  Linux</b>',
									'<b>Warning: Partition 7 does not end on cylinder boundary.</b>',
									'<b>/dev/sda8             5593        6527     7502355   83  Linux</b>',
									'<b>Warning: Partition 8 does not end on cylinder boundary.</b>',
									'<br>',
									'<b>Error： /dev/sdb:  unrecogised disk label</b>',
								],
							},
							{
								tittle:[
									'<span>可以看到/dev/sdb为第二块盘</span>',
								],
							}
						],
					},
					{
						tittle:'2. 分区',
						detail:[
							{
								tittle:[
									'<span>输入命令parted /dev/sdb</span>',
								],
								code:[
									'~# parted /dev/sdb',
									'<b>GNU Parted 2.3</b>',
									'<b>Using /dev/sdb</b>',
									"<b>Welcome to GNU Parted! Type 'help' to view a list of commands.</b>",
									'<b>(parted)</b>',
								],
							},
							{
								tittle:[
									'<span>输入mklabel gpt,创建gpt分区</span>',
								],
								code:[
									'<b>(parted) mklabel gpt</b>',
								],
							},
							{
								tittle:[
									'<span>创建一个分区，参数为分区起点和终点</span>',
									'<span>mkpart primary 0 100%</span>',
								],
								code:[
									'<b>(parted) mkpart primary 0 100%</b>',
									'<b>Warning: The resulting partition is not properly aligned for best performance.</b>',
									"<b>Ignore/Cancel? ignore</b>",
									'<b>(parted)</b>',
								],
							},
							{
								tittle:[
									'<span>Print命令查看是否成功创建</span>',
								],
								code:[
									'<b>(parted) print</b>',
									'<b>Model: VMware, VMware Virtual S (scsi)</b>',
									"<b>Disk /dev/sdb: 21.5GB</b>",
									'<b>Sector size (logical/physical): 512B/512B</b>',
									'<b>Partition Table: gpt</b>',
									'<br>',
									"<b>Number  Start   End     Size    File system  Name      Flags</b>",
									'<b> 1      17.4kB  21.5GB  21.5GB  ext3         primary</b>'
								],
							},
							{
								tittle:[
									'<span>这时候磁盘分区创建完成，quit退出后在终端进行格式化磁盘</span>',
								],
							},
						],
					},
					{
						tittle:'3. 格式化',
						detail:[
							{
								code:[
									'~# mkfs.ext3 /dev/sdb1',
								],
							},
							{
								tittle:[
									'<span>或</span>',
								],
								code:[
									'~# mkfs.ext4 /dev/sdb1',
								],
							},
							{
								tittle:[
									'<span>根据现场需要自行选择ext3或ext4格式</span>',
								],
							},
						],
					},
					{
						tittle:'4. 创建挂载目录',
						detail:[
							{
								code:[
									'~# mkdir /work',
								],
							},
							{
								tittle:[
									'<span>查看需要添加磁盘的uuid</span>',
									'<span>命令为</span>',
								],
								code:[
									'~# blkid /dev/sdb1',
								],
							},
							{
								tittle:[
									'<span>注：sdb1为现场用户使用第二块磁盘，具体情况根据现场变化而定</span>',
								],
								code:[
									'~# blkid /dev/sdb1',
									'<b>/dev/sdb1: UUID="6f72d92d-27de-4e7b-9737-6d6d744ba70a" SEC_TYPE="ext2" TYPE="ext3"</b>',
								],
							},
						],
					},
					{
						tittle:'5. 将uuid号写入fstab',
						detail:[
							{
								code:[
									' ~# vim /etc/fstab',
									'<br>',
									'# /work was on /dev/sdb1 during installation',
									'<b>UUID=6f72d92d-27de-4e7b-9737-6d6d744ba70a /work           ext3    defaults,acl,data=writeback,acl    0       2</b>',
								],
							},
						],
					},
					{
						tittle:'6. 使用这条命令挂载磁盘',
						detail:[
							{
								code:[
									'mount -a',
								],
							},
							{
								tittle:[
									'<span>如果没有报错查看挂载sdb1是否完成</span>',
								],
								code:[
									'df   -h',
								],
							},
							{
								tittle:[
									'<span>正常情况能显示挂载的情况</span>',
									'<span>重启测试是否能自动挂载，重启后在执行df  -h这条命令查看挂载是否正常</span>',
								],
							},
						],
					},
				],
			},
		},
		/*模板
		{
			tittle:'',
			text:{
				problem:[
					'',
				],
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				resolvent:[
					{
						tittle:'',
						detail:[
							{
								tittle:[
									'<span></span>',
								],
								code:[
									'',
									'',
									'',
									'',
								],
								img:[
									require('../../images/question/'),
								],
							},
						],
					},
				],
			},
		},
		*/
		/*模板
		{
			tittle:'',//大标题
			text:{
				// 问题描述
				problem:[
					'',
				],
				// 适用范围
				scope:[
					'linx-6.0.60',
					'linx-6.0.80',
					'linx-6.0.100'
				],
				// 解决方案
				resolvent:[
					{
						tittle:// 步骤
						'',
						'<b>6.0.60搭建nfs</b>',
						//加粗标题
						'<span>服务端</span>',
						//加点标题
						detail:[
							{
								tittle:[
									''，
									'<span></span>',
									//加点
									'<span style="text-indent:1em;"></span>',
									//带缩进带点
									'<span style="text-indent:-1em;"></span>',
									//解决方案下有序列表前内容 带点
									'<b style="text-indent:1em;"></b>',
									//带缩进
									'<span><b>加粗内容</b></span>',
									//加粗
								],
								code:[
									'{内容}',
									//输入代码
									'{内容}<span>{注释}</span>',
									//带注释输入代码
									'<b>{内容}</b>',
									// 绿色
									'<br>',
									// 换行
									'<b>{内容}</b><span>{内容}</span>',
									//绿色块带注释
									'<b><span>{内容}</span></b><span>{内容}</span>',
									//绿色块带注释带缩进
									'<b><span style="text-indent:Nem;">{内容}</span></b>',
									// 绿色块ifconfig下
									'<b><span style="text-indent:Nem;">{内容}</span><span>{内容}</span></b>',
									// 绿色块ifconfig下带标题
									
									//注：N智能取值：4,12
								],
								img:[
									require('../../images/question/'),
								],
							},
						]
					},
				],
			},
		},
		*/
	]

};
const mutations = {};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
