<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思云桌面概述</h2>
					<p>云桌面（Cloud Desktop）技术又称为虚拟桌面技术，它能够为用户提供远程的计算机桌面服务。用户使用浏览器或瘦终端经网络交换机访问 engine 服务器（管理引擎），engine 服务器通过网络交换机访问节点集群，集群中每台节点均通过光纤交换机连接到存储，来访问存放在存储中的虚拟机磁盘（如图示一）。</p>
					<figure>
						<img v-show="bodyWidthMut > 750" src="./images/云桌面.png"/>
						<img v-show="bodyWidthMut <= 750" src="./images/云桌面-1080.png"/>
						<p>图示一</p>
					</figure>
				</div>
				<div class="textBox textBoxBackground">
					<h2>凝思云桌面产品特性</h2>
					<div class="featureBox">
						<div class="featureText5">
							<div>
								<div class="text">
									<h4>搭建、拓展、维护简单便捷</h4>
									<p>通过资源池化技术打破单机资源隔离限制，对计算资源、存储资源进行动态、迅速的扩容/分配，有效避免传统网络中PC机数量大、软硬件升级繁琐、部署分散等问题。</p>
								</div>
								<div class="text">
									<h4>安全保障强</h4>
									<p>多因子登录，提供多种手段登录到云桌面，支持人脸认证登录、指纹识别登录、用户名密码登录，安全可追溯。</p>
								</div>
							</div>
							<div>
								<div class="text">
									<h4>国产化支持</h4>
									<p>从底层操作系统到虚拟化软件再到云终端系统，均由凝思自主研发，实现了全系统国产化，同时提供后续安全升级与补丁更新，保障了客户业务的安全、稳定。</p>
								</div>
								<div class="text">
									<h4>替代堡垒机方案</h4>
									<p>由虚拟化平台内部进行相关通信的权限分配、访问控制和访问记录，并直接将核心服务器访问权限整合到用户账户中。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textBox textBigTop">
					<h2>云桌面功能特性</h2>
					<div class="textDetails">
						<p class="figure figure1"><b>直观的配置与管理</b>凝思云桌面管理平台提供了基于 Web 页面的可视化资源管控平台（如图示二），对主机与虚拟机的 CPU、内存、网络等重要资源进行全面、实时的监测。</p>
						<figure>
							<img src="./images/云桌面2.png"/>
							<p>图示二</p>
						</figure>
						<p class="figure figure2"><b>虚拟设备访问控制</b>可动态配置是否允许复制粘贴、单向复制、双向复制等，对存储外设进行管控，云桌面引擎端提供网络访问控制黑名单机制，提供安全保障的同时也兼顾使用的便捷需求。</p>
						<p class="figure figure3"><b>虚拟工作站终端回显</b>云桌面用户进行非法操作时，可通过云桌面提供的终端回显功能回溯整个操作过程找到问题所在。</p>
						<p class="figure figure4"><b>虚拟机高可用技术</b>用户误操作导致系统损坏引发虚拟机无法启动时，凝思虚拟化平台仅需重新克隆并更换系统介质（如图示三），即可实现整个虚拟机的修复，保障了用户数据不丢失。</p>
						<figure>
							<img src="./images/云桌面3.png"/>
							<p>图示三</p>
						</figure>
						<p class="figure figure5"><b>存储冷热分离技术</b>凝思虚拟化实现的基于 Windows 和 Linux 操作系统级优化的虚拟机磁盘冷热分离技术（如图示四），以低廉成本提供较高性能。</p>
						<figure>
							<img src="./images/云桌面4.png"/>
							<p>图示四</p>
						</figure>
						<p class="figure figure6"><b>物理主机性能状态监测</b>提供物理主机的 CPU、内存、网络资源的实时性和全面性的性能状态监测，可获得该主机最新的操作更新记录，方便运维人员进行操作检查、故障排查等。</p>
						<p class="figure figure7"><b>虚拟机性能状态监测</b>提供虚拟机 CPU、内存、网络资源的实时性和全面性的性能状态监测，可获得虚拟机名字、注释，并且可通过编辑修改该值。</p>
						<p class="figure figure8"><b>spice 密钥方案</b>通过 spice 密钥方案，客户仅能通过凝思的瘦终端登录该虚拟机，可快速获取到该虚拟机已创建的快照链，并且可以随时回退、删除快照，极大的保障了用户的数据安全。</p>
						<p class="figure figure9"><b>广泛的兼容性</b>支持市场上各主流厂商的服务器，兼容市场上主流网卡、HBA 卡等配件产品， 兼容市场上主流的存储阵列产品，支持凝思安全操作系统、Linux、Windows等主流操作系统。</p>
					</div>
				</div>
				<div class="textBox textBoxBackground">
					<h2>云桌面的配置</h2>
					<div class="featureBox">
						<div class="featureText4">
							<div class="text">
								<h4>单机版配置</h4>
								<p>单机配置指使用计算资源节点本地存储，而管理引擎部署在某台计算资源节点，简单快捷，适合低成本建设场景。</p>
							</div>
							<div class="text">
								<h4>集群版配置</h4>
								<p>管理节点双机热备、计算节点集群部署，配备独立存储，通过凝思系统双机热备软件和凝思多路径软件以及动态资源调度、虚拟机在线迁移等机制，提高系统可用性、冗余容错能力和运行性能，适合高可用、高可靠的场景。</p>
							</div>
						</div>
					</div>
					<h2 class="marginshort">应用场景</h2>
					<div class="imgsDisplay2">
						<div>
							<img src="./images/云桌面-调度.png"/>
							<p>调度管理</p>
						</div>
						<div>
							<img src="./images/云桌面-培训.png"/>
							<p>业务培训</p>
						</div>
						<div>
							<img src="./images/云桌面-行政.png"/>
							<p>行政办公</p>
						</div>
						<div>
							<img src="./images/云桌面-服务.png"/>
							<p>服务中心</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'CloudDesktopSystem',
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
		}
	},
	mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
}
</script>
<style scoped>

</style>
