<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg" id="tittlrImgGovernment1">
			<b>政府机关</b>
		</h1>
		<div class="column">
			<div>
				<h2>国家安全部项目</h2>
				<p>国家安全部及各省厅为保障CA系统的安全稳定使用基于<span>凝思安全操作系统的凝思安全服务器</span>。截止目前，国家安全部及各省厅CA系统均由<span>凝思安全服务器为其提供服务</span>。为提升国家安全部凝思安全服务器的可靠性，凝思为其提供了凝思双机热备软件，目前已在<span>各省厅上线运行</span>，凝思安全服务器和双机热备软件为CA系统的安全、稳定、可靠提供了有力的保障。</p>
			</div>
			<div>
				<h2>中办项目</h2>
				<p>凝思安全域名解析服务平台部署在中办、中国信息安全测评中心等单位，该系统可按中文地址习惯进行域名解析。2017年中办及各个部委开始了新一轮电子政务内网项目建设。自2017年5月至今，凝思为气象局、北京铁路局、扶贫办、国侨办、广电总局、药监局、海洋局、农业部、保监会、中央党史办公室、法制办、共青团中央、科技部、国家档案局、政协、银保监、财政部、林业局、网信办、光明日报等20个部委提供<span>凝思安全服务器及DNS系统。</span></p>
			</div>
		</div>
		<div class="column columngray">
			<div class="divBorderbottom">
				<h2>D单位项目</h2>
				<p>D单位担负着重要职责，对系统的性能、安全性有极高的要求。面对高流量、大并发应用场景，凝思充分发挥操作系统层面的研发能力，对设备驱动和网络协议栈进行深度优化，极大提高了网络流量处理能力。</p>
				<p>针对D单位高性价比、高能效比、节省空间的需求，凝思深入研发，解决各种问题，及时适配了PowerPC平台。设备单机性能提高了4倍以上，能耗降低，空间仅占原来的四分之一，为D单位提供了良好的产品和服务。</p>
				<p>根据D单位的业务特点，凝思安全操作系统在其全国分支机构，以及移动、联通、电信三大运营商的前端，部署达到数千套，有力支撑了该单位的业务应用系统。</p>
			</div>
			<div>
				<h2 class="novisical">D单位项目</h2>
				<h3>沙箱集群系统 </h3>
				<p>基于操作系统虚拟化技术，将单台机器运行独立业务系统的个数从20提升至500。</p>
				<h3>零拷贝系统</h3>
				<p>使系统的抓包能力从每秒捕捉24万个包提升至每秒捕捉240万个包。</p>
				<h3>某连接系统</h3>
				<p>通过系统定制，使系统具备百万级的连入和连出能力。</p>
				<h3>专用设备定制</h3>
				<p>基于Power的装用系统定制，性能是通用X86平台的5-10倍。</p>
				<h3>某代理系统</h3>
				<p>定制开发支持IPV6/IPV4双线的透明代理操作系统。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'Offices',
	}
</script>
<style scoped>

</style>
