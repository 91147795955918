<!-- 顶部导航栏 -->
<template>
    <div
        :class="[navInHeader == '/Home' && navShow == 0 ? 'box1_2' : 'box1_1',{box2:navHide}]"
    >
        <div id="navigation">
            <router-link to="/Home"><img id="logotop" src="./images/logo.png" alt="LOGO"></router-link>
            <div id="lattice" v-show="navListShow">
            	<div class="menut">
            		<router-link to="/Home">{{navList[0].name}}</router-link>
                    <p
                    	v-for="(item,index) in navList"
            			:key="index + '-2'"
                        v-show="index > 0"
                        @click="navInHeaderIndex=index,navProindex=-1,navOpindex=-1"
                        :class="{pinT:navInHeaderIndex==index}"
                    >{{item.name}}</p>
            	</div>
            	<div class="menud">
            		<div
            			v-for="(item,index) in navList"
            			:key="index + '-3'"
                        v-show="index > 1 && navInHeaderIndex==index"
                    >
                        <router-link
                        	v-for ="(nav,index2) in item.subItems"
                        	:key="index2"
                        	class="level_2"
                        	:to="nav.link"
                        >{{nav.text}}</router-link>
                    </div>
                    <div
                        v-show="navInHeaderIndex==1"
                    >
                        <div>
                        	<h1
                        		@click="changenavProindex(0)"
                        		:class="{turn:navProindex==0}"
                        	>操作系统</h1>
                        	<div v-show="navProindex==0">
                        		<h2
                        			@click="changenavOpindex(0)"
                        			:class="{turn:navOpindex==0}"
                        			v-show="0"
                        		>服务器版</h2>
                        		<div v-show="navOpindex==0">
                        			<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':0}}">凝思安全操作系统V6.0.42</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':1}}">凝思安全操作系统V6.0.60</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':2}}">凝思安全操作系统V6.0.80</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':5}}">凝思安全操作系统龙蜥版V6.0.98</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':6}}">凝思安全操作系统欧拉版V6.0.99</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':3}}">凝思安全操作系统V6.0.100</router-link>
				                	<router-link :to="{path:'/Products/OperatingSystem',query:{'producttitle':'服务器版','ptitle':4}}">凝思工业互联网安全操作系统</router-link>
                        		</div>
                        	</div>
                        </div>
                        <div>
                        	<h1
                        		@click="changenavProindex(1)"
                        		:class="{turn:navProindex==1}"
                        	>系统功能增强</h1>
                        	<div v-show="navProindex==1">
                        		<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思多路径软件'}}">凝思多路径软件</router-link>
		                    	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思双机热备软件'}}">凝思双机热备软件</router-link>
		                    	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思资源控制软件'}}">凝思资源控制软件</router-link>
		                    	<router-link :to="{path:'/Products/SystemEnhancements',query:{'producttitle':'凝思触摸屏软件'}}">凝思触摸屏软件</router-link>
                        	</div>
                        </div>
                        <div>
                        	<h1
                        		@click="changenavProindex(2)"
                        		:class="{turn:navProindex==2}"
                        	>平台软件</h1>
                        	<div v-show="navProindex==2">
                        		<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思云桌面系统'}}">凝思云桌面系统</router-link>
			                	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思云计算平台软件'}}">凝思云计算平台软件</router-link>
			                	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思大数据平台软件'}}">凝思大数据平台软件</router-link>
			                	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思分布式文件系统'}}">凝思分布式文件系统</router-link>
			                	<router-link :to="{path:'/Products/PlatformSoftware',query:{'producttitle':'凝思虚拟化管理平台'}}">凝思虚拟化管理平台</router-link>
                        	</div>
                        </div>
                        <div>
                        	<h1
                        		@click="changenavProindex(3)"
                        		:class="{turn:navProindex==3}"
                        	>定制开发</h1>
                        	<div v-show="navProindex==3">
                        		<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'平台产品定制开发'}}">平台产品定制开发</router-link>
		                    	<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'跨平台移植开发'}}">跨平台移植开发</router-link>
		                    	<router-link :to="{path:'/Products/CustomDevelopment',query:{'producttitle':'系统功能软件开发'}}">系统功能软件开发</router-link>
                        	</div>
                        </div>
                        <router-link
                    		class="cssp"
                    		:to="{path:'/Products/SecureServerPlatform',query:{'producttitle':'凝思安全服务器平台'}}"
                    	>凝思安全服务器平台</router-link>
                    	<router-link
                    		class="csst"
                    		to="/Products/ProductsCatalog"
                    	>查看所有产品</router-link>
                    </div>
            	</div>
            </div>
        </div>
        <div
            :class="navInHeader1 == 0&&navShow == 0 ? navListShow == 0 ? 'navll1':'navll2' : navListShow == 0 ? 'navll3':'navll4'"
            @click="changeNavListShow(navListShow)"
        ></div>
        <div id="backg" @click="navListShow = 0" v-show="navListShow == 1"></div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
    name: 'header2',
    data(){
        return{
        	bodyWidthMut: document.body.clientWidth,
        	navInHeader:this.$route.path,
        	navShow:0,
            navHide:0,
            navListShow:0,
            navInHeader1:0,
            navInHeaderIndex:1,
            navProindex:-1,
            navOpindex:-1,
        }
    },
    computed:{
        ...mapState({
            navList: (state)=>state.Navigation.navList,
        })
    },
    methods:{
        changeNavListShow(navListShow){
            if(navListShow == 0){
                this.navListShow = 1;
            } else {
                this.navListShow = 0;
            }
            this.navProindex = -1;
            this.navOpindex = -1;
            this.navInHeader = this.$route.path;
            if(this.navInHeader.indexOf('/Products') != -1){
                this.navInHeaderIndex = 1;
            } else if(this.navInHeader.indexOf('/Solutions') != -1){
                this.navInHeaderIndex = 2;
            } else if(this.navInHeader.indexOf('/EcologicalCooperation') != -1){
                this.navInHeaderIndex =  3;
            } else if(this.navInHeader.indexOf('/ServiceAndSupport') != -1){
                this.navInHeaderIndex = 4;
            } else if(this.navInHeader.indexOf('/AboutLinx') != -1){
                this.navInHeaderIndex = 5;
            } else{
                this.navInHeaderIndex = 1;
            }
        },
        changenavProindex(val){
        	this.navOpindex = 0;
        	if(this.navProindex == val){
        		this.navProindex = -1;
        	} else {
        		this.navProindex = val;
        	}
        },
        changenavOpindex(val){
        	if(this.navOpindex == val){
        		this.navOpindex = -1;
        	} else {
        		this.navOpindex = val;
        	}
        }
    },
    mounted() {
    	window.addEventListener('scroll',()=>{this.navShow=document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset}, true);
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
    watch:{
    	$route:{
            immediate:true,
            handler(val,oldVal){
            	this.navListShow = 0;
                this.navInHeaderIndex = 1;
                this.navProindex = -1;
            	this.navOpindex = -1;
                this.navInHeader = this.$route.path;
                if(this.navInHeader == '/Home'){
                    this.navInHeader1 = 0;
                } else if(this.navInHeader.indexOf('/Products') != -1){
                    this.navInHeader1 = 1;
                } else if(this.navInHeader.indexOf('/Solutions') != -1){
                    this.navInHeader1 = 2;
                } else if(this.navInHeader.indexOf('/EcologicalCooperation') != -1){
                    this.navInHeader1 = 3;
                } else if(this.navInHeader.indexOf('/ServiceAndSupport') != -1){
                    this.navInHeader1 = 4;
                } else if(this.navInHeader.indexOf('/AboutLinx') != -1){
                    this.navInHeader1 = 5;
                } else{
                    this.navInHeader1 = -1;
                }
                
            },
            deep: true
        },
        navShow(newValue,oldValue){
            if(newValue != 0){
                if(newValue < oldValue){
                    this.navHide = 0;
                } else if((newValue - oldValue) > 64){
                    this.navHide = 1;
                    this.navListShow = 0;
                    this.navInHeaderIndex = 1;
                	this.navProindex = -1;
            		this.navOpindex = -1;
                }
            } else {
                this.navHide = 0;
            }
        },
        bodyWidthMut:{
		    immediate:true,
		    handler(){
		    	this.navInHeaderIndex = 1;
		    	this.navListShow = 0;
			}
		},
	}
}
</script>
<style scoped>
#backg{
    width:100vw;
    height:100vh;
}
/* 导航栏样式 */
.box1_1{
    width: 100%;
    height: 80px;
    background-color: rgba(255,255,255,1);
    position: fixed;         
    text-align: center; 
    z-index: 9999;
    box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
    transition: background-color .3s;
    top:0;
    transition: top .3s;
}
/*首页导航栏-透明*/
.box1_2{
    width: 100%;
    height: 80px;
    background-color: rgba(255,255,255,0);
    position: fixed;         
    text-align: center; 
    z-index: 9999;
    transition: background-color .3s;
}
.box2{
    top:-100px;
    transition: top .3s;
}
/*汉堡按钮*/
.navll1{
    display:block;
    position: absolute;
    width:80px;
    height:80px;
    top:0;
    right:0;
    background: url(./images/导航栏-汉堡.png) no-repeat center center;
    background-size: 22px 18px;
    cursor:pointer;
}
.navll2{
    display:block;
    position: absolute;
    width:80px;
    height:80px;
    top:0;
    right:0;
    background: url(./images/导航栏-关闭.png) no-repeat center center;
    background-size: 18px 18px;
    cursor:pointer;
}
.navll3{
    display:block;
    position: absolute;
    width:80px;
    height:80px;
    top:0;
    right:0;
    background: url(./images/导航栏-汉堡2.png) no-repeat center center;
    background-size: 22px 18px;
    cursor:pointer;
}
.navll4{
    display:block;
    position: absolute;
    width:80px;
    height:80px;
    top:0;
    right:0;
    background: url(./images/导航栏-关闭2.png) no-repeat center center;
    background-size: 18px 18px;
    cursor:pointer;
}
#navigation{
    position: relative;
    width: 100%;
    height: 80px;
}
#logotop{
    margin-left:24px;
    position: absolute;
    transform: translateY(-50%);
    left:0px;
    top:50%;
    width: 102px;
    height: auto;
}
.navle1{
    display:block;
    position: absolute;
    width:80px;
    height:74px;
    top:0;
    right:0;
    transform:rotate(0deg);
    background: url(./images/导航栏-下拉.png) no-repeat center center;
    background-size: 12px 8px;
    transition:transform 0.15s;
}
.navle2{
    display:block;
    position: absolute;
    width:80px;
    height:74px;
    top:0;
    right:0;
    transform:rotate(-90deg);
    background: url(./images/导航栏-下拉.png) no-repeat center center;
    background-size: 12px 8px;
    transition:transform 0.15s;
}

#lattice{
    margin-right:0px;
    position: relative;
    top:80px;
    width: 100%;
    background-color:#fff;
    border-top:1px solid #e0e0e0;
    box-shadow: 0px 0px 25px rgba(5,5,5,0.08);
    display:grid;
    grid-template-columns: 208px auto;
}
#lattice .menut{
    position: relative;
    background-color:#f5f6f7;
    padding:24px 0;
    width:208px;
}
#lattice .menut a,#lattice .menut p{
    position: relative;
    display:block;
    width:208px;
    font-size: 16px;
    font-weight: 500;
    color: #262b33;
    line-height: 46px;
    text-align:left;
    text-indent:24px;
    cursor:pointer;
}
#lattice .menut .pinT{
    background-color:#fff;
}
#lattice .menut .pinT:before{
    display:block;
    position: absolute;
    content:'';
    width:3px;
    height:18px;
    top:14px;
    left:0;
    background-color:#dd1d29;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
}
#lattice .menud{
    position: relative;
    margin:0;
    padding:24px 0;
    background: url(./images/下拉菜单点阵-pc.png) no-repeat bottom right;
}
#lattice .menud > div {
    margin:0 32px;
}
#lattice .menud > div > div{
    border-bottom:1px solid #e0e0e0;
}
#lattice .menud h1,#lattice .menud .cssp{
    position: relative;
    display:block;
    width:100%;
    font-size: 16px;
    font-weight: 400;
    color: #262b33;
    line-height: 46px;
    text-align:left;
    text-indent:0;
}
#lattice .menud h2{
    position: relative;
    display:block;
    width:100%;
    font-size: 16px;
    font-weight: 400;
    color: #262b33;
    line-height: 38px;
    text-align:left;
    text-indent:1em;
}
#lattice .menud h1:after,#lattice .menud h2:after{
    display:block;
    position: absolute;
    content:'';
    width:46px;
    height:46px;
    background: url(./images/箭头-菜单-灰色-pc.png) no-repeat center;
    top:0;
    right:-20px;
    transform:rotate(0deg);
    transition:transform 0.15s;
}
#lattice .menud .turn{
    font-weight: 500;
}
#lattice .menud .turn:after{
    transform:rotate(90deg);
}
#lattice .menud a{
    display:block;
    width:100%;
    font-size: 16px;
    font-weight: 400;
    color: #565d69;
    line-height: 46px;
    text-align:left;
    text-indent:0em;
}
#lattice .menud a:hover{
    color: #dd1d29;
}
#lattice .menud .csst{
    position: relative;
    display:block;
    width:100%;
    font-size: 16px;
    font-weight: 500;
    color: #262b33;
    line-height: 46px;
    text-align:left;
    text-indent:0;
}
#lattice .menud h1+div a{
    text-indent:1em;
    line-height: 38px;
}
#lattice .menud h2+div a{
    text-indent:1em;
    line-height: 38px;
}
/*手机端*/
@media only screen and (max-width:750px){
/* 导航栏样式 */
	.box1_1,.box1_2,#navigation{
        height: 64px;
    }
    .box2{
        top:-74px;
        transition: top .3s;
    }
    /*汉堡按钮*/
    .navll1{
        display:block;
        position: absolute;
        width:46px;
        height:64px;
        top:0;
        right:0;
        background: url(./images/导航栏-汉堡.png) no-repeat center center;
        background-size: 16px 14px;
    }
    .navll2{
        display:block;
        position: absolute;
        width:46px;
        height:64px;
        top:0;
        right:0;
        background: url(./images/导航栏-关闭.png) no-repeat center center;
        background-size: 14px 14px;
    }
    .navll3{
        display:block;
        position: absolute;
        width:46px;
        height:68px;
        top:0;
        right:0;
        background: url(./images/导航栏-汉堡2.png) no-repeat center center;
        background-size: 16px 14px;
    }
    .navll4{
        display:block;
        position: absolute;
        width:46px;
        height:68px;
        top:0;
        right:0;
        background: url(./images/导航栏-关闭2.png) no-repeat center center;
        background-size: 14px 14px;
	}
	#logotop{
        margin-left:12px;
        width: 86px;
        height: 34px;
    }

	#lattice{
		top:64px;
		border-top:0.5px solid #e0e0e0;
		box-shadow: 0px 0px 12px rgba(5,5,5,0.08);
		display:grid;
		grid-template-columns: 105px auto;
	}
	#lattice .menut{
		padding:10px 0;
		width:105px;
	}
	#lattice .menut a,#lattice .menut p{
		width:105px;
		font-size: 15px;
		font-weight: 500;
		line-height: 46px;
		text-indent:15px;
	}
	#lattice .menut .pinT{
		font-weight: 600;
	}
	#lattice .menut .pinT:before{
		width:3px;
		height:18px;
		top:14px;
		left:0;
		border-top-right-radius:2px;
		border-bottom-right-radius:2px;
	}
	#lattice .menud{
		padding:10px 0;
		background: url(./images/下拉菜单点阵-pc.png) no-repeat bottom right;
	}
	#lattice .menud > div {
		margin:0 20px;
	}
	#lattice .menud > div > div{
		border-bottom:0.5px solid #e0e0e0;
	}
	#lattice .menud h1,#lattice .menud .cssp{
		font-size: 15px;
		line-height: 46px;
	}
	#lattice .menud h2{
		font-size: 15px;
		line-height: 40px;
		text-indent:0.5em;
	}
	#lattice .menud a{
		font-size: 15px;
		line-height: 46px;
	}
	#lattice .menud .csst{
		font-size: 15px;
		line-height: 46px;
	}
	#lattice .menud h1+div a{
		line-height: 46px;
		text-indent:0.5em;
	}
	#lattice .menud h2+div a{
		line-height: 46px;
		text-indent:1em;
	}
}
</style>
