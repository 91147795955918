<template>
    <div class="buboxfooter">
    	<!-- <img src="./images/logo.png" alt="LOGO"> -->
        <p>©{{time}} 北京凝思软件股份有限公司</p>
        <p> 
            <router-link to="/PrivacyPolicy">隐私政策</router-link>
            <span>|</span>
            <router-link to="/LegalNotices">法律声明</router-link>
            <span>|</span>
            <a target="_parent" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021006165号-1</a>
            <span>|</span>
            <a target="_parent" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024724">京公网安备11010802024724号</a>
        </p>  
        <router-link to="/Home">www.linx-info.com</router-link>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'foot3',
    data() {
		return {
			time: "",
		};
	},
	mounted() {
      this.nowtime()
    },
    methods:{
    	nowtime() {
			let nowDate = new Date();
			let date = {
				// 获取当前年份
				year: nowDate.getFullYear(),
			};
			//拼接
			this.time = date.year;
		},
    },
}
</script>

<style scoped>
/*pad竖屏*/
@media only screen and (max-width:1024px){
	.buboxfooter{
		width: 672px;
		padding:16px calc(50vw - 336px);
		height: auto;
		margin: 0 auto;
		position: relative;
		background-color: #14161c;
	}
	.buboxfooter img{
		display:block;
		position:relative;
		width: 108px;
		height:42px;
		left:0;
		top:0;
		padding:20px 0 12px;
	}
	.buboxfooter p,.buboxfooter > a{
		position: relative;
		display:block;
		left: 0px;
		top:0px;
		color: #a2a2a3;
		line-height: 32px;
		font-size: 16px;
		text-align: left;
	}
	.buboxfooter p span{
		position: relative;
		font-size: 16px;
		color: #a2a2a3;
	}
	.buboxfooter p span{
		padding:0 5px;
	}
	.buboxfooter p span:nth-child(1){
		font-size: 16px;
		padding:0 0;
		display:inline-block;
	}
	.buboxfooter p span:nth-child(5):after{
		content: ' ';
		width: 100%;
		height:0px;
		padding:0 0;
		display:block;
	}
	.buboxfooter p a{
		position: relative;
		font-size: 16px;
		color: #a2a2a3;
	}
	.buboxfooter p a:hover{
		color: #a2a2a3;
	}
}
</style>
