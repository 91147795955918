<template>
	<div>
		<div class="topLattice"></div>
		<TopOfProducts :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainMargin" id="fontmainPCata" ref="fontCatalog">
			<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
			<div class="productIntroduce">
				<transition name="fade">
					<div v-show="bodyWidthMut > 750 || numProductIntroduce == 1">
						<h1>丰富的设备驱动程序</h1>
						<p>高效地完成硬件资源调度</p>
					</div>
				</transition>
				<transition name="fade">
					<div v-show="bodyWidthMut > 750 || numProductIntroduce == 2">
						<h1>丰富的接口函数库和实用工具</h1>
						<p>良好的软件兼容性</p>
					</div>
				</transition>
				<transition name="fade">
					<div v-show="bodyWidthMut > 750 || numProductIntroduce == 3">
						<h1>支持实时、非实时大型业务系统</h1>
						<p>安全、稳定、可靠</p>
					</div>
				</transition>
			</div>
			<ScrollCatalog :hv="hv"></ScrollCatalog>
			<div class="font" id="fontCatalog">
				<div
					class="CatalogDetails"
					v-for ="(products,productsindex) in productsList"
					:key="productsindex"
				>
					<div class="location" :id="products.id" :ref="products.id"></div>
					<h2>{{products.tittle}}</h2>
					<Catalogshrink :introduction="products.introduction" v-show="products.introduction"></Catalogshrink>
					<div class="CatalogshrinkShort" v-show="products.introductionShort">
						<p v-for ="(introductionShort,introductionShortindex) in products.introductionShort" :key="introductionShortindex">{{introductionShort}}</p>
					</div>
					<section class="productDisplay">
						<router-link
							class="productDisplayDetail"
							v-for ="(productDisplay,productDisplayindex) in products.productDisplay"
							:key="productDisplayindex"
							:to="{path:products.link,query:{producttitle:productDisplay.title}}"
						>
							<h3>{{productDisplay.title}}</h3>
							<p
								v-for ="(productDisplayText,productDisplayTextindex) in productDisplay.text"
								:key="productDisplayTextindex"
								v-html="productDisplayText"></p>
							<span>了解详情<img src="../../../images/箭头-蓝色-向右.png"></span>
							<div></div>
						</router-link>
					</section>
				</div>
			</div>
		</div>
		<div class="bottomLattice"></div>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

import ScrollCatalog from './ScrollCatalog';
import Catalogshrink from './Catalogshrink.vue';

export default {
	name:'ProductsCatalog',
	components: {
	    ScrollCatalog,
		Catalogshrink
	},
	data() {
		return {
			natittle1: false,
			natittle2: false,
			natittle3: this.$attrs.tittle,
			opc:[0,0,0,0,0,0],
			hv:[0,0,0,0,0,0],
			bodyWidthMut: document.body.clientWidth,
			numProductIntroduce: 1,
			brief:[
				'与世界技术发展同步',
				'打造安全自主可控的基础软件平台',
			],
		}
	},
	computed:{
        ...mapState({
            productsList: (state)=>state.Products.productsList,
        })
    },
	created(){
		this.$nextTick(() => {this.toLocal()})
	},
	mounted(){
		let _this=this;
		_this.$nextTick(function(){
			window.addEventListener('scroll',_this.handleScroll);
		})
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
		setInterval(() => {
			if(this.numProductIntroduce == 1){
				this.numProductIntroduce = 2
			}else if(this.numProductIntroduce == 2){
				this.numProductIntroduce = 3
			}else if(this.numProductIntroduce == 3){
				this.numProductIntroduce = 1
			}
		}, 5000)
		
		var scrollT = window.pagesYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    	this.hv[0] = document.getElementById('ProductDescription').getBoundingClientRect().top + scrollT;
		this.hv[1] = document.getElementById('OperatingSystem').getBoundingClientRect().top + scrollT;
		this.hv[2] = document.getElementById('SystemEnhancements').getBoundingClientRect().top + scrollT;
		this.hv[3] = document.getElementById('PlatformSoftware').getBoundingClientRect().top + scrollT;
		this.hv[4] = document.getElementById('SecureServerPlatform').getBoundingClientRect().top + scrollT;
		this.hv[5] = document.getElementById('CustomDevelopment').getBoundingClientRect().top + scrollT;

		var hash = window.location.hash;
		var index = hash.lastIndexOf("#");
		if (index != -1) {
		var id = hash.substring(index + 1, hash.length + 1);
		var div = document.getElementById(id);
		if (div) {
			setTimeout(function () {
				// console.log($(div).offset().top);
				$('html, body').animate({scrollTop: $(div).offset().top - 43}, 500)
				}, 0);
			}
		}
    },
	methods:{
        changeopt(index){
			if(this.opc[index]){
				this.opc[index] = 0;
			} else {
				this.opc[index] = 1;
			}
			alert(this.opc[index])
        },
        toLocal(){
        	let Id = localStorage.getItem('toId');
        	let toElement = document.getElementById(Id);
        	if(Id){
        		toElement.scrollIntoView();
        		localStorage.setItem('toId','');
        	}
        }
    },
}
</script>
<style scoped>
:root {
  --scrollbar: 0px;
}
.fontmainMargin{
	margin-top: 50px;
}
#fontmainPCata{
	padding-bottom:130px;
}
.topLattice{
	position: absolute;
	width: 890px;
	height:700px;
	right:calc( 50% - 50vw);
	top:478px;
	background:url('./images/产品列表_上点阵.png') no-repeat top right;
	z-index:-1;
}
.bottomLattice{
	position: absolute;
	width: 890px;
	height:100%;
	left:calc( 50% - 50vw);
	bottom:-56px;
	background:url('./images/产品列表_下点阵.png') no-repeat bottom left;
	z-index:-1;
}
/*页顶简介*/
.fontmain .productIntroduce{
	position: relative;
	margin-bottom:42px;
	top:-50px;
	display: grid;
	width:100%;
	grid-gap: auto;
	grid-template-columns: repeat(3,33%);
	justify-content:space-between;
}
.fontmain .productIntroduce div{
	height:53px;
	margin:19px 0;
	text-align:center;
	padding-top:3px;
}
.fontmain .productIntroduce div:nth-child(2){
	border-left:1px solid #d6d6d6;
	border-right:1px solid #d6d6d6;
}
.fontmain .productIntroduce div h1{
	line-height: 26px;
	font-size:16px;
	color:#565d69;
	font-weight:500;
}
.fontmain .productIntroduce div p{
	line-height: 24px;
	font-size:14px;
	color:#585f6b;
	font-weight:300;
}
#fontCatalog{
	background-color:rgba(0,0,0,0);
	width: 890px;
	border: none;
	margin: 0;
}
#fontCatalog h2{
	margin :0;
	line-height: 44px;
	color:#262b33;
	font-size:24px;
	font-weight:600;
}
/*概述页*/
.CatalogDetails{
	position: relative;
	margin-top: 94px;
}
.CatalogDetails:first-child{
	margin-top: 0px;
	padding-bottom:52px;
	border-bottom:1px solid #e3e4e5;
}
.CatalogDetails:nth-child(2){
	margin-top: 42px;
}
.CatalogDetails div:first-child{
	position: absolute;
	width:890px;
	height:1px;
	left:0;
	top:-120px;
	z-index:-1;
}
/*短简介文本*/
.CatalogDetails .CatalogshrinkShort{
	position: relative;
	width:calc(100% - 2px);
	background-color:#f5f6f7;
	border:1px solid #edeff0;
	margin-top:10px;
	overflow:hidden;
	padding:28px 0;
}
.CatalogDetails .CatalogshrinkShort p{
	margin:0 auto;
	width:816px;
	line-height:24px;
	color:#262b33;
	font-weight:300;
}
/*产品展示*/
.CatalogDetails .productDisplay{
	display: grid;
	width:100%;
	grid-gap: 28px 28px;
	grid-template-columns: repeat(auto-fill,278px);
	margin-top:28px; 
}
.CatalogDetails h2+.productDisplay{
	margin-top:26px; 
}
.CatalogDetails .productDisplayDetail{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	position: relative;
	background: #fff;
	text-decoration: none;
	padding-bottom: 11px;
	border:1px solid #e3e4e5;
	box-shadow: 0px 20px 40px -20px rgba(5,5,5,0);
	transition: all .15s;
}
.CatalogDetails .productDisplayDetail:hover{
	border:1px solid #e8e9eb;
	box-shadow: 0px 20px 40px -20px rgba(5,5,5,0.08);
}
.CatalogDetails .productDisplayDetail:before{
	position: absolute;
	display:block;
	content:'';
	height:98px;
	width:100px;
	background:url('./images/灰色点阵.png') no-repeat;
	top: -8px;
	left:12px;
	z-index: -1;
	transition: all .15s;
	opacity:0;
}
.CatalogDetails .productDisplayDetail:hover:before{
	opacity:1;
}
.CatalogDetails .productDisplayDetail div{
	position: absolute;
	height:99px;
	width:100px;
	background:url('./images/红色点阵.png') no-repeat;
	bottom: -15px;
	right: -14px;
	z-index: -1;
	transition: all .15s;
	opacity:0;
}
.CatalogDetails .productDisplayDetail:hover div{
	opacity:1;
}
.CatalogDetails .productDisplayDetail div:after{
	position: absolute;
	display:block;
	content:'';
	height:99px;
	width:100px;
	background-image:
		linear-gradient(0deg,#eaebeb 0%, #fff 100%),
		linear-gradient(#eaebeb,#eaebeb);
	border-radius:4px;
	bottom: 9px;
	right: 8px;
	z-index: -1;
	opacity:0;
	transition: all .15s;
}
.CatalogDetails .productDisplayDetail:hover div:after{
	opacity:1;
}
.CatalogDetails .productDisplayDetail h3{
	margin: 0;
	font-size: 18px;
	letter-spacing: 0px;
	font-weight: 500;
	line-height: 44px;
	color: #262b33;
	text-indent: 32px;
	margin-top:19px;
}
.CatalogDetails .productDisplayDetail h3:before{
	display:none;
}

.CatalogDetails .productDisplayDetail p{
	position: relative;
	top:4px;
	margin: 0 32px auto;
	color: #565d69;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 300;
	line-height: 22px;
	text-indent: 0em;
	overflow:hidden;
	text-overflow:ellipsis;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:6;
	transition: all .15s;
}
.CatalogDetails .productDisplayDetail:hover p{
	color: #262b33;
}
.CatalogDetails .productDisplayDetail span{
	text-decoration: none;
    width:100%;
    text-indent: 32px;
    font-weight:400;
    color: #0072ce;
    font-size: 16px;
    line-height: 58px;
}
.CatalogDetails .productDisplayDetail span img{
	position: relative;
    top: -2px;
    left: 5px;
    display: inline-block;
    width: 14px;
    height: 8px;
    transition: left .3s;
}
.CatalogDetails .productDisplayDetail:hover span img{
	left: 11px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	.fontmainMargin{
		margin-top: 40px;
	}
	#fontCatalog{
		width: 100%;
	}
	.fontmain .productIntroduce{
		top:-40px;
	}
	/*产品展示*/
	.CatalogDetails .productDisplay{
		grid-gap: 32px;
		grid-template-columns: 278px 278px 278px;
		justify-content:space-between;
		margin-top:36px;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontCatalog{
		margin-bottom:56px;
	}
	/*页顶简介*/
	.fontmain .productIntroduce div{
		height:73px;
	}
	.fontmain .productIntroduce div h1{
		line-height: 20px;
		margin:0 auto 6px;
	}
	.fontmain .productIntroduce div:nth-child(1) h1{
		width:80px;
	}
	.fontmain .productIntroduce div:nth-child(2) h1{
		width:128px;
	}
	.fontmain .productIntroduce div:nth-child(3) h1{
		width:128px;
	}
	.topLattice{
		width:460px;
	}
	.bottomLattice{
		width:460px;
	}
	/*产品展示*/
	.CatalogDetails .productDisplay{
		grid-gap: 35px 40px;
		justify-content:center;
		grid-template-columns: 278px 278px;
	}
	.CatalogDetails div:first-child {
		width: 100%;
	}
	.CatalogDetails .CatalogshrinkShort p{
		width: 656px;

	}
}
/*手机端*/
@media only screen and (max-width:750px){
.fade-leave-active,.fade-enter-active{
    transition: opacity 0.6s
}
.fade-enter-to,.fade-leave{
    opacity: 1
}
.fade-leave-to,.fade-enter{
    opacity: 0
}

	.fontmainMargin{
		margin-top: 40px;
	}
.topLattice{
	width:350px;
}
.bottomLattice{
	width:350px;
}
.fontmain .productIntroduce{
	position: relative;
	height:76px;
	margin-bottom:0px;
	top:-40px;
	display: grid;
	width:100%;
	grid-gap: auto;
	grid-template-columns: 100%;
	justify-content:space-between;
}
.fontmain .productIntroduce div{
	position: absolute;
	width:100%;
	height:39px;
	margin:24px auto;
	padding-top:0;
}
.fontmain .productIntroduce div:nth-child(2){
	border-left:none;
	border-right:none;
}
.fontmain .productIntroduce div h1{
	width:100%!important;
	line-height: 20px;
	font-size:14px;
	margin-bottom:5px;
}
.fontmain .productIntroduce div p{
	line-height: 19px;
	font-size:13px;
}

	#fontCatalog{
		width: 100%;
	}
	#fontCatalog h2{
		text-indent: 15px;
		font-size:15px;
		line-height: 15px;
	}
	/*概述页*/
	.CatalogDetails{
		margin-top: 40px;
	}
	.CatalogDetails:first-child{
		padding-bottom: 0;
		border-bottom:  1px solid #edeff0;
	}
	.CatalogDetails div:first-child{
		/* width:100%; */
		height:1px;
		left:0;
		top:-100px;
	}
	/*短简介文本*/
	.CatalogDetails .CatalogshrinkShort{
		width:calc(100% - 1px - 30px);
		border: 1px solid #edeff0;
		margin:20px auto 30px;
		padding:21.5px 0;
	}
	.CatalogDetails .CatalogshrinkShort p{
		margin:0 auto;
		width:calc(100% - 60px);
		line-height:2em;
		line-height: 21px;
	}
	/*产品展示*/
	.CatalogDetails .productDisplay{
		grid-gap: 30px;
		grid-template-columns: 100%;
	}
	.CatalogDetails h2+.productDisplay{
		margin-top:20px; 
	}
	.CatalogDetails .productDisplayDetail{
		margin:0 15px;
		padding-bottom: 10px;
		border:1px solid #e3e4e5;
		box-shadow: 0px 10px 20px -10px rgba(5,5,5,0);
	}
	.CatalogDetails .productDisplayDetail:hover{
		border: 1px solid #e8e9eb;
		box-shadow: 0px 10px 20px -10px rgba(5,5,5,0.08);
	}
	.CatalogDetails .productDisplayDetail:before{
		height:49px;
		width:92.5px;
		background:url('./images/灰色点阵-1080.png') no-repeat;
		background-size:92.5px 49px;
		top: -6.5px;
		left:10px;
	}
	.CatalogDetails .productDisplayDetail div{
		position: absolute;
		height:147px;
		width:148px;
		background:url('./images/红色点阵-1080.png') no-repeat;
		background-size:148px 147px;
		bottom: -8.5px;
		right: -9px;
	}
	.CatalogDetails .productDisplayDetail div:after{
		height:140px;
		width:145px;
		border-radius:2px;
		bottom: 4.5px;
		right: 4px;
	}
	
	.CatalogDetails .productDisplayDetail h3{
		font-size: 15px;
		line-height: 45px;
		text-indent: 30px;
		margin-top:10px;
	}
	.CatalogDetails .productDisplayDetail h3:before{
		display:none;
	}
	.CatalogDetails .productDisplayDetail p{
		margin: 0 30px;
		font-size: 14px;
		line-height: 20px;
		-webkit-line-clamp:4;
	}
	.CatalogDetails .productDisplayDetail span{
		text-indent: 30px;
		font-weight:400;
		font-size: 14px;
		line-height: 44px;
	}
	.CatalogDetails .productDisplayDetail span img{
		position: relative;
		top: -1px;
		left:8px;
		width: 13.5px;
		height: 8.5px;
	}
	.CatalogDetails .productDisplayDetail:hover span img{
		left: 16px;
	}
}
/*手机端1080
@media only screen and (min-height:1300px){
	.fontmainMargin{
		margin-top: 115px;
	}
	.fontmainMargin .topLattice{
		position: absolute;
		width:920px;
		height:100%;
		right:calc( 50% - 50vw);
		top:-115px;
		background:url('./images/产品列表_上点阵.png') no-repeat top right;
		z-index:-1;
	}
	.fontmainMargin .bottomLattice{
		position: absolute;
		width:920px;
		height:100%;
		left:calc( 50% - 50vw);
		bottom:-200px;
		background:url('./images/产品列表_下点阵.png') no-repeat bottom left;
		z-index:-1;
	}

	#fontCatalog{
		width: 100%;
	}
	#fontCatalog h2{
		text-indent: 44px;
		font-size:44px;
		line-height: 44px;
	}
	概述页
	.CatalogDetails{
		margin-top: 115px;
	}
	.CatalogDetails:first-child{
		padding-bottom: 0;
		border-bottom: 2px solid #edeff0;
	}
	.CatalogDetails div:first-child{
		height:1px;
		left:0;
		top:-290px;
	}
	产品展示
	.CatalogDetails .productDisplay{
		grid-gap: 86px;
		grid-template-columns: 100%;
	}
	.CatalogDetails h2+.productDisplay{
		margin-top:58px; 
	}
	.CatalogDetails .productDisplayDetail{
		margin:0 44px;
		padding-bottom: 62px;
		border:2px solid #e0e0e0;
	}
	.CatalogDetails .productDisplayDetail h3{
		font-size: 44px;
		line-height: 140px;
		text-indent: 86px;
		margin-top:24px;
	}
	.CatalogDetails .productDisplayDetail h3:before{
		display:none;
	}
	.CatalogDetails .productDisplayDetail p{
		margin: 0 86px;
		font-size: 40px;
		line-height: 60px;
	}
}*/
</style>
