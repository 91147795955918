<template>
    <div class="font" id="detailsfont">
        <h1>
            <span class="titleOfh1">{{detailTittle}}</span>
        </h1>
        <div class="fontOfdetails">
            <h2>问题描述</h2>
            <p class="pform1" v-for="(problem,indexproblem) in itemList.problem" :key="indexproblem + '-6'">{{problem}}</p>
            <h2>适用范围</h2>
            <p class="pform1" v-for="(scope,indexscope) in itemList.scope" :key="indexscope + '-7'">{{scope}}</p>
            <h2>解决方案</h2>
            <div v-for="(resolvent,indexresolvent) in itemList.resolvent" :key="indexresolvent + '-8'">
                <h3 v-html="resolvent.tittle"></h3>
                <div v-for="(detail,indexdetail) in resolvent.detail" :key="indexdetail + '-9'">
                    <p v-for="(tittle2,indextittle2) in detail.tittle" :key="indextittle2 + '-10'"  v-html="tittle2"></p>
                    <div v-show="detail.code">
                        <p v-for="(code,indexcode) in detail.code" :key="indexcode + '-11'" v-html="code"></p>
                    </div>
                    <img v-for="(img,indeximg) in detail.img" :kry="indeximg" :src="img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
	name:'Particulars',
    props: ['title4','detailTittle'],
	data() {
		return {
            itemList:'',
		}
	},
    computed:{
		...mapState({
			detailList:(state)=>state.Question.detailList,
		})
	},
	watch:{
		detailTittle:{
            immediate:true,
            handler(val){
                for (var i=0;i<this.detailList.length;i++){
                    if(this.detailList[i].tittle == val){
                        this.itemList = this.detailList[i].text;
                    }
                }
            },
            deep: true,
        }
	}
}
</script>
<style scoped>
/* 一级标题 */
#detailsfont h1 .titleOfh1{
    font-size: 26px;
    font-weight: 400; 
}
#detailsfont h1{
    line-height:70px;
    border-bottom:1px solid #e0e0e0;
}
/* 内容区 */
#detailsfont .fontOfdetails{
    padding: 0;
}
/* 二级标题 */
#detailsfont .fontOfdetails h2{
    font-size: 20px;
    line-height: 50px;
    color: #262b33;
    font-weight: 500; 
    margin-top: 17px;
}
#detailsfont .fontOfdetails p+h2{
    margin-top: 20px;
}
/* 三级文字 */
#detailsfont .fontOfdetails p{
    font-size: 16px;
    line-height: 24px;
    color: #262b33;
    font-weight: 400; 
    text-indent:1em;
    margin-top: 0!important;
}
#detailsfont .fontOfdetails .pform1{
    line-height: 26px;
    text-indent:0em;
}

#detailsfont .fontOfdetails div p >>> span{
    display:inline-block;
    text-indent:0em;
    color: #262b33;  
}
#detailsfont .fontOfdetails .pform1:before, #detailsfont .fontOfdetails div p >>> span:before{
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 4px 7px;
    background-color: #666;
    content: " ";
}
#detailsfont .fontOfdetails > div{
  margin: 16px 0 32px;
}
/*解决问题下第一个方案标题*/
#detailsfont .fontOfdetails h2+div{
    margin-top: 5px;
}
#detailsfont .fontOfdetails div h3{
    font-size: 16px;
    line-height: 24px;
    color: #262b33;
    font-weight: 400; 
    text-indent: 0;
}
#detailsfont .fontOfdetails div h3::before{
  display: none;
}
#detailsfont .fontOfdetails div h3 >>> b{
    font-size: 18px;
    font-weight: 500; 
}
#detailsfont .fontOfdetails div h3 >>> span{
    font-weight: 500;
    font-size: 16px; 
    color: #262b33;
}
#detailsfont .fontOfdetails div div div{
    background-color: #ecf5f0;
    border-radius:8px;
    margin: 12px 32px;
    padding: 20px 24px;  
    white-space:nowrap;
    overflow-x: auto;
}
#detailsfont .fontOfdetails div div div::-webkit-scrollbar{
    z-index:0;
}
#detailsfont .fontOfdetails div div p >>> b{
    margin-left: 1em;
    white-space:nowrap;
}
#detailsfont .fontOfdetails div div img{
    display: block;
    margin: 8px auto;
    width: 661px;
}
#detailsfont .fontOfdetails div div div p:before{
    display:none;
}
#detailsfont .fontOfdetails div div div p{
	position: relative;
    margin: 0;
    text-indent:0em;
    color: #7a7a7a;  
    white-space:nowrap;
}
#detailsfont .fontOfdetails div div div p >>> span{
    display:inline-block;
    text-indent:2em;
    color: #7a7a7a;
}
#detailsfont .fontOfdetails div div div p >>> span:before{
    display: none;
}
#detailsfont .fontOfdetails div div div p >>> b+span{
    display:inline-block;
    position: absolute;
    left:0;
    text-indent:22em;
}
#detailsfont .fontOfdetails div div div p >>> b{
    display:inline-block;
    color: #00806f;  
    font-weight: 400; 
}
#detailsfont .fontOfdetails div div div p >>> b span+span{
    display:inline-block;
    position: absolute;
    left:0;
    text-indent:0em;
}
/*手机端*/
@media only screen and (max-width:750px){
    /* 一级标题 */
    #detailsfont h1 .titleOfh1{
        font-size: 15px;
        margin-left: 0;
    }
    /* 内容区 */
    #detailsfont .fontOfdetails{
        padding: 0 15px;
    }
    /* 二级标题 */
    #detailsfont .fontOfdetails h2{
        font-size: 14px;
        line-height: 26px;
        margin-top: 8px;
    }
    #detailsfont .fontOfdetails p+h2{
        margin-top: 10px;
    }
    /* 三级文字 */
    #detailsfont .fontOfdetails p{
        font-size: 13px;
    }
    #detailsfont .fontOfdetails div p >>> span{
        width: calc(100% - 30px);
    }
    #detailsfont .fontOfdetails > div{
    margin: 8px 0 16px;
    }
    /*解决问题下第一个方案标题*/
    #detailsfont .fontOfdetails h2+div{
        margin-top: 3px;
    }
    #detailsfont .fontOfdetails div h3{
        font-size: 13px;
        line-height: 20px;
    }
    #detailsfont .fontOfdetails div h3 >>> b{
        font-size: 14px;
    }
    #detailsfont .fontOfdetails div h3 >>> span{
        font-size: 13px;
    }
    #detailsfont .fontOfdetails div div div{
        border-radius:4px;
        margin: 6px 16px;
        padding: 10px 12px;  
    }
    #detailsfont .fontOfdetails div div img{
        display: block;
        margin: 8px auto;
        width: calc(100vw - 60px);
    }
}
</style>
