/* News仓库 */
const state = {
	verShow: false,
	nowUrl: '',
	nowUrls: [],
	/* 安全公告 */
	SecurityNoticeList: [
		{
			tittle:'安全公告',
			text:[
				{
					downUrl:'/download/security/CVE-2019-11477.78.79/凝思安全操作系统内核-CVE-2019-11477_78_79脆弱性缓解措施实施通知.pdf',
					name:'凝思安全操作系统内核-CVE-2019-11477_78_79脆弱性缓解措施实施通知',
					time:'2019-06-28'
				},
			]
		},
	],
	/* 软件下载 */
	SoftwareDownloadList: [
		{
			tittle:'操作系统',
			text:[
				{
					name: '凝思安全操作系统V6.0.42（服务器和工作站版）',
					downUrl: '/download/os/6.0.42/linxos-6.0.42-20230715-x86_64-DVD.iso',
					md5: '48a89e97457a1fb0ad3baefb4f2621fb',
					time: '2023-12-11',
				},
				{
					name: '凝思安全操作系统V6.0.60（服务器和工作站版）',
					downUrl: '/download/os/6.0.60/linxos-6.0.60-20230531-x86_64-DVD.iso',
					md5: '5a66b5d3ef188676eacc63c6a3f37586',
					time: '2023-06-20',
				},
				{
					name: '凝思安全操作系统V6.0.80（服务器和工作站版）',
					downUrl: '/download/os/6.0.80/linxos-6.0.80-20230727-x86_64-DVD.iso',
					md5: 'c0dac7d5e89d8e62f25af28f00ead49e',
					time: '2023-08-30',
				},
				{
					name: '凝思安全操作系统V6.0.100（服务器和工作站版）X86_64',
					downUrl: '/download/os/6.0.100/x86_64/linxos-6.0.100-20230822-x86_64-DVD.iso',
					md5: '851468218404d48356ff2e2112a0933d',
					time: '2023-10-12',
				},
				{
					name: '凝思安全操作系统V6.0.100（服务器和工作站版）ARM64',
					downUrl: '/download/os/6.0.100/arm64/linxos-6.0.100-20240425-arm64-DVD.iso',
					md5: '7134b481e8188a8e2ede2061db17228b',
					time: '2024-05-09',
				},

				{
					name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3 X86_64',
					downUrl: '/download/os/6.0.99/x86_64/el20.03-sp3/linxos-6.0.99-el20.03-sp3-20240813-x86_64-DVD.iso',
					md5: '46674e0c3bdfdb47155744ff7ce1bbff',
					time: '2024-08-27',
				},
				{
					name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3 ARM64',
					downUrl: '/download/os/6.0.99/arm64/el20.03-sp3/linxos-6.0.99-el20.03-sp3-20240813-arm64-DVD.iso',
					md5: 'e8b3f269eb75534920c3bee02e6e00af',
					time: '2024-08-27',
				},
				{
					name: '凝思安全操作系统龙蜥版V6.0.98 AN8 X86_64',
					downUrl: '/download/os/6.0.98/x86_64/linxos-6.0.98-an8-20240730-x86_64-DVD.iso',
					md5: 'b4879e2f087a1db972915645b6cfc13a',
					time: '2024-09-26',
				}
			]
		},
		{
			tittle:'应用软件',
			text:[
				// 凝思系统双机热备软件   
				{
					name: '凝思系统双机热备软件（适用于凝思安全操作系统V6.0.42、V6.0.60）',
					downUrl: '/download/software/LXHA/LXHA-20180702-lxha2-x86_64.tgz',
					md5: 'e295c7f0a92811723753c8a6307f8d6b',
					time: '2018-07-02',
				},
				{
					name: '凝思双机热备软件（适用于凝思安全操作系统V6.0.80 non-root环境）V1.0.0.20190410',
					downUrl: '/download/software/LXHA/Linx_LXHA_V1.0.0.20190410+Linx6.0.80_NON-ROOT_amd64.tar.gz',
					md5: 'b18931e5b4485150968873cc2cad8a04',
					time: '2019-06-20',
				},
				{
					name: '支持存储双活的凝思V6.0.42系统多路径软件补丁包',
					downUrl: '/download/software/linx-mp/support/kernel-udev-lvm2-multipath-support.tar.gz',
					md5: '53131b9a1916bf1919b3d59b6a7cc294',
					time: '2016-12-30',
				},
				// 凝思多路径软件
				{
					name: '凝思多路径软件（适用于凝思安全操作系统V6.0.42 20161201）',
					downUrl: '/download/software/linx-mp/42/multipath-tools%230.5.0-x86_64-linx1-Rocky4.2.pkg.tar.gz',
					md5: 'c0f2d262290f6556a35daaf97a3edaa8',
					time: '2019-01-09',
				},
				{
					name: '凝思多路径软件V2.0（适用于凝思安全操作系统V6.0.42-700 X86_64）',
					downUrl: '/download/software/linx-mp/42-700/linx-mp_2.0-1.0.1+6.0.42-700_x86_64.tar.gz',
					md5: '865b3836002be26d81ebdb1e55fa3e42',
					time: '2023-07-24',
				},
				{
					name: '凝思多路径软件V2.0（适用于凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linx-mp/80/linx-mp_2.0-1.0.0+6.0.80_X86_64.tar.gz',
					md5: 'f05dd4dae7f3cd856e41ebd42aed9395',
					time: '2023-08-15',
				},
				// 凝思指纹仪认证平台软件
				{
					name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.42 X86_64)V2.0.1',
					downUrl: '/download/software/fingerprint/42/zhiang_linx42_x86_64%232.0.1.pkg.tar.gz',
					md5: '753680d95c8684436fd777d6e8ac76bf',
					time: '2019-11-05',
				},
				{
					name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.60 X86_64)V2.0.2',
					downUrl: '/download/software/fingerprint/60/linxzazfp60amd64_2.0.2.deb',
					md5: 'b838d8f4c3ec456b5a19c7ce19fb0434',
					time: '2019-11-12',
				},
				{
					name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.80 X86_64)V2.0.0',
					downUrl: '/download/software/fingerprint/80/linxzazfp80amd64_2.0.0.zip',
					md5: '7bcb7bc16c657ab32c1a5911acdb33ae',
					time: '2019-11-21',
				},
				// 凝思虚拟化管理系统
				{
					name: '华为虚拟化平台-OS6.0.42补丁包',
					downUrl: '/download/software/hw-noxen/kernel-noxen-pkg.tar.gz',
					md5: '1e1465be3de7e007db1f8c3bfb9b453b',
					time: '2016-10-26',
				},
				{
					name: '华为虚拟化平台-OS6.0.60补丁包',
					downUrl: '/download/software/hw-noxen/linx6.0_noxen_upgrade.tgz',
					md5: '6344b3e691e510a29b8fc60f6394f2f4',
					time: '2017-01-04',
				},
				// 凝思渔翁UKEY认证软件
				{
					name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/yuweng-ukey/amd64/yuweng_ukey_linx80_amd64%231.0.3.deb',
					md5: 'cd7b29b86038517c1e11c8b5f65dead4',
					time: '2019-01-30',
				},
				{
					name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 PPC）',
					downUrl: '/download/software/yuweng-ukey/ppc/yuweng_ukey_linx80_ppc%231.0.3.tar.gz',
					md5: 'd67ee41011eee89cd9b55a4dd72aeb16',
					time: '2019-01-30',
				},
				// 凝思容器系统
				{
					name: '凝思容器系统软件V1.0（集群）',
					downUrl: '/download/software/linx-container/linx-container_1.0-1.1.1+6.0.80_amd64.tar.gz',
					md5: '468e8e586e5a33efb45be6bb2c59af91',
					time: '2022-05-17',
				},
				{
					name: '凝思容器系统软件V1.0（容器）',
					downUrl: '/download/software/linx-container/linx-container-docker_1.0-1.1.1+6.0.80_amd64.tar.gz',
					md5: 'c8b520bbe6491699d4ae0014b6dfbb38',
					time: '2022-05-17',
				},
				// {
				// 	name: '凝思容器系统软件V1.0-R1.1.0-4补丁包',
				// 	downUrl: '/download/software/linx-container-patch/1.1.0-4-patch/linx-container_1.0-1.1.0-4-patch+6.0.80_x86_64.tar.gz',
				// 	md5: 'ec4231c813114d11175c74b7fc0f500a',
				// 	time: '2023-01-16',
				// },
				{
					name: '凝思容器系统软件V1.0-R1.1.1-1补丁包',
					downUrl: '/download/software/linx-container-patch/1.1.1-1-patch/linx-container_1.0-1.1.1-1-patch+6.0.80_x86_64.tar.gz',
					md5: '391efa726246f7fb1dabe985edf9d6c7',
					time: '2023-01-16',
				},
				//80
				{
					name: '凝思安全容器系统(集群)V1.0（适配凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxscontainer/80/x86_64/linxscontainer_1.0-1.1.1+6.0.80_x86_64.tar.gz',
					md5: '45b18b0aadf0cde86eb3e99246cb9757',
					time: '2022-11-28',
				},
				{
					name: '凝思安全容器系统（容器）V1.0（适配凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxscontainer/80/x86_64/linxscontainer-runtime_1.0-1.1.1+6.0.80_x86_64.tar.gz',
					md5: '50ab23dfb9231a04f225675e2e00358b',
					time: '2022-11-28',
				},
				{
					name: '凝思安全容器系统（前端）V1.0（适配凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxscontainer/80/x86_64/linxscontainer-ui_1.0-1.1.1+6.0.80_x86_64.tar.gz',
					md5: '25796f94e844968e324b307b0f8ff770',
					time: '2022-11-28',
				},
				{
					name: '凝思安全容器系统（SDK）V1.0（适配凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxscontainer/80/x86_64/linxscontainer-sdk_1.0-1.1.1+6.0.80_x86_64.tar.gz',
					md5: 'fb446355b2697b160491a045016c04e5',
					time: '2022-11-28',
				},
				{
					name: '凝思安全容器系统（工具包）V1.0（适配凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxscontainer/80/x86_64/linxscontainer-tools_1.0-1.1.1.tar.gz',
					md5: 'b023bc11d9f883c97fd9fd611a91f64d',
					time: '2022-11-28',
				},
				//100
				{
					name: '凝思安全容器系统（集群）V1.0（适配凝思安全操作系统V6.0.100 ARM64）',
					downUrl: '/download/software/linxscontainer/100/arm64/linx-scontainer_1.0-1.1.0+6.0.100_arm64.tar.gz',
					md5: 'd764e4fa5db2997334e6dd18f71f3e48',
					time: '2022-11-04',
				},
				{
					name: '凝思安全容器系统（容器）V1.0（适配凝思安全操作系统V6.0.100 ARM64）',
					downUrl: '/download/software/linxscontainer/100/arm64/linx-scontainer-runtime_1.0-1.1.0+6.0.100_arm64.tar.gz',
					md5: '8c6ff5421e064958a7c1b41fd7ef6636',
					time: '2022-11-04',
				},
				{
					name: '凝思安全容器系统（前端）V1.0（适配凝思安全操作系统V6.0.100 ARM64）',
					downUrl: '/download/software/linxscontainer/100/arm64/linx-scontainer-ui_1.0-1.1.0+6.0.100_arm64.tar.gz',
					md5: 'dc077b9fc73d8b78f17171a7bf32aa5d',
					time: '2022-11-04',
				},
				{
					name: '凝思安全容器系统（SDK）V1.0（适配凝思安全操作系统V6.0.100 ARM64）',
					downUrl: '/download/software/linxscontainer/100/arm64/linx-scontainer-sdk_1.0-1.1.0+6.0.100_arm64.tar.gz',
					md5: 'bc60927e12a379919d566d31dc08144a',
					time: '2022-11-04',
				},
				{
					name: '凝思安全容器系统（工具包）V1.0（适配凝思安全操作系统V6.0.100 ARM64）',
					downUrl: '/download/software/linxscontainer/100/arm64/linx-scontainer-tools_1.0-1.1.0.tar.gz',
					md5: '40815e5832802b8cf9126588a1c68043',
					time: '2022-11-04',
				},
				{
					name: '凝思轻量级容器软件V1.1',
					downUrl: '/download/software/linxc/linxc-1.1-amd64-1.1.1.tar.gz',
					md5: '5d3a5fa31f6ff1a8b2288505c267fbcf',
					time: '2020-12-20',
				},
				//凝思Windows应用容器软件
				{
					name: '凝思Windows应用容器软件V1.0',
					downUrl: '/download/software/linx-wc/linx-wc_1.0-1.0.0+win-server_x86_64.zip',
					md5: 'a596748c39d0dd90838f622f332d98b3',
					time: '2023-05-06',
				},
				//分布式文件系统
				{
					name: '凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/software/linxdfs/linxdfs_1.1-1.0.3+6.0.80_amd64.tar.gz',
					md5: '473503b402c4ab94168c942680ff44d6',
					time: '2022-05-16',
				},
				// 安全云桌面系统
				{
					name: '凝思安全云桌面系统V1.0-R1.1.7',
					downUrl: '/download/software/linx-virt/linx-virt_1.0-1.1.7_x86_64.tar.gz',
					md5: 'adbe44ba7c58ddffce3dd0cacfee7609',
					time: '2023-12-14',
				},
				{
					name: '凝思安全云桌面系统V1.0-R1.1.6-7补丁包',
					downUrl: '/download/software/linx-virt-patch/1.1.6-7/linx-virt_1.0-1.1.6-7-patch_x86_64.tar.gz',
					md5: 'f8b8504e81c80a09460ef4a1417171f1',
					time: '2023-11-28',
				},
				// 凝思虚拟化管理平台软件
				{
					name: '凝思虚拟化管理平台软件V1.0-R1.1.7',
					downUrl: '/download/software/linx-vmps/1.1.7/linx-vmps_1.0-1.1.7_x86_64.tar.gz',
					md5: '2d51cd9bd9ba4aff1bf2584e294d97d0',
					time: '2023-12-14',
				},
				//多因子鉴别系统平台
				{
					name: '凝思多因子鉴别系统平台V1.0（适用于凝思安全操作系统V6.0.42 X86_64）',
					downUrl: '/download/software/linx-identity-auth/42/linx-identity-auth-1.0+6.0.42_x86_64_1.0.0.tar.gz',
					md5: '99cb6b615f83349e78874b9e8a2ce13a',
					time: '2021-12-30',
				},
			]
		},
		{
			tittle:'其他',
			text:[
				{
					name: '凝思安全操作系统V6.0.60驱动升级包',
					downUrl: '/download/driver/update-drivers-linx6.0.60.4.tgz',
					md5: '924d7c4cb6bd9378dbf54c0a6a0615b1',
					time: '2017-05-19',
				},
				{
					name: '惠普打印机驱动安装包(含文档)',
					downUrl: '/download/driver/linx_hp_printer_support.tgz',
					md5: 'f35e658b23e7f16a3d592953037b837b',
					time: '2018-10-22',
				},
				{
					name: '联想L100打印机驱动安装包（含文档）（适用于凝思安全操作系统V6.0.60 X86_64）',
					downUrl: '/download/driver/lenovo-L100-printer/lenovo-L100-Linx60.tar.gz',
					md5: '90f6821a4a87e20d4d40d08933479b6e',
					time: '2020-02-20',
				},
				{
					name: '联想L100打印机驱动安装包（含文档）（适用于凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/driver/lenovo-L100-printer/lenovo-L100-Linx80.tar.gz',
					md5: 'c27a4b78707247fdf92728c9bcba2780',
					time: '2020-03-31',
				},
				{
					name: '佳能LBP6018L打印机驱动安装包（含文档）（适用于凝思安全操作系统V6.0.80 X86_64）',
					downUrl: '/download/driver/canon-LBP6018L-printer/canon-LBP6018L-Linx80.tar.gz',
					md5: '1c5cde951c3d1747c8d47de5bfac63b1',
					time: '2020-05-14',
				},
			]
		}
	],
	/* 文档下载 */
	DocumentDownloadList: [
		{
			tittle:'凝思安全操作系统V6.0.42 （服务器和工作站版）',
			text:[
				{
					name: '凝思安全操作系统V6.0.42用户手册V1.1',
					downUrl: '/download/document/6.0.42/凝思安全操作系统V6.0.42用户手册.pdf',
					md5: '22545e91c63757ed7a37286d98809d6a',
					time: '2023-12-11',
				},
				{
					name: '凝思安全操作系统V6.0.42安装手册V1.1',
					downUrl: '/download/document/6.0.42/凝思安全操作系统V6.0.42安装手册V1.1.pdf',
					md5: '992540bcf024da1d31995325854ccde5',
					time: '2023-12-11',
				},
				{
					name: '凝思安全操作系统V6.0.42定制安装手册V1.1',
					downUrl: '/download/document/6.0.42/凝思安全操作系统V6.0.42定制安装手册V1.1.pdf',
					md5: '7d7e462ccc415b6c5bf3a6bc2b8845ca',
					time: '2023-12-11',
				},
				{
					name: '凝思安全操作系统V6.0.42 X86_64 20230715发布说明',
					downUrl: '/download/document/6.0.42/凝思安全操作系统V6.0.42_X86_64_20230715发布说明.pdf',
					md5: '12f713e4f09e4a2b6f12f24a7372f8ab',
					time: '2023-12-11',
				},
			]
		},
		{
			tittle:'凝思安全操作系统V6.0.60 （服务器和工作站版）',
			text:[
				{
					name: '凝思安全操作系统V6.0.60用户手册',
					downUrl: '/download/document/6.0.60/凝思安全操作系统V6.0.60用户手册.pdf',
					md5: '51ecf30eb8461acf5d3a3dc4e35fb9e6',
					time: '2023-06-20',
				},
				{
					name: '凝思安全操作系统V6.0.60安装手册',
					downUrl: '/download/document/6.0.60/凝思安全操作系统V6.0.60安装手册.pdf',
					md5: 'b4e52c19547727daec3e9df604a083c4',
					time: '2023-06-20',
				},
				{
					name: '凝思安全操作系统V6.0.60 X86_64 20230531发布说明V1.0',
					downUrl: '/download/document/6.0.60/凝思安全操作系统V6.0.60_X86_64_20230531发布说明V1.0.pdf',
					md5: '4c141604888b215c428bcced25b1b30c',
					time: '2023-06-20',
				}
			]
		},
		{
			tittle:'凝思安全操作系统V6.0.80 （服务器和工作站版）',
			text:[
                {
                    name: '凝思安全操作系统V6.0.80用户手册',
                    downUrl: '/download/document/6.0.80/凝思安全操作系统V6.0.80用户手册.pdf',
                    md5: '9daf85c7b7f72174b0c1823b209ac881',
                    time: '2023-08-30',
                },
                {
                    name: '凝思安全操作系统V6.0.80安装手册',
                    downUrl: '/download/document/6.0.80/凝思安全操作系统V6.0.80安装手册.pdf',
                    md5: 'f38740902accd075038e1f7a4571f694',
                    time: '2023-08-30',
                },
                {
                    name: '凝思安全操作系统V6.0.80 X86_64 20230727发布说明V1.0',
                    downUrl: '/download/document/6.0.80/凝思安全操作系统V6.0.80_X86_64_20230727发布说明V1.0.pdf',
                    md5: '917b5ac803740fd924fa997f468e19d3',
                    time: '2023-08-30',
                },
                {
                    name: '凝思安全操作系统V6.0.80网络配置说明',
                    downUrl: '/download/document/6.0.80/Linx6.0.80网络配置说明.pdf',
                    md5: '8f6b9f14d34ed1dd10e333ea17637eac',
                    time: '2019-10-23',
                }
			]
		},
		{
			tittle:'凝思安全操作系统V6.0.100（服务器和工作站版）',
			text:[
                {
                    name: '凝思安全操作系统V6.0.100用户手册V1.2',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100用户手册.pdf',
                    md5: '5368e9f0f0122552ac916771fa857a10',
                    time: '2023-10-12',
                },
                {
                    name: '凝思安全操作系统V6.0.100 X86_64 安装手册V1.1',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_X86_64安装手册V1.1.pdf',
                    md5: 'd198e543cc3b45a88cd71fdc290eedbb',
                    time: '2023-10-12',
                },
                {
                    name: '凝思安全操作系统V6.0.100 X86_64 20230822发布说明V1.0',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_X86_64_20230822发布说明V1.0.pdf',
                    md5: '729c24ad107b4ab3db8be4e07fbec971',
                    time: '2023-10-12',
                },
				{
                    name: '凝思安全操作系统V6.0.100 ARM64 用户手册V1.0',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_ARM64用户手册.pdf',
                    md5: 'bb9e1a618fbc72975fa9951896825f7b',
                    time: '2024-05-09',
                },
				{
                    name: '凝思安全操作系统V6.0.100 ARM64 安装手册V1.3',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_ARM64安装手册V1.3.pdf',
                    md5: '44da661b9c5226e7794ec99b8a26d7e1',
                    time: '2024-05-09',
                },
				{
                    name: '凝思安全操作系统V6.0.100软RAID安装使用说明V1.4',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100软RAID安装使用说明V1.4.pdf',
                    md5: '832bf48b21cac0eac90d78905ce8021c',
                    time: '2024-05-09',
                },
				{
                    name: '凝思安全操作系统V6.0.100 ARM64双屏及显卡使用说明V1.2',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_ARM64双屏及显卡使用说明V1.2.pdf',
                    md5: 'f9767045984b129991e25dfcbb4aeffb',
                    time: '2024-05-09',
                },
				{
                    name: '凝思安全操作系统V6.0.100 chroot容器环境使用V1.1',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_chroot容器环境使用V1.1.pdf',
                    md5: '24af6a8e5d01624c2386057ee0627f74',
                    time: '2024-05-09',
                },
				{
                    name: '凝思安全操作系统V6.0.100 ARM64 20240425发布说明',
                    downUrl: '/download/document/6.0.100/凝思安全操作系统V6.0.100_ARM64_20240425发布说明.pdf',
                    md5: 'c7eefc30f6a92aeb3c25ded91c3453ea',
                    time: '2024-05-09',
                }
			]
		},
		{
			tittle:'凝思安全操作系统欧拉版',
			text:[
                {
                    name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3用户手册V2.1',
                    downUrl: '/download/document/6.0.99/el20.03-sp3/凝思安全操作系统欧拉版V6.0.99_EL20.03-SP3用户手册.pdf',
                    md5: '47fae062354b3d02db5372d2db64f8c3',
                    time: '2024-08-27',
                },
				{
                    name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3安装手册V2.0',
                    downUrl: '/download/document/6.0.99/el20.03-sp3/凝思安全操作系统欧拉版V6.0.99_EL20.03-SP3安装手册.pdf',
                    md5: '74d0cbd6344174f5cf84be65eff5c002 ',
                    time: '2024-08-27',
                },
				{
                    name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3 X86_64 20240813发布说明',
                    downUrl: '/download/document/6.0.99/el20.03-sp3/凝思安全操作系统欧拉版V6.0.99_EL20.03-SP3_X86_64_20240813发布说明.pdf',
                    md5: '897700d0eb15c34f5e7c34299e583bfa',
                    time: '2024-08-27',
                },
				{
                    name: '凝思安全操作系统欧拉版V6.0.99 EL20.03-SP3 ARM64 20240813发布说明',
                    downUrl: '/download/document/6.0.99/el20.03-sp3/凝思安全操作系统欧拉版V6.0.99_EL20.03-SP3_ARM64_20240813发布说明.pdf',
                    md5: 'a5cb99155ec66b5e2004e79940c684a5',
                    time: '2024-08-27',
                }
			]
		},
		{
			tittle:'凝思安全操作系统龙蜥版',
			text:[
                {
                    name: '凝思安全操作系统龙蜥版V6.0.98 AN8用户手册',
                    downUrl: '/download/document/6.0.98/凝思安全操作系统龙蜥版V6.0.98_AN8用户手册.pdf',
                    md5: 'b480bd2d1c0647d28eb45cf2296d7fa1',
                    time: '2024-09-26',
                },
				{
                    name: '凝思安全操作系统龙蜥版V6.0.98 AN8安装手册',
                    downUrl: '/download/document/6.0.98/凝思安全操作系统龙蜥版V6.0.98_AN8安装手册.pdf',
                    md5: 'a59360b6157be491a434fd26f1640329',
                    time: '2024-09-26',
                },
				{
                    name: '凝思安全操作系统龙蜥版V6.0.98 AN8 X86_64 20240730发布说明',
                    downUrl: '/download/document/6.0.98/凝思安全操作系统龙蜥版V6.0.98_AN8_X86_64_20240730发布说明.pdf',
                    md5: 'd1f519e6179e95909c15e1ca2990b843',
                    time: '2024-09-26',
                }
			]
		},
		{
			tittle:'凝思双机热备软件',
			text:[
                {
                    name: '凝思双机热备软件V20180702（适用于凝思安全操作系统V6.0.42）安装配置手册V1.0.1',
                    downUrl: '/download/document/LXHA/凝思双机热备软件V20180702（适用于凝思安全操作系统V6.0.42）安装配置手册V1.0.1.pdf',
                    md5: '85734cf33fef725f5b5c6b101ebf90a4',
                    time: '2019-10-11',
                },
                {
                    name: '凝思双机热备软件V20180702（适用于凝思安全操作系统V6.0.60）安装配置手册V1.0.1',
                    downUrl: '/download/document/LXHA/凝思双机热备软件V20180702（适用于凝思安全操作系统V6.0.60）安装配置手册V1.0.1.pdf',
                    md5: 'aa91145a66afe7c618d6e678ebbc2e5b',
                    time: '2019-10-11',
                },
                {
                    name: '凝思双机热备软件（适用于凝思安全操作系统V6.0.80 non-root环境）V1.0.0.20190410安装配置手册V1.0.1',
                    downUrl: '/download/document/LXHA/凝思双机热备软件V1.0.0.20190410（适用于凝思安全操作系统V6.0.80 non-root环境）安装配置手册V1.0.1.pdf',
                    md5: 'b676444d26021a4b0000f4488346cbe4',
                    time: '2019-10-11',
                },
			]
		},
		{
			tittle:'凝思多路径软件',
			text:[
                {
                    name: '凝思多路径软件（适用于凝思安全操作系统V6.0.42）安装配置手册V1.0(20191011)',
                    downUrl: '/download/document/linx-mp/凝思多路径软件（适用于凝思安全操作系统V6.0.42）安装配置手册V1.0(20191011).pdf',
                    md5: '07ebb627510333059ea37f5e0efb38da',
                    time: '2019-10-23',
                },
				{
                    name: '凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.42-700 X86_64)安装配置手册V1.1',
                    downUrl: '/download/document/linx-mp/凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.42-700 X86_64)安装配置手册V1.1.pdf',
                    md5: 'b609a5ac521fcf1c362d9f7d3fc82260',
                    time: '2023-08-15',
                },
				{
                    name: '凝思多路径软件V2.0（适用于凝思安全操作系统V6.0.42-700 X86_64）R1.0.1发布说明V1.0',
                    downUrl: '/download/document/linx-mp/凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.42-700 X86_64)R1.0.1发布说明V1.0.pdf',
                    md5: 'f1123934734f28dba59b36dec23a987d',
                    time: '2023-07-24',
                },
                {
                    name: '凝思多路径软件（适用于凝思安全操作系统V6.0.60）安装配置手册V1.0(20191011)',
                    downUrl: '/download/document/linx-mp/凝思多路径软件（适用于凝思安全操作系统V6.0.60）安装配置手册V1.0(20191011).pdf',
                    md5: '6789d5c92d3468d26eff9bd2023fb49b',
                    time: '2019-10-23',
                },
                {
                    name: '凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.80 X86_64)R1.0.0发布说明V1.0',
					downUrl: '/download/document/linx-mp/凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.80 X86_64)R1.0.0发布说明V1.0.pdf',
                    md5: '56fd8b727958aec3bb178e55cbbeb88e',
                    time: '2023-08-15',
                },
				{
                    name: '凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.80 X86_64)安装配置手册V1.1',
                    downUrl: '/download/document/linx-mp/凝思多路径软件V2.0(适用于凝思安全操作系统V6.0.80 X86_64)安装配置手册V1.1.pdf',
                    md5: '4037311bb3919d4008c1796070b51d2a',
                    time: '2023-08-15',
                },
			]
		},
		{
			tittle:'凝思指纹仪认证平台软件',
			text:[
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.42 X86_64)V2.0.1安装手册',
                    downUrl: '/download/software/fingerprint/42/凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.42 x86_64)V2.0.1安装手册.pdf',
                    md5: '83d8ae1965dc1c757d2f5a7ea4d57ac1',
                    time: '2019-11-05',
                },
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.42 X86_64)V2.0.1使用手册',
                    downUrl: '/download/software/fingerprint/42/凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.42 x86_64)V2.0.1使用手册.pdf',
                    md5: '1ad638d5adb08737bb2f9fe90bd01689',
                    time: '2019-11-05',
                },
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.60 X86_64)V2.0.2安装手册',
                    downUrl: '/download/software/fingerprint/60/凝思指纹仪认证平台软件（指昂指纹仪适用于凝思安全操作系统V6.0.60 amd64）V2.0.2安装手册.pdf',
                    md5: '412cbc2dfcd2487a8fcbdbfaed625c7',
                    time: '2019-11-12',
                },
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.60 X86_64)V2.0.2使用手册',
                    downUrl: '/download/software/fingerprint/60/凝思指纹仪认证平台软件（指昂指纹仪适用于凝思安全操作系统V6.0.60 amd64）V2.0.2使用手册.pdf',
                    md5: '0d7976b04554e16778b1be3a351b3745',
                    time: '2019-11-12',
                },
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.80 X86_64)V2.0.0安装手册',
                    downUrl: '/download/software/fingerprint/80/凝思指纹仪认证平台软件（指昂指纹仪适用于凝思安全操作系统V6.0.80 amd64）V2.0.0安装手册.pdf',
                    md5: '5cc859fb32db3ef054e0072036d88dd6',
                    time: '2019-11-21',
                },
                {
                    name: '凝思指纹仪认证平台软件(指昂指纹仪适用于凝思安全操作系统V6.0.80 X86_64)V2.0.0使用手册',
                    downUrl: '/download/software/fingerprint/80/凝思指纹仪认证平台软件（指昂指纹仪适用于凝思安全操作系统V6.0.80 amd64）V2.0.0使用手册.pdf',
                    md5: '2e77719d82c96c1f38b7151da2549cf0',
                    time: '2019-11-21',
                },
			]
		},
		{
			tittle:'凝思渔翁UKEY认证软件',
			text:[
                {
                    name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 X86_64）安装手册',
                    downUrl: '/download/document/yuweng-ukey/amd64/凝思渔翁UKEY认证软件V1.0.3（Linx V6.0.80_amd64）安装手册.pdf',
                    md5: '3086efb8cae2d9a5488aabd0f818b1b5',
                    time: '2019-01-30',
                },
                {
                    name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 X86_64）使用手册',
                    downUrl: '/download/document/yuweng-ukey/amd64/凝思渔翁UKEY认证软件V1.0.3（Linx V6.0.80_amd64）使用手册.pdf',
                    md5: '2b8e36afa0ad4d7854c6921caddf7e94',
                    time: '2019-01-30',
                },
                {
                    name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 PPC）安装手册',
                    downUrl: '/download/document/yuweng-ukey/ppc/凝思渔翁UKEY认证软件V1.0.3（Linx V6.0.80_PPC）安装手册.pdf',
                    md5: '60e8baf5590139600c83f41b10f99514',
                    time: '2019-01-30',
                },
                {
                    name: '凝思渔翁UKEY认证软件V1.0.3（适用于凝思安全操作系统V6.0.80 PPC）使用手册',
                    downUrl: '/download/document/yuweng-ukey/ppc/凝思渔翁UKEY认证软件V1.0.3（Linx V6.0.80_PPC）使用手册.pdf',
                    md5: '6d6ca539951011d9e4f4f2258b019b30',
                    time: '2019-01-30',
                },
			]
		},
		{
			tittle:'凝思容器系统',
			text:[
                {
                    name: '凝思容器系统软件V1.0-R1.1.1发布说明',
                    downUrl: '/download/document/linx-container/凝思容器系统软件V1.0-R1.1.1发布说明.pdf',
                    md5: '40f100d8801b286ec502b8f0d5de7c6c',
                    time: '2022-05-17',
                },
                {
                    name: '凝思容器系统软件V1.0部署手册V1.1',
                    downUrl: '/download/document/linx-container/凝思容器系统软件V1.0部署手册V1.1.pdf',
                    md5: '58e9cea81ae91fba104149acb7811f04',
                    time: '2023-05-09',
                },
                {
                    name: '凝思容器系统软件V1.0使用手册V1.0',
                    downUrl: '/download/document/linx-container/凝思容器系统软件V1.0使用手册V1.0.pdf',
                    md5: 'ac3fe6d20245d5e64aa2114532b18e73',
                    time: '2022-05-17',
                },
                {
                    name: '凝思容器管理系统V1.0使用手册V1.0',
                    downUrl: '/download/document/linx-container/凝思容器管理系统V1.0使用手册V1.0.pdf',
                    md5: '6f9e78f2b8b031ca54b941229079e330',
                    time: '2022-05-17',
                },
				// {
                //     name: '凝思容器系统软件V1.0-R1.1.0-4补丁包部署手册V1.0',
                //     downUrl: '/download/document/linx-container/1.1.0-4-patch/凝思容器系统软件V1.0-R1.1.0-4补丁包部署手册V1.0.pdf',
                //     md5: '2571649cbc533654edf0a060886abd31',
                //     time: '2023-01-16',
                // },
				// {
                //     name: '凝思容器系统软件V1.0-R1.1.0-4补丁包发布说明V1.0',
                //     downUrl: '/download/document/linx-container/1.1.0-4-patch/凝思容器系统软件V1.0-R1.1.0-4补丁包发布说明V1.0.pdf',
                //     md5: '1b2d7c58f1e246cc1626a9ce9db6ad5e',
                //     time: '2023-01-16',
                // },
                {
                    name: '凝思容器系统软件V1.0-R1.1.1-1补丁包部署手册V1.0',
                    downUrl: '/download/document/linx-container/1.1.1-1-patch/凝思容器系统软件V1.0-R1.1.1-1补丁包部署手册V1.0.pdf',
                    md5: 'd8069a43d952dcbc233c43fc29b6d3d5',
                    time: '2023-01-16',
                },
				{
                    name: '凝思容器系统软件V1.0-R1.1.1-1补丁包发布说明V1.0',
                    downUrl: '/download/document/linx-container/1.1.1-1-patch/凝思容器系统软件V1.0-R1.1.1-1补丁包发布说明V1.0.pdf',
                    md5: '44b7f640dd353f0f55c4f3e73d818262',
                    time: '2023-01-16',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.0（适用于凝思安全操作系统V6.0.100 ARM64）发布说明',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.0（适用于凝思安全操作系统V6.0.100 ARM64）发布说明.pdf',
                    md5: 'c3472208cce5a00d947c67231b132d2c',
                    time: '2022-11-04',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.1（适用于凝思安全操作系统V6.0.80 X86_64）发布说明',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.1（适用于凝思安全操作系统V6.0.80 X86_64）发布说明.pdf',
                    md5: 'd8983e7c15cf3eb721c0553e059eddd4',
                    time: '2022-11-28',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.1（适用于凝思安全操作系统V6.0.80 X86_64）SDK更新说明',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.1（适用于凝思安全操作系统V6.0.80 X86_64）SDK更新说明.pdf',
                    md5: 'a60af69fd3485796e3ac25de8502ffc2',
                    time: '2022-11-28',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.1镜像制作手册V1.6',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.1镜像制作手册V1.6.pdf',
                    md5: 'ddafd9135e018552beb0a72167c16336',
                    time: '2022-11-28',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.1部署手册V1.6',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.1部署手册V1.6.pdf',
                    md5: '550f85289f9cedd764be80554ad37663',
                    time: '2022-11-28',
                },
                {
                    name: '凝思安全容器系统V1.0-R1.1.1使用手册V1.6',
                    downUrl: '/download/document/linxscontainer/凝思安全容器系统V1.0-R1.1.1使用手册V1.6.pdf',
                    md5: 'bbbfd674cc2a55441c0a25678b4d9d6e',
                    time: '2022-11-28',
                },
                {
                    name: '凝思轻量级容器软件V1.1-R1.1.1发布说明',
                    downUrl: '/download/document/linxc/凝思轻量级容器软件V1.1-R1.1.1发布说明.pdf',
                    md5: 'fd18467da479088fffa1ecd63523e518',
                    time: '2020-12-21',
                },
                {
                    name: '凝思轻量级容器软件V1.1-R1.1.1部署手册',
                    downUrl: '/download/document/linxc/凝思轻量级容器软件V1.1-R1.1.1部署手册.pdf',
                    md5: '0cd2724560d5332790f618baf9a6a417',
                    time: '2020-12-21',
                },
                {
                    name: '凝思轻量级容器软件V1.1-R1.1.1使用手册',
                    downUrl: '/download/document/linxc/凝思轻量级容器软件V1.1-R1.1.1使用手册.pdf',
                    md5: 'ef8ef5acf2203695e8ce8d1066b0bdb9',
                    time: '2020-12-21',
                },
				{
                    name: '凝思Windows应用容器软件V1.0-R1.0.0发布说明',
                    downUrl: '/download/document/linx-wc/凝思Windows应用容器软件V1.0-R1.0.0发布说明.pdf',
                    md5: '39f978bb1230a5d373aad0a954ca5c23',
                    time: '2023-05-06',
                },
				{
                    name: '凝思Windows应用容器软件V1.0-R1.0.0使用手册',
                    downUrl: '/download/document/linx-wc/凝思Windows应用容器软件V1.0-R1.0.0使用手册.pdf',
                    md5: '1bcf6085e2efdc1039654171e8f8d207',
                    time: '2023-05-06',
                },
			]
		},
		{
			tittle:'凝思分布式文件系统',
			text:[
                {
                    name: '凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3发布说明',
                    downUrl: '/download/document/linxdfs/凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3发布说明.pdf',
                    md5: '27ecaa6794db232c53e8f8cf57dcc2a1',
                    time: '2022-05-16',
                },
                {
                    name: '凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3使用手册',
                    downUrl: '/download/document/linxdfs/凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3使用手册.pdf',
                    md5: '9eb96472c9d9b55ca5ecd0339dc4098a',
                    time: '2022-05-16',
                },
                {
                    name: '凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3部署手册',
                    downUrl: '/download/document/linxdfs/凝思分布式文件系统V1.1（适用于凝思安全操作系统V6.0.80 X86_64）R1.0.3部署手册.pdf',
                    md5: '73abe48d9e41d0de17a0610e7f71e9a2',
                    time: '2022-05-16',
                },
			]
		},
		{
			tittle:'凝思安全云桌面系统',
			text:[
                {
                    name: '凝思安全云桌面系统V1.0-R1.1.7用户手册',
                    downUrl: '/download/document/linx-virt/凝思安全云桌面系统V1.0-R1.1.7用户手册.pdf',
                    md5: '5d065029891b94498a5dc10859db084a',
                    time: '2023-12-14',
                },
                {
                    name: '凝思安全云桌面系统V1.0-R1.1.7部署手册',
                    downUrl: '/download/document/linx-virt/凝思安全云桌面系统V1.0-R1.1.7部署手册.pdf',
                    md5: '76b89ff18f82c460bf330d840b03a589',
                    time: '2023-12-14',
                },
                {
                    name: '凝思安全云桌面系统V1.0-R1.1.7发布说明',
                    downUrl: '/download/document/linx-virt/凝思安全云桌面系统V1.0-R1.1.7发布说明.pdf',
                    md5: 'bf1ac48eb99bd2995c001e69506f3927',
                    time: '2023-12-14',
                },
                {
                    name: '凝思安全云桌面系统V1.0-R1.1.6-7补丁包安装手册',
                    downUrl: '/download/software/linx-virt-patch/1.1.6-7/凝思安全云桌面系统V1.0-R1.1.6-7补丁包安装手册.pdf',
                    md5: 'fe15190e71e33a574770172bd7a32df1',
                    time: '2023-11-28',
                },
                {
                    name: '凝思安全云桌面系统V1.0-R1.1.6-7补丁包发布说明',
                    downUrl: '/download/software/linx-virt-patch/1.1.6-7/凝思安全云桌面系统V1.0-R1.1.6-7补丁包发布说明.pdf',
                    md5: '5d6b7f91a9786679ed38e30a46d018e0',
                    time: '2023-11-28',
                }
			]
		},
		{
			tittle:'凝思虚拟化管理平台软件',
			text:[
                {
                    name: '凝思虚拟化管理平台软件V1.0-R1.1.7用户手册',
                    downUrl: '/download/document/linx-vmps/凝思虚拟化管理平台软件V1.0-R1.1.7用户手册.pdf',
                    md5: 'ec300374108776e16563a8a01d16f4e5',
                    time: '2023-12-14',
                },
                {
                    name: '凝思虚拟化管理平台软件V1.0-R1.1.7部署手册',
                    downUrl: '/download/document/linx-vmps/凝思虚拟化管理平台软件V1.0-R1.1.7部署手册.pdf',
                    md5: '01407faf0be8cdb1540ce5ddd44725c1',
                    time: '2023-12-14',
                },
                {
                    name: '凝思虚拟化管理平台软件V1.0-R1.1.7发布说明',
                    downUrl: '/download/document/linx-vmps/凝思虚拟化管理平台软件V1.0-R1.1.7发布说明.pdf',
                    md5: '027e2e1dd51f463740829d5df6e46fdc',
                    time: '2023-12-14',
                }
			]
		},
		{
			tittle:'凝思多因子鉴别系统平台',
			text:[
                {
                    name: '凝思多因子鉴别系统平台V1.0安装使用说明V1.1',
                    downUrl: '/download/software/linx-identity-auth/42/凝思多因子鉴别系统平台V1.0安装使用说明V1.1.pdf',
                    md5: 'ea44cb8511281cbf70f46e2f9ac54a19',
                    time: '2021-12-30',
                },
                {
                    name: '凝思多因子鉴别系统平台V1.0（适用于凝思安全操作系统V6.0.42 X86_64）R1.0.0发布说明',
                    downUrl: '/download/software/linx-identity-auth/42/凝思多因子鉴别系统平台V1.0（适用于凝思安全操作系统V6.0.42 X86_64）R1.0.0发布说明.pdf',
                    md5: 'd439156c1a69eff5e5c868d1107941e4',
                    time: '2021-12-30',
                },
			]
		},
		{
			tittle:'其他',
			text:[
                {
                    name: '映美LQ-350K打印机配置说明（适用于凝思安全操作系统V6.0.60 X86_64）',
                    downUrl: '/download/document/映美LQ-350K/Linx6.0系统映美LQ-350K打印机配置.pdf',
                    md5: '82268f4e6f1f3985dfc437ae5bf2a5a1',
                    time: '2020-05-22',
                },
			]
		},
	],
};
const mutations = {
	getNowUrls(state, url) {
        state.nowUrls = [...state.nowUrls, url]
    },
	removeNowUrl(state, url) {
		const list = state.nowUrls.filter(item => item !== url)
        state.nowUrls = list
    },
	getNowUrl(state, url) {
        state.nowUrl = url
    },
	getVerShow(state, ver) {
		state.verShow = ver
	}
};
const actions = {};
const getters = {};
export default {
	state,
	mutations,
	actions,
	getters
}
