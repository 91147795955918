<template>
	<div>
		<div id="boxTittle3">
			<div id="tittle3">
			</div> 
		</div> 
		<div class="font" id="fontPdetail">
			<div class="fontBox">
				<div class="textBox">
					<h2>凝思安全DNS服务器</h2>
					<p>凝思安全DNS服务器基于DNS应用系统的安全机制包括DNSSEC机制，CHROOT机制，TSIG签名机制，限制域名请求来源，限制域名动态更新，SSL加密的B/S远程配置和状态监视；基于操作系统核心的安全机制，即包括强制访问控制机制（MAC）、强制行为控制机制（MBC）、强制能力控制机制（MCC）和访问控制列表（ACL）。</p>
				</div>
				<div class="textBox textBoxBackground">
					<h2>以下是凝思安全DNS服务器产品特点：</h2>
					<!-- <FeatureBox :feature="feature"></FeatureBox> -->
					<div class="featureBox">
						<div class="featureText5">
							<div>
								<div class="text">
									<h4>通用性</h4>
									<p>可以解析标准的英文域名，支持各相关rfc和标准的要求；</p>
									<p>对于汉语拼音方式的域名按照英文方式处理，按照标准方式解析域名；</p>
									<p>对于中英文混合域名认为是中文域名，按照中文习惯方式解析地址。</p>
								</div>
								<div class="text">
									<h4>易维护性</h4>
									<p>B/S结构方式实现远程域名服务器远行状态的图形监视，远程配置系统数据传输使用基于SSL的加密通道。</p>
								</div>
								<div class="text">
									<h4>支持强制能力控制</h4>
									<p>消除系统中不受限制的进程，只赋予每个特权进程能够完成其功能的最小能力，实现“最小特权”原则。</p>
								</div>
								<div class="text">
									<h4>支持四权分立的管理</h4>
									<p>即基于系统管理员，安全管理员，网络管理员，审计管理员的系统管理。</p>
								</div>
								<div class="text">
									<h4>支持磁盘冗余容错</h4>
									<p>支持RAID 技术，能实现SCSI盘的双盘镜像，更换故障硬盘后，支持自动重建盘组，恢复盘组状态。</p>
								</div>
								<div class="text">
									<h4>热备与互备</h4>
									<p>支持RAID 技术，能实现SCSI盘的双盘镜像，更换故障硬盘后，支持自动重建盘组，恢复盘组状态。</p>
								</div>
							</div>
							<div>
								<div class="text">
									<h4>易管理性</h4>
									<p>DNS系统提供B/S结构的远程配置系统；</p>
									<p>可实现对DNS系统参数、区文件参数配置，实现添加、修改和删除资源记录功能；</p>
									<p>界面采用中文显示。</p>
								</div>
								<div class="text">
									<h4>强制运行控制机制</h4>
									<p>保护和监视服务器中运行的主体，保证进程主体以恰当的方式对外提供服务。</p>
								</div>
								<div class="text">
									<h4>访问控制列表</h4>
									<p>提供细粒度的访问控制，能够以特定用户或特定组为单位的访问许可分配，从而防止文件许可的不必要扩散。</p>
								</div>
								
								<div class="text">
									<h4>提供安全增强服务</h4>
									<p>系统的所有对外服务均要基于核心操作系统机制进行特别的安全增强。</p>
									<p>包括，SSH提供加密s远程外壳；</p>
									<p>DNS服务提供域名与IP地址间的相互转换；</p>
									<p>安全网页服务Web；</p>
									<p>电子邮件的接收和转发（安全邮件服务EMAIL)；

代理服务（安全代理服务PROXY；</p>
									<p>数据库的查询与管理（安全SQL数据库服务 MySQL）。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textBox">
					<h2>通用安全服务器平台种类和特点</h2>
					<div class="grayBlock">
						<h4>安全文件共享平台</h4>
						<p>支持操作系统固化技术；灵活实现Windows与Linux之间的文件共享；支持共享打印机。</p>
						<h4>安全CA平台</h4>
						<p>支持双机热备功能实现CA服务热切换；支持安全通信，各子系统之间通信采用SSL连接；支持吉大正元、格尔和兴唐等CA软件。</p>
						<h4>安全Web平台</h4>
						<p>支持双机热备功能；对Web服务进行SSL/TLS网络加密传输；可定制固化的操作系统；提供脚本程序分析Web服务日志文件。</p>
						<h4>安全数据库平台</h4>
						<p>采用双机热备机制确保系统服务不中断；提供自启动服务、邮件和声音报警机制；兼容国内外主流数据库软件。</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'SecureServer',
	data() {
		return {
			feature:[
        		{
					id:'feature29',
        			title:'通用性',
        			text:[
        				'可以解析标准的英文域名，支持各相关rfc和标准的要求；',
        				'对于汉语拼音方式的域名按照英文方式处理，按照标准方式解析域名；',
        				'对于中英文混合域名认为是中文域名，按照中文习惯方式解析地址。',
        			],
        		},
        		{
					id:'feature30',
        			title:'易管理性',
        			text:[
        				'DNS系统提供B/S结构的远程配置系统；',
        				'可实现对DNS系统参数、区文件参数配置，实现添加、修改和删除资源记录功能；',
        				'界面采用中文显示。',
        			],
        		},
        		{
					id:'feature31',
        			title:'易维护性',
        			text:[
        				'B/S结构方式实现远程域名服务器远行状态的图形监视，远程配置系统数据传输使用基于SSL的加密通道。',
        			],
        		},
        		{
					id:'feature31',
        			title:'强制运行控制机制',
        			text:[
        				'保护和监视服务器中运行的主体，保证进程主体以恰当的方式对外提供服务。',
        			],
        		},
        		{
					id:'feature32',
        			title:'支持强制能力控制',
        			text:[
        				'消除系统中不受限制的进程，只赋予每个特权进程能够完成其功能的最小能力，实现“最小特权”原则。',
        			],
        		},
        		{
					id:'feature33',
        			title:'访问控制列表',
        			text:[
        				'提供细粒度的访问控制，能够以特定用户或特定组为单位的访问许可分配，从而防止文件许可的不必要扩散。',
        			],
        		},
        		{
					id:'feature34',
        			title:'支持四权分立的管理',
        			text:[
        				'即基于系统管理员，安全管理员，网络管理员，审计管理员的系统管理。',
        			],
        		},
        		{
					id:'feature35',
        			title:'提供安全增强服务',
        			text:[
        				'系统的所有对外服务均要基于核心操作系统机制进行特别的安全增强。',
        				'包括，SSH提供加密远程外壳；',
        				'DNS服务提供域名与IP地址间的相互转换；',
        				'安全网页服务Web；',
        				'电子邮件的接收和转发（安全邮件服务EMAIL)；',
        				'代理服务（安全代理服务PROXY；',
        				'数据库的查询与管理（安全SQL数据库服务 MySQL）。',
        			],
        		},
        		{
					id:'feature36',
        			title:'支持磁盘冗余容错',
        			text:[
        				'支持RAID 技术，能实现SCSI盘的双盘镜像，更换故障硬盘后，支持自动重建盘组，恢复盘组状态。',
        			],
        		},
        		{
					id:'feature37',
        			title:'热备与互备',
        			text:[
        				'支持RAID 技术，能实现SCSI盘的双盘镜像，更换故障硬盘后，支持自动重建盘组，恢复盘组状态。',
        			],
        		},
    		],
    	}
	},
}
</script>
<style scoped>

</style>
