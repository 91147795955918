<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<div class="font" id="fontNoBorder">
			<Shrink :shrinkList="shrinkList"></Shrink>
			<CaseDisplay :caseDisplay="caseDisplay" :path="path"></CaseDisplay>
		</div>
	</div>
</template>
<script>
export default {
	name:'PoverIndustry',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '电力行业',
			path: '/Solutions/PoverIndustryDetails',
			shrinkList:[
				'中国电网由国家电网公司（下称：国网）和中国南方电网公司（下称：南网）的电力网组成，构成了全世界规模最大、结构最复杂的工业应用系统。电力调度系统是电网安全、优质、经济运行的重要保障。',
				'国网提出电力调度系统全国产化目标，作为电力调度自动化系统的底层平台，国网及南网对操作系统的安全性、稳定性和可用性提出了很高的要求，必须能够支撑整个电力调度系统各子系统安全稳定的运行。凝思成为首选操作系统厂商，参加中国电科院、国网电科院的移植工作，将应用程序从Tru64和HPUX平台向凝思安全操作系统平台移植，同时还参与了智能电网调度控制系统（D5000系统）中管理模块的联合开发工作。',
				'凝思还与国内多家知名服务器硬件厂商如联想、华为、浪潮、曙光以及存储厂商如华为、H3C等通力合作，对系统和硬件的兼容性、稳定性进行了全面深入的测试，并针对电力调度自动化系统可用性、安全性方面的特殊需求设计出相应的解决方案，为国家电网提供了安全可靠的底层服务平台，全方位支持电力调度自动化系统的稳定可靠运行。',
				'至今，凝思安全操作系统全面部署在国调中心、华北、东北、华东、华中、西北和西南6大分中心、以及国网所属全国各省、直辖市、100多个地市调度中心，覆盖国、网、省、地市级调度系统， 总装机量超过20万套，实现了中国电网调度系统的业务支撑。',
			],
			caseDisplay:[
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'电网调度系统',
					text:'凝思成为电力调度自动化系统的首选厂商参加中国电科院、国网电科院的移植工作'
				},
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'配网自动化',
					text:'凝思为业内主要配网应用厂家定制开发安全操作系统产品'
				},
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'变电站',
					text:'凝思安全操作系统在全国范围内的变电站大量应用'
				},
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'发电集团',
					text:'凝思安全操作系统在全国九大发电集团均有部署'
				},
				{
					backgroundImgUrl:require('../../../components/CaseDisplay/images/案例列表背景.png'),
					IconUrl:require('../../../components/CaseDisplay/images/懒加载-案例.png'),
					IconUrl2:require('../../../components/CaseDisplay/images/懒加载-案例-1080.png'),
					tittle:'国网信息化',
					text:'凝思为电网信息通讯系统的多个项目提供产品和服务'
				},
			]
		}
	}
}
</script>
<style scoped>

</style>
