<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<div
			class="font"
			id="fontNoMax"
			style="width:100%;margin-left:0;"
		>
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
            <div
				class="Listcue"
				v-for="(cell,index2) in CompatibleList"
				:key="index2 + '-2'"
				v-show="cuetitle == index2"
			>
				<h2 style="user-select: none;color:#fff"> f </h2>
            	<div>
            		<b>分类：</b><span @click="cueShow = !cueShow" :class="cueShow ? 'arrowturn2' : 'arrowturn1'" v-html="cell.title"></span>
                	<ul v-show="cueShow || bodyWidthMut <= 750">
                		<li 
                			v-for="(cell3,index3) in CompatibleList"
                			:key="index3 + '-3'"
                			v-html="cell3.title"
                			:class="{liInPage:index3 == cuetitle}"
                			@click="cuetitle = index3,cueShow = !cueShow,tablephshow=1"
                		></li>
                	</ul>
            	</div>
            </div>
             <div
             	class="ListTable"
             	v-for="(detail,index4) in CompatibleList"
             	:key="index4 + '-4'"
				 v-show="cuetitle == index4"
             >
				<table>
					<tr>
						<th>序号</th>
						<th>分类</th>
						<th>厂商名称</th>
						<!-- <th v-show="detail.title == '安全类硬件'">硬件类型</th> -->
						<th>产品名称</th>
						<!-- <th v-show="detail.title == 'CPU'">认证型号</th> -->
						<th>操作系统（凝思）</th>
						<th>认证时间</th>
						<th>备注</th>
					</tr>
					<tr 
						v-for="(text,index5) in detail.text"
						:key="index5 + '-5'"
						v-show="bodyWidthMut > 750 || index5 < 15*tablephshow"
					>
						<td>{{index5 + 1}}</td>
						<td>{{text.category}}</td>
						<td>{{text.CertifiedManufacturer}}</td>
						<!-- <td v-show="detail.title == '安全类硬件'">{{text.HardwareType}}</td> -->
						<td v-html="text.CertifiedProduct"></td>
						<!-- <td v-show="detail.title == 'CPU'">{{text.CertifiedModel}}</td> -->
						<td v-html="text.AuthenticationSystem"></td>
						<td>{{text.time}}</td>
						<td v-html="text.remark"></td>
					</tr>
				</table>
			</div>
			<div class="more" @click="tablephshow = tablephshow + 1" v-show="moreshow">加载更多</div>
		</div>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
export default {
	name:'CompatibleList',
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			natittle1: this.$attrs.tittle,
			natittle2: '兼容列表',
			natittle3: '生态兼容列表',
			cueShow: false,
			cuetitle: 0,
			icon: require('../images/兼容列表.png'),
			tablephshow: 1,
			nowtableLe: 0,
			moreshow: 1,
		}
	},
	computed:{
		...mapState({
			CompatibleList:(state)=>state.Ecological.CompatibleList,
		})
	},
	mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
	watch:{
		cuetitle:{
			immediate:true,
			handler(){
				this.nowtableLe = this.CompatibleList[this.cuetitle].text.length
				if(this.nowtableLe <= this.tablephshow*15){
					this.moreshow = 0
				} else {
					this.moreshow = 1
				}
			}
		},
		tablephshow:{
			immediate:true,
			handler(){
				this.nowtableLe = this.CompatibleList[this.cuetitle].text.length
				if(this.nowtableLe <= this.tablephshow*15){
					this.moreshow = 0
				} else {
					this.moreshow = 1
				}
			}
		},
		bodyWidthMut:{
			immediate:true,
			handler(val){
				this.cueShow = false;
			}
		}
	},
}
</script>
<style scoped>
.Listcue{
	position:relative;
	width:calc(100% - 64px);
	margin:6px auto 18px;
}
.Listcue h2{
	margin:0;
	line-height:40px;
}
.Listcue h2>>>br{
	display: none;
}
.Listcue div{
	position: absolute;
	width:208px;
	height:38px;
	border-bottom:1px solid #c2c2c2;
	right:0;
	top:0;
	z-index:999;
}
.Listcue div b{
	font-weight:400;
	color: #565d69;
	margin:0 10px;
	line-height:39px;
	pointer-events:none;
	font-size: 16px;
}
.Listcue div span{
	display:inline-block;
	width:120px;
	color: #262b33;
	line-height:39px;
	font-size: 14px;
	cursor:pointer;
}
.Listcue div span>>>br{
	display:none;
}
.Listcue div span:after{
	content:'';
	display:block;
	position: absolute;
	right:0;
	top:0;
	width:39px;
	height:39px;
	background:url('../../../images/收起.png') no-repeat center;
	background-size: 11px 7px;
	transition: transform 0.3s;
}
.arrowturn1:after{
	transform:rotate(-90deg);
}
.arrowturn2:after{
	transform:rotate(0);
}
.Listcue div ul{
	position: absolute;
	right:0;
	top:38px;
	border:1px solid #c2c2c2;
	box-shadow:rgba(05,05, 05, 0.1) 0px 2px 8px;
}
.Listcue div ul li{
	width:110px;
	padding:15px 24px;
	color: #262b33;
	line-height:47px;
	line-height:18px;
	font-size:14px;
	background-color:#fff;
	transition: background-color 0.15s;
	cursor:pointer;
}
.Listcue div ul li br{
	display:none;
}
.Listcue div ul li:hover{
	background-color:#f5f5f5;
	
}
.Listcue div ul .liInPage{
	color:#dd1d29;
	background-color:#f5f5f5;
}

.ListTable{
	width:100%;
	max-height:610px;
	overflow-x:auto;
	overflow-y:auto;
	border-top:1px solid #e0e0e0;
}
.ListTable table{
	min-width:100%;
}
.ListTable table tr:nth-child(2n+1){
	background-color:#f5f5f5;
}
.ListTable table tr th{
	border-left:1px solid #e0e0e0;
	border-bottom:1px solid #e0e0e0;
	text-align:center;
	font-size: 16px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 54px;
	color: #262b33;
	padding:0 24px;
	background-color:#f5f5f5;
	white-space:nowrap;
}
.ListTable table tr td{
	border-left:1px solid #e0e0e0;
	text-align:center;
	font-size: 14px;
	letter-spacing: 0px;
	font-weight: 400;
	line-height: 22px;
	color: #262b33;
	padding:16px 24px;
	white-space:nowrap;
}
.ListTable table tr th:first-child, .ListTable table tr td:first-child{
	border-left:0px solid #e0e0e0;
	width:32px;
}
.more{
	display:none;
}
/*手机端*/
@media only screen and (max-width:750px){
	.Listcue{
		margin: 6px -0.5px 28px;
		width:100%;
	}
	.Listcue h2{
		display: none;
	}
	.Listcue div{
		position:relative;
		width:100%;
		height:auto;
		padding:0;
		left: 0;
		background-color:#fff;
		border-bottom:none;
		padding-top:1px;
		overflow-x:auto;
	}
	.Listcue div::-webkit-scrollbar{
		display:none;
	}
	.Listcue div b, .Listcue div span, .Listcue div span:after{
		display:none;
	}
	.Listcue div ul{
		display:flex;
		position:relative;
		white-space:nowrap;
		background-color:#f5f5f5;
		top:0;
		border:0px solid #c2c2c2;
		/* border-top:0.5px solid #c2c2c2; */
		box-shadow:rgba(05,05, 05, 0.1) 0px 0px 0px;
	}
	.Listcue div ul:before{
		position: absolute;
		content: '';
		height: 0.5px;
		width: 100%;
		background-color: #c2c2c2;
	}

	.Listcue div ul li{
		position:relative;
		display:inline-block;
		vertical-align:top;
		flex:0 0 56px;
		height:auto;
		padding: 13.5px 15px;
		margin-right:-2px;
		font-size: 14px;
		line-height: 17px;
		white-space:nowrap;
		background-color:#f5f5f5;
		border-top:0.5px solid #c2c2c2;
	}
	.Listcue div ul li br{
		display:inline-block;
	}
	.Listcue div ul .liInPage{
		color: #262b33;
		font-weight:500;
	}
	.Listcue div ul li:hover:before, .Listcue div ul .liInPage:before{
		position: absolute;
		content:'';
		display:block;
		width:30px;
		height:1.5px;
		background-color:#dd1d29;
		left:15px;
		top:-1px;
	}
	.ListTable{
		width:calc(100% - 30px);
		overflow-x:auto;
		overflow-y:hidden;
		max-height:1000000px;
		margin:auto;
		border:1px solid #e0e0e0;
	}
	.ListTable table{
		min-width:100%;
		border:none;
	}
	.ListTable table tr th{
		border-left:0.5px solid #e0e0e0;
		border-top: none;
		text-align:center;
		font-size: 12px;
		letter-spacing: 0px;
		font-weight: 500;
		line-height: 27px;
		color: #262b33;
		padding:0 7px;
		background-color:#f5f5f5;
		white-space:nowrap;
	}
	.ListTable table tr td{
		border-left:0.5px solid #e0e0e0;
		border-top:0.5px solid #e0e0e0;
		text-align:center;
		font-size: 12px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 12px;
		color: #262b33;
		padding:8px 15px;
		white-space:nowrap;
	}
	.ListTable table tr th:first-child, .ListTable table tr td:first-child{
		width:28px;
	}
	.more{
		display:block;
		line-height:39px;
		border:0.5px solid #e0e0e0;
		margin:12px 14.5px 0;
		background-color:#f5f5f5;
		text-align:center;
		font-size: 13px;
		letter-spacing: 0px;
		font-weight: 500;
		color:#0072ce;
	}
	.more:after{
		content:'';
		position:relative;
		display:inline-block;
		margin-left:4px;
		width:13px;
		height:13px;
		top:2px;
		background:url('../images/加载更多.png') no-repeat center;
		background-size:9.5px 9.5px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.Listcue{
		margin: 15px -1px 70px;
		width:100%;
	}
	.Listcue h2{
		display: none;
	}
	.Listcue div{
		position:relative;
		width:100%;
		height:auto;
		padding:0;
		left: 0;
		background-color:#fff;
		border-bottom:none;
		padding-top:4px;
		overflow-x:scroll;
	}
	.Listcue div::-webkit-scrollbar{
		display:none;
	}
	.Listcue div b, .Listcue div span, .Listcue div span:after{
		display:none;
	}
	.Listcue div ul{
		display:flex;
		position:relative;
		white-space:nowrap;
		background-color:#fff;
		top:0;
		border:0px solid #c2c2c2;
		box-shadow:rgba(05,05, 05, 0.1) 0px 0px 0px;
	}

	.Listcue div ul li{
		position:relative;
		display:inline-block;
		vertical-align:top;
		flex:0 0 160px;
		height:auto;
		padding: 34px 44px;
		margin-right:-4px;
		font-size: 40px;
		line-height: 50px;
		white-space:nowrap;
		background-color:#f5f5f5;
		border-top:2px solid #c2c2c2;
	}
	.Listcue div ul li br{
		display:inline-block;
	}
	.Listcue div ul .liInPage{
		color: #292929;
		font-weight:500;
	}
	.Listcue div ul li:hover:before, .Listcue div ul .liInPage:before{
		position: absolute;
		content:'';
		display:block;
		width:92px;
		height:6px;
		background-color:#dd1d29;
		left:44px;
		top:-4px;
	}
	.ListTable{
		width:calc(100% - 88px);
		overflow-x:auto;
		overflow-y:hidden;
		margin:auto;
		border:2px solid #e0e0e0;
	}
	.ListTable table{
		min-width:100%;
		border:none;
	}
	.ListTable table tr th{
		border-left:2px solid #e0e0e0;
		border-top: none;
		text-align:center;
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 500;
		line-height: 150px;
		color: #292929;
		padding:0 40px;
		background-color:#f5f5f5;
		white-space:nowrap;
	}
	.ListTable table tr td{
		border-left:2px solid #e0e0e0;
		border-top:2px solid #e0e0e0;
		text-align:center;
		font-size: 40px;
		letter-spacing: 0px;
		font-weight: 400;
		line-height: 60px;
		color: #292929;
		padding:45px 40px;
		white-space:nowrap;
	}
	.ListTable table tr th:first-child, .ListTable table tr td:first-child{
		width:80px;
	}
	.more{
		display:block;
		line-height:116px;
		border:2px solid #e0e0e0;
		margin:30px 42px 0;
		background-color:#f5f5f5;
		text-align:center;
		font-size: 38px;
		letter-spacing: 0px;
		font-weight: 500;
		color:#0072ce;
	}
	.more:after{
		content:'';
		position:relative;
		display:inline-block;
		margin-left:12px;
		width:38px;
		height:38px;
		top:6px;
		background:url('../images/加载更多.png') no-repeat center;
		background-size:24px 25px;
	}
} */
</style>
