<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<!-- <Petrochemicals1 v-show="natittle3 == '石油化工'"></Petrochemicals1> -->
	</div>
</template>

<script>
// import Petrochemicals1 from './Petrochemicals1.vue';

export default {
	name:'PetrochemicalsDetails',
	// components:{
	// 	Petrochemicals1
    // },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	mounted(){
        if(this.natittle3 == '石油化工'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '石油化工'
        }
    },
}
</script>
<style scoped>

</style>
