import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//引入小仓库
import All from "./All";
import Home from "./Home";
import Ecological from "./Ecological";
import News from "./News";
import Honor from "./Honor";
import Jobs from "./Jobs";
import Navigation from "./Navigation";
import Question from "./Question";
import Products from "./Products";
import Service from "./Service";

export default new Vuex.Store({
	modules:{
		All,
		Home,
		Ecological,
		News,
		Honor,
		Jobs,
		Navigation,
		Question,
		Products,
		Service
	}
});
