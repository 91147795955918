<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<NationalRailwayGroup v-show="natittle3 == '国铁集团'"></NationalRailwayGroup>
	</div>
</template>

<script>
import NationalRailwayGroup from './NationalRailwayGroup.vue';

export default {
	name:'RailDetails',
	components:{
		NationalRailwayGroup
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	created(){
        if(this.$route.query.tittle == '轨道交通'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '轨道交通'
        }
    },
}
</script>
<style>
#fontNoBorder > #tittlrImgRail1{
	background:url('../images/标题背景-国铁.jpg') no-repeat center -427px;
	background-size: 1245px 811px;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
	#fontNoBorder > #tittlrImgRail1{
		background:url('../images/标题背景-国铁.jpg') no-repeat center bottom;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > #tittlrImgRail1{
		background:url('../images/标题背景-国铁.jpg') no-repeat -262px bottom;
		background-size: 1245px 811px;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder > #tittlrImgRail1{
		background:url('../images/标题背景-国铁.jpg') center -123.5px;
		background-size: 622.5px 405.5px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder > #tittlrImgRail1{
		background:url('../images/标题背景-国铁.jpg') no-repeat center -58px;
		background-size: 1245px 811px;
	}
} */
</style>
