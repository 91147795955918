<!-- 首页-回到顶部 -->
<template>
	<div class="count-flop" :key="compKey">
        <div :class="item != '.' && item != ',' ?'count-flop-box':'count-flop-point'" v-for="(item, index) in value" :key="index">
            <div v-if="item == ','" class="count-flop-content">,</div>
            <div v-else-if="item == '.'" class="count-flop-content">.</div>
            <div v-else class="count-flop-content" :class="{['rolling_' + item] : numberChange}">
                <div v-for="(item2,index2) in numberList" :key="index2" class="count-flop-num">{{item2}}</div>
            </div>
        </div>
        <div v-if="fit" class="count-flop-unit">%</div>
    </div>
</template>

<script>
export default {
    name: 'ScrollNumber',
    data() {
        return {
        	navShow:document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset,
        	clientHeight:'',
        	numberChange: false,
            value: [],
            numberList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            compKey: 0
        };
    },
    props: ["val","fit"],
    watch: {
        val() {
            this.value = this.val.toString().split("");
            this.compKey += 1;
        }
    },
    mounted () {
        window.addEventListener('scroll',() => {
        	this.navShow = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
        	if(this.navShow >=300){
				this.numberChange = true;
			}
        });
        this.clientHeight = `${document.documentElement.clientHeight}`;
		if(this.clientHeight > 1000 || this.navShow >=3000){
			this.numberChange = true;
		}
	},
    created() {
        this.value = this.val.toString().split("");
    },
}
</script>

<style scoped>
/*pc*/
	.count-flop {
		position:relative;
        display: inline-block;
    }
    .count-flop > div {
        position: relative;
        display: inline-block;
        overflow: hidden;
        height: 57px;
    }

    .count-flop-box {
        width: 30px;
        height: 57px;
        line-height: 57px;
    }

    .count-flop-point {
        width: 10px;
        color: #dd1d29;
        font-size: 52px;
        line-height: 57px;
    }

    .count-flop-content {
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        animation-fill-mode: forwards !important;
    }
    .count-flop-num{
    	line-height: 57px;
        color: #dd1d29;
        font-size: 52px;
        font-weight: 600;
    }
    .count-flop > .count-flop-unit {
    	margin:0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 36px;
        font-weight:900;
        color: #dd1d29;
    }

    .rolling_1 {
        animation: rolling_1 3.6s ease;
    }

    @keyframes rolling_1 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-10%);
        }
    }

    .rolling_2 {
        animation: rolling_2 3.6s ease;
    }

    @keyframes rolling_2 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-20%);
        }
    }

    .rolling_3 {
        animation: rolling_3 3.6s ease;
    }

    @keyframes rolling_3 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-30%);
        }
    }

    .rolling_4 {
        animation: rolling_4 3.6s ease;
    }

    @keyframes rolling_4 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-40%);
        }
    }

    .rolling_5 {
        animation: rolling_5 3.6s ease;
    }

    @keyframes rolling_5 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-50%);
        }
    }

    .rolling_6 {
        animation: rolling_6 3.6s ease;
    }

    @keyframes rolling_6 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-60%);
        }
    }

    .rolling_7 {
        animation: rolling_7 3.6s ease;
    }

    @keyframes rolling_7 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-70%);
        }
    }

    .rolling_8 {
        animation: rolling_8 3.6s ease;
    }

    @keyframes rolling_8 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-80%);
        }
    }

    .rolling_9 {
        animation: rolling_9 3.6s ease;
    }

    @keyframes rolling_9 {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-90%);
        }
    }
/*手机端*/
@media only screen and (max-width:750px){
    .count-flop > div {
        height: 40px;
    }

    .count-flop-box {
        width: 20px;
        height: 40px;
        line-height: 40px;
    }

    .count-flop-point {
        width: 6px;
        font-size: 30px;
        line-height: 40px;
    }
    .count-flop-num{
    	line-height: 40px;
        font-size: 36px;
    }
    .count-flop > .count-flop-unit {
        height: 40px;
        line-height: 50px;
        font-size: 20px;
    }
}

</style>
