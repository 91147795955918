<!-- 法律声明 -->
<template>
    <div class="fontmain fontmainNotitle1"> 
        <div class="font" id="font1">
            <div class="title">
                <h1>
                    <span>法律声明</span>
                </h1>
            </div>
            <div class="details">
                <h2>网站使用</h2>
                <p>除非本声明另有规定，用户在使用本网站之内容时，应遵守下列约定:</p>
                <p class="point">未经凝思软件书面许可，不得复制、留存、编辑、修改、组合、镜像，转发、出版、演示、抄袭本网站之任何内容或将本网站之任何内容用于任何未经凝思软件允许的商业目的。</p>
                <p class="point">摘录或转载本网站原创稿件时，须经凝思软件书面许可并在明显位置注名信息来源:凝思软件官网，摘录或转载应确保反映原文的真实含义。对擅自篡改或歪曲原文真实含义的行为，以及未经凝思软件书面许可擅自摘录或转载本网站原创稿件且不注明信息来源的行为，凝思软件保留采取进一步法律措施的权利。如需摘录或转载本网站包含的由其它组织、机构或个人提供的信息内容时，请与相关权利人取得联系，与之相关的任何事务以及法律责任均与本网站和凝思软件无关。</p>
                <p class="point">任何时间，任何情况下，凝思软件均有权拒绝任何用户访问、使用本网站。</p>
                <p class="point">如果凝思软件确定用户行为违法或有损本网站和凝思软件的利益，则凝思软件将保留追究相关责任企业或个人的权利。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegalNotices',
}
</script>
