<template>
	<div class="fontmain fontmainMargin">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<Offices v-show="natittle3 == '政府机关'"></Offices>
	</div>
</template>

<script>
import Offices from './Offices.vue';

export default {
	name:'GovernmentDetails',
	components:{
		Offices
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: this.$route.query.tittle,
		}
	},
	mounted(){
        if(this.$route.query.tittle == '政府机关'){
        	this.natittle2 = false
        } else {
        	this.natittle2 = '政府机关'
        }
    },
}
</script>
<style>
#fontNoBorder > #tittlrImgGovernment1{
	background:url('../images/标题背景-政府.jpg') no-repeat center -150px;
	background-size: 1173px 880px;
}
@media only screen and (max-width:1170px){
	#fontNoBorder > #tittlrImgGovernment1{
		background:url('../images/标题背景-政府.jpg') no-repeat center -100px;
		background-size:100% auto;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#fontNoBorder > #tittlrImgGovernment1{
		background:url('../images/标题背景-政府.jpg') no-repeat center -76px;
		background-size:100% auto;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#fontNoBorder > #tittlrImgGovernment1{
		background:url('../images/标题背景-政府.jpg') no-repeat center;
		background-size: 100% auto;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	#fontNoBorder > #tittlrImgGovernment1{
		background:url('../images/标题背景-政府.jpg') no-repeat center 0px;
		background-size: auto 746px;
	}
} */
</style>
