<!-- 首页 -->
<template>
    <div>
		<Banner/>
		<LinxProducts :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1"/>
		<Solutions :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1"/>
	<div id="ecological">	<EcologicalCooperation2 :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1"/>
		<EcologicalPartners :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1"/>
	</div>
		<ServiceAndSupport/>
		<CompanyHighlights :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" data-wow-duration="1.2s" data-wow-delay="0s" data-wow-offset="150"  data-wow-iteration="1"/>
    </div>
</template>

<script>
import Banner from './Banner';
import LinxProducts from './LinxProducts';
import Solutions from './Solutions';
import EcologicalCooperation from './EcologicalCooperation';
import EcologicalCooperation2 from './EcologicalCooperation2';
import EcologicalPartners from './EcologicalPartners';
import ServiceAndSupport from './ServiceAndSupport';
import CompanyHighlights from './CompanyHighlights';

export default {
    name: 'Home',
    components:{
		Banner,
		LinxProducts,
		Solutions,
		ServiceAndSupport,
		EcologicalCooperation,
		EcologicalCooperation2,
		EcologicalPartners,
		ServiceAndSupport,
		CompanyHighlights
    },
    data() {
      return {
        bodyWidthMut: document.body.clientWidth,
      }
    },
	mounted() {
		window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
		this.$nextTick(() => {
			new this.$wow.WOW().init();
		});
	},


}
</script>

<style>
	a{
		text-decoration: none;
	}
	/*pc*/
	.box{
		position:relative;
		width: 100%;
		padding: 24px auto 0;
	}
	.title-font1{
		font-weight: 500;
		width: 100%;
		color: #262b33;
		font-size: 40px;
		text-align:center;
		letter-spacing:2px;
		vertical-align: middle;
		line-height: 216px;
		padding-top: 72px;
	}
	#title-font1-box3{
		padding-top: 42px;
	}
	.title-font1 div{
		width: 40px;
		height: 3px;
		background-color: #dd1d29;
		position: relative;
		/* left: 0;
		top: 0; */
		left:50%;
		bottom: 56px;
		transform: translateX(-50%);
	}
    .biglink{
        text-decoration: none;
        position: relative;
        display:block;
        width:210px;
        line-height:57px;
        border-radius:2px;
        margin:32px auto 0px;
        background:#fff;
        text-align:center;
		border: 1px solid #8f8f8f;
        font-weight:500;
        letter-spacing: 0px;
        color: #292929;
        font-size: 16px;
        transition: all 0s;
    }
    .biglink::after{
        position: relative;
		display: inline-block;
		content: '';
		width: 27px;
        height: 24px;
		background: url(./images/箭头-黑色-向右.png) no-repeat right center;
		background-size: 14px 8px;
        top: 5px;
    }
    .biglink:hover{
        background:#dd1d29;
		border: 1px solid #dd1d29;
        color: #fff;
		transition: all 0s;
    }
    .biglink:hover::after{
		background: url(./images/箭头-白色-向右.png) no-repeat right center;
		background-size: 14px 8px;
    }
    #ecological{
    	background-image: linear-gradient(to top, rgba(14,17,20,0.06),rgba(255,255,255,0.06) calc(100% - 199px));
    	padding-bottom:85px;
    	margin-bottom:-35px;
    }
/*pad横屏*/
@media only screen and (max-width:1170px){
    .title-font1{
        font-size: 36px;
        letter-spacing:2px;
        line-height: 196px;
        padding-top:0;
    }
    #title-font1-box3{
		padding-top: 0px;
	}
    .title-font1 div{
        bottom: 49px;
    }
	.biglink{
	    margin:48px auto 0px;
	}
	#ecological{
    	background-image: linear-gradient(to top, rgba(14,17,20,0.06),rgba(255,255,255,0.06) calc(100% - 199px - 198px));
    }
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	#ecological{
    	background-image: linear-gradient(to top, rgba(14,17,20,0.06),rgba(255,255,255,0.06) calc(100% - 199px - 242px));
    }
}
/*手机端*/
@media only screen and (max-width:750px){
	.box{
		padding: 18px auto 0;
	}
	.title-font1{
		font-size: 26px;
		line-height: 136px;
		padding-top: 57px;
	}
	#title-font1-box3{
		padding-top: 9px;
	}
	.title-font1 div{
		width: 28px;
		bottom: 33px;
	}
	.biglink, .biglink:hover{
        width:232px;
        line-height:49px;
        border-radius:1px;
		border-width: 1px;
        margin:28px auto 0px;
        font-size: 13px;
    }
    .biglink::after,.biglink:hover::after{
		width: 23.5px;
        height: 19px;
		background-size: 13px 7.5px;
        top: 4px;
    }
    #ecological{
    	background-image: linear-gradient(to top, rgba(14,17,20,0),rgba(255,255,255,0) calc(100% - 199px - 242px));
    	padding-bottom:0px;
    	margin-bottom:0px;
    }
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	.box{
		padding: 36px auto 0;
	}
	.title-font1{
		font-size: 74px;
		line-height: 400px;
	}
	.title-font1 div{
		width: 82px;
		height:7px;
		bottom: 96px;
	}
	.biglink, .biglink:hover{
		width:669px;
		line-height:143px;
		border-radius:2px;
		border-width: 2px;
		margin:80px auto 0px;
		font-size: 42px;
	}
    .biglink::after,.biglink:hover::after{
		width: 70px;
        height: 61px;
		background-size: 38px 22px;
        top: 13px;
    }
}   */
</style>
