<template>
	<div id="cue" ref="cue">
        <div class="catalog">
            <div class="side-catalog">
                <div class="line"></div>
                <div class="side-bar">
                    <div class="circle-start"></div>
                    <div class="circle-end"></div>
                </div>
                <div class="catalog-scroller" >
                    <dl id="catalog-list">
                        <dt
                            class="catalog-title"
                            v-for="(itemnews,indexnews) in newsDataList" 
                            :key="indexnews + '-1'" 
                        >
                            <div class="pointer" :id="'p' + itemnews.year"></div>
                            <a @click="goAnchor('#year' + itemnews.year)" class="title-link" :id="'a' + itemnews.year">{{itemnews.year}}</a>
                        </dt>
                    </dl>
                </div>
                <div class="right-wrap">
                    <a :id="catalogTop == 0 ? 'under-up' : 'go-up'" @click="catalogMove(1)"></a>
                    <a :id="catalogTop == (5 - catalogLength) ? 'under-down' : 'go-down'" @click="catalogMove(0)"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name:'NewsCue',
    computed:{
		...mapState({
			newsDataList:(state)=>state.News.newsDataList,
		})
	},
    data() {
        return {
            catalogLength:0,
            catalogTop:0,
            cueH: 0,
            cueT: 0,
            hyear: this.$attrs.hyear
        }
    },
    mounted() {
		this.catalogLength = this.newsDataList.length;
        this.cueH = $("#cue").offset().top;//初始目录距离页面顶端距离
        this.cueT = this.$refs.cue.offsetTop;//目录距离内容区顶部的原始距离
        $('#p' + (2016 + this.catalogLength -1)).css({"background-color":"rgba(221,29,41,0.9)"});
        $('#a' + (2016 +this.catalogLength -1)).css({"color":"rgba(221,29,41,0.9)"});
        window.addEventListener("scroll",this.handleScroll,true);
        window.addEventListener("scroll",this.handleScroll2,true);
	},
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll,true);
    },
    methods:{
		goAnchor(id){
            document.querySelector(id).scrollIntoView({
                behavior:"smooth",
                block:"start",
            });
        },
        catalogMove(val){
            if(val == 1){
                if(this.catalogTop < 0){
                    this.catalogTop = this.catalogTop + 1;
                }
            } else if(val == 0) {
                if(this.catalogTop > (5 - this.catalogLength)){
                    this.catalogTop = this.catalogTop - 1;
                }
            }
        },
        handleScroll(){
			// 目录浮动规则
			// var move=document.getElementById("tittleCatalog");
            var pageY = $(document).height();//页面高度
            var scroH = document.documentElement.scrollTop;//滚动距离
            var cueN = 56;//目录浮动后距离导航栏的距离
            var navH = 60;//导航栏高度
            var butH = 130+422+8;//页面底部组件高度+内容区下边距
            var cueh = 190;//目录组件高度
            if(scroH<(this.cueH- navH - cueN)){
                $("#cue").css({"top":this.cueT});
            } else if(scroH > (pageY - butH - cueh - navH - cueN - this.cueT)){
                $("#cue").css({"top":pageY - butH - cueh - this.cueH});
            } else {
                $("#cue").css({"top":scroH - this.cueH + cueN + navH + this.cueT});
            }
        },
        handleScroll2(){
			var scroH = document.documentElement.scrollTop;
            for(var i=0;i<this.catalogLength;i++){
                if(i == (this.catalogLength - 1)){
                    if(this.hyear[i - 1]-scroH >= 10){
                        $('#p' + (2016 + i)).css({"background-color":"rgba(221,29,41,0.9)"});
                        $('#a' + (2016 +i)).css({"color":"rgba(221,29,41,0.9)"});
                        this.catalogTop = 0;
                    } else {
                        $('#p' + (2016 + i)).css({"background-color":"#cacbcc"});
                        $('#a' + (2016 + i)).css({"color":"#8d9198"});
                    }
                } else if (i == 0){
                    if(this.hyear[i]-scroH < 10){
                        $('#p' + (2016 + i)).css({"background-color":"rgba(221,29,41,0.9)"});
                        $('#a' + (2016 +i)).css({"color":"rgba(221,29,41,0.9)"});
                        this.catalogTop = 5 - this.catalogLength;
                    } else {
                        $('#p' + (2016 + i)).css({"background-color":"#cacbcc"});
                        $('#a' + (2016 + i)).css({"color":"#8d9198"});
                    }
                } else {
                    if(this.hyear[i - 1]-scroH >= 10 && this.hyear[i]-scroH < 10){
                        $('#p' + (2016 + i)).css({"background-color":"rgba(221,29,41,0.9)"});
                        $('#a' + (2016 +i)).css({"color":"rgba(221,29,41,0.9)"});
                        if(i <= 2){
                            this.catalogTop = 5 - this.catalogLength;
                        } else if ( i >= this.catalogLength - 3){
                            this.catalogTop = 0;
                        } else {
                            this.catalogTop = i + 3 - this.catalogLength;
                        }
                    } else {
                        $('#p' + (2016 + i)).css({"background-color":"#cacbcc"});
                        $('#a' + (2016 + i)).css({"color":"#8d9198"});
                    }
                }
            }
        },
	},
    watch:{
		catalogTop(){
            var move=document.getElementById("catalog-list");
            move.style.top = 20 + this.catalogTop*30 +'px'
        }
	}
}
</script>
<style scoped>
#cue{
    position: absolute;
    margin-left: 800px;
    top: 96px;
}
.side-catalog {
    position: relative;
    height: 190px;
    right: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
}
/* 线 */
.side-catalog .line {
    position: absolute;
    width: 1px;
    height: 190px;
    left: 5px;
    background-color: #cacbcc;
}
/* 开始与结束的小园点 */
.side-catalog .side-bar .circle-start {
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #fff;
    border: 2px solid #cacbcc;
    border-radius: 50%;
    z-index: 1;
}
.side-catalog .side-bar .circle-end {
    position: absolute;
    bottom: 0px;
    width: 7px;
    height: 7px;
    background-color: #fff;
    border: 2px solid #cacbcc;
    border-radius: 50%;
    z-index: 1;
}
/* 链接表单 */
.side-catalog .catalog-scroller #catalog-list {
    position: relative;
    top:20px;
    transition: top  0.25s;
    /*-moz-transition: top  0.25s;   Firefox 4 */
    /* -webkit-transition: top  0.25s; Safari 和 Chrome */
    /*-o-transition: top  0.25s;  Opera */ 
}
.side-catalog .catalog-scroller #catalog-list .catalog-title{  
    height: 30px;
    width: 60px;
    position: relative;
}
.side-catalog .catalog-scroller #catalog-list .catalog-title .pointer{
    width: 7px;
    height: 7px;
    background-color: #cacbcc;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.side-catalog .catalog-scroller #catalog-list .catalog-title .title-link{
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #8d9198;
    cursor:pointer;
}
.side-catalog .catalog-scroller #catalog-list .catalog-title .title-link:hover{
    color: rgba(221,29,41,0.9)!important;
}

/* 箭头 */
.side-catalog .right-wrap #go-up {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 31px;
    height: 20px;
    border-top: 3px solid #fff;
    background: url(../images/年份-向上.png) no-repeat;
    background-color: #fff;
    background-position: top center;
    cursor:pointer;
}
.side-catalog .right-wrap #under-up{
    position: absolute;
    top: 0px;
    right: 0px;
    border-top: 3px solid #fff;
    width: 31px;
    height: 20px;
    background: url(../images/年份-向上-不可用.png) no-repeat;
    background-color: #fff;
    background-position: top center;
    cursor: not-allowed;
}
.side-catalog .right-wrap #go-down {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 31px;
    height: 20px;
    border-bottom: 3px solid #fff;
    background: url(../images/年份-向下.png) no-repeat;
    background-color: #fff;
    background-position: bottom center;
    cursor:pointer;
}
.side-catalog .right-wrap #under-down{
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-bottom: 3px solid #fff;
    width: 31px;
    height: 20px;
    z-index: 1;
    background: url(../images/年份-向下-不可用.png) no-repeat;
    background-color: #fff;
    background-position: bottom center;
    cursor: not-allowed;
}
</style>
