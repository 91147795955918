<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg tittlrImgBorder" id="tittlrImgRail1">
			<b>国铁集团</b>
			<p>中国国家铁路集团有限公司（以下简称国铁集团）以铁路客货运输为主业，实行多元化经营，负责铁路运输统一调度指挥，统筹安排路网性运力资源配置，承担国家规定的公益性运输任务，负责铁路行业运输收入清算和收入进款管理，是国内大型的基础设施行业。</p>
		</h1>
		<div class="column">
			<div>
				<p>在国铁集团电子支付项目中，凝思充分发挥操作系统底层定制开发能力强大和安全机制研究深入的优势，结合长期大型基础设施行业工程经验，在电子支付平台中支撑了128颗安腾 IA-64 处理器架构的 HP 大型计算机，软件方面良好支持了中间件、数据库等基础应用和铁路业务系统。作为本项目的一大亮点，凝思创造性地将<span>强制访问控制机制</span>通过网络标记扩展到网络环境中，实现了<span>网络隔离和数据传输控制功能</span>，有效提高了网络数据传输的<span>机密性、完整性</span>，协助国铁集团构建了国内第一个达到<span>等级保护第四级</span>的应用系统。</p>
			</div>
			<div>
				<p>根据国铁集团要求，各路局的数据需要备份到总公司。为保证数据备份的安全性和准确性，凝思为国铁集团专用安全网关定制开发了安全操作系统，在保证大量业务数据高效转发的同时也保证了数据传输的安全、准确。此操作系统已经部署在国铁集团总公司及多个路局并<span>平稳运行</span>三年以上。</p>
			</div>
		</div>
		<div class="column columngray padcharge1">
			<div>
				<p>根据国铁集团公司的财务系统对安全数字证书的使用需求，凝思定制开发了安全证书转换系统，实现了以下功能：</p>
				<div class="imgcolum">
					<div>
						<img src="../images/SSL.png"/>
						<p>SSL加速卡调用</p>
					</div>
					<div>
						<img src="../images/双向.png"/>
						<p>双向认证</p>
					</div>
					<div>
						<img src="../images/CA.png"/>
						<p>CA证书格式转换</p>
					</div>
				</div>
			</div>
			<div>
				<p>安全证书转换系统不但通过数字认证提高了财务系统的安全性，而且极大的提高了SSL加密通信的性能，目前已成功部署并稳定运行。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'NationalRailwayGroup',
	}
</script>
<style scoped>

</style>
