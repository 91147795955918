/* Home官网首页模块仓库 */
//state:仓库存储数据的地方
const state = {
    navList:[
        {
            name:"首页",
            path:'/Home'
        },
        {
            name:"产品中心",
            path:'/Products/ProductsCatalog',
            subItems:[
            	{
            		link:'/Products/ProductsCatalog',
            		text: '产品概述',
            		goid:'top'
            	},
                {
                	link:'/Products/ProductsCatalog',
                	text: '操作系统',
            		goid:'OperatingSystem'
            	},
                {
                	link:'/Products/ProductsCatalog',
                	text: '系统功能增强软件',
            		goid:'SystemEnhancements'
            	},
                {
                	link:'/Products/ProductsCatalog',
                	text: '平台软件',
            		goid:'PlatformSoftware'
            	},
            	{
                	link:'/Products/ProductsCatalog',
                	text: '定制开发',
            		goid:'CustomDevelopment'
            	},
                {
                	link:'/Products/ProductsCatalog',
                	text: '凝思安全服务器平台',
            		goid:'SecureServerPlatform'
            	},
            ]
        },
        {
            name:"解决方案",
            path:'/Solutions/Government',
            subItems:[
                { link:'/Solutions/Government',text: '政府机关'},
                { link:'/Solutions/PoverIndustry',text: '电力行业 '},
                { link:'/Solutions/Rail',text: '轨道交通'},
                { link:'/Solutions/Army',text: '军队'},
                { link:'/Solutions/ResearchInstitutes',text: '科研院所'},
                //{ link:'/Solutions/Operator',text: '运营商'},
                //{ link:'/Solutions/Petrochemicals',text: '石油化工'},
                //{ link:'/Solutions/WaterConservancy',text: '水利'},
                //{ link:'/Solutions/Solution',text: '解决方案'}
            ]
        },
        {
            name:"生态合作",
            path:'/EcologicalCooperation/CooperativeValue',
            subItems:[
                { link:'/EcologicalCooperation/CooperativeValue',text: '合作价值'},
                { link:'/EcologicalCooperation/EcologicalPartner',text: '生态伙伴'},
                { link:'/EcologicalCooperation/EcologicalLaboratory',text: '生态实验室'},
                { link:'/EcologicalCooperation/CompatibleList',text: '兼容列表'}
            ]
        },
        {
            name:"服务与支持",
            // path:'/ServiceAndSupport/SecurityNotice',
            path:'/ServiceAndSupport/SoftwareDownload',
            subItems:[
                { link:'/ServiceAndSupport/SoftwareDownload',text: '软件下载'},
                { link:'/ServiceAndSupport/DocumentDownload',text: '文档下载'},
                { link:'/ServiceAndSupport/ServicePrecess',text: '服务流程'},
                { link:'/ServiceAndSupport/CommonQuestions',text: '常见问题'},
                { link:'/ServiceAndSupport/SecurityNotice',text: '安全公告'},
            ]
        },
        {
            name:"关于凝思",
            path:'/AboutLinx/Company',
            subItems:[
                { link:'/AboutLinx/Company',text: '公司简介'},
                { link:'/AboutLinx/Founder',text: '创始人简介'},
                // { link:'/AboutLinx/DevelopmentPath',text: '发展历程'},
                { link:'/AboutLinx/QualificationHonor',text: '资质荣誉'},
                { link:'/AboutLinx/News',text: '新闻中心'},
                // { link:'/AboutLinx/Partner',text: '合作伙伴'},
                // { link:'/AboutLinx/InvestorInformation',text: '投资者信息'},
                { link:'/AboutLinx/Enrollful',text: '招贤纳士'},
                { link:'/AboutLinx/Contact',text: '联系我们'}
            ]
        }
    ],
};
const mutations = {

};
const actions = {

};
const getters = {

};
export default {
	state,
	mutations,
	actions,
	getters
}
