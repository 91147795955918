<!-- 首页-凝思产品 -->
<template>
    <div class="box" id="products">
        <div class="title-font1" id="title-font1-box3">凝思产品
            <div></div>
        </div>
        <div class="con-in-box3">
            <div
             class="cell-in-box3" :class="{wow:bodyWidthMut>1170,fadeIn:bodyWidthMut>1170}" v-for="(item,index) in productList" :key="index"
             data-wow-duration="1.2s" :data-wow-delay="index*0.15+'s'" data-wow-offset="150"  data-wow-iteration="1">
                <div class="bor"></div>
                        <div class="bor2"></div>
                <div class="imgpr">    
                    <img :src="item.imgUrl" :alt="item.imgAlt">
                </div>
                <div class="tipr" v-html="item.tittle"></div>
                <p>{{item.text}}</p>
                <a @click="toguid(item.link,item.goid)">了解详情<img src="../../../images/箭头-蓝色-向右.png"></a>
            </div>
        </div>
        <router-link to="/Products/ProductsCatalog" class="biglink">前往产品中心</router-link>
    </div>
</template> 
<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
    name: 'LinxProducts',
    data() {
        return {
        	bodyWidthMut: document.body.clientWidth,
        }
    },
    computed:{
		...mapState({
			productList:(state)=>state.Home.productList,
		})
	},
    mounted(){
    	window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
        this.$nextTick(() => {
			new this.$wow.WOW().init();
		});
    },
    methods:{
    	toguid(path, id){
    		var path = path;
    		var Id = id;
    		localStorage.setItem('toId',Id);
    		this.$router.push(path);
    	}
    }
}
</script>

<style scoped>
    #products{
        background: url(./images/BANNER下点阵.png) no-repeat left 32px;
    }
    /*pc*/
    .con-in-box3{
        position:relative;
        width:1170px;
        margin: 0px auto 0;
        text-align:center;      
    }
    .cell-in-box3{    
        display:inline-block;
        position:relative;
        width: 261px;
        height: 390px;
        margin: 0 calc((1170px - 4 * 261px) / 8);
    }
    .cell-in-box3 .bor{
        position:absolute;
        width: 259px;
        height: 388px;
        border:1px  solid #e3e5e8;
        z-index:-1;
        opacity:0;
        transition: all .9s;
    }
    .cell-in-box3:after{
        position:absolute;
        top:0;
        left:0;
        display: block;
        width: 0px;
        height: 0px;
        border-left:1px  solid #fff;
        border-top:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3:before{
        position:absolute;
        top:0;
        right:0;
        display: block;
        width: 0px;
        height: 0px;
        border-right:1px  solid #fff;
        border-top:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3 .bor2:after{
        position:absolute;
        bottom:0;
        left:0;
        display: block;
        width: 0px;
        height: 0px;
        border-bottom:1px  solid #fff;
        border-left:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3 .bor2:before{
        position:absolute;
        bottom:0;
        right:0;
        display: block;
        width: 0px;
        height: 0px;
        border-bottom:1px  solid #fff;
        border-right:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }

    .cell-in-box3:hover .bor{
        opacity:1;
        transition: all .15s;
    }
    .cell-in-box3:hover:after,.cell-in-box3:hover:before,.cell-in-box3:hover .bor2:after,.cell-in-box3:hover .bor2:before{
        width: 14px;
        height: 14px;
        border-color:#898e96;
        transition: width .15s,height .15s;
    }

    .cell-in-box3 .imgpr{
        position:absolute;
        display: inline-block;
        width: 72px;
        height: 68px;
        top:40px;
        left:50%;
        transform: translateX(-50%); 
    }
    .cell-in-box3 .imgpr img{
        width: auto;
        height: 68px;
    }
    .cell-in-box3 .tipr{
        position:absolute;
        width:100%;
        top:161px;
        color: #262b33;
        font-size: 22px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 500;
        line-height: 28px;
    }
    .cell-in-box3 > p{
        position:absolute;
        width:160px;
        left:50%;
        transform: translateX(-50%); 
        top:234px;
        color: #565d69;
        font-size: 16px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 300;
        line-height: 20px;
    }
    .cell-in-box3 > a{
        text-decoration: none;
        position:absolute;
        width:100%;
        text-align:center;
        bottom:33px;
        left:0;
        font-weight:500;
        letter-spacing: 0px;
        color: #0072ce;
        font-size: 16px;
        line-height:18px;
        opacity:0;
        transition: all .15s;
        cursor:pointer;
    }
    .cell-in-box3:hover > a{
        opacity:1;
        bottom:39px;
        transition: all .3s;
    }
    .cell-in-box3 > a > img{
        position: relative;
        top: -2px;
        left: 6px;
        display: inline-block;
        width: 14px;
        height: 8px;
        transition: left .3s;
    }
    .cell-in-box3 > a:hover > img{
        left: 11px;
        transition: left .3s;
    }
/*pad横屏*/
@media only screen and (max-width:1170px){
	.con-in-box3{
	    width:1024px;
	}
	.cell-in-box3{    
	    width: 212px;
	    height: 352px;
        margin: 0 calc((1024px - 4 * 212px) / 8);
	}
	.cell-in-box3 .bor{
	    width: 210px;
	    height: 350px;
	}

	.cell-in-box3 .imgpr{
	    top:30px;
	}
	.cell-in-box3 .tipr{
	    top:139px;
	}
	.cell-in-box3 > p{
	    width:164px; 
	    top:212px;
	}
	.cell-in-box3 > a{
	    bottom:30px;
	    opacity:1;
	}
	.cell-in-box3:hover > a{
	    bottom:30px;
	}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
    #products{
		background: url(./images/BANNER下点阵-竖屏.png) no-repeat;
	}
    .con-in-box3{
        width:618px;
        -moz-column-count:2; /* Firefox */
-webkit-column-count:2; /* Safari 和 Chrome */
column-count:2;
    }
    .cell-in-box3{    
        width: 261px;
        height: 390px;
        margin-bottom:40px;
    }
    .cell-in-box3:last-child{
        border:none;
    }
    .cell-in-box3 .bor{
        position:absolute;
        width: 259px;
        height: 388px;
        border:1px  solid #e8e8e8;
        z-index:-1;
        opacity:0;
        transition: all .9s;
    }
    .cell-in-box3:after{
        position:absolute;
        top:0;
        left:0;
        display: block;
        width: 0px;
        height: 0px;
        border-left:1px  solid #fff;
        border-top:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3:before{
        position:absolute;
        top:0;
        right:0;
        display: block;
        width: 0px;
        height: 0px;
        border-right:1px  solid #fff;
        border-top:1px  solid #9fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3 .bor2:after{
        position:absolute;
        bottom:0;
        left:0;
        display: block;
        width: 0px;
        height: 0px;
        border-bottom:1px  solid #fff;
        border-left:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }
    .cell-in-box3 .bor2:before{
        position:absolute;
        bottom:0;
        right:0;
        display: block;
        width: 0px;
        height: 0px;
        border-bottom:1px  solid #fff;
        border-right:1px  solid #fff;
        content: ' ';
        transition: width .9s,height .9s,border-color 0s .9s;
    }

    .cell-in-box3 .bor{
        opacity:1;
        transition: all .15s;
    }
    .cell-in-box3:after,.cell-in-box3:before,.cell-in-box3 .bor2:after,.cell-in-box3 .bor2:before{
        width: 14px;
        height: 14px;
        border-color:#898e96;
        transition: width .15s,height .15s;
    }

    .cell-in-box3 .imgpr{
        position:absolute;
        display: inline-block;
        width: 72px;
        height: 68px;
        top:38px;
        left:50%;
        transform: translateX(-50%); 
    }
    .cell-in-box3 .tipr{
        position:absolute;
        width:100%;
        top:170px;
        color: #262b33;
        font-size: 22px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 500;
        line-height: 28px;
    }
    .cell-in-box3 > p{
        position:absolute;
        width:160px;
        left:50%;
        transform: translateX(-50%); 
        top:243px;
        color: #565d69;
        font-size: 16px;
        text-align:center;
        letter-spacing:0px;
        font-weight: 300;
        line-height: 20px;
    }
    .cell-in-box3 > a{
        text-decoration: none;
        position:absolute;
        width:100%;
        text-align:center;
        bottom:39px;
        left:0;
        font-weight:500;
        letter-spacing: 0px;
        color: #0072ce;
        font-size: 16px;
        opacity:1;
        transition: all .15s;
    }
    .cell-in-box3:hover > a{
        opacity:1;
        bottom:39px;
        transition: all .3s;
    }
}
/*手机端*/
@media only screen and (max-width:750px){
    #products{
		background: url(./images/BANNER下点阵-手机端.png) no-repeat;
        background-size: 50% auto;
        background-color: #fff;
	}
    .con-in-box3{
        width:100%;
        -moz-column-count:1; /* Firefox */
-webkit-column-count:1; /* Safari 和 Chrome */
column-count:1;
    }
    .cell-in-box3{    
        width: 317px;
        margin:0 auto;
        height: 265px;
        margin-bottom:0px;
        border-bottom:1px  solid #e8e8e8;
    }
    .cell-in-box3 .bor,.cell-in-box3:after,.cell-in-box3:before,.cell-in-box3 .bor2:after,.cell-in-box3 .bor2:before{
        display:none;
    }

    .cell-in-box3 .imgpr{
        width: auto;
        height: 60px;
        top:51px;
    }
    .cell-in-box3 .imgpr img{
        width: auto;
        height: 60px;
    }
    .cell-in-box3 .tipr{
        top:143px;
        font-size: 18px;
        line-height: 18px;
    }
    .cell-in-box3 .tipr >>> br{
        display:none;
    }
    .cell-in-box3 > p{
        width:295px;
        top:172.5px;
        font-size: 14px;
        font-weight: 300;
        line-height: 23px;
    }
    .cell-in-box3 > a{
        width:100%;
        height:100%;
        bottom:0px;
        opacity:0;
    }
    .cell-in-box3:hover > a{
        opacity:0;
        bottom:0px;
    }
    .cell-in-box3 > a > img{
        display:none;
    }
}
/*手机端1080
@media only screen and (min-height:1300px){
    #products{
		background: url(./images/BANNER下点阵-手机端1080.png) no-repeat;
	}
    .con-in-box3{
        width:100%;
        -moz-column-count:1;  Firefox 
        -webkit-column-count:1;  Safari 和 Chrome 
        column-count:1;
    }
    .cell-in-box3{
        width: 992px;
        height: 655px;
        margin-bottom:0px;
        border-top:146px  solid #fff;
        border-bottom:2px  solid #e0e0e0;
    }
    .cell-in-box3:nth-child(1){
        border-top:0px  solid #fff;
    }
    .cell-in-box3 .bor,.cell-in-box3:after,.cell-in-box3:before,.cell-in-box3 .bor2:after,.cell-in-box3 .bor2:before{
        display:none;
    }

    .cell-in-box3 .imgpr{
        width: auto;
        height: 173px;
        top:0px;
    }
    .cell-in-box3 .imgpr img{
        width: auto;
        height: 173px;
    }
    .cell-in-box3 .tipr{
        top:265px;
        font-size: 52px;
        line-height: 52px;
    }
    .cell-in-box3 > p{
        width:838px;
        top:353px;
        font-size: 40px;
        font-weight: 300;
        line-height: 64px;
    }
    .cell-in-box3 > a{
        width:100%;
        height:100%;
        bottom:0px;
        opacity:0;
    }
    .cell-in-box3:hover > a{
        opacity:0;
        bottom:0px;
    }
    .cell-in-box3 > a > img{
        display:none;
    }
}*/
</style>
