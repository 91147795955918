<template>
    <div id="titleinbox">
        <div id="titleinpage" @click="title4Hide = !title4Hide">
            {{title4List[title4].tittle}}
            <div id="rinpage" :class="title4Hide ? 'rinpage2' : 'rinpage1'"></div>
        </div>
		<div id="titlebox" :class="bodyWidthMut > 1170 || title4Hide ? '' : 'title4Hide'">
			<div
				class="titlediv"
				v-for="(item,index) in title4List"
				:key="index + '-1'"
                :class="{title5:title4 == index}"
                @click="changeindex1(index)"
			>
				<span
					:data-content="item.tittle"
					:class="filltiHide[index] ? 'close' : 'open'"
					@click="getfilltiHide(index),toTop(index)"
				>{{item.tittle}}
                    <img
                        v-show="index !== 0"
                        src="../../../images/箭头-蓝灰.png"
                    >
                </span>
				<ul class="fillti" v-show="filltiHide[index] && index !== 0">
					<li
						v-for="(text,index2) in item.text"
						:key="index2 + '-2'"
						@click="changeTitle4(index,text.tittle),title4Hide = false ,toTop(0)"
						:id="text.tittle == detailTittle ? 'onfill' : ''"
					>{{text.tittle}}</li>
				</ul>
			</div>
		</div> 
    </div>
</template>

<script>
export default {
	name:'SideCue',
    props: ['title4','detailTittle'],
	data() {
		return {
			bodyWidthMut: document.body.clientWidth,
			filltiHide:[false,false,false,false],
			title4List: this.$attrs.title4List,
            title4Hide :false,
            titleinpagetext:'请选择',
		}
	},
	methods:{
		gettitle4(index){
			if (index == 0){
				this.title4 = index; 
				this.detailTittle = '';
				this.title4Hide = !this.title4Hide;
			}else {
				if(this.bodyWidthMut > 1170){
					this.title4Hide = !this.title4Hide;
				} else {
					this.title4Hide = !this.title4Hide;
					this.title4Hide = !this.title4Hide;
				}
			}
		},
        changeindex1(index) {
            if(index === 0){
                this.$emit('update:title4', index);
                if(this.bodyWidthMut <= 1170){
                    this.title4Hide = false;
                }
            }
        },
        changeTitle4(index,text) {
            this.$emit('update:title4', index);
            this.$emit('update:detailTittle', text);
        },
        getfilltiHide(index){
            if(this.filltiHide[index] == false){
                this.$set(this.filltiHide, index, true)
            } else {
                this.filltiHide[index] = false;
                this.$set(this.filltiHide, index, false)
            }
        },
        toTop(val) {
        	if(val == 0){
				document.documentElement.scrollTop = 225;
			}
		}
	},
    mounted() {
      window.addEventListener('resize', () => this.bodyWidthMut = document.body.clientWidth, false);
    },
    watch: {
        title4:{
            // immediate:true,
            handler(val){
                this.$set(this.filltiHide, val, true);
                // this.titleinpagetext=this.title4List[this.title4].tittle;
                this.title4Hide = !this.title4Hide;
                if(this.bodyWidthMut <=1170){
                    this.filltiHide = [false,false,false,false];
                    this.title4Hide = false;
                }
            }
        }
    }
}
</script>
<style scoped>
#titleinbox{
  display: inline-block;
  vertical-align: top;
}
#titlebox{
  position: relative;
  width: 248px;
  border: #e0e0e0 1px solid;
}
#titlebox div{
  position: relative;
  cursor:pointer;
}
#titlebox div span{
  position: relative;
  display: block;
  width: calc(100% - 32px);
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing:0px;
  /* text-indent: 32px; */
  padding: 24px 0;
  padding-left: 32px;
  color: #262b33;
  z-index: 1;
}
#titlebox div:after{
  display: block;
  content: '';
  width:100%;
  height: 1px;
  background-color: #fff;
}
#titlebox div:last-child:after{
  display: none;
}
#titlebox .title5 span:after,#titlebox div span:hover:after{
  content:attr(data-content);
  display: inline-block;
  position:absolute;
  vertical-align: middle;
  width: calc(100% - 32px);
  padding: 24px 0;
  padding-left: 32px;
  height: 24px;
  line-height: 24px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  letter-spacing:0px;
  font-weight: 500;
  color: #dd1d29;
  background-color: #f7f7f7;
  top:-1px;
  left: 0;
  z-index: 9;
  pointer-events:none;
}
#titlebox .title5 span:before,#titlebox div span:hover:before{
  display: block;
  position:absolute;
  content: '';
  height: 74px;
  width: 2px;
  background-color: #dd1d29;
  z-index: 10;
  top:-1px;
  left: -1px;
  z-index: 9999;
}

#titlebox div span img{
    display: block;
    position: absolute;
    width:5px;
	height:8px;
    padding: 32px 24px;
	transition:transform 0.15s;
    z-index: 22;
    top:0;
    right: 0;
}
#titlebox div .open img{
    transform:rotate(0deg);
}
#titlebox div .close img{
	transform:rotate(90deg);
}
#titlebox div ul{
    position: relative;
    top: -1px;
    left: -1px;
    padding: 10px 0;
    z-index: 10;
    background-color: #fff;
    border-left: 1px solid #e0e0e0;
}
#titlebox div ul:after{
    display: block;
    position: relative;
    content: '';
    width: calc(100% + 3px);
    height: 1px;
    background-color: #e0e0e0;
    bottom: -12px;
    left: -1px;
    z-index: 999;
}
#titlebox div ul li{
    position: relative;
    display: block;
    width: calc(100% - 51px);
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing:0px;
    /* text-indent: 32px; */
    padding: 8px 0;
    padding-left: 51px;
    color: #262b33;
    z-index: 100;
    background-color: #fff;
    /* border-left: 1px solid #e0e0e0; */
}
#titlebox div ul li:hover,#titlebox div ul #onfill{
    color: #dd1d29;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
    #titleinpage{
        display:block;
        position: relative;
        width:872px;
        height:47px;
        padding-left:30px;
        line-height:49px;
        font-size:18px;
        font-weight: 500;
        color:#565d69;
        background-color:#fff;
        border:1px solid #e0e0e0;
        margin:0 auto 40px;
        z-index:8;
    }
    #titleinpage #rinpage{
        display:block;
        position: absolute;
        width:59px;
        height:47px;
        right:0;
        top:0;
        background:url(../../images/箭头-蓝灰.png) no-repeat center center;
        transition:transform 0.15s;
    }
    .rinpage1{transform:rotate(0deg);}
    .rinpage2{transform:rotate(90deg);}

    .title4Hide{display: none!important;}
    #titlebox{
        position: absolute;
        width:902px;
        padding:22px 0;
        top:48px;
        border: #e0e0e0 1px solid;
        box-shadow: 0px 3px 12px rgba(5,5,5,0.1);
        margin: 0;
        background-color:#fff;
        z-index:999;
    }
    #titlebox div span{
        font-size: 18px;
    }
    #titlebox .title5 span:after,#titlebox div span:hover:after,
    #titlebox .title5 span:before,#titlebox div span:hover:before{
        display: none;
    }
    #titlebox div ul{
        display:block;
        position: relative;
        width: calc(100% - 66px);
        padding:0;
        padding-left:66px;
    }
    #titlebox div ul:after{
        display: none;
    }
#titlebox div ul li{
    position: relative;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing:0px;
    padding: 8px 0;
    padding-left: 0;
    color: #292929;
    z-index: 100;
    background-color: #fff;
}
#titlebox div ul li:hover,#titlebox div ul #onfill{
    color: #292929;
}
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
  /*三级导航栏*/
  #titleinpage{
    width:688px;
    padding-left:15px;
  }
  #titlebox{
    width:718px;
  }
}
/*手机端*/
@media only screen and (max-width:750px){
    #titleinpage{
        width:calc(100vw - 15px - 1px - 30px);
        height:35px;
        line-height:35px;
        font-size:14px;
        margin: 0 auto;
        margin-left: 15px;
    }
    #titleinpage #rinpage{
        width:34.5px;
        height:35px;
        background-size: 5px 8px;
    }
    #titlebox{
        width:calc(100% - 30px - 1px);
        left:15px;
        padding:13px 0;
        top:35.5px;
    }
    #titlebox div span{
        font-size: 14px;
        padding: 12px 0;
        padding-left: 16px;
        height:24px;
        line-height:24px;
        width: calc(100% - 16px);
    }
    #titlebox div span img{
        padding: 20px 24px;
    }
    #titlebox div ul{
        width: calc(100% - 30px);
        padding-left:30px;
    }
    #titlebox div ul li{
        height: 22px;
        line-height: 22px;
        font-size:12px;
    }
    #titlebox div ul li:hover,#titlebox div ul #onfill{
        color: #292929;
    }
}
</style>
