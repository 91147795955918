<template>
    <div class="font" id="pandectfont">
        <h1>
            <span class="titleOfh1">常见问题总览</span>
        </h1>
        <div class="questionnav" v-for="(item,index) in title4List" :key="index + '-3'" v-show="index > 0">
            <h3>{{item.tittle}}</h3>
            <div>
                <a v-for="(text,index2) in item.text" :key="index2 + '-4'" @click="changeTitle4(index,text.tittle)">{{text.tittle}}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name:'Overview',
    props: ['title4','detailTittle'],
	data() {
		return {
			title4List: this.$attrs.title4List,
		}
	},
    methods:{
        changeTitle4(index,text) {
            this.$emit('update:title4', index);
            this.$emit('update:detailTittle', text);
        }
    },
}
</script>
<style scoped>
#pandectfont > h1{
    line-height:72px;
    border-bottom:1px solid #e0e0e0;
}
#pandectfont .questionnav{
    margin: 28px 32px 36px;
}
#pandectfont .questionnav:last-child{
    margin: 8px 32px 36px;
}
#pandectfont .questionnav h3{
    font-size: 16px;
    line-height: 16px;
    color: #262b33;
    font-weight: 500; 
    text-indent: 0;
    margin: 0;
}
#pandectfont .questionnav h3:before{
    display: none;
}
#pandectfont .questionnav div{
    display: grid;
    margin-top:12px;
    grid-gap: 12px 32px;
	grid-template-columns: repeat(auto-fill,calc(25% - 32px));
}
#pandectfont .questionnav div a{
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    color: #565d69;
    font-weight: 400; 
    cursor:pointer;
}
#pandectfont .questionnav div a:hover{
    color: #b11721;
}
#pandectfont .questionnav div a:before, #pandectfont .questionnav div a:hover:before{
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 4px;
    margin-left: 0px;
    background-color: #5c5c5c;
    content: " ";
}
/*手机端*/
@media only screen and (max-width:750px){
    #pandectfont > h1 span{
        margin-left: 0;
    }
    #pandectfont .questionnav{
        margin: 14px 15px 18px;
    }
    #pandectfont .questionnav:last-child{
        margin: 4px 15px 18px;
    }
    #pandectfont .questionnav h3{
        font-size: 14px;
        line-height: 14px;
    }
    #pandectfont .questionnav div{
        grid-template-columns: repeat(auto-fill,50%);
    }
    #pandectfont .questionnav div a, #pandectfont .questionnav div a:hover{
        font-size: 13px;
    }
}
</style>
