<template>
	<div>
		<TopOfProducts :smallTitle="natittle2" :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainMargin">
			<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
			<Multipath v-show="natittle3 == '凝思多路径软件'"></Multipath>
			<DualSystem v-show="natittle3 == '凝思双机热备软件'"></DualSystem>
			<ResourceControl v-show="natittle3 == '凝思资源控制软件'"></ResourceControl>
			<TouchScreen v-show="natittle3 == '凝思触摸屏软件'"></TouchScreen>
		</div>
	</div>
</template>

<script>
import Multipath from './Multipath.vue';
import DualSystem from './DualSystem.vue';
import ResourceControl from './ResourceControl.vue';
import TouchScreen from './TouchScreen.vue';
export default {
	name:'OperatingSystem',
	components:{
		Multipath,
		DualSystem,
		ResourceControl,
		TouchScreen,
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: '系统功能增强软件',
			natittle3: this.$route.query.producttitle,
			brief:'',
			brief1:[
				'凝思多路径软件是在凝思安全操作系统的基础上构建的功能增强软件，能够控制应用服务器访问存储设备的路径，管理服务器和存储设备间的多条物理路径。',
				'凝思多路径软件实现了完整的路径管理、路径冗余、路径选择、路径调度、路径负载均衡、路径故障处理、路径恢复功能，为用户提供安全、成熟、稳定、易用的多路径解决方案。',
			],
			brief2:[
				'凝思双机热备软件是在凝思安全操作系统的基础上构建的功能增强软件，为用户提供高可用（High Availability）的服务运行平台。',
				'双机热备软件工作方式为主备方式，即主机工作，备机处于监控准备状况；当主机宕机时，备机接管主机的一切工作，待主机恢复正常后，按使用者的设定以自动或手动方式将服务切换到主机上运行，数据的一致性通过共享存储系统解决。',
			],
			brief3:[
				'凝思资源控制软件是在凝思安全操作系统的基础上构建的功能增强软件，实现了对系统中应用程序进程使用服务器软硬件资源的控制，有效解决了应用程序对资源的无限制申请和占用问题。通过对服务器软硬件资源在应用服务程序之间合理的配置和使用，可以有力保证系统的长期稳定运行，大大提高系统的可用性和健壮性，为用户业务的长期不间断运行提供保障。',
			],
			brief4:[
				'凝思触摸屏软件是在凝思安全操作系统的基础上构建的功能增强软件，实现了对触摸屏幕的软件支持功能，通过在屏幕上触摸点击就可以完成与鼠标相同的功能。该软件运行于凝思安全操作系统V6.0，可同时支持多个触摸屏幕，支持单点、两点触摸，提供给用户一个简单、易用、成熟的触摸屏操作过程和体验。',
			],
		}
	},
    watch:{
        $route:{
            immediate:true,
            handler(){
                this.natittle3 = this.$route.query.producttitle;
                if(this.$route.query.producttitle == '凝思多路径软件'){
                    this.brief = this.brief1;
                } else if(this.$route.query.producttitle == '凝思双机热备软件'){
                    this.brief = this.brief2;
                } else if(this.$route.query.producttitle == '凝思资源控制软件'){
                    this.brief = this.brief3;
                } else if(this.$route.query.producttitle == '凝思触摸屏软件'){
                    this.brief = this.brief4;
                } 
            }
        }
    }
}
</script>
<style scoped>

</style>
