<template>
	<div class="fontmain">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font" id="Linxhistory" v-show="title4 == 0">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
		    <div class="sum">
		        	<p>北京凝思科技有限公司成立于2001年，以“实现操作系统安全化、可信化、可靠化、国产化”为使命，致力于国家新型基础设施建设，服务于国民经济重点行业，实现基础软件国产化，为大型基础设施的信息基础设施提供高安全、高可靠、高性能操作系统软件及其相关的关键产品，从系统软件基础层面开始，全面保障信息安全。</p>
		        	<p>凝思软件秉承“独立自主、完全可控”的产品研发理念，采取“借助开源社区版，不依赖任何商业发行版，自主再创新构建独立、系统、完整的安全体系”的基本技术路线，在产品和技术发展上自主可控。即便RedHat、SUSE或Ubuntu等国外商业产品 “断供”也不会对凝思操作系统的产品造成任何影响。</p>
		        	<p>公司成立之初，研发团队推出了基于凝思安全操作系统的安全服务器原理样机。样机在国家863十五周年成果展的保密馆展出，备受瞩目。2002年凝思召开了凝思磐石安全服务器的新闻发布会，引起主流媒体的高度关注。</p>
		    </div>
		    <div class="Linxyear">
		        	<p v-for="(itemLinxyear,indexLinxyear) in Linxyear" :key="indexLinxyear + '-1'"><b>{{itemLinxyear.year}}</b>{{itemLinxyear.text}}</p>
		    </div>
		    <div class="sum">
		        	<p>凝思研发凝思安全操作系统多年，凭借着卓越的专业技术能力、丰富的工程实施管理以及运维保障的经验，凝思的专业团队帮助行业客户将应用系统迁移到凝思安全操作系统上，向用户提供所需的基于开源架构的系统解决方案、技术咨询、产品定制开发、用户开发支持、培训和运维保障等全方位的支持和服务，同时可以配合第三方厂商完成凝思安全机制的相关配置，实现应用程序安全增强，提升用户程序的安全性。凝思与世界技术发展同步，为行业用户的关键应用打造安全自主可控的基础软件平台。</p>
		    </div>
		</div>
		<div class="font" id="Linxhistory" v-show="title4 == 1">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
			<div
				class="memorabilia"
				v-for="(itemmemorabilia,indexmemorabilia) in memorabilia"
				:key="indexmemorabilia + '-2'"
			>
					<p>{{itemmemorabilia.text}}</p>
					<h1>{{itemmemorabilia.year}}</h1>
					<div></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'DevelopmentPath',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '',
			title4: 0,
			icon: require('../images/发展icon.png'),
			title4List: [
				{
					tittle:'凝思足迹',
				},
				{
					tittle:'大事记'
				}
			],
			Linxyear:[
				{
					year:'2005',
					text:'年经国家发改委批准，“凝思磐石安全服务器系统系列产品产业化项目”被列入国家高技术产业发展项目。获得涉及国家秘密的计算机信息系统软件开发甲级资质。从2001年到2007年，凝思通过研发、生产、销售基于凝思安全操作系统的凝思磐石安全服务器平台，以及为行业用户设计、开发、生产满足高安全需求的定制产品，不断向用户介绍安全操作系统的概念、安全等级划分的标准和技术要求，提升市场对信息系统安全的认识水平。从2006年开始，国家关于信息系统安全的多项要求和指南相继出台，市场对安全操作系统的认知度不断提高，选择安全、自主、可控的操作系统逐渐成为行业用户设计建设自己的业务系统时的一个重要考量。',
				},
				{
					year:'2007',
					text:'年，国家有关部门组织了对重要基础设施的信息系统安全的全面检查，检查采用了真实有力的攻击方式。凝思安全服务器系统被放在电力调度系统的前端进行防御工作。国家有关部门采取了多种手段进行攻击，历时21天，当外围的其它防御设备被逐一攻破之后，凝思安全服务器系统仍保持正常工作。',
				},
				{
					year:'2008',
					text:'年，凝思磐石安全服务平台软件（即凝思安全操作系统）V2.4作为操作系统产品批量部署到国家电网电力调度系统。',
				},
				{
					year:'2009',
					text:'年，凝思安全操作系统V4.1成为国内首批获得GB/T 20272-2006 《信息安全技术-操作系统安全技术要求》结构化保护级（第四级）销售许可证的产品。凝思独创的强制行为控制机制和独自编写的内核安全子系统使得凝思安全操作系统独具特色，满足了行业用户对高安全等级操作系统的要求。',
				},
				{
					year:'2010',
					text:'年，凝思安全操作系统开始在国家电网调度系统大规模部署，并结合电网系统要求推出凝思双机热备系统软件和多种工程解决方案。',
				},
				{
					year:'2011',
					text:'年“凝思安全操作系统产业化项目”被列入国家发改委信息安全专项项目，中国的信息系统软硬件平台产业链在国家的扶持下不断发展和完善，市场接受程度不断提高。几年中，凝思相继推出了凝思安全操作系统V4.2、凝思安全操作系统V6.0和其它系列产品。这些产品具有良好的兼容性、安全、稳定、可靠，同时又简单易用，技术先进、可扩展性强，为用户提供了坚实的底层软件平台，支撑了行业用户的关键应用。凝思安全操作系统被大规模部署在行业用户的关键应用系统上。本年公司凝思安全操作系统软件开始进入铁道行业。',
				},
				{
					year:'2012',
					text:'年，凝思围绕凝思安全操作系统推出凝思多路径软件、凝思安全分布式文件系统、凝思资源控制软件、凝思零拷贝软件和凝思虚拟化平台等产品，推出凝思安全操作系统V6.0，获得软件著作权证书。',
				},
				{
					year:'2013',
					text:'年，国家电网公司第四次“安全操作系统竞争性谈判”结果公布，凝思在评标过程中综合排名前列，获得70％的市场份额。继凝思双机热备系统之后，凝思多路径软件也进入了集采目录。含本次的中标结果，凝思在国家电网公司的总装机量已超过7000套。凝思安全操作系统广泛部署于各类机架服务器、刀片集群、图形工作站和安全传输类设备，全面支撑了智能电网调度系统实时监控与预警、调度管理、调度计划和安全校核4大类26种专业应用。',
				},
				{
					year:'2014',
					text:'年，凝思推出基于开源架构的系统解决方案。',
				},
				{
					year:'2015',
					text:'年， 凝思应邀参加了由银监会主办的中国银行业信息科技风险管理2015年会，会议主题为“科技引领创新，开放共享未来”。会议期间，与会领导参观了会场中凝思“用国产安全操作系统构建新型IT基础设施”成果展示区，对凝思的产品及解决方案给予极高的赞誉。',
				},
				{
					year:'2016',
					text:'年，成立北京凝思软件股份有限公司。本年凝思再次获得涉密信息系统集成软件开发甲级资质。',
				},
				{
					year:'2017',
					text:'年，凝思牵头申请并联合解放军国防科技大学和南瑞科技股份有限公司的核高基“工业互联网、物联网安全操作系统产业化及规模化应用“课题获得正式批复。公司创始人宫敏博士荣获“功勋程序员”荣誉证书。',
				},
				{
					year:'2018',
					text:'年，获2017年度IBM优秀PowerLinux解决方案奖。同年，北京凝思软件股份有限公司四川分公司成立。',
				},
				{
					year:'2019',
					text:'年，凝思顺利完成国庆70周年电网、互联网安全保障工作。',
				},
				{
					year:'2020',
					text:'年，凝思作为牵头单位承担的核高基“工业互联网、物联网安全操作系统产业化及规模化应用”项目顺利通过验收。凝思顺利通过软件能力成熟度CMMI5认证。',
				},
				{
					year:'2021',
					text:'年，凝思软件顺利完成战略融资工作。此次融资由国网英大产业基金公司与国开制造业转型升级基金联合领投，南网产业基金、中信建投资本、三峡资本、江苏省投管、维京投资、万有引力资本与诚美资本联合跟投。融资金额总计3.57亿元人民币。',
				},
			],
			memorabilia:[
				{
					text:'2021年,顺利完成战略融资工作，融资金额总计3.57亿元人民币。',
					year:'2021',
				},
				{
					text:'2020年,获得CMMI5级认证，公司作为牵头单位的核高基课题通过验收。',
					year:'2020',
				},
				{
					text:'2019年,顺利完成国庆七十周年保电工作。',
					year:'2019',
				},
				{
					text:'2018年,喜获2017年度IBM优秀PowerLinux解决方案奖。',
					year:'2018',
				},
				{
					text:'2017年,公司牵头申请并联合解放军国防科技大学和南瑞科技股份有限公司的核高基“工业互联网、物联网安全操作系统产业化及规模化应用“课题获得正式批复。',
					year:'2017',
				},
				{
					text:'2016年,再次获得涉密信息系统集成软件开发甲级资质。',
					year:'2016',
				},
				{
					text:'2015年,应邀参加了由银监会主办的中国银行业信息科技风险管理2015年会，会议主题为“科技引领创新，开放共享未来”。会议期间，与会领导参观了会场中凝思科技“用国产安全操作系统构建新型IT基础设施”成果展示区，对凝思的产品及解决方案给予极高的赞誉。',
					year:'2015',
				},
				{
					text:'2014年,推出基于开源架构的系统解决方案。',
					year:'2014',
				},
				{
					text:'2013年,凝思安全操作系统V6.0获得公安部等保四级销售许可证。推出凝思多路径软件和凝思资源控制软件，获软件著作权。',
					year:'2013',
				},
				{
					text:'2012年,经国家发改委批准，“凝思安全操作系统产业化项目”被列入2011年国家高科技产业发展项目计划。凝思围绕凝思安全操作系统推出凝思多路径软件、凝思安全分布式文件系统、凝思资源控制软件、凝思零拷贝软件和凝思虚拟化平台等产品，推出凝思安全操作系统V6.0。',
					year:'2012',
				},
				{
					text:'2011年,凝思安全操作系统进入铁路市场，凝思安全操作系统V4.2获得公安部等级保护四级销售许可证。',
					year:'2011',
				},
				{
					text:'2010年,凝思安全操作系统开始在国家电网调度系统大规模部署，凝思推出凝思双机热备系统软件和多种工程解决方案。',
					year:'2010',
				},

				{
					text:'2009年,凝思磐石安全操作系统V4.1获得公安部等级保护四级销售许可证。',
					year:'2009',
				},
				{
					text:'2008年,凝思磐石安全操服务器平台软件（即凝思磐石安全操作系统）V2.4产品进入国家电网调度自动化市场。',
					year:'2008',
				},
				{
					text:'2005年,经国家发改委批准，“凝思磐石安全服务器系统系列产品产业化项目”被列入国家高技术产业发展项目，完成了国产安全服务器系统的产业化，同时完成了军队用户安全管理平台的定制开发和生产。获得涉及国家秘密的计算机系统软件开发甲级资质。',
					year:'2005',
				},
				{
					text:'2004年,完成双系统隔离服务器和电力安全文件网关的定制开发和生产，产品进入国家计算机网络与信息安全管理中心和电力市场。推出固化的安全操作系统卡，凝思磐石安全服务平台软件V2.4（即凝思磐石安全操作系统）获得软件著作权。',
					year:'2004',
				},
				{
					text:'2003年,凝思磐石安全服务器系统获《计算机信息系统安全专用产品销售许可证》，被塞迪评测评为2003年度最佳Linux安全产品”，产品为有高安全要求的政府和军队用户提供CA、DNS、网页、数据库等多种应用服务。凝思磐石安全服务器进入国家电力调度通信中心。',
					year:'2003',
				},
				{
					text:'2002年,推出凝思磐石安全服务器，产品获得军用信息安全产品军B级认证，获得主流媒体高度关注，被军队单位采购。',
					year:'2002',
				},
				{
					text:'2001年,凝思在北京成立，承担的国家863信息安全应急项目“服务器安全增强技术”通过专家组验收，推出安全服务器原理样机，参加军用操作系统标准的制定。',
					year:'2001',
				},
			]
		}
	},
	watch:{
        title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
            },
            deep: true,
        }
    }
}
</script>
<style scoped>
/* 凝思足迹 */
#Linxhistory{
	padding-bottom: 6px!important;
}
.font .sum{
	margin: 2px 0px 20px;
}
.font .sum p{
	color: #333333;
}

.font .Linxyear{
	margin: 2px 0px 20px;
}
.font .Linxyear p{
	color: #4d4d4d;
	letter-spacing: 0px;
	margin-bottom: 16px;
}
.font .Linxyear p b{
	color: #b11721;
	font-size: 20px;
}
/* 大事记 */
.font .memorabilia{
	padding-left: 24px;
	margin-left:96px;
	border-left: 2px solid #dbdbdb; 
	position: relative;
}
.font .memorabilia p{
	margin:0;
	padding: 26px 32px 26px 0px;
	color: #333;
	border-bottom: #e0e0e0 1px solid;
	text-indent:0em;
}
.font .memorabilia:nth-child(2) p{
padding: 6px 32px 26px 0px;
}
.font .memorabilia:last-child p{
	border-bottom: #e0e0e0 0px solid;
}
.font .memorabilia h1{
	position: absolute;
	top:50%;
	left: -24px;
	transform: translateX(-100%) translateY(-50%);
	color: #96999c;
	font-size: 20px;
	letter-spacing: 0px;
	font-weight: 500; 
}
.font .memorabilia:nth-child(2) h1{
	top:calc(50% - 10px);
}
.font .memorabilia div{
	position: absolute;
	top:50%;
	left: -1px;
	transform: translateX(-50%) translateY(-50%);
	height: 8px;
	width: 8px;
	background-color: #dbdbdb;
	border: 2px solid #fff;
	border-radius: 50%;
}
.font .memorabilia:nth-child(2) div{
	top:calc(50% - 10px);
}
.font .memorabilia:last-child div::after{
	display: inline-block;
	position: relative;
	top:9px;
	height: 50px;
	width: 8px;
	background-color: #fff;
	content: '';
}
/*pad横屏*/
@media only screen and (max-width:1170px){
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
	/* 大事记 */
	.font .memorabilia:last-child div::after{
		display: inline-block;
		position: relative;
		top:9px;
		height: 63px;
		width: 8px;
		background-color: #fff;
		content: '';
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	/* 凝思足迹 */
	#Linxhistory{
		padding-bottom: 0px!important;
	}
	.font .sum{
		margin: 0px;
	}
	.font .sum:nth-child(2) p:nth-child(1){
		margin-top: 0px;
	}
	.font .Linxyear{
		margin: 23px 0;
	}
	.font .Linxyear p{
		color: #4d4d4d;
	}
	.font .Linxyear p b{
		color: #b11721;
		font-size: 18px;
	}
	/* 大事记 */
	.font .memorabilia{
		padding-left: 15px;
		margin-left:63px;
	}
	.font .memorabilia p{
		padding: 13.5px 13px 13.5px 0px;
	}
	.font .memorabilia:nth-child(2) p{
		padding: 0px 15px 13.5px 0px;
	}
	.font .memorabilia h1{
		left: -12px;
		transform: translateX(-100%) translateY(-50%);
		font-size: 18px;
		font-weight: 600; 
	}
	.font .memorabilia:nth-child(2) h1,.font .memorabilia:nth-child(2) div{
		top:calc(50% - 7px);
	}
	.font .memorabilia:last-child div::after{
		height: 57px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	凝思足迹
	#Linxhistory{
		padding-bottom: 0px!important;
	}
	.font .sum{
		margin: 0px;
	}
	.font .sum:nth-child(2) p:nth-child(1){
		margin-top: 0px;
	}
	.font .Linxyear{
		margin: 70px 0;
	}
	.font .Linxyear p{
		color: #4d4d4d;
	}
	.font .Linxyear p b{
		color: #b11721;
		font-size: 44px;
	}
	大事记
	.font .memorabilia{
		padding-left: 40px;
		margin-left:168px;
		border-left: 4px solid #dbdbdb; 
	}
	.font .memorabilia p{
		padding: 51px 40px 51px 0px;
		border-bottom: #e0e0e0 2px solid;
	}
	.font .memorabilia:nth-child(2) p{
		padding: 0px 40px 51px 0px;
	}
	.font .memorabilia h1{
		left: -40px;
		transform: translateX(-100%) translateY(-50%);
		font-size: 40px;
		font-weight: 600; 
	}
	.font .memorabilia div{
		position: absolute;
		top:50%;
		left: -2px;
		transform: translateX(-50%) translateY(-50%);
		height: 14px;
		width: 14px;
		background-color: #dbdbdb;
		border: 3px solid #fff;
		border-radius: 50%;
	}
	.font .memorabilia:last-child div::after{
		display: inline-block;
		position: relative;
		top:15px;
		height: 176px;
		width: 14px;
		background-color: #fff;
		content: '';
	}
	.font .memorabilia:nth-child(2) h1,.font .memorabilia:nth-child(2) div{
		top:calc(50% - 26px);
	}
} */
</style>
