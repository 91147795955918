<template>
	<div class="fontmain" id="companyFont">
		<!-- <NaIn :natittle1="natittle1" :natittle2="natittle2" :natittle3="natittle3"></NaIn> -->
		<SideNavigation :title4.sync="title4" :title4List="title4List"></SideNavigation>
		<div class="font">
			<h1>
				<img :src="icon"/>
				<span>{{natittle3}}</span>
			</h1>
			<!-- <div class="Linx">
				<div>
					<h3>我们是</h3> 
					<p>国内先进的国产安全操作系统厂商<span class="twinkle"></span></p>
				</div>
			</div> -->
			<div class="video">
				<p>北京凝思软件股份有限公司（以下简称“凝思软件”）是国内先进的国产安全操作系统厂商，以“实现国家大型基础设施行业基础软件国产化、安全化”为使命，致力于提供“高安全性、高性能、高稳定性”的操作系统软件及相关产品和服务，在操作系统层面保障生产及关键业务系统的安全稳定运行,目前已成为国内大型基础行业国产操作系统的中坚力量。</p>
				<p>凝思软件秉承“基于开源，但不依赖于开源，更不受制于任何商业发行版”的理念，面向不同的应用场景，采用自主可控的技术路线，不依赖于任何基于Linux的商业发行版，不受国外操作系统厂商的“断供”、“闭源”等影响。自成立以来，凝思软件始终坚持以自有技术、产品和服务为国家大型基础行业提供更安全、更可信的自主操作系统环境，为国家关键领域的建设保驾护航。</p>
				<p>凝思软件是国家级“专精特新”小巨人企业，参与了多项国家、行业操作系统相关标准的制定，拥有多项专利和软件著作权。2024年5月，凝思安全操作系统顺利通过安全可靠测评，产品能力和自主研发实力得到了国家权威机构的认可。通过多年技术研究和工程实践的积累，凝思软件拥有了强大的技术能力，在可靠性、安全性、实时性、专业性等多方面实现了重大突破，在操作系统国产化方面做出了重要贡献。凝思软件操作系统研发能力处于国内前列，并与国际同步。</p>
				<p>凝思软件核心产品为凝思安全操作系统，产品线包括服务器版、图形工作站版、装置专用版、工业互联网版等。同时，基于下游客户的特定应用场景需求，凝思软件逐步开发了系统功能软件和云计算产品。系统功能软件主要有内网主机监管软件、双机热备系统、多路径系统、并行冗余网络协议软件、容器系统等，云计算产品主要有安全云桌面系统、虚拟化管理平台、安全终端及解析软件等。</p>
				<p>凝思软件已完成多轮战略融资工作，引入国开制造业转型升级国家大基金、国家电网、南方电网、三峡集团、中信建投、深创投、中国联通、江苏省投管、成都科创投等中央企业和国资背景产业基金，助力凝思软件产业融合和行业拓展。</p>
				<!-- <div class="dominant">
					<div class="cell" v-for="(itemdominant,indexdominant) in dominant" :key="indexdominant + '-2'">                               
							<span><img :src="itemdominant.imgUrl" :alt="itemdominant.text">{{itemdominant.text}}</span>
					</div>
				</div> -->
				<p>经过多年持续努力，凝思软件安全操作系统系列产品、系统功能软件系列产品和云计算产品的用户遍及电力、政府、金融、轨道交通、电信运营商等重点行业，在大型基础行业实现规模化部署，产品的安全性、稳定性经过了充分验证，公司已成为国家大型基础设施行业信息化建设的坚强基石。</p>
			</div>
			<!-- <div class="banner">
				<div class="support">
					<h2>凝思软件系列产品成为基础设施行业的坚强基石</h2>
				</div>
				<div class="demo swiper-container swiper-no-swiping">
					<div class="list swiper-wrapper">
						<img 
							class="swiper-slide"
							v-for="(itemdemoList,indexdemoList) in demoList" 
							:key="indexdemoList + '-3'" 
							:src="itemdemoList.imgUrl"
							:alt="itemdemoList.text"
						>
					</div>
				</div>
				<div class="demo swiper-container swiper-no-swiping" id="demofont">
					<div id="Fontbox" class="swiper-wrapper"> 
						<div
							class="swiper-slide"
							id="listFont"
							v-for="(itemdemoList,indexdemoList) in demoList" 
							:key="indexdemoList + '-4'" 
						><div>{{itemdemoList.text}}</div></div>
					</div>
					<div>
						<a id="prev"></a>
						<a id="next"></a>
					</div>
				</div>
			</div>
			<br clear="all" /> -->
			<div class="spirt">
				<div v-for="(itemspirtList,indexspirtList) in spirtList" :key="indexspirtList + '-5'">
					<h2>{{itemspirtList.tittle}}</h2>
					<p>{{itemspirtList.text}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import Swiper from 'swiper';

export default {
	name:'Company',
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: false,
			natittle3: '',
			icon: require('../images/公司icon.png'),
			title4: 0,
			title4List: [
				{
					tittle:'公司简介',
				}
			],
			dominant:[
				{
					imgUrl:require('../images/公司简介-可靠性.png'),
					text:'可靠性'
				},
				{
					imgUrl:require('../images/公司简介-安全性.png'),
					text:'安全性'
				},
				{
					imgUrl:require('../images/公司简介-实时性.png'),
					text:'实时性'
				},
				{
					imgUrl:require('../images/公司简介-性能.png'),
					text:'性能'
				},
			],
			/*demoList:[
				{
					imgUrl:require('../images/政府.png'),
					text:'政府机关'
				},
				{
					imgUrl:require('../images/电力行业.png'),
					text:'电力行业'
				},
				{
					imgUrl:require('../images/轨道.png'),
					text:'轨道交通'
				},
				{
					imgUrl:require('../images/石油化工.png'),
					text:'石油化工'
				},
				{
					imgUrl:require('../images/军队.png'),
					text:'军队'
				},
									{
					imgUrl:require('../images/科研.png'),
					text:'科研院所'
				},
			],*/
			spirtList:[
				{
					tittle:'企业精神',
					text:'专业、创新、合作、坚持'
				},
				{
					tittle:'公司使命',
					text:'实现国家大型基础设施行业基础软件国产化、安全化'
				},
				{
					tittle:'公司愿景',
					text:'让用户拥有更加安全和开放的关键任务系统'
				},
			]
		}
	},
	/*mounted() {
		this.swiper = new Swiper('.demo', {
			direction: 'vertical',
			loop:true,
			navigation: {
				nextEl: '#next',
				prevEl: '#prev',
			},
		});
	},*/
	watch:{
        title4:{
            immediate:true,
            handler(){
                this.natittle3 = this.title4List[this.title4].tittle;
            },
            deep: true,
        }
    }
}
</script>
<style scoped>
/*.font .Linx{
    width: 839px;
    height: 256px;
    margin-left: 32px;
    background: url(../images/公司简介-背景图.jpg) no-repeat;
    background-size:auto 256px;
    position: relative;
}
.font .Linx div{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0px;
}
.font .Linx div h3{
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
    font-size: 20px;
    font-weight: 300;
    letter-spacing:1px;
	margin: 0;
    margin-bottom: 15px;
	text-indent: 0em;
}
.font .Linx div h3:before{
	display: none;
}
.font .Linx div p{
    margin: 0;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    font-size: 28px;
    font-weight: 600;
    letter-spacing:1px;
    text-indent:34px;
}
.font .Linx div p span{
	display:inline-block;
    width: 15px;
    height: 2px;
    margin-left:5px;
    margin-bottom: -2px;
    background-color: #fff;
    transition:opacity 0.05s;
}
.twinkle{
	animation-name: flash; 
  	animation-duration: 2s; 
	animation-timing-function:ease;
	animation-iteration-count :infinite;
}
*/
.font .video{
    margin: 0px 32px 41px;
}
.font .video p{
    margin: 12px 0;
}
/* .font .video p video{
    float: right;
    margin-left: 35px;
    margin-bottom: 2px;
}
.font .video p > b{
    color: #262b33;
    display: block;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing:1px;
    text-indent:0em;
}
.font .video p > b:nth-of-type(1){
    padding-top: 11px;
}
.font .video p > b:nth-of-type(2){
    margin-bottom: 29px;
}
.font .video p span{
    color: #565d69;
    display: block;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing:1px;
    text-indent:2em;
}
.font .video p span b{
    color: #565d69;
    display: inline;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing:1px;
    text-indent:0em;
}
.font .leader{
    margin: 0px 0px;
    padding-top: 41px;
    background-color: #f7f7f7;
}
.font .leader p{
    margin: 0 32px 12px;
    color: #262b33;
    font-weight: 400;
    letter-spacing:1px;
    text-indent:2em;
}
.font .leader h2{
    margin: 0 32px 20px;
    color: #262b33;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing:1px;
    text-indent:0em;
} */
.font .video .dominant{
	display: grid;
    margin: 0px 32px;
    /*padding-bottom: 48px;*/
    grid-template-columns: 154px 154px 154px 154px;
	text-align: center;
	justify-content:space-around;
}
.font .video .dominant .cell{
    position: relative;
    top: 0;
    display: block;
    width: 154px;
    height: 90px;
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
}
.font .video .dominant .cell:hover{
    box-shadow: 0px 2px 8px 0px rgba(5,5,5,0.12);
}

.font .video .dominant .cell span{
    color: #262b33;
    font-size: 16px;
    font-weight: 500;
    letter-spacing:0px;
    position: absolute;
    top: calc(50% - 8px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space:nowrap;
}
.font .video .dominant .cell span img{
    max-height:26px;
    max-width:26px;
    margin-right: 12px;
    position: relative;
    top: 8px;
}
/*
.font .banner{
    margin: 48px 32px;
}
.font .banner h2{
    margin: 0;
    color: #262b33;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing:1px;
    text-indent:0em;
}
.font .banner .productList{
	margin-bottom: 7px;
}
.font .banner .productList p{
	margin: 14px 0 7px;
}
.font .banner .productList div{
	display: grid;
	grid-template-columns: repeat(auto-fill,25%);
}
.font .banner .productList div p{
	margin: 5px 0;
	margin-left:30px;
	text-indent: 0;
	color: #565d69;
	position: relative;
}
.font .banner .productList div p:before{
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../images/公司简介-向右标记.png) no-repeat;
    background-size:18px;
    position: absolute;
    top: 5px;
    left:-30px;
    content: '';
}
.font .banner .productList+p{
	margin: 0 0 calc(64px - 4px);
}
.font .banner .support{
    width: 320px;
    float: left;
    padding-top:10px;
}
.font .banner .support p{
    margin: 0;
	text-indent: 0;
	padding-top:16px;
	line-height: 24px;
}

.font .banner .demo{
    float: right;
    width: 451px;
    height: 275px;
    border: 10px solid #fff;
    box-shadow: 0px 0px 20px 0px rgba(5,5,5,0.12);
    position: relative;
    overflow: hidden;
}
.font .banner .demo .list{
    width: 451px;
    height: 275px;
}
.font .banner .demo .list img{
	display: block;
    width: 451px!important;
    height: 275px!important;
}
.font .banner #demofont{
    position: absolute;
	right: 32px;
    width: 451px;
    height: 36px;
	margin: 129px 0;
	background: rgba(0, 0, 0, 0);
	border: none;
    border-left: 10px solid #fff;
	border-right: 10px solid #fff;
    box-shadow: none;
}
.font .banner #demofont #Fontbox{
	position: relative;
    width: 100%;
    height: 36px;
	margin: 0 auto;
}
.font .banner #demofont #Fontbox #listFont{
	width: 100%!important;
    height: 336px;
}
.font .banner #demofont #Fontbox #listFont div{
    width: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    font-size: 24px;
    font-weight: 500;
    letter-spacing:0px;
    line-height: 36px;
}
.font .banner .demo div a{
    display: inline-block;
    width: 28px;
    height: 36px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-repeat:no-repeat;
    background-position: center center;
    z-index: 9;
    background-size:11px 10px;
    cursor:pointer;
}
.font .banner .demo div #prev{
    background-image: url(../images/关于-banner-左.png);
}
.font .banner .demo div #prev:hover{
    background-image: url(../images/关于-banner-左-选中.png);
}
.font .banner .demo div #next{
    right: 0;
    background-image: url(../images/关于-banner-右.png);
}
.font .banner .demo div #next:hover{
    background-image: url(../images/关于-banner-右-选中.png);
}*/
.font .spirt{
    margin-top: 48px;
    padding: 48px 32px 24px;
    background-color: #f7f7f7;
}
.font .spirt div{
    height: 118px;
    margin-bottom: 24px;
    background-color: #fff;
    padding-left: 32px;
    padding-top: 16px;
    box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
}
.font .spirt div h2{
    line-height: 56px;
    margin: 0;
    color: #b11721;
    font-size: 24px;
    font-weight: 600;
    letter-spacing:1px;
    text-indent:0em;
}
.font .spirt div p{
    line-height: 48px;
    margin: 0;
    color: #262b33;
    font-weight: 500;
    letter-spacing:1px;
    text-indent:0em;
}
/*pad横屏*/
@media only screen and (max-width:1170px){
}
/*pad竖屏*/
@media only screen and (max-width:1024px){
/*	.font .Linx{
		width: 656px;
		height: 256px;
		margin-left: 32px;
		background-size:839px;
		position: relative;
	}*/

	.font .video{
		margin: 0px 32px 41px;
	}

/*	.font .leader p{
		margin: 0 32px 29px;
	}
.font .leader .itemcell span{
	text-indent: 24px;
}
.font .leader .itemcell span:nth-child(1){
	width: 140px;
}
.font .leader .itemcell span:nth-child(2){
	width: 280px;
}
.font .leader .itemcell span:nth-child(3){
	width: 220px;
}*/


	.font .video .dominant{
		grid-template-columns: repeat(auto-fill, 132px);
	}
	.font .video .dominant .cell{
		width: 123px;
		height: 92px;
	}
	.font .video .dominant .cell:hover{
		box-shadow: 0px 2px 8px 0px rgba(5,5,5,0.12);
	}
	.font .video .dominant .cell span{
		position: relative;
		display:block;
		width:123px;
		color: #262b33;
		font-size: 16px;
		font-weight: 500;
		letter-spacing:0px;
		text-align: center;
		top: 50%;
		left: 0;
		transform: translateX(0) translateY(-50%);
		white-space:nowrap;
	}
	.font .video .dominant .cell span img{
		display:block;
		position: relative;
		margin: 0 auto 10px;
		top: 0px;
	}
	/* .font .banner{
		position: relative;
		margin:30px 32px;
		height:302px;
	}
	.font .banner .productList+p{
		padding-bottom:36px;
	}
	.font .banner .support{
		width: 256px;
		float: left;
		position: relative;
		top: -48px;
	}
	.font .banner .support h2{
		margin: 0;
		color: #262b33;
		font-size: 24px;
		line-height: 44px;
		font-weight: 600;
		letter-spacing:1px;
		text-indent:0em;
		white-space:nowrap;
	}
	.font .banner .support h3{
		margin-bottom: 16px;
	}
	.font .banner .support p{
		padding-top:20px;
		line-height: 28px;
		margin-bottom: 0px;
	}
	.font .banner .support p:before{
		margin-right: 10px;
	}
	.font .banner .demo{
		float: right;
		width: 342px;
		height: 275px;
		border: 10px solid #fff;
		box-shadow: 0px 0px 20px 0px rgba(5,5,5,0.12);
		position: relative;
		overflow: hidden;
		margin-top:30px;
	}
	.font .banner .demo .list{
		width: 342px;
		height: 275px;
	}
	.font .banner .demo .list img{
		display: block;
		width: 451px!important;
		height: 275px!important;
	}
	.font .banner #demofont{
		width: 342px;
		right:0;
		margin-top:calc(129px + 30px);
	}*/

	.font .spirt{
		margin-top: 0px;
		padding: 48px 32px 24px;
		background-color: #f7f7f7;
	}
	.font .spirt div{
		height: 118px;
		margin-bottom: 24px;
		background-color: #fff;
		padding-left: 32px;
		padding-top: 16px;
		box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	}
	.font .spirt div h2{
		line-height: 56px;
		margin: 0;
		color: #b11721;
		font-size: 24px;
		font-weight: 600;
		letter-spacing:1px;
		text-indent:0em;
	}
	.font .spirt div p{
		line-height: 48px;
		margin: 0;
		color: #262b33;
		font-size: 16px;
		font-weight: 500;
		letter-spacing:1px;
		text-indent:0em;
	}
}
/*手机端*/
@media only screen and (max-width:750px){
	#companyFont{
		padding-bottom:0px;
	}
	/*.font .Linx div{
		padding-left: 24px;
	}
	.font .Linx{
		width: 100%;
		height: 150px;
		margin-left: 0;
		background: url(../images/公司简介-背景图.jpg);
		background-size:auto 150px;
	}
	.font .Linx div h3{
		font-size: 18px;
		line-height:28px;
		letter-spacing:0px;
		margin-bottom: 0;
	}
	.font .Linx div h2{
		font-size: 22px;
		line-height:32px;
		letter-spacing:0px;
	}
	.font .Linx div p{
		font-size: 18px;
		line-height:28px;
		text-indent:0;
	}
	.font .Linx div p span{
		display:inline-block;
		width: 11px;
		height: 1.5px;
		margin-left:1px;
	}*/
	.font .video{
		margin: 0px 15px 28px;
	}
	.font .video p:first-child{
		margin-top: 0;
	}
	.font .video p > b{
		color: #262b33;
		display: block;
		font-size: 18px;
		line-height: 28px;
		font-weight: 600;
		letter-spacing:0px;
		text-indent:0em;
	}
	.font .video p > b:nth-of-type(1){
		padding-top: 0px;
	}
	.font .video p > b:nth-of-type(2){
		margin-bottom: 22px;
	}
	.font .video p span,.font .video p span b{
		color: #565d69;
		display: block;
		font-size: 14px;
		line-height: 23px;
		font-weight: 400;
		letter-spacing:0px;
		text-indent:2em;
	}
	.font .video p span b{
		text-indent:0em;
		display:block;
		margin:7px 0;
	}

	/*.font .leader{
		margin: 0px 0px;
		padding-top: 22px;
		background-color: #f7f7f7;
	}
	.font .leader p{
		margin: 0 16px 15px;
		color: #262b33;
		font-weight: 400;
		letter-spacing:0px;
		text-indent:2em;
	}
	.font .leader .itemcell{
		padding-bottom: 0;
	}
	.font .leader .itemcell span{
		display: block;
		margin: 6px auto;
		text-indent: 12px;
		font-size: 13px;
		line-height: 30px;
		height: 30px;
	}
	.font .leader .itemcell span:nth-child(1){
		width: 110px;
	}
	.font .leader .itemcell span:nth-child(2){
		width: 230px;
	}
	.font .leader .itemcell span:nth-child(3){
		width: 180px;
	}

	.font .leader h2{
		margin: 17px 15px 0;
		color: #262b33;
		font-size: 17px;
		line-height: 28px;
		padding: 10px 0;
		font-weight: 600;
		letter-spacing:0px;
		text-indent:0em;
	}*/
	.font .video .dominant{
		margin: 0px 15px;
		/*padding-bottom: 32px;*/
		grid-template-columns: 65px 65px 65px 65px;
	}
	.font .video .dominant .cell{
		width: 65px;
		height: 83px;
	}
	.font .video .dominant .cell span{
		width: 65px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing:0px;
		text-align: center;
		top: 50%;
		left: 0;
		transform: translateX(0) translateY(-40%);
		white-space:nowrap;
		line-height:30px;
	}
	.font .video .dominant .cell span img{
		max-height:18px;
		max-width:18px;
		margin: 0 auto 0px;
	}
	/*.font .banner{
		margin: 22px 15px;
		height:auto;
		position: relative;
	}
	.font .banner h2{
		font-size: 18px;
		line-height: 38px;
		letter-spacing:0px;
	}
	.font .banner .productList{
		margin-bottom: 44px;
	}
.font .banner .productList p{
	margin: 14px 0 26px;
}
.font .banner .productList div{
	display: grid;
	grid-template-columns: repeat(auto-fill,50%);
}
	.font .banner .support{
		width: 100%;
	}
	.font .banner .support h2{
		font-size: 18px;
		line-height: 38px;
		letter-spacing:0px;
	}
	.font .banner .support p{
		display:inline-block;
		width:100%;
		font-size: 14px;
		line-height: 28px;
		margin-bottom: 0px;
		letter-spacing:0px;
	}
	.font .banner .support p:before{
		margin-right: 16px;
		width: 28px;
		height: 28px;
		background: url(../images/公司简介-向右标记.png) no-repeat;
		background-size:28px;
	}
	.font .banner .demo{
		float: none;
		position: relative;
		width: calc(100vw - 50px);
		height: 275px;
		border: 10px solid #fff;
		box-shadow: 0px 0px 20px 0px rgba(5,5,5,0.12);
		overflow: hidden;
	}
	.font .banner .demo .list{
		width: calc(100vw - 50px);
		height: 275px;
	}
	.font .banner .demo .list img{
		display: block;
		width: auto;
		min-width: calc(100vw - 50px);
		min-height: 275px;
	}
	.font .banner #demofont{
		width: calc(100vw - 50px);
		right:0;
		bottom: 0px;
	}*/

	.font .spirt{
		margin-top: 32px;
		padding: 32px 15px 17px;
		background-color: #f7f7f7;
		margin-bottom: 0;
	}
	.font .spirt div{
		height: auto;
		margin-bottom: 15px;
		background-color: #fff;
		padding-left: 16px;
		padding-top: 5px;
		padding-bottom: 16px;
		box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	}
	.font .spirt div h2{
		line-height: 48px;
		font-size: 18px;
		font-weight: 600;
		letter-spacing:1px;
		text-indent:0em;
	}
	.font .spirt div p{
		line-height: 20px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing:0px;
	}
}
/*手机端1080*/
/* @media only screen and (min-height:1300px){
	我们是国内领先的服务器版安全操作系统提供商
	.font .Linx{
		width: 100%;
		height: 434px;
		margin-left: 0;
		background-size:auto 434px;
	}
	.font .Linx div{
		padding-left: 44px;
	}
	.font .Linx div h3{
		font-size: 53px;
		line-height:84px;
		letter-spacing:0px;
		margin-bottom: 0;
	}
	.font .Linx div h2{
		font-size: 64px;
		line-height:96px;
		letter-spacing:0px;
	}

	凝思安全操作系统的核心安全机制具有完全自主知识产权。
	.font .video{
		margin: 81px 44px 79px;
	}
	.font .video p > b{
		color: #262b33;
		display: block;
		font-size: 52px;
		line-height: 74px;
		font-weight: 600;
		letter-spacing:0px;
		text-indent:0em;
	}
	.font .video p > b:nth-of-type(1){
		padding-top: 0px;
	}
	.font .video p > b:nth-of-type(2){
		margin-bottom: 68px;
	}
	.font .video p span,.font .video p span b{
		color: #565d69;
		display: block;
		font-size: 40px;
		line-height: 66px;
		font-weight: 400;
		letter-spacing:0px;
		text-indent:2em;
	}
	.font .video p span b{
		text-indent:0em;
		display:block;
		margin:44px 0;
	}

	底层软件平台和平台架构设计。
	.font .leader{
		margin: 0px 0px;
		padding-top: 81px;
		background-color: #f7f7f7;
	}
	.font .leader p{
		margin: 0 44px 44px;
		color: #262b33;
		font-weight: 400;
		letter-spacing:0px;
	}
	.font .leader h2{
		margin: 39px 44px 0;
		color: #262b33;
		font-size: 52px;
		line-height: 132px;
		font-weight: 600;
		letter-spacing:0px;
		text-indent:0em;
	}
	.font .leader .dominant{
		padding-bottom: 92px;
		width: calc(100% - 88px);
	}
	.font .leader .dominant .cell{
		width: 180px;
		height: 238px;
	}
	.font .leader .dominant .cell span{
		position: relative;
		display:block;
		width:180px;
		color: #262b33;
		font-size: 38px;
		font-weight: 500;
		letter-spacing:0px;
		text-align: center;
		top: 50%;
		left: 0;
		transform: translateX(0) translateY(-50%);
		white-space:nowrap;
	}
	.font .leader .dominant .cell span img{
		display:block;
		height:42px;
		max-height:42px;
		max-width:42px;
		position: relative;
		margin: 0 auto 26px;
		top: 0px;
	}
	全方位的支持和服务。
	.font .banner{
		margin: 69px 44px 92px;
		height:340px;
	}
	.font .banner .support{
		width: 100%;
	}
	.font .banner .support h2{
		font-size: 52px;
		line-height: 98px;
		letter-spacing:0px;
	}
	.font .banner .support h3{
		max-width: 836px;
		font-size: 38px;
		line-height: 64px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	.font .banner .support p{
		display:inline-block;
		width:calc(50% - 4px);
		font-size: 40px;
		line-height: 86px;
		margin-bottom: 1px;
		letter-spacing:0px;
	}
	.font .banner .support p:before{
		margin-right: 22px;
		width: 40px;
		height: 40px;
		background: url(../images/公司简介-向右标记.png) no-repeat;
		background-size:40px;
	}
	.font .banner .demo{
		float:left;
		margin: 47px auto 0;
		left:calc(50% - 496px);
		width: 952px;
		height: 622px;
		border: 20px solid #fff;
		box-shadow: 0px 0px 20px 0px rgba(5,5,5,0.12);
		position: relative;
		overflow: hidden;
	}
	.font .banner .demo .list{
		width: 952px;
		height: 622px;
	}
	.font .banner .demo .list img{
		display: block;
		width: 952px!important;
		height: 622px!important;
	}
	.font .banner #demofont{
		position: absolute;
		right: 44px;
		width: 952px;
		height: 60px;
		margin: 851px 0;
		background: rgba(0, 0, 0, 0);
		border: none;
		border-left: 20px solid #fff;
		border-right: 20px solid #fff;
		box-shadow: none;
	}
	.font .banner #demofont #Fontbox{
		position: relative;
		width: 100%;
		height: 60px;
		margin: 0 auto;
	}
	.font .banner #demofont #Fontbox #listFont{
		width: 100%!important;
		height: 60px;
	}
	.font .banner #demofont #Fontbox #listFont div{
		width: 100%;
		text-align: center;
		color: #fff;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
		font-size: 52px;
		font-weight: 500;
		letter-spacing:0px;
		line-height: 60px;
	}
	.font .banner .demo div a{
		display: inline-block;
		width: 60px;
		height: 76px;
		background-color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-repeat:no-repeat;
		background-position: center center;
		z-index: 9;
		background-size:23px 20px;
	}

	.font .spirt{
		margin-top: 92px;
		padding: 92px 44px 48px;
		background-color: #f7f7f7;
	}
	.font .spirt div{
		height: auto;
		margin-bottom: 44px;
		background-color: #fff;
		padding-left: 44px;
		padding-top: 8px;
		padding-bottom: 52px;
		box-shadow: 0px 1px 4px 0px rgba(5,5,5,0.12);
	}
	.font .spirt div h2{
		line-height: 152px;
		font-size: 52px;
		font-weight: 600;
		letter-spacing:1px;
		text-indent:0em;
	}
	.font .spirt div p{
		line-height: 46px;
		font-size: 34px;
		font-weight: 400;
		letter-spacing:0px;
	}
} */
</style>
