<template>
	<div>
		<TopOfProducts :smallTitle="natittle2" :natittle3="natittle3" :brief="brief"></TopOfProducts>
		<div class="fontmain fontmainNoBottom">
			<ServerEdition v-show="natittle3 == '服务器版'"></ServerEdition>
		</div>
	</div>
</template>

<script>
import ServerEdition from './ServerEdition.vue';

export default {
	name:'OperatingSystem',
	components:{
		ServerEdition
    },
	data() {
		return {
			natittle1: this.$attrs.tittle,
			natittle2: "操作系统",
			natittle3: this.$route.query.producttitle,
			brief:'',
			brief1:[
				'本产品适用于后端大型服务器集群和高性能计算机节点。充分利用硬件平台多路多核的特性，支持高流量和大并发的网络数据处理能力，提供丰富的网络服务，具备多种冗余容错机制。',
			],
		}
	},
	watch:{
        $route:{
            immediate:true,
            handler(){
                this.natittle3 = this.$route.query.producttitle;
                if(this.$route.query.producttitle == '服务器版'){
                    this.brief = this.brief1;
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
