<template>
	<div class="font" id="fontNoBorder">
		<h1 class="tittlrImg tittlrImgBorder" id="tittlrImgPoverIndustry1">
			<b>电网调度系统</b>
			<p>基于凝思安全操作系统的全国产化电力自动化调度系统于2008年开始在四川省备调中心成功部署并投入运行，整个国产化的电力调度系统的功能性、稳定性和安全性得到充分考验和肯定。</p>
		</h1>
		<div class="column columnOutcountColumn">
			<div>
				<p>凝思安全操作系统大量部署在<span>电力自动化调度系统</span>中，截至2022年，已累计部署<span>超过160000套</span>，市场占有率<span>超70%。</span></p>
			</div>
			<div class="countColumn">
				<div>
					<img src="../images/数字背景.png"/>
					<ScrollNumber :val="formatNumber(value,0,0)"></ScrollNumber>
					<p>累计部署超160000套</p>
				</div>
				<div>
					<img src="../images/数字背景.png"/>
					<ScrollNumber :val="formatNumber(value2,0,0)" :fit="1"></ScrollNumber>
					<p>市场占有率超70%</p>
				</div>
			</div>
		</div>
		<div class="outColumn4">
			<h2>典型案例</h2>
			<div class="column4">
				<p>国家电网调度中心</p>
				<p>南网总调调度中心</p>
				<p>东北、华北、西北、西南、华东、华中六大分中心</p>
				<p>江苏省调及各地市调度中心</p>
				<p>浙江省调及各地市调度中心</p>
				<p>上海市调及各区调度中心</p>
				<p>北京市调及各区调度中心</p>
				<p>天津市调及各区调度中心</p>
				<p>安徽省调及各地市调度中心</p>
				<p>福建省调及各地市调度中心</p>
				<p>江西省调及各地市调度中心</p>
				<p>河北省调及各地市调度中心</p>
				<p>冀北调度中心</p>
				<p>四川省调及各地市调度中心</p>
				<p>山东省调及各地市调度中心</p>
				<p>山西省调及各地市调度中心</p>
				<p>吉林省调及各地市调度中心</p>
				<p>湖北省荆门市调度中心</p>
				<p>湖南省岳阳市调度中心、郴州市调度中心</p>
				<p>重庆市调度中心</p>
				<p>云南中调</p>
				<p>广西中调</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'DispatchingSystem',
	data() {
		return {
			value:160000,
			value2:70,
		}
	},
	methods: {
		/**
		 * formatNumber()
		 * 将数值四舍五入后格式化.
		 *
		 * @param num 数值(Number或者String)
		 * @param cent 要保留的小数位(Number)
		 * @param isThousand 是否需要千分位 0:不需要, 1:需要(数值类型);
		 * @return 格式的字符串,如'1,234,567.45'
		 * @type String
		*/
		formatNumber(num, cent, isThousand) {
			num = num.toString().replace(/\$|\,/g, "");
			if (isNaN(num)) num = "0";
			var sign = num == (num = Math.abs(num));
			num = parseFloat(num.toFixed(cent));
			num = num.toString();
			var cents;
			if (num.lastIndexOf(".") != -1) {
				let index = num.lastIndexOf(".");
				cents = num.substring(index + 1, num.length);
			} else {
				cents = "";
			}
			num = Math.floor(num * Math.pow(10, cent) + 0.50000000001);
			num = Math.floor(num / Math.pow(10, cent)).toString();
			if (isThousand) {
				for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
				  num = num.substring(0, num.length - (4 * i + 3)) + "," + num.substring(num.length - (4 * i + 3));
			}
			if (cent > 0) {
				if (cents == "") {
					return (sign ? "" : "-") + num;
				} else {
					return (sign ? "" : "-") + num + "." + cents;
				}
			} else {
				return (sign ? "" : "-") + num;
			}
		},
  	},
}
</script>
<style scoped>
.select {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    height: 60px;
}
</style>
